import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  JSON: { input: any; output: any }
}

export type AiFeatureUsageLimit = {
  __typename?: 'AIFeatureUsageLimit'
  limit: Scalars['Int']['output']
  usage: Scalars['Int']['output']
}

export type Achievement = {
  __typename?: 'Achievement'
  description?: Maybe<Scalars['JSON']['output']>
  id: Scalars['Int']['output']
  issueYear?: Maybe<Scalars['Int']['output']>
  issuingAuthority?: Maybe<Scalars['String']['output']>
  jobSeekerId?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  searchDescription?: Maybe<Scalars['String']['output']>
}

export type AchievementInput = {
  description?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  issueYear?: InputMaybe<Scalars['Int']['input']>
  issuingAuthority?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  searchDescription?: InputMaybe<Scalars['String']['input']>
}

export type ActivateCompanyUserInput = {
  companyUserId: Scalars['Int']['input']
}

export type ActivityLog = {
  __typename?: 'ActivityLog'
  CandidateApplication?: Maybe<CandidateApplication>
  CandidateProfile: CandidateProfile
  CompanyUser?: Maybe<CompanyUser>
  TemplateStage?: Maybe<TemplateStage>
  activity?: Maybe<Scalars['JSON']['output']>
  activityTimestamp?: Maybe<Scalars['DateTime']['output']>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  candidateProfileId: Scalars['Int']['output']
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  jobAction?: Maybe<JobActionEnum>
  templateStageId?: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['DateTime']['output']
  userAction?: Maybe<UserActionEnum>
}

export type Address = {
  __typename?: 'Address'
  activeStatus?: Maybe<Scalars['Boolean']['output']>
  addressLine1?: Maybe<Scalars['String']['output']>
  addressLine2?: Maybe<Scalars['String']['output']>
  addressType?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  contactName?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  jobListingId?: Maybe<Scalars['Int']['output']>
  latitude?: Maybe<Scalars['String']['output']>
  longitude?: Maybe<Scalars['String']['output']>
  officeId?: Maybe<Scalars['Int']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  scalisUserId?: Maybe<Scalars['Int']['output']>
  state?: Maybe<Scalars['String']['output']>
  timeZone?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type AdvanceCandidateApplicationInput = {
  candidateApplicationIds: Array<Scalars['Int']['input']>
  doNotNotifyCandidate?: InputMaybe<Scalars['Boolean']['input']>
  hostCalendarId?: InputMaybe<Scalars['Int']['input']>
  interviewLength?: InputMaybe<Scalars['Int']['input']>
  interviewSchedule?: InputMaybe<InterviewScheduleEnum>
  interviewers?: InputMaybe<Array<Scalars['Int']['input']>>
  message?: InputMaybe<Scalars['String']['input']>
  nextTemplateStageId: Scalars['Int']['input']
  templateMessageId?: InputMaybe<Scalars['Int']['input']>
}

export type AdvanceFromOfferToHiredInput = {
  candidateApplicationId: Scalars['Int']['input']
}

export type AnswerInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  checkboxes?: InputMaybe<Array<Scalars['String']['input']>>
  date?: InputMaybe<Scalars['DateTime']['input']>
  freeResponse?: InputMaybe<Scalars['String']['input']>
  multipleChoice?: InputMaybe<Scalars['String']['input']>
  numerical?: InputMaybe<Scalars['Float']['input']>
  yesNo?: InputMaybe<Scalars['Boolean']['input']>
}

export type ApplicantNotes = {
  __typename?: 'ApplicantNotes'
  UserNote?: Maybe<UserNote>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  scalisUserId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  userNoteId?: Maybe<Scalars['Int']['output']>
}

export enum ApplicationCreationTypeEnum {
  Dummy = 'DUMMY',
}

export type ApplicationFiltersOnCandidateProfile = {
  applicationStatus?: InputMaybe<Array<ApplicationStatusEnum>>
  applicationStatusNotIn?: InputMaybe<Array<ApplicationStatusEnum>>
  applicationType?: InputMaybe<Array<ApplicationTypeEnum>>
  candidateProfileId?: InputMaybe<Array<Scalars['Int']['input']>>
  companies?: InputMaybe<Array<Scalars['String']['input']>>
  companyId?: InputMaybe<Scalars['Int']['input']>
  creditedCompanyUserIds?: InputMaybe<Array<Scalars['Int']['input']>>
  educationLevel?: InputMaybe<Array<EducationLevelEnum>>
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingIds?: InputMaybe<Array<Scalars['Int']['input']>>
  jobTitles?: InputMaybe<Array<Scalars['String']['input']>>
  lastActivity?: InputMaybe<LastActivity>
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
  majors?: InputMaybe<Array<Scalars['String']['input']>>
  matchScore?: InputMaybe<MatchScore>
  medalistDesignation?: InputMaybe<Array<Scalars['String']['input']>>
  nonTraditionalStatus?: InputMaybe<Scalars['Boolean']['input']>
  profileType?: InputMaybe<ProfileTypeEnum>
  publicListingIds?: InputMaybe<Array<Scalars['String']['input']>>
  raceEthnicity?: InputMaybe<Array<RaceEthnicityEnum>>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
  schools?: InputMaybe<Array<Scalars['String']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
  skills?: InputMaybe<Array<Scalars['String']['input']>>
  sourceCategory?: InputMaybe<Array<SourceCategoryEnum>>
  sourceName?: InputMaybe<Array<Scalars['String']['input']>>
  templateMilestoneNotIn?: InputMaybe<Array<NameEnum>>
  templateStageIds?: InputMaybe<Array<Scalars['Int']['input']>>
  timezones?: InputMaybe<Array<Scalars['String']['input']>>
  veteranStatus?: InputMaybe<Array<VeteranStatusEnum>>
  willingToRelocate?: InputMaybe<Array<WillingToRelocateEnum>>
  yearsOfExperience?: InputMaybe<Scalars['Int']['input']>
}

export type ApplicationForm = {
  __typename?: 'ApplicationForm'
  ApplicationFormScreeningQuestion?: Maybe<Array<ApplicationFormScreeningQuestion>>
  Company?: Maybe<Company>
  CompanyDepartment?: Maybe<CompanyDepartment>
  CompanyOffice?: Maybe<CompanyOffice>
  CompanyUser?: Maybe<CompanyUser>
  companyDepartmentId?: Maybe<Scalars['Int']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  companyOfficeId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  isArchived?: Maybe<Scalars['Boolean']['output']>
  isDeleted?: Maybe<Scalars['Boolean']['output']>
  isEdited?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ApplicationFormScreeningQuestion = {
  __typename?: 'ApplicationFormScreeningQuestion'
  ApplicationForm?: Maybe<ApplicationForm>
  ScreeningQuestion?: Maybe<ScreeningQuestion>
  applicationFormId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  isActive?: Maybe<Scalars['Boolean']['output']>
  rankOrder?: Maybe<Scalars['Int']['output']>
  screeningQuestionId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ApplicationRejection = {
  __typename?: 'ApplicationRejection'
  CandidateApplication?: Maybe<CandidateApplication>
  CandidateMessage?: Maybe<CandidateMessage>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  details?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  messageId?: Maybe<Scalars['Int']['output']>
  reason?: Maybe<RejectionReasonEnum>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum ApplicationStatusEnum {
  InterviewCompleted = 'INTERVIEW_COMPLETED',
  InterviewNotScheduled = 'INTERVIEW_NOT_SCHEDULED',
  InterviewRequested = 'INTERVIEW_REQUESTED',
  InterviewResend = 'INTERVIEW_RESEND',
  InterviewScheduled = 'INTERVIEW_SCHEDULED',
  InvitedToApply = 'INVITED_TO_APPLY',
  InviteDeclined = 'INVITE_DECLINED',
  NeedsReview = 'NEEDS_REVIEW',
  Prospect = 'PROSPECT',
  Rejected = 'REJECTED',
  RejectionCancelled = 'REJECTION_CANCELLED',
  RejectionPending = 'REJECTION_PENDING',
}

export enum ApplicationTypeEnum {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC',
}

export type AutoRejectCandidatesInput = {
  applicationId: Scalars['Int']['input']
  candidateProfileId: Scalars['Int']['input']
  customMessage: Scalars['String']['input']
  publicJobListingId: Scalars['String']['input']
  scalisUserId: Scalars['Int']['input']
  screeningQuestionsAnswers: Array<ScreeningQuestionAnswerInput>
}

export type AutoRejectCandidatesResponse = {
  __typename?: 'AutoRejectCandidatesResponse'
  jobId: Scalars['String']['output']
}

export type AvailableFilterOutput = {
  __typename?: 'AvailableFilterOutput'
  column: Scalars['String']['output']
  label: Scalars['String']['output']
  type: CoreReportFilterTypeEnum
  values?: Maybe<Array<Scalars['String']['output']>>
}

export type BookmarkedJob = {
  __typename?: 'BookmarkedJob'
  JobListing?: Maybe<JobListing>
  ScalisUser?: Maybe<ScalisUser>
  id: Scalars['ID']['output']
  jobListingId?: Maybe<Scalars['Int']['output']>
  publicListingId?: Maybe<Scalars['String']['output']>
  scalisUserId?: Maybe<Scalars['Int']['output']>
}

export type Calendar = {
  __typename?: 'Calendar'
  CalendarEvents?: Maybe<Array<CalendarEvent>>
  calendarSettingsId: Scalars['Int']['output']
  color?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isPrimary: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nylasCalendarId: Scalars['String']['output']
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent'
  Calendar?: Maybe<Calendar>
  CandidateInterview?: Maybe<CandidateInterview>
  CompanyUser?: Maybe<CompanyUser>
  EventAttachments?: Maybe<Array<EventAttachment>>
  EventJobs?: Maybe<Array<EventJob>>
  calendarId?: Maybe<Scalars['Int']['output']>
  candidates?: Maybe<Scalars['JSON']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  conferencing?: Maybe<Scalars['JSON']['output']>
  description?: Maybe<Scalars['String']['output']>
  employers?: Maybe<Scalars['JSON']['output']>
  endTime?: Maybe<Scalars['DateTime']['output']>
  eventType?: Maybe<EventTypeEnum>
  id: Scalars['ID']['output']
  internalGuests?: Maybe<Scalars['JSON']['output']>
  isScalisEvent: Scalars['Boolean']['output']
  location?: Maybe<Scalars['String']['output']>
  masterEventId?: Maybe<Scalars['String']['output']>
  nylasCalendarEventId?: Maybe<Scalars['String']['output']>
  nylasEventStatus?: Maybe<NylasEventStatusesEnum>
  regardingCandidates?: Maybe<Scalars['JSON']['output']>
  regardingJobs?: Maybe<Scalars['JSON']['output']>
  reminders?: Maybe<Scalars['JSON']['output']>
  repeats?: Maybe<RepeatsEnum>
  startTime?: Maybe<Scalars['DateTime']['output']>
  timeZone?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export enum CalendarProviderEnum {
  Google = 'google',
  Microsoft = 'microsoft',
}

export type CalendarSettings = {
  __typename?: 'CalendarSettings'
  Calendars?: Maybe<Array<Calendar>>
  availability?: Maybe<Scalars['JSON']['output']>
  companyUserId: Scalars['Int']['output']
  email: Scalars['String']['output']
  hourDisplay: Scalars['Int']['output']
  id: Scalars['ID']['output']
  nylasGrantId: Scalars['String']['output']
  provider: Scalars['String']['output']
  timeZone?: Maybe<Scalars['String']['output']>
}

export type CampaignErrors = {
  __typename?: 'CampaignErrors'
  company?: Maybe<Scalars['String']['output']>
  orderedProducts?: Maybe<Array<ValidationError>>
  orderedProductsSpecs?: Maybe<Array<ValidationError>>
  postingDetails?: Maybe<NestedValidationErrors>
  recruiterInfo?: Maybe<NestedValidationErrors>
  targetGroup?: Maybe<NestedValidationErrors>
}

export type CampaignFiltersInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderReference?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
}

export type CampaignOrderItemInput = {
  jobListingIds: Array<Scalars['Int']['input']>
  productId: Scalars['String']['input']
}

export enum CampaignStatuses {
  InProgress = 'IN_PROGRESS',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
}

export type CampaignTotalPrice = {
  __typename?: 'CampaignTotalPrice'
  amount: Scalars['Int']['output']
  currency: Scalars['String']['output']
}

export type CampaignValidationErrors = {
  __typename?: 'CampaignValidationErrors'
  errors: Array<CampaignErrors>
}

export enum CanStartNewJobEnum {
  Immediately = 'IMMEDIATELY',
  NotSure = 'NOT_SURE',
  OneWeek = 'ONE_WEEK',
  TwoWeek = 'TWO_WEEK',
}

export type CancelCampaign = {
  __typename?: 'CancelCampaign'
  campaignId: Scalars['String']['output']
  campaignStatus: CampaignStatuses
}

export type CancelCampaignInput = {
  orderId: Scalars['Int']['input']
}

export type CancelCampaignOutput = {
  __typename?: 'CancelCampaignOutput'
  campaigns: Array<CancelCampaign>
  orderId: Scalars['Int']['output']
}

export type CancelScheduledReportInput = {
  id: Scalars['Int']['input']
}

export type CancelSubscriptionInput = {
  subscriptionId: Scalars['Int']['input']
}

export type CandidateApplication = {
  __typename?: 'CandidateApplication'
  ActivityLog?: Maybe<Array<ActivityLog>>
  ApplicantNotes?: Maybe<Array<ApplicantNotes>>
  CandidateApplicationNote?: Maybe<Array<CandidateApplicationNote>>
  CandidateApplicationRating?: Maybe<Array<CandidateApplicationRating>>
  CandidateInterview?: Maybe<Array<CandidateInterview>>
  CandidateMessage?: Maybe<CandidateProfile>
  CandidateProfile?: Maybe<CandidateProfile>
  CandidateScorecard?: Maybe<Array<CandidateScorecard>>
  Company?: Maybe<Company>
  JobListing?: Maybe<JobListing>
  LastPublicTemplateStage?: Maybe<TemplateStage>
  SchedulerLinkInvite?: Maybe<Array<SchedulerLinkInvite>>
  ScreeningQuestionAnswer?: Maybe<Array<ScreeningQuestionAnswer>>
  TemplateStage?: Maybe<TemplateStage>
  UploadedDocumentCandidateApplication?: Maybe<Array<UploadedDocumentCandidateApplication>>
  applicationCreationType?: Maybe<Scalars['String']['output']>
  applicationStatus?: Maybe<ApplicationStatusEnum>
  applicationTimestamp?: Maybe<Scalars['DateTime']['output']>
  applicationType?: Maybe<Scalars['String']['output']>
  appliedAt?: Maybe<Scalars['DateTime']['output']>
  candidateProfileId?: Maybe<Scalars['Int']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  deleted?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  interviewScheduling?: Maybe<InterviewSchedulingEnum>
  jobListingId?: Maybe<Scalars['Int']['output']>
  lastUpdatedStatusAt?: Maybe<Scalars['DateTime']['output']>
  matchScore?: Maybe<Scalars['Float']['output']>
  templateStageId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  viewedAt?: Maybe<Scalars['DateTime']['output']>
  viewedProfile?: Maybe<Scalars['Boolean']['output']>
}

export type CandidateApplicationNotCreatedOutput = {
  __typename?: 'CandidateApplicationNotCreatedOutput'
  candidateProfileId: Scalars['Int']['output']
}

export type CandidateApplicationNote = {
  __typename?: 'CandidateApplicationNote'
  candidateApplication?: Maybe<CandidateApplication>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUser?: Maybe<CompanyUser>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  privateStatus?: Maybe<Scalars['Boolean']['output']>
  userNote: UserNote
  userNoteId: Scalars['Int']['output']
}

export type CandidateApplicationRating = {
  __typename?: 'CandidateApplicationRating'
  CandidateProfile?: Maybe<CandidateProfile>
  candidateApplication?: Maybe<CandidateApplication>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  candidateProfileId?: Maybe<Scalars['Int']['output']>
  companyUser?: Maybe<CompanyUser>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  score?: Maybe<Scalars['Float']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CandidateApplicationStatus = {
  __typename?: 'CandidateApplicationStatus'
  Campaign?: Maybe<JobAdCampaign>
  CandidateApplication?: Maybe<CandidateApplication>
  JobListing: JobListing
  ScalisUser: ScalisUser
  campaignId?: Maybe<Scalars['Int']['output']>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  completedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  jobListingId: Scalars['Int']['output']
  scalisUserId: Scalars['Int']['output']
  source?: Maybe<Scalars['String']['output']>
  startedAt: Scalars['DateTime']['output']
}

export type CandidateApplicationUserNoteInput = {
  noteText: Scalars['JSON']['input']
}

export type CandidateCollaborator = {
  __typename?: 'CandidateCollaborator'
  candidateProfile?: Maybe<CandidateProfile>
  candidateProfileId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  requisition?: Maybe<Requisition>
  requisitionId?: Maybe<Scalars['Int']['output']>
  requisitionJobDescription?: Maybe<RequisitionJobDescription>
  requisitionJobDescriptionId?: Maybe<Scalars['Int']['output']>
  role?: Maybe<Role>
  roleId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CandidateFilter = {
  __typename?: 'CandidateFilter'
  companyId: Scalars['Int']['output']
  companyUserId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  filters: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type CandidateInterview = {
  __typename?: 'CandidateInterview'
  CalendarEvent?: Maybe<CalendarEvent>
  CandidateApplication?: Maybe<CandidateApplication>
  CompanyUser?: Maybe<CompanyUser>
  TemplateStage?: Maybe<TemplateStage>
  applicationId?: Maybe<Scalars['Int']['output']>
  calendarEventId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  interviewData?: Maybe<Scalars['JSON']['output']>
  stageId?: Maybe<Scalars['Int']['output']>
  status?: Maybe<CandidateInterviewStatusEnum>
}

export enum CandidateInterviewStatusEnum {
  InterviewCompleted = 'INTERVIEW_COMPLETED',
  InterviewNotScheduled = 'INTERVIEW_NOT_SCHEDULED',
  InterviewRequested = 'INTERVIEW_REQUESTED',
  InterviewResend = 'INTERVIEW_RESEND',
  InterviewScheduled = 'INTERVIEW_SCHEDULED',
}

export type CandidateMedalistDesignation = {
  __typename?: 'CandidateMedalistDesignation'
  id: Scalars['Int']['output']
  jobListing?: Maybe<JobListing>
  jobListingId?: Maybe<Scalars['Int']['output']>
  medalistDesignation?: Maybe<Scalars['String']['output']>
  profileDetailsId?: Maybe<Scalars['Int']['output']>
}

export type CandidateMedalistDesignationInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  medalistDesignation?: InputMaybe<Scalars['String']['input']>
}

export type CandidateMessage = {
  __typename?: 'CandidateMessage'
  ApplicationRejection?: Maybe<Array<ApplicationRejection>>
  candidateApplication?: Maybe<CandidateApplication>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  companyUser?: Maybe<CompanyUser>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  customMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  templateMessage?: Maybe<TemplateMessage>
  templateMessageId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CandidateOffer = {
  __typename?: 'CandidateOffer'
  CandidateApplication: CandidateApplication
  CandidateOfferVersion?: Maybe<Array<CandidateOfferVersion>>
  candidateApplicationId: Scalars['Int']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CandidateOfferCustomField = {
  __typename?: 'CandidateOfferCustomField'
  CandidateOfferVersion: CandidateOfferVersion
  OfferCustomField: OfferCustomField
  candidateOfferVersionId: Scalars['Int']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  fieldValue?: Maybe<Scalars['JSON']['output']>
  id: Scalars['Int']['output']
  offerCustomFieldId: Scalars['Int']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CandidateOfferFile = {
  __typename?: 'CandidateOfferFile'
  CandidateOfferVersion: CandidateOfferVersion
  candidateOfferVersionId: Scalars['Int']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  fileName: Scalars['String']['output']
  filePath: Scalars['String']['output']
  fileType: OfferFileTypeEnum
  id: Scalars['Int']['output']
}

export type CandidateOfferRejection = {
  __typename?: 'CandidateOfferRejection'
  candidateOfferId: Scalars['Int']['output']
  id: Scalars['Int']['output']
  reason?: Maybe<Scalars['String']['output']>
  wantsToProvideReason: Scalars['Boolean']['output']
}

export type CandidateOfferVersion = {
  __typename?: 'CandidateOfferVersion'
  CandidateOffer: CandidateOffer
  CandidateOfferCustomField?: Maybe<Array<CandidateOfferCustomField>>
  CandidateOfferFile?: Maybe<Array<CandidateOfferFile>>
  CompanyOffice?: Maybe<CompanyOffice>
  additionalCompensation?: Maybe<Scalars['JSON']['output']>
  benefits?: Maybe<Scalars['JSON']['output']>
  candidateName: Scalars['String']['output']
  candidateOfferId: Scalars['Int']['output']
  companyOfficeId?: Maybe<Scalars['Int']['output']>
  compensation: Scalars['Float']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  expirationDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  jobTitle: Scalars['String']['output']
  jobType: JobTypeEnum
  lastUpdatedStatusAt?: Maybe<Scalars['DateTime']['output']>
  manager?: Maybe<Scalars['String']['output']>
  offerStatus: OfferStatusEnum
  payType: PayTypeEnum
  startDate?: Maybe<Scalars['DateTime']['output']>
  version: Scalars['Int']['output']
}

export type CandidateProfile = {
  __typename?: 'CandidateProfile'
  CandidateApplication: Array<CandidateApplication>
  CandidateApplicationRating: Array<CandidateApplicationRating>
  CandidateCollaborator: Array<CandidateCollaborator>
  CandidateProfileNote: Array<CandidateProfileNote>
  Company?: Maybe<Company>
  ProfileDetails: ProfileDetails
  ScalisUser?: Maybe<ScalisUser>
  UploadedCandidate?: Maybe<UploadedCandidate>
  UploadedDocumentCandidateProfile: Array<UploadedDocumentCandidateProfile>
  companyId?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  profileType?: Maybe<Scalars['String']['output']>
  scalisUserId?: Maybe<Scalars['Int']['output']>
  uploadedCandidateId?: Maybe<Scalars['Int']['output']>
}

export type CandidateProfileLocation = {
  __typename?: 'CandidateProfileLocation'
  addressLine1?: Maybe<Scalars['String']['output']>
  addressLine2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  postalCode?: Maybe<Scalars['Int']['output']>
  profileDetailsId?: Maybe<Scalars['Int']['output']>
  state?: Maybe<Scalars['String']['output']>
}

export type CandidateProfileLocationInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>
  addressLine2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  postalCode?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type CandidateProfileNote = {
  __typename?: 'CandidateProfileNote'
  candidateProfile?: Maybe<CandidateProfile>
  candidateProfileId?: Maybe<Scalars['Int']['output']>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUser?: Maybe<CompanyUser>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  privateStatus?: Maybe<Scalars['Boolean']['output']>
  userNote: UserNote
  userNoteId: Scalars['Int']['output']
}

export type CandidateProfileUserNoteInput = {
  noteText: Scalars['JSON']['input']
}

export type CandidateScorecard = {
  __typename?: 'CandidateScorecard'
  CandidateApplication?: Maybe<CandidateApplication>
  CandidateScorecardAnswers: Array<CandidateScorecardAnswers>
  CompanyUser?: Maybe<CompanyUser>
  ScorecardTemplate?: Maybe<ScorecardTemplate>
  TemplateStage?: Maybe<TemplateStage>
  additionalInformation?: Maybe<Scalars['String']['output']>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  scorecardTemplateId?: Maybe<Scalars['Int']['output']>
  status?: Maybe<ScorecardStatusTypeEnum>
  templateStageId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CandidateScorecardAnswers = {
  __typename?: 'CandidateScorecardAnswers'
  CandidateScorecard?: Maybe<CandidateScorecard>
  ScorecardTemplateQuestion?: Maybe<ScorecardTemplateQuestion>
  answer?: Maybe<Scalars['JSON']['output']>
  answerPreferredStatus?: Maybe<Scalars['Boolean']['output']>
  candidateScorecardId?: Maybe<Scalars['Int']['output']>
  dateAnswer?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  overallRecommendation?: Maybe<OverallRecommendationEnum>
  scoreAnswer?: Maybe<Scalars['Int']['output']>
  scorecardTemplateQuestionId?: Maybe<Scalars['Int']['output']>
}

export type CandidateSource = {
  __typename?: 'CandidateSource'
  candidateSourceName?: Maybe<CandidateSourceName>
  companyUser?: Maybe<CompanyUser>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  creditCompanyUserId?: Maybe<Scalars['Int']['output']>
  creditExternal?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  jobListing?: Maybe<JobListing>
  jobListingId?: Maybe<Scalars['Int']['output']>
  profileDetails?: Maybe<ProfileDetails>
  profileDetailsId?: Maybe<Scalars['Int']['output']>
  sourceNameId?: Maybe<Scalars['Int']['output']>
  sourceStrategy?: Maybe<Scalars['Int']['output']>
}

export enum CandidateSourceCategoryEnum {
  Agency = 'AGENCY',
  CompanyWebsite = 'COMPANY_WEBSITE',
  DirectApplicant = 'DIRECT_APPLICANT',
  Event = 'EVENT',
  Internal = 'INTERNAL',
  Other = 'OTHER',
  Referred = 'REFERRED',
  Scalis = 'SCALIS',
  Sourced = 'SOURCED',
  ThirdParty = 'THIRD_PARTY',
  University = 'UNIVERSITY',
}

export type CandidateSourceInput = {
  creditCompanyUserId?: InputMaybe<Scalars['Int']['input']>
  creditExternal?: InputMaybe<Scalars['String']['input']>
  /** @deprecated No longer used */
  customSource?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  profileDetailsId: Scalars['Int']['input']
  publicJobListingId?: InputMaybe<Scalars['String']['input']>
  /** @deprecated Deprecated in light of new sourceName relation */
  sourceCategory?: InputMaybe<SourceCategoryEnum>
  /** @deprecated Use sourceNameId instead */
  sourceName?: InputMaybe<Scalars['String']['input']>
  sourceNameId?: InputMaybe<Scalars['Int']['input']>
  sourceStrategy?: InputMaybe<Scalars['Int']['input']>
}

export type CandidateSourceName = {
  __typename?: 'CandidateSourceName'
  candidateSource?: Maybe<Array<CandidateSource>>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  sourceCategory?: Maybe<SourceCategoryEnum>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CandidateSourceNameCreateInput = {
  name: Scalars['String']['input']
  sourceCategory: CandidateSourceCategoryEnum
}

export type CandidateSourceNameInput = {
  creditCompanyUserId?: InputMaybe<Array<Scalars['Int']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  sourceCategory?: InputMaybe<CandidateSourceCategoryEnum>
}

export type CandidateStats = {
  __typename?: 'CandidateStats'
  distance?: Maybe<Scalars['String']['output']>
  experience?: Maybe<Scalars['String']['output']>
  matchPercentage?: Maybe<Scalars['Float']['output']>
  opportunity: CandidateStatsOpportunity
  screeningQuestions?: Maybe<Scalars['String']['output']>
  skillsMatched?: Maybe<Scalars['Int']['output']>
}

export type CandidateStatsOpportunity = {
  __typename?: 'CandidateStatsOpportunity'
  applicationStatus?: Maybe<ApplicationStatusEnum>
  appliedAt: Scalars['String']['output']
  candidateApplicationId: Scalars['Int']['output']
  currentStageId: Scalars['Int']['output']
  internalRequisitionId: Scalars['Int']['output']
  jobListingId: Scalars['String']['output']
  jobListingTitle: Scalars['String']['output']
  lastUpdatedStatusAt: Scalars['String']['output']
  location: Scalars['String']['output']
  milestoneType?: Maybe<NameEnum>
  requisitionId: Scalars['String']['output']
  requisitionJobName: Scalars['String']['output']
  stageName: Scalars['String']['output']
  stages: Array<CandidateStatsOpportunityStage>
}

export type CandidateStatsOpportunityStage = {
  __typename?: 'CandidateStatsOpportunityStage'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export enum CandidateTypeEnum {
  Guest = 'GUEST',
  Jobseeker = 'JOBSEEKER',
  UploadedCandidate = 'UPLOADED_CANDIDATE',
}

export type CertificateOrLicense = {
  __typename?: 'CertificateOrLicense'
  description?: Maybe<Scalars['JSON']['output']>
  id: Scalars['Int']['output']
  issueYear?: Maybe<Scalars['Int']['output']>
  issuingAuthority?: Maybe<Scalars['String']['output']>
  jobSeekerId?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  searchDescription?: Maybe<Scalars['String']['output']>
}

export type CertificateOrLicenseInput = {
  description?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  issueYear?: InputMaybe<Scalars['Int']['input']>
  issuingAuthority?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  searchDescription?: InputMaybe<Scalars['String']['input']>
}

export type ChangePasswordInput = {
  code: Scalars['String']['input']
  password: Scalars['String']['input']
  passwordConfirmation: Scalars['String']['input']
}

export type Channel = {
  __typename?: 'Channel'
  id: Scalars['Int']['output']
  logo_rectangle_url?: Maybe<Array<Logo>>
  logo_square_url?: Maybe<Array<Logo>>
  logo_url?: Maybe<Array<LogoUrl>>
  mc_enabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  type: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ChatC2J = {
  __typename?: 'ChatC2J'
  chatId?: Maybe<Scalars['String']['output']>
  coUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  scUserId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ChatInfo = {
  __typename?: 'ChatInfo'
  chatId?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  userId?: Maybe<Scalars['Int']['output']>
}

export type ChatInfoJs = {
  __typename?: 'ChatInfoJS'
  chatId?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['Int']['output']>
}

export type ChatUserInfo = {
  __typename?: 'ChatUserInfo'
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  userId?: Maybe<Scalars['Int']['output']>
}

export type ChatUserInfoJs = {
  __typename?: 'ChatUserInfoJS'
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  userId?: Maybe<Scalars['Int']['output']>
}

export enum ClosedReasonEnum {
  NoLongerNeeded = 'NO_LONGER_NEEDED',
  OnHold = 'ON_HOLD',
  OpenedByMistake = 'OPENED_BY_MISTAKE',
}

export type Company = {
  __typename?: 'Company'
  Address: Array<Address>
  CompanyDepartment: Array<CompanyDepartment>
  CompanyInfo?: Maybe<CompanyInfo>
  CompanyOffice: Array<CompanyOffice>
  Orders: Array<Order>
  TemplateMessage?: Maybe<Array<TemplateMessage>>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  externalCompanyId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastActivity?: Maybe<Scalars['DateTime']['output']>
  subdomain?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CompanyDepartment = {
  __typename?: 'CompanyDepartment'
  ParentCompanyDepartment?: Maybe<CompanyDepartment>
  companyId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  departmentName?: Maybe<Scalars['String']['output']>
  externalId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  subordinateTo?: Maybe<Scalars['Int']['output']>
  type?: Maybe<CompanyDepartmentTypeEnum>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CompanyDepartmentInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>
  departmentName?: InputMaybe<Scalars['String']['input']>
  externalId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  subordinateTo?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<CompanyDepartmentTypeEnum>
}

export enum CompanyDepartmentTypeEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
}

export type CompanyInfo = {
  __typename?: 'CompanyInfo'
  atsNeeds?: Maybe<Scalars['String']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyType?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  currentAtsName?: Maybe<Scalars['String']['output']>
  employeeCount?: Maybe<EmployeeCountEnum>
  hearAboutUs?: Maybe<Scalars['String']['output']>
  hiringNeeds?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  industry?: Maybe<IndustryEnum>
  linkedin?: Maybe<Scalars['String']['output']>
  linkedinId?: Maybe<Scalars['String']['output']>
  logo?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  positionsToFill?: Maybe<PositionsToFillEnum>
  reasonToSwitch?: Maybe<Scalars['String']['output']>
  recruitingOrStaffing?: Maybe<Scalars['Boolean']['output']>
  subIndustry?: Maybe<SubIndustryTypeEnum>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  usingATS?: Maybe<Scalars['Boolean']['output']>
  website?: Maybe<Scalars['String']['output']>
  yearsInOperation?: Maybe<Scalars['Int']['output']>
}

export type CompanyInfoInput = {
  atsNeeds?: InputMaybe<Scalars['String']['input']>
  companyId?: InputMaybe<Scalars['Int']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companyType?: InputMaybe<Scalars['String']['input']>
  currentAtsName?: InputMaybe<Scalars['String']['input']>
  employeeCount?: InputMaybe<EmployeeCountEnum>
  hearAboutUs?: InputMaybe<Scalars['String']['input']>
  hiringNeeds?: InputMaybe<Scalars['String']['input']>
  industry?: InputMaybe<IndustryEnum>
  linkedin?: InputMaybe<Scalars['String']['input']>
  linkedinId?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  positionsToFill?: InputMaybe<PositionsToFillEnum>
  reasonToSwitch?: InputMaybe<Scalars['String']['input']>
  recruitingOrStaffing?: InputMaybe<Scalars['Boolean']['input']>
  subIndustry?: InputMaybe<SubIndustryTypeEnum>
  usingATS?: InputMaybe<Scalars['Boolean']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  yearsInOperation?: InputMaybe<Scalars['Int']['input']>
}

export type CompanyOffice = {
  __typename?: 'CompanyOffice'
  Address?: Maybe<Address>
  addressId?: Maybe<Scalars['Int']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  externalId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  officeName?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CompanyOfficeInput = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  companyId?: InputMaybe<Scalars['Int']['input']>
  externalId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  officeName?: InputMaybe<Scalars['String']['input']>
}

export type CompanySocial = {
  __typename?: 'CompanySocial'
  facebook?: Maybe<Scalars['String']['output']>
  linkedin?: Maybe<Scalars['String']['output']>
  twitter?: Maybe<Scalars['String']['output']>
}

export type CompanyType = {
  __typename?: 'CompanyType'
  id: Scalars['String']['output']
  location?: Maybe<LocationType>
  logo?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type CompanyUser = {
  __typename?: 'CompanyUser'
  CalendarSettings?: Maybe<CalendarSettings>
  CandidateMessage?: Maybe<Array<CandidateMessage>>
  TemplateMessage?: Maybe<Array<TemplateMessage>>
  UserInvitation?: Maybe<UserInvitation>
  UserRoles?: Maybe<Array<UserRoles>>
  companyId: Scalars['Int']['output']
  companyUserStatus?: Maybe<CompanyUserStatusEnum>
  id: Scalars['ID']['output']
  profileImage?: Maybe<Scalars['String']['output']>
  userInvitationId?: Maybe<Scalars['Int']['output']>
}

export type CompanyUserGroup = {
  __typename?: 'CompanyUserGroup'
  Departments: Array<CompanyUserGroupCompanyDepartment>
  Members: Array<CompanyUserGroupCompanyUser>
  Offices: Array<CompanyUserGroupCompanyOffice>
  company: Company
  companyId: Scalars['Int']['output']
  companyUserId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: CompanyUser
  deleted: Scalars['Boolean']['output']
  externalId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type CompanyUserGroupCompanyDepartment = {
  __typename?: 'CompanyUserGroupCompanyDepartment'
  CompanyDepartment: CompanyDepartment
  CompanyUserGroup: CompanyUserGroup
  companyUserGroupId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  departmentId: Scalars['Int']['output']
  id: Scalars['ID']['output']
}

export type CompanyUserGroupCompanyOffice = {
  __typename?: 'CompanyUserGroupCompanyOffice'
  CompanyOffice: CompanyOffice
  CompanyUserGroup: CompanyUserGroup
  companyUserGroupId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  officeId: Scalars['Int']['output']
}

export type CompanyUserGroupCompanyUser = {
  __typename?: 'CompanyUserGroupCompanyUser'
  CompanyUser: CompanyUser
  CompanyUserGroup: CompanyUserGroup
  companyUserGroupId: Scalars['Int']['output']
  companyUserId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
}

export enum CompanyUserStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export type CompleteInterviewInput = {
  interviewId: Scalars['Int']['input']
}

export enum ConditionTypeEnum {
  Department = 'DEPARTMENT',
  EmploymentType = 'EMPLOYMENT_TYPE',
  Office = 'OFFICE',
  PayType = 'PAY_TYPE',
}

export type ConnectCalendarInput = {
  email?: InputMaybe<Scalars['String']['input']>
  provider: CalendarProviderEnum
}

/** The provider auth URL */
export type ConnectCalendarOutput = {
  __typename?: 'ConnectCalendarOutput'
  authUrl: Scalars['String']['output']
}

export type ContactInfo = {
  __typename?: 'ContactInfo'
  id: Scalars['Int']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  scalisUserId?: Maybe<Scalars['Int']['output']>
}

export type ContactInfoInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type CoreReport = {
  __typename?: 'CoreReport'
  Company: Company
  FavoriteCoreReport: Array<FavoriteCoreReport>
  ScheduledReport: Array<ScheduledReport>
  SharedCoreReport: Array<SharedCoreReport>
  companyId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  isFavorite: Scalars['Boolean']['output']
  reportName: CoreReportNameEnum
  updatedAt: Scalars['DateTime']['output']
}

export type CoreReportCsvOutput = {
  __typename?: 'CoreReportCsvOutput'
  base64: Scalars['String']['output']
}

export type CoreReportDataOutput = {
  __typename?: 'CoreReportDataOutput'
  availableFilters?: Maybe<Array<AvailableFilterOutput>>
  coreReportId: Scalars['Int']['output']
  data: Scalars['JSON']['output']
  schema: Scalars['JSON']['output']
  total: Scalars['Int']['output']
}

export type CoreReportFilter = {
  __typename?: 'CoreReportFilter'
  CompanyUser: CompanyUser
  CoreReport: CoreReport
  companyUserId: Scalars['Int']['output']
  coreReportId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  filters: Scalars['JSON']['output']
  id: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export enum CoreReportFilterTypeEnum {
  Date = 'DATE',
  Text = 'TEXT',
}

export enum CoreReportNameEnum {
  CandidateSummary = 'CANDIDATE_SUMMARY',
  EeocReport = 'EEOC_REPORT',
  JobOpeningsReport = 'JOB_OPENINGS_REPORT',
  JobStatusReport = 'JOB_STATUS_REPORT',
  MilestoneMetrics = 'MILESTONE_METRICS',
  OfferDetailsReport = 'OFFER_DETAILS_REPORT',
  PipelineMetrics = 'PIPELINE_METRICS',
  SourceQualityReport = 'SOURCE_QUALITY_REPORT',
}

export type CountCampaignView = {
  __typename?: 'CountCampaignView'
  message: Scalars['String']['output']
  viewCount: Scalars['Int']['output']
}

export type CountCampaignViewInput = {
  campaign: Scalars['String']['input']
  source: Scalars['String']['input']
}

export type CreateApplicantNotesInput = {
  candidateApplicationId: Scalars['Int']['input']
  text: Scalars['String']['input']
}

export type CreateBookmarkedJobInput = {
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  publicListingId?: InputMaybe<Scalars['String']['input']>
}

export type CreateCalendarEventCandidateInput = {
  email: Scalars['String']['input']
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  type: CandidateTypeEnum
}

export type CreateCalendarEventInput = {
  /** Calendar id from Scalis */
  calendarId: Scalars['Int']['input']
  candidates?: InputMaybe<Array<CreateCalendarEventCandidateInput>>
  /** Whether or not a meeting should be created. Defaults to false. */
  createConference?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** Workspace/Company employees that will be participating the event. Previously known as internalHrAssociates */
  internalEmployees?: InputMaybe<Array<CreateCalendarEventInternalEmployeesInput>>
  isScalisEvent: Scalars['Boolean']['input']
  location?: InputMaybe<Scalars['String']['input']>
  regardingCandidates?: InputMaybe<Array<CreateCalendarEventRegardingCandidatesInput>>
  regardingJobs?: InputMaybe<Array<Scalars['Int']['input']>>
  reminders?: InputMaybe<CreateCalendarEventRemindersInput>
  repeats?: InputMaybe<RepeatsEnum>
  title: Scalars['String']['input']
  type: EventTypeEnum
  when: CreateCalendarEventWhenInput
}

export type CreateCalendarEventInternalEmployeesInput = {
  email: Scalars['String']['input']
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type CreateCalendarEventRegardingCandidatesInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  type: CandidateTypeEnum
}

export type CreateCalendarEventRemindersInput = {
  overrides?: InputMaybe<Array<CreateCalendarEventRemindersOverridesInput>>
  useDefault: Scalars['Boolean']['input']
}

export type CreateCalendarEventRemindersOverridesInput = {
  reminderMethod: ReminderMethodEnum
  reminderMinutes: Scalars['Int']['input']
}

export type CreateCalendarEventWhenInput = {
  endTime: Scalars['String']['input']
  startTime: Scalars['String']['input']
  timezone: Scalars['String']['input']
}

export type CreateCandidateApplicationFromEmployerInput = {
  candidateProfileId: Scalars['Int']['input']
  invite: Scalars['Boolean']['input']
  jobListingId: Scalars['Int']['input']
  message?: InputMaybe<Scalars['String']['input']>
}

export type CreateCandidateApplicationFromJobSeekerInput = {
  campaignId?: InputMaybe<Scalars['String']['input']>
  publicJobListingId: Scalars['String']['input']
  screeningQuestionsAnswers?: InputMaybe<Array<ScreeningQuestionsAnswersInput>>
  source?: InputMaybe<Scalars['String']['input']>
  sourceNameId?: InputMaybe<Scalars['Int']['input']>
}

export type CreateCandidateFilterInput = {
  filters: Scalars['JSON']['input']
  name: Scalars['String']['input']
}

export type CreateCandidateOfferFileInput = {
  candidateApplicationId: Scalars['Int']['input']
  candidateOfferVersionId: Scalars['Int']['input']
  documentName: Scalars['String']['input']
  htmlTemplate: Scalars['String']['input']
}

export type CreateCandidateOfferRejectionInput = {
  candidateOfferId: Scalars['Int']['input']
  reason?: InputMaybe<Scalars['String']['input']>
  wantsToProvideReason: Scalars['Boolean']['input']
}

export type CreateChatC2JInput = {
  chatId?: InputMaybe<Scalars['String']['input']>
  coUser: Scalars['Int']['input']
  scUser: Scalars['Int']['input']
}

export type CreateChatV2Input = {
  chatId?: InputMaybe<Scalars['String']['input']>
  coUserId: Scalars['Int']['input']
  scUserId: Scalars['Int']['input']
}

export type CreateCompanyInput = {
  externalCompanyId?: InputMaybe<Scalars['String']['input']>
  subdomain: Scalars['String']['input']
}

export type CreateCompanyUserFromInvitationInput = {
  companyId: Scalars['Int']['input']
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  verificationCode: Scalars['String']['input']
}

export type CreateCompanyUserGroupInput = {
  companyId: Scalars['Int']['input']
  companyUserId: Scalars['Int']['input']
  departmentIds?: InputMaybe<Array<Scalars['Int']['input']>>
  externalId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type CreateDocumentTemplateInput = {
  conditions?: InputMaybe<DocumentTemplateConditions>
  documentTemplateName: Scalars['String']['input']
  documentTemplateText: Scalars['String']['input']
  documentType: OfferDocumentTemplateTypeEnum
}

export type CreateInterviewEventWhenInput = {
  endTime: Scalars['String']['input']
  startTime: Scalars['String']['input']
  timezone: Scalars['String']['input']
}

export type CreateInterviewInput = {
  applicationId: Scalars['Int']['input']
  calendarId: Scalars['Int']['input']
  createConference?: InputMaybe<Scalars['Boolean']['input']>
  details?: InputMaybe<Scalars['String']['input']>
  interviewers: Array<Scalars['Int']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  scorecardTemplateId?: InputMaybe<Scalars['Int']['input']>
  stageId: Scalars['Int']['input']
  when: CreateInterviewEventWhenInput
}

export type CreateJobSeekerAccountInput = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type CreateScalisUserUploadedDocumentInput = {
  documentName: Scalars['String']['input']
  documentPath: Scalars['String']['input']
  documentType?: InputMaybe<DocumentTypeEnum>
}

export type CreateScalisUserUploadedDocumentOutput = {
  __typename?: 'CreateScalisUserUploadedDocumentOutput'
  documentName: Scalars['String']['output']
  documentPath: Scalars['String']['output']
  documentType: DocumentTypeEnum
  id: Scalars['Int']['output']
}

export type CreateScheduledReportInput = {
  coreReportId: Scalars['Int']['input']
  customDaysOfWeek?: InputMaybe<Array<RepeatDaysOfWeekEnum>>
  customTime?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  recipients: Array<Scalars['String']['input']>
  repeat?: InputMaybe<RepeatTypeEnum>
  reportSchedulerType: ReportSchedulerTypeEnum
  sendImmediately?: InputMaybe<Scalars['Boolean']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type CreateSchedulerLinkInviteCandidateInput = {
  id: Scalars['Int']['input']
  type: CandidateTypeEnum
}

export type CreateSchedulerLinkInviteInput = {
  applicationId: Scalars['Int']['input']
  calendarId: Scalars['Int']['input']
  candidates?: InputMaybe<Array<CreateSchedulerLinkInviteCandidateInput>>
  durationMinutes: Scalars['Int']['input']
  interviewers: Array<Scalars['Int']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  stageId?: InputMaybe<Scalars['Int']['input']>
  templateMessageId?: InputMaybe<Scalars['Int']['input']>
}

export type CreateSchedulerLinkSessionInput = {
  configuration_id: Scalars['String']['input']
  time_to_live: Scalars['Int']['input']
}

export type CreateSchedulerLinkSettingsInput = {
  maxInterviewsPerDay: Scalars['Int']['input']
  minimumHour: Scalars['Int']['input']
  rollingDays: Scalars['Int']['input']
}

export type CreateUploadedCandidateInput = {
  creditCompanyUserId?: InputMaybe<Scalars['Int']['input']>
  documents?: InputMaybe<Array<Scalars['String']['input']>>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  lastName: Scalars['String']['input']
  linkedinProfile?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  sourceNameId: Scalars['Int']['input']
  sourceStrategy?: InputMaybe<Scalars['Int']['input']>
}

export type CreateUploadedDocumentCandidateApplicationInput = {
  UploadedDocument: CreateUploadedDocumentInput
  candidateApplicationId: Scalars['Int']['input']
  companyUserId?: InputMaybe<Scalars['Int']['input']>
}

export type CreateUploadedDocumentCandidateProfileInput = {
  UploadedDocument: CreateUploadedDocumentInput
  candidateProfileId: Scalars['Int']['input']
  companyUserId?: InputMaybe<Scalars['Int']['input']>
}

export type CreateUploadedDocumentInput = {
  documentName?: InputMaybe<Scalars['String']['input']>
  documentPath: Scalars['String']['input']
}

export type CreateUsersInCompanyUserGroupInput = {
  companyId: Scalars['Int']['input']
  companyUserGroupId: Scalars['Int']['input']
  companyUserIds: Array<Scalars['Int']['input']>
}

export enum CurrencyEnum {
  Aud = 'AUD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export enum CurrentStageEnum {
  BasicInformation = 'BASIC_INFORMATION',
  EmailVerification = 'EMAIL_VERIFICATION',
  Profile = 'PROFILE',
  ReviewProfile = 'REVIEW_PROFILE',
  ScreeningQuestions = 'SCREENING_QUESTIONS',
  UploadResume = 'UPLOAD_RESUME',
}

export enum CustomFieldInputTypeEnum {
  Currency = 'CURRENCY',
  Date = 'DATE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  Number = 'NUMBER',
  Text = 'TEXT',
}

export type DateTimeRangeInput = {
  endDate: Scalars['DateTime']['input']
  startDate: Scalars['DateTime']['input']
}

export type DefaultToken = {
  __typename?: 'DefaultToken'
  categoryName: TokenCategoryNameEnum
  columnName?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  isAggregatedToken: Scalars['Boolean']['output']
  tableName?: Maybe<Scalars['String']['output']>
  tokenName: TokenNameEnum
  tokenType: Array<DefaultTokenTypeEnum>
}

export enum DefaultTokenTypeEnum {
  Email = 'EMAIL',
  Offer = 'OFFER',
}

export type DeleteAchievementInput = {
  achievementId: Scalars['Int']['input']
}

export type DeleteApplicantNotesInput = {
  id: Scalars['Int']['input']
}

export type DeleteApplicationFormInput = {
  id: Scalars['Int']['input']
}

export type DeleteApplicationFormScreeningQuestionInput = {
  id: Scalars['Int']['input']
}

export type DeleteBookmarkedJobInput = {
  publicListingId: Scalars['String']['input']
}

export type DeleteCalendarEventInput = {
  eventId: Scalars['Int']['input']
}

export type DeleteCandidateApplicationInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateApplicationNoteInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateCollaboratorInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateFilterInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateMedalistDesignationInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateMessageInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateOfferCustomFieldInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateOfferFileInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateOfferInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateOfferVersionInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateProfileLocationInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateProfileNoteInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateScorecardInput = {
  id: Scalars['Int']['input']
}

export type DeleteCandidateSourceInput = {
  id: Scalars['Int']['input']
}

export type DeleteCertificateOrLicenseInput = {
  certificateOrLicenseId: Scalars['Int']['input']
}

export type DeleteCompanyDepartmentInput = {
  id: Scalars['Int']['input']
}

export type DeleteCompanyOfficeInput = {
  id: Scalars['Int']['input']
}

export type DeleteCompanyUserFromWorkspaceInput = {
  companyUserId: Scalars['Int']['input']
}

export type DeleteCompanyUserGroupInput = {
  companyId: Scalars['Int']['input']
  companyUserGroupId: Scalars['Int']['input']
}

export type DeleteEducationInput = {
  educationId: Scalars['Int']['input']
}

export type DeleteExperienceInput = {
  experienceId: Scalars['Int']['input']
}

export type DeleteExternalApplicationInput = {
  applicationId: Scalars['Int']['input']
}

export type DeleteExternalJobApplicationResponse = {
  __typename?: 'DeleteExternalJobApplicationResponse'
  message: Scalars['String']['output']
}

export type DeleteGlobalScreeningQuestionInput = {
  id: Scalars['Int']['input']
}

export type DeleteHiringTeamMemberInput = {
  id: Scalars['Int']['input']
}

export type DeleteInterviewInput = {
  interviewId: Scalars['Int']['input']
}

export type DeleteInterviewTeamInput = {
  id: Scalars['Int']['input']
}

export type DeleteInvitationFromWorkspaceInput = {
  invitationId: Scalars['Int']['input']
}

export type DeleteJobListingInput = {
  id: Scalars['Int']['input']
}

export type DeleteJobListingScreeningQuestionInput = {
  id: Scalars['Int']['input']
}

export type DeleteJobOpeningInput = {
  id: Scalars['Int']['input']
}

export type DeleteJobPipelineInput = {
  id: Scalars['Int']['input']
}

export type DeleteJobSeekerSkillInput = {
  skillId: Scalars['Int']['input']
}

/** Input type for deleting an offer custom field */
export type DeleteOfferCustomFieldInput = {
  /** Unique identifier of the offer custom field to delete */
  id: Scalars['Int']['input']
}

export type DeleteOfferDocumentTemplateInput = {
  id: Scalars['Int']['input']
}

export type DeleteOfferDocumentTemplateSectionInput = {
  offerDocumentTemplateId: Scalars['Int']['input']
  offerSectionTemplateId: Scalars['Int']['input']
}

export type DeleteOfferSectionTemplateInput = {
  id: Scalars['Int']['input']
}

export type DeleteOfferTemplateConditionInput = {
  id: Scalars['Int']['input']
}

export type DeleteRequisitionInput = {
  id: Scalars['Int']['input']
}

export type DeleteRequisitionJobDescriptionInput = {
  id: Scalars['Int']['input']
}

export type DeleteRequisitionSkillInput = {
  id: Scalars['Int']['input']
}

export type DeleteScalisUserUploadedDocumentInput = {
  id: Scalars['Int']['input']
}

export type DeleteScalisUserUploadedDocumentOutput = {
  __typename?: 'DeleteScalisUserUploadedDocumentOutput'
  success: Scalars['Boolean']['output']
}

export type DeleteSchedulerLinkInviteInput = {
  inviteId: Scalars['Int']['input']
}

export type DeleteSchedulerLinkSettingsInput = {
  id: Scalars['Int']['input']
}

export type DeleteScorecardTemplateInput = {
  id: Scalars['Int']['input']
}

export type DeleteScorecardTemplateQuestionInput = {
  id: Scalars['Int']['input']
}

export type DeleteScorecardTemplateSectionInput = {
  id: Scalars['Int']['input']
}

export type DeleteScreeningQuestionInput = {
  id: Scalars['Int']['input']
}

export type DeleteSkillInput = {
  skillId: Scalars['Int']['input']
}

export type DeleteStageCollaboratorInput = {
  id: Scalars['Int']['input']
}

export type DeleteStageSettingsInput = {
  id: Scalars['Int']['input']
}

export type DeleteTemplateJobDescriptionInput = {
  id: Scalars['Int']['input']
}

export type DeleteTemplateMessageInput = {
  id: Scalars['Int']['input']
}

export type DeleteTemplateMilestoneInput = {
  id: Scalars['Int']['input']
}

export type DeleteTemplatePipelineInput = {
  id: Scalars['Int']['input']
}

export type DeleteTemplateStageInput = {
  id: Scalars['Int']['input']
}

export type DeleteTokenTestResultInput = {
  id: Scalars['Int']['input']
}

export type DeleteUploadedDocumentCandidateApplicationInput = {
  id: Scalars['Int']['input']
}

export type DeleteUploadedDocumentCandidateProfileInput = {
  id: Scalars['Int']['input']
}

export type DeleteUploadedDocumentInput = {
  id: Scalars['Int']['input']
}

export type DeleteUsersFromCompanyUserGroupInput = {
  companyId: Scalars['Int']['input']
  companyUserGroupId: Scalars['Int']['input']
  companyUserIds: Array<Scalars['Int']['input']>
}

export type DeleteVolunteerWorkInput = {
  volunteerWorkId: Scalars['Int']['input']
}

export type DemographicInfo = {
  __typename?: 'DemographicInfo'
  gender?: Maybe<GenderEnum>
  id: Scalars['Int']['output']
  jobSeekerId?: Maybe<Scalars['Int']['output']>
  raceEthnicity?: Maybe<Scalars['String']['output']>
  veteranStatus?: Maybe<VeteranStatusEnum>
  workHistory?: Maybe<Scalars['Boolean']['output']>
}

export type DemographicInfoInput = {
  gender?: InputMaybe<GenderEnum>
  raceEthnicity?: InputMaybe<Scalars['String']['input']>
  veteranStatus?: InputMaybe<VeteranStatusEnum>
  workHistory?: InputMaybe<Scalars['Boolean']['input']>
}

export type DisconnectCalendarInput = {
  email: Scalars['String']['input']
}

export type DisconnectCalendarOutput = {
  __typename?: 'DisconnectCalendarOutput'
  success: Scalars['Boolean']['output']
}

export type DocumentTemplateConditions = {
  departments?: InputMaybe<Array<Scalars['Int']['input']>>
  employmentType?: InputMaybe<Array<JobTypeEnum>>
  offices?: InputMaybe<Array<Scalars['Int']['input']>>
  payType?: InputMaybe<Array<PayTypeEnum>>
}

export enum DocumentTypeEnum {
  OfferLetter = 'OFFER_LETTER',
  Other = 'OTHER',
  Resume = 'RESUME',
}

export type DownloadDocumentTemplateInput = {
  id: Scalars['Int']['input']
}

export type DownloadDocumentTemplateOutput = {
  __typename?: 'DownloadDocumentTemplateOutput'
  temporaryUrl: Scalars['String']['output']
}

export type Duration = {
  __typename?: 'Duration'
  period: Scalars['Int']['output']
  range: Scalars['String']['output']
}

export type EditCalendarEventCandidateInput = {
  email: Scalars['String']['input']
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  type: CandidateTypeEnum
}

export type EditCalendarEventInput = {
  /** External calendar event id from Nylas */
  calendarEventId: Scalars['String']['input']
  /** Calendar id from Scalis */
  calendarId: Scalars['Int']['input']
  candidates?: InputMaybe<Array<EditCalendarEventCandidateInput>>
  /** Whether or not a meeting should be created. Defaults to false. */
  createConference?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** Event id from Scalis */
  eventId: Scalars['Int']['input']
  existingConferencing?: InputMaybe<Scalars['JSON']['input']>
  /** Workspace/Company employees that will be participating the event. Previously known as internalHrAssociates */
  internalEmployees?: InputMaybe<Array<EditCalendarEventInternalEmployeesInput>>
  isScalisEvent: Scalars['Boolean']['input']
  location?: InputMaybe<Scalars['String']['input']>
  regardingCandidates?: InputMaybe<Array<EditCalendarEventRegardingCandidatesInput>>
  regardingJobs?: InputMaybe<Array<Scalars['Int']['input']>>
  reminders?: InputMaybe<EditCalendarEventRemindersInput>
  repeats?: InputMaybe<RepeatsEnum>
  title: Scalars['String']['input']
  type: EventTypeEnum
  when: EditCalendarEventWhenInput
}

export type EditCalendarEventInternalEmployeesInput = {
  email: Scalars['String']['input']
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type EditCalendarEventRegardingCandidatesInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  type: CandidateTypeEnum
}

export type EditCalendarEventRemindersInput = {
  overrides?: InputMaybe<Array<EditCalendarEventRemindersOverridesInput>>
  useDefault: Scalars['Boolean']['input']
}

export type EditCalendarEventRemindersOverridesInput = {
  reminderMethod: ReminderMethodEnum
  reminderMinutes: Scalars['Int']['input']
}

export type EditCalendarEventWhenInput = {
  endTime: Scalars['String']['input']
  startTime: Scalars['String']['input']
  timezone: Scalars['String']['input']
}

export type EditCalendarSettingsInput = {
  availability?: InputMaybe<Scalars['String']['input']>
  hourDisplay?: InputMaybe<Scalars['Int']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type EditGlobalScreeningQuestionInput = {
  ansOptions?: InputMaybe<Scalars['String']['input']>
  globalQuestionOrder?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['Int']['input']
  isActiveGlobally?: InputMaybe<Scalars['Boolean']['input']>
  isRejectionQuestion?: InputMaybe<Scalars['Boolean']['input']>
  maxVal?: InputMaybe<Scalars['Int']['input']>
  minVal?: InputMaybe<Scalars['Int']['input']>
  preferredAnswer?: InputMaybe<Scalars['String']['input']>
  questionDesc?: InputMaybe<Scalars['String']['input']>
  questionTitle?: InputMaybe<Scalars['String']['input']>
  questionType?: InputMaybe<QuestionTypeEnum>
  rejectMessage?: InputMaybe<Scalars['String']['input']>
  valStatus?: InputMaybe<Scalars['String']['input']>
}

export type Education = {
  __typename?: 'Education'
  additionalInformation?: Maybe<Scalars['String']['output']>
  areasOfStudy?: Maybe<Scalars['JSON']['output']>
  cumGPA?: Maybe<Scalars['Float']['output']>
  cumGPAScale?: Maybe<Scalars['Float']['output']>
  currentlyStudying?: Maybe<Scalars['Boolean']['output']>
  departmentCumGPA?: Maybe<Scalars['Float']['output']>
  departmentCumGPAScale?: Maybe<Scalars['Float']['output']>
  educationLevel?: Maybe<EducationLevelEnum>
  endDate?: Maybe<Scalars['DateTime']['output']>
  hideCumGPA?: Maybe<Scalars['Boolean']['output']>
  hideDepartmentGPA?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['Int']['output']
  institutionName?: Maybe<Scalars['String']['output']>
  jobSeekerId?: Maybe<Scalars['Int']['output']>
  majors?: Maybe<Scalars['JSON']['output']>
  minors?: Maybe<Scalars['JSON']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
}

export type EducationInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  areasOfStudy?: InputMaybe<Scalars['JSON']['input']>
  cumGPA?: InputMaybe<Scalars['Float']['input']>
  cumGPAScale?: InputMaybe<Scalars['Float']['input']>
  currentlyStudying?: InputMaybe<Scalars['Boolean']['input']>
  departmentCumGPA?: InputMaybe<Scalars['Float']['input']>
  departmentCumGPAScale?: InputMaybe<Scalars['Float']['input']>
  educationLevel?: InputMaybe<EducationLevelEnum>
  endDate?: InputMaybe<Scalars['String']['input']>
  hideCumGPA?: InputMaybe<Scalars['Boolean']['input']>
  hideDepartmentGPA?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  institutionName?: InputMaybe<Scalars['String']['input']>
  majors?: InputMaybe<Scalars['JSON']['input']>
  minors?: InputMaybe<Scalars['JSON']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export enum EducationLevelEnum {
  AdvancedCertificate = 'ADVANCED_CERTIFICATE',
  Associates = 'ASSOCIATES',
  Bachelors = 'BACHELORS',
  Certificate = 'CERTIFICATE',
  Doctorate = 'DOCTORATE',
  HighSchoolDegree = 'HIGH_SCHOOL_DEGREE',
  Masters = 'MASTERS',
  NonDegreeSeeking = 'NON_DEGREE_SEEKING',
  PostdoctoralStudies = 'POSTDOCTORAL_STUDIES',
  TechnicalDiploma = 'TECHNICAL_DIPLOMA',
}

export enum EducationLevelPrefEnum {
  AdvancedCertificate = 'ADVANCED_CERTIFICATE',
  Associates = 'ASSOCIATES',
  Bachelors = 'BACHELORS',
  Certificate = 'CERTIFICATE',
  Doctorate = 'DOCTORATE',
  GraduateDegree = 'GRADUATE_DEGREE',
  HighSchool = 'HIGH_SCHOOL',
  Masters = 'MASTERS',
  None = 'NONE',
  NonDegreeSeeking = 'NON_DEGREE_SEEKING',
  PostdoctoralStudies = 'POSTDOCTORAL_STUDIES',
  TechnicalDiploma = 'TECHNICAL_DIPLOMA',
  UndergraduateDegree = 'UNDERGRADUATE_DEGREE',
}

export type EmailVerification = {
  __typename?: 'EmailVerification'
  activeStatus?: Maybe<Scalars['Boolean']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  emailAddress?: Maybe<Scalars['String']['output']>
  emailVerified?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['Int']['output']
  isPrimary?: Maybe<Scalars['Boolean']['output']>
  isVisibleToEmployers?: Maybe<Scalars['Boolean']['output']>
  scalisUserId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum EmployeeCountEnum {
  Count_1To_3 = 'COUNT_1_TO_3',
  Count_3To_5 = 'COUNT_3_TO_5',
  Count_6To_10 = 'COUNT_6_TO_10',
  Count_11To_20 = 'COUNT_11_TO_20',
  Count_20To_100 = 'COUNT_20_TO_100',
  Count_100To_500 = 'COUNT_100_TO_500',
  Count_500Plus = 'COUNT_500_PLUS',
}

export enum EmploymentTypeEnum {
  FixedTerm = 'fixed_term',
  FixedTermWithOptionForPermanent = 'fixed_term_with_option_for_permanent',
  Freelance = 'freelance',
  Internship = 'internship',
  Permanent = 'permanent',
  Temporary = 'temporary',
  Traineeship = 'traineeship',
}

export type EventAttachment = {
  __typename?: 'EventAttachment'
  CalendarEvent: CalendarEvent
  eventId: Scalars['Int']['output']
  file?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type EventJob = {
  __typename?: 'EventJob'
  CalendarEvent: CalendarEvent
  JobListing: JobListing
  calendarEventId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  jobId: Scalars['Int']['output']
}

export enum EventTypeEnum {
  AllHands = 'ALL__HANDS',
  Department = 'DEPARTMENT',
  Huddle = 'HUDDLE',
  InternalCandidate = 'INTERNAL_CANDIDATE',
  Meeting = 'MEETING',
  OneOnOne = 'ONE__ON__ONE',
  PanelReview = 'PANEL_REVIEW',
  Review = 'REVIEW',
  StrategyMeeting = 'STRATEGY_MEETING',
}

export type ExchangeNylasTokenInput = {
  code: Scalars['String']['input']
}

export type Experience = {
  __typename?: 'Experience'
  Skill?: Maybe<Array<Skill>>
  additionalComp?: Maybe<Scalars['Boolean']['output']>
  description?: Maybe<Scalars['String']['output']>
  employerName?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  industry?: Maybe<IndustryEnum>
  isCurrentJob?: Maybe<Scalars['Boolean']['output']>
  isCurrentlyWorking?: Maybe<Scalars['Boolean']['output']>
  jobPay?: Maybe<Scalars['Float']['output']>
  jobSeekerId?: Maybe<Scalars['Int']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  jobType?: Maybe<JobTypeEnum>
  location?: Maybe<Scalars['String']['output']>
  locationType?: Maybe<Scalars['String']['output']>
  longFormDesc?: Maybe<Scalars['String']['output']>
  managementPosition?: Maybe<Scalars['Boolean']['output']>
  payType?: Maybe<Scalars['String']['output']>
  searchLongFormDesc?: Maybe<Scalars['String']['output']>
  searchShortFormDesc?: Maybe<Scalars['String']['output']>
  shortFormDesc?: Maybe<Scalars['JSON']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
  subIndustry?: Maybe<Scalars['String']['output']>
  teamSize?: Maybe<TeamSizeEnum>
  workPlaceCandidate?: Maybe<WorkPlaceCandidateEnum>
}

export type ExperienceInput = {
  additionalComp?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  employerName?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  industry?: InputMaybe<IndustryEnum>
  isCurrentJob?: InputMaybe<Scalars['Boolean']['input']>
  isCurrentlyWorking?: InputMaybe<Scalars['Boolean']['input']>
  jobPay?: InputMaybe<Scalars['Float']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  jobType?: InputMaybe<JobTypeEnum>
  location?: InputMaybe<Scalars['String']['input']>
  locationType?: InputMaybe<Scalars['String']['input']>
  longFormDesc?: InputMaybe<Scalars['String']['input']>
  managementPosition?: InputMaybe<Scalars['Boolean']['input']>
  payType?: InputMaybe<PayTypeEnum>
  searchLongFormDesc?: InputMaybe<Scalars['String']['input']>
  searchShortFormDesc?: InputMaybe<Scalars['String']['input']>
  shortFormDesc?: InputMaybe<Scalars['JSON']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  subIndustry?: InputMaybe<Scalars['String']['input']>
  teamSize?: InputMaybe<TeamSizeEnum>
  workPlaceCandidate?: InputMaybe<WorkPlaceCandidateEnum>
}

export type ExternalApplicationInput = {
  jobId: Scalars['String']['input']
  status?: InputMaybe<ExternalApplicationStatus>
}

export enum ExternalApplicationStatus {
  ApplicationReview = 'APPLICATION_REVIEW',
  Hired = 'HIRED',
  Interviews = 'INTERVIEWS',
  Offer = 'OFFER',
  PreScreen = 'PRE_SCREEN',
}

export type ExternalCompany = {
  __typename?: 'ExternalCompany'
  image?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  shortDescription?: Maybe<Scalars['String']['output']>
  social?: Maybe<CompanySocial>
  uuid: Scalars['String']['output']
  website?: Maybe<Scalars['String']['output']>
}

export type ExternalJob = {
  __typename?: 'ExternalJob'
  company: ExternalCompany
  companyName: Scalars['String']['output']
  descriptionHtml: Scalars['String']['output']
  employmentType?: Maybe<Scalars['String']['output']>
  expirationDate: Scalars['String']['output']
  extractedAt: Scalars['String']['output']
  h1bSponsorship: Scalars['Boolean']['output']
  jobDescription: Scalars['String']['output']
  jobKey: Scalars['String']['output']
  location?: Maybe<ExternalJobLocation>
  salaryRange?: Maybe<SalaryRange>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
  workLevel?: Maybe<JobLevel>
  workplaceType?: Maybe<WorkplaceType>
}

export type ExternalJobApplication = {
  __typename?: 'ExternalJobApplication'
  appliedAt?: Maybe<Scalars['DateTime']['output']>
  employerName: Scalars['String']['output']
  id: Scalars['ID']['output']
  jobId: Scalars['String']['output']
  jobTitle: Scalars['String']['output']
  jobUrl: Scalars['String']['output']
  location: Scalars['String']['output']
  status?: Maybe<ExternalApplicationStatus>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ExternalJobLocation = {
  __typename?: 'ExternalJobLocation'
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  state: Scalars['String']['output']
}

export type FavoriteCoreReport = {
  __typename?: 'FavoriteCoreReport'
  CompanyUser: CompanyUser
  CoreReport: CoreReport
  companyUserId: Scalars['Int']['output']
  coreReportId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type FavoriteReportInput = {
  coreReportId: Scalars['Int']['input']
}

export enum FilterContentTypeEnum {
  CandidateNames = 'candidateNames',
  Companies = 'companies',
  CreditedCompanyUser = 'creditedCompanyUser',
  Degrees = 'degrees',
  Emails = 'emails',
  JobTitles = 'jobTitles',
  Majors = 'majors',
  Schools = 'schools',
  Skills = 'skills',
}

export type FilterCriteriaInput = {
  column: Scalars['String']['input']
  dateTimeRange?: InputMaybe<DateTimeRangeInput>
  type: CoreReportFilterTypeEnum
  values?: InputMaybe<Array<Scalars['String']['input']>>
}

export type FilterReferralsInput = {
  referredBy?: InputMaybe<Scalars['Int']['input']>
  referredTo?: InputMaybe<Scalars['Int']['input']>
}

export type FilterValueType = {
  __typename?: 'FilterValueType'
  count: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export type FiltersType = {
  __typename?: 'FiltersType'
  key: Scalars['String']['output']
  values: Array<FilterValueType>
}

export enum GenderEnum {
  DeclineToSelfIdentify = 'DECLINE_TO_SELF_IDENTIFY',
  Female = 'FEMALE',
  Male = 'MALE',
  NonBinary = 'NON_BINARY',
}

export type GenerateFilesFromHtmlInput = {
  html: Scalars['String']['input']
}

export type GenerateFilesFromHtmlOutput = {
  __typename?: 'GenerateFilesFromHtmlOutput'
  docxKey: Scalars['String']['output']
  pdfKey: Scalars['String']['output']
}

export type GetApplicationFormInput = {
  id: Scalars['Int']['input']
}

export type GetCampaignInput = {
  id: Scalars['String']['input']
}

export type GetCandidateOfferCustomFieldInput = {
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  candidateOfferVersionId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type GetCandidateOfferFileInput = {
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  candidateOfferVersionId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type GetCandidateOfferFileUrlInput = {
  candidateOfferVersionId: Scalars['Int']['input']
}

export type GetCandidateOfferFileUrlOutput = {
  __typename?: 'GetCandidateOfferFileUrlOutput'
  fileName: Scalars['String']['output']
  fileType: OfferFileTypeEnum
  url: Scalars['String']['output']
}

export type GetCandidateOfferInput = {
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type GetCandidateOfferVersionEmbeddedClaimUrlInput = {
  candidateOfferVersionId: Scalars['Int']['input']
  signers: Array<SignatureRequestSignersInput>
  subject: Scalars['String']['input']
}

export type GetCandidateOfferVersionEmbeddedClaimUrlResult = {
  __typename?: 'GetCandidateOfferVersionEmbeddedClaimUrlResult'
  claimUrl?: Maybe<Scalars['String']['output']>
  clientId?: Maybe<Scalars['String']['output']>
  signatureRequestId?: Maybe<Scalars['String']['output']>
}

export type GetCandidateOfferVersionInput = {
  candidateOfferId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  version?: InputMaybe<Scalars['Int']['input']>
}

export type GetCandidateScorecardInput = {
  candidateApplicationIds?: InputMaybe<Array<Scalars['Int']['input']>>
  candidateProfileIds?: InputMaybe<Array<Scalars['Int']['input']>>
  ids?: InputMaybe<Array<Scalars['Int']['input']>>
  jobListingIds?: InputMaybe<Array<Scalars['Int']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  publicListingIds?: InputMaybe<Array<Scalars['String']['input']>>
  sortBy?: InputMaybe<SortByCandidateScorecard>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<Array<ScorecardStatusTypeEnum>>
}

export type GetDefaultTokenInput = {
  categoryName?: InputMaybe<TokenCategoryNameEnum>
  id?: InputMaybe<Scalars['Int']['input']>
  isAggregatedToken?: InputMaybe<Scalars['Boolean']['input']>
  tokenName?: InputMaybe<TokenNameEnum>
  tokenType?: InputMaybe<Array<DefaultTokenTypeEnum>>
}

export type GetExternalJobListingInput = {
  id: Scalars['String']['input']
}

export type GetGlobalScreeningQuestionInput = {
  id: Scalars['Int']['input']
  showReplaced?: InputMaybe<Scalars['Boolean']['input']>
}

export type GetManySchedulerLinkSettingsInput = {
  companyUserIds: Array<Scalars['Int']['input']>
}

/** Input type for querying offer custom fields */
export type GetOfferCustomFieldInput = {
  /** ID of the candidate application to filter conditions that should be applied */
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  /** Types of conditions to filter by */
  conditionType?: InputMaybe<Array<ConditionTypeEnum>>
  /** ID of the department to filter by */
  departmentId?: InputMaybe<Scalars['Int']['input']>
  /** Type of employment to filter by */
  employmentType?: InputMaybe<JobTypeEnum>
  /** Type of input field (e.g., text, number, select) */
  fieldConfigurationType?: InputMaybe<CustomFieldInputTypeEnum>
  /** Flag indicating if the field is required */
  fieldRequired?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag indicating if a system token should be generated */
  generateSystemToken?: InputMaybe<Scalars['Boolean']['input']>
  /** Unique identifier of the offer custom field */
  id?: InputMaybe<Scalars['Int']['input']>
  /** ID of the office to filter by */
  officeId?: InputMaybe<Scalars['Int']['input']>
  /** Type of pay to filter by */
  payType?: InputMaybe<PayTypeEnum>
  /** Name of the system token if generated */
  systemTokenName?: InputMaybe<Scalars['String']['input']>
  /** ID of the associated template condition */
  templateConditionId?: InputMaybe<Scalars['Int']['input']>
}

export type GetOfferDocumentTemplateInput = {
  conditionType?: InputMaybe<Array<ConditionTypeEnum>>
  departmentId?: InputMaybe<Scalars['Int']['input']>
  documentFileName?: InputMaybe<Scalars['String']['input']>
  documentFilePath?: InputMaybe<Scalars['String']['input']>
  documentTemplateStatus?: InputMaybe<OfferDocumentTemplateStatusEnum>
  documentType?: InputMaybe<OfferDocumentTemplateTypeEnum>
  employmentType?: InputMaybe<JobTypeEnum>
  id?: InputMaybe<Scalars['Int']['input']>
  officeId?: InputMaybe<Scalars['Int']['input']>
  payType?: InputMaybe<PayTypeEnum>
}

export type GetOfferDocumentTemplateSectionInput = {
  offerDocumentTemplateId?: InputMaybe<Scalars['Int']['input']>
  offerSectionTemplateId?: InputMaybe<Scalars['Int']['input']>
}

export type GetOfferSectionTemplateInput = {
  conditionType?: InputMaybe<Array<ConditionTypeEnum>>
  departmentId?: InputMaybe<Scalars['Int']['input']>
  employmentType?: InputMaybe<JobTypeEnum>
  id?: InputMaybe<Scalars['Int']['input']>
  officeId?: InputMaybe<Scalars['Int']['input']>
  payType?: InputMaybe<PayTypeEnum>
  sectionTemplateStatus?: InputMaybe<TokenStatusEnum>
}

export type GetOfferTemplateConditionInput = {
  conditionType?: InputMaybe<ConditionTypeEnum>
  departmentId?: InputMaybe<Scalars['Int']['input']>
  employmentType?: InputMaybe<JobTypeEnum>
  id?: InputMaybe<Scalars['Int']['input']>
  offerDocumentTemplateId?: InputMaybe<Scalars['Int']['input']>
  officeId?: InputMaybe<Scalars['Int']['input']>
  payType?: InputMaybe<PayTypeEnum>
  sectionTemplateId?: InputMaybe<Scalars['Int']['input']>
}

export type GetOrdersInput = {
  id: Scalars['Int']['input']
}

export type GetScorecardTemplatesInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  companyTemplate?: InputMaybe<Scalars['Boolean']['input']>
  ids?: InputMaybe<Array<Scalars['Int']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  scorecardName?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<SortByScorecardTemplate>
  sortDirection?: InputMaybe<SortDirection>
  visible?: InputMaybe<Scalars['Boolean']['input']>
}

export type GetTemporaryS3UrlInput = {
  fileKey?: InputMaybe<Scalars['String']['input']>
  filePath?: InputMaybe<Scalars['String']['input']>
}

export type GetTemporaryS3UrlOutput = {
  __typename?: 'GetTemporaryS3UrlOutput'
  tempUrl: Scalars['String']['output']
}

export type GetTokenTestResultInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  offerDocumentTemplateId?: InputMaybe<Scalars['Int']['input']>
  offerSectionTemplateId?: InputMaybe<Scalars['Int']['input']>
}

export type GlobalListingSetup = {
  __typename?: 'GlobalListingSetup'
  Company: Company
  aboutCompanyStatement?: Maybe<Scalars['JSON']['output']>
  companyId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  footerStatement?: Maybe<Scalars['JSON']['output']>
  id: Scalars['Int']['output']
  isAboutCompanyActive: Scalars['Boolean']['output']
  isFooterStatementActive: Scalars['Boolean']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type GlobalListingSetupInput = {
  aboutCompanyStatement?: InputMaybe<Scalars['JSON']['input']>
  companyId: Scalars['Int']['input']
  footerStatement?: InputMaybe<Scalars['JSON']['input']>
  isAboutCompanyActive?: InputMaybe<Scalars['Boolean']['input']>
  isFooterStatementActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type HiringTeamMember = {
  __typename?: 'HiringTeamMember'
  CompanyUser?: Maybe<CompanyUser>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  requisition?: Maybe<Requisition>
  requisitionId?: Maybe<Scalars['Int']['output']>
  requisitionJobDescription?: Maybe<RequisitionJobDescription>
  requisitionJobDescriptionId?: Maybe<Scalars['Int']['output']>
  role?: Maybe<Role>
  roleId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type HtmlToBase64Input = {
  html: Scalars['String']['input']
}

export type HtmlToBase64Output = {
  __typename?: 'HtmlToBase64Output'
  docx: Scalars['String']['output']
  pdf: Scalars['String']['output']
}

export type HtmlToDocxInput = {
  html: Scalars['String']['input']
}

export type HtmlToDocxOutput = {
  __typename?: 'HtmlToDocxOutput'
  dir?: Maybe<Scalars['String']['output']>
  filename: Scalars['String']['output']
  size?: Maybe<Scalars['Int']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type IdsArrayInput = {
  ids: Array<Scalars['String']['input']>
}

export enum IndustryEnum {
  AerospaceAndDefense = 'AEROSPACE_AND_DEFENSE',
  Agriculture = 'AGRICULTURE',
  ArtsEntertainmentAndRecreation = 'ARTS_ENTERTAINMENT_AND_RECREATION',
  Automotive = 'AUTOMOTIVE',
  Construction = 'CONSTRUCTION',
  ConsultingServices = 'CONSULTING_SERVICES',
  Education = 'EDUCATION',
  EnergyMiningAndUtilities = 'ENERGY_MINING_AND_UTILITIES',
  EnvironmentalServices = 'ENVIRONMENTAL_SERVICES',
  ECommerce = 'E_COMMERCE',
  FashionAndApparel = 'FASHION_AND_APPAREL',
  FinancialServices = 'FINANCIAL_SERVICES',
  FoodAndBeverage = 'FOOD_AND_BEVERAGE',
  GovernmentAndPublicAdminstration = 'GOVERNMENT_AND_PUBLIC_ADMINSTRATION',
  Healthcare = 'HEALTHCARE',
  HotelsAndTravelAccomodation = 'HOTELS_AND_TRAVEL_ACCOMODATION',
  HumanResourcesAndStaffing = 'HUMAN_RESOURCES_AND_STAFFING',
  InformationTechnology = 'INFORMATION_TECHNOLOGY',
  Insurance = 'INSURANCE',
  Legal = 'LEGAL',
  ManagementAndConsulting = 'MANAGEMENT_AND_CONSULTING',
  Manufacturing = 'MANUFACTURING',
  MediaAndCommunication = 'MEDIA_AND_COMMUNICATION',
  NonprofitAndNgo = 'NONPROFIT_AND_NGO',
  PersonalConsumerServices = 'PERSONAL_CONSUMER_SERVICES',
  PharmaceuticalAndBiotech = 'PHARMACEUTICAL_AND_BIOTECH',
  ProfessionalServices = 'PROFESSIONAL_SERVICES',
  RealEstate = 'REAL_ESTATE',
  RestaurantsAndFoodService = 'RESTAURANTS_AND_FOOD_SERVICE',
  RetailAndWholesale = 'RETAIL_AND_WHOLESALE',
  SocialMediaAndNetworking = 'SOCIAL_MEDIA_AND_NETWORKING',
  Telecommunications = 'TELECOMMUNICATIONS',
  TransportationAndLogistics = 'TRANSPORTATION_AND_LOGISTICS',
}

export type InsertGlobalScreeningQuestionInput = {
  ansOptions?: InputMaybe<Scalars['String']['input']>
  globalQuestionOrder?: InputMaybe<Scalars['Int']['input']>
  isRejectionQuestion?: InputMaybe<Scalars['Boolean']['input']>
  maxVal?: InputMaybe<Scalars['Int']['input']>
  minVal?: InputMaybe<Scalars['Int']['input']>
  preferredAnswer?: InputMaybe<Scalars['String']['input']>
  questionDesc?: InputMaybe<Scalars['String']['input']>
  questionTitle?: InputMaybe<Scalars['String']['input']>
  questionType?: InputMaybe<QuestionTypeEnum>
  rejectMessage?: InputMaybe<Scalars['String']['input']>
  valStatus?: InputMaybe<Scalars['String']['input']>
}

export enum InterviewLengthEnum {
  Dummy = 'DUMMY',
}

export enum InterviewScheduleEnum {
  Later = 'LATER',
  Scheduler = 'SCHEDULER',
}

export enum InterviewSchedulingEnum {
  ManuallySchedule = 'MANUALLY_SCHEDULE',
  SendInvite = 'SEND_INVITE',
}

export type InterviewTeam = {
  __typename?: 'InterviewTeam'
  CompanyUser: CompanyUser
  StageSettings: StageSettings
  companyUserId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  stageSettingsId: Scalars['Int']['output']
}

export type InterviewTeamInput = {
  companyUserId: Scalars['Int']['input']
  id?: InputMaybe<Scalars['Int']['input']>
  stageSettingsId?: InputMaybe<Scalars['Int']['input']>
}

export enum InvitationStatusEnum {
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Invited = 'INVITED',
  InviteAccepted = 'INVITE_ACCEPTED',
}

export type InviteCandidatesToApplyInput = {
  candidateProfileIds: Array<Scalars['Int']['input']>
  customMessage?: InputMaybe<Scalars['String']['input']>
  jobListingId: Scalars['Int']['input']
  notifyUploadedCandidates?: InputMaybe<Scalars['Boolean']['input']>
  templateMessageId?: InputMaybe<Scalars['Int']['input']>
}

export type InviteCandidatesToApplyOutput = {
  __typename?: 'InviteCandidatesToApplyOutput'
  alreadyHasActiveApplication?: Maybe<Array<JobInviteNotSentOutput>>
  isPreviouslyInvited?: Maybe<Array<JobInviteNotSentOutput>>
  profileNotFound?: Maybe<Array<JobInviteNotSentOutput>>
  sent?: Maybe<Array<JobInvite>>
  uploadedCandidatesToReviewStage?: Maybe<Array<JobInviteNotSentOutput>>
}

export enum InviteStatusEnum {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Sent = 'SENT',
}

export type InviteUserInput = {
  emails: Array<Scalars['String']['input']>
  invitedAs: InvitedAsRoleInput
}

export enum InvitedAsRoleInput {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
  SuperAdmin = 'SUPER_ADMIN',
}

export type Invoice = {
  __typename?: 'Invoice'
  accountCountry?: Maybe<Scalars['String']['output']>
  accountName?: Maybe<Scalars['String']['output']>
  amountDue: Scalars['Float']['output']
  amountPaid: Scalars['Float']['output']
  amountRemaining: Scalars['Float']['output']
  billingReason?: Maybe<Scalars['String']['output']>
  created: Scalars['DateTime']['output']
  currency: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['DateTime']['output']>
  hostedInvoiceUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoicePdf?: Maybe<Scalars['String']['output']>
  nextPaymentAttempt?: Maybe<Scalars['Int']['output']>
  number?: Maybe<Scalars['String']['output']>
  paid: Scalars['Boolean']['output']
  periodEnd?: Maybe<Scalars['DateTime']['output']>
  periodStart: Scalars['DateTime']['output']
  receiptNumber?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  subtotal: Scalars['Float']['output']
  subtotalExcludingTax?: Maybe<Scalars['Float']['output']>
  tax?: Maybe<Scalars['Float']['output']>
  total: Scalars['Float']['output']
}

export enum JobActionEnum {
  Created = 'CREATED',
  JobopeningClosed = 'JOBOPENING_CLOSED',
  JobopeningFilled = 'JOBOPENING_FILLED',
  JobopeningOpened = 'JOBOPENING_OPENED',
  JobClosed = 'JOB_CLOSED',
  JobOnHold = 'JOB_ON_HOLD',
  JobOpened = 'JOB_OPENED',
}

export type JobAdCampaign = {
  __typename?: 'JobAdCampaign'
  Company: Company
  JobListing: JobListing
  Order: Order
  Requisition: Requisition
  campaignName: Scalars['String']['output']
  companyId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  jobListingId: Scalars['Int']['output']
  requisitionId: Scalars['Int']['output']
  targetGroup: Scalars['JSON']['output']
  updatedAt: Scalars['DateTime']['output']
  vonqCampaignId?: Maybe<Scalars['String']['output']>
}

export type JobCampaign = {
  __typename?: 'JobCampaign'
  campaignId: Scalars['String']['output']
  campaignName: Scalars['String']['output']
  companyId: Scalars['String']['output']
  createdOn: Scalars['String']['output']
  currency?: Maybe<CurrencyEnum>
  customerId?: Maybe<Scalars['String']['output']>
  isEditable: Scalars['Boolean']['output']
  labels?: Maybe<JobCampaignLabels>
  orderId?: Maybe<Scalars['Int']['output']>
  orderReference?: Maybe<Scalars['String']['output']>
  orderedProducts: Array<Scalars['String']['output']>
  orderedProductsSpecs?: Maybe<Array<OrderedProductsSpec>>
  paymentStatus?: Maybe<PaymentStatusEnum>
  postingDetails?: Maybe<PostingDetails>
  postings: Array<Posting>
  recruiterInfo?: Maybe<RecruiterInfo>
  status: CampaignStatuses
  targetGroup?: Maybe<TargetGroup>
  totalPrice?: Maybe<CampaignTotalPrice>
  /** @deprecated Use campaignTraffic.views instead */
  viewsCount?: Maybe<Scalars['JSON']['output']>
}

export type JobCampaignCreation = {
  __typename?: 'JobCampaignCreation'
  clientSecret: Scalars['String']['output']
  deliveryTime: JobCampaignDeliveryTime
  orderReference: Scalars['String']['output']
  price: OrderCampaignPrice
}

export type JobCampaignCreationOrCampaignErrors = CampaignValidationErrors | JobCampaignCreation

export type JobCampaignDeliveryTime = {
  __typename?: 'JobCampaignDeliveryTime'
  daysToProcess: Scalars['Int']['output']
  daysToSetup: Scalars['Int']['output']
  totalDays: Scalars['Int']['output']
}

export type JobCampaignEducationLevel = {
  __typename?: 'JobCampaignEducationLevel'
  id: Scalars['Int']['output']
  name: Array<EducationLevel>
}

export type JobCampaignIndustry = {
  __typename?: 'JobCampaignIndustry'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type JobCampaignLabels = {
  __typename?: 'JobCampaignLabels'
  jobListingId?: Maybe<Scalars['String']['output']>
  productName?: Maybe<Scalars['String']['output']>
  requisitionId?: Maybe<Scalars['String']['output']>
}

export type JobCampaignLocation = {
  __typename?: 'JobCampaignLocation'
  canonical_name: Scalars['String']['output']
  fully_qualified_place_name?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
}

export type JobCampaignProduct = {
  __typename?: 'JobCampaignProduct'
  allow_orders: Scalars['Boolean']['output']
  audience_group: Scalars['String']['output']
  bundle_products_ids: Array<Scalars['String']['output']>
  channel: Channel
  cross_postings: Array<Scalars['String']['output']>
  description: Scalars['String']['output']
  duration: Duration
  homepage: Scalars['String']['output']
  id: Scalars['ID']['output']
  industries: Array<JobCampaignIndustry>
  job_functions: Array<JobFunction>
  locations: Array<JobCampaignLocation>
  logo_rectangle_url?: Maybe<Array<Logo>>
  logo_square_url?: Maybe<Array<Logo>>
  logo_url: Array<LogoUrl>
  mc_enabled: Scalars['Boolean']['output']
  mc_only: Scalars['Boolean']['output']
  product_id: Scalars['String']['output']
  ratecard_price: Array<Price>
  time_to_process: ProcessTime
  time_to_setup: SetupTime
  title: Scalars['String']['output']
  type: Scalars['String']['output']
  vonq_price: Array<Price>
}

export type JobCampaignProductInput = {
  id: Scalars['String']['input']
}

export type JobCampaignProducts = {
  __typename?: 'JobCampaignProducts'
  count: Scalars['Int']['output']
  results?: Maybe<Array<JobCampaignProduct>>
}

export type JobCampaignResponse = {
  __typename?: 'JobCampaignResponse'
  data: Array<JobCampaign>
  limit: Scalars['Int']['output']
  offset: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type JobCampaignSalaryRange = {
  __typename?: 'JobCampaignSalaryRange'
  currency: CurrencyEnum
  from?: Maybe<Scalars['Int']['output']>
  to?: Maybe<Scalars['Int']['output']>
}

export type JobCampaignSeniority = {
  __typename?: 'JobCampaignSeniority'
  id: Scalars['Int']['output']
  name: Array<SeniorityLevel>
}

export type JobFunction = {
  __typename?: 'JobFunction'
  children?: Maybe<Array<JobFunction>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type JobInvite = {
  __typename?: 'JobInvite'
  CandidateApplication?: Maybe<CandidateApplication>
  JobListing?: Maybe<JobListing>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  dismissed?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  inviteStatus?: Maybe<InviteStatusEnum>
  jobListingId?: Maybe<Scalars['Int']['output']>
  sentAt?: Maybe<Scalars['DateTime']['output']>
  token?: Maybe<Scalars['Int']['output']>
}

export enum JobLevel {
  EntryLevel = 'ENTRY_LEVEL',
  FirstManagement = 'FIRST_MANAGEMENT',
  IntermediateOrExperienced = 'INTERMEDIATE_OR_EXPERIENCED',
  MiddleManagement = 'MIDDLE_MANAGEMENT',
  SeniorOrExecutive = 'SENIOR_OR_EXECUTIVE',
}

export enum JobLevelEnum {
  EntryLevel = 'ENTRY_LEVEL',
  FirstManagement = 'FIRST_MANAGEMENT',
  IntermediateOrExperienced = 'INTERMEDIATE_OR_EXPERIENCED',
  MiddleManagement = 'MIDDLE_MANAGEMENT',
  SeniorOrExecutive = 'SENIOR_OR_EXECUTIVE',
}

export enum JobLevelRoleEnum {
  HiringManager = 'HIRING_MANAGER',
  HiringTeamMember = 'HIRING_TEAM_MEMBER',
  JobViewer = 'JOB_VIEWER',
  Recruiter = 'RECRUITER',
}

export type JobListing = {
  __typename?: 'JobListing'
  Address?: Maybe<Array<Address>>
  Campaigns?: Maybe<Array<JobAdCampaign>>
  CandidateApplication?: Maybe<Array<CandidateApplication>>
  EventJob?: Maybe<Array<EventJob>>
  JobInvite?: Maybe<Array<JobInvite>>
  JobListingJobPipeline?: Maybe<Array<JobListingJobPipeline>>
  JobListingScreeningQuestion?: Maybe<Array<JobListingScreeningQuestion>>
  Requisition?: Maybe<Requisition>
  acceptingCandidates?: Maybe<Scalars['Boolean']['output']>
  companyDefault?: Maybe<Scalars['Boolean']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  displayPayOnListing?: Maybe<Scalars['Boolean']['output']>
  externalListingId?: Maybe<Scalars['String']['output']>
  externalTitle?: Maybe<Scalars['String']['output']>
  hasApplied?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['Int']['output']
  industry?: Maybe<IndustryEnum>
  internalMatchingDescription?: Maybe<Scalars['String']['output']>
  jobRequisitionId?: Maybe<Scalars['Int']['output']>
  listingTitle?: Maybe<Scalars['String']['output']>
  postedAt?: Maybe<Scalars['DateTime']['output']>
  publicDescription?: Maybe<Scalars['String']['output']>
  publicListingId?: Maybe<Scalars['String']['output']>
  searchInternalMatchingDescription?: Maybe<Scalars['String']['output']>
  searchPublicDescription?: Maybe<Scalars['String']['output']>
  stage?: Maybe<StageEnum>
  stateAndOrCountry?: Maybe<Scalars['String']['output']>
  subIndustry?: Maybe<SubIndustryTypeEnum>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  workplaceType?: Maybe<WorkplaceTypeEnum>
}

export type JobListingJobPipeline = {
  __typename?: 'JobListingJobPipeline'
  JobListing?: Maybe<JobListing>
  JobPipeline?: Maybe<JobPipeline>
  id: Scalars['ID']['output']
  jobListingId?: Maybe<Scalars['Int']['output']>
  jobPipelineId?: Maybe<Scalars['Int']['output']>
}

export type JobListingScreeningQuestion = {
  __typename?: 'JobListingScreeningQuestion'
  CandidateAnswer?: Maybe<ScreeningQuestionAnswer>
  JobListing?: Maybe<JobListing>
  ScreeningQuestion?: Maybe<ScreeningQuestion>
  active?: Maybe<Scalars['Boolean']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  displaySequenceNumber?: Maybe<Scalars['Int']['output']>
  hasApplications?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  jobListingId?: Maybe<Scalars['Int']['output']>
  screeningQuestionId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type JobListingScreeningQuestionCandidateAnswerArgs = {
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
}

export type JobListingsSearchResult = ExternalJob | JobListing

export type JobOpening = {
  __typename?: 'JobOpening'
  CandidateApplication?: Maybe<CandidateApplication>
  CandidateProfile?: Maybe<CandidateProfile>
  Company?: Maybe<Company>
  Requisition?: Maybe<Requisition>
  closedDate?: Maybe<Scalars['DateTime']['output']>
  closedReason?: Maybe<ClosedReasonEnum>
  companyId?: Maybe<Scalars['Int']['output']>
  extOpeningId?: Maybe<Scalars['String']['output']>
  filledByApplication?: Maybe<Scalars['Int']['output']>
  filledByCandidate?: Maybe<Scalars['Int']['output']>
  filledDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  jobRequisitionId?: Maybe<Scalars['Int']['output']>
  openingStatus?: Maybe<OpeningStatusEnum>
  openingType?: Maybe<OpeningTypeEnum>
  startDate?: Maybe<Scalars['DateTime']['output']>
  targetHireDate?: Maybe<Scalars['DateTime']['output']>
}

export type JobPipeline = {
  __typename?: 'JobPipeline'
  JobListingJobPipeline?: Maybe<Array<JobListingJobPipeline>>
  Requisition?: Maybe<Requisition>
  StageSettings?: Maybe<Array<StageSettings>>
  TemplatePipeline?: Maybe<TemplatePipeline>
  id: Scalars['ID']['output']
  jobRequisitionId?: Maybe<Scalars['Int']['output']>
  templatePipelineId?: Maybe<Scalars['Int']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type JobSearchResultType = {
  __typename?: 'JobSearchResultType'
  benefits?: Maybe<Array<Scalars['String']['output']>>
  company?: Maybe<CompanyType>
  createdAt?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  descriptionHtml?: Maybe<Scalars['String']['output']>
  educationLevel?: Maybe<EducationLevelPrefEnum>
  employment?: Maybe<JobTypeEnum>
  experienceLevel?: Maybe<JobLevelEnum>
  id: Scalars['String']['output']
  isApplied?: Maybe<Scalars['Boolean']['output']>
  isBookmarked?: Maybe<Scalars['Boolean']['output']>
  locations?: Maybe<Array<LocationType>>
  payment?: Maybe<PayTypeEnum>
  salary?: Maybe<SalaryType>
  skills?: Maybe<Array<Scalars['String']['output']>>
  source?: Maybe<SourceTypeEnum>
  tags?: Maybe<Array<Scalars['String']['output']>>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
  workplace?: Maybe<LocationTypeEnum>
}

export type JobSeekerHeader = {
  __typename?: 'JobSeekerHeader'
  educationDisplayedId?: Maybe<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  jobDisplayedId?: Maybe<Scalars['Int']['output']>
  jobSeekerId?: Maybe<Scalars['Int']['output']>
  profileHeadline?: Maybe<Scalars['String']['output']>
}

export type JobSeekerHeaderInput = {
  educationDisplayedId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  jobDisplayedId?: InputMaybe<Scalars['Int']['input']>
  profileHeadline?: InputMaybe<Scalars['String']['input']>
}

export type JobSeekerInfo = {
  __typename?: 'JobSeekerInfo'
  aboutMe?: Maybe<Scalars['String']['output']>
  canStartNewJob?: Maybe<CanStartNewJobEnum>
  consideringOffers?: Maybe<Scalars['Boolean']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  currPrefRoles?: Maybe<Scalars['JSON']['output']>
  desiredPay?: Maybe<Scalars['Int']['output']>
  employStatus?: Maybe<Scalars['Boolean']['output']>
  hasFinishedOnboarding: Scalars['Boolean']['output']
  id: Scalars['Int']['output']
  jobLevel?: Maybe<Scalars['String']['output']>
  jobSeekerId?: Maybe<Scalars['Int']['output']>
  jobType?: Maybe<Scalars['String']['output']>
  jobTypePref?: Maybe<JobTypePrefEnum>
  locationsWillRelocate?: Maybe<Scalars['JSON']['output']>
  messageUserId?: Maybe<Scalars['String']['output']>
  nonTraditionalStatus?: Maybe<Scalars['Boolean']['output']>
  payType?: Maybe<Scalars['String']['output']>
  searchStatus?: Maybe<Scalars['String']['output']>
  searchingJobs?: Maybe<Scalars['Boolean']['output']>
  teamSize?: Maybe<TeamSizeEnum>
  tryingToRelocate?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  willingToRelocate?: Maybe<Scalars['String']['output']>
  yearsInWorkforce?: Maybe<Scalars['Int']['output']>
  yearsOfExperience?: Maybe<Scalars['Int']['output']>
}

export type JobSeekerInfoInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>
  canStartNewJob?: InputMaybe<CanStartNewJobEnum>
  consideringOffers?: InputMaybe<Scalars['Boolean']['input']>
  currPrefRoles?: InputMaybe<Scalars['JSON']['input']>
  desiredPay?: InputMaybe<Scalars['Int']['input']>
  employStatus?: InputMaybe<Scalars['Boolean']['input']>
  hasFinishedOnboarding?: InputMaybe<Scalars['Boolean']['input']>
  jobLevel?: InputMaybe<Scalars['String']['input']>
  jobType?: InputMaybe<JobTypeEnum>
  jobTypePref?: InputMaybe<JobTypePrefEnum>
  locationsWillRelocate?: InputMaybe<Array<LocationsWillRelocateInput>>
  messageUserId?: InputMaybe<Scalars['String']['input']>
  nonTraditionalStatus?: InputMaybe<Scalars['Boolean']['input']>
  payType?: InputMaybe<Scalars['String']['input']>
  searchStatus?: InputMaybe<Scalars['String']['input']>
  searchingJobs?: InputMaybe<Scalars['Boolean']['input']>
  teamSize?: InputMaybe<TeamSizeEnum>
  token?: InputMaybe<Scalars['String']['input']>
  tryingToRelocate?: InputMaybe<Scalars['String']['input']>
  willingToRelocate?: InputMaybe<Scalars['String']['input']>
  yearsInWorkforce?: InputMaybe<Scalars['Int']['input']>
  yearsOfExperience?: InputMaybe<Scalars['Int']['input']>
}

export type JobSeekerSkillInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  level?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  sequenceNumber?: InputMaybe<Scalars['Int']['input']>
  yearsOfExp?: InputMaybe<Scalars['Int']['input']>
}

export type JobSeekerSkills = {
  __typename?: 'JobSeekerSkills'
  id: Scalars['Int']['output']
  level?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  sequenceNumber?: Maybe<Scalars['Int']['output']>
  yearsOfExp?: Maybe<Scalars['Int']['output']>
}

export enum JobStatusEnum {
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
}

export enum JobTypeEnum {
  Contractor = 'CONTRACTOR',
  FullTime = 'FULL_TIME',
  Internship = 'INTERNSHIP',
  Other = 'OTHER',
  PartTime = 'PART_TIME',
  PerDiem = 'PER_DIEM',
  Seasonal = 'SEASONAL',
  Temporary = 'TEMPORARY',
}

export enum JobTypePrefEnum {
  EntirelyRemote = 'ENTIRELY_REMOTE',
  HybridRemote = 'HYBRID_REMOTE',
  InPerson = 'IN_PERSON',
}

export type LastActivity = {
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
}

export type ListApplicationFormInput = {
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  companyOfficeId?: InputMaybe<Scalars['Int']['input']>
}

export type ListApplicationFormScreeningQuestionInput = {
  applicationFormId: Scalars['Int']['input']
  orderBy?: InputMaybe<SortDirection>
}

export type ListCompanyUserGroupsInput = {
  companyId: Scalars['Int']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type ListGlobalScreeningQuestionInput = {
  isActiveGlobally?: InputMaybe<Scalars['Boolean']['input']>
  orderBy?: InputMaybe<SortDirection>
  showReplaced?: InputMaybe<Scalars['Boolean']['input']>
}

export type ListInvoices = {
  __typename?: 'ListInvoices'
  invoices: Array<Invoice>
  upcomingInvoices: Array<Invoice>
}

export type ListTemplateMessageInput = {
  id?: InputMaybe<Scalars['String']['input']>
  messageOwner?: InputMaybe<MessageOwnerEnum>
  messageType?: InputMaybe<MessageTypeEnum>
  search?: InputMaybe<Scalars['String']['input']>
}

export type LocationInputType = {
  city?: InputMaybe<Scalars['String']['input']>
  continent?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  region?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type LocationType = {
  __typename?: 'LocationType'
  city?: Maybe<Scalars['String']['output']>
  continent?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  region?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
}

export enum LocationTypeEnum {
  Hybrid = 'HYBRID',
  OnSite = 'ON_SITE',
  Remote = 'REMOTE',
}

export type LocationsWillRelocateInput = {
  city?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type Logo = {
  __typename?: 'Logo'
  size: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type LogoUrl = {
  __typename?: 'LogoUrl'
  url: Scalars['String']['output']
}

export type MatchScore = {
  max: Scalars['Int']['input']
  min: Scalars['Int']['input']
}

export enum MessageOwnerEnum {
  Company = 'COMPANY',
  Personal = 'PERSONAL',
}

export enum MessageTypeEnum {
  AdvanceCandidate = 'ADVANCE_CANDIDATE',
  CandidateOffer = 'CANDIDATE_OFFER',
  InviteToApply = 'INVITE_TO_APPLY',
  RejectCandidate = 'REJECT_CANDIDATE',
}

export type Mutation = {
  __typename?: 'Mutation'
  ActivateCompanyUser: CompanyUser
  AdvanceCandidateApplication: Array<CandidateApplication>
  AdvanceFromOfferToHired: CandidateApplication
  CancelJobCampaign?: Maybe<CancelCampaignOutput>
  CancelScheduledReport: ScheduledReport
  CancelSubscription?: Maybe<UserSubscription>
  ChangePassword?: Maybe<ScalisUser>
  /** Complete an interview */
  CompleteInterview: CandidateInterview
  /** Get the provider auth URL */
  ConnectCalendar: ConnectCalendarOutput
  CountCampaignView?: Maybe<CountCampaignView>
  CreateApplicantNotes: ApplicantNotes
  CreateBookmarkedJob: BookmarkedJob
  /** Create a calendar event */
  CreateCalendarEvent: CalendarEvent
  CreateCandidateApplicationFromEmployer: CandidateApplication
  CreateCandidateApplicationFromJobSeeker: CandidateApplication
  CreateCandidateFilter: CandidateFilter
  CreateCandidateOfferFile: Array<CandidateOfferFile>
  CreateCandidateOfferRejection: CandidateOfferRejection
  CreateCandidateSource?: Maybe<CandidateSource>
  CreateCandidateSourceName: CandidateSourceName
  CreateChatV2: ChatC2J
  CreateCompany: Company
  CreateCompanyUserFromInvitation: CompanyUser
  CreateCompanyUserGroup: CompanyUserGroup
  CreateDocumentTemplate: OfferDocumentTemplate
  CreateFavoriteReport: FavoriteCoreReport
  /** Schedule an interview for a candidate */
  CreateInterview: CandidateInterview
  /** @deprecated Use SignUp mutation instead */
  CreateJobSeekerAccount: ScalisUser
  CreateScalisUserUploadedDocument?: Maybe<CreateScalisUserUploadedDocumentOutput>
  /** Create scheduler link invite */
  CreateSchedulerLinkInvite: Scalars['Boolean']['output']
  /** Create scheduler link settings */
  CreateSchedulerLinkSession: SchedulerLinkSessionOutput
  /** Create scheduler link settings */
  CreateSchedulerLinkSettings: SchedulerLinkSettings
  CreateSharedReport: SharedCoreReport
  CreateUploadedCandidate: UploadedCandidate
  CreateUploadedDocument?: Maybe<UploadedDocument>
  CreateUploadedDocumentCandidateApplication?: Maybe<UploadedDocumentCandidateApplication>
  CreateUploadedDocumentCandidateProfile?: Maybe<UploadedDocumentCandidateProfile>
  CreateUsersInCompanyUserGroup: CompanyUserGroup
  DeleteAchievement: Scalars['Boolean']['output']
  DeleteApplicantNotes: ApplicantNotes
  DeleteApplicationForm: ApplicationForm
  DeleteApplicationFormScreeningQuestion: ApplicationFormScreeningQuestion
  DeleteBookmarkedJob: BookmarkedJob
  DeleteCalendarEvent?: Maybe<CalendarSettings>
  DeleteCandidateApplication: CandidateApplication
  DeleteCandidateApplicationNote: CandidateApplicationNote
  DeleteCandidateCollaborator: CandidateCollaborator
  DeleteCandidateFilter: CandidateFilter
  DeleteCandidateMedalistDesignation: CandidateMedalistDesignation
  DeleteCandidateMessage: CandidateMessage
  DeleteCandidateOffer: CandidateOffer
  DeleteCandidateOfferCustomField: CandidateOfferCustomField
  DeleteCandidateOfferFile: CandidateOfferFile
  DeleteCandidateOfferVersion: CandidateOfferVersion
  DeleteCandidateProfileLocation: CandidateProfileLocation
  DeleteCandidateProfileNote: CandidateProfileNote
  DeleteCandidateScorecard?: Maybe<CandidateScorecard>
  DeleteCandidateSource: CandidateSource
  DeleteCertificateOrLicense: Scalars['Boolean']['output']
  DeleteCompanyDepartment: CompanyDepartment
  DeleteCompanyOffice: CompanyOffice
  DeleteCompanyUserFromWorkspace: CompanyUser
  DeleteCompanyUserGroup: CompanyUserGroup
  DeleteEducation: Scalars['Boolean']['output']
  DeleteExperience: Scalars['Boolean']['output']
  DeleteExternalJobApplication: DeleteExternalJobApplicationResponse
  DeleteFavoriteReport: FavoriteCoreReport
  DeleteGlobalScreeningQuestion: ScreeningQuestion
  DeleteHiringTeamMember: HiringTeamMember
  /** Delete a scheduled interview */
  DeleteInterview: Scalars['Boolean']['output']
  DeleteInterviewTeam: InterviewTeam
  DeleteInvitationFromWorkspace: UserInvitation
  DeleteJobListing: JobListing
  DeleteJobListingScreeningQuestion: JobListingScreeningQuestion
  DeleteJobOpening: JobOpening
  DeleteJobPipeline: JobPipeline
  DeleteJobSeekerSkill: Scalars['Boolean']['output']
  DeleteOfferCustomField: OfferCustomField
  DeleteOfferDocumentTemplate: OfferDocumentTemplate
  DeleteOfferDocumentTemplateSection: OfferDocumentTemplateSection
  DeleteOfferSectionTemplate: OfferSectionTemplate
  DeleteOfferTemplateCondition: OfferTemplateCondition
  DeleteRequisition: Requisition
  DeleteRequisitionJobDescription: RequisitionJobDescription
  DeleteRequisitionSkill: RequisitionSkill
  /** Delete profile image of the current user */
  DeleteScalisUserProfileImage: ScalisUser
  DeleteScalisUserUploadedDocument?: Maybe<DeleteScalisUserUploadedDocumentOutput>
  /** Delete scheduler link invite */
  DeleteSchedulerLinkInvite: Scalars['Boolean']['output']
  /** Delete scheduler link settings */
  DeleteSchedulerLinkSettings: SchedulerLinkSettings
  DeleteScorecardTemplate: ScorecardTemplate
  DeleteScorecardTemplateQuestion: ScorecardTemplateQuestion
  DeleteScorecardTemplateSection: ScorecardTemplateSection
  DeleteScreeningQuestion: ScreeningQuestion
  DeleteSharedReport: Scalars['Boolean']['output']
  DeleteSkill: Array<Scalars['Boolean']['output']>
  DeleteStageCollaborator: StageCollaborator
  DeleteStageSettings: StageSettings
  DeleteTemplateJobDescription: TemplateJobDescription
  DeleteTemplateMessage: TemplateMessage
  DeleteTemplateMilestone: TemplateMilestone
  DeleteTemplatePipeline: TemplatePipeline
  DeleteTemplateStage: TemplateStage
  DeleteTokenTestResult: TokenTestResult
  DeleteUploadedDocument: UploadedDocument
  DeleteUploadedDocumentCandidateApplication: UploadedDocumentCandidateApplication
  DeleteUploadedDocumentCandidateProfile: UploadedDocumentCandidateProfile
  DeleteUsersFromCompanyUserGroup: CompanyUserGroup
  DeleteVolunteerWork: Scalars['Boolean']['output']
  DisableUserAccount?: Maybe<ScalisUser>
  /** Delete the Nylas Account associated with the provided email and destroys the grantId on Nylas */
  DisconnectCalendar: DisconnectCalendarOutput
  DownloadDocumentTemplate: DownloadDocumentTemplateOutput
  /** Edit a calendar event */
  EditCalendarEvent: CalendarEvent
  EditCalendarSettings: CalendarSettings
  EditGlobalScreeningQuestion?: Maybe<ScreeningQuestion>
  /** Exchange a Nylas code for an access token and update CalendarSettings */
  ExchangeNylasToken: CalendarSettings
  GenerateFilesFromHtml?: Maybe<GenerateFilesFromHtmlOutput>
  HtmlToBase64?: Maybe<HtmlToBase64Output>
  HtmlToDocx?: Maybe<HtmlToDocxOutput>
  InsertGlobalScreeningQuestion?: Maybe<Array<Maybe<ScreeningQuestion>>>
  InviteCandidatesToApply?: Maybe<InviteCandidatesToApplyOutput>
  InviteUser: Array<UserInvitation>
  OrderJobCampaign?: Maybe<JobCampaignCreationOrCampaignErrors>
  ProcessAutoRejectCandidates: Scalars['String']['output']
  ProcessRejectCandidates: RejectCandidatesResponse
  RejectCandidates: RejectCandidatesResponse
  ResendEmailVerification: ScalisUser
  ResendResetPasswordEmail: Array<EmailVerification>
  /** Resend scheduler link invite */
  ResendSchedulerLinkInvite: Scalars['Boolean']['output']
  ResetPassword?: Maybe<ScalisUser>
  ScheduleReport: ScheduledReport
  SendCandidateOffer: Scalars['Boolean']['output']
  SendCandidateScorecard: CandidateScorecard
  SendCandidatesToSourcingStage?: Maybe<SendCandidatesToSourcingStageOutput>
  SendJobListing: SendJobListingResult
  SendReminderCandidateScorecard: CandidateScorecard
  SendResetPasswordEmail: Array<EmailVerification>
  SignUp: ScalisUser
  StartCandidateApplication: CandidateApplicationStatus
  Subscribe?: Maybe<ScalisSubscription>
  /** Fetch all events from the calendar within a 6 months range */
  SyncCalendar: CalendarSettings
  /** Fetch all events from company calendars of all users within a 6 months range */
  SyncCompanyUsersCalendars: SyncCompanyUsersCalendarsOutput
  ToggleScorecardTemplateArchive?: Maybe<ScorecardTemplate>
  UndoHire: CandidateApplication
  UndoOfferCandidateDeclined: CandidateOfferVersion
  UndoRejectCandidates: Scalars['Boolean']['output']
  UpdateCandidateFilter: CandidateFilter
  UpdateCandidateOfferVersionStatus: CandidateOfferVersion
  UpdateCompanyData: Company
  UpdateCompanyUserGroup: CompanyUserGroup
  UpdateCompanyUserRole: CompanyUser
  UpdateDocumentTemplate: OfferDocumentTemplate
  /** Update a scheduled interview */
  UpdateInterview: CandidateInterview
  UpdateJobInvite: JobInvite
  UpdateScalisUserData: ScalisUser
  UpdateScheduledReport: ScheduledReport
  /** Update scheduler link invite */
  UpdateSchedulerLinkInvite: Scalars['Boolean']['output']
  /** Update scheduler link settings */
  UpdateSchedulerLinkSettings: SchedulerLinkSettings
  UpdateUsersInCompanyUserGroup: CompanyUserGroup
  UploadS3File?: Maybe<UploadS3FileOutput>
  UpsertAddress?: Maybe<Address>
  UpsertApplicationForm?: Maybe<ApplicationForm>
  UpsertApplicationFormScreeningQuestion?: Maybe<ApplicationFormScreeningQuestion>
  UpsertCandidateApplicationNote?: Maybe<CandidateApplicationNote>
  UpsertCandidateApplicationRating?: Maybe<CandidateApplicationRating>
  UpsertCandidateCollaborator?: Maybe<CandidateCollaborator>
  UpsertCandidateMessage?: Maybe<CandidateMessage>
  UpsertCandidateOffer?: Maybe<CandidateOffer>
  UpsertCandidateOfferCustomField?: Maybe<CandidateOfferCustomField>
  UpsertCandidateOfferFile?: Maybe<CandidateOfferFile>
  UpsertCandidateOfferVersion?: Maybe<CandidateOfferVersion>
  UpsertCandidateProfile?: Maybe<CandidateProfile>
  UpsertCandidateProfileNote: CandidateProfileNote
  UpsertCandidateScorecard?: Maybe<CandidateScorecard>
  UpsertCandidateScorecardAnswers?: Maybe<CandidateScorecardAnswers>
  UpsertEmail: ScalisUser
  UpsertExternalJobApplication: ExternalJobApplication
  UpsertHiringTeamMember?: Maybe<HiringTeamMember>
  UpsertJobListing?: Maybe<JobListing>
  UpsertJobListingJobPipeline?: Maybe<Array<JobListingJobPipeline>>
  UpsertJobListingScreeningQuestion?: Maybe<JobListingScreeningQuestion>
  UpsertJobOpening?: Maybe<JobOpening>
  UpsertJobPipeline?: Maybe<JobPipeline>
  UpsertOfferCustomField?: Maybe<OfferCustomField>
  UpsertOfferDocumentTemplate?: Maybe<OfferDocumentTemplate>
  UpsertOfferDocumentTemplateSection?: Maybe<OfferDocumentTemplateSection>
  UpsertOfferSectionTemplate?: Maybe<OfferSectionTemplate>
  UpsertOfferTemplateCondition?: Maybe<Array<Maybe<OfferTemplateCondition>>>
  UpsertRequisition?: Maybe<Requisition>
  UpsertRequisitionJobDescription?: Maybe<RequisitionJobDescription>
  UpsertRequisitionSkill?: Maybe<RequisitionSkill>
  UpsertScorecardTemplate?: Maybe<ScorecardTemplate>
  UpsertScreeningQuestion?: Maybe<ScreeningQuestion>
  UpsertSkill?: Maybe<Array<Skill>>
  UpsertStageCollaborator?: Maybe<StageCollaborator>
  UpsertStageSettings?: Maybe<StageSettings>
  UpsertTemplateJobDescription?: Maybe<TemplateJobDescription>
  UpsertTemplateMessage?: Maybe<TemplateMessage>
  UpsertTemplateMilestone?: Maybe<TemplateMilestone>
  UpsertTemplatePipeline?: Maybe<TemplatePipeline>
  UpsertTemplateStage?: Maybe<TemplateStage>
  UpsertTokenTestResult?: Maybe<TokenTestResult>
  ValidateOfferDocumentTemplate: OfferDocumentTemplate
  ValidateOfferSectionTemplate: OfferSectionTemplate
  VerifyEmailCode: ScalisUser
  VerifyPasswordCode: ScalisUser
  upsertGlobalListingSetup?: Maybe<GlobalListingSetup>
}

export type MutationActivateCompanyUserArgs = {
  input: ActivateCompanyUserInput
}

export type MutationAdvanceCandidateApplicationArgs = {
  input: AdvanceCandidateApplicationInput
}

export type MutationAdvanceFromOfferToHiredArgs = {
  input: AdvanceFromOfferToHiredInput
}

export type MutationCancelJobCampaignArgs = {
  input: CancelCampaignInput
}

export type MutationCancelScheduledReportArgs = {
  input: CancelScheduledReportInput
}

export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput
}

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput
}

export type MutationCompleteInterviewArgs = {
  input: CompleteInterviewInput
}

export type MutationConnectCalendarArgs = {
  input: ConnectCalendarInput
}

export type MutationCountCampaignViewArgs = {
  input: CountCampaignViewInput
}

export type MutationCreateApplicantNotesArgs = {
  input: CreateApplicantNotesInput
}

export type MutationCreateBookmarkedJobArgs = {
  input: CreateBookmarkedJobInput
}

export type MutationCreateCalendarEventArgs = {
  input: CreateCalendarEventInput
}

export type MutationCreateCandidateApplicationFromEmployerArgs = {
  input: CreateCandidateApplicationFromEmployerInput
}

export type MutationCreateCandidateApplicationFromJobSeekerArgs = {
  input: CreateCandidateApplicationFromJobSeekerInput
}

export type MutationCreateCandidateFilterArgs = {
  input: CreateCandidateFilterInput
}

export type MutationCreateCandidateOfferFileArgs = {
  input: CreateCandidateOfferFileInput
}

export type MutationCreateCandidateOfferRejectionArgs = {
  input: CreateCandidateOfferRejectionInput
}

export type MutationCreateCandidateSourceArgs = {
  input: CandidateSourceInput
}

export type MutationCreateCandidateSourceNameArgs = {
  input: CandidateSourceNameCreateInput
}

export type MutationCreateChatV2Args = {
  input: CreateChatV2Input
}

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput
}

export type MutationCreateCompanyUserFromInvitationArgs = {
  input: CreateCompanyUserFromInvitationInput
}

export type MutationCreateCompanyUserGroupArgs = {
  input: CreateCompanyUserGroupInput
}

export type MutationCreateDocumentTemplateArgs = {
  input: CreateDocumentTemplateInput
}

export type MutationCreateFavoriteReportArgs = {
  input: FavoriteReportInput
}

export type MutationCreateInterviewArgs = {
  input: CreateInterviewInput
}

export type MutationCreateJobSeekerAccountArgs = {
  input: CreateJobSeekerAccountInput
}

export type MutationCreateScalisUserUploadedDocumentArgs = {
  input: CreateScalisUserUploadedDocumentInput
}

export type MutationCreateSchedulerLinkInviteArgs = {
  input: CreateSchedulerLinkInviteInput
}

export type MutationCreateSchedulerLinkSessionArgs = {
  input: CreateSchedulerLinkSessionInput
}

export type MutationCreateSchedulerLinkSettingsArgs = {
  input: CreateSchedulerLinkSettingsInput
}

export type MutationCreateSharedReportArgs = {
  input: SharedReportInput
}

export type MutationCreateUploadedCandidateArgs = {
  input: CreateUploadedCandidateInput
}

export type MutationCreateUploadedDocumentArgs = {
  input: CreateUploadedDocumentInput
}

export type MutationCreateUploadedDocumentCandidateApplicationArgs = {
  input: CreateUploadedDocumentCandidateApplicationInput
}

export type MutationCreateUploadedDocumentCandidateProfileArgs = {
  input: CreateUploadedDocumentCandidateProfileInput
}

export type MutationCreateUsersInCompanyUserGroupArgs = {
  input: CreateUsersInCompanyUserGroupInput
}

export type MutationDeleteAchievementArgs = {
  input: DeleteAchievementInput
}

export type MutationDeleteApplicantNotesArgs = {
  input: DeleteApplicantNotesInput
}

export type MutationDeleteApplicationFormArgs = {
  input: DeleteApplicationFormInput
}

export type MutationDeleteApplicationFormScreeningQuestionArgs = {
  input: DeleteApplicationFormScreeningQuestionInput
}

export type MutationDeleteBookmarkedJobArgs = {
  input: DeleteBookmarkedJobInput
}

export type MutationDeleteCalendarEventArgs = {
  input: DeleteCalendarEventInput
}

export type MutationDeleteCandidateApplicationArgs = {
  input: DeleteCandidateApplicationInput
}

export type MutationDeleteCandidateApplicationNoteArgs = {
  input: DeleteCandidateApplicationNoteInput
}

export type MutationDeleteCandidateCollaboratorArgs = {
  input: DeleteCandidateCollaboratorInput
}

export type MutationDeleteCandidateFilterArgs = {
  input: DeleteCandidateFilterInput
}

export type MutationDeleteCandidateMedalistDesignationArgs = {
  input: DeleteCandidateMedalistDesignationInput
}

export type MutationDeleteCandidateMessageArgs = {
  input: DeleteCandidateMessageInput
}

export type MutationDeleteCandidateOfferArgs = {
  input: DeleteCandidateOfferInput
}

export type MutationDeleteCandidateOfferCustomFieldArgs = {
  input: DeleteCandidateOfferCustomFieldInput
}

export type MutationDeleteCandidateOfferFileArgs = {
  input: DeleteCandidateOfferFileInput
}

export type MutationDeleteCandidateOfferVersionArgs = {
  input: DeleteCandidateOfferVersionInput
}

export type MutationDeleteCandidateProfileLocationArgs = {
  input: DeleteCandidateProfileLocationInput
}

export type MutationDeleteCandidateProfileNoteArgs = {
  input: DeleteCandidateProfileNoteInput
}

export type MutationDeleteCandidateScorecardArgs = {
  input: DeleteCandidateScorecardInput
}

export type MutationDeleteCandidateSourceArgs = {
  input: DeleteCandidateSourceInput
}

export type MutationDeleteCertificateOrLicenseArgs = {
  input: DeleteCertificateOrLicenseInput
}

export type MutationDeleteCompanyDepartmentArgs = {
  input: DeleteCompanyDepartmentInput
}

export type MutationDeleteCompanyOfficeArgs = {
  input: DeleteCompanyOfficeInput
}

export type MutationDeleteCompanyUserFromWorkspaceArgs = {
  input: DeleteCompanyUserFromWorkspaceInput
}

export type MutationDeleteCompanyUserGroupArgs = {
  input: DeleteCompanyUserGroupInput
}

export type MutationDeleteEducationArgs = {
  input: DeleteEducationInput
}

export type MutationDeleteExperienceArgs = {
  input: DeleteExperienceInput
}

export type MutationDeleteExternalJobApplicationArgs = {
  input: DeleteExternalApplicationInput
}

export type MutationDeleteFavoriteReportArgs = {
  input: FavoriteReportInput
}

export type MutationDeleteGlobalScreeningQuestionArgs = {
  input: DeleteGlobalScreeningQuestionInput
}

export type MutationDeleteHiringTeamMemberArgs = {
  input: DeleteHiringTeamMemberInput
}

export type MutationDeleteInterviewArgs = {
  input: DeleteInterviewInput
}

export type MutationDeleteInterviewTeamArgs = {
  input: DeleteInterviewTeamInput
}

export type MutationDeleteInvitationFromWorkspaceArgs = {
  input: DeleteInvitationFromWorkspaceInput
}

export type MutationDeleteJobListingArgs = {
  input: DeleteJobListingInput
}

export type MutationDeleteJobListingScreeningQuestionArgs = {
  input: DeleteJobListingScreeningQuestionInput
}

export type MutationDeleteJobOpeningArgs = {
  input: DeleteJobOpeningInput
}

export type MutationDeleteJobPipelineArgs = {
  input: DeleteJobPipelineInput
}

export type MutationDeleteJobSeekerSkillArgs = {
  input: DeleteJobSeekerSkillInput
}

export type MutationDeleteOfferCustomFieldArgs = {
  input: DeleteOfferCustomFieldInput
}

export type MutationDeleteOfferDocumentTemplateArgs = {
  input: DeleteOfferDocumentTemplateInput
}

export type MutationDeleteOfferDocumentTemplateSectionArgs = {
  input: DeleteOfferDocumentTemplateSectionInput
}

export type MutationDeleteOfferSectionTemplateArgs = {
  input: DeleteOfferSectionTemplateInput
}

export type MutationDeleteOfferTemplateConditionArgs = {
  input: DeleteOfferTemplateConditionInput
}

export type MutationDeleteRequisitionArgs = {
  input: DeleteRequisitionInput
}

export type MutationDeleteRequisitionJobDescriptionArgs = {
  input: DeleteRequisitionJobDescriptionInput
}

export type MutationDeleteRequisitionSkillArgs = {
  input: DeleteRequisitionSkillInput
}

export type MutationDeleteScalisUserUploadedDocumentArgs = {
  input: DeleteScalisUserUploadedDocumentInput
}

export type MutationDeleteSchedulerLinkInviteArgs = {
  input: DeleteSchedulerLinkInviteInput
}

export type MutationDeleteSchedulerLinkSettingsArgs = {
  input: DeleteSchedulerLinkSettingsInput
}

export type MutationDeleteScorecardTemplateArgs = {
  input: DeleteScorecardTemplateInput
}

export type MutationDeleteScorecardTemplateQuestionArgs = {
  input: DeleteScorecardTemplateQuestionInput
}

export type MutationDeleteScorecardTemplateSectionArgs = {
  input: DeleteScorecardTemplateSectionInput
}

export type MutationDeleteScreeningQuestionArgs = {
  input: DeleteScreeningQuestionInput
}

export type MutationDeleteSharedReportArgs = {
  input: SharedReportInput
}

export type MutationDeleteSkillArgs = {
  input: Array<DeleteSkillInput>
}

export type MutationDeleteStageCollaboratorArgs = {
  input: DeleteStageCollaboratorInput
}

export type MutationDeleteStageSettingsArgs = {
  input: DeleteStageSettingsInput
}

export type MutationDeleteTemplateJobDescriptionArgs = {
  input: DeleteTemplateJobDescriptionInput
}

export type MutationDeleteTemplateMessageArgs = {
  input: DeleteTemplateMessageInput
}

export type MutationDeleteTemplateMilestoneArgs = {
  input: DeleteTemplateMilestoneInput
}

export type MutationDeleteTemplatePipelineArgs = {
  input: DeleteTemplatePipelineInput
}

export type MutationDeleteTemplateStageArgs = {
  input: DeleteTemplateStageInput
}

export type MutationDeleteTokenTestResultArgs = {
  input: DeleteTokenTestResultInput
}

export type MutationDeleteUploadedDocumentArgs = {
  input: DeleteUploadedDocumentInput
}

export type MutationDeleteUploadedDocumentCandidateApplicationArgs = {
  input: DeleteUploadedDocumentCandidateApplicationInput
}

export type MutationDeleteUploadedDocumentCandidateProfileArgs = {
  input: DeleteUploadedDocumentCandidateProfileInput
}

export type MutationDeleteUsersFromCompanyUserGroupArgs = {
  input: DeleteUsersFromCompanyUserGroupInput
}

export type MutationDeleteVolunteerWorkArgs = {
  input: DeleteVolunteerWorkInput
}

export type MutationDisconnectCalendarArgs = {
  input: DisconnectCalendarInput
}

export type MutationDownloadDocumentTemplateArgs = {
  input: DownloadDocumentTemplateInput
}

export type MutationEditCalendarEventArgs = {
  input: EditCalendarEventInput
}

export type MutationEditCalendarSettingsArgs = {
  input?: InputMaybe<EditCalendarSettingsInput>
}

export type MutationEditGlobalScreeningQuestionArgs = {
  input: EditGlobalScreeningQuestionInput
}

export type MutationExchangeNylasTokenArgs = {
  input: ExchangeNylasTokenInput
}

export type MutationGenerateFilesFromHtmlArgs = {
  input: GenerateFilesFromHtmlInput
}

export type MutationHtmlToBase64Args = {
  input: HtmlToBase64Input
}

export type MutationHtmlToDocxArgs = {
  input: HtmlToDocxInput
}

export type MutationInsertGlobalScreeningQuestionArgs = {
  input: Array<InsertGlobalScreeningQuestionInput>
}

export type MutationInviteCandidatesToApplyArgs = {
  input: InviteCandidatesToApplyInput
}

export type MutationInviteUserArgs = {
  input: InviteUserInput
}

export type MutationOrderJobCampaignArgs = {
  input: OrderCampaignInput
}

export type MutationProcessAutoRejectCandidatesArgs = {
  apiKey: Scalars['String']['input']
  input: AutoRejectCandidatesInput
}

export type MutationProcessRejectCandidatesArgs = {
  apiKey: Scalars['String']['input']
  companyUserId: Scalars['Int']['input']
  input: RejectCandidatesInput
  jobId: Scalars['String']['input']
}

export type MutationRejectCandidatesArgs = {
  delaySeconds?: InputMaybe<Scalars['Int']['input']>
  input: RejectCandidatesInput
}

export type MutationResendEmailVerificationArgs = {
  input: ResendEmailVerificationInput
}

export type MutationResendResetPasswordEmailArgs = {
  input: ResendResetPasswordEmailInput
}

export type MutationResendSchedulerLinkInviteArgs = {
  input: ResendSchedulerLinkInviteInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationScheduleReportArgs = {
  input: CreateScheduledReportInput
}

export type MutationSendCandidateOfferArgs = {
  input: SendCandidateOfferInput
}

export type MutationSendCandidateScorecardArgs = {
  input: SendCandidateScorecardInput
}

export type MutationSendCandidatesToSourcingStageArgs = {
  input: SendCandidatesToSourcingStageInput
}

export type MutationSendJobListingArgs = {
  input: SendJobListingInput
}

export type MutationSendReminderCandidateScorecardArgs = {
  input: SendReminderCandidateScorecardInput
}

export type MutationSendResetPasswordEmailArgs = {
  input: SendResetPasswordEmailInput
}

export type MutationSignUpArgs = {
  input: SignUpInput
}

export type MutationStartCandidateApplicationArgs = {
  input: StartCandidateApplicationInput
}

export type MutationSubscribeArgs = {
  input: SubscriptionInput
}

export type MutationToggleScorecardTemplateArchiveArgs = {
  input: ToggleScorecardTemplateArchiveInput
}

export type MutationUndoHireArgs = {
  input: UndoHireInput
}

export type MutationUndoOfferCandidateDeclinedArgs = {
  input: UndoOfferCandidateDeclinedInput
}

export type MutationUndoRejectCandidatesArgs = {
  input: UndoRejectCandidatesInput
}

export type MutationUpdateCandidateFilterArgs = {
  input: UpdateCandidateFilterInput
}

export type MutationUpdateCandidateOfferVersionStatusArgs = {
  input: UpdateCandidateOfferVersionStatusInput
}

export type MutationUpdateCompanyDataArgs = {
  input: UpdateCompanyDataInput
}

export type MutationUpdateCompanyUserGroupArgs = {
  input: UpdateCompanyUserGroupInput
}

export type MutationUpdateCompanyUserRoleArgs = {
  input: UpdateCompanyUserRoleInput
}

export type MutationUpdateDocumentTemplateArgs = {
  input: UpdateDocumentTemplateInput
}

export type MutationUpdateInterviewArgs = {
  input: UpdateInterviewInput
}

export type MutationUpdateJobInviteArgs = {
  input: UpdateJobInviteInput
}

export type MutationUpdateScalisUserDataArgs = {
  input: UpdateScalisUserDataInput
}

export type MutationUpdateScheduledReportArgs = {
  input: UpdateScheduledReportInput
}

export type MutationUpdateSchedulerLinkInviteArgs = {
  input: UpdateSchedulerLinkInviteInput
}

export type MutationUpdateSchedulerLinkSettingsArgs = {
  input: UpdateSchedulerLinkSettingsInput
}

export type MutationUpdateUsersInCompanyUserGroupArgs = {
  input: UpdateUsersInCompanyUserGroupInput
}

export type MutationUploadS3FileArgs = {
  input: UploadS3FileInput
}

export type MutationUpsertAddressArgs = {
  input: UpsertAddressInput
}

export type MutationUpsertApplicationFormArgs = {
  input: UpsertApplicationFormInput
}

export type MutationUpsertApplicationFormScreeningQuestionArgs = {
  input: UpsertApplicationFormScreeningQuestionInput
}

export type MutationUpsertCandidateApplicationNoteArgs = {
  input: UpsertCandidateApplicationNoteInput
}

export type MutationUpsertCandidateApplicationRatingArgs = {
  input: UpsertCandidateApplicationRatingInput
}

export type MutationUpsertCandidateCollaboratorArgs = {
  input: UpsertCandidateCollaboratorInput
}

export type MutationUpsertCandidateMessageArgs = {
  input: UpsertCandidateMessageInput
}

export type MutationUpsertCandidateOfferArgs = {
  input: UpsertCandidateOfferInput
}

export type MutationUpsertCandidateOfferCustomFieldArgs = {
  input: UpsertCandidateOfferCustomFieldInput
}

export type MutationUpsertCandidateOfferFileArgs = {
  input: UpsertCandidateOfferFileInput
}

export type MutationUpsertCandidateOfferVersionArgs = {
  input: UpsertCandidateOfferVersionInput
}

export type MutationUpsertCandidateProfileArgs = {
  input: UpsertCandidateProfileInput
}

export type MutationUpsertCandidateProfileNoteArgs = {
  input: UpsertCandidateProfileNoteInput
}

export type MutationUpsertCandidateScorecardArgs = {
  input: UpsertCandidateScorecardInput
}

export type MutationUpsertCandidateScorecardAnswersArgs = {
  input: UpsertCandidateScorecardAnswersInput
}

export type MutationUpsertEmailArgs = {
  input: UpsertEmailInput
}

export type MutationUpsertExternalJobApplicationArgs = {
  input: ExternalApplicationInput
}

export type MutationUpsertHiringTeamMemberArgs = {
  input: UpsertHiringTeamMemberInput
}

export type MutationUpsertJobListingArgs = {
  input: UpsertJobListingInput
}

export type MutationUpsertJobListingJobPipelineArgs = {
  input: Array<UpsertJobListingJobPipelineInput>
}

export type MutationUpsertJobListingScreeningQuestionArgs = {
  input: UpsertJobListingScreeningQuestionInput
}

export type MutationUpsertJobOpeningArgs = {
  input: UpsertJobOpeningInput
}

export type MutationUpsertJobPipelineArgs = {
  input: UpsertJobPipelineInput
}

export type MutationUpsertOfferCustomFieldArgs = {
  input: UpsertOfferCustomFieldInput
}

export type MutationUpsertOfferDocumentTemplateArgs = {
  input: UpsertOfferDocumentTemplateInput
}

export type MutationUpsertOfferDocumentTemplateSectionArgs = {
  input: UpsertOfferDocumentTemplateSectionInput
}

export type MutationUpsertOfferSectionTemplateArgs = {
  input: UpsertOfferSectionTemplateInput
}

export type MutationUpsertOfferTemplateConditionArgs = {
  input: Array<UpsertOfferTemplateConditionInput>
}

export type MutationUpsertRequisitionArgs = {
  input: UpsertRequisitionInput
}

export type MutationUpsertRequisitionJobDescriptionArgs = {
  input: UpsertRequisitionJobDescriptionInput
}

export type MutationUpsertRequisitionSkillArgs = {
  input: UpsertRequisitionSkillInput
}

export type MutationUpsertScorecardTemplateArgs = {
  input: UpsertScorecardTemplateInput
}

export type MutationUpsertScreeningQuestionArgs = {
  input: UpsertScreeningQuestionInput
}

export type MutationUpsertSkillArgs = {
  input: Array<UpsertSkillInput>
}

export type MutationUpsertStageCollaboratorArgs = {
  input: UpsertStageCollaboratorInput
}

export type MutationUpsertStageSettingsArgs = {
  input: UpsertStageSettingsInput
}

export type MutationUpsertTemplateJobDescriptionArgs = {
  input: UpsertTemplateJobDescriptionInput
}

export type MutationUpsertTemplateMessageArgs = {
  input: UpsertTemplateMessageInput
}

export type MutationUpsertTemplateMilestoneArgs = {
  input: UpsertTemplateMilestoneInput
}

export type MutationUpsertTemplatePipelineArgs = {
  input: UpsertTemplatePipelineInput
}

export type MutationUpsertTemplateStageArgs = {
  input: UpsertTemplateStageInput
}

export type MutationUpsertTokenTestResultArgs = {
  input: UpsertTokenTestResultInput
}

export type MutationValidateOfferDocumentTemplateArgs = {
  input: ValidateOfferDocumentTemplateInput
}

export type MutationValidateOfferSectionTemplateArgs = {
  input: ValidateOfferSectionTemplateInput
}

export type MutationVerifyEmailCodeArgs = {
  input: VerifyEmailCodeInput
}

export type MutationVerifyPasswordCodeArgs = {
  input: VerifyPasswordCodeInput
}

export type MutationUpsertGlobalListingSetupArgs = {
  input: GlobalListingSetupInput
}

export enum NameEnum {
  ApplicationReview = 'APPLICATION_REVIEW',
  Hired = 'HIRED',
  Interviewing = 'INTERVIEWING',
  Offer = 'OFFER',
  PreScreen = 'PRE_SCREEN',
  Sourcing = 'SOURCING',
}

export type NestedValidationErrors = {
  __typename?: 'NestedValidationErrors'
  fields: Scalars['JSON']['output']
}

export enum NylasEventStatusesEnum {
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Tentative = 'tentative',
}

export type OfferCustomField = {
  __typename?: 'OfferCustomField'
  CandidateOfferCustomField?: Maybe<Array<CandidateOfferCustomField>>
  Company?: Maybe<Company>
  CompanyUser?: Maybe<CompanyUser>
  OfferTemplateCondition?: Maybe<Array<OfferTemplateCondition>>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  fieldConfigurationType: CustomFieldInputTypeEnum
  fieldDescription?: Maybe<Scalars['String']['output']>
  fieldName: Scalars['String']['output']
  fieldRequired: Scalars['Boolean']['output']
  generateSystemToken: Scalars['Boolean']['output']
  id: Scalars['Int']['output']
  multipleChoiceOptions?: Maybe<Scalars['JSON']['output']>
  systemTokenName?: Maybe<Scalars['String']['output']>
  templateConditionId?: Maybe<Scalars['Int']['output']>
  triggerNewOfferVersion: Scalars['Boolean']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type OfferDocumentTemplate = {
  __typename?: 'OfferDocumentTemplate'
  Company?: Maybe<Company>
  CompanyUser?: Maybe<CompanyUser>
  OfferDocumentTemplateSection?: Maybe<Array<OfferDocumentTemplateSection>>
  OfferTemplateCondition?: Maybe<Array<OfferTemplateCondition>>
  TokenTestResult?: Maybe<Array<TokenTestResult>>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  documentFileName?: Maybe<Scalars['String']['output']>
  documentFilePath?: Maybe<Scalars['String']['output']>
  documentTemplateName: Scalars['String']['output']
  documentTemplateStatus: OfferDocumentTemplateStatusEnum
  documentTemplateText: Scalars['String']['output']
  documentType: OfferDocumentTemplateTypeEnum
  id: Scalars['ID']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  validationResult?: Maybe<Scalars['JSON']['output']>
}

export type OfferDocumentTemplateSection = {
  __typename?: 'OfferDocumentTemplateSection'
  OfferDocumentTemplate: OfferDocumentTemplate
  OfferSectionTemplate: OfferSectionTemplate
  offerDocumentTemplateId: Scalars['Int']['output']
  offerSectionTemplateId: Scalars['Int']['output']
}

export enum OfferDocumentTemplateStatusEnum {
  Failed = 'FAILED',
  NotTested = 'NOT_TESTED',
  Verified = 'VERIFIED',
}

export enum OfferDocumentTemplateTypeEnum {
  Nda = 'NDA',
  OfferLetter = 'OFFER_LETTER',
  Other = 'OTHER',
}

export enum OfferFileTypeEnum {
  AdditionalDocument = 'ADDITIONAL_DOCUMENT',
  Docx = 'DOCX',
  Pdf = 'PDF',
  SignedDocument = 'SIGNED_DOCUMENT',
}

export type OfferSectionTemplate = {
  __typename?: 'OfferSectionTemplate'
  Company?: Maybe<Company>
  CompanyUser?: Maybe<CompanyUser>
  OfferDocumentTemplateSection?: Maybe<Array<OfferDocumentTemplateSection>>
  OfferTemplateCondition?: Maybe<Array<OfferTemplateCondition>>
  TokenTestResult?: Maybe<Array<TokenTestResult>>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  sectionTemplateName: Scalars['String']['output']
  sectionTemplateStatus: TokenStatusEnum
  sectionTemplateText: Scalars['String']['output']
  sectionToken: Scalars['String']['output']
  templateConditionId?: Maybe<Scalars['Int']['output']>
  tokenDescription?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  validationResult?: Maybe<Scalars['JSON']['output']>
}

export enum OfferStatusEnum {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Deleted = 'DELETED',
  NotCreated = 'NOT_CREATED',
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
  SentAwaitingSignature = 'SENT_AWAITING_SIGNATURE',
  Signed = 'SIGNED',
  Voided = 'VOIDED',
}

export type OfferTemplateCondition = {
  __typename?: 'OfferTemplateCondition'
  CompanyDepartment?: Maybe<CompanyDepartment>
  CompanyOffice?: Maybe<CompanyOffice>
  OfferCustomField?: Maybe<OfferCustomField>
  OfferDocumentTemplate?: Maybe<OfferDocumentTemplate>
  OfferSectionTemplate?: Maybe<OfferSectionTemplate>
  conditionType: ConditionTypeEnum
  createdAt?: Maybe<Scalars['DateTime']['output']>
  customFieldId?: Maybe<Scalars['Int']['output']>
  departmentId?: Maybe<Scalars['Int']['output']>
  employmentType?: Maybe<JobTypeEnum>
  id: Scalars['ID']['output']
  offerDocumentTemplateId?: Maybe<Scalars['Int']['output']>
  officeId?: Maybe<Scalars['Int']['output']>
  payType?: Maybe<PayTypeEnum>
  sectionTemplateId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum OpeningStatusEnum {
  Closed = 'CLOSED',
  Filled = 'FILLED',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
}

export enum OpeningTypeEnum {
  InternalHire = 'INTERNAL_HIRE',
  New = 'NEW',
  Replacement = 'REPLACEMENT',
}

export type Order = {
  __typename?: 'Order'
  Campaigns?: Maybe<JobCampaignResponse>
  Company: Company
  /** @deprecated Use campaignTraffic.applicants instead */
  applicantsCount?: Maybe<Scalars['JSON']['output']>
  campaignTraffic?: Maybe<Scalars['JSON']['output']>
  companyId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  orderReference: Scalars['String']['output']
  status: PaymentStatusEnum
  subTotal: Scalars['Float']['output']
  total: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
  vat: Scalars['Float']['output']
}

export type OrderCampaignsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type OrderCampaignInput = {
  campaignName: Scalars['String']['input']
  items: Array<CampaignOrderItemInput>
  orderReference?: InputMaybe<Scalars['String']['input']>
  requisitionId: Scalars['Int']['input']
  targetGroup: TargetGroupInput
}

export type OrderCampaignPrice = {
  __typename?: 'OrderCampaignPrice'
  subTotal: Scalars['Float']['output']
  total: Scalars['Float']['output']
  vat: Scalars['Float']['output']
}

export type OrderedProductsSpec = {
  __typename?: 'OrderedProductsSpec'
  productId?: Maybe<Scalars['String']['output']>
  utm?: Maybe<Scalars['String']['output']>
}

export enum OrganizationRoleEnum {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
  SuperAdmin = 'SUPER_ADMIN',
}

export enum OverallRecommendationEnum {
  Neutral = 'NEUTRAL',
  No = 'NO',
  Yes = 'YES',
}

export enum PayTypeEnum {
  Hourly = 'HOURLY',
  Salary = 'SALARY',
}

export enum PaymentStatusEnum {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
}

export enum PermissionEnum {
  CandidatesAdvance = 'CANDIDATES__ADVANCE',
  CandidatesMessage = 'CANDIDATES__MESSAGE',
  CandidatesReject = 'CANDIDATES__REJECT',
  CandidatesUpload = 'CANDIDATES__UPLOAD',
  CandidateCollaboratorDelete = 'CANDIDATE_COLLABORATOR_DELETE',
  CandidateCollaboratorUpsert = 'CANDIDATE_COLLABORATOR_UPSERT',
  CandidateMessageDelete = 'CANDIDATE_MESSAGE_DELETE',
  CandidateMessageUpsert = 'CANDIDATE_MESSAGE_UPSERT',
  CandidateOfferCustomFieldDelete = 'CANDIDATE_OFFER_CUSTOM_FIELD_DELETE',
  CandidateOfferCustomFieldUpsert = 'CANDIDATE_OFFER_CUSTOM_FIELD_UPSERT',
  CandidateOfferDelete = 'CANDIDATE_OFFER_DELETE',
  CandidateOfferFileDelete = 'CANDIDATE_OFFER_FILE_DELETE',
  CandidateOfferFileUpsert = 'CANDIDATE_OFFER_FILE_UPSERT',
  CandidateOfferUpsert = 'CANDIDATE_OFFER_UPSERT',
  CandidateOfferVersionDelete = 'CANDIDATE_OFFER_VERSION_DELETE',
  CandidateOfferVersionUpsert = 'CANDIDATE_OFFER_VERSION_UPSERT',
  CandidateProfilesDelete = 'CANDIDATE_PROFILES__DELETE',
  CandidateProfilesEdit = 'CANDIDATE_PROFILES__EDIT',
  CandidateProfilesView = 'CANDIDATE_PROFILES__VIEW',
  CompanySettingsEdit = 'COMPANY_SETTINGS__EDIT',
  CustomFieldDelete = 'CUSTOM_FIELD_DELETE',
  CustomFieldUpsert = 'CUSTOM_FIELD_UPSERT',
  DefaultTokenDelete = 'DEFAULT_TOKEN_DELETE',
  DefaultTokenUpsert = 'DEFAULT_TOKEN_UPSERT',
  DemographicInformationView = 'DEMOGRAPHIC_INFORMATION__VIEW',
  FeedbackSubmit = 'FEEDBACK__SUBMIT',
  FeedbackView = 'FEEDBACK__VIEW',
  HiringTeamMemberDelete = 'HIRING_TEAM_MEMBER_DELETE',
  HiringTeamMemberUpsert = 'HIRING_TEAM_MEMBER_UPSERT',
  InterviewsDelete = 'INTERVIEWS__DELETE',
  InterviewsEdit = 'INTERVIEWS__EDIT',
  InterviewsSchedule = 'INTERVIEWS__SCHEDULE',
  InterviewsView = 'INTERVIEWS__VIEW',
  JobsDelete = 'JOBS__DELETE',
  JobsEdit = 'JOBS__EDIT',
  JobsView = 'JOBS__VIEW',
  JobListingDelete = 'JOB_LISTING_DELETE',
  JobListingScreeningQuestionDelete = 'JOB_LISTING_SCREENING_QUESTION_DELETE',
  JobListingScreeningQuestionUpsert = 'JOB_LISTING_SCREENING_QUESTION_UPSERT',
  JobListingUpsert = 'JOB_LISTING_UPSERT',
  JobOpeningDelete = 'JOB_OPENING_DELETE',
  JobOpeningUpsert = 'JOB_OPENING_UPSERT',
  JobPipelineDelete = 'JOB_PIPELINE_DELETE',
  JobPipelineUpsert = 'JOB_PIPELINE_UPSERT',
  JobPostingsCreate = 'JOB_POSTINGS__CREATE',
  JobPostingsDelete = 'JOB_POSTINGS__DELETE',
  JobPostingsEdit = 'JOB_POSTINGS__EDIT',
  JobPostingsView = 'JOB_POSTINGS__VIEW',
  MessageTemplatesEdit = 'MESSAGE_TEMPLATES__EDIT',
  OfferCustomFieldDelete = 'OFFER_CUSTOM_FIELD_DELETE',
  OfferCustomFieldUpsert = 'OFFER_CUSTOM_FIELD_UPSERT',
  OfferDocumentTemplateDelete = 'OFFER_DOCUMENT_TEMPLATE_DELETE',
  OfferDocumentTemplateSectionDelete = 'OFFER_DOCUMENT_TEMPLATE_SECTION_DELETE',
  OfferDocumentTemplateSectionUpsert = 'OFFER_DOCUMENT_TEMPLATE_SECTION_UPSERT',
  OfferDocumentTemplateUpsert = 'OFFER_DOCUMENT_TEMPLATE_UPSERT',
  OfferDocumentTemplateValidate = 'OFFER_DOCUMENT_TEMPLATE_VALIDATE',
  OfferSectionTemplateDelete = 'OFFER_SECTION_TEMPLATE_DELETE',
  OfferSectionTemplateUpsert = 'OFFER_SECTION_TEMPLATE_UPSERT',
  OfferTemplateConditionDelete = 'OFFER_TEMPLATE_CONDITION_DELETE',
  OfferTemplateConditionUpsert = 'OFFER_TEMPLATE_CONDITION_UPSERT',
  PrivateNotesView = 'PRIVATE_NOTES__VIEW',
  ReferralsSubmit = 'REFERRALS__SUBMIT',
  ReportsGenerate = 'REPORTS__GENERATE',
  ReportsView = 'REPORTS__VIEW',
  RequisitionDelete = 'REQUISITION_DELETE',
  RequisitionJobDescriptionDelete = 'REQUISITION_JOB_DESCRIPTION_DELETE',
  RequisitionJobDescriptionUpsert = 'REQUISITION_JOB_DESCRIPTION_UPSERT',
  RequisitionSkillDelete = 'REQUISITION_SKILL_DELETE',
  RequisitionSkillUpsert = 'REQUISITION_SKILL_UPSERT',
  RequisitionUpsert = 'REQUISITION_UPSERT',
  ScorecardSubmit = 'SCORECARD__SUBMIT',
  ScorecardView = 'SCORECARD__VIEW',
  ScreeningQuestionAnswerDelete = 'SCREENING_QUESTION_ANSWER_DELETE',
  ScreeningQuestionAnswerUpsert = 'SCREENING_QUESTION_ANSWER_UPSERT',
  ScreeningQuestionDelete = 'SCREENING_QUESTION_DELETE',
  ScreeningQuestionUpsert = 'SCREENING_QUESTION_UPSERT',
  SectionTemplateDelete = 'SECTION_TEMPLATE_DELETE',
  SectionTemplateUpsert = 'SECTION_TEMPLATE_UPSERT',
  StageCollaboratorDelete = 'STAGE_COLLABORATOR_DELETE',
  StageCollaboratorUpsert = 'STAGE_COLLABORATOR_UPSERT',
  StageSettingsDelete = 'STAGE_SETTINGS_DELETE',
  StageSettingsUpsert = 'STAGE_SETTINGS_UPSERT',
  TemplateJobDescriptionDelete = 'TEMPLATE_JOB_DESCRIPTION_DELETE',
  TemplateJobDescriptionUpsert = 'TEMPLATE_JOB_DESCRIPTION_UPSERT',
  TemplateMessageDelete = 'TEMPLATE_MESSAGE_DELETE',
  TemplateMessageUpsert = 'TEMPLATE_MESSAGE_UPSERT',
  TemplateMilestoneDelete = 'TEMPLATE_MILESTONE_DELETE',
  TemplateMilestoneUpsert = 'TEMPLATE_MILESTONE_UPSERT',
  TemplatePipelineDelete = 'TEMPLATE_PIPELINE_DELETE',
  TemplatePipelineUpsert = 'TEMPLATE_PIPELINE_UPSERT',
  TemplateStageDelete = 'TEMPLATE_STAGE_DELETE',
  TemplateStageUpsert = 'TEMPLATE_STAGE_UPSERT',
  TokenTestResultDelete = 'TOKEN_TEST_RESULT_DELETE',
  TokenTestResultUpsert = 'TOKEN_TEST_RESULT_UPSERT',
  UsersPermissionsEdit = 'USERS_PERMISSIONS__EDIT',
  UsersInvite = 'USERS__INVITE',
}

export enum PositionsToFillEnum {
  Count_1To_2 = 'COUNT_1_TO_2',
  Count_2To_5 = 'COUNT_2_TO_5',
  Count_5To_20 = 'COUNT_5_TO_20',
  Count_21Plus = 'COUNT_21_PLUS',
}

export type Posting = {
  __typename?: 'Posting'
  clicks: Scalars['Int']['output']
  name: Scalars['String']['output']
  productId: Scalars['String']['output']
}

export type PostingContactInfo = {
  __typename?: 'PostingContactInfo'
  emailAddress?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
}

export type PostingDetails = {
  __typename?: 'PostingDetails'
  applicationUrl: Scalars['String']['output']
  contactInfo?: Maybe<PostingContactInfo>
  description: Scalars['String']['output']
  employmentType: EmploymentTypeEnum
  jobPageUrl: Scalars['String']['output']
  organization: PostingOrganization
  salaryIndication?: Maybe<SalaryIndication>
  title: Scalars['String']['output']
  weeklyWorkingHours: PostingWeeklyWorkingHours
  workingLocation: PostingWorkingLocation
  yearsOfExperience: Scalars['Int']['output']
}

export type PostingOrganization = {
  __typename?: 'PostingOrganization'
  companyLogo: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type PostingWeeklyWorkingHours = {
  __typename?: 'PostingWeeklyWorkingHours'
  from?: Maybe<Scalars['Int']['output']>
  to: Scalars['Int']['output']
}

export type PostingWorkingLocation = {
  __typename?: 'PostingWorkingLocation'
  addressLine1: Scalars['String']['output']
  addressLine2?: Maybe<Scalars['String']['output']>
  allowsRemoteWork?: Maybe<Scalars['Int']['output']>
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  postcode: Scalars['String']['output']
}

export enum PreferredAnswerTypeEnum {
  AllOf = 'ALL_OF',
  AtLeastOne = 'AT_LEAST_ONE',
  None = 'NONE',
}

export type Price = {
  __typename?: 'Price'
  amount: Scalars['Float']['output']
  currency: Scalars['String']['output']
}

export type ProcessTime = {
  __typename?: 'ProcessTime'
  period: Scalars['Int']['output']
  range: Scalars['String']['output']
}

export type ProductFiltersInput = {
  includeLocationId?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['String']['input']>
  jobFunctionId?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<SortByJobProductsEnum>
}

export type ProfileDetails = {
  __typename?: 'ProfileDetails'
  CandidateMedalistDesignation: Array<CandidateMedalistDesignation>
  CandidateProfileLocation: Array<CandidateProfileLocation>
  CandidateSource: Array<CandidateSource>
  candidateProfileId?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  profileLink?: Maybe<Scalars['String']['output']>
  secondaryEmail?: Maybe<Scalars['String']['output']>
  timezone?: Maybe<Scalars['String']['output']>
}

export type ProfileDetailsInput = {
  CandidateMedalistDesignation?: InputMaybe<Array<CandidateMedalistDesignationInput>>
  CandidateProfileLocation?: InputMaybe<Array<CandidateProfileLocationInput>>
  CandidateSource?: InputMaybe<Array<CandidateSourceInput>>
  id?: InputMaybe<Scalars['Int']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  profileLink?: InputMaybe<Scalars['String']['input']>
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  timezone?: InputMaybe<Scalars['String']['input']>
}

export enum ProfileTypeEnum {
  JobSeeker = 'JOB_SEEKER',
  UploadedCandidate = 'UPLOADED_CANDIDATE',
}

export type Query = {
  __typename?: 'Query'
  CampaignJobFunctions?: Maybe<Array<JobFunction>>
  CountBookmarkedJob?: Maybe<CountBookmarkedJob>
  CountCalendarEvents?: Maybe<CountOutput>
  CountCandidateApplication?: Maybe<CountOutput>
  CountCandidateProfile?: Maybe<CountOutput>
  CountCandidateScorecard?: Maybe<CountOutput>
  CountCompanyInfo?: Maybe<CountOutput>
  CountCoreReport: Scalars['Int']['output']
  CountExternalJobApplication?: Maybe<CountOutput>
  CountFavoriteCoreReport: Scalars['Int']['output']
  CountJobInvite?: Maybe<CountOutput>
  CountReferrals: ReferralsCount
  CountRequisition?: Maybe<CountOutput>
  CountScheduledReport: Scalars['Int']['output']
  CountScorecardTemplate?: Maybe<CountOutput>
  CountSharedCoreReport: Scalars['Int']['output']
  CountSkills?: Maybe<CountOutput>
  GetApplicantNotes?: Maybe<Array<ApplicantNotes>>
  GetApplicationForm?: Maybe<ApplicationForm>
  GetBookmarkedJob?: Maybe<Array<BookmarkedJob>>
  GetCalendarEvents?: Maybe<Array<CalendarEvent>>
  GetCalendarSettings?: Maybe<CalendarSettings>
  GetCampaign: JobCampaign
  GetCandidateApplication?: Maybe<Array<CandidateApplication>>
  GetCandidateApplicationFilterContent: CandidateApplicationFilterContent
  GetCandidateCollaborator?: Maybe<CandidateCollaborator>
  GetCandidateFilter?: Maybe<Array<CandidateFilter>>
  GetCandidateMessage?: Maybe<CandidateMessage>
  GetCandidateOffer?: Maybe<Array<CandidateOffer>>
  GetCandidateOfferCustomField?: Maybe<Array<CandidateOfferCustomField>>
  GetCandidateOfferFile?: Maybe<Array<CandidateOfferFile>>
  GetCandidateOfferFileUrl?: Maybe<Array<GetCandidateOfferFileUrlOutput>>
  GetCandidateOfferVersionEmbeddedClaimUrl: GetCandidateOfferVersionEmbeddedClaimUrlResult
  GetCandidateProfile?: Maybe<Array<CandidateProfile>>
  GetCandidateScorecard?: Maybe<Array<CandidateScorecard>>
  GetCandidateSourceNames?: Maybe<Array<CandidateSourceName>>
  GetCandidateStats?: Maybe<CandidateStats>
  GetChatUsersJSV2: Array<ChatUserInfoJs>
  GetChatUsersV2: Array<ChatUserInfo>
  GetChatsJSV2: Array<ChatInfoJs>
  GetChatsV2: Array<ChatInfo>
  GetCompanyInfo?: Maybe<Array<Company>>
  GetCompanyUsers?: Maybe<Array<CompanyUser>>
  GetCoreReport?: Maybe<Array<CoreReport>>
  GetCoreReportCsv?: Maybe<CoreReportCsvOutput>
  GetCoreReportData?: Maybe<CoreReportDataOutput>
  GetDefaultToken?: Maybe<Array<DefaultToken>>
  GetEmailActivation: Scalars['Boolean']['output']
  GetExternalJobApplication?: Maybe<Array<ExternalJobApplication>>
  GetExternalJobListing?: Maybe<ExternalJob>
  GetFavoriteCoreReport?: Maybe<Array<FavoriteCoreReport>>
  GetFeatureUsageLimit?: Maybe<AiFeatureUsageLimit>
  GetGlobalScreeningQuestions?: Maybe<Array<ScreeningQuestion>>
  GetHiringTeamMember?: Maybe<HiringTeamMember>
  GetJobCampaignEducationLevels?: Maybe<Array<JobCampaignEducationLevel>>
  GetJobCampaignProduct?: Maybe<JobCampaignProduct>
  GetJobCampaignProducts?: Maybe<JobCampaignProducts>
  GetJobCampaignProductsById?: Maybe<JobCampaignProducts>
  GetJobCampaignSeniority: Array<JobCampaignSeniority>
  GetJobCampaigns?: Maybe<JobCampaignResponse>
  GetJobInvite?: Maybe<Array<JobInvite>>
  GetJobListing?: Maybe<Array<JobListing>>
  GetJobListingScreeningQuestion?: Maybe<Array<JobListingScreeningQuestion>>
  GetJobPipeline?: Maybe<Array<JobPipeline>>
  /** Get scheduler link settings */
  GetManySchedulerLinkSettings: Array<SchedulerLinkSettings>
  /** Retrieves offer custom fields based on the provided criteria */
  GetOfferCustomField?: Maybe<Array<OfferCustomField>>
  GetOfferDocumentTemplate?: Maybe<Array<OfferDocumentTemplate>>
  GetOfferDocumentTemplateSection?: Maybe<Array<OfferDocumentTemplateSection>>
  GetOfferSectionTemplate?: Maybe<Array<OfferSectionTemplate>>
  GetOfferTemplateCondition?: Maybe<Array<OfferTemplateCondition>>
  GetOneCandidateProfile?: Maybe<CandidateProfile>
  GetOrders: Array<Order>
  GetPlace: Place
  GetPlacesPrediction: Array<PlacePrediction>
  GetPublicJobListing?: Maybe<JobListing>
  GetRejectCandidateQuestions?: Maybe<Array<RejectCandidateQuestion>>
  GetRequisition?: Maybe<Requisition>
  GetRequisitionExtId?: Maybe<RequisitionExtId>
  GetRequisitionJobDescription?: Maybe<Array<RequisitionJobDescription>>
  GetRequisitionJobDescriptionByRequisition?: Maybe<RequisitionJobDescription>
  GetRequisitionSkill?: Maybe<RequisitionSkill>
  GetRequisitions?: Maybe<Array<Requisition>>
  GetRoles?: Maybe<Array<Role>>
  GetScalisUser?: Maybe<ScalisUser>
  GetScheduledReport?: Maybe<Array<ScheduledReport>>
  /** Get scheduler link settings */
  GetSchedulerLinkSettings?: Maybe<SchedulerLinkSettings>
  GetScorecardTemplate?: Maybe<Array<ScorecardTemplate>>
  GetScorecardTemplates?: Maybe<Array<ScorecardTemplate>>
  GetSharedCoreReport?: Maybe<Array<SharedCoreReport>>
  GetSkills?: Maybe<Array<Skill>>
  GetStageCollaborator?: Maybe<StageCollaborator>
  GetStageSettings?: Maybe<StageSettings>
  GetTemplateJobDescription?: Maybe<Array<TemplateJobDescription>>
  GetTemplateMessage?: Maybe<TemplateMessage>
  GetTemplateMilestone?: Maybe<TemplateMilestone>
  GetTemplatePipeline?: Maybe<TemplatePipeline>
  GetTemplateStage?: Maybe<Array<TemplateStage>>
  GetTemporaryS3Url?: Maybe<GetTemporaryS3UrlOutput>
  GetTokenTestResult?: Maybe<Array<TokenTestResult>>
  GetUploadedCandidate?: Maybe<UploadedCandidate>
  GetUserInvitations?: Maybe<Array<UserInvitation>>
  JobCampaignIndustries?: Maybe<Array<JobCampaignIndustry>>
  ListApplicationFormScreeningQuestions?: Maybe<Array<ApplicationFormScreeningQuestion>>
  ListCompanyDepartments?: Maybe<Array<CompanyDepartment>>
  ListCompanyOffices?: Maybe<Array<CompanyOffice>>
  ListCompanyUserGroups: Array<CompanyUserGroup>
  ListGlobalScreeningQuestions?: Maybe<Array<ScreeningQuestion>>
  ListInvoices?: Maybe<ListInvoices>
  ListReferrals: Array<UploadedCandidate>
  ListSubscriptions?: Maybe<Array<StripeSubscription>>
  ListTemplateMessages?: Maybe<Array<TemplateMessage>>
  ListTemplatePipelines?: Maybe<Array<TemplatePipeline>>
  Me?: Maybe<ScalisUser>
  ReferralsStats: ReferralsStats
  /** Replaces tokens in a given text with system-defined and offer-specific tokens. */
  ReplaceTokens?: Maybe<ReplaceTokensOutput>
  Search?: Maybe<SearchResult>
  SearchCandidatesFilterContent: SearchCandidatesFilterContentOutput
  SearchJobCampaignLocations?: Maybe<Array<JobCampaignLocation>>
  getGlobalListingSetup?: Maybe<GlobalListingSetup>
}

export type QueryCountBookmarkedJobArgs = {
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
}

export type QueryCountCalendarEventsArgs = {
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryCountCandidateApplicationArgs = {
  applicationStatus?: InputMaybe<Array<ApplicationStatusEnum>>
  applicationStatusNotIn?: InputMaybe<Array<ApplicationStatusEnum>>
  applicationType?: InputMaybe<Array<ApplicationTypeEnum>>
  candidateProfileId?: InputMaybe<Array<Scalars['Int']['input']>>
  companies?: InputMaybe<Array<Scalars['String']['input']>>
  companyId?: InputMaybe<Scalars['Int']['input']>
  creditedCompanyUserIds?: InputMaybe<Array<Scalars['Int']['input']>>
  educationLevel?: InputMaybe<Array<EducationLevelEnum>>
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingIds?: InputMaybe<Array<Scalars['Int']['input']>>
  jobTitles?: InputMaybe<Array<Scalars['String']['input']>>
  lastActivity?: InputMaybe<LastActivity>
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
  majors?: InputMaybe<Array<Scalars['String']['input']>>
  matchScore?: InputMaybe<MatchScore>
  medalistDesignation?: InputMaybe<Array<Scalars['String']['input']>>
  nonTraditionalStatus?: InputMaybe<Scalars['Boolean']['input']>
  profileType?: InputMaybe<ProfileTypeEnum>
  publicListingIds?: InputMaybe<Array<Scalars['String']['input']>>
  raceEthnicity?: InputMaybe<Array<RaceEthnicityEnum>>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
  schools?: InputMaybe<Array<Scalars['String']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
  skills?: InputMaybe<Array<Scalars['String']['input']>>
  sourceCategory?: InputMaybe<Array<SourceCategoryEnum>>
  sourceName?: InputMaybe<Array<Scalars['String']['input']>>
  templateMilestoneNotIn?: InputMaybe<Array<NameEnum>>
  templateStageIds?: InputMaybe<Array<Scalars['Int']['input']>>
  timezones?: InputMaybe<Array<Scalars['String']['input']>>
  veteranStatus?: InputMaybe<Array<VeteranStatusEnum>>
  willingToRelocate?: InputMaybe<Array<WillingToRelocateEnum>>
  yearsOfExperience?: InputMaybe<YearsOfExperience>
}

export type QueryCountCandidateProfileArgs = {
  applicationFilters?: InputMaybe<ApplicationFiltersOnCandidateProfile>
  candidateProfileId?: InputMaybe<Scalars['Int']['input']>
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCountCandidateScorecardArgs = {
  candidateApplicationIds?: InputMaybe<Array<Scalars['Int']['input']>>
  candidateProfileIds?: InputMaybe<Array<Scalars['Int']['input']>>
  ids?: InputMaybe<Array<Scalars['Int']['input']>>
  jobListingIds?: InputMaybe<Array<Scalars['Int']['input']>>
  publicListingIds?: InputMaybe<Array<Scalars['String']['input']>>
  status?: InputMaybe<Array<ScorecardStatusTypeEnum>>
}

export type QueryCountCompanyInfoArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  subdomain?: InputMaybe<Scalars['String']['input']>
}

export type QueryCountCoreReportArgs = {
  coreReportName?: InputMaybe<CoreReportNameEnum>
  id?: InputMaybe<Scalars['Int']['input']>
  tab?: InputMaybe<ReportFilterTabEnum>
}

export type QueryCountExternalJobApplicationArgs = {
  search?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<ExternalApplicationStatus>>
}

export type QueryCountFavoriteCoreReportArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCountJobInviteArgs = {
  inviteStatus?: InputMaybe<Array<InviteStatusEnum>>
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCountReferralsArgs = {
  input?: InputMaybe<FilterReferralsInput>
}

export type QueryCountRequisitionArgs = {
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCountScheduledReportArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCountScorecardTemplateArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  companyTemplate?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  scorecardName?: InputMaybe<Scalars['String']['input']>
}

export type QueryCountSharedCoreReportArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCountSkillsArgs = {
  experienceId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetApplicantNotesArgs = {
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetApplicationFormArgs = {
  input: GetApplicationFormInput
}

export type QueryGetBookmarkedJobArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<SortByBoomarkedJob>
  sortDirection?: InputMaybe<SortDirection>
}

export type QueryGetCalendarEventsArgs = {
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  sortDirection?: InputMaybe<SortDirection>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryGetCampaignArgs = {
  input: GetCampaignInput
}

export type QueryGetCandidateApplicationArgs = {
  applicationStatus?: InputMaybe<Array<ApplicationStatusEnum>>
  applicationStatusNotIn?: InputMaybe<Array<ApplicationStatusEnum>>
  applicationType?: InputMaybe<Array<ApplicationTypeEnum>>
  candidateProfileId?: InputMaybe<Array<Scalars['Int']['input']>>
  companies?: InputMaybe<Array<Scalars['String']['input']>>
  companyId?: InputMaybe<Scalars['Int']['input']>
  creditedCompanyUserIds?: InputMaybe<Array<Scalars['Int']['input']>>
  educationLevel?: InputMaybe<Array<EducationLevelEnum>>
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingIds?: InputMaybe<Array<Scalars['Int']['input']>>
  jobTitles?: InputMaybe<Array<Scalars['String']['input']>>
  lastActivity?: InputMaybe<LastActivity>
  limit?: InputMaybe<Scalars['Int']['input']>
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
  majors?: InputMaybe<Array<Scalars['String']['input']>>
  matchScore?: InputMaybe<MatchScore>
  medalistDesignation?: InputMaybe<Array<Scalars['String']['input']>>
  nonTraditionalStatus?: InputMaybe<Scalars['Boolean']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  profileType?: InputMaybe<ProfileTypeEnum>
  publicListingIds?: InputMaybe<Array<Scalars['String']['input']>>
  raceEthnicity?: InputMaybe<Array<RaceEthnicityEnum>>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
  schools?: InputMaybe<Array<Scalars['String']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
  skills?: InputMaybe<Array<Scalars['String']['input']>>
  sortBy?: InputMaybe<SortByApplication>
  sortDirection?: InputMaybe<SortDirection>
  sourceCategory?: InputMaybe<Array<SourceCategoryEnum>>
  sourceName?: InputMaybe<Array<Scalars['String']['input']>>
  templateMilestoneNotIn?: InputMaybe<Array<NameEnum>>
  templateStageIds?: InputMaybe<Array<Scalars['Int']['input']>>
  timezones?: InputMaybe<Array<Scalars['String']['input']>>
  veteranStatus?: InputMaybe<Array<VeteranStatusEnum>>
  willingToRelocate?: InputMaybe<Array<WillingToRelocateEnum>>
  yearsOfExperience?: InputMaybe<YearsOfExperience>
}

export type QueryGetCandidateApplicationFilterContentArgs = {
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  publicListingId?: InputMaybe<Scalars['String']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetCandidateCollaboratorArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetCandidateFilterArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetCandidateMessageArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetCandidateOfferArgs = {
  input: GetCandidateOfferInput
}

export type QueryGetCandidateOfferCustomFieldArgs = {
  input: GetCandidateOfferCustomFieldInput
}

export type QueryGetCandidateOfferFileArgs = {
  input: GetCandidateOfferFileUrlInput
}

export type QueryGetCandidateOfferFileUrlArgs = {
  input: GetCandidateOfferFileUrlInput
}

export type QueryGetCandidateOfferVersionEmbeddedClaimUrlArgs = {
  input: GetCandidateOfferVersionEmbeddedClaimUrlInput
}

export type QueryGetCandidateProfileArgs = {
  applicationFilters?: InputMaybe<ApplicationFiltersOnCandidateProfile>
  candidateProfileIds?: InputMaybe<Array<Scalars['Int']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetCandidateScorecardArgs = {
  input: GetCandidateScorecardInput
}

export type QueryGetCandidateSourceNamesArgs = {
  input?: InputMaybe<CandidateSourceNameInput>
}

export type QueryGetCandidateStatsArgs = {
  candidateProfileId: Scalars['Int']['input']
  jobListingId: Scalars['String']['input']
}

export type QueryGetCompanyInfoArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  subdomain?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetCoreReportArgs = {
  coreReportName?: InputMaybe<CoreReportNameEnum>
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  tab?: InputMaybe<ReportFilterTabEnum>
}

export type QueryGetCoreReportCsvArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetCoreReportDataArgs = {
  filters?: InputMaybe<Array<FilterCriteriaInput>>
  generateAvailableFilters?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['Int']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetDefaultTokenArgs = {
  input: GetDefaultTokenInput
}

export type QueryGetEmailActivationArgs = {
  email: Scalars['String']['input']
}

export type QueryGetExternalJobApplicationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<SortByExternalApplication>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<Array<ExternalApplicationStatus>>
}

export type QueryGetExternalJobListingArgs = {
  input: GetExternalJobListingInput
}

export type QueryGetFavoriteCoreReportArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetGlobalScreeningQuestionsArgs = {
  input: GetGlobalScreeningQuestionInput
}

export type QueryGetHiringTeamMemberArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetJobCampaignProductArgs = {
  input: JobCampaignProductInput
}

export type QueryGetJobCampaignProductsArgs = {
  input?: InputMaybe<ProductFiltersInput>
}

export type QueryGetJobCampaignProductsByIdArgs = {
  input: IdsArrayInput
}

export type QueryGetJobCampaignsArgs = {
  input?: InputMaybe<CampaignFiltersInput>
}

export type QueryGetJobInviteArgs = {
  inviteStatus?: InputMaybe<Array<InviteStatusEnum>>
  limit?: InputMaybe<Scalars['Int']['input']>
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<SortByJobInvite>
  sortDirection?: InputMaybe<SortDirection>
}

export type QueryGetJobListingArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  publicListingId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetJobListingScreeningQuestionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<SortDirection>
}

export type QueryGetJobPipelineArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  templatePipelineId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetManySchedulerLinkSettingsArgs = {
  input: GetManySchedulerLinkSettingsInput
}

export type QueryGetOfferCustomFieldArgs = {
  input: GetOfferCustomFieldInput
}

export type QueryGetOfferDocumentTemplateArgs = {
  input: GetOfferDocumentTemplateInput
}

export type QueryGetOfferDocumentTemplateSectionArgs = {
  input: GetOfferDocumentTemplateSectionInput
}

export type QueryGetOfferSectionTemplateArgs = {
  input: GetOfferSectionTemplateInput
}

export type QueryGetOfferTemplateConditionArgs = {
  input: GetOfferTemplateConditionInput
}

export type QueryGetOneCandidateProfileArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetOrdersArgs = {
  input: GetOrdersInput
}

export type QueryGetPlaceArgs = {
  placeId: Scalars['String']['input']
}

export type QueryGetPlacesPredictionArgs = {
  input: Scalars['String']['input']
}

export type QueryGetPublicJobListingArgs = {
  publicListingId: Scalars['String']['input']
}

export type QueryGetRejectCandidateQuestionsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetRequisitionArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetRequisitionJobDescriptionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  requisitionId: Scalars['Int']['input']
}

export type QueryGetRequisitionJobDescriptionByRequisitionArgs = {
  requisitionId: Scalars['Int']['input']
}

export type QueryGetRequisitionSkillArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetRequisitionsArgs = {
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  companyId?: InputMaybe<Scalars['Int']['input']>
  companyOfficeId?: InputMaybe<Scalars['Int']['input']>
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  lastActivity?: InputMaybe<RequisitionLastActivity>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  pipeline?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<JobStatusEnum>
}

export type QueryGetScalisUserArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetScheduledReportArgs = {
  coreReportId: Scalars['Int']['input']
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetSchedulerLinkSettingsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetScorecardTemplateArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  companyTemplate?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  scorecardName?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<SortByScorecardTemplate>
  sortDirection?: InputMaybe<SortDirection>
  visible?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryGetScorecardTemplatesArgs = {
  input?: InputMaybe<GetScorecardTemplatesInput>
}

export type QueryGetSharedCoreReportArgs = {
  coreReportId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetSkillsArgs = {
  experienceId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetStageCollaboratorArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetStageSettingsArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetTemplateJobDescriptionArgs = {
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetTemplateMessageArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetTemplateMilestoneArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetTemplatePipelineArgs = {
  id: Scalars['Int']['input']
}

export type QueryGetTemplateStageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  milestoneIn?: InputMaybe<Array<NameEnum>>
  milestoneNotIn?: InputMaybe<Array<NameEnum>>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetTemporaryS3UrlArgs = {
  input: GetTemporaryS3UrlInput
}

export type QueryGetTokenTestResultArgs = {
  input: GetTokenTestResultInput
}

export type QueryGetUploadedCandidateArgs = {
  id: Scalars['Int']['input']
}

export type QueryListApplicationFormScreeningQuestionsArgs = {
  input: ListApplicationFormScreeningQuestionInput
}

export type QueryListCompanyDepartmentsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryListCompanyOfficesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryListCompanyUserGroupsArgs = {
  input: ListCompanyUserGroupsInput
}

export type QueryListGlobalScreeningQuestionsArgs = {
  input: ListGlobalScreeningQuestionInput
}

export type QueryListReferralsArgs = {
  input?: InputMaybe<FilterReferralsInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type QueryListTemplateMessagesArgs = {
  input: ListTemplateMessageInput
}

export type QueryListTemplatePipelinesArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryReferralsStatsArgs = {
  input: FilterReferralsInput
}

export type QueryReplaceTokensArgs = {
  input: ReplaceTokensInput
}

export type QuerySearchArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  educationLevel?: InputMaybe<EducationLevelPrefEnum>
  employment?: InputMaybe<JobTypeEnum>
  experienceLevel?: InputMaybe<JobLevelEnum>
  id?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  location?: InputMaybe<LocationInputType>
  page?: InputMaybe<Scalars['Int']['input']>
  payment?: InputMaybe<PayTypeEnum>
  salary?: InputMaybe<SalaryInputType>
  search?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<SearchSortEnum>
  source?: InputMaybe<SourceTypeEnum>
  tags?: InputMaybe<Scalars['String']['input']>
  workplace?: InputMaybe<LocationTypeEnum>
}

export type QuerySearchCandidatesFilterContentArgs = {
  filterType: FilterContentTypeEnum
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  publicListingId?: InputMaybe<Scalars['String']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  search: Scalars['String']['input']
}

export type QuerySearchJobCampaignLocationsArgs = {
  input: SearchJobCampaignLocationsInput
}

export type QueryGetGlobalListingSetupArgs = {
  companyId: Scalars['Int']['input']
}

export enum QuestionTypeEnum {
  File = 'FILE',
  FreeResponse = 'FREE_RESPONSE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleSelect = 'MULTIPLE_SELECT',
  Numerical = 'NUMERICAL',
  Url = 'URL',
  YesNo = 'YES_NO',
}

export enum RaceEthnicityEnum {
  AmericanIndianOrAlaskaNative = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  Asian = 'ASIAN',
  BlackOrAfricanAmerican = 'BLACK_OR_AFRICAN_AMERICAN',
  DeclineToSelfIdentify = 'DECLINE_TO_SELF_IDENTIFY',
  HispanicOrLatino = 'HISPANIC_OR_LATINO',
  NativeHawaiianOrOtherPacificIslander = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  TwoOrMoreRaces = 'TWO_OR_MORE_RACES',
  White = 'WHITE',
}

export type RecruiterInfo = {
  __typename?: 'RecruiterInfo'
  emailAddress?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export enum Recurrence {
  ScalisMonthly = 'scalis_monthly',
  ScalisYearly = 'scalis_yearly',
}

export type ReferCandidateInput = {
  creditCompanyUserId?: InputMaybe<Scalars['Int']['input']>
  documents?: InputMaybe<Array<Scalars['String']['input']>>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  jobListingId: Scalars['Int']['input']
  lastName: Scalars['String']['input']
  linkedinProfile?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  sourceNameId: Scalars['Int']['input']
  sourceStrategy?: InputMaybe<Scalars['Int']['input']>
}

export type ReferralsCount = {
  __typename?: 'ReferralsCount'
  count: Scalars['Int']['output']
}

export type ReferralsStats = {
  __typename?: 'ReferralsStats'
  myReferrals: Scalars['Int']['output']
  totalReferrals: Scalars['Int']['output']
  totalReferralsByMilestone: Scalars['JSON']['output']
}

export type RejectCandaditesQuestionInput = {
  questionId: Scalars['Int']['input']
  score: Scalars['Int']['input']
}

export type RejectCandidateQuestion = {
  __typename?: 'RejectCandidateQuestion'
  id: Scalars['Int']['output']
  question?: Maybe<Scalars['String']['output']>
  required?: Maybe<Scalars['Boolean']['output']>
  sequence?: Maybe<Scalars['Int']['output']>
}

export type RejectCandidatesInput = {
  applicationIds: Array<Scalars['Int']['input']>
  customMessage?: InputMaybe<Scalars['String']['input']>
  details?: InputMaybe<Scalars['String']['input']>
  medalistDesignation: Scalars['String']['input']
  questions: Array<RejectCandaditesQuestionInput>
  reason?: InputMaybe<RejectionReasonEnum>
  templateMessageId?: InputMaybe<Scalars['Int']['input']>
}

export type RejectCandidatesResponse = {
  __typename?: 'RejectCandidatesResponse'
  applicationIds: Array<Scalars['Int']['output']>
  jobId: Scalars['String']['output']
}

export enum RejectionReasonEnum {
  BetterQualifiedCandidatesChosenEducation = 'BETTER_QUALIFIED_CANDIDATES_CHOSEN_EDUCATION',
  BetterQualifiedCandidatesChosenExperience = 'BETTER_QUALIFIED_CANDIDATES_CHOSEN_EXPERIENCE',
  BetterQualifiedCandidatesChosenSkills = 'BETTER_QUALIFIED_CANDIDATES_CHOSEN_SKILLS',
  CandidateAppliedToWrongPosition = 'CANDIDATE_APPLIED_TO_WRONG_POSITION',
  CandidateWithdrawn = 'CANDIDATE_WITHDRAWN',
  DeclinedInterview = 'DECLINED_INTERVIEW',
  DoesntMeetMinimumQualifications = 'DOESNT_MEET_MINIMUM_QUALIFICATIONS',
  DuplicateApplication = 'DUPLICATE_APPLICATION',
  FailedAssessment = 'FAILED_ASSESSMENT',
  FailedBackgroundCheck = 'FAILED_BACKGROUND_CHECK',
  FailedDrugTest = 'FAILED_DRUG_TEST',
  FailedToCompleteRequiredAssessment = 'FAILED_TO_COMPLETE_REQUIRED_ASSESSMENT',
  FailedToRespond = 'FAILED_TO_RESPOND',
  ImpracticalJobExpectations = 'IMPRACTICAL_JOB_EXPECTATIONS',
  InflatedResponsibilities = 'INFLATED_RESPONSIBILITIES',
  InterviewNoShow = 'INTERVIEW_NO_SHOW',
  JobNotAlignedWithCareerObjectives = 'JOB_NOT_ALIGNED_WITH_CAREER_OBJECTIVES',
  LackedClarityInAnswers = 'LACKED_CLARITY_IN_ANSWERS',
  LackedSpecificTechnicalKnowledge = 'LACKED_SPECIFIC_TECHNICAL_KNOWLEDGE',
  LackOfRelevantExperience = 'LACK_OF_RELEVANT_EXPERIENCE',
  LackOfRelevantSkillsAndAbilities = 'LACK_OF_RELEVANT_SKILLS_AND_ABILITIES',
  LimitedAvailabilityOrSchedulingConflicts = 'LIMITED_AVAILABILITY_OR_SCHEDULING_CONFLICTS',
  MissingRequiredCertifications = 'MISSING_REQUIRED_CERTIFICATIONS',
  NegativeReferences = 'NEGATIVE_REFERENCES',
  OfferRejectedDoesntMeetSalaryExpectations = 'OFFER_REJECTED_DOESNT_MEET_SALARY_EXPECTATIONS',
  OfferRejectedJobLocation = 'OFFER_REJECTED_JOB_LOCATION',
  OfferRejectedJobSchedule = 'OFFER_REJECTED_JOB_SCHEDULE',
  OfferRejectedOther = 'OFFER_REJECTED_OTHER',
  Other = 'OTHER',
  OverqualifiedForPosition = 'OVERQUALIFIED_FOR_POSITION',
  PoorCommunicationSkills = 'POOR_COMMUNICATION_SKILLS',
  PoorInterviewPerformance = 'POOR_INTERVIEW_PERFORMANCE',
  PositionAlreadyFilled = 'POSITION_ALREADY_FILLED',
  PositionEliminated = 'POSITION_ELIMINATED',
  PositionOnHold = 'POSITION_ON_HOLD',
  RequiresVisaSponsorship = 'REQUIRES_VISA_SPONSORSHIP',
  SalaryExpectationsTooHigh = 'SALARY_EXPECTATIONS_TOO_HIGH',
  UnableToRelocateAsRequired = 'UNABLE_TO_RELOCATE_AS_REQUIRED',
  UnprofessionalBehaviorDuringHiringProcess = 'UNPROFESSIONAL_BEHAVIOR_DURING_HIRING_PROCESS',
}

export enum ReminderMethodEnum {
  Display = 'display',
  Email = 'email',
  Popup = 'popup',
  Sound = 'sound',
}

export enum RepeatDaysOfWeekEnum {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED',
}

export enum RepeatTypeEnum {
  Custom = 'CUSTOM',
  Daily = 'DAILY',
  EveryWeekday = 'EVERY_WEEKDAY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export enum RepeatsEnum {
  Annually = 'ANNUALLY',
  Daily = 'DAILY',
  DoesNotRepeat = 'DOES_NOT_REPEAT',
  EveryWeekday = 'EVERY_WEEKDAY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type ReplaceTokensInput = {
  /** Candidate application ID */
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  /** Candidate offer ID */
  candidateOfferId?: InputMaybe<Scalars['Int']['input']>
  /** Company user ID */
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  /** Text to replace tokens in */
  text: Scalars['String']['input']
}

export type ReplaceTokensOutput = {
  __typename?: 'ReplaceTokensOutput'
  defaultTokensNotAvailable?: Maybe<Array<TokenNameEnum>>
  offerCustomTokensNotAvailable?: Maybe<Array<Scalars['String']['output']>>
  result: Scalars['String']['output']
}

export enum ReportFilterTabEnum {
  Default = 'DEFAULT',
  Favorite = 'FAVORITE',
  Scheduled = 'SCHEDULED',
  Shared = 'SHARED',
}

export enum ReportSchedulerTypeEnum {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
}

export type Requisition = {
  __typename?: 'Requisition'
  Campaigns?: Maybe<JobCampaignResponse>
  Company?: Maybe<Company>
  CompanyUser?: Maybe<CompanyUser>
  Orders?: Maybe<Array<Order>>
  PrimaryContactCompanyUser?: Maybe<CompanyUser>
  RequisitionJobDescription?: Maybe<Array<RequisitionJobDescription>>
  RequisitionSkills?: Maybe<Array<RequisitionSkill>>
  additionalComp?: Maybe<Scalars['JSON']['output']>
  benefits?: Maybe<Scalars['JSON']['output']>
  candidateCollaborator?: Maybe<Array<CandidateCollaborator>>
  collegeGradPref?: Maybe<Scalars['Boolean']['output']>
  companyDepartment?: Maybe<CompanyDepartment>
  companyDepartmentId?: Maybe<Scalars['Int']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  companyOffice?: Maybe<CompanyOffice>
  companyOfficeId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  diverseHirePref?: Maybe<Scalars['Boolean']['output']>
  educationLevelPref?: Maybe<EducationLevelPrefEnum>
  extRequisitionId?: Maybe<Scalars['String']['output']>
  hiringTeamMember?: Maybe<Array<HiringTeamMember>>
  id: Scalars['ID']['output']
  jobLevel?: Maybe<JobLevelEnum>
  jobListings?: Maybe<Array<JobListing>>
  jobName?: Maybe<Scalars['String']['output']>
  jobOpening?: Maybe<Array<JobOpening>>
  jobPipelines?: Maybe<Array<JobPipeline>>
  jobStatus?: Maybe<JobStatusEnum>
  jobType?: Maybe<JobTypeEnum>
  locationDistancePref?: Maybe<Scalars['Int']['output']>
  maxPay?: Maybe<Scalars['Float']['output']>
  minPay?: Maybe<Scalars['Float']['output']>
  nonTraditionalPref?: Maybe<Scalars['Boolean']['output']>
  openedAt?: Maybe<Scalars['DateTime']['output']>
  payType?: Maybe<PayTypeEnum>
  primaryContact?: Maybe<Scalars['Int']['output']>
  stageCollaborator?: Maybe<Array<StageCollaborator>>
  teamSize?: Maybe<TeamSizeEnum>
  travelType?: Maybe<TravelTypeEnum>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  veteranPref?: Maybe<Scalars['Boolean']['output']>
  yearsOfExperiencePref?: Maybe<YearsOfExperienceEnum>
}

export type RequisitionCampaignsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type RequisitionExtId = {
  __typename?: 'RequisitionExtId'
  Company?: Maybe<Company>
  companyId?: Maybe<Scalars['Int']['output']>
  extId?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
}

export type RequisitionJobDescription = {
  __typename?: 'RequisitionJobDescription'
  candidateCollaborator?: Maybe<Array<CandidateCollaborator>>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  hiringTeamMember?: Maybe<Array<HiringTeamMember>>
  id: Scalars['ID']['output']
  requisition?: Maybe<Requisition>
  requisitionId?: Maybe<Scalars['Int']['output']>
  stageCollaborator?: Maybe<Array<StageCollaborator>>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type RequisitionLastActivity = {
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
}

export type RequisitionSkill = {
  __typename?: 'RequisitionSkill'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  requisition?: Maybe<Requisition>
  requisitionId?: Maybe<Scalars['Int']['output']>
  sequenceNumber?: Maybe<Scalars['Int']['output']>
  skill?: Maybe<Skill>
  skillId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type RequisitionSkillInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  sequenceNumber?: InputMaybe<Scalars['Int']['input']>
  skill?: InputMaybe<UpsertSkillInput>
  skillId?: InputMaybe<Scalars['Int']['input']>
}

export type ResendEmailVerificationInput = {
  email: Scalars['String']['input']
}

export type ResendResetPasswordEmailInput = {
  id: Scalars['Int']['input']
}

export type ResendSchedulerLinkInviteInput = {
  inviteId: Scalars['Int']['input']
}

export type ResetPasswordInput = {
  code: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type Role = {
  __typename?: 'Role'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export enum RoleEnum {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  HiringManager = 'HIRING_MANAGER',
  HiringTeamMember = 'HIRING_TEAM_MEMBER',
  JobViewer = 'JOB_VIEWER',
  Member = 'MEMBER',
  Recruiter = 'RECRUITER',
  StageCollaborator = 'STAGE_COLLABORATOR',
  SuperAdmin = 'SUPER_ADMIN',
}

export enum RoleTypeEnum {
  CaretakingAndFamilyResponsibilities = 'CARETAKING_AND_FAMILY_RESPONSIBILITIES',
  Freelance = 'FREELANCE',
  GigEconomyJobs = 'GIG_ECONOMY_JOBS',
  ResearchAndAcademicProjects = 'RESEARCH_AND_ACADEMIC_PROJECTS',
  TravelAndExchangePrograms = 'TRAVEL_AND_EXCHANGE_PROGRAMS',
  VolunteerWork = 'VOLUNTEER_WORK',
}

export type SalaryIndication = {
  __typename?: 'SalaryIndication'
  period: SalaryPeriod
  range?: Maybe<JobCampaignSalaryRange>
}

export type SalaryInputType = {
  currency?: InputMaybe<Scalars['String']['input']>
  max?: InputMaybe<Scalars['Int']['input']>
  min?: InputMaybe<Scalars['Int']['input']>
}

export enum SalaryPeriod {
  Hourly = 'hourly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export type SalaryRange = {
  __typename?: 'SalaryRange'
  currency: Scalars['String']['output']
  max: Scalars['Int']['output']
  min: Scalars['Int']['output']
}

export type SalaryType = {
  __typename?: 'SalaryType'
  currency?: Maybe<Scalars['String']['output']>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
}

export type ScalisSubscription = {
  __typename?: 'ScalisSubscription'
  clientSecret: Scalars['String']['output']
  status: Scalars['String']['output']
  subscriptionId: Scalars['String']['output']
}

export type ScalisUser = {
  __typename?: 'ScalisUser'
  Achievement?: Maybe<Array<Achievement>>
  Address?: Maybe<Array<Address>>
  CertificateOrLicense?: Maybe<Array<CertificateOrLicense>>
  ContactInfo?: Maybe<Array<ContactInfo>>
  DemographicInfo?: Maybe<Array<DemographicInfo>>
  Education?: Maybe<Array<Education>>
  EmailVerification?: Maybe<Array<EmailVerification>>
  Experience?: Maybe<Array<Experience>>
  ExperienceTenure?: Maybe<ExperienceTenure>
  JobSeekerHeader?: Maybe<Array<JobSeekerHeader>>
  JobSeekerInfo?: Maybe<JobSeekerInfo>
  JobSeekerSkill?: Maybe<Array<JobSeekerSkills>>
  ScalisUserUploadedDocument?: Maybe<Array<ScalisUserUploadedDocument>>
  UserSubscription?: Maybe<Array<UserSubscription>>
  VolunteerWork?: Maybe<Array<VolunteerWork>>
  WebsiteOrSocialMedia?: Maybe<Array<WebsiteOrSocialMedia>>
  activeStatus?: Maybe<Scalars['Boolean']['output']>
  completedAt?: Maybe<Scalars['DateTime']['output']>
  currentStage?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  lastName?: Maybe<Scalars['String']['output']>
  profileImage?: Maybe<Scalars['String']['output']>
}

export type ScalisUserEducationArgs = {
  highestEducation?: InputMaybe<Scalars['Boolean']['input']>
}

export type ScalisUserExperienceArgs = {
  onlyCurrentJob?: InputMaybe<Scalars['Boolean']['input']>
  onlyMostRecentJob?: InputMaybe<Scalars['Boolean']['input']>
}

export type ScalisUserUploadedDocument = {
  __typename?: 'ScalisUserUploadedDocument'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  documentName?: Maybe<Scalars['String']['output']>
  documentPath?: Maybe<Scalars['String']['output']>
  documentType: DocumentTypeEnum
  id: Scalars['Int']['output']
  scalisUserId: Scalars['Int']['output']
}

export type ScheduledReport = {
  __typename?: 'ScheduledReport'
  CompanyUser: CompanyUser
  CoreReport: CoreReport
  companyUserId: Scalars['Int']['output']
  coreReportId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  cronExpression?: Maybe<Scalars['String']['output']>
  customDaysOfWeek?: Maybe<Scalars['JSON']['output']>
  customTime?: Maybe<Scalars['String']['output']>
  deleted?: Maybe<Scalars['DateTime']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  recipients: Scalars['JSON']['output']
  repeat?: Maybe<RepeatTypeEnum>
  reportSchedulerType: ReportSchedulerTypeEnum
  startDate?: Maybe<Scalars['DateTime']['output']>
  taskId: Scalars['String']['output']
  taskObject?: Maybe<Scalars['JSON']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type SchedulerLinkInvite = {
  __typename?: 'SchedulerLinkInvite'
  Calendar?: Maybe<Calendar>
  CandidateApplication?: Maybe<CandidateApplication>
  CompanyUser?: Maybe<CompanyUser>
  TemplateStage?: Maybe<TemplateStage>
  applicationId: Scalars['Int']['output']
  calendarId: Scalars['Int']['output']
  candidateData?: Maybe<Scalars['JSON']['output']>
  companyUserId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  durationMinutes?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  interviewData?: Maybe<Scalars['JSON']['output']>
  message?: Maybe<Scalars['String']['output']>
  nylasConfigurationId: Scalars['String']['output']
  stageId: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SchedulerLinkSessionOutput = {
  __typename?: 'SchedulerLinkSessionOutput'
  session_id: Scalars['String']['output']
}

export type SchedulerLinkSettings = {
  __typename?: 'SchedulerLinkSettings'
  CompanyUser: CompanyUser
  companyUserId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  maxInterviewsPerDay: Scalars['Int']['output']
  minimumHour: Scalars['Int']['output']
  rollingDays: Scalars['Int']['output']
}

export enum ScorecardQuestionTypeEnum {
  Checkboxes = 'CHECKBOXES',
  Date = 'DATE',
  FreeResponse = 'FREE_RESPONSE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  Numerical = 'NUMERICAL',
  YesNo = 'YES_NO',
}

export enum ScorecardStatusTypeEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

export type ScorecardTemplate = {
  __typename?: 'ScorecardTemplate'
  CandidateScorecard: Array<CandidateScorecard>
  Company?: Maybe<Company>
  CompanyUser?: Maybe<CompanyUser>
  ScorecardTemplateSection: Array<ScorecardTemplateSection>
  StageSettings: Array<StageSettings>
  archived?: Maybe<Scalars['Boolean']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  companyTemplate?: Maybe<Scalars['Boolean']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  department?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  scorecardDescription?: Maybe<Scalars['String']['output']>
  scorecardName?: Maybe<Scalars['String']['output']>
  timestampAt?: Maybe<Scalars['DateTime']['output']>
  visible?: Maybe<Scalars['Boolean']['output']>
}

export type ScorecardTemplateQuestion = {
  __typename?: 'ScorecardTemplateQuestion'
  CandidateScorecardAnswers?: Maybe<Array<CandidateScorecardAnswers>>
  ScorecardTemplateSection?: Maybe<ScorecardTemplateSection>
  Skill?: Maybe<Skill>
  answerOptions?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  preferredAnswer?: Maybe<Scalars['String']['output']>
  questionDescription?: Maybe<Scalars['String']['output']>
  questionText?: Maybe<Scalars['String']['output']>
  questionType?: Maybe<ScorecardQuestionTypeEnum>
  scoreAnswer?: Maybe<Scalars['Boolean']['output']>
  scorecardTemplateSectionId?: Maybe<Scalars['Int']['output']>
  skillId?: Maybe<Scalars['Int']['output']>
}

export type ScorecardTemplateQuestionInput = {
  ScorecardTemplateSkill?: InputMaybe<ScorecardTemplateSkillInput>
  answerOptions?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  preferredAnswer?: InputMaybe<Scalars['String']['input']>
  questionDescription?: InputMaybe<Scalars['String']['input']>
  questionText: Scalars['String']['input']
  questionType: ScorecardQuestionTypeEnum
  scorecardTemplateSectionId?: InputMaybe<Scalars['Int']['input']>
}

export type ScorecardTemplateSection = {
  __typename?: 'ScorecardTemplateSection'
  ScorecardTemplate?: Maybe<ScorecardTemplate>
  ScorecardTemplateQuestion: Array<ScorecardTemplateQuestion>
  id: Scalars['ID']['output']
  rankOrder?: Maybe<Scalars['Int']['output']>
  scorecardTemplateId?: Maybe<Scalars['Int']['output']>
  sectionName?: Maybe<Scalars['String']['output']>
  sectionType?: Maybe<SectionTypeEnum>
}

export type ScorecardTemplateSectionInput = {
  ScorecardTemplateQuestions?: InputMaybe<Array<ScorecardTemplateQuestionInput>>
  id?: InputMaybe<Scalars['Int']['input']>
  rankOrder: Scalars['Int']['input']
  scorecardTemplateId?: InputMaybe<Scalars['Int']['input']>
  sectionName: Scalars['String']['input']
  sectionType: SectionTypeEnum
}

export type ScorecardTemplateSkillInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  level?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  yearsOfExp?: InputMaybe<Scalars['Int']['input']>
}

/** A screening question used to evaluate job candidates */
export type ScreeningQuestion = {
  __typename?: 'ScreeningQuestion'
  /** The company that owns this screening question */
  Company?: Maybe<Company>
  /** The company user who created this screening question */
  CompanyUser?: Maybe<CompanyUser>
  /** Relation to job listings that use this screening question */
  JobListingScreeningQuestion?: Maybe<Array<JobListingScreeningQuestion>>
  /** Related template message for rejection */
  TemplateMessage?: Maybe<TemplateMessage>
  /** Comma-separated list of answer options for MULTIPLE_CHOICE or MULTIPLE_SELECT questions */
  ansOptions?: Maybe<Scalars['String']['output']>
  /** Foreign key to the Company that owns this question */
  companyId?: Maybe<Scalars['Int']['output']>
  /** Foreign key to the CompanyUser who created this question */
  companyUserId?: Maybe<Scalars['Int']['output']>
  /** Timestamp when the question was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>
  globalQuestionOrder?: Maybe<Scalars['Int']['output']>
  /** Unique identifier for the screening question */
  id: Scalars['ID']['output']
  isActiveGlobally?: Maybe<Scalars['Boolean']['output']>
  isDeleted?: Maybe<Scalars['Boolean']['output']>
  isEdited?: Maybe<Scalars['Boolean']['output']>
  isGlobalQuestion?: Maybe<Scalars['Boolean']['output']>
  /** If true, answering incorrectly will trigger automatic rejection */
  isRejectionQuestion?: Maybe<Scalars['Boolean']['output']>
  isReplacedBy?: Maybe<Scalars['Int']['output']>
  /** If true, this is a reusable template question */
  isTemplateQuestion?: Maybe<Scalars['Boolean']['output']>
  /** For NUMERICAL questions, the maximum acceptable value */
  maxVal?: Maybe<Scalars['Int']['output']>
  /** For NUMERICAL questions, the minimum acceptable value */
  minVal?: Maybe<Scalars['Int']['output']>
  /** Array of preferred answers for MULTIPLE_SELECT questions */
  multipleSelectPreferredAnswers?: Maybe<Scalars['JSON']['output']>
  /** For YES_NO and MULTIPLE_CHOICE: the correct/preferred answer(s) as comma-separated values */
  preferredAnswer?: Maybe<Scalars['String']['output']>
  /** For MULTIPLE_SELECT: determines if ALL_OF or ANY_OF the preferred answers are required */
  preferredAnswerType?: Maybe<PreferredAnswerTypeEnum>
  /** Additional description or context to help candidates understand the question */
  questionDesc?: Maybe<Scalars['String']['output']>
  /** The main text of the screening question shown to candidates */
  questionTitle?: Maybe<Scalars['String']['output']>
  /** Type of question that determines the input type and validation rules */
  questionType?: Maybe<QuestionTypeEnum>
  /** Custom message to display when a candidate is rejected based on this question */
  rejectMessage?: Maybe<Scalars['String']['output']>
  /** Controls how selection options are displayed to the user */
  selectionDisplay?: Maybe<SelectionDisplayEnum>
  /** Reference to a template message for rejection */
  templateMessageId?: Maybe<Scalars['Int']['output']>
  /** Timestamp when the question was last updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  /** Status related to validation of numerical values */
  valStatus?: Maybe<Scalars['String']['output']>
}

export type ScreeningQuestionAnswer = {
  __typename?: 'ScreeningQuestionAnswer'
  CandidateApplication?: Maybe<CandidateApplication>
  UploadedDocumentCandidateApplication?: Maybe<UploadedDocumentCandidateApplication>
  answer?: Maybe<Scalars['String']['output']>
  answerStatus?: Maybe<Scalars['Boolean']['output']>
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  listingScreeningQuestionId?: Maybe<Scalars['Int']['output']>
  multipleSelectAnswers?: Maybe<Scalars['JSON']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  uploadedDocumentCandidateApplicationId?: Maybe<Scalars['Int']['output']>
}

export type ScreeningQuestionAnswerInput = {
  answer: Scalars['String']['input']
  listingScreeningQuestionId: Scalars['Int']['input']
}

export type ScreeningQuestionsAnswersInput = {
  answer: Scalars['String']['input']
  documentName?: InputMaybe<Scalars['String']['input']>
  documentPath?: InputMaybe<Scalars['String']['input']>
  listingScreeningQuestionId: Scalars['Int']['input']
  multipleSelectAnswers?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SearchCandidatesFilterContentOutput = {
  __typename?: 'SearchCandidatesFilterContentOutput'
  filterContent?: Maybe<Array<FilterContentItem>>
}

export type SearchJobCampaignLocationsInput = {
  text: Scalars['String']['input']
}

export type SearchResult = {
  __typename?: 'SearchResult'
  count: Scalars['Int']['output']
  filters: Array<FiltersType>
  results: Array<JobSearchResultType>
}

export enum SearchSortEnum {
  SortBestMatch = 'SORT_BEST_MATCH',
  SortNewest = 'SORT_NEWEST',
  SortOldest = 'SORT_OLDEST',
}

export enum SectionTypeEnum {
  Questions = 'QUESTIONS',
  Skills = 'SKILLS',
}

export enum SelectionDisplayEnum {
  Dropdown = 'DROPDOWN',
  Picklist = 'PICKLIST',
}

export type SendCandidateOfferInput = {
  candidateOfferId: Scalars['Int']['input']
  customMessage?: InputMaybe<Scalars['String']['input']>
  recipientsIds?: InputMaybe<Array<Scalars['Int']['input']>>
  templateMessageId?: InputMaybe<Scalars['Int']['input']>
}

export type SendCandidateScorecardInput = {
  id: Scalars['Int']['input']
}

export type SendCandidatesToSourcingStageInput = {
  candidateProfileIds: Array<Scalars['Int']['input']>
  jobListingId: Scalars['Int']['input']
}

export type SendCandidatesToSourcingStageOutput = {
  __typename?: 'SendCandidatesToSourcingStageOutput'
  alreadyHasActiveApplication?: Maybe<Array<CandidateApplicationNotCreatedOutput>>
  candidateApplications?: Maybe<Array<CandidateApplication>>
  profileNotFound?: Maybe<Array<CandidateApplicationNotCreatedOutput>>
}

export type SendJobListingInput = {
  email: Scalars['String']['input']
  message: Scalars['String']['input']
  publicListingId: Scalars['String']['input']
  recipients: Array<Scalars['String']['input']>
}

export type SendJobListingResult = {
  __typename?: 'SendJobListingResult'
  email: Scalars['String']['output']
  message: Scalars['String']['output']
  publicListingId: Scalars['String']['output']
  recipients: Array<Scalars['String']['output']>
}

export type SendReminderCandidateScorecardInput = {
  id: Scalars['Int']['input']
}

export type SendResetPasswordEmailInput = {
  email: Scalars['String']['input']
}

export type SeniorityLevel = {
  __typename?: 'SeniorityLevel'
  languageCode: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SetupTime = {
  __typename?: 'SetupTime'
  period: Scalars['Int']['output']
  range: Scalars['String']['output']
}

export type SharedCoreReport = {
  __typename?: 'SharedCoreReport'
  CompanyUser: CompanyUser
  CoreReport: CoreReport
  companyUserId: Scalars['Int']['output']
  coreReportId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SharedReportInput = {
  companyUserId: Scalars['Int']['input']
  coreReportId: Scalars['Int']['input']
  email: Scalars['String']['input']
}

export type SignUpInput = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type SignatureRequestSignersInput = {
  emailAddress: Scalars['String']['input']
  name: Scalars['String']['input']
  order?: InputMaybe<Scalars['Int']['input']>
}

export type Skill = {
  __typename?: 'Skill'
  experienceId?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  level?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  yearsOfExp?: Maybe<Scalars['Int']['output']>
}

export enum SortByApplication {
  ApplicationStatus = 'applicationStatus',
  AppliedAt = 'appliedAt',
  ExternalTitle = 'externalTitle',
  ListingTitle = 'listingTitle',
  PostedAt = 'postedAt',
  Round = 'round',
}

export enum SortByBoomarkedJob {
  ListingTitle = 'listingTitle',
  PostedAt = 'postedAt',
}

export enum SortByCalendarEvent {
  StartTime = 'startTime',
}

export enum SortByCandidateScorecard {
  AssignedTo = 'assignedTo',
  CreatedAt = 'createdAt',
  ScorecardTemplateName = 'scorecardTemplateName',
  Status = 'status',
  TemplateStage = 'templateStage',
}

export enum SortByExternalApplication {
  AppliedAt = 'appliedAt',
  EmployerName = 'employerName',
  Status = 'status',
}

export enum SortByJobInvite {
  Applicants = 'applicants',
  ListingTitle = 'listingTitle',
  PostedAt = 'postedAt',
}

export enum SortByJobProductsEnum {
  CreatedAsc = 'created_asc',
  CreatedDesc = 'created_desc',
  ListPriceAsc = 'list_price_asc',
  ListPriceDesc = 'list_price_desc',
  OrderFrequencyAsc = 'order_frequency_asc',
  OrderFrequencyDesc = 'order_frequency_desc',
  Recent = 'recent',
  Relevant = 'relevant',
}

export enum SortByScorecardTemplate {
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Department = 'department',
  Id = 'id',
  ScorecardName = 'scorecardName',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SourceCategoryEnum {
  Agency = 'AGENCY',
  CompanyWebsite = 'COMPANY_WEBSITE',
  DirectApplicant = 'DIRECT_APPLICANT',
  Event = 'EVENT',
  Internal = 'INTERNAL',
  Other = 'OTHER',
  Referred = 'REFERRED',
  Scalis = 'SCALIS',
  Sourced = 'SOURCED',
  ThirdParty = 'THIRD_PARTY',
  University = 'UNIVERSITY',
}

export type SourceInput = {
  campaignId?: InputMaybe<Scalars['Int']['input']>
  source?: InputMaybe<Scalars['String']['input']>
}

export type SourceStats = {
  __typename?: 'SourceStats'
  applicants: Scalars['Int']['output']
  applyStarts: Scalars['Int']['output']
  completionRate: Scalars['Float']['output']
  views: Scalars['Int']['output']
}

export enum SourceTypeEnum {
  External = 'external',
  Internal = 'internal',
}

export type StageCollaborator = {
  __typename?: 'StageCollaborator'
  CompanyUser?: Maybe<CompanyUser>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  requisition?: Maybe<Requisition>
  requisitionId?: Maybe<Scalars['Int']['output']>
  requisitionJobDescription?: Maybe<RequisitionJobDescription>
  requisitionJobDescriptionId?: Maybe<Scalars['Int']['output']>
  role?: Maybe<Role>
  roleId?: Maybe<Scalars['Int']['output']>
  templateStage?: Maybe<TemplateStage>
  templateStageId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum StageEnum {
  BasicInformation = 'BASIC_INFORMATION',
  Complete = 'COMPLETE',
  Description = 'DESCRIPTION',
  Location = 'LOCATION',
  Preferences = 'PREFERENCES',
  ScreeningQuestions = 'SCREENING_QUESTIONS',
  Skills = 'SKILLS',
}

export type StageSettings = {
  __typename?: 'StageSettings'
  InterviewTeam?: Maybe<Array<InterviewTeam>>
  JobPipeline?: Maybe<JobPipeline>
  TemplateStage?: Maybe<TemplateStage>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  jobPipelineId?: Maybe<Scalars['Int']['output']>
  scorecardTemplateId?: Maybe<Scalars['Int']['output']>
  templateStageId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type StartCandidateApplicationInput = {
  campaignId?: InputMaybe<Scalars['String']['input']>
  publicListingId: Scalars['String']['input']
  source?: InputMaybe<Scalars['String']['input']>
}

export type StripeSubscription = {
  __typename?: 'StripeSubscription'
  cancelAt?: Maybe<Scalars['DateTime']['output']>
  cancelAtPeriodEnd: Scalars['Boolean']['output']
  currentPeriodEnd: Scalars['DateTime']['output']
  currentPeriodStart: Scalars['DateTime']['output']
  endedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  startDate: Scalars['DateTime']['output']
  status: Scalars['String']['output']
}

export enum SubIndustryTypeEnum {
  AeroDefenseAviationServices = 'AERO_DEFENSE_AVIATION_SERVICES',
  AgriAnimalProduction = 'AGRI_ANIMAL_PRODUCTION',
  AgriCropProduction = 'AGRI_CROP_PRODUCTION',
  AgriFarmSupport = 'AGRI_FARM_SUPPORT',
  AgriFishery = 'AGRI_FISHERY',
  AgriFloralNursery = 'AGRI_FLORAL_NURSERY',
  AgriForestryLoggingTimber = 'AGRI_FORESTRY_LOGGING_TIMBER',
  ArtsEntRecArtGalleries = 'ARTS_ENT_REC_ART_GALLERIES',
  ArtsEntRecCultureEntertainment = 'ARTS_ENT_REC_CULTURE_ENTERTAINMENT',
  ArtsEntRecFilmProduction = 'ARTS_ENT_REC_FILM_PRODUCTION',
  ArtsEntRecGambling = 'ARTS_ENT_REC_GAMBLING',
  ArtsEntRecMusicSoundProduction = 'ARTS_ENT_REC_MUSIC_SOUND_PRODUCTION',
  ArtsEntRecPhotography = 'ARTS_ENT_REC_PHOTOGRAPHY',
  ArtsEntRecPublishing = 'ARTS_ENT_REC_PUBLISHING',
  ArtsEntRecSportsRecreation = 'ARTS_ENT_REC_SPORTS_RECREATION',
  ArtsEntRecTicketSales = 'ARTS_ENT_REC_TICKET_SALES',
  ArtsEntRecTranslationLinguistic = 'ARTS_ENT_REC_TRANSLATION_LINGUISTIC',
  ArtsEntRecVideoGamePublishing = 'ARTS_ENT_REC_VIDEO_GAME_PUBLISHING',
  AutoElectricVehicleManufacturing = 'AUTO_ELECTRIC_VEHICLE_MANUFACTURING',
  AutoVehicleDealers = 'AUTO_VEHICLE_DEALERS',
  AutoVehicleManufacturing = 'AUTO_VEHICLE_MANUFACTURING',
  AutoVehicleRepairMaintenance = 'AUTO_VEHICLE_REPAIR_MAINTENANCE',
  ConstrArchitecturalEngineering = 'CONSTR_ARCHITECTURAL_ENGINEERING',
  ConstrCommercialEquipment = 'CONSTR_COMMERCIAL_EQUIPMENT',
  ConstrRepairFacilities = 'CONSTR_REPAIR_FACILITIES',
  ConsultCfoConsulting = 'CONSULT_CFO_CONSULTING',
  ConsultHrConsulting = 'CONSULT_HR_CONSULTING',
  ConsultMarketingConsulting = 'CONSULT_MARKETING_CONSULTING',
  ConsultSalesConsulting = 'CONSULT_SALES_CONSULTING',
  ConsultStrategyConsulting = 'CONSULT_STRATEGY_CONSULTING',
  EcomAgency = 'ECOM_AGENCY',
  EcomBrand = 'ECOM_BRAND',
  EcomLogisticsFulfillment = 'ECOM_LOGISTICS_FULFILLMENT',
  EcomOnlineMarketplace = 'ECOM_ONLINE_MARKETPLACE',
  EcomOnlinePayments = 'ECOM_ONLINE_PAYMENTS',
  EcomSaas = 'ECOM_SAAS',
  EduCollegesUniversities = 'EDU_COLLEGES_UNIVERSITIES',
  EduLms = 'EDU_LMS',
  EduOnlinePlatforms = 'EDU_ONLINE_PLATFORMS',
  EduPreschoolsChildcare = 'EDU_PRESCHOOLS_CHILDCARE',
  EduPrimarySecondary = 'EDU_PRIMARY_SECONDARY',
  EduSoftware = 'EDU_SOFTWARE',
  EduTrainingServices = 'EDU_TRAINING_SERVICES',
  EnergyMiningMetals = 'ENERGY_MINING_METALS',
  EnergyRenewable = 'ENERGY_RENEWABLE',
  EnergyUtilities = 'ENERGY_UTILITIES',
  EnvSvcsEnvConsulting = 'ENV_SVCS_ENV_CONSULTING',
  EnvSvcsRenewableEnergy = 'ENV_SVCS_RENEWABLE_ENERGY',
  EnvSvcsWasteManagement = 'ENV_SVCS_WASTE_MANAGEMENT',
  FashionClothingRetailers = 'FASHION_CLOTHING_RETAILERS',
  FashionDesigners = 'FASHION_DESIGNERS',
  FashionTextileFabric = 'FASHION_TEXTILE_FABRIC',
  FinSvcsAccountingTax = 'FIN_SVCS_ACCOUNTING_TAX',
  FinSvcsAssetManagement = 'FIN_SVCS_ASSET_MANAGEMENT',
  FinSvcsBankingLending = 'FIN_SVCS_BANKING_LENDING',
  FinSvcsDebtRelief = 'FIN_SVCS_DEBT_RELIEF',
  FinSvcsDigitalPayments = 'FIN_SVCS_DIGITAL_PAYMENTS',
  FinSvcsP2PLending = 'FIN_SVCS_P2P_LENDING',
  FinSvcsPlanningAdvisory = 'FIN_SVCS_PLANNING_ADVISORY',
  FinSvcsRoboAdvisory = 'FIN_SVCS_ROBO_ADVISORY',
  FinSvcsStockExchanges = 'FIN_SVCS_STOCK_EXCHANGES',
  FinSvcsTransactionProcessing = 'FIN_SVCS_TRANSACTION_PROCESSING',
  FoodBevBeverageProduction = 'FOOD_BEV_BEVERAGE_PRODUCTION',
  FoodBevDelivery = 'FOOD_BEV_DELIVERY',
  FoodBevOrganicNatural = 'FOOD_BEV_ORGANIC_NATURAL',
  GovPublicAdminMunicipal = 'GOV_PUBLIC_ADMIN_MUNICIPAL',
  GovPublicAdminNational = 'GOV_PUBLIC_ADMIN_NATIONAL',
  GovPublicAdminStateRegional = 'GOV_PUBLIC_ADMIN_STATE_REGIONAL',
  HealthAmbulanceMedicalTransport = 'HEALTH_AMBULANCE_MEDICAL_TRANSPORT',
  HealthDentalClinics = 'HEALTH_DENTAL_CLINICS',
  HealthHomeCare = 'HEALTH_HOME_CARE',
  HealthHospitalsClinics = 'HEALTH_HOSPITALS_CLINICS',
  HealthMedEquipSupplies = 'HEALTH_MED_EQUIP_SUPPLIES',
  HealthMedTestingLabs = 'HEALTH_MED_TESTING_LABS',
  HealthNursingCare = 'HEALTH_NURSING_CARE',
  HealthRehabServices = 'HEALTH_REHAB_SERVICES',
  HealthTech = 'HEALTH_TECH',
  HotelsTravelAgencies = 'HOTELS_TRAVEL_AGENCIES',
  HotelsTravelCruiseLines = 'HOTELS_TRAVEL_CRUISE_LINES',
  HotelsTravelEventManagement = 'HOTELS_TRAVEL_EVENT_MANAGEMENT',
  HotelsTravelHotelsResorts = 'HOTELS_TRAVEL_HOTELS_RESORTS',
  HotelsTravelThemeParks = 'HOTELS_TRAVEL_THEME_PARKS',
  HumanresStaffingHrConsulting = 'HUMANRES_STAFFING_HR_CONSULTING',
  HumanresStaffingStaffingSubcontracting = 'HUMANRES_STAFFING_STAFFING_SUBCONTRACTING',
  InfoCloudComputing = 'INFO_CLOUD_COMPUTING',
  InfoCybersecurity = 'INFO_CYBERSECURITY',
  InfoDataAnalytics = 'INFO_DATA_ANALYTICS',
  InfoHardwareDev = 'INFO_HARDWARE_DEV',
  InfoSaas = 'INFO_SAAS',
  InfoSoftwareDev = 'INFO_SOFTWARE_DEV',
  InfoSupportServices = 'INFO_SUPPORT_SERVICES',
  InfoTelecomEquip = 'INFO_TELECOM_EQUIP',
  InfoWebServices = 'INFO_WEB_SERVICES',
  InsAgenciesBrokerages = 'INS_AGENCIES_BROKERAGES',
  InsCarriers = 'INS_CARRIERS',
  InsHealthInsurance = 'INS_HEALTH_INSURANCE',
  LegalLawFirms = 'LEGAL_LAW_FIRMS',
  LegalServices = 'LEGAL_SERVICES',
  ManageBusinessConsulting = 'MANAGE_BUSINESS_CONSULTING',
  ManageMembershipOrganizations = 'MANAGE_MEMBERSHIP_ORGANIZATIONS',
  ManageResearchDev = 'MANAGE_RESEARCH_DEV',
  ManageSecurityProtective = 'MANAGE_SECURITY_PROTECTIVE',
  ManufChemical = 'MANUF_CHEMICAL',
  ManufCommercialPrinting = 'MANUF_COMMERCIAL_PRINTING',
  ManufConsumerProducts = 'MANUF_CONSUMER_PRODUCTS',
  ManufElectronics = 'MANUF_ELECTRONICS',
  ManufFoodBev = 'MANUF_FOOD_BEV',
  ManufHealthProducts = 'MANUF_HEALTH_PRODUCTS',
  ManufMachinery = 'MANUF_MACHINERY',
  ManufMetalMineral = 'MANUF_METAL_MINERAL',
  ManufTextileApparel = 'MANUF_TEXTILE_APPAREL',
  ManufTransportEquip = 'MANUF_TRANSPORT_EQUIP',
  ManufWoodPaper = 'MANUF_WOOD_PAPER',
  MediaCommAdvertisingPr = 'MEDIA_COMM_ADVERTISING_PR',
  MediaCommBroadcast = 'MEDIA_COMM_BROADCAST',
  MediaCommFilmProduction = 'MEDIA_COMM_FILM_PRODUCTION',
  MediaCommMusicSound = 'MEDIA_COMM_MUSIC_SOUND',
  MediaCommPhotography = 'MEDIA_COMM_PHOTOGRAPHY',
  MediaCommPublishing = 'MEDIA_COMM_PUBLISHING',
  MediaCommTranslationLinguistic = 'MEDIA_COMM_TRANSLATION_LINGUISTIC',
  MediaCommVideoGame = 'MEDIA_COMM_VIDEO_GAME',
  NonprofitNgoCivicSocial = 'NONPROFIT_NGO_CIVIC_SOCIAL',
  NonprofitNgoFoundations = 'NONPROFIT_NGO_FOUNDATIONS',
  NonprofitNgoReligious = 'NONPROFIT_NGO_RELIGIOUS',
  PersonalServicesBeautyWellness = 'PERSONAL_SERVICES_BEAUTY_WELLNESS',
  PersonalServicesEventServices = 'PERSONAL_SERVICES_EVENT_SERVICES',
  PersonalServicesLaundryCleaning = 'PERSONAL_SERVICES_LAUNDRY_CLEANING',
  PersonalServicesPetCareVeterinary = 'PERSONAL_SERVICES_PET_CARE_VETERINARY',
  PersonalServicesPrivateHouseholds = 'PERSONAL_SERVICES_PRIVATE_HOUSEHOLDS',
  PharmaBiotechBiotech = 'PHARMA_BIOTECH_BIOTECH',
  PharmaBiotechPharma = 'PHARMA_BIOTECH_PHARMA',
  ProfServicesAdvertisingAgencies = 'PROF_SERVICES_ADVERTISING_AGENCIES',
  ProfServicesDesign = 'PROF_SERVICES_DESIGN',
  ProfServicesPrAgencies = 'PROF_SERVICES_PR_AGENCIES',
  RealEstateAgencies = 'REAL_ESTATE_AGENCIES',
  RealEstatePropertyManagement = 'REAL_ESTATE_PROPERTY_MANAGEMENT',
  RestaurantsBarsNightclubs = 'RESTAURANTS_BARS_NIGHTCLUBS',
  RestaurantsCafes = 'RESTAURANTS_CAFES',
  RestaurantsCateringContractors = 'RESTAURANTS_CATERING_CONTRACTORS',
  RetailAuctionsGalleries = 'RETAIL_AUCTIONS_GALLERIES',
  RetailAutoPartsAccessories = 'RETAIL_AUTO_PARTS_ACCESSORIES',
  RetailBeautyPersonalAccessories = 'RETAIL_BEAUTY_PERSONAL_ACCESSORIES',
  RetailConvenienceStores = 'RETAIL_CONVENIENCE_STORES',
  RetailDepartmentClothingShoe = 'RETAIL_DEPARTMENT_CLOTHING_SHOE',
  RetailDrugHealthStores = 'RETAIL_DRUG_HEALTH_STORES',
  RetailEcommerce = 'RETAIL_ECOMMERCE',
  RetailElectronicsAppliances = 'RETAIL_ELECTRONICS_APPLIANCES',
  RetailFoodBevStores = 'RETAIL_FOOD_BEV_STORES',
  RetailGeneralMerchandise = 'RETAIL_GENERAL_MERCHANDISE',
  RetailGiftNoveltySouvenir = 'RETAIL_GIFT_NOVELTY_SOUVENIR',
  RetailGroceryStores = 'RETAIL_GROCERY_STORES',
  RetailHomeFurnitureHousewares = 'RETAIL_HOME_FURNITURE_HOUSEWARES',
  RetailHomeImprovement = 'RETAIL_HOME_IMPROVEMENT',
  RetailLuxuryGoods = 'RETAIL_LUXURY_GOODS',
  RetailMediaEntertainment = 'RETAIL_MEDIA_ENTERTAINMENT',
  RetailOfficeSupplyCopy = 'RETAIL_OFFICE_SUPPLY_COPY',
  RetailPetSupplies = 'RETAIL_PET_SUPPLIES',
  RetailSpecialtyStores = 'RETAIL_SPECIALTY_STORES',
  RetailSportingGoods = 'RETAIL_SPORTING_GOODS',
  RetailToysHobbies = 'RETAIL_TOYS_HOBBIES',
  RetailWholesale = 'RETAIL_WHOLESALE',
  SocialMediaOnlineCommunities = 'SOCIAL_MEDIA_ONLINE_COMMUNITIES',
  SocialMediaPlatforms = 'SOCIAL_MEDIA_PLATFORMS',
  SocialMediaProfessionalNetworking = 'SOCIAL_MEDIA_PROFESSIONAL_NETWORKING',
  TelecomTelecommunications = 'TELECOM_TELECOMMUNICATIONS',
  TransportAirlinesAirports = 'TRANSPORT_AIRLINES_AIRPORTS',
  TransportCarTruckRental = 'TRANSPORT_CAR_TRUCK_RENTAL',
  TransportManagement = 'TRANSPORT_MANAGEMENT',
  TransportMarine = 'TRANSPORT_MARINE',
  TransportParkingValet = 'TRANSPORT_PARKING_VALET',
  TransportRail = 'TRANSPORT_RAIL',
  TransportShippingTrucking = 'TRANSPORT_SHIPPING_TRUCKING',
  TransportTaxiCarServices = 'TRANSPORT_TAXI_CAR_SERVICES',
}

export type SubscriptionInput = {
  recurrence?: Recurrence
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Paused = 'PAUSED',
  Unpaid = 'UNPAID',
}

export type SyncCompanyUsersCalendarsOutput = {
  __typename?: 'SyncCompanyUsersCalendarsOutput'
  success: Scalars['Boolean']['output']
}

export type TargetGroup = {
  __typename?: 'TargetGroup'
  educationLevel: Array<TargetGroupItem>
  industry: Array<TargetGroupItem>
  jobCategory: Array<TargetGroupItem>
  seniority: Array<TargetGroupItem>
}

export type TargetGroupInput = {
  educationLevel: Array<TargetGroupItemInput>
  industry: Array<TargetGroupItemInput>
  jobCategory: Array<TargetGroupItemInput>
  seniority: Array<TargetGroupItemInput>
}

export type TargetGroupItem = {
  __typename?: 'TargetGroupItem'
  description: Scalars['String']['output']
  vonqId: Scalars['String']['output']
}

export type TargetGroupItemInput = {
  description: Scalars['String']['input']
  vonqId: Scalars['String']['input']
}

export enum TeamSizeEnum {
  Count_1To_5 = 'COUNT_1_TO_5',
  Count_6To_10 = 'COUNT_6_TO_10',
  Count_11To_20 = 'COUNT_11_TO_20',
  Count_21To_50Plus = 'COUNT_21_TO_50_PLUS',
}

export type TemplateJobDescription = {
  __typename?: 'TemplateJobDescription'
  company?: Maybe<Company>
  companyDepartment?: Maybe<CompanyDepartment>
  companyDepartmentId?: Maybe<Scalars['ID']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUser?: Maybe<CompanyUser>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type TemplateMessage = {
  __typename?: 'TemplateMessage'
  ScreeningQuestion?: Maybe<Array<ScreeningQuestion>>
  candidateMessage?: Maybe<Array<CandidateMessage>>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUser?: Maybe<CompanyUser>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['String']['output']>
  messageOwner?: Maybe<MessageOwnerEnum>
  messageType?: Maybe<MessageTypeEnum>
  title?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type TemplateMilestone = {
  __typename?: 'TemplateMilestone'
  TemplatePipeline?: Maybe<TemplatePipeline>
  TemplateStage?: Maybe<Array<TemplateStage>>
  archived?: Maybe<Scalars['Boolean']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<NameEnum>
  rankOrder?: Maybe<Scalars['Int']['output']>
  templatePipelineId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type TemplatePipeline = {
  __typename?: 'TemplatePipeline'
  Company?: Maybe<Company>
  CompanyDepartment?: Maybe<CompanyDepartment>
  CompanyUser?: Maybe<CompanyUser>
  JobPipeline?: Maybe<Array<JobPipeline>>
  TemplateMilestones?: Maybe<Array<TemplateMilestone>>
  TemplateStage?: Maybe<Array<TemplateStage>>
  archived?: Maybe<Scalars['Boolean']['output']>
  companyDepartmentId?: Maybe<Scalars['Int']['output']>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  ghostPipeline?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  pipelinedUsed?: Maybe<Scalars['Boolean']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type TemplateStage = {
  __typename?: 'TemplateStage'
  CandidateApplicationByStage?: Maybe<Array<CandidateApplication>>
  CandidateInterview?: Maybe<Array<CandidateInterview>>
  StageCollaborator?: Maybe<Array<StageCollaborator>>
  StageSettings?: Maybe<Array<StageSettings>>
  TemplateMilestone?: Maybe<TemplateMilestone>
  TemplatePipeline?: Maybe<TemplatePipeline>
  archived?: Maybe<Scalars['Boolean']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  rankOrder?: Maybe<Scalars['Int']['output']>
  templateMilestoneId?: Maybe<Scalars['Int']['output']>
  templatePipelineId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  visibility?: Maybe<TemplateStageVisibilityEnum>
}

export type TemplateStageCandidateApplicationByStageArgs = {
  requisitionId?: InputMaybe<Scalars['Int']['input']>
}

export enum TemplateStageVisibilityEnum {
  VisibleOnlyInternally = 'VISIBLE_ONLY_INTERNALLY',
  VisibleToCandidate = 'VISIBLE_TO_CANDIDATE',
}

export type ToggleScorecardTemplateArchiveInput = {
  id: Scalars['Int']['input']
}

export enum TokenCategoryNameEnum {
  ApplicationInfo = 'APPLICATION_INFO',
  CandidateProfile = 'CANDIDATE_PROFILE',
  CompanyInfo = 'COMPANY_INFO',
  CustomField = 'CUSTOM_FIELD',
  InterviewTokens = 'INTERVIEW_TOKENS',
  JobDetails = 'JOB_DETAILS',
  MyInformation = 'MY_INFORMATION',
  OfferDetails = 'OFFER_DETAILS',
  SectionTokens = 'SECTION_TOKENS',
  SignatureTokens = 'SIGNATURE_TOKENS',
  SystemTokens = 'SYSTEM_TOKENS',
}

export enum TokenNameEnum {
  AnnualBonus = 'ANNUAL_BONUS',
  AnnualSalary = 'ANNUAL_SALARY',
  ApplicationDate = 'APPLICATION_DATE',
  AvailabilitySubmissionLink = 'AVAILABILITY_SUBMISSION_LINK',
  Benefits = 'BENEFITS',
  CandidateApplicationId = 'CANDIDATE_APPLICATION_ID',
  CandidateCompany = 'CANDIDATE_COMPANY',
  CandidateCurrentTitle = 'CANDIDATE_CURRENT_TITLE',
  CandidateEmailAddress = 'CANDIDATE_EMAIL_ADDRESS',
  CandidateFirstName = 'CANDIDATE_FIRST_NAME',
  CandidateFullName = 'CANDIDATE_FULL_NAME',
  CandidateLastName = 'CANDIDATE_LAST_NAME',
  CandidateLinkedinProfile = 'CANDIDATE_LINKEDIN_PROFILE',
  CandidateMailingAddress = 'CANDIDATE_MAILING_ADDRESS',
  CandidatePhone = 'CANDIDATE_PHONE',
  CandidateProfileId = 'CANDIDATE_PROFILE_ID',
  CandidateProfileLink = 'CANDIDATE_PROFILE_LINK',
  CandidateSchool = 'CANDIDATE_SCHOOL',
  CandidateSignature = 'CANDIDATE_SIGNATURE',
  CandidateSignatureDate = 'CANDIDATE_SIGNATURE_DATE',
  CompanyLinkedinUrl = 'COMPANY_LINKEDIN_URL',
  CompanyName = 'COMPANY_NAME',
  CompanyUserSignature = 'COMPANY_USER_SIGNATURE',
  CompanyUserSignatureDate = 'COMPANY_USER_SIGNATURE_DATE',
  CompanyWebsiteUrl = 'COMPANY_WEBSITE_URL',
  DayOfWeek = 'DAY_OF_WEEK',
  EquityPackage = 'EQUITY_PACKAGE',
  HiringManagerName = 'HIRING_MANAGER_NAME',
  HourlyRate = 'HOURLY_RATE',
  InternalJobName = 'INTERNAL_JOB_NAME',
  InterviewerJobTitle = 'INTERVIEWER_JOB_TITLE',
  InterviewerName = 'INTERVIEWER_NAME',
  InterviewDate = 'INTERVIEW_DATE',
  InterviewEndTime = 'INTERVIEW_END_TIME',
  InterviewInviteLocation = 'INTERVIEW_INVITE_LOCATION',
  InterviewKitLink = 'INTERVIEW_KIT_LINK',
  InterviewName = 'INTERVIEW_NAME',
  InterviewOrganizerName = 'INTERVIEW_ORGANIZER_NAME',
  InterviewPanel = 'INTERVIEW_PANEL',
  InterviewStartTime = 'INTERVIEW_START_TIME',
  InterviewSummary = 'INTERVIEW_SUMMARY',
  InvitationLink = 'INVITATION_LINK',
  JobDescription = 'JOB_DESCRIPTION',
  JobPostUrl = 'JOB_POST_URL',
  JobReqName = 'JOB_REQ_NAME',
  JobType = 'JOB_TYPE',
  LinkToApply = 'LINK_TO_APPLY',
  ListingDescription = 'LISTING_DESCRIPTION',
  MedalistDesignation = 'MEDALIST_DESIGNATION',
  MyEmailAddress = 'MY_EMAIL_ADDRESS',
  MyFirstName = 'MY_FIRST_NAME',
  MyFullName = 'MY_FULL_NAME',
  MyJobTitle = 'MY_JOB_TITLE',
  MyScalisProfileUrl = 'MY_SCALIS_PROFILE_URL',
  OfferExpirationDate = 'OFFER_EXPIRATION_DATE',
  Office = 'OFFICE',
  OfficeAddress = 'OFFICE_ADDRESS',
  PrimaryContact = 'PRIMARY_CONTACT',
  PublicListingTitle = 'PUBLIC_LISTING_TITLE',
  RecruiterName = 'RECRUITER_NAME',
  ReportingManager = 'REPORTING_MANAGER',
  RequisitionId = 'REQUISITION_ID',
  SalesCommission = 'SALES_COMMISSION',
  ScorecardLink = 'SCORECARD_LINK',
  SelfSchedulerLink = 'SELF_SCHEDULER_LINK',
  SignOff = 'SIGN_OFF',
  SignOnBonus = 'SIGN_ON_BONUS',
  SourceName = 'SOURCE_NAME',
  SpotBonus = 'SPOT_BONUS',
  StageNameCurrent = 'STAGE_NAME_CURRENT',
  StageNameNext = 'STAGE_NAME_NEXT',
  StartDate = 'START_DATE',
  StockOptions = 'STOCK_OPTIONS',
  TodayDate = 'TODAY_DATE',
  VariableCompensation = 'VARIABLE_COMPENSATION',
}

export enum TokenStatusEnum {
  FailedTest = 'FAILED_TEST',
  NotAvailableDueToConditions = 'NOT_AVAILABLE_DUE_TO_CONDITIONS',
  NotTested = 'NOT_TESTED',
  Verified = 'VERIFIED',
}

export type TokenTestResult = {
  __typename?: 'TokenTestResult'
  OfferDocumentTemplate?: Maybe<OfferDocumentTemplate>
  OfferSectionTemplate?: Maybe<OfferSectionTemplate>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  offerDocumentTemplateId?: Maybe<Scalars['Int']['output']>
  offerSectionTemplateId?: Maybe<Scalars['Int']['output']>
  result?: Maybe<Scalars['JSON']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum TravelTypeEnum {
  No = 'NO',
  Yes = 'YES',
}

export type UndoHireInput = {
  candidateApplicationId: Scalars['Int']['input']
}

export type UndoOfferCandidateDeclinedInput = {
  candidateOfferVersionId: Scalars['Int']['input']
}

export type UndoRejectCandidatesInput = {
  applicationIds: Array<Scalars['Int']['input']>
  jobId: Scalars['String']['input']
}

export type UpdateCandidateFilterInput = {
  filters?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['Int']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCandidateOfferVersionStatusInput = {
  id: Scalars['Int']['input']
  offerStatus: OfferStatusEnum
}

export type UpdateCompanyDataInput = {
  Address?: InputMaybe<Array<UpsertAddressInput>>
  CompanyDepartment?: InputMaybe<Array<CompanyDepartmentInput>>
  CompanyInfo?: InputMaybe<CompanyInfoInput>
  CompanyOffice?: InputMaybe<Array<CompanyOfficeInput>>
  externalCompanyId?: InputMaybe<Scalars['String']['input']>
  lastActivity?: InputMaybe<Scalars['DateTime']['input']>
  subdomain?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCompanyUserGroupInput = {
  companyId: Scalars['Int']['input']
  companyUserGroupId: Scalars['Int']['input']
  departmentIds?: InputMaybe<Array<Scalars['Int']['input']>>
  externalId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type UpdateCompanyUserRoleInput = {
  companyUserId: Scalars['Int']['input']
  role: OrganizationRoleEnum
}

export type UpdateDocumentTemplateInput = {
  conditions?: InputMaybe<DocumentTemplateConditions>
  documentTemplateName?: InputMaybe<Scalars['String']['input']>
  documentTemplateText?: InputMaybe<Scalars['String']['input']>
  documentType?: InputMaybe<OfferDocumentTemplateTypeEnum>
  id: Scalars['Int']['input']
}

export type UpdateInterviewEventWhenInput = {
  endTime: Scalars['String']['input']
  startTime: Scalars['String']['input']
  timezone: Scalars['String']['input']
}

export type UpdateInterviewInput = {
  applicationId?: InputMaybe<Scalars['Int']['input']>
  calendarId?: InputMaybe<Scalars['Int']['input']>
  createConference?: InputMaybe<Scalars['Boolean']['input']>
  details?: InputMaybe<Scalars['String']['input']>
  interviewId: Scalars['Int']['input']
  interviewers?: InputMaybe<Array<Scalars['Int']['input']>>
  location?: InputMaybe<Scalars['String']['input']>
  scorecardTemplateId?: InputMaybe<Scalars['Int']['input']>
  stageId?: InputMaybe<Scalars['Int']['input']>
  when?: InputMaybe<UpdateInterviewEventWhenInput>
}

export type UpdateJobInviteInput = {
  dismissed?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['Int']['input']
  inviteStatus?: InputMaybe<InviteStatusEnum>
  token?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateScalisUserDataInput = {
  Achievement?: InputMaybe<Array<AchievementInput>>
  Address?: InputMaybe<Array<UpsertAddressInput>>
  CertificateOrLicense?: InputMaybe<Array<CertificateOrLicenseInput>>
  ContactInfo?: InputMaybe<Array<ContactInfoInput>>
  DemographicInfo?: InputMaybe<DemographicInfoInput>
  Education?: InputMaybe<Array<EducationInput>>
  Experience?: InputMaybe<Array<ExperienceInput>>
  JobSeekerHeader?: InputMaybe<Array<JobSeekerHeaderInput>>
  JobSeekerInfo?: InputMaybe<JobSeekerInfoInput>
  JobSeekerSkill?: InputMaybe<Array<JobSeekerSkillInput>>
  VolunteerWork?: InputMaybe<Array<VolunteerWorkInput>>
  WebsiteOrSocialMedia?: InputMaybe<Array<WebsiteOrSocialMediaInput>>
  activeStatus?: InputMaybe<Scalars['Boolean']['input']>
  currentStage?: InputMaybe<CurrentStageEnum>
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  profileImage?: InputMaybe<Scalars['String']['input']>
}

export type UpdateScheduledReportInput = {
  customDaysOfWeek?: InputMaybe<Array<RepeatDaysOfWeekEnum>>
  customTime?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['Int']['input']
  recipients: Array<Scalars['String']['input']>
  repeat?: InputMaybe<RepeatTypeEnum>
  reportSchedulerType: ReportSchedulerTypeEnum
  sendImmediately?: InputMaybe<Scalars['Boolean']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateSchedulerLinkInviteCandidateInput = {
  id: Scalars['Int']['input']
  type: CandidateTypeEnum
}

export type UpdateSchedulerLinkInviteInput = {
  calendarId: Scalars['Int']['input']
  candidates: Array<UpdateSchedulerLinkInviteCandidateInput>
  durationMinutes?: InputMaybe<Scalars['Int']['input']>
  interviewers: Array<Scalars['Int']['input']>
  inviteId: Scalars['Int']['input']
  message?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSchedulerLinkSettingsInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  maxInterviewsPerDay?: InputMaybe<Scalars['Int']['input']>
  minimumHour?: InputMaybe<Scalars['Int']['input']>
  rollingDays?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateUsersInCompanyUserGroupInput = {
  companyId: Scalars['Int']['input']
  companyUserGroupId: Scalars['Int']['input']
  companyUserIds: Array<Scalars['Int']['input']>
}

export type UploadS3FileInput = {
  base64File: Scalars['String']['input']
  contentType: Scalars['String']['input']
  fileKey: Scalars['String']['input']
}

export type UploadS3FileOutput = {
  __typename?: 'UploadS3FileOutput'
  fileKey: Scalars['String']['output']
  filePath: Scalars['String']['output']
}

export type UploadedCandidate = {
  __typename?: 'UploadedCandidate'
  Achievement: Array<UploadedCandidateAchievement>
  CandidateProfile: CandidateProfile
  CertificateOrLicense: Array<UploadedCandidateCertificateOrLicense>
  Company: Company
  CompanyUser: CompanyUser
  Education: Array<UploadedCandidateEducation>
  Experience: Array<UploadedCandidateExperience>
  JobSeekerSkill?: Maybe<Array<UploadedCandidateJobSeekerSkills>>
  VolunteerWork: Array<UploadedCandidateVolunteerWork>
  WebsiteOrSocialMedia: Array<UploadedCandidateWebsiteOrSocialMedia>
  companyId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  emailAddress?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  lastName?: Maybe<Scalars['String']['output']>
  linkedinProfile?: Maybe<Scalars['String']['output']>
  profileImage?: Maybe<Scalars['String']['output']>
  uploadedCandidateType?: Maybe<UploadedCandidateTypeEnum>
}

export type UploadedCandidateAchievement = {
  __typename?: 'UploadedCandidateAchievement'
  description?: Maybe<Scalars['JSON']['output']>
  id: Scalars['Int']['output']
  issueYear?: Maybe<Scalars['Int']['output']>
  issuingAuthority?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  searchDescription?: Maybe<Scalars['String']['output']>
  uploadedCandidateId?: Maybe<Scalars['Int']['output']>
}

export type UploadedCandidateCertificateOrLicense = {
  __typename?: 'UploadedCandidateCertificateOrLicense'
  description?: Maybe<Scalars['JSON']['output']>
  id: Scalars['Int']['output']
  issueYear?: Maybe<Scalars['Int']['output']>
  issuingAuthority?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  searchDescription?: Maybe<Scalars['String']['output']>
  uploadedCandidateId?: Maybe<Scalars['Int']['output']>
}

export type UploadedCandidateEducation = {
  __typename?: 'UploadedCandidateEducation'
  additionalInformation?: Maybe<Scalars['String']['output']>
  areasOfStudy?: Maybe<Scalars['JSON']['output']>
  cumGPA?: Maybe<Scalars['Float']['output']>
  cumGPAScale?: Maybe<Scalars['Float']['output']>
  currentlyStudying?: Maybe<Scalars['Boolean']['output']>
  departmentCumGPA?: Maybe<Scalars['Float']['output']>
  departmentCumGPAScale?: Maybe<Scalars['Float']['output']>
  educationLevel?: Maybe<EducationLevelEnum>
  endDate?: Maybe<Scalars['DateTime']['output']>
  hideCumGPA?: Maybe<Scalars['Boolean']['output']>
  hideDepartmentGPA?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['Int']['output']
  institutionName?: Maybe<Scalars['String']['output']>
  majors?: Maybe<Scalars['JSON']['output']>
  minors?: Maybe<Scalars['JSON']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
  uploadedCandidateId?: Maybe<Scalars['Int']['output']>
}

export type UploadedCandidateExperience = {
  __typename?: 'UploadedCandidateExperience'
  additionalComp?: Maybe<Scalars['Boolean']['output']>
  description?: Maybe<Scalars['String']['output']>
  employerName?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  industry?: Maybe<IndustryEnum>
  isCurrentJob?: Maybe<Scalars['Boolean']['output']>
  isCurrentlyWorking?: Maybe<Scalars['Boolean']['output']>
  jobPay?: Maybe<Scalars['Float']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  jobType?: Maybe<JobTypeEnum>
  location?: Maybe<Scalars['String']['output']>
  locationType?: Maybe<Scalars['String']['output']>
  longFormDesc?: Maybe<Scalars['String']['output']>
  managementPosition?: Maybe<Scalars['Boolean']['output']>
  payType?: Maybe<Scalars['String']['output']>
  searchLongFormDesc?: Maybe<Scalars['String']['output']>
  searchShortFormDesc?: Maybe<Scalars['String']['output']>
  shortFormDesc?: Maybe<Scalars['JSON']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
  subIndustry?: Maybe<Scalars['String']['output']>
  teamSize?: Maybe<TeamSizeEnum>
  uploadedCandidateId?: Maybe<Scalars['Int']['output']>
  workPlaceCandidate?: Maybe<WorkPlaceCandidateEnum>
}

export type UploadedCandidateJobSeekerSkills = {
  __typename?: 'UploadedCandidateJobSeekerSkills'
  id: Scalars['Int']['output']
  level?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  sequenceNumber?: Maybe<Scalars['Int']['output']>
  yearsOfExp?: Maybe<Scalars['Int']['output']>
}

export type UploadedCandidateSkill = {
  __typename?: 'UploadedCandidateSkill'
  experienceId?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  level?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  yearsOfExp?: Maybe<Scalars['Int']['output']>
}

export enum UploadedCandidateTypeEnum {
  Scraped = 'SCRAPED',
  Uploaded = 'UPLOADED',
}

export type UploadedCandidateVolunteerWork = {
  __typename?: 'UploadedCandidateVolunteerWork'
  description?: Maybe<Scalars['JSON']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  isCurrentlyWorking?: Maybe<Scalars['Boolean']['output']>
  location?: Maybe<Scalars['String']['output']>
  organizationName?: Maybe<Scalars['String']['output']>
  roleType?: Maybe<RoleTypeEnum>
  searchDescription?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
  uploadedCandidateId?: Maybe<Scalars['Int']['output']>
  volunteerJobTitle?: Maybe<Scalars['String']['output']>
}

export type UploadedCandidateWebsiteOrSocialMedia = {
  __typename?: 'UploadedCandidateWebsiteOrSocialMedia'
  id: Scalars['Int']['output']
  type: WebsiteTypeEnum
  uploadedCandidateId?: Maybe<Scalars['Int']['output']>
  websiteURL: Scalars['String']['output']
}

export type UploadedDocument = {
  __typename?: 'UploadedDocument'
  UploadedDocumentCandidateApplication: UploadedDocumentCandidateApplication
  UploadedDocumentCandidateProfile: UploadedDocumentCandidateProfile
  createdAt?: Maybe<Scalars['DateTime']['output']>
  documentName?: Maybe<Scalars['String']['output']>
  documentPath?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
}

export type UploadedDocumentCandidateApplication = {
  __typename?: 'UploadedDocumentCandidateApplication'
  CandidateApplication: CandidateApplication
  CompanyUser?: Maybe<CompanyUser>
  UploadedDocument: UploadedDocument
  candidateApplicationId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  uploadedDocumentId?: Maybe<Scalars['Int']['output']>
}

export type UploadedDocumentCandidateProfile = {
  __typename?: 'UploadedDocumentCandidateProfile'
  CandidateProfile: CandidateProfile
  CompanyUser: CompanyUser
  UploadedDocument: UploadedDocument
  candidateProfileId?: Maybe<Scalars['Int']['output']>
  companyUserId?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  uploadedDocumentId?: Maybe<Scalars['Int']['output']>
}

export type UpsertAddressInput = {
  activeStatus?: InputMaybe<Scalars['Boolean']['input']>
  addressLine1?: InputMaybe<Scalars['String']['input']>
  addressLine2?: InputMaybe<Scalars['String']['input']>
  addressType?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  companyId?: InputMaybe<Scalars['Int']['input']>
  contactName?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  latitude?: InputMaybe<Scalars['String']['input']>
  longitude?: InputMaybe<Scalars['String']['input']>
  officeId?: InputMaybe<Scalars['Int']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type UpsertApplicationFormInput = {
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  companyOfficeId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpsertApplicationFormScreeningQuestionInput = {
  applicationFormId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  rankOrder?: InputMaybe<Scalars['Int']['input']>
  screeningQuestionId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertCandidateApplicationNoteInput = {
  UserNote: CandidateApplicationUserNoteInput
  candidateApplicationId: Scalars['Int']['input']
  id?: InputMaybe<Scalars['Int']['input']>
  privateStatus?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpsertCandidateApplicationRatingInput = {
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  candidateProfileId?: InputMaybe<Scalars['Int']['input']>
  score: Scalars['Float']['input']
}

export type UpsertCandidateCollaboratorInput = {
  candidateProfileId?: InputMaybe<Scalars['Int']['input']>
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  requisitionJobDescriptionId?: InputMaybe<Scalars['Int']['input']>
  roleId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertCandidateMessageInput = {
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  customMessage?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  templateMessageId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertCandidateOfferCustomFieldInput = {
  CandidateOfferVersion?: InputMaybe<UpsertCandidateOfferVersionInput>
  OfferCustomField?: InputMaybe<UpsertOfferCustomFieldInput>
  candidateOfferVersionId?: InputMaybe<Scalars['Int']['input']>
  fieldValue?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  offerCustomFieldId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertCandidateOfferFileInput = {
  CandidateOfferVersion?: InputMaybe<UpsertCandidateOfferVersionInput>
  candidateOfferVersionId?: InputMaybe<Scalars['Int']['input']>
  fileName?: InputMaybe<Scalars['String']['input']>
  filePath?: InputMaybe<Scalars['String']['input']>
  fileType?: InputMaybe<OfferFileTypeEnum>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertCandidateOfferInput = {
  CandidateOfferVersion?: InputMaybe<UpsertCandidateOfferVersionInput>
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertCandidateOfferVersionInput = {
  CandidateOffer?: InputMaybe<UpsertCandidateOfferInput>
  CandidateOfferCustomField?: InputMaybe<Array<UpsertCandidateOfferCustomFieldInput>>
  additionalCompensation?: InputMaybe<Array<Scalars['String']['input']>>
  benefits?: InputMaybe<Array<Scalars['String']['input']>>
  candidateName: Scalars['String']['input']
  candidateOfferId?: InputMaybe<Scalars['Int']['input']>
  companyOfficeId?: InputMaybe<Scalars['Int']['input']>
  compensation: Scalars['Float']['input']
  expirationDate?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  jobTitle: Scalars['String']['input']
  jobType: JobTypeEnum
  lastUpdatedStatusAt?: InputMaybe<Scalars['String']['input']>
  manager?: InputMaybe<Scalars['String']['input']>
  offerStatus: OfferStatusEnum
  payType: PayTypeEnum
  signatureRequestId?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type UpsertCandidateProfileInput = {
  ProfileDetails: ProfileDetailsInput
  candidateProfileId: Scalars['Int']['input']
}

export type UpsertCandidateProfileNoteInput = {
  UserNote: CandidateProfileUserNoteInput
  candidateProfileId: Scalars['Int']['input']
  id?: InputMaybe<Scalars['Int']['input']>
  privateStatus?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpsertCandidateScorecardAnswersInput = {
  answer?: InputMaybe<AnswerInput>
  answerPreferredStatus?: InputMaybe<Scalars['Boolean']['input']>
  candidateScorecardId?: InputMaybe<Scalars['Int']['input']>
  dateAnswer?: InputMaybe<Scalars['DateTime']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  overallRecommendation?: InputMaybe<OverallRecommendationEnum>
  scoreAnswer?: InputMaybe<Scalars['Int']['input']>
  scorecardTemplateQuestionId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertCandidateScorecardInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  scorecardTemplateId?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<ScorecardStatusTypeEnum>
  templateStageId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertEmailInput = {
  activeStatus?: InputMaybe<Scalars['Boolean']['input']>
  email: Scalars['String']['input']
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>
  isVisibleToEmployers?: InputMaybe<Scalars['Boolean']['input']>
  password?: InputMaybe<Scalars['String']['input']>
}

export type UpsertHiringTeamMemberInput = {
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  requisitionJobDescriptionId?: InputMaybe<Scalars['Int']['input']>
  roleId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertJobListingInput = {
  Address?: InputMaybe<UpsertAddressInput>
  JobListingScreeningQuestion?: InputMaybe<Array<UpsertJobListingScreeningQuestionInput>>
  acceptingCandidates?: InputMaybe<Scalars['Boolean']['input']>
  companyDefault?: InputMaybe<Scalars['Boolean']['input']>
  displayPayOnListing?: InputMaybe<Scalars['Boolean']['input']>
  externalTitle?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  industry?: InputMaybe<IndustryEnum>
  internalMatchingDescription?: InputMaybe<Scalars['String']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  listingTitle?: InputMaybe<Scalars['String']['input']>
  postedAt?: InputMaybe<Scalars['String']['input']>
  publicDescription?: InputMaybe<Scalars['String']['input']>
  searchInternalMatchingDescription?: InputMaybe<Scalars['String']['input']>
  searchPublicDescription?: InputMaybe<Scalars['String']['input']>
  stage?: InputMaybe<StageEnum>
  stateAndOrCountry?: InputMaybe<Scalars['String']['input']>
  subIndustry?: InputMaybe<SubIndustryTypeEnum>
  workplaceType?: InputMaybe<WorkplaceTypeEnum>
}

export type UpsertJobListingJobPipelineInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingId: Scalars['Int']['input']
  jobPipelineId: Scalars['Int']['input']
}

export type UpsertJobListingScreeningQuestionInput = {
  ScreeningQuestion?: InputMaybe<UpsertScreeningQuestionInput>
  active?: InputMaybe<Scalars['Boolean']['input']>
  displaySequenceNumber?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  screeningQuestionId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertJobOpeningInput = {
  closedDate?: InputMaybe<Scalars['String']['input']>
  closedReason?: InputMaybe<ClosedReasonEnum>
  companyId?: InputMaybe<Scalars['Int']['input']>
  extOpeningId?: InputMaybe<Scalars['String']['input']>
  filledByApplication?: InputMaybe<Scalars['Int']['input']>
  filledByCandidate?: InputMaybe<Scalars['Int']['input']>
  filledDate?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  openingStatus?: InputMaybe<OpeningStatusEnum>
  openingType?: InputMaybe<OpeningTypeEnum>
  startDate?: InputMaybe<Scalars['String']['input']>
  targetHireDate?: InputMaybe<Scalars['String']['input']>
}

export type UpsertJobPipelineInput = {
  StageSettings?: InputMaybe<UpsertStageSettingsInput>
  TemplatePipeline?: InputMaybe<UpsertTemplatePipelineInput>
  id?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  templatePipelineId?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpsertOfferCustomFieldInput = {
  CandidateOfferCustomField?: InputMaybe<UpsertCandidateOfferCustomFieldInput>
  OfferTemplateCondition?: InputMaybe<UpsertOfferTemplateConditionInput>
  /** Type of input field (e.g., text, number, select) */
  fieldConfigurationType?: InputMaybe<CustomFieldInputTypeEnum>
  /** Description of the field */
  fieldDescription?: InputMaybe<Scalars['String']['input']>
  /** Name of the custom field */
  fieldName?: InputMaybe<Scalars['String']['input']>
  /** Flag indicating if the field is required */
  fieldRequired?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag indicating if a system token should be generated */
  generateSystemToken?: InputMaybe<Scalars['Boolean']['input']>
  /** Unique identifier of the offer custom field */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Options for multiple choice fields */
  multipleChoiceOptions?: InputMaybe<Scalars['JSON']['input']>
  /** Name of the system token if generated */
  systemTokenName?: InputMaybe<Scalars['String']['input']>
  /** ID of the associated template condition */
  templateConditionId?: InputMaybe<Scalars['Int']['input']>
  /** Flag indicating if changes should trigger a new offer version */
  triggerNewOfferVersion?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpsertOfferDocumentTemplateInput = {
  TokenTestResult?: InputMaybe<UpsertTokenTestResultInput>
  documentFileName?: InputMaybe<Scalars['String']['input']>
  documentFilePath?: InputMaybe<Scalars['String']['input']>
  documentTemplateName?: InputMaybe<Scalars['String']['input']>
  documentTemplateStatus?: InputMaybe<OfferDocumentTemplateStatusEnum>
  documentTemplateText?: InputMaybe<Scalars['String']['input']>
  documentType?: InputMaybe<OfferDocumentTemplateTypeEnum>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertOfferDocumentTemplateSectionInput = {
  OfferDocumentTemplate?: InputMaybe<UpsertOfferDocumentTemplateInput>
  OfferSectionTemplate?: InputMaybe<UpsertOfferSectionTemplateInput>
  offerDocumentTemplateId: Scalars['Int']['input']
  offerSectionTemplateId: Scalars['Int']['input']
}

export type UpsertOfferSectionTemplateInput = {
  OfferDocumentTemplateSection?: InputMaybe<UpsertOfferDocumentTemplateSectionInput>
  OfferTemplateCondition?: InputMaybe<UpsertOfferTemplateConditionInput>
  TokenTestResult?: InputMaybe<UpsertTokenTestResultInput>
  id?: InputMaybe<Scalars['Int']['input']>
  sectionTemplateName?: InputMaybe<Scalars['String']['input']>
  sectionTemplateStatus?: InputMaybe<TokenStatusEnum>
  sectionTemplateText?: InputMaybe<Scalars['String']['input']>
  sectionToken?: InputMaybe<Scalars['String']['input']>
  tokenDescription?: InputMaybe<Scalars['String']['input']>
}

export type UpsertOfferTemplateConditionInput = {
  /** Type of condition that determines when this template should be applied */
  conditionType?: InputMaybe<ConditionTypeEnum>
  /** ID of the custom field this condition is associated with */
  customFieldId?: InputMaybe<Scalars['Int']['input']>
  /** ID of the department this condition applies to */
  departmentId?: InputMaybe<Scalars['Int']['input']>
  /** Type of employment (e.g., full-time, part-time) this condition applies to */
  employmentType?: InputMaybe<JobTypeEnum>
  /** Unique identifier for the template condition */
  id?: InputMaybe<Scalars['Int']['input']>
  /** ID of the offer document template this condition is associated with */
  offerDocumentTemplateId?: InputMaybe<Scalars['Int']['input']>
  /** ID of the office location this condition applies to */
  officeId?: InputMaybe<Scalars['Int']['input']>
  /** Type of payment (e.g., hourly, salary) this condition applies to */
  payType?: InputMaybe<PayTypeEnum>
  /** ID of the section template this condition is associated with */
  sectionTemplateId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertRequisitionInput = {
  additionalComp?: InputMaybe<Array<Scalars['String']['input']>>
  benefits?: InputMaybe<Array<Scalars['String']['input']>>
  candidateCollaborator?: InputMaybe<Array<UpsertCandidateCollaboratorInput>>
  collegeGradPref?: InputMaybe<Scalars['Boolean']['input']>
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  companyOfficeId?: InputMaybe<Scalars['Int']['input']>
  diverseHirePref?: InputMaybe<Scalars['Boolean']['input']>
  educationLevelPref?: InputMaybe<EducationLevelPrefEnum>
  extRequisitionId?: InputMaybe<Scalars['String']['input']>
  hiringTeamMember?: InputMaybe<Array<UpsertHiringTeamMemberInput>>
  id?: InputMaybe<Scalars['Int']['input']>
  jobLevel?: InputMaybe<JobLevelEnum>
  jobListings?: InputMaybe<Array<UpsertJobListingInput>>
  jobName?: InputMaybe<Scalars['String']['input']>
  jobOpening?: InputMaybe<Array<UpsertJobOpeningInput>>
  jobPipelines?: InputMaybe<Array<UpsertJobPipelineInput>>
  jobStatus?: InputMaybe<JobStatusEnum>
  jobType?: InputMaybe<JobTypeEnum>
  locationDistancePref?: InputMaybe<Scalars['Int']['input']>
  maxPay?: InputMaybe<Scalars['Float']['input']>
  minPay?: InputMaybe<Scalars['Float']['input']>
  nonTraditionalPref?: InputMaybe<Scalars['Boolean']['input']>
  openedAt?: InputMaybe<Scalars['String']['input']>
  payType?: InputMaybe<PayTypeEnum>
  primaryContact?: InputMaybe<Scalars['Int']['input']>
  requisitionJobDescription?: InputMaybe<UpsertRequisitionJobDescriptionInput>
  requisitionSkills?: InputMaybe<Array<RequisitionSkillInput>>
  stageCollaborator?: InputMaybe<Array<UpsertStageCollaboratorInput>>
  teamSize?: InputMaybe<TeamSizeEnum>
  travelType?: InputMaybe<TravelTypeEnum>
  veteranPref?: InputMaybe<Scalars['Boolean']['input']>
  yearsOfExperiencePref?: InputMaybe<YearsOfExperienceEnum>
}

export type UpsertRequisitionJobDescriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertRequisitionSkillInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  requisitionId: Scalars['Int']['input']
  sequenceNumber?: InputMaybe<Scalars['Int']['input']>
  skillId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertScorecardTemplateInput = {
  ScorecardTemplateSections?: InputMaybe<Array<ScorecardTemplateSectionInput>>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  companyTemplate?: InputMaybe<Scalars['Boolean']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  scorecardDescription?: InputMaybe<Scalars['String']['input']>
  scorecardName?: InputMaybe<Scalars['String']['input']>
  timestampAt?: InputMaybe<Scalars['DateTime']['input']>
  visible?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpsertScreeningQuestionAnswerInput = {
  answer?: InputMaybe<Scalars['String']['input']>
  answerStatus?: InputMaybe<Scalars['Boolean']['input']>
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  listingScreeningQuestionId?: InputMaybe<Scalars['Int']['input']>
}

/** Input for creating or updating a screening question */
export type UpsertScreeningQuestionInput = {
  /** Comma-separated list of answer options for MULTIPLE_CHOICE or MULTIPLE_SELECT questions */
  ansOptions?: InputMaybe<Scalars['String']['input']>
  /** ID of the company that owns this question */
  companyId?: InputMaybe<Scalars['Int']['input']>
  /** ID of the company user who created this question */
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  globalQuestionOrder?: InputMaybe<Scalars['Int']['input']>
  /** ID of the screening question to update (omit for new questions) */
  id?: InputMaybe<Scalars['Int']['input']>
  isActiveGlobally?: InputMaybe<Scalars['Boolean']['input']>
  isGlobalQuestion?: InputMaybe<Scalars['Boolean']['input']>
  /** If true, answering incorrectly will trigger automatic rejection */
  isRejectionQuestion?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether this is a reusable template question */
  isTemplateQuestion?: InputMaybe<Scalars['Boolean']['input']>
  /** For NUMERICAL questions, the maximum acceptable value */
  maxVal?: InputMaybe<Scalars['Int']['input']>
  /** For NUMERICAL questions, the minimum acceptable value */
  minVal?: InputMaybe<Scalars['Int']['input']>
  /** Array of preferred answers for MULTIPLE_SELECT questions */
  multipleSelectPreferredAnswers?: InputMaybe<Array<Scalars['String']['input']>>
  /** For YES_NO and MULTIPLE_CHOICE: the correct/preferred answer(s) as comma-separated values */
  preferredAnswer?: InputMaybe<Scalars['String']['input']>
  /** For MULTIPLE_SELECT: determines if ALL_OF or ANY_OF the preferred answers are required */
  preferredAnswerType?: InputMaybe<PreferredAnswerTypeEnum>
  /** Additional description or context to help candidates understand the question */
  questionDesc?: InputMaybe<Scalars['String']['input']>
  /** The main text of the screening question shown to candidates */
  questionTitle?: InputMaybe<Scalars['String']['input']>
  /** Type of question that determines the input type and validation rules */
  questionType?: InputMaybe<QuestionTypeEnum>
  /** Custom message to display when a candidate is rejected based on this question */
  rejectMessage?: InputMaybe<Scalars['String']['input']>
  /** Controls how selection options are displayed to the user */
  selectionDisplay?: InputMaybe<SelectionDisplayEnum>
  /** Reference to a template message for rejection */
  templateMessageId?: InputMaybe<Scalars['Int']['input']>
  /** Status related to validation of numerical values */
  valStatus?: InputMaybe<Scalars['String']['input']>
}

export type UpsertSkillInput = {
  experienceId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  level?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  yearsOfExp?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertStageCollaboratorInput = {
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  requisitionJobDescriptionId?: InputMaybe<Scalars['Int']['input']>
  templateStageId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertStageSettingsInput = {
  InterviewTeam?: InputMaybe<Array<InterviewTeamInput>>
  id?: InputMaybe<Scalars['Int']['input']>
  jobPipelineId?: InputMaybe<Scalars['Int']['input']>
  scorecardTemplateId?: InputMaybe<Scalars['Int']['input']>
  templateStageId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertTemplateJobDescriptionInput = {
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpsertTemplateMessageInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  messageOwner?: InputMaybe<MessageOwnerEnum>
  messageType?: InputMaybe<MessageTypeEnum>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpsertTemplateMilestoneInput = {
  TemplateStage?: InputMaybe<Array<UpsertTemplateStageInput>>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<NameEnum>
  rankOrder?: InputMaybe<Scalars['Int']['input']>
  templatePipelineId?: InputMaybe<Scalars['Int']['input']>
}

export type UpsertTemplatePipelineInput = {
  TemplateMilestones?: InputMaybe<Array<UpsertTemplateMilestoneInput>>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  companyId?: InputMaybe<Scalars['Int']['input']>
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  ghostPipeline?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  pipelinedUsed?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpsertTemplateStageInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  rankOrder?: InputMaybe<Scalars['Int']['input']>
  templateMilestoneId?: InputMaybe<Scalars['Int']['input']>
  templatePipelineId?: InputMaybe<Scalars['Int']['input']>
  visibility?: InputMaybe<TemplateStageVisibilityEnum>
}

export type UpsertTokenTestResultInput = {
  id?: InputMaybe<Scalars['Int']['input']>
  offerDocumentTemplateId?: InputMaybe<Scalars['Int']['input']>
  offerSectionTemplateId?: InputMaybe<Scalars['Int']['input']>
  result?: InputMaybe<Scalars['String']['input']>
}

export enum UserActionEnum {
  AdvanceCandidate = 'ADVANCE_CANDIDATE',
  ApplicationWithdrawn = 'APPLICATION_WITHDRAWN',
  AvailabilitySubmitted = 'AVAILABILITY_SUBMITTED',
  CandidateApplied = 'CANDIDATE_APPLIED',
  CandidateDetailsUpdated = 'CANDIDATE_DETAILS_UPDATED',
  CandidateRatingSubmitted = 'CANDIDATE_RATING_SUBMITTED',
  CandidateRatingUpdated = 'CANDIDATE_RATING_UPDATED',
  CandidateUploaded = 'CANDIDATE_UPLOADED',
  EmailCandidate = 'EMAIL_CANDIDATE',
  InterviewConfirmationSent = 'INTERVIEW_CONFIRMATION_SENT',
  InterviewScheduledLink = 'INTERVIEW_SCHEDULED_LINK',
  InterviewScheduledManually = 'INTERVIEW_SCHEDULED_MANUALLY',
  InviteCandidate = 'INVITE_CANDIDATE',
  InviteExpired = 'INVITE_EXPIRED',
  InviteRejected = 'INVITE_REJECTED',
  MarkInterviewComplete = 'MARK_INTERVIEW_COMPLETE',
  MatchCandidate = 'MATCH_CANDIDATE',
  OfferAcceptedByCandidate = 'OFFER_ACCEPTED_BY_CANDIDATE',
  OfferCreated = 'OFFER_CREATED',
  OfferDeclinedByCandidate = 'OFFER_DECLINED_BY_CANDIDATE',
  OfferDeleted = 'OFFER_DELETED',
  OfferNewVersionCreated = 'OFFER_NEW_VERSION_CREATED',
  OfferSentToCandidate = 'OFFER_SENT_TO_CANDIDATE',
  OfferSentToCandidateAwaitingSignature = 'OFFER_SENT_TO_CANDIDATE_AWAITING_SIGNATURE',
  OfferVoided = 'OFFER_VOIDED',
  ReferredCandidate = 'REFERRED_CANDIDATE',
  RejectionCancelled = 'REJECTION_CANCELLED',
  RejectionPending = 'REJECTION_PENDING',
  RejectCandidate = 'REJECT_CANDIDATE',
  RemoveCandidate = 'REMOVE_CANDIDATE',
  RequestAvailability = 'REQUEST_AVAILABILITY',
  ScorecardComplete = 'SCORECARD_COMPLETE',
  ScorecardCreated = 'SCORECARD_CREATED',
  ScorecardEdited = 'SCORECARD_EDITED',
  ScorecardInProgress = 'SCORECARD_IN_PROGRESS',
  SendSchedulerLink = 'SEND_SCHEDULER_LINK',
  UnmarkInterviewComplete = 'UNMARK_INTERVIEW_COMPLETE',
  UploadDocument = 'UPLOAD_DOCUMENT',
}

export type UserInvitation = {
  __typename?: 'UserInvitation'
  CompanyUser?: Maybe<CompanyUser>
  ScalisUser?: Maybe<ScalisUser>
  companyId?: Maybe<Scalars['Int']['output']>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  invitationStatus?: Maybe<InvitationStatusEnum>
  invitedAs?: Maybe<RoleEnum>
  scalisUserId?: Maybe<Scalars['Int']['output']>
}

export type UserNote = {
  __typename?: 'UserNote'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  noteText?: Maybe<Scalars['JSON']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type UserRoles = {
  __typename?: 'UserRoles'
  role: Role
  roleId: Scalars['Int']['output']
  userId: Scalars['Int']['output']
}

export type UserSubscription = {
  __typename?: 'UserSubscription'
  ScalisUser: ScalisUser
  canceledAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  currentPeriodEnd: Scalars['DateTime']['output']
  currentPeriodStart: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  productId: Scalars['String']['output']
  scalisUserId: Scalars['Int']['output']
  status: SubscriptionStatus
  stripeCustomerId: Scalars['String']['output']
  subscriptionId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ValidateOfferDocumentTemplateInput = {
  id: Scalars['Int']['input']
}

export type ValidateOfferSectionTemplateInput = {
  id: Scalars['Int']['input']
}

export type ValidationError = {
  __typename?: 'ValidationError'
  message: Array<Scalars['String']['output']>
}

export type VerifyEmailCodeInput = {
  code: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type VerifyPasswordCodeInput = {
  code: Scalars['String']['input']
}

export enum VeteranStatusEnum {
  ArmedForcesServiceMedalVeteran = 'ARMED_FORCES_SERVICE_MEDAL_VETERAN',
  DisabledVeteran = 'DISABLED_VETERAN',
  DoNotWishToAnswer = 'DO_NOT_WISH_TO_ANSWER',
  NotAProtectedVeteran = 'NOT_A_PROTECTED_VETERAN',
  OtherProtectedVeteran = 'OTHER_PROTECTED_VETERAN',
  RecentlySeparatedVeteran = 'RECENTLY_SEPARATED_VETERAN',
}

export type VolunteerWork = {
  __typename?: 'VolunteerWork'
  description?: Maybe<Scalars['JSON']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  isCurrentlyWorking?: Maybe<Scalars['Boolean']['output']>
  jobSeekerId?: Maybe<Scalars['Int']['output']>
  location?: Maybe<Scalars['String']['output']>
  organizationName?: Maybe<Scalars['String']['output']>
  roleType?: Maybe<RoleTypeEnum>
  searchDescription?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
  volunteerJobTitle?: Maybe<Scalars['String']['output']>
}

export type VolunteerWorkInput = {
  description?: InputMaybe<Scalars['JSON']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  isCurrentlyWorking?: InputMaybe<Scalars['Boolean']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  organizationName?: InputMaybe<Scalars['String']['input']>
  roleType?: InputMaybe<RoleTypeEnum>
  searchDescription?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  volunteerJobTitle?: InputMaybe<Scalars['String']['input']>
}

export type WebsiteOrSocialMedia = {
  __typename?: 'WebsiteOrSocialMedia'
  id: Scalars['Int']['output']
  scalisUserId: Scalars['Int']['output']
  type: WebsiteTypeEnum
  websiteURL: Scalars['String']['output']
}

export type WebsiteOrSocialMediaInput = {
  type: WebsiteTypeEnum
  websiteURL: Scalars['String']['input']
}

export enum WebsiteTypeEnum {
  Facebook = 'FACEBOOK',
  Github = 'GITHUB',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  Personal_1 = 'PERSONAL_1',
  Personal_2 = 'PERSONAL_2',
  Tiktok = 'TIKTOK',
  Twitter = 'TWITTER',
}

export enum WillingToRelocateEnum {
  No = 'NO',
  Yes = 'YES',
  YesToOnlySpecificAreas = 'YES_TO_ONLY_SPECIFIC_AREAS',
}

export enum WorkPlaceCandidateEnum {
  Hybrid = 'HYBRID',
  OnSite = 'ON_SITE',
  Remote = 'REMOTE',
}

export enum WorkplaceType {
  EntirelyRemote = 'ENTIRELY_REMOTE',
  HybridRemote = 'HYBRID_REMOTE',
  InPerson = 'IN_PERSON',
}

export enum WorkplaceTypeEnum {
  EntirelyRemote = 'ENTIRELY_REMOTE',
  HybridRemote = 'HYBRID_REMOTE',
  InPerson = 'IN_PERSON',
}

export type YearsOfExperience = {
  max: Scalars['Int']['input']
  min: Scalars['Int']['input']
}

export enum YearsOfExperienceEnum {
  Count_1To_2 = 'COUNT_1_TO_2',
  Count_3To_5 = 'COUNT_3_TO_5',
  Count_5To_7 = 'COUNT_5_TO_7',
  Count_7To_9 = 'COUNT_7_TO_9',
  Count_10Plus = 'COUNT_10_PLUS',
}

export type AddressComponent = {
  __typename?: 'addressComponent'
  languageCode?: Maybe<Scalars['String']['output']>
  longText?: Maybe<Scalars['String']['output']>
  shortText?: Maybe<Scalars['String']['output']>
  types?: Maybe<Array<Scalars['String']['output']>>
}

export type CandidateApplicationFilterContent = {
  __typename?: 'candidateApplicationFilterContent'
  candidateNames: Array<FilterContentItem>
  companies: Array<FilterContentItem>
  creditedCompanyUser: Array<FilterContentItem>
  degrees: Array<FilterContentItem>
  emails: Array<FilterContentItem>
  jobTitles: Array<FilterContentItem>
  majors: Array<FilterContentItem>
  schools: Array<FilterContentItem>
  skills: Array<FilterContentItem>
}

export type CountBookmarkedJob = {
  __typename?: 'countBookmarkedJob'
  count: Scalars['Int']['output']
}

export type CountOutput = {
  __typename?: 'countOutput'
  count: Scalars['Int']['output']
}

export type EducationLevel = {
  __typename?: 'educationLevel'
  languageCode: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ExperienceTenure = {
  __typename?: 'experienceTenure'
  average: Scalars['Float']['output']
  longest: Scalars['Float']['output']
  shortest: Scalars['Float']['output']
}

export type FilterContentItem = {
  __typename?: 'filterContentItem'
  id?: Maybe<Scalars['Int']['output']>
  value: Scalars['String']['output']
}

export type JobInviteNotSentOutput = {
  __typename?: 'jobInviteNotSentOutput'
  candidateProfileId: Scalars['Int']['output']
  jobListingId: Scalars['Int']['output']
}

export type Location = {
  __typename?: 'location'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
}

export type Place = {
  __typename?: 'place'
  addressComponents: Array<AddressComponent>
  formattedAddress: Scalars['String']['output']
  id: Scalars['String']['output']
  location: Location
}

export type PlacePrediction = {
  __typename?: 'placePrediction'
  description: Scalars['String']['output']
  placeId: Scalars['String']['output']
  structuredFormatting?: Maybe<StructuredFormatting>
}

export type StructuredFormatting = {
  __typename?: 'structuredFormatting'
  mainText?: Maybe<Scalars['String']['output']>
  secondaryText?: Maybe<Scalars['String']['output']>
}

export type AddressFragmentFragment = {
  __typename?: 'Address'
  id: number
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
  postalCode?: string | null
  timeZone?: string | null
}

export type ApplicantNotesFragmentFragment = {
  __typename?: 'ApplicantNotes'
  id: string
  createdAt?: any | null
  candidateApplicationId?: number | null
  scalisUserId?: number | null
  UserNote?: { __typename?: 'UserNote'; id: string; noteText?: any | null } | null
}

export type CandidateCollaboratorFragmentFragment = {
  __typename?: 'CandidateCollaborator'
  id: string
  requisitionId?: number | null
  companyUserId?: number | null
  candidateProfileId?: number | null
  roleId?: number | null
  requisitionJobDescriptionId?: number | null
}

export type CompanyDepartmentFragmentFragment = {
  __typename?: 'CompanyDepartment'
  id: string
  companyId?: number | null
  departmentName?: string | null
  subordinateTo?: number | null
  externalId?: string | null
  createdAt?: any | null
  updatedAt?: any | null
}

export type CompanyInfoFragmentFragment = {
  __typename?: 'CompanyInfo'
  companyName?: string | null
  logo?: string | null
}

export type CompanyRequiredFieldsFragment = {
  __typename?: 'Company'
  createdAt?: any | null
  externalCompanyId?: string | null
  id: string
  lastActivity?: any | null
  subdomain?: string | null
  updatedAt?: any | null
  Address: Array<{
    __typename?: 'Address'
    activeStatus?: boolean | null
    addressLine1?: string | null
    addressLine2?: string | null
    addressType?: string | null
    city?: string | null
    contactName?: string | null
    createdAt?: any | null
    country?: string | null
    id: number
    latitude?: string | null
    longitude?: string | null
    postalCode?: string | null
    scalisUserId?: number | null
    timeZone?: string | null
    state?: string | null
    updatedAt?: any | null
  }>
  CompanyInfo?: {
    __typename?: 'CompanyInfo'
    atsNeeds?: string | null
    companyId?: number | null
    companyType?: string | null
    employeeCount?: EmployeeCountEnum | null
    companyName?: string | null
    createdAt?: any | null
    hearAboutUs?: string | null
    industry?: IndustryEnum | null
    hiringNeeds?: string | null
    id: string
    logo?: string | null
    phoneNumber?: string | null
    positionsToFill?: PositionsToFillEnum | null
    updatedAt?: any | null
    recruitingOrStaffing?: boolean | null
    subIndustry?: SubIndustryTypeEnum | null
    usingATS?: boolean | null
    website?: string | null
    yearsInOperation?: number | null
  } | null
  CompanyDepartment: Array<{
    __typename?: 'CompanyDepartment'
    companyId?: number | null
    createdAt?: any | null
    departmentName?: string | null
    externalId?: string | null
    id: string
    subordinateTo?: number | null
    updatedAt?: any | null
  }>
  CompanyOffice: Array<{
    __typename?: 'CompanyOffice'
    companyId?: number | null
    createdAt?: any | null
    externalId?: string | null
    id: string
    officeName?: string | null
    updatedAt?: any | null
  }>
}

export type CoreReportFragment = {
  __typename?: 'CoreReport'
  reportName: CoreReportNameEnum
  isFavorite: boolean
  updatedAt: any
  createdAt: any
  companyId: number
  id: number
}

export type HiringTeamMemberFragmentFragment = {
  __typename?: 'HiringTeamMember'
  id: string
  companyUserId?: number | null
  roleId?: number | null
  requisitionId?: number | null
  requisitionJobDescriptionId?: number | null
}

export type JobCampaignProductFragmentFragment = {
  __typename?: 'JobCampaignProduct'
  product_id: string
  audience_group: string
  title: string
  description: string
  homepage: string
  cross_postings: Array<string>
  duration: { __typename?: 'Duration'; period: number; range: string }
  industries: Array<{ __typename?: 'JobCampaignIndustry'; id: number; name: string }>
  job_functions: Array<{ __typename?: 'JobFunction'; id: string; name: string }>
  locations: Array<{ __typename?: 'JobCampaignLocation'; canonical_name: string; id: number }>
  logo_rectangle_url?: Array<{ __typename?: 'Logo'; url: string }> | null
  vonq_price: Array<{ __typename?: 'Price'; amount: number; currency: string }>
  time_to_process: { __typename?: 'ProcessTime'; period: number; range: string }
}

export type RequisitionJobDescriptionFragmentFragment = {
  __typename?: 'RequisitionJobDescription'
  id: string
  requisitionId?: number | null
  description?: string | null
}

export type JobListingJobPipelineFragmentFragment = {
  __typename?: 'JobListingJobPipeline'
  id: string
  jobListingId?: number | null
  jobPipelineId?: number | null
}

export type JobListingScreeningQuestionFragmentFragment = {
  __typename?: 'JobListingScreeningQuestion'
  id: string
  jobListingId?: number | null
  displaySequenceNumber?: number | null
  screeningQuestionId?: number | null
}

export type JobListingFragmentFragment = {
  __typename?: 'JobListing'
  id: number
  publicListingId?: string | null
  jobRequisitionId?: number | null
  listingTitle?: string | null
  externalTitle?: string | null
  workplaceType?: WorkplaceTypeEnum | null
  publicDescription?: string | null
  displayPayOnListing?: boolean | null
  industry?: IndustryEnum | null
  subIndustry?: SubIndustryTypeEnum | null
  companyDefault?: boolean | null
  stateAndOrCountry?: string | null
  internalMatchingDescription?: string | null
  searchPublicDescription?: string | null
  searchInternalMatchingDescription?: string | null
  postedAt?: any | null
  acceptingCandidates?: boolean | null
  stage?: StageEnum | null
}

export type JobOpeningFragmentFragment = {
  __typename?: 'JobOpening'
  id: string
  jobRequisitionId?: number | null
  openingStatus?: OpeningStatusEnum | null
  extOpeningId?: string | null
  companyId?: number | null
  targetHireDate?: any | null
  openingType?: OpeningTypeEnum | null
  filledDate?: any | null
  startDate?: any | null
  filledByApplication?: number | null
  filledByCandidate?: number | null
  closedReason?: ClosedReasonEnum | null
  closedDate?: any | null
}

export type JobPipelineFragmentFragment = {
  __typename?: 'JobPipeline'
  id: string
  title?: string | null
  jobRequisitionId?: number | null
  templatePipelineId?: number | null
}

export type RequisitionSkillsFragmentFragment = {
  __typename?: 'RequisitionSkill'
  id: string
  requisitionId?: number | null
  skillId?: number | null
  sequenceNumber?: number | null
}

export type RequisitionFragmentFragment = {
  __typename?: 'Requisition'
  id: string
  jobStatus?: JobStatusEnum | null
  openedAt?: any | null
  jobName?: string | null
  extRequisitionId?: string | null
  jobType?: JobTypeEnum | null
  jobLevel?: JobLevelEnum | null
  travelType?: TravelTypeEnum | null
  payType?: PayTypeEnum | null
  minPay?: number | null
  maxPay?: number | null
  additionalComp?: any | null
  benefits?: any | null
  locationDistancePref?: number | null
  educationLevelPref?: EducationLevelPrefEnum | null
  yearsOfExperiencePref?: YearsOfExperienceEnum | null
  nonTraditionalPref?: boolean | null
  veteranPref?: boolean | null
  diverseHirePref?: boolean | null
  collegeGradPref?: boolean | null
}

export type RoleFragmentFragment = { __typename?: 'Role'; id: string; name: string }

export type ScalisUserAboutFragmentFragment = {
  __typename?: 'ScalisUser'
  JobSeekerInfo?: {
    __typename?: 'JobSeekerInfo'
    id: number
    aboutMe?: string | null
    searchingJobs?: boolean | null
  } | null
}

export type ScalisUserAchievementsFragmentFragment = {
  __typename?: 'ScalisUser'
  Achievement?: Array<{
    __typename?: 'Achievement'
    id: number
    name?: string | null
    issuingAuthority?: string | null
    issueYear?: number | null
    description?: any | null
  }> | null
}

export type ScalisUserCertificatesOrLicensesFragmentFragment = {
  __typename?: 'ScalisUser'
  CertificateOrLicense?: Array<{
    __typename?: 'CertificateOrLicense'
    id: number
    name?: string | null
    issuingAuthority?: string | null
    issueYear?: number | null
    description?: any | null
  }> | null
}

export type ScalisUserDemographicInfoFragmentFragment = {
  __typename?: 'ScalisUser'
  DemographicInfo?: Array<{
    __typename?: 'DemographicInfo'
    gender?: GenderEnum | null
    raceEthnicity?: string | null
    veteranStatus?: VeteranStatusEnum | null
  }> | null
}

export type ScalisUserEducationFragmentFragment = {
  __typename?: 'ScalisUser'
  Education?: Array<{
    __typename?: 'Education'
    id: number
    institutionName?: string | null
    educationLevel?: EducationLevelEnum | null
    areasOfStudy?: any | null
    startDate?: any | null
    endDate?: any | null
    cumGPA?: number | null
    cumGPAScale?: number | null
    majors?: any | null
    minors?: any | null
    hideCumGPA?: boolean | null
    hideDepartmentGPA?: boolean | null
    departmentCumGPA?: number | null
    additionalInformation?: string | null
    currentlyStudying?: boolean | null
  }> | null
}

export type ScalisUserExperienceFragmentFragment = {
  __typename?: 'ScalisUser'
  Experience?: Array<{
    __typename?: 'Experience'
    id: number
    jobTitle?: string | null
    employerName?: string | null
    jobType?: JobTypeEnum | null
    locationType?: string | null
    location?: string | null
    startDate?: any | null
    endDate?: any | null
    isCurrentlyWorking?: boolean | null
    isCurrentJob?: boolean | null
    description?: string | null
    industry?: IndustryEnum | null
    subIndustry?: string | null
    managementPosition?: boolean | null
    teamSize?: TeamSizeEnum | null
    Skill?: Array<{ __typename?: 'Skill'; id: string; name?: string | null }> | null
  }> | null
}

export type ScalisUserSkillsFragmentFragment = {
  __typename?: 'ScalisUser'
  JobSeekerSkill?: Array<{
    __typename?: 'JobSeekerSkills'
    id: number
    sequenceNumber?: number | null
    level?: string | null
    name?: string | null
    yearsOfExp?: number | null
  }> | null
}

export type ScalisUserVolunteerWorkFragmentFragment = {
  __typename?: 'ScalisUser'
  VolunteerWork?: Array<{
    __typename?: 'VolunteerWork'
    id: number
    roleType?: RoleTypeEnum | null
    volunteerJobTitle?: string | null
    organizationName?: string | null
    location?: string | null
    startDate?: any | null
    endDate?: any | null
    isCurrentlyWorking?: boolean | null
    description?: any | null
    searchDescription?: string | null
  }> | null
}

export type ScalisUserWebsiteOrSocialFragmentFragment = {
  __typename?: 'ScalisUser'
  WebsiteOrSocialMedia?: Array<{
    __typename?: 'WebsiteOrSocialMedia'
    websiteURL: string
    type: WebsiteTypeEnum
  }> | null
}

export type ScalisUserFragment = {
  __typename?: 'ScalisUser'
  id: number
  firstName?: string | null
  lastName?: string | null
  profileImage?: string | null
}

export type ScheduledReportFragment = {
  __typename?: 'ScheduledReport'
  coreReportId: number
  customDaysOfWeek?: any | null
  customTime?: string | null
  deleted?: any | null
  endDate?: any | null
  id: number
  recipients: any
  repeat?: RepeatTypeEnum | null
  reportSchedulerType: ReportSchedulerTypeEnum
  startDate?: any | null
}

export type ScreenStageCollaboratorFragmentFragment = {
  __typename: 'StageCollaborator'
  id: string
  CompanyUser?: {
    __typename: 'CompanyUser'
    id: string
    profileImage?: string | null
    UserInvitation?: {
      __typename: 'UserInvitation'
      id: number
      email?: string | null
      ScalisUser?: { __typename: 'ScalisUser'; id: number; firstName?: string | null; lastName?: string | null } | null
    } | null
  } | null
}

export type ScreeningQuestionFragmentFragment = {
  __typename?: 'ScreeningQuestion'
  id: string
  isTemplateQuestion?: boolean | null
  questionTitle?: string | null
  questionDesc?: string | null
  questionType?: QuestionTypeEnum | null
  minVal?: number | null
  maxVal?: number | null
  valStatus?: string | null
  ansOptions?: string | null
  isRejectionQuestion?: boolean | null
  rejectMessage?: string | null
  templateMessageId?: number | null
  preferredAnswer?: string | null
  companyId?: number | null
  companyUserId?: number | null
}

export type SharedCoreReportFragment = {
  __typename?: 'SharedCoreReport'
  email: string
  id: string
  coreReportId: number
  companyUserId: number
}

export type SkillFragmentFragment = {
  __typename?: 'Skill'
  id: string
  experienceId?: number | null
  name?: string | null
  level?: string | null
  yearsOfExp?: number | null
}

export type StageCollaboratorFragmentFragment = {
  __typename?: 'StageCollaborator'
  id: string
  requisitionId?: number | null
  companyUserId?: number | null
  templateStageId?: number | null
  roleId?: number | null
  requisitionJobDescriptionId?: number | null
}

export type StageSettingsFragmentFragment = {
  __typename?: 'StageSettings'
  id: string
  jobPipelineId?: number | null
  templateStageId?: number | null
  scorecardTemplateId?: number | null
}

export type TemplateMilestoneKanbanFragment = {
  __typename: 'TemplateMilestone'
  id: string
  name?: NameEnum | null
  rankOrder?: number | null
  TemplateStage?: Array<{
    __typename: 'TemplateStage'
    id: string
    name?: string | null
    rankOrder?: number | null
    visibility?: TemplateStageVisibilityEnum | null
    CandidateApplicationByStage?: Array<{ __typename?: 'CandidateApplication'; id: string }> | null
  }> | null
}

export type TemplateMilestoneFragmentFragment = {
  __typename?: 'TemplateMilestone'
  id: string
  templatePipelineId?: number | null
  name?: NameEnum | null
  rankOrder?: number | null
  archived?: boolean | null
}

export type TemplatePipelineFragmentFragment = {
  __typename?: 'TemplatePipeline'
  id: string
  companyId?: number | null
  companyUserId?: number | null
  name?: string | null
  companyDepartmentId?: number | null
  archived?: boolean | null
}

export type TemplateStageKanbanFragment = {
  __typename?: 'TemplateStage'
  id: string
  name?: string | null
  rankOrder?: number | null
  visibility?: TemplateStageVisibilityEnum | null
  CandidateApplicationByStage?: Array<{ __typename?: 'CandidateApplication'; id: string }> | null
}

export type TemplateStageFragmentFragment = {
  __typename?: 'TemplateStage'
  id: string
  templatePipelineId?: number | null
  visibility?: TemplateStageVisibilityEnum | null
  name?: string | null
  templateMilestoneId?: number | null
  rankOrder?: number | null
  archived?: boolean | null
}

export type UserNoteFragmentFragment = { __typename?: 'UserNote'; id: string; noteText?: any | null }

export type UpsertAddressFormMutationVariables = Exact<{
  input: UpsertAddressInput
}>

export type UpsertAddressFormMutation = {
  __typename?: 'Mutation'
  UpsertAddress?: { __typename: 'Address'; id: number } | null
}

export type DeleteAchievementMutationVariables = Exact<{
  input: DeleteAchievementInput
}>

export type DeleteAchievementMutation = { __typename?: 'Mutation'; DeleteAchievement: boolean }

export type DeleteCertificateOrLicenseMutationVariables = Exact<{
  input: DeleteCertificateOrLicenseInput
}>

export type DeleteCertificateOrLicenseMutation = { __typename?: 'Mutation'; DeleteCertificateOrLicense: boolean }

export type DeleteEducationMutationVariables = Exact<{
  input: DeleteEducationInput
}>

export type DeleteEducationMutation = { __typename?: 'Mutation'; DeleteEducation: boolean }

export type DeleteExperienceMutationVariables = Exact<{
  input: DeleteExperienceInput
}>

export type DeleteExperienceMutation = { __typename?: 'Mutation'; DeleteExperience: boolean }

export type DeleteJobSeekerSkillMutationVariables = Exact<{
  input: DeleteJobSeekerSkillInput
}>

export type DeleteJobSeekerSkillMutation = { __typename?: 'Mutation'; DeleteJobSeekerSkill: boolean }

export type DeleteVolunteerWorkMutationVariables = Exact<{
  input: DeleteVolunteerWorkInput
}>

export type DeleteVolunteerWorkMutation = { __typename?: 'Mutation'; DeleteVolunteerWork: boolean }

export type CreateCandidateApplicationFromJobSeekerMutationVariables = Exact<{
  input: CreateCandidateApplicationFromJobSeekerInput
}>

export type CreateCandidateApplicationFromJobSeekerMutation = {
  __typename?: 'Mutation'
  CreateCandidateApplicationFromJobSeeker: { __typename?: 'CandidateApplication'; id: string }
}

export type UpdateScalisUserBasicInformationMutationVariables = Exact<{
  input: UpdateScalisUserDataInput
}>

export type UpdateScalisUserBasicInformationMutation = {
  __typename?: 'Mutation'
  UpdateScalisUserData: {
    __typename?: 'ScalisUser'
    firstName?: string | null
    lastName?: string | null
    ContactInfo?: Array<{
      __typename?: 'ContactInfo'
      id: number
      phoneNumber?: string | null
      scalisUserId?: number | null
    }> | null
    DemographicInfo?: Array<{
      __typename?: 'DemographicInfo'
      gender?: GenderEnum | null
      id: number
      raceEthnicity?: string | null
      veteranStatus?: VeteranStatusEnum | null
    }> | null
  }
}

export type UpsertExperienceSkillsMutationVariables = Exact<{
  upsertInput: Array<UpsertSkillInput> | UpsertSkillInput
  deleteInput: Array<DeleteSkillInput> | DeleteSkillInput
}>

export type UpsertExperienceSkillsMutation = {
  __typename?: 'Mutation'
  DeleteSkill: Array<boolean>
  UpsertSkill?: Array<{
    __typename?: 'Skill'
    experienceId?: number | null
    id: string
    level?: string | null
    name?: string | null
    yearsOfExp?: number | null
  }> | null
}

export type UpdateScalisUserAboutMeMutationVariables = Exact<{
  id: Scalars['Int']['input']
  aboutMe?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateScalisUserAboutMeMutation = {
  __typename?: 'Mutation'
  UpdateScalisUserData: {
    __typename?: 'ScalisUser'
    id: number
    JobSeekerInfo?: { __typename?: 'JobSeekerInfo'; id: number; aboutMe?: string | null } | null
  }
}

export type UpdateScalisUserDataMutationVariables = Exact<{
  input: UpdateScalisUserDataInput
}>

export type UpdateScalisUserDataMutation = {
  __typename?: 'Mutation'
  UpdateScalisUserData: {
    __typename?: 'ScalisUser'
    id: number
    firstName?: string | null
    lastName?: string | null
    profileImage?: string | null
    Address?: Array<{
      __typename?: 'Address'
      id: number
      addressLine1?: string | null
      addressLine2?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      postalCode?: string | null
      timeZone?: string | null
    }> | null
    JobSeekerInfo?: {
      __typename?: 'JobSeekerInfo'
      id: number
      aboutMe?: string | null
      searchingJobs?: boolean | null
    } | null
    JobSeekerSkill?: Array<{
      __typename?: 'JobSeekerSkills'
      id: number
      sequenceNumber?: number | null
      level?: string | null
      name?: string | null
      yearsOfExp?: number | null
    }> | null
    Experience?: Array<{
      __typename?: 'Experience'
      id: number
      jobTitle?: string | null
      employerName?: string | null
      jobType?: JobTypeEnum | null
      locationType?: string | null
      location?: string | null
      startDate?: any | null
      endDate?: any | null
      isCurrentlyWorking?: boolean | null
      isCurrentJob?: boolean | null
      description?: string | null
      industry?: IndustryEnum | null
      subIndustry?: string | null
      managementPosition?: boolean | null
      teamSize?: TeamSizeEnum | null
      Skill?: Array<{ __typename?: 'Skill'; id: string; name?: string | null }> | null
    }> | null
    VolunteerWork?: Array<{
      __typename?: 'VolunteerWork'
      id: number
      roleType?: RoleTypeEnum | null
      volunteerJobTitle?: string | null
      organizationName?: string | null
      location?: string | null
      startDate?: any | null
      endDate?: any | null
      isCurrentlyWorking?: boolean | null
      description?: any | null
      searchDescription?: string | null
    }> | null
    Education?: Array<{
      __typename?: 'Education'
      id: number
      institutionName?: string | null
      educationLevel?: EducationLevelEnum | null
      areasOfStudy?: any | null
      startDate?: any | null
      endDate?: any | null
      cumGPA?: number | null
      cumGPAScale?: number | null
      majors?: any | null
      minors?: any | null
      hideCumGPA?: boolean | null
      hideDepartmentGPA?: boolean | null
      departmentCumGPA?: number | null
      additionalInformation?: string | null
      currentlyStudying?: boolean | null
    }> | null
    CertificateOrLicense?: Array<{
      __typename?: 'CertificateOrLicense'
      id: number
      name?: string | null
      issuingAuthority?: string | null
      issueYear?: number | null
      description?: any | null
    }> | null
    Achievement?: Array<{
      __typename?: 'Achievement'
      id: number
      name?: string | null
      issuingAuthority?: string | null
      issueYear?: number | null
      description?: any | null
    }> | null
    DemographicInfo?: Array<{
      __typename?: 'DemographicInfo'
      gender?: GenderEnum | null
      raceEthnicity?: string | null
      veteranStatus?: VeteranStatusEnum | null
    }> | null
    WebsiteOrSocialMedia?: Array<{
      __typename?: 'WebsiteOrSocialMedia'
      websiteURL: string
      type: WebsiteTypeEnum
    }> | null
  }
}

export type UpdateScalisUserIsSearchingJobsMutationVariables = Exact<{
  id: Scalars['Int']['input']
  searchingJobs?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateScalisUserIsSearchingJobsMutation = {
  __typename?: 'Mutation'
  UpdateScalisUserData: {
    __typename?: 'ScalisUser'
    id: number
    JobSeekerInfo?: { __typename?: 'JobSeekerInfo'; id: number; searchingJobs?: boolean | null } | null
  }
}

export type UpdateScalisUserNameMutationVariables = Exact<{
  input: UpdateScalisUserDataInput
}>

export type UpdateScalisUserNameMutation = {
  __typename?: 'Mutation'
  UpdateScalisUserData: { __typename?: 'ScalisUser'; id: number; firstName?: string | null; lastName?: string | null }
}

export type UpdateScalisUserResumeMutationVariables = Exact<{
  id: Scalars['Int']['input']
  aboutMe?: InputMaybe<Scalars['String']['input']>
  jobSeekerSkills?: InputMaybe<Array<JobSeekerSkillInput> | JobSeekerSkillInput>
  experiences?: InputMaybe<Array<ExperienceInput> | ExperienceInput>
  volunteerWorks?: InputMaybe<Array<VolunteerWorkInput> | VolunteerWorkInput>
  educations?: InputMaybe<Array<EducationInput> | EducationInput>
  certificateOrLicenses?: InputMaybe<Array<CertificateOrLicenseInput> | CertificateOrLicenseInput>
  achievements?: InputMaybe<Array<AchievementInput> | AchievementInput>
}>

export type UpdateScalisUserResumeMutation = {
  __typename?: 'Mutation'
  UpdateScalisUserData: {
    __typename?: 'ScalisUser'
    id: number
    JobSeekerInfo?: { __typename?: 'JobSeekerInfo'; id: number; aboutMe?: string | null } | null
    JobSeekerSkill?: Array<{
      __typename?: 'JobSeekerSkills'
      id: number
      level?: string | null
      name?: string | null
      sequenceNumber?: number | null
      yearsOfExp?: number | null
    }> | null
    Experience?: Array<{ __typename?: 'Experience'; id: number; description?: string | null }> | null
    VolunteerWork?: Array<{ __typename?: 'VolunteerWork'; id: number; description?: any | null }> | null
    Education?: Array<{ __typename?: 'Education'; id: number; additionalInformation?: string | null }> | null
    CertificateOrLicense?: Array<{ __typename?: 'CertificateOrLicense'; id: number; description?: any | null }> | null
    Achievement?: Array<{ __typename?: 'Achievement'; id: number; description?: any | null }> | null
  }
}

export type SignUpMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type SignUpMutation = { __typename?: 'Mutation'; SignUp: { __typename?: 'ScalisUser'; id: number } }

export type DeleteBookmarkedJobMutationVariables = Exact<{
  input: DeleteBookmarkedJobInput
}>

export type DeleteBookmarkedJobMutation = {
  __typename?: 'Mutation'
  DeleteBookmarkedJob: { __typename?: 'BookmarkedJob'; publicListingId?: string | null }
}

export type ConnectCalendarMutationVariables = Exact<{
  input: ConnectCalendarInput
}>

export type ConnectCalendarMutation = {
  __typename?: 'Mutation'
  ConnectCalendar: { __typename?: 'ConnectCalendarOutput'; authUrl: string }
}

export type DisconnectCalendarMutationVariables = Exact<{
  input: DisconnectCalendarInput
}>

export type DisconnectCalendarMutation = {
  __typename?: 'Mutation'
  DisconnectCalendar: { __typename?: 'DisconnectCalendarOutput'; success: boolean }
}

export type EditCalendarSettingsMutationVariables = Exact<{
  input: EditCalendarSettingsInput
}>

export type EditCalendarSettingsMutation = {
  __typename?: 'Mutation'
  EditCalendarSettings: {
    __typename?: 'CalendarSettings'
    id: string
    companyUserId: number
    email: string
    provider: string
    nylasGrantId: string
    availability?: any | null
    timeZone?: string | null
  }
}

export type ExchangeNylasTokenMutationVariables = Exact<{
  input: ExchangeNylasTokenInput
}>

export type ExchangeNylasTokenMutation = {
  __typename?: 'Mutation'
  ExchangeNylasToken: {
    __typename?: 'CalendarSettings'
    id: string
    availability?: any | null
    provider: string
    timeZone?: string | null
    hourDisplay: number
    email: string
    Calendars?: Array<{
      __typename?: 'Calendar'
      id: string
      name: string
      isPrimary: boolean
      color?: string | null
      CalendarEvents?: Array<{
        __typename?: 'CalendarEvent'
        id: string
        nylasEventStatus?: NylasEventStatusesEnum | null
        title?: string | null
        eventType?: EventTypeEnum | null
        description?: string | null
        startTime?: any | null
        endTime?: any | null
        timeZone?: string | null
        location?: string | null
        repeats?: RepeatsEnum | null
        reminders?: any | null
        employers?: any | null
        conferencing?: any | null
        masterEventId?: string | null
        regardingCandidates?: any | null
        regardingJobs?: any | null
        internalGuests?: any | null
        candidates?: any | null
        isScalisEvent: boolean
      }> | null
    }> | null
  }
}

export type SyncCalendarMutationVariables = Exact<{ [key: string]: never }>

export type SyncCalendarMutation = {
  __typename?: 'Mutation'
  SyncCalendar: { __typename?: 'CalendarSettings'; id: string }
}

export type DeleteCandidateApplicationNoteMutationVariables = Exact<{
  input: DeleteCandidateApplicationNoteInput
}>

export type DeleteCandidateApplicationNoteMutation = {
  __typename?: 'Mutation'
  DeleteCandidateApplicationNote: { __typename?: 'CandidateApplicationNote'; id: number }
}

export type UpsertCandidateApplicationNoteMutationVariables = Exact<{
  input: UpsertCandidateApplicationNoteInput
}>

export type UpsertCandidateApplicationNoteMutation = {
  __typename?: 'Mutation'
  UpsertCandidateApplicationNote?: { __typename?: 'CandidateApplicationNote'; id: number } | null
}

export type UpsertCandidateApplicationRatingMutationVariables = Exact<{
  input: UpsertCandidateApplicationRatingInput
}>

export type UpsertCandidateApplicationRatingMutation = {
  __typename?: 'Mutation'
  UpsertCandidateApplicationRating?: {
    __typename?: 'CandidateApplicationRating'
    id: string
    candidateProfileId?: number | null
    candidateApplicationId?: number | null
    companyUserId?: number | null
    score?: number | null
    createdAt?: any | null
    updatedAt?: any | null
  } | null
}

export type AdvanceCandidateApplicationMutationVariables = Exact<{
  input: AdvanceCandidateApplicationInput
}>

export type AdvanceCandidateApplicationMutation = {
  __typename?: 'Mutation'
  AdvanceCandidateApplication: Array<{ __typename?: 'CandidateApplication'; id: string }>
}

export type CreateUploadedDocumentCandidateApplicationMutationVariables = Exact<{
  input: CreateUploadedDocumentCandidateApplicationInput
}>

export type CreateUploadedDocumentCandidateApplicationMutation = {
  __typename?: 'Mutation'
  CreateUploadedDocumentCandidateApplication?: {
    __typename?: 'UploadedDocumentCandidateApplication'
    id: string
  } | null
}

export type DeleteUploadedDocumentCandidateApplicationMutationVariables = Exact<{
  input: DeleteUploadedDocumentCandidateApplicationInput
}>

export type DeleteUploadedDocumentCandidateApplicationMutation = {
  __typename?: 'Mutation'
  DeleteUploadedDocumentCandidateApplication: { __typename?: 'UploadedDocumentCandidateApplication'; id: string }
}

export type InviteCandidatesToApplyMutationVariables = Exact<{
  input: InviteCandidatesToApplyInput
}>

export type InviteCandidatesToApplyMutation = {
  __typename?: 'Mutation'
  InviteCandidatesToApply?: {
    __typename?: 'InviteCandidatesToApplyOutput'
    uploadedCandidatesToReviewStage?: Array<{
      __typename?: 'jobInviteNotSentOutput'
      jobListingId: number
      candidateProfileId: number
    }> | null
    sent?: Array<{
      __typename?: 'JobInvite'
      token?: number | null
      sentAt?: any | null
      jobListingId?: number | null
      inviteStatus?: InviteStatusEnum | null
      id: string
      dismissed?: boolean | null
      candidateApplicationId?: number | null
    }> | null
    profileNotFound?: Array<{
      __typename?: 'jobInviteNotSentOutput'
      jobListingId: number
      candidateProfileId: number
    }> | null
    isPreviouslyInvited?: Array<{
      __typename?: 'jobInviteNotSentOutput'
      jobListingId: number
      candidateProfileId: number
    }> | null
    alreadyHasActiveApplication?: Array<{
      __typename?: 'jobInviteNotSentOutput'
      jobListingId: number
      candidateProfileId: number
    }> | null
  } | null
}

export type RejectCandidatesMutationVariables = Exact<{
  input: RejectCandidatesInput
  delaySeconds?: InputMaybe<Scalars['Int']['input']>
}>

export type RejectCandidatesMutation = {
  __typename?: 'Mutation'
  RejectCandidates: { __typename?: 'RejectCandidatesResponse'; jobId: string }
}

export type SendCandidatesToSourcingStageMutationVariables = Exact<{
  input: SendCandidatesToSourcingStageInput
}>

export type SendCandidatesToSourcingStageMutation = {
  __typename?: 'Mutation'
  SendCandidatesToSourcingStage?: {
    __typename?: 'SendCandidatesToSourcingStageOutput'
    profileNotFound?: Array<{ __typename?: 'CandidateApplicationNotCreatedOutput'; candidateProfileId: number }> | null
    candidateApplications?: Array<{ __typename?: 'CandidateApplication'; id: string }> | null
    alreadyHasActiveApplication?: Array<{
      __typename?: 'CandidateApplicationNotCreatedOutput'
      candidateProfileId: number
    }> | null
  } | null
}

export type StartCandidateApplicationMutationVariables = Exact<{
  input: StartCandidateApplicationInput
}>

export type StartCandidateApplicationMutation = {
  __typename?: 'Mutation'
  StartCandidateApplication: { __typename?: 'CandidateApplicationStatus'; id: string }
}

export type UndoRejectCandidatesMutationVariables = Exact<{
  input: UndoRejectCandidatesInput
}>

export type UndoRejectCandidatesMutation = { __typename?: 'Mutation'; UndoRejectCandidates: boolean }

export type DeleteCandidateCollaboratorMutationVariables = Exact<{
  input: DeleteCandidateCollaboratorInput
}>

export type DeleteCandidateCollaboratorMutation = {
  __typename?: 'Mutation'
  DeleteCandidateCollaborator: { __typename?: 'CandidateCollaborator'; id: string }
}

export type UpsertCandidateCollaboratorMutationVariables = Exact<{
  input: UpsertCandidateCollaboratorInput
}>

export type UpsertCandidateCollaboratorMutation = {
  __typename?: 'Mutation'
  UpsertCandidateCollaborator?: {
    __typename?: 'CandidateCollaborator'
    id: string
    requisitionId?: number | null
    companyUserId?: number | null
    candidateProfileId?: number | null
    roleId?: number | null
    requisitionJobDescriptionId?: number | null
  } | null
}

export type DeleteCandidateMedalistDesignationMutationVariables = Exact<{
  input: DeleteCandidateMedalistDesignationInput
}>

export type DeleteCandidateMedalistDesignationMutation = {
  __typename?: 'Mutation'
  DeleteCandidateMedalistDesignation: { __typename?: 'CandidateMedalistDesignation'; id: number }
}

export type DeleteCandidateProfileLocationMutationVariables = Exact<{
  input: DeleteCandidateProfileLocationInput
}>

export type DeleteCandidateProfileLocationMutation = {
  __typename?: 'Mutation'
  DeleteCandidateProfileLocation: { __typename?: 'CandidateProfileLocation'; id: number }
}

export type DeleteCandidateProfileNoteMutationVariables = Exact<{
  input: DeleteCandidateProfileNoteInput
}>

export type DeleteCandidateProfileNoteMutation = {
  __typename?: 'Mutation'
  DeleteCandidateProfileNote: { __typename?: 'CandidateProfileNote'; id: number }
}

export type UpsertCandidateProfileNoteMutationVariables = Exact<{
  input: UpsertCandidateProfileNoteInput
}>

export type UpsertCandidateProfileNoteMutation = {
  __typename?: 'Mutation'
  UpsertCandidateProfileNote: { __typename?: 'CandidateProfileNote'; id: number }
}

export type CreateCandidateSourceMutationVariables = Exact<{
  input: CandidateSourceInput
}>

export type CreateCandidateSourceMutation = {
  __typename?: 'Mutation'
  CreateCandidateSource?: { __typename?: 'CandidateSource'; id: number } | null
}

export type CreateUploadedDocumentCandidateProfileMutationVariables = Exact<{
  input: CreateUploadedDocumentCandidateProfileInput
}>

export type CreateUploadedDocumentCandidateProfileMutation = {
  __typename?: 'Mutation'
  CreateUploadedDocumentCandidateProfile?: { __typename?: 'UploadedDocumentCandidateProfile'; id: string } | null
}

export type DeleteCandidateSourceMutationVariables = Exact<{
  input: DeleteCandidateSourceInput
}>

export type DeleteCandidateSourceMutation = {
  __typename?: 'Mutation'
  DeleteCandidateSource: { __typename?: 'CandidateSource'; id: number }
}

export type DeleteUploadedDocumentCandidateProfileMutationVariables = Exact<{
  input: DeleteUploadedDocumentCandidateProfileInput
}>

export type DeleteUploadedDocumentCandidateProfileMutation = {
  __typename?: 'Mutation'
  DeleteUploadedDocumentCandidateProfile: { __typename?: 'UploadedDocumentCandidateProfile'; id: string }
}

export type UpsertCandidateProfileMutationVariables = Exact<{
  input: UpsertCandidateProfileInput
}>

export type UpsertCandidateProfileMutation = {
  __typename?: 'Mutation'
  UpsertCandidateProfile?: { __typename?: 'CandidateProfile'; id: string } | null
}

export type DeleteCandidateScorecardMutationVariables = Exact<{
  input: DeleteCandidateScorecardInput
}>

export type DeleteCandidateScorecardMutation = {
  __typename?: 'Mutation'
  DeleteCandidateScorecard?: { __typename?: 'CandidateScorecard'; id: string } | null
}

export type GetCandidateScorecardQueryVariables = Exact<{
  input: GetCandidateScorecardInput
}>

export type GetCandidateScorecardQuery = {
  __typename?: 'Query'
  GetCandidateScorecard?: Array<{
    __typename?: 'CandidateScorecard'
    id: string
    additionalInformation?: string | null
    candidateApplicationId?: number | null
    companyUserId?: number | null
    scorecardTemplateId?: number | null
    status?: ScorecardStatusTypeEnum | null
    templateStageId?: number | null
    updatedAt?: any | null
    createdAt?: any | null
    ScorecardTemplate?: {
      __typename?: 'ScorecardTemplate'
      id: string
      scorecardName?: string | null
      department?: string | null
      createdAt?: any | null
      companyTemplate?: boolean | null
      archived?: boolean | null
      companyId?: number | null
      timestampAt?: any | null
    } | null
    CandidateApplication?: {
      __typename?: 'CandidateApplication'
      id: string
      candidateProfileId?: number | null
      applicationStatus?: ApplicationStatusEnum | null
      JobListing?: {
        __typename?: 'JobListing'
        id: number
        externalTitle?: string | null
        Requisition?: {
          __typename?: 'Requisition'
          id: string
          extRequisitionId?: string | null
          jobName?: string | null
        } | null
      } | null
    } | null
    CandidateScorecardAnswers: Array<{
      __typename?: 'CandidateScorecardAnswers'
      answer?: any | null
      answerPreferredStatus?: boolean | null
      candidateScorecardId?: number | null
      dateAnswer?: any | null
      id: string
      scoreAnswer?: number | null
      scorecardTemplateQuestionId?: number | null
      overallRecommendation?: OverallRecommendationEnum | null
    }>
    TemplateStage?: {
      __typename?: 'TemplateStage'
      name?: string | null
      id: string
      createdAt?: any | null
      archived?: boolean | null
      rankOrder?: number | null
      templateMilestoneId?: number | null
      templatePipelineId?: number | null
      updatedAt?: any | null
      visibility?: TemplateStageVisibilityEnum | null
      TemplateMilestone?: { __typename?: 'TemplateMilestone'; id: string; name?: NameEnum | null } | null
    } | null
    CompanyUser?: {
      __typename?: 'CompanyUser'
      UserInvitation?: {
        __typename?: 'UserInvitation'
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
        } | null
      } | null
    } | null
  }> | null
}

export type SendReminderCandidateScorecardMutationVariables = Exact<{
  input: SendReminderCandidateScorecardInput
}>

export type SendReminderCandidateScorecardMutation = {
  __typename?: 'Mutation'
  SendReminderCandidateScorecard: { __typename?: 'CandidateScorecard'; id: string }
}

export type UpsertCandidateScorecardAnswersMutationVariables = Exact<{
  input: UpsertCandidateScorecardAnswersInput
}>

export type UpsertCandidateScorecardAnswersMutation = {
  __typename?: 'Mutation'
  UpsertCandidateScorecardAnswers?: {
    __typename?: 'CandidateScorecardAnswers'
    id: string
    answer?: any | null
    answerPreferredStatus?: boolean | null
    candidateScorecardId?: number | null
    dateAnswer?: any | null
    scoreAnswer?: number | null
    scorecardTemplateQuestionId?: number | null
  } | null
}

export type UpsertCandidateScorecardMutationVariables = Exact<{
  input: UpsertCandidateScorecardInput
}>

export type UpsertCandidateScorecardMutation = {
  __typename?: 'Mutation'
  UpsertCandidateScorecard?: {
    __typename?: 'CandidateScorecard'
    id: string
    scorecardTemplateId?: number | null
    status?: ScorecardStatusTypeEnum | null
    templateStageId?: number | null
    companyUserId?: number | null
    candidateApplicationId?: number | null
    additionalInformation?: string | null
  } | null
}

export type CreateCandidateSourceNameMutationVariables = Exact<{
  input: CandidateSourceNameCreateInput
}>

export type CreateCandidateSourceNameMutation = {
  __typename?: 'Mutation'
  CreateCandidateSourceName: {
    __typename?: 'CandidateSourceName'
    id: number
    name: string
    sourceCategory?: SourceCategoryEnum | null
  }
}

export type CreateCompanyUserGroupMutationVariables = Exact<{
  input: CreateCompanyUserGroupInput
}>

export type CreateCompanyUserGroupMutation = {
  __typename?: 'Mutation'
  CreateCompanyUserGroup: {
    __typename?: 'CompanyUserGroup'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    deleted: boolean
    createdBy: {
      __typename?: 'CompanyUser'
      id: string
      profileImage?: string | null
      UserInvitation?: {
        __typename?: 'UserInvitation'
        ScalisUser?: {
          __typename?: 'ScalisUser'
          firstName?: string | null
          lastName?: string | null
          EmailVerification?: Array<{ __typename?: 'EmailVerification'; emailAddress?: string | null }> | null
        } | null
      } | null
    }
    Offices: Array<{
      __typename?: 'CompanyUserGroupCompanyOffice'
      CompanyOffice: {
        __typename?: 'CompanyOffice'
        id: string
        officeName?: string | null
        externalId?: string | null
      }
    }>
    Departments: Array<{
      __typename?: 'CompanyUserGroupCompanyDepartment'
      CompanyDepartment: {
        __typename?: 'CompanyDepartment'
        id: string
        departmentName?: string | null
        externalId?: string | null
      }
    }>
    Members: Array<{
      __typename?: 'CompanyUserGroupCompanyUser'
      CompanyUser: {
        __typename?: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          ScalisUser?: {
            __typename?: 'ScalisUser'
            firstName?: string | null
            lastName?: string | null
            EmailVerification?: Array<{ __typename?: 'EmailVerification'; emailAddress?: string | null }> | null
          } | null
        } | null
      }
    }>
  }
}

export type CreateUsersInCompanyUserGroupMutationVariables = Exact<{
  input: CreateUsersInCompanyUserGroupInput
}>

export type CreateUsersInCompanyUserGroupMutation = {
  __typename?: 'Mutation'
  CreateUsersInCompanyUserGroup: {
    __typename?: 'CompanyUserGroup'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    deleted: boolean
    Members: Array<{
      __typename?: 'CompanyUserGroupCompanyUser'
      CompanyUser: {
        __typename?: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          ScalisUser?: {
            __typename?: 'ScalisUser'
            firstName?: string | null
            lastName?: string | null
            EmailVerification?: Array<{ __typename?: 'EmailVerification'; emailAddress?: string | null }> | null
          } | null
        } | null
      }
    }>
  }
}

export type DeleteCompanyUserGroupMutationVariables = Exact<{
  input: DeleteCompanyUserGroupInput
}>

export type DeleteCompanyUserGroupMutation = {
  __typename?: 'Mutation'
  DeleteCompanyUserGroup: {
    __typename?: 'CompanyUserGroup'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    deleted: boolean
  }
}

export type DeleteUsersFromCompanyUserGroupMutationVariables = Exact<{
  input: DeleteUsersFromCompanyUserGroupInput
}>

export type DeleteUsersFromCompanyUserGroupMutation = {
  __typename?: 'Mutation'
  DeleteUsersFromCompanyUserGroup: {
    __typename?: 'CompanyUserGroup'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    deleted: boolean
    Members: Array<{
      __typename?: 'CompanyUserGroupCompanyUser'
      CompanyUser: {
        __typename?: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          ScalisUser?: {
            __typename?: 'ScalisUser'
            firstName?: string | null
            lastName?: string | null
            EmailVerification?: Array<{ __typename?: 'EmailVerification'; emailAddress?: string | null }> | null
          } | null
        } | null
      }
    }>
  }
}

export type UpdateCompanyUserGroupMutationVariables = Exact<{
  input: UpdateCompanyUserGroupInput
}>

export type UpdateCompanyUserGroupMutation = {
  __typename?: 'Mutation'
  UpdateCompanyUserGroup: {
    __typename?: 'CompanyUserGroup'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    deleted: boolean
    Members: Array<{
      __typename?: 'CompanyUserGroupCompanyUser'
      CompanyUser: {
        __typename?: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          ScalisUser?: {
            __typename?: 'ScalisUser'
            firstName?: string | null
            lastName?: string | null
            EmailVerification?: Array<{ __typename?: 'EmailVerification'; emailAddress?: string | null }> | null
          } | null
        } | null
      }
    }>
  }
}

export type UpdateUsersInCompanyUserGroupMutationVariables = Exact<{
  input: UpdateUsersInCompanyUserGroupInput
}>

export type UpdateUsersInCompanyUserGroupMutation = {
  __typename?: 'Mutation'
  UpdateUsersInCompanyUserGroup: {
    __typename?: 'CompanyUserGroup'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    deleted: boolean
    Members: Array<{
      __typename?: 'CompanyUserGroupCompanyUser'
      CompanyUser: {
        __typename?: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          ScalisUser?: {
            __typename?: 'ScalisUser'
            firstName?: string | null
            lastName?: string | null
            EmailVerification?: Array<{ __typename?: 'EmailVerification'; emailAddress?: string | null }> | null
          } | null
        } | null
      }
    }>
  }
}

export type ActivateCompanyUserMutationVariables = Exact<{
  input: ActivateCompanyUserInput
}>

export type ActivateCompanyUserMutation = {
  __typename?: 'Mutation'
  ActivateCompanyUser: { __typename?: 'CompanyUser'; id: string }
}

export type CreateCompanyUserFromInvitationMutationVariables = Exact<{
  input: CreateCompanyUserFromInvitationInput
}>

export type CreateCompanyUserFromInvitationMutation = {
  __typename?: 'Mutation'
  CreateCompanyUserFromInvitation: { __typename?: 'CompanyUser'; id: string }
}

export type DeleteCompanyUserFromWorkspaceMutationVariables = Exact<{
  input: DeleteCompanyUserFromWorkspaceInput
}>

export type DeleteCompanyUserFromWorkspaceMutation = {
  __typename?: 'Mutation'
  DeleteCompanyUserFromWorkspace: { __typename?: 'CompanyUser'; id: string }
}

export type DeleteInvitationFromWorkspaceMutationVariables = Exact<{
  input: DeleteInvitationFromWorkspaceInput
}>

export type DeleteInvitationFromWorkspaceMutation = {
  __typename?: 'Mutation'
  DeleteInvitationFromWorkspace: { __typename?: 'UserInvitation'; id: number }
}

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput
}>

export type InviteUserMutation = {
  __typename?: 'Mutation'
  InviteUser: Array<{ __typename?: 'UserInvitation'; id: number }>
}

export type UpdateCompanyUserRoleMutationVariables = Exact<{
  input: UpdateCompanyUserRoleInput
}>

export type UpdateCompanyUserRoleMutation = {
  __typename?: 'Mutation'
  UpdateCompanyUserRole: { __typename?: 'CompanyUser'; id: string }
}

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput
}>

export type CreateCompanyMutation = { __typename?: 'Mutation'; CreateCompany: { __typename: 'Company'; id: string } }

export type DeleteCompanyDepartmentMutationVariables = Exact<{
  input: DeleteCompanyDepartmentInput
}>

export type DeleteCompanyDepartmentMutation = {
  __typename?: 'Mutation'
  DeleteCompanyDepartment: { __typename: 'CompanyDepartment'; id: string }
}

export type DeleteCompanyOfficeMutationVariables = Exact<{
  input: DeleteCompanyOfficeInput
}>

export type DeleteCompanyOfficeMutation = {
  __typename?: 'Mutation'
  DeleteCompanyOffice: { __typename: 'CompanyOffice'; id: string }
}

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyDataInput
}>

export type UpdateCompanyMutation = {
  __typename?: 'Mutation'
  UpdateCompanyData: { __typename: 'Company'; id: string }
}

export type UpsertGlobalListingSetupMutationVariables = Exact<{
  input: GlobalListingSetupInput
}>

export type UpsertGlobalListingSetupMutation = {
  __typename?: 'Mutation'
  upsertGlobalListingSetup?: {
    __typename?: 'GlobalListingSetup'
    id: number
    aboutCompanyStatement?: any | null
    footerStatement?: any | null
    companyId: number
    isAboutCompanyActive: boolean
    isFooterStatementActive: boolean
    updatedAt: any
  } | null
}

export type DeleteGlobalScreeningQuestionMutationVariables = Exact<{
  input: DeleteGlobalScreeningQuestionInput
}>

export type DeleteGlobalScreeningQuestionMutation = {
  __typename?: 'Mutation'
  DeleteGlobalScreeningQuestion: {
    __typename?: 'ScreeningQuestion'
    id: string
    questionTitle?: string | null
    questionDesc?: string | null
  }
}

export type EditGlobalScreeningQuestionMutationVariables = Exact<{
  input: EditGlobalScreeningQuestionInput
}>

export type EditGlobalScreeningQuestionMutation = {
  __typename?: 'Mutation'
  EditGlobalScreeningQuestion?: {
    __typename?: 'ScreeningQuestion'
    id: string
    questionTitle?: string | null
    questionDesc?: string | null
    questionType?: QuestionTypeEnum | null
    minVal?: number | null
    maxVal?: number | null
    valStatus?: string | null
    ansOptions?: string | null
    isRejectionQuestion?: boolean | null
    rejectMessage?: string | null
    preferredAnswer?: string | null
    isGlobalQuestion?: boolean | null
    isActiveGlobally?: boolean | null
    globalQuestionOrder?: number | null
    isEdited?: boolean | null
    isReplacedBy?: number | null
    isDeleted?: boolean | null
    companyId?: number | null
    companyUserId?: number | null
    createdAt?: any | null
    updatedAt?: any | null
  } | null
}

export type InsertGlobalScreeningQuestionMutationVariables = Exact<{
  input: Array<InsertGlobalScreeningQuestionInput> | InsertGlobalScreeningQuestionInput
}>

export type InsertGlobalScreeningQuestionMutation = {
  __typename?: 'Mutation'
  InsertGlobalScreeningQuestion?: Array<{
    __typename?: 'ScreeningQuestion'
    id: string
    questionTitle?: string | null
    questionDesc?: string | null
    questionType?: QuestionTypeEnum | null
    ansOptions?: string | null
    isRejectionQuestion?: boolean | null
    rejectMessage?: string | null
    preferredAnswer?: string | null
    isActiveGlobally?: boolean | null
    globalQuestionOrder?: number | null
    createdAt?: any | null
    updatedAt?: any | null
  } | null> | null
}

export type DeleteHiringTeamMemberMutationVariables = Exact<{
  input: DeleteHiringTeamMemberInput
}>

export type DeleteHiringTeamMemberMutation = {
  __typename?: 'Mutation'
  DeleteHiringTeamMember: { __typename?: 'HiringTeamMember'; id: string }
}

export type UpsertHiringTeamMemberMutationVariables = Exact<{
  input: UpsertHiringTeamMemberInput
}>

export type UpsertHiringTeamMemberMutation = {
  __typename?: 'Mutation'
  UpsertHiringTeamMember?: {
    __typename?: 'HiringTeamMember'
    id: string
    companyUserId?: number | null
    roleId?: number | null
    requisitionId?: number | null
    requisitionJobDescriptionId?: number | null
  } | null
}

export type CreateInterviewMutationVariables = Exact<{
  input: CreateInterviewInput
}>

export type CreateInterviewMutation = {
  __typename?: 'Mutation'
  CreateInterview: {
    __typename?: 'CandidateInterview'
    status?: CandidateInterviewStatusEnum | null
    stageId?: number | null
    interviewData?: any | null
    id: string
    companyUserId?: number | null
    calendarEventId?: number | null
    applicationId?: number | null
  }
}

export type CreateSchedulerLinkMutationVariables = Exact<{
  input: CreateSchedulerLinkInviteInput
}>

export type CreateSchedulerLinkMutation = { __typename?: 'Mutation'; CreateSchedulerLinkInvite: boolean }

export type DeleteInterviewMutationVariables = Exact<{
  input: DeleteInterviewInput
}>

export type DeleteInterviewMutation = { __typename?: 'Mutation'; DeleteInterview: boolean }

export type UpdateInterviewMutationVariables = Exact<{
  input: UpdateInterviewInput
}>

export type UpdateInterviewMutation = {
  __typename?: 'Mutation'
  UpdateInterview: {
    __typename?: 'CandidateInterview'
    status?: CandidateInterviewStatusEnum | null
    stageId?: number | null
    interviewData?: any | null
    id: string
    companyUserId?: number | null
    calendarEventId?: number | null
    applicationId?: number | null
  }
}

export type CountCampaignViewMutationVariables = Exact<{
  input: CountCampaignViewInput
}>

export type CountCampaignViewMutation = {
  __typename?: 'Mutation'
  CountCampaignView?: { __typename?: 'CountCampaignView'; message: string; viewCount: number } | null
}

export type OrderJobCampaignMutationVariables = Exact<{
  input: OrderCampaignInput
}>

export type OrderJobCampaignMutation = {
  __typename?: 'Mutation'
  OrderJobCampaign?:
    | {
        __typename?: 'CampaignValidationErrors'
        errors: Array<{
          __typename?: 'CampaignErrors'
          company?: string | null
          orderedProducts?: Array<{ __typename?: 'ValidationError'; message: Array<string> }> | null
          orderedProductsSpecs?: Array<{ __typename?: 'ValidationError'; message: Array<string> }> | null
          postingDetails?: { __typename?: 'NestedValidationErrors'; fields: any } | null
          targetGroup?: { __typename?: 'NestedValidationErrors'; fields: any } | null
          recruiterInfo?: { __typename?: 'NestedValidationErrors'; fields: any } | null
        }>
      }
    | {
        __typename?: 'JobCampaignCreation'
        orderReference: string
        clientSecret: string
        deliveryTime: {
          __typename?: 'JobCampaignDeliveryTime'
          totalDays: number
          daysToSetup: number
          daysToProcess: number
        }
        price: { __typename?: 'OrderCampaignPrice'; subTotal: number; total: number; vat: number }
      }
    | null
}

export type UpdateJobInviteMutationVariables = Exact<{
  input: UpdateJobInviteInput
}>

export type UpdateJobInviteMutation = {
  __typename?: 'Mutation'
  UpdateJobInvite: { __typename?: 'JobInvite'; id: string }
}

export type DeleteJobListingMutationVariables = Exact<{
  input: DeleteJobListingInput
}>

export type DeleteJobListingMutation = {
  __typename?: 'Mutation'
  DeleteJobListing: { __typename?: 'JobListing'; id: number }
}

export type UpsertJobListingJobPipelineMutationVariables = Exact<{
  input: Array<UpsertJobListingJobPipelineInput> | UpsertJobListingJobPipelineInput
}>

export type UpsertJobListingJobPipelineMutation = {
  __typename?: 'Mutation'
  UpsertJobListingJobPipeline?: Array<{
    __typename?: 'JobListingJobPipeline'
    id: string
    jobListingId?: number | null
    jobPipelineId?: number | null
  }> | null
}

export type UpsertJobListingMutationVariables = Exact<{
  input: UpsertJobListingInput
}>

export type UpsertJobListingMutation = {
  __typename?: 'Mutation'
  UpsertJobListing?: {
    __typename?: 'JobListing'
    id: number
    publicListingId?: string | null
    jobRequisitionId?: number | null
    listingTitle?: string | null
    externalTitle?: string | null
    workplaceType?: WorkplaceTypeEnum | null
    publicDescription?: string | null
    displayPayOnListing?: boolean | null
    industry?: IndustryEnum | null
    subIndustry?: SubIndustryTypeEnum | null
    companyDefault?: boolean | null
    stateAndOrCountry?: string | null
    internalMatchingDescription?: string | null
    searchPublicDescription?: string | null
    searchInternalMatchingDescription?: string | null
    postedAt?: any | null
    acceptingCandidates?: boolean | null
    stage?: StageEnum | null
    Address?: Array<{
      __typename?: 'Address'
      id: number
      addressLine1?: string | null
      addressLine2?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      postalCode?: string | null
      timeZone?: string | null
    }> | null
    JobListingScreeningQuestion?: Array<{
      __typename?: 'JobListingScreeningQuestion'
      id: string
      jobListingId?: number | null
      displaySequenceNumber?: number | null
      screeningQuestionId?: number | null
      ScreeningQuestion?: {
        __typename?: 'ScreeningQuestion'
        id: string
        isTemplateQuestion?: boolean | null
        questionTitle?: string | null
        questionDesc?: string | null
        questionType?: QuestionTypeEnum | null
        minVal?: number | null
        maxVal?: number | null
        valStatus?: string | null
        ansOptions?: string | null
        isRejectionQuestion?: boolean | null
        rejectMessage?: string | null
        templateMessageId?: number | null
        preferredAnswer?: string | null
        companyId?: number | null
        companyUserId?: number | null
      } | null
    }> | null
  } | null
}

export type DeleteJobPipelineMutationVariables = Exact<{
  input: DeleteJobPipelineInput
}>

export type DeleteJobPipelineMutation = {
  __typename?: 'Mutation'
  DeleteJobPipeline: { __typename?: 'JobPipeline'; id: string }
}

export type UpsertJobPipelineMutationVariables = Exact<{
  input: UpsertJobPipelineInput
}>

export type UpsertJobPipelineMutation = {
  __typename?: 'Mutation'
  UpsertJobPipeline?: {
    __typename?: 'JobPipeline'
    id: string
    title?: string | null
    jobRequisitionId?: number | null
    templatePipelineId?: number | null
    TemplatePipeline?: {
      __typename?: 'TemplatePipeline'
      id: string
      companyId?: number | null
      companyUserId?: number | null
      name?: string | null
      companyDepartmentId?: number | null
      archived?: boolean | null
      TemplateMilestones?: Array<{
        __typename?: 'TemplateMilestone'
        id: string
        templatePipelineId?: number | null
        name?: NameEnum | null
        rankOrder?: number | null
        archived?: boolean | null
        TemplateStage?: Array<{
          __typename?: 'TemplateStage'
          id: string
          templatePipelineId?: number | null
          visibility?: TemplateStageVisibilityEnum | null
          name?: string | null
          templateMilestoneId?: number | null
          rankOrder?: number | null
          archived?: boolean | null
        }> | null
      }> | null
    } | null
  } | null
}

export type CreateApplicantNotesMutationVariables = Exact<{
  input: CreateApplicantNotesInput
}>

export type CreateApplicantNotesMutation = {
  __typename?: 'Mutation'
  CreateApplicantNotes: { __typename?: 'ApplicantNotes'; id: string }
}

export type CreateBookmarkedJobMutationVariables = Exact<{
  input: CreateBookmarkedJobInput
}>

export type CreateBookmarkedJobMutation = {
  __typename?: 'Mutation'
  CreateBookmarkedJob: { __typename?: 'BookmarkedJob'; id: string; jobListingId?: number | null }
}

export type CreateJobSeekerAccountMutationVariables = Exact<{
  input: CreateJobSeekerAccountInput
}>

export type CreateJobSeekerAccountMutation = {
  __typename?: 'Mutation'
  CreateJobSeekerAccount: { __typename?: 'ScalisUser'; id: number }
}

export type DeleteApplicantNotesMutationVariables = Exact<{
  input: DeleteApplicantNotesInput
}>

export type DeleteApplicantNotesMutation = {
  __typename?: 'Mutation'
  DeleteApplicantNotes: { __typename?: 'ApplicantNotes'; id: string }
}

export type DeleteExternalJobApplicationMutationVariables = Exact<{
  input: DeleteExternalApplicationInput
}>

export type DeleteExternalJobApplicationMutation = {
  __typename?: 'Mutation'
  DeleteExternalJobApplication: { __typename?: 'DeleteExternalJobApplicationResponse'; message: string }
}

export type ResendEmailVerificationMutationVariables = Exact<{
  input: ResendEmailVerificationInput
}>

export type ResendEmailVerificationMutation = {
  __typename?: 'Mutation'
  ResendEmailVerification: {
    __typename?: 'ScalisUser'
    EmailVerification?: Array<{
      __typename?: 'EmailVerification'
      activeStatus?: boolean | null
      createdAt?: any | null
      emailAddress?: string | null
      emailVerified?: boolean | null
      id: number
      isPrimary?: boolean | null
      scalisUserId?: number | null
      updatedAt?: any | null
    }> | null
  }
}

export type SendJobListingMutationVariables = Exact<{
  input: SendJobListingInput
}>

export type SendJobListingMutation = {
  __typename?: 'Mutation'
  SendJobListing: { __typename?: 'SendJobListingResult'; publicListingId: string }
}

export type ShareJobListingMutationVariables = Exact<{
  input: SendJobListingInput
}>

export type ShareJobListingMutation = {
  __typename?: 'Mutation'
  SendJobListing: {
    __typename?: 'SendJobListingResult'
    email: string
    recipients: Array<string>
    publicListingId: string
    message: string
  }
}

export type UpsertExternalJobApplicationMutationVariables = Exact<{
  input: ExternalApplicationInput
}>

export type UpsertExternalJobApplicationMutation = {
  __typename?: 'Mutation'
  UpsertExternalJobApplication: { __typename?: 'ExternalJobApplication'; id: string }
}

export type VerifyEmailCodeMutationVariables = Exact<{
  input: VerifyEmailCodeInput
}>

export type VerifyEmailCodeMutation = {
  __typename?: 'Mutation'
  VerifyEmailCode: {
    __typename?: 'ScalisUser'
    EmailVerification?: Array<{
      __typename?: 'EmailVerification'
      activeStatus?: boolean | null
      createdAt?: any | null
      emailVerified?: boolean | null
      emailAddress?: string | null
      id: number
      isPrimary?: boolean | null
      scalisUserId?: number | null
      updatedAt?: any | null
    }> | null
  }
}

export type WithdrawApplicationMutationVariables = Exact<{
  input: DeleteCandidateApplicationInput
}>

export type WithdrawApplicationMutation = {
  __typename?: 'Mutation'
  DeleteCandidateApplication: { __typename?: 'CandidateApplication'; id: string }
}

export type CreateChatV2MutationVariables = Exact<{
  input: CreateChatV2Input
}>

export type CreateChatV2Mutation = {
  __typename?: 'Mutation'
  CreateChatV2: { __typename?: 'ChatC2J'; coUserId?: number | null; scUserId?: number | null; chatId?: string | null }
}

export type CancelScheduleReportMutationVariables = Exact<{
  input: CancelScheduledReportInput
}>

export type CancelScheduleReportMutation = {
  __typename?: 'Mutation'
  CancelScheduledReport: { __typename?: 'ScheduledReport'; id: number }
}

export type CreateFavoriteReportMutationVariables = Exact<{
  input: FavoriteReportInput
}>

export type CreateFavoriteReportMutation = {
  __typename?: 'Mutation'
  CreateFavoriteReport: {
    __typename?: 'FavoriteCoreReport'
    id: number
    coreReportId: number
    CoreReport: {
      __typename?: 'CoreReport'
      reportName: CoreReportNameEnum
      isFavorite: boolean
      updatedAt: any
      createdAt: any
      companyId: number
      id: number
    }
  }
}

export type CreateScheduleReportMutationVariables = Exact<{
  input: CreateScheduledReportInput
}>

export type CreateScheduleReportMutation = {
  __typename?: 'Mutation'
  ScheduleReport: {
    __typename?: 'ScheduledReport'
    CoreReport: {
      __typename?: 'CoreReport'
      reportName: CoreReportNameEnum
      isFavorite: boolean
      updatedAt: any
      createdAt: any
      companyId: number
      id: number
      ScheduledReport: Array<{
        __typename?: 'ScheduledReport'
        coreReportId: number
        customDaysOfWeek?: any | null
        customTime?: string | null
        deleted?: any | null
        endDate?: any | null
        id: number
        recipients: any
        repeat?: RepeatTypeEnum | null
        reportSchedulerType: ReportSchedulerTypeEnum
        startDate?: any | null
      }>
    }
  }
}

export type CreateSharedReportMutationVariables = Exact<{
  input: SharedReportInput
}>

export type CreateSharedReportMutation = {
  __typename?: 'Mutation'
  CreateSharedReport: {
    __typename?: 'SharedCoreReport'
    createdAt: any
    email: string
    id: string
    coreReportId: number
    companyUserId: number
  }
}

export type DeleteFavoriteReportMutationVariables = Exact<{
  input: FavoriteReportInput
}>

export type DeleteFavoriteReportMutation = {
  __typename?: 'Mutation'
  DeleteFavoriteReport: {
    __typename?: 'FavoriteCoreReport'
    id: number
    coreReportId: number
    CoreReport: {
      __typename?: 'CoreReport'
      reportName: CoreReportNameEnum
      isFavorite: boolean
      updatedAt: any
      createdAt: any
      companyId: number
      id: number
    }
  }
}

export type DeleteSharedReportMutationVariables = Exact<{
  input: SharedReportInput
}>

export type DeleteSharedReportMutation = { __typename?: 'Mutation'; DeleteSharedReport: boolean }

export type UpdateScheduleReportMutationVariables = Exact<{
  input: UpdateScheduledReportInput
}>

export type UpdateScheduleReportMutation = {
  __typename?: 'Mutation'
  UpdateScheduledReport: {
    __typename?: 'ScheduledReport'
    CoreReport: {
      __typename?: 'CoreReport'
      reportName: CoreReportNameEnum
      isFavorite: boolean
      updatedAt: any
      createdAt: any
      companyId: number
      id: number
      ScheduledReport: Array<{
        __typename?: 'ScheduledReport'
        coreReportId: number
        customDaysOfWeek?: any | null
        customTime?: string | null
        deleted?: any | null
        endDate?: any | null
        id: number
        recipients: any
        repeat?: RepeatTypeEnum | null
        reportSchedulerType: ReportSchedulerTypeEnum
        startDate?: any | null
      }>
    }
  }
}

export type DeleteRequisitionMutationVariables = Exact<{
  input: DeleteRequisitionInput
}>

export type DeleteRequisitionMutation = {
  __typename?: 'Mutation'
  DeleteRequisition: { __typename?: 'Requisition'; id: string }
}

export type DeleteRequisitionJobDescriptionMutationVariables = Exact<{
  input: DeleteRequisitionJobDescriptionInput
}>

export type DeleteRequisitionJobDescriptionMutation = {
  __typename?: 'Mutation'
  DeleteRequisitionJobDescription: { __typename?: 'RequisitionJobDescription'; id: string }
}

export type DeleteTemplateJobDescriptionMutationVariables = Exact<{
  input: DeleteTemplateJobDescriptionInput
}>

export type DeleteTemplateJobDescriptionMutation = {
  __typename?: 'Mutation'
  DeleteTemplateJobDescription: { __typename?: 'TemplateJobDescription'; id: string }
}

export type UpsertRequisitionJobDescriptionMutationVariables = Exact<{
  input: UpsertRequisitionJobDescriptionInput
}>

export type UpsertRequisitionJobDescriptionMutation = {
  __typename?: 'Mutation'
  UpsertRequisitionJobDescription?: {
    __typename?: 'RequisitionJobDescription'
    id: string
    requisitionId?: number | null
    description?: string | null
  } | null
}

export type UpsertTemplateJobDescriptionMutationVariables = Exact<{
  input: UpsertTemplateJobDescriptionInput
}>

export type UpsertTemplateJobDescriptionMutation = {
  __typename?: 'Mutation'
  UpsertTemplateJobDescription?: {
    __typename?: 'TemplateJobDescription'
    id: string
    name?: string | null
    description?: string | null
    companyDepartmentId?: string | null
  } | null
}

export type DeleteJobOpeningMutationVariables = Exact<{
  input: DeleteJobOpeningInput
}>

export type DeleteJobOpeningMutation = {
  __typename?: 'Mutation'
  DeleteJobOpening: { __typename?: 'JobOpening'; id: string }
}

export type UpsertJobOpeningMutationVariables = Exact<{
  input: UpsertJobOpeningInput
}>

export type UpsertJobOpeningMutation = {
  __typename?: 'Mutation'
  UpsertJobOpening?: {
    __typename?: 'JobOpening'
    id: string
    jobRequisitionId?: number | null
    openingStatus?: OpeningStatusEnum | null
    extOpeningId?: string | null
    companyId?: number | null
    targetHireDate?: any | null
    openingType?: OpeningTypeEnum | null
    filledDate?: any | null
    startDate?: any | null
    filledByApplication?: number | null
    filledByCandidate?: number | null
    closedReason?: ClosedReasonEnum | null
    closedDate?: any | null
  } | null
}

export type DeleteRequisitionSkillMutationVariables = Exact<{
  input: DeleteRequisitionSkillInput
}>

export type DeleteRequisitionSkillMutation = {
  __typename?: 'Mutation'
  DeleteRequisitionSkill: { __typename?: 'RequisitionSkill'; id: string }
}

export type UpsertRequisitionSkillMutationVariables = Exact<{
  input: UpsertRequisitionSkillInput
}>

export type UpsertRequisitionSkillMutation = {
  __typename?: 'Mutation'
  UpsertRequisitionSkill?: {
    __typename?: 'RequisitionSkill'
    id: string
    requisitionId?: number | null
    skillId?: number | null
    sequenceNumber?: number | null
  } | null
}

export type DeleteJobListingScreeningQuestionMutationVariables = Exact<{
  input: DeleteJobListingScreeningQuestionInput
}>

export type DeleteJobListingScreeningQuestionMutation = {
  __typename?: 'Mutation'
  DeleteJobListingScreeningQuestion: { __typename?: 'JobListingScreeningQuestion'; id: string }
}

export type DeleteScreeningQuestionMutationVariables = Exact<{
  input: DeleteScreeningQuestionInput
}>

export type DeleteScreeningQuestionMutation = {
  __typename?: 'Mutation'
  DeleteScreeningQuestion: { __typename?: 'ScreeningQuestion'; id: string }
}

export type UpsertJobListingScreeningQuestionMutationVariables = Exact<{
  input: UpsertJobListingScreeningQuestionInput
}>

export type UpsertJobListingScreeningQuestionMutation = {
  __typename?: 'Mutation'
  UpsertJobListingScreeningQuestion?: {
    __typename?: 'JobListingScreeningQuestion'
    id: string
    jobListingId?: number | null
    displaySequenceNumber?: number | null
    screeningQuestionId?: number | null
    ScreeningQuestion?: {
      __typename?: 'ScreeningQuestion'
      id: string
      isTemplateQuestion?: boolean | null
      questionTitle?: string | null
      questionDesc?: string | null
      questionType?: QuestionTypeEnum | null
      minVal?: number | null
      maxVal?: number | null
      valStatus?: string | null
      ansOptions?: string | null
      isRejectionQuestion?: boolean | null
      rejectMessage?: string | null
      templateMessageId?: number | null
      preferredAnswer?: string | null
      companyId?: number | null
      companyUserId?: number | null
    } | null
  } | null
}

export type UpsertScreeningQuestionMutationVariables = Exact<{
  input: UpsertScreeningQuestionInput
}>

export type UpsertScreeningQuestionMutation = {
  __typename?: 'Mutation'
  UpsertScreeningQuestion?: {
    __typename?: 'ScreeningQuestion'
    id: string
    isTemplateQuestion?: boolean | null
    questionTitle?: string | null
    questionDesc?: string | null
    questionType?: QuestionTypeEnum | null
    minVal?: number | null
    maxVal?: number | null
    valStatus?: string | null
    ansOptions?: string | null
    isRejectionQuestion?: boolean | null
    rejectMessage?: string | null
    templateMessageId?: number | null
    preferredAnswer?: string | null
    companyId?: number | null
    companyUserId?: number | null
  } | null
}

export type UpdateRequisitionApplicabilityMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  jobListings?: InputMaybe<Array<UpsertJobListingInput> | UpsertJobListingInput>
}>

export type UpdateRequisitionApplicabilityMutation = {
  __typename?: 'Mutation'
  UpsertRequisition?: {
    __typename: 'Requisition'
    id: string
    jobListings?: Array<{ __typename: 'JobListing'; id: number; acceptingCandidates?: boolean | null }> | null
  } | null
}

export type UpsertRequisitionMutationVariables = Exact<{
  input: UpsertRequisitionInput
}>

export type UpsertRequisitionMutation = {
  __typename?: 'Mutation'
  UpsertRequisition?: {
    __typename?: 'Requisition'
    id: string
    jobStatus?: JobStatusEnum | null
    openedAt?: any | null
    jobName?: string | null
    extRequisitionId?: string | null
    jobType?: JobTypeEnum | null
    jobLevel?: JobLevelEnum | null
    travelType?: TravelTypeEnum | null
    payType?: PayTypeEnum | null
    minPay?: number | null
    maxPay?: number | null
    additionalComp?: any | null
    benefits?: any | null
    locationDistancePref?: number | null
    educationLevelPref?: EducationLevelPrefEnum | null
    yearsOfExperiencePref?: YearsOfExperienceEnum | null
    nonTraditionalPref?: boolean | null
    veteranPref?: boolean | null
    diverseHirePref?: boolean | null
    collegeGradPref?: boolean | null
    jobOpening?: Array<{
      __typename?: 'JobOpening'
      id: string
      jobRequisitionId?: number | null
      openingStatus?: OpeningStatusEnum | null
      extOpeningId?: string | null
      companyId?: number | null
      targetHireDate?: any | null
      openingType?: OpeningTypeEnum | null
      filledDate?: any | null
      startDate?: any | null
      filledByApplication?: number | null
      filledByCandidate?: number | null
      closedReason?: ClosedReasonEnum | null
      closedDate?: any | null
    }> | null
    RequisitionJobDescription?: Array<{
      __typename?: 'RequisitionJobDescription'
      id: string
      requisitionId?: number | null
      description?: string | null
    }> | null
    RequisitionSkills?: Array<{
      __typename?: 'RequisitionSkill'
      sequenceNumber?: number | null
      skill?: { __typename?: 'Skill'; name?: string | null; level?: string | null; yearsOfExp?: number | null } | null
    }> | null
    jobListings?: Array<{
      __typename?: 'JobListing'
      deletedAt?: any | null
      id: number
      publicListingId?: string | null
      jobRequisitionId?: number | null
      listingTitle?: string | null
      externalTitle?: string | null
      workplaceType?: WorkplaceTypeEnum | null
      publicDescription?: string | null
      displayPayOnListing?: boolean | null
      industry?: IndustryEnum | null
      subIndustry?: SubIndustryTypeEnum | null
      companyDefault?: boolean | null
      stateAndOrCountry?: string | null
      internalMatchingDescription?: string | null
      searchPublicDescription?: string | null
      searchInternalMatchingDescription?: string | null
      postedAt?: any | null
      acceptingCandidates?: boolean | null
      stage?: StageEnum | null
      Address?: Array<{
        __typename?: 'Address'
        id: number
        addressLine1?: string | null
        addressLine2?: string | null
        city?: string | null
        state?: string | null
        country?: string | null
        postalCode?: string | null
        timeZone?: string | null
      }> | null
      JobListingScreeningQuestion?: Array<{
        __typename?: 'JobListingScreeningQuestion'
        id: string
        jobListingId?: number | null
        displaySequenceNumber?: number | null
        screeningQuestionId?: number | null
        ScreeningQuestion?: {
          __typename?: 'ScreeningQuestion'
          id: string
          isTemplateQuestion?: boolean | null
          questionTitle?: string | null
          questionDesc?: string | null
          questionType?: QuestionTypeEnum | null
          minVal?: number | null
          maxVal?: number | null
          valStatus?: string | null
          ansOptions?: string | null
          isRejectionQuestion?: boolean | null
          rejectMessage?: string | null
          templateMessageId?: number | null
          preferredAnswer?: string | null
          companyId?: number | null
          companyUserId?: number | null
        } | null
      }> | null
    }> | null
    jobPipelines?: Array<{
      __typename?: 'JobPipeline'
      id: string
      title?: string | null
      jobRequisitionId?: number | null
      templatePipelineId?: number | null
      TemplatePipeline?: {
        __typename?: 'TemplatePipeline'
        id: string
        companyId?: number | null
        companyUserId?: number | null
        name?: string | null
        companyDepartmentId?: number | null
        archived?: boolean | null
        TemplateMilestones?: Array<{
          __typename?: 'TemplateMilestone'
          id: string
          templatePipelineId?: number | null
          name?: NameEnum | null
          rankOrder?: number | null
          archived?: boolean | null
          TemplateStage?: Array<{
            __typename?: 'TemplateStage'
            id: string
            templatePipelineId?: number | null
            visibility?: TemplateStageVisibilityEnum | null
            name?: string | null
            templateMilestoneId?: number | null
            rankOrder?: number | null
            archived?: boolean | null
          }> | null
        }> | null
      } | null
    }> | null
    hiringTeamMember?: Array<{
      __typename?: 'HiringTeamMember'
      id: string
      companyUserId?: number | null
      roleId?: number | null
      requisitionId?: number | null
      requisitionJobDescriptionId?: number | null
    }> | null
    candidateCollaborator?: Array<{
      __typename?: 'CandidateCollaborator'
      id: string
      requisitionId?: number | null
      companyUserId?: number | null
      candidateProfileId?: number | null
      roleId?: number | null
      requisitionJobDescriptionId?: number | null
    }> | null
    stageCollaborator?: Array<{
      __typename?: 'StageCollaborator'
      id: string
      requisitionId?: number | null
      companyUserId?: number | null
      templateStageId?: number | null
      roleId?: number | null
      requisitionJobDescriptionId?: number | null
    }> | null
  } | null
}

export type CreateScalisUserUploadedDocumentMutationVariables = Exact<{
  input: CreateScalisUserUploadedDocumentInput
}>

export type CreateScalisUserUploadedDocumentMutation = {
  __typename?: 'Mutation'
  CreateScalisUserUploadedDocument?: {
    __typename?: 'CreateScalisUserUploadedDocumentOutput'
    id: number
    documentPath: string
    documentName: string
    documentType: DocumentTypeEnum
  } | null
}

export type DeleteScalisUserUploadedDocumentMutationVariables = Exact<{
  input: DeleteScalisUserUploadedDocumentInput
}>

export type DeleteScalisUserUploadedDocumentMutation = {
  __typename?: 'Mutation'
  DeleteScalisUserUploadedDocument?: { __typename?: 'DeleteScalisUserUploadedDocumentOutput'; success: boolean } | null
}

export type UpdateSchedulerLinkSettingsMutationVariables = Exact<{
  input: UpdateSchedulerLinkSettingsInput
}>

export type UpdateSchedulerLinkSettingsMutation = {
  __typename?: 'Mutation'
  UpdateSchedulerLinkSettings: {
    __typename?: 'SchedulerLinkSettings'
    id: string
    minimumHour: number
    rollingDays: number
    maxInterviewsPerDay: number
  }
}

export type DeleteScorecardTemplateMutationVariables = Exact<{
  input: DeleteScorecardTemplateInput
}>

export type DeleteScorecardTemplateMutation = {
  __typename?: 'Mutation'
  DeleteScorecardTemplate: { __typename?: 'ScorecardTemplate'; id: string }
}

export type ToggleScorecardTemplateArchiveMutationVariables = Exact<{
  input: ToggleScorecardTemplateArchiveInput
}>

export type ToggleScorecardTemplateArchiveMutation = {
  __typename?: 'Mutation'
  ToggleScorecardTemplateArchive?: {
    __typename?: 'ScorecardTemplate'
    id: string
    scorecardName?: string | null
    archived?: boolean | null
    department?: string | null
    companyId?: number | null
    createdAt?: any | null
  } | null
}

export type UpsertScorecardTemplateMutationVariables = Exact<{
  input: UpsertScorecardTemplateInput
}>

export type UpsertScorecardTemplateMutation = {
  __typename?: 'Mutation'
  UpsertScorecardTemplate?: {
    __typename?: 'ScorecardTemplate'
    id: string
    scorecardName?: string | null
    scorecardDescription?: string | null
    visible?: boolean | null
    department?: string | null
    companyId?: number | null
    createdAt?: any | null
  } | null
}

export type DeleteSkillMutationVariables = Exact<{
  input: Array<DeleteSkillInput> | DeleteSkillInput
}>

export type DeleteSkillMutation = { __typename?: 'Mutation'; DeleteSkill: Array<boolean> }

export type UpsertSkillMutationVariables = Exact<{
  input: Array<UpsertSkillInput> | UpsertSkillInput
}>

export type UpsertSkillMutation = {
  __typename?: 'Mutation'
  UpsertSkill?: Array<{
    __typename?: 'Skill'
    id: string
    experienceId?: number | null
    name?: string | null
    level?: string | null
    yearsOfExp?: number | null
  }> | null
}

export type DeleteStageCollaboratorMutationVariables = Exact<{
  input: DeleteStageCollaboratorInput
}>

export type DeleteStageCollaboratorMutation = {
  __typename?: 'Mutation'
  DeleteStageCollaborator: { __typename?: 'StageCollaborator'; id: string }
}

export type UpsertScreenStageCollaboratorMutationVariables = Exact<{
  input: UpsertStageCollaboratorInput
}>

export type UpsertScreenStageCollaboratorMutation = {
  __typename?: 'Mutation'
  UpsertStageCollaborator?: {
    __typename: 'StageCollaborator'
    id: string
    CompanyUser?: {
      __typename: 'CompanyUser'
      id: string
      profileImage?: string | null
      UserInvitation?: {
        __typename: 'UserInvitation'
        id: number
        email?: string | null
        ScalisUser?: {
          __typename: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type UpsertStageCollaboratorMutationVariables = Exact<{
  input: UpsertStageCollaboratorInput
}>

export type UpsertStageCollaboratorMutation = {
  __typename?: 'Mutation'
  UpsertStageCollaborator?: {
    __typename?: 'StageCollaborator'
    id: string
    requisitionId?: number | null
    companyUserId?: number | null
    templateStageId?: number | null
    roleId?: number | null
    requisitionJobDescriptionId?: number | null
  } | null
}

export type DeleteStageSettingsMutationVariables = Exact<{
  input: DeleteStageSettingsInput
}>

export type DeleteStageSettingsMutation = {
  __typename?: 'Mutation'
  DeleteStageSettings: { __typename?: 'StageSettings'; id: string }
}

export type UpsertStageSettingsMutationVariables = Exact<{
  input: UpsertStageSettingsInput
}>

export type UpsertStageSettingsMutation = {
  __typename?: 'Mutation'
  UpsertStageSettings?: {
    __typename?: 'StageSettings'
    id: string
    jobPipelineId?: number | null
    templateStageId?: number | null
    scorecardTemplateId?: number | null
  } | null
}

export type HtmlToBase64MutationVariables = Exact<{
  input: HtmlToBase64Input
}>

export type HtmlToBase64Mutation = {
  __typename?: 'Mutation'
  HtmlToBase64?: { __typename?: 'HtmlToBase64Output'; pdf: string; docx: string } | null
}

export type HtmlToDocxMutationVariables = Exact<{
  input: HtmlToDocxInput
}>

export type HtmlToDocxMutation = {
  __typename?: 'Mutation'
  HtmlToDocx?: {
    __typename?: 'HtmlToDocxOutput'
    filename: string
    size?: number | null
    url?: string | null
    dir?: string | null
  } | null
}

export type DeleteTemplateMessageMutationVariables = Exact<{
  input: DeleteTemplateMessageInput
}>

export type DeleteTemplateMessageMutation = {
  __typename?: 'Mutation'
  DeleteTemplateMessage: { __typename?: 'TemplateMessage'; id: string }
}

export type UpsertTemplateMessageMutationVariables = Exact<{
  input: UpsertTemplateMessageInput
}>

export type UpsertTemplateMessageMutation = {
  __typename?: 'Mutation'
  UpsertTemplateMessage?: {
    __typename?: 'TemplateMessage'
    id: string
    title?: string | null
    message?: string | null
    messageType?: MessageTypeEnum | null
    messageOwner?: MessageOwnerEnum | null
    createdAt?: any | null
    updatedAt?: any | null
  } | null
}

export type DeleteTemplateMilestoneMutationVariables = Exact<{
  input: DeleteTemplateMilestoneInput
}>

export type DeleteTemplateMilestoneMutation = {
  __typename?: 'Mutation'
  DeleteTemplateMilestone: { __typename?: 'TemplateMilestone'; id: string }
}

export type UpsertTemplateMilestoneMutationVariables = Exact<{
  input: UpsertTemplateMilestoneInput
}>

export type UpsertTemplateMilestoneMutation = {
  __typename?: 'Mutation'
  UpsertTemplateMilestone?: {
    __typename?: 'TemplateMilestone'
    id: string
    templatePipelineId?: number | null
    name?: NameEnum | null
    rankOrder?: number | null
    archived?: boolean | null
    TemplateStage?: Array<{
      __typename?: 'TemplateStage'
      id: string
      templatePipelineId?: number | null
      visibility?: TemplateStageVisibilityEnum | null
      name?: string | null
      templateMilestoneId?: number | null
      rankOrder?: number | null
      archived?: boolean | null
    }> | null
  } | null
}

export type DeleteTemplatePipelineMutationVariables = Exact<{
  input: DeleteTemplatePipelineInput
}>

export type DeleteTemplatePipelineMutation = {
  __typename?: 'Mutation'
  DeleteTemplatePipeline: { __typename?: 'TemplatePipeline'; id: string }
}

export type UpsertTemplatePipelineMutationVariables = Exact<{
  input: UpsertTemplatePipelineInput
}>

export type UpsertTemplatePipelineMutation = {
  __typename?: 'Mutation'
  UpsertTemplatePipeline?: {
    __typename?: 'TemplatePipeline'
    id: string
    companyId?: number | null
    companyUserId?: number | null
    name?: string | null
    companyDepartmentId?: number | null
    archived?: boolean | null
    TemplateMilestones?: Array<{
      __typename?: 'TemplateMilestone'
      id: string
      templatePipelineId?: number | null
      name?: NameEnum | null
      rankOrder?: number | null
      archived?: boolean | null
      TemplateStage?: Array<{
        __typename?: 'TemplateStage'
        id: string
        templatePipelineId?: number | null
        visibility?: TemplateStageVisibilityEnum | null
        name?: string | null
        templateMilestoneId?: number | null
        rankOrder?: number | null
        archived?: boolean | null
      }> | null
    }> | null
  } | null
}

export type DeleteTemplateStageMutationVariables = Exact<{
  input: DeleteTemplateStageInput
}>

export type DeleteTemplateStageMutation = {
  __typename?: 'Mutation'
  DeleteTemplateStage: { __typename?: 'TemplateStage'; id: string }
}

export type UpsertTemplateStageMutationVariables = Exact<{
  input: UpsertTemplateStageInput
}>

export type UpsertTemplateStageMutation = {
  __typename?: 'Mutation'
  UpsertTemplateStage?: {
    __typename?: 'TemplateStage'
    id: string
    templatePipelineId?: number | null
    visibility?: TemplateStageVisibilityEnum | null
    name?: string | null
    templateMilestoneId?: number | null
    rankOrder?: number | null
    archived?: boolean | null
  } | null
}

export type UploadS3FileMutationVariables = Exact<{
  input: UploadS3FileInput
}>

export type UploadS3FileMutation = {
  __typename?: 'Mutation'
  UploadS3File?: { __typename?: 'UploadS3FileOutput'; fileKey: string; filePath: string } | null
}

export type CreateUploadedCandidateMutationVariables = Exact<{
  input: CreateUploadedCandidateInput
}>

export type CreateUploadedCandidateMutation = {
  __typename?: 'Mutation'
  CreateUploadedCandidate: { __typename: 'UploadedCandidate'; id: number }
}

export type UpdateAccountInformationMutationVariables = Exact<{
  input: UpdateScalisUserDataInput
}>

export type UpdateAccountInformationMutation = {
  __typename?: 'Mutation'
  UpdateScalisUserData: {
    __typename?: 'ScalisUser'
    firstName?: string | null
    lastName?: string | null
    Address?: Array<{
      __typename?: 'Address'
      id: number
      addressLine1?: string | null
      addressLine2?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      postalCode?: string | null
      timeZone?: string | null
    }> | null
    ContactInfo?: Array<{ __typename?: 'ContactInfo'; id: number; phoneNumber?: string | null }> | null
  }
}

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>

export type DeleteAccountMutation = {
  __typename?: 'Mutation'
  DisableUserAccount?: { __typename?: 'ScalisUser'; id: number } | null
}

export type UpsertEmailMutationVariables = Exact<{
  input: UpsertEmailInput
}>

export type UpsertEmailMutation = {
  __typename?: 'Mutation'
  UpsertEmail: {
    __typename?: 'ScalisUser'
    EmailVerification?: Array<{
      __typename: 'EmailVerification'
      id: number
      emailAddress?: string | null
      isPrimary?: boolean | null
      isVisibleToEmployers?: boolean | null
      activeStatus?: boolean | null
    }> | null
  }
}

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput
}>

export type ChangePasswordMutation = {
  __typename?: 'Mutation'
  ChangePassword?: { __typename: 'ScalisUser'; id: number } | null
}

export type ResendResetPasswordEmailMutationVariables = Exact<{
  input: ResendResetPasswordEmailInput
}>

export type ResendResetPasswordEmailMutation = {
  __typename?: 'Mutation'
  ResendResetPasswordEmail: Array<{ __typename: 'EmailVerification'; id: number }>
}

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  ResetPassword?: { __typename: 'ScalisUser'; id: number } | null
}

export type SendResetPasswordEmailMutationVariables = Exact<{
  input: SendResetPasswordEmailInput
}>

export type SendResetPasswordEmailMutation = {
  __typename?: 'Mutation'
  SendResetPasswordEmail: Array<{ __typename: 'EmailVerification'; id: number; emailAddress?: string | null }>
}

export type VerifyEmailCodeResetPasswordMutationVariables = Exact<{
  input: VerifyEmailCodeInput
}>

export type VerifyEmailCodeResetPasswordMutation = {
  __typename?: 'Mutation'
  VerifyEmailCode: { __typename: 'ScalisUser'; id: number }
}

export type VerifyPasswordCodeMutationVariables = Exact<{
  input: VerifyPasswordCodeInput
}>

export type VerifyPasswordCodeMutation = {
  __typename?: 'Mutation'
  VerifyPasswordCode: { __typename: 'ScalisUser'; id: number }
}

export type GetPublicJobListingQueryVariables = Exact<{
  publicListingId: Scalars['String']['input']
}>

export type GetPublicJobListingQuery = {
  __typename?: 'Query'
  GetPublicJobListing?: {
    __typename?: 'JobListing'
    externalTitle?: string | null
    externalListingId?: string | null
    displayPayOnListing?: boolean | null
    id: number
    industry?: IndustryEnum | null
    JobListingScreeningQuestion?: Array<{
      __typename?: 'JobListingScreeningQuestion'
      id: string
      active?: boolean | null
      displaySequenceNumber?: number | null
      screeningQuestionId?: number | null
      ScreeningQuestion?: {
        __typename?: 'ScreeningQuestion'
        id: string
        companyId?: number | null
        questionTitle?: string | null
        questionType?: QuestionTypeEnum | null
        ansOptions?: string | null
        isTemplateQuestion?: boolean | null
        isGlobalQuestion?: boolean | null
        globalQuestionOrder?: number | null
        maxVal?: number | null
        minVal?: number | null
        preferredAnswer?: string | null
        questionDesc?: string | null
        valStatus?: string | null
      } | null
    }> | null
    Requisition?: { __typename?: 'Requisition'; Company?: { __typename?: 'Company'; id: string } | null } | null
  } | null
}

export type GetUserHasFinishedOnboardingQueryVariables = Exact<{ [key: string]: never }>

export type GetUserHasFinishedOnboardingQuery = {
  __typename?: 'Query'
  Me?: {
    __typename?: 'ScalisUser'
    id: number
    JobSeekerInfo?: { __typename?: 'JobSeekerInfo'; id: number; hasFinishedOnboarding: boolean } | null
  } | null
}

export type GetUserProfileDataQueryVariables = Exact<{ [key: string]: never }>

export type GetUserProfileDataQuery = {
  __typename?: 'Query'
  Me?: {
    __typename?: 'ScalisUser'
    id: number
    firstName?: string | null
    lastName?: string | null
    completedAt?: any | null
    currentStage?: string | null
    profileImage?: string | null
    EmailVerification?: Array<{
      __typename?: 'EmailVerification'
      emailAddress?: string | null
      emailVerified?: boolean | null
      isPrimary?: boolean | null
    }> | null
    JobSeekerInfo?: {
      __typename?: 'JobSeekerInfo'
      id: number
      aboutMe?: string | null
      searchingJobs?: boolean | null
    } | null
    JobSeekerHeader?: Array<{
      __typename?: 'JobSeekerHeader'
      id: number
      profileHeadline?: string | null
      jobDisplayedId?: number | null
      educationDisplayedId?: number | null
    }> | null
    ContactInfo?: Array<{ __typename?: 'ContactInfo'; id: number; phoneNumber?: string | null }> | null
    Address?: Array<{
      __typename?: 'Address'
      id: number
      city?: string | null
      state?: string | null
      country?: string | null
      addressLine1?: string | null
      addressLine2?: string | null
      postalCode?: string | null
      timeZone?: string | null
      longitude?: string | null
      latitude?: string | null
    }> | null
    Achievement?: Array<{
      __typename?: 'Achievement'
      id: number
      name?: string | null
      issuingAuthority?: string | null
      issueYear?: number | null
      description?: any | null
    }> | null
    CertificateOrLicense?: Array<{
      __typename?: 'CertificateOrLicense'
      id: number
      name?: string | null
      issuingAuthority?: string | null
      issueYear?: number | null
      description?: any | null
    }> | null
    JobSeekerSkill?: Array<{
      __typename?: 'JobSeekerSkills'
      id: number
      sequenceNumber?: number | null
      level?: string | null
      name?: string | null
      yearsOfExp?: number | null
    }> | null
    DemographicInfo?: Array<{
      __typename?: 'DemographicInfo'
      gender?: GenderEnum | null
      raceEthnicity?: string | null
      veteranStatus?: VeteranStatusEnum | null
    }> | null
    WebsiteOrSocialMedia?: Array<{
      __typename?: 'WebsiteOrSocialMedia'
      websiteURL: string
      type: WebsiteTypeEnum
    }> | null
    VolunteerWork?: Array<{
      __typename?: 'VolunteerWork'
      id: number
      roleType?: RoleTypeEnum | null
      volunteerJobTitle?: string | null
      organizationName?: string | null
      location?: string | null
      startDate?: any | null
      endDate?: any | null
      isCurrentlyWorking?: boolean | null
      description?: any | null
      searchDescription?: string | null
    }> | null
    Experience?: Array<{
      __typename?: 'Experience'
      id: number
      jobTitle?: string | null
      employerName?: string | null
      jobType?: JobTypeEnum | null
      locationType?: string | null
      location?: string | null
      startDate?: any | null
      endDate?: any | null
      isCurrentlyWorking?: boolean | null
      isCurrentJob?: boolean | null
      description?: string | null
      industry?: IndustryEnum | null
      subIndustry?: string | null
      managementPosition?: boolean | null
      teamSize?: TeamSizeEnum | null
      Skill?: Array<{ __typename?: 'Skill'; id: string; name?: string | null }> | null
    }> | null
    Education?: Array<{
      __typename?: 'Education'
      id: number
      institutionName?: string | null
      educationLevel?: EducationLevelEnum | null
      areasOfStudy?: any | null
      startDate?: any | null
      endDate?: any | null
      cumGPA?: number | null
      cumGPAScale?: number | null
      majors?: any | null
      minors?: any | null
      hideCumGPA?: boolean | null
      hideDepartmentGPA?: boolean | null
      departmentCumGPA?: number | null
      additionalInformation?: string | null
      currentlyStudying?: boolean | null
    }> | null
    ScalisUserUploadedDocument?: Array<{
      __typename?: 'ScalisUserUploadedDocument'
      id: number
      documentPath?: string | null
      documentName?: string | null
      documentType: DocumentTypeEnum
      createdAt: any
    }> | null
  } | null
}

export type GetCalendarSettingsQueryVariables = Exact<{ [key: string]: never }>

export type GetCalendarSettingsQuery = {
  __typename?: 'Query'
  GetCalendarSettings?: {
    __typename?: 'CalendarSettings'
    id: string
    availability?: any | null
    provider: string
    timeZone?: string | null
    hourDisplay: number
    email: string
    companyUserId: number
    nylasGrantId: string
    Calendars?: Array<{
      __typename?: 'Calendar'
      id: string
      nylasCalendarId: string
      calendarSettingsId: number
      name: string
      isPrimary: boolean
      color?: string | null
      CalendarEvents?: Array<{
        __typename?: 'CalendarEvent'
        id: string
        nylasEventStatus?: NylasEventStatusesEnum | null
        nylasCalendarEventId?: string | null
        title?: string | null
        eventType?: EventTypeEnum | null
        description?: string | null
        startTime?: any | null
        endTime?: any | null
        timeZone?: string | null
        location?: string | null
        repeats?: RepeatsEnum | null
        reminders?: any | null
        employers?: any | null
        conferencing?: any | null
        masterEventId?: string | null
        regardingCandidates?: any | null
        regardingJobs?: any | null
        internalGuests?: any | null
        candidates?: any | null
        isScalisEvent: boolean
      }> | null
    }> | null
  } | null
}

export type GetCandidateApplicationByListingQueryVariables = Exact<{
  candidateProfileId: Scalars['Int']['input']
  publicListingId: Scalars['String']['input']
}>

export type GetCandidateApplicationByListingQuery = {
  __typename?: 'Query'
  GetCandidateApplication?: Array<{
    __typename?: 'CandidateApplication'
    id: string
    candidateProfileId?: number | null
    JobListing?: {
      __typename?: 'JobListing'
      id: number
      Requisition?: { __typename?: 'Requisition'; id: string; jobName?: string | null } | null
    } | null
  }> | null
}

export type GetApplicationOverviewQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetApplicationOverviewQuery = {
  __typename?: 'Query'
  GetCandidateApplication?: Array<{
    __typename?: 'CandidateApplication'
    id: string
    applicationStatus?: ApplicationStatusEnum | null
    appliedAt?: any | null
    applicationType?: string | null
    Company?: {
      __typename?: 'Company'
      Address: Array<{ __typename?: 'Address'; city?: string | null; state?: string | null; country?: string | null }>
      CompanyInfo?: { __typename?: 'CompanyInfo'; companyName?: string | null; logo?: string | null } | null
    } | null
    JobListing?: {
      __typename?: 'JobListing'
      externalListingId?: string | null
      externalTitle?: string | null
      publicListingId?: string | null
      id: number
      jobRequisitionId?: number | null
      stateAndOrCountry?: string | null
      workplaceType?: WorkplaceTypeEnum | null
      Requisition?: {
        __typename?: 'Requisition'
        id: string
        jobName?: string | null
        extRequisitionId?: string | null
      } | null
      Address?: Array<{
        __typename?: 'Address'
        timeZone?: string | null
        state?: string | null
        longitude?: string | null
        latitude?: string | null
        country?: string | null
        activeStatus?: boolean | null
        addressLine1?: string | null
        addressLine2?: string | null
        addressType?: string | null
        city?: string | null
        postalCode?: string | null
      }> | null
    } | null
    LastPublicTemplateStage?: {
      __typename?: 'TemplateStage'
      id: string
      name?: string | null
      TemplateMilestone?: { __typename?: 'TemplateMilestone'; name?: NameEnum | null } | null
    } | null
    TemplateStage?: {
      __typename?: 'TemplateStage'
      id: string
      name?: string | null
      visibility?: TemplateStageVisibilityEnum | null
      TemplateMilestone?: { __typename?: 'TemplateMilestone'; id: string; name?: NameEnum | null } | null
      TemplatePipeline?: {
        __typename?: 'TemplatePipeline'
        TemplateMilestones?: Array<{
          __typename?: 'TemplateMilestone'
          id: string
          name?: NameEnum | null
          rankOrder?: number | null
          TemplateStage?: Array<{
            __typename?: 'TemplateStage'
            name?: string | null
            id: string
            rankOrder?: number | null
            visibility?: TemplateStageVisibilityEnum | null
          }> | null
        }> | null
      } | null
    } | null
    CandidateInterview?: Array<{
      __typename?: 'CandidateInterview'
      stageId?: number | null
      interviewData?: any | null
      id: string
      status?: CandidateInterviewStatusEnum | null
      companyUserId?: number | null
      calendarEventId?: number | null
      applicationId?: number | null
      CalendarEvent?: {
        __typename?: 'CalendarEvent'
        title?: string | null
        timeZone?: string | null
        startTime?: any | null
        repeats?: RepeatsEnum | null
        reminders?: any | null
        regardingJobs?: any | null
        regardingCandidates?: any | null
        nylasEventStatus?: NylasEventStatusesEnum | null
        nylasCalendarEventId?: string | null
        masterEventId?: string | null
        location?: string | null
        isScalisEvent: boolean
        internalGuests?: any | null
        id: string
        eventType?: EventTypeEnum | null
        employers?: any | null
        endTime?: any | null
        description?: string | null
        conferencing?: any | null
        companyUserId?: number | null
        candidates?: any | null
        calendarId?: number | null
        EventAttachments?: Array<{
          __typename?: 'EventAttachment'
          id: string
          file?: string | null
          eventId: number
        }> | null
        Calendar?: { __typename?: 'Calendar'; name: string; id: string; isPrimary: boolean } | null
        CompanyUser?: {
          __typename?: 'CompanyUser'
          UserInvitation?: {
            __typename?: 'UserInvitation'
            ScalisUser?: {
              __typename?: 'ScalisUser'
              id: number
              firstName?: string | null
              lastName?: string | null
            } | null
          } | null
        } | null
      } | null
    }> | null
    ApplicantNotes?: Array<{
      __typename?: 'ApplicantNotes'
      userNoteId?: number | null
      id: string
      candidateApplicationId?: number | null
      updatedAt?: any | null
      UserNote?: { __typename?: 'UserNote'; id: string; noteText?: any | null } | null
    }> | null
    SchedulerLinkInvite?: Array<{
      __typename?: 'SchedulerLinkInvite'
      stageId: number
      nylasConfigurationId: string
      message?: string | null
      interviewData?: any | null
      id: string
      durationMinutes?: number | null
      companyUserId: number
      candidateData?: any | null
      calendarId: number
      applicationId: number
      TemplateStage?: {
        __typename?: 'TemplateStage'
        name?: string | null
        id: string
        rankOrder?: number | null
      } | null
    }> | null
    CandidateProfile?: {
      __typename?: 'CandidateProfile'
      ScalisUser?: {
        __typename?: 'ScalisUser'
        id: number
        lastName?: string | null
        firstName?: string | null
        profileImage?: string | null
      } | null
    } | null
  }> | null
}

export type GetCandidatesApplicationsByStageQueryVariables = Exact<{
  milestoneNotIn?: InputMaybe<Array<NameEnum> | NameEnum>
  milestoneIn?: InputMaybe<Array<NameEnum> | NameEnum>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCandidatesApplicationsByStageQuery = {
  __typename?: 'Query'
  GetTemplateStage?: Array<{
    __typename?: 'TemplateStage'
    id: string
    CandidateApplicationByStage?: Array<{
      __typename?: 'CandidateApplication'
      id: string
      CandidateProfile?: {
        __typename?: 'CandidateProfile'
        id: string
        UploadedCandidate?: {
          __typename?: 'UploadedCandidate'
          id: number
          lastName?: string | null
          firstName?: string | null
          emailAddress?: string | null
        } | null
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          lastName?: string | null
          firstName?: string | null
          EmailVerification?: Array<{
            __typename?: 'EmailVerification'
            id: number
            isVisibleToEmployers?: boolean | null
            isPrimary?: boolean | null
            emailAddress?: string | null
          }> | null
        } | null
      } | null
    }> | null
  }> | null
}

export type GetRejectCandidateQuestionsQueryVariables = Exact<{ [key: string]: never }>

export type GetRejectCandidateQuestionsQuery = {
  __typename?: 'Query'
  GetRejectCandidateQuestions?: Array<{
    __typename?: 'RejectCandidateQuestion'
    sequence?: number | null
    required?: boolean | null
    question?: string | null
    id: number
  }> | null
}

export type SearchCandidatesFilterContentQueryVariables = Exact<{
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  publicListingId?: InputMaybe<Scalars['String']['input']>
  filterType: FilterContentTypeEnum
  search: Scalars['String']['input']
}>

export type SearchCandidatesFilterContentQuery = {
  __typename?: 'Query'
  SearchCandidatesFilterContent: {
    __typename?: 'SearchCandidatesFilterContentOutput'
    filterContent?: Array<{ __typename?: 'filterContentItem'; value: string; id?: number | null }> | null
  }
}

export type GetCandidateCollaboratorQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetCandidateCollaboratorQuery = {
  __typename?: 'Query'
  GetCandidateCollaborator?: {
    __typename?: 'CandidateCollaborator'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    requisitionId?: number | null
    companyUserId?: number | null
    candidateProfileId?: number | null
    roleId?: number | null
    requisitionJobDescriptionId?: number | null
    role?: { __typename?: 'Role'; id: string; name: string } | null
  } | null
}

export type GetCandidateInterviewsProfileApplicationFragment = {
  __typename?: 'CandidateApplication'
  viewedProfile?: boolean | null
  viewedAt?: any | null
  templateStageId?: number | null
  matchScore?: number | null
  lastUpdatedStatusAt?: any | null
  jobListingId?: number | null
  interviewScheduling?: InterviewSchedulingEnum | null
  id: string
  deleted?: any | null
  createdAt?: any | null
  companyId?: number | null
  candidateProfileId?: number | null
  appliedAt?: any | null
  applicationType?: string | null
  applicationTimestamp?: any | null
  applicationCreationType?: string | null
  applicationStatus?: ApplicationStatusEnum | null
  CandidateInterview?: Array<{
    __typename?: 'CandidateInterview'
    stageId?: number | null
    interviewData?: any | null
    id: string
    status?: CandidateInterviewStatusEnum | null
    companyUserId?: number | null
    calendarEventId?: number | null
    applicationId?: number | null
    CalendarEvent?: {
      __typename?: 'CalendarEvent'
      title?: string | null
      timeZone?: string | null
      startTime?: any | null
      repeats?: RepeatsEnum | null
      reminders?: any | null
      regardingJobs?: any | null
      regardingCandidates?: any | null
      nylasEventStatus?: NylasEventStatusesEnum | null
      nylasCalendarEventId?: string | null
      masterEventId?: string | null
      location?: string | null
      isScalisEvent: boolean
      internalGuests?: any | null
      id: string
      eventType?: EventTypeEnum | null
      employers?: any | null
      endTime?: any | null
      description?: string | null
      conferencing?: any | null
      companyUserId?: number | null
      candidates?: any | null
      calendarId?: number | null
      EventAttachments?: Array<{
        __typename?: 'EventAttachment'
        id: string
        file?: string | null
        eventId: number
      }> | null
      Calendar?: { __typename?: 'Calendar'; name: string; id: string; isPrimary: boolean } | null
      CompanyUser?: {
        __typename?: 'CompanyUser'
        UserInvitation?: {
          __typename?: 'UserInvitation'
          ScalisUser?: {
            __typename?: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
          } | null
        } | null
      } | null
    } | null
  }> | null
  TemplateStage?: {
    __typename?: 'TemplateStage'
    id: string
    name?: string | null
    visibility?: TemplateStageVisibilityEnum | null
    TemplateMilestone?: { __typename?: 'TemplateMilestone'; id: string; name?: NameEnum | null } | null
    TemplatePipeline?: {
      __typename?: 'TemplatePipeline'
      TemplateMilestones?: Array<{
        __typename?: 'TemplateMilestone'
        id: string
        name?: NameEnum | null
        rankOrder?: number | null
        TemplateStage?: Array<{
          __typename?: 'TemplateStage'
          name?: string | null
          id: string
          rankOrder?: number | null
          visibility?: TemplateStageVisibilityEnum | null
        }> | null
      }> | null
    } | null
  } | null
  JobListing?: {
    __typename?: 'JobListing'
    externalListingId?: string | null
    externalTitle?: string | null
    publicListingId?: string | null
    id: number
    jobRequisitionId?: number | null
    stateAndOrCountry?: string | null
    workplaceType?: WorkplaceTypeEnum | null
    Requisition?: {
      __typename?: 'Requisition'
      id: string
      jobName?: string | null
      extRequisitionId?: string | null
    } | null
    Address?: Array<{
      __typename?: 'Address'
      timeZone?: string | null
      state?: string | null
      longitude?: string | null
      latitude?: string | null
      country?: string | null
      activeStatus?: boolean | null
      addressLine1?: string | null
      addressLine2?: string | null
      addressType?: string | null
      city?: string | null
      postalCode?: string | null
    }> | null
  } | null
  CandidateScorecard?: Array<{
    __typename?: 'CandidateScorecard'
    id: string
    status?: ScorecardStatusTypeEnum | null
    templateStageId?: number | null
    ScorecardTemplate?: { __typename?: 'ScorecardTemplate'; id: string; scorecardName?: string | null } | null
    CompanyUser?: {
      __typename?: 'CompanyUser'
      id: string
      UserInvitation?: {
        __typename?: 'UserInvitation'
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
        } | null
      } | null
    } | null
  }> | null
}

export type GetCandidateInterviewsQueryVariables = Exact<{
  candidateProfileId: Scalars['Int']['input']
}>

export type GetCandidateInterviewsQuery = {
  __typename?: 'Query'
  GetOneCandidateProfile?: {
    __typename?: 'CandidateProfile'
    CandidateApplication: Array<{
      __typename?: 'CandidateApplication'
      viewedProfile?: boolean | null
      viewedAt?: any | null
      templateStageId?: number | null
      matchScore?: number | null
      lastUpdatedStatusAt?: any | null
      jobListingId?: number | null
      interviewScheduling?: InterviewSchedulingEnum | null
      id: string
      deleted?: any | null
      createdAt?: any | null
      companyId?: number | null
      candidateProfileId?: number | null
      appliedAt?: any | null
      applicationType?: string | null
      applicationTimestamp?: any | null
      applicationCreationType?: string | null
      applicationStatus?: ApplicationStatusEnum | null
      CandidateInterview?: Array<{
        __typename?: 'CandidateInterview'
        stageId?: number | null
        interviewData?: any | null
        id: string
        status?: CandidateInterviewStatusEnum | null
        companyUserId?: number | null
        calendarEventId?: number | null
        applicationId?: number | null
        CalendarEvent?: {
          __typename?: 'CalendarEvent'
          title?: string | null
          timeZone?: string | null
          startTime?: any | null
          repeats?: RepeatsEnum | null
          reminders?: any | null
          regardingJobs?: any | null
          regardingCandidates?: any | null
          nylasEventStatus?: NylasEventStatusesEnum | null
          nylasCalendarEventId?: string | null
          masterEventId?: string | null
          location?: string | null
          isScalisEvent: boolean
          internalGuests?: any | null
          id: string
          eventType?: EventTypeEnum | null
          employers?: any | null
          endTime?: any | null
          description?: string | null
          conferencing?: any | null
          companyUserId?: number | null
          candidates?: any | null
          calendarId?: number | null
          EventAttachments?: Array<{
            __typename?: 'EventAttachment'
            id: string
            file?: string | null
            eventId: number
          }> | null
          Calendar?: { __typename?: 'Calendar'; name: string; id: string; isPrimary: boolean } | null
          CompanyUser?: {
            __typename?: 'CompanyUser'
            UserInvitation?: {
              __typename?: 'UserInvitation'
              ScalisUser?: {
                __typename?: 'ScalisUser'
                id: number
                firstName?: string | null
                lastName?: string | null
              } | null
            } | null
          } | null
        } | null
      }> | null
      TemplateStage?: {
        __typename?: 'TemplateStage'
        id: string
        name?: string | null
        visibility?: TemplateStageVisibilityEnum | null
        TemplateMilestone?: { __typename?: 'TemplateMilestone'; id: string; name?: NameEnum | null } | null
        TemplatePipeline?: {
          __typename?: 'TemplatePipeline'
          TemplateMilestones?: Array<{
            __typename?: 'TemplateMilestone'
            id: string
            name?: NameEnum | null
            rankOrder?: number | null
            TemplateStage?: Array<{
              __typename?: 'TemplateStage'
              name?: string | null
              id: string
              rankOrder?: number | null
              visibility?: TemplateStageVisibilityEnum | null
            }> | null
          }> | null
        } | null
      } | null
      JobListing?: {
        __typename?: 'JobListing'
        externalListingId?: string | null
        externalTitle?: string | null
        publicListingId?: string | null
        id: number
        jobRequisitionId?: number | null
        stateAndOrCountry?: string | null
        workplaceType?: WorkplaceTypeEnum | null
        Requisition?: {
          __typename?: 'Requisition'
          id: string
          jobName?: string | null
          extRequisitionId?: string | null
        } | null
        Address?: Array<{
          __typename?: 'Address'
          timeZone?: string | null
          state?: string | null
          longitude?: string | null
          latitude?: string | null
          country?: string | null
          activeStatus?: boolean | null
          addressLine1?: string | null
          addressLine2?: string | null
          addressType?: string | null
          city?: string | null
          postalCode?: string | null
        }> | null
      } | null
      CandidateScorecard?: Array<{
        __typename?: 'CandidateScorecard'
        id: string
        status?: ScorecardStatusTypeEnum | null
        templateStageId?: number | null
        ScorecardTemplate?: { __typename?: 'ScorecardTemplate'; id: string; scorecardName?: string | null } | null
        CompanyUser?: {
          __typename?: 'CompanyUser'
          id: string
          UserInvitation?: {
            __typename?: 'UserInvitation'
            ScalisUser?: {
              __typename?: 'ScalisUser'
              id: number
              firstName?: string | null
              lastName?: string | null
            } | null
          } | null
        } | null
      }> | null
    }>
  } | null
}

export type GetCandidateProfilesQueryVariables = Exact<{
  candidateProfileIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type GetCandidateProfilesQuery = {
  __typename?: 'Query'
  GetCandidateProfile?: Array<{
    __typename?: 'CandidateProfile'
    id: string
    uploadedCandidateId?: number | null
    scalisUserId?: number | null
    profileType?: string | null
    ScalisUser?: {
      __typename?: 'ScalisUser'
      id: number
      profileImage?: string | null
      firstName?: string | null
      lastName?: string | null
      Experience?: Array<{
        __typename?: 'Experience'
        id: number
        employerName?: string | null
        isCurrentlyWorking?: boolean | null
        isCurrentJob?: boolean | null
        jobTitle?: string | null
      }> | null
      Education?: Array<{
        __typename?: 'Education'
        id: number
        educationLevel?: EducationLevelEnum | null
        institutionName?: string | null
      }> | null
      ContactInfo?: Array<{ __typename?: 'ContactInfo'; phoneNumber?: string | null; id: number }> | null
      EmailVerification?: Array<{
        __typename?: 'EmailVerification'
        isVisibleToEmployers?: boolean | null
        isPrimary?: boolean | null
        emailVerified?: boolean | null
        id: number
        emailAddress?: string | null
        activeStatus?: boolean | null
      }> | null
    } | null
    UploadedCandidate?: {
      __typename?: 'UploadedCandidate'
      linkedinProfile?: string | null
      lastName?: string | null
      id: number
      firstName?: string | null
      emailAddress?: string | null
    } | null
  }> | null
}

export type GetCandidateStatsQueryVariables = Exact<{
  jobListingId: Scalars['String']['input']
  candidateProfileId: Scalars['Int']['input']
}>

export type GetCandidateStatsQuery = {
  __typename?: 'Query'
  GetCandidateStats?: {
    __typename?: 'CandidateStats'
    skillsMatched?: number | null
    screeningQuestions?: string | null
    matchPercentage?: number | null
    experience?: string | null
    distance?: string | null
    opportunity: {
      __typename?: 'CandidateStatsOpportunity'
      candidateApplicationId: number
      applicationStatus?: ApplicationStatusEnum | null
      appliedAt: string
      currentStageId: number
      jobListingId: string
      jobListingTitle: string
      lastUpdatedStatusAt: string
      location: string
      milestoneType?: NameEnum | null
      requisitionId: string
      internalRequisitionId: number
      requisitionJobName: string
      stageName: string
      stages: Array<{ __typename?: 'CandidateStatsOpportunityStage'; id: number; name: string }>
    }
  } | null
}

export type GetCompanyUsersUploadCandidateQueryVariables = Exact<{ [key: string]: never }>

export type GetCompanyUsersUploadCandidateQuery = {
  __typename?: 'Query'
  GetCompanyUsers?: Array<{
    __typename?: 'CompanyUser'
    id: string
    companyUserStatus?: CompanyUserStatusEnum | null
    UserInvitation?: {
      __typename?: 'UserInvitation'
      id: number
      ScalisUser?: {
        __typename?: 'ScalisUser'
        id: number
        firstName?: string | null
        lastName?: string | null
        activeStatus?: boolean | null
      } | null
    } | null
  }> | null
}

export type GetOneCandidateProfileQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetOneCandidateProfileQuery = {
  __typename?: 'Query'
  GetOneCandidateProfile?: {
    __typename?: 'CandidateProfile'
    id: string
    profileType?: string | null
    uploadedCandidateId?: number | null
    scalisUserId?: number | null
    ScalisUser?: {
      __typename?: 'ScalisUser'
      id: number
      firstName?: string | null
      lastName?: string | null
      profileImage?: string | null
      currentJob?: Array<{
        __typename?: 'Experience'
        id: number
        employerName?: string | null
        isCurrentlyWorking?: boolean | null
        isCurrentJob?: boolean | null
        jobTitle?: string | null
      }> | null
      highestEducation?: Array<{
        __typename?: 'Education'
        id: number
        educationLevel?: EducationLevelEnum | null
        institutionName?: string | null
      }> | null
      WebsiteOrSocialMedia?: Array<{
        __typename?: 'WebsiteOrSocialMedia'
        type: WebsiteTypeEnum
        websiteURL: string
        id: number
      }> | null
      ContactInfo?: Array<{ __typename?: 'ContactInfo'; id: number; phoneNumber?: string | null }> | null
      EmailVerification?: Array<{
        __typename?: 'EmailVerification'
        id: number
        emailAddress?: string | null
        isVisibleToEmployers?: boolean | null
        isPrimary?: boolean | null
        activeStatus?: boolean | null
      }> | null
      JobSeekerHeader?: Array<{
        __typename?: 'JobSeekerHeader'
        id: number
        profileHeadline?: string | null
        jobDisplayedId?: number | null
        educationDisplayedId?: number | null
      }> | null
      Education?: Array<{
        __typename?: 'Education'
        id: number
        institutionName?: string | null
        educationLevel?: EducationLevelEnum | null
        areasOfStudy?: any | null
        minors?: any | null
        majors?: any | null
        endDate?: any | null
        startDate?: any | null
        cumGPA?: number | null
        cumGPAScale?: number | null
      }> | null
      Experience?: Array<{
        __typename?: 'Experience'
        id: number
        jobTitle?: string | null
        employerName?: string | null
        longFormDesc?: string | null
        startDate?: any | null
        endDate?: any | null
        jobType?: JobTypeEnum | null
        location?: string | null
        locationType?: string | null
        description?: string | null
      }> | null
      JobSeekerInfo?: { __typename?: 'JobSeekerInfo'; aboutMe?: string | null; id: number } | null
      VolunteerWork?: Array<{
        __typename?: 'VolunteerWork'
        id: number
        volunteerJobTitle?: string | null
        organizationName?: string | null
        startDate?: any | null
        endDate?: any | null
        isCurrentlyWorking?: boolean | null
        description?: any | null
        location?: string | null
      }> | null
      DemographicInfo?: Array<{
        __typename?: 'DemographicInfo'
        workHistory?: boolean | null
        veteranStatus?: VeteranStatusEnum | null
        raceEthnicity?: string | null
        id: number
        gender?: GenderEnum | null
      }> | null
      JobSeekerSkill?: Array<{
        __typename?: 'JobSeekerSkills'
        id: number
        level?: string | null
        name?: string | null
        sequenceNumber?: number | null
        yearsOfExp?: number | null
      }> | null
      CertificateOrLicense?: Array<{
        __typename?: 'CertificateOrLicense'
        id: number
        issuingAuthority?: string | null
        name?: string | null
        description?: any | null
        issueYear?: number | null
      }> | null
      Achievement?: Array<{
        __typename?: 'Achievement'
        id: number
        issuingAuthority?: string | null
        description?: any | null
        issueYear?: number | null
        name?: string | null
      }> | null
      Address?: Array<{ __typename?: 'Address'; id: number; city?: string | null; state?: string | null }> | null
    } | null
    ProfileDetails: {
      __typename?: 'ProfileDetails'
      id: string
      secondaryEmail?: string | null
      phoneNumber?: string | null
      profileLink?: string | null
      timezone?: string | null
      CandidateSource: Array<{
        __typename?: 'CandidateSource'
        id: number
        candidateSourceName?: {
          __typename?: 'CandidateSourceName'
          name: string
          sourceCategory?: SourceCategoryEnum | null
        } | null
        companyUser?: { __typename?: 'CompanyUser'; id: string } | null
        jobListing?: {
          __typename?: 'JobListing'
          id: number
          externalTitle?: string | null
          Requisition?: { __typename?: 'Requisition'; extRequisitionId?: string | null; jobName?: string | null } | null
        } | null
      }>
      CandidateProfileLocation: Array<{
        __typename?: 'CandidateProfileLocation'
        id: number
        city?: string | null
        state?: string | null
      }>
      CandidateMedalistDesignation: Array<{
        __typename?: 'CandidateMedalistDesignation'
        id: number
        medalistDesignation?: string | null
        jobListing?: { __typename?: 'JobListing'; id: number; externalTitle?: string | null } | null
      }>
    }
    CandidateApplication: Array<{
      __typename?: 'CandidateApplication'
      id: string
      applicationStatus?: ApplicationStatusEnum | null
      appliedAt?: any | null
      lastUpdatedStatusAt?: any | null
      JobListing?: {
        __typename?: 'JobListing'
        id: number
        publicListingId?: string | null
        externalTitle?: string | null
        listingTitle?: string | null
        workplaceType?: WorkplaceTypeEnum | null
        stateAndOrCountry?: string | null
        Address?: Array<{
          __typename?: 'Address'
          id: number
          city?: string | null
          state?: string | null
          country?: string | null
        }> | null
        Requisition?: {
          __typename?: 'Requisition'
          id: string
          extRequisitionId?: string | null
          jobName?: string | null
        } | null
      } | null
      TemplateStage?: {
        __typename?: 'TemplateStage'
        id: string
        name?: string | null
        TemplateMilestone?: { __typename?: 'TemplateMilestone'; id: string; name?: NameEnum | null } | null
        TemplatePipeline?: {
          __typename?: 'TemplatePipeline'
          id: string
          TemplateMilestones?: Array<{
            __typename?: 'TemplateMilestone'
            id: string
            rankOrder?: number | null
            TemplateStage?: Array<{
              __typename?: 'TemplateStage'
              id: string
              rankOrder?: number | null
              name?: string | null
            }> | null
          }> | null
        } | null
      } | null
      CandidateApplicationRating?: Array<{
        __typename?: 'CandidateApplicationRating'
        id: string
        score?: number | null
      }> | null
      UploadedDocumentCandidateApplication?: Array<{
        __typename?: 'UploadedDocumentCandidateApplication'
        id: string
        updatedAt?: any | null
        UploadedDocument: {
          __typename?: 'UploadedDocument'
          id: number
          documentPath?: string | null
          documentName?: string | null
          createdAt?: any | null
        }
        CompanyUser?: {
          __typename?: 'CompanyUser'
          id: string
          profileImage?: string | null
          UserInvitation?: {
            __typename?: 'UserInvitation'
            id: number
            ScalisUser?: {
              __typename?: 'ScalisUser'
              id: number
              firstName?: string | null
              lastName?: string | null
            } | null
          } | null
        } | null
      }> | null
      CandidateApplicationNote?: Array<{
        __typename?: 'CandidateApplicationNote'
        id: number
        privateStatus?: boolean | null
        userNote: { __typename?: 'UserNote'; id: string; noteText?: any | null; updatedAt?: any | null }
        companyUser?: {
          __typename?: 'CompanyUser'
          id: string
          profileImage?: string | null
          UserInvitation?: {
            __typename?: 'UserInvitation'
            id: number
            ScalisUser?: {
              __typename?: 'ScalisUser'
              id: number
              firstName?: string | null
              lastName?: string | null
            } | null
          } | null
        } | null
        candidateApplication?: {
          __typename?: 'CandidateApplication'
          id: string
          JobListing?: { __typename?: 'JobListing'; id: number; publicListingId?: string | null } | null
        } | null
      }> | null
      ActivityLog?: Array<{
        __typename?: 'ActivityLog'
        id: string
        activityTimestamp?: any | null
        activity?: any | null
        userAction?: UserActionEnum | null
      }> | null
    }>
    CandidateApplicationRating: Array<{ __typename?: 'CandidateApplicationRating'; id: string; score?: number | null }>
    UploadedDocumentCandidateProfile: Array<{
      __typename?: 'UploadedDocumentCandidateProfile'
      id: string
      updatedAt?: any | null
      UploadedDocument: {
        __typename?: 'UploadedDocument'
        id: number
        documentPath?: string | null
        documentName?: string | null
        createdAt?: any | null
      }
      CompanyUser: {
        __typename?: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          id: number
          ScalisUser?: {
            __typename?: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
          } | null
        } | null
      }
    }>
    CandidateProfileNote: Array<{
      __typename?: 'CandidateProfileNote'
      id: number
      privateStatus?: boolean | null
      userNote: { __typename?: 'UserNote'; id: string; noteText?: any | null; updatedAt?: any | null }
      companyUser?: {
        __typename?: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          id: number
          ScalisUser?: {
            __typename?: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
          } | null
        } | null
      } | null
    }>
    UploadedCandidate?: {
      __typename?: 'UploadedCandidate'
      linkedinProfile?: string | null
      lastName?: string | null
      id: number
      firstName?: string | null
      emailAddress?: string | null
    } | null
  } | null
}

export type GetTalentPoolQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  applicationFilters?: InputMaybe<ApplicationFiltersOnCandidateProfile>
}>

export type GetTalentPoolQuery = {
  __typename?: 'Query'
  GetCandidateProfile?: Array<{
    __typename?: 'CandidateProfile'
    id: string
    ScalisUser?: {
      __typename?: 'ScalisUser'
      id: number
      firstName?: string | null
      lastName?: string | null
      profileImage?: string | null
      mostRecentJob?: Array<{
        __typename?: 'Experience'
        id: number
        industry?: IndustryEnum | null
        jobTitle?: string | null
        employerName?: string | null
      }> | null
      currentJob?: Array<{
        __typename?: 'Experience'
        id: number
        jobTitle?: string | null
        employerName?: string | null
      }> | null
      highestEducation?: Array<{
        __typename?: 'Education'
        id: number
        educationLevel?: EducationLevelEnum | null
        institutionName?: string | null
      }> | null
      Address?: Array<{
        __typename?: 'Address'
        country?: string | null
        city?: string | null
        state?: string | null
      }> | null
      EmailVerification?: Array<{
        __typename?: 'EmailVerification'
        emailAddress?: string | null
        isVisibleToEmployers?: boolean | null
      }> | null
      JobSeekerInfo?: { __typename?: 'JobSeekerInfo'; jobSeekerId?: number | null } | null
      ExperienceTenure?: { __typename?: 'experienceTenure'; average: number; longest: number; shortest: number } | null
    } | null
    UploadedCandidate?: {
      __typename?: 'UploadedCandidate'
      emailAddress?: string | null
      firstName?: string | null
      id: number
      lastName?: string | null
    } | null
    ProfileDetails: {
      __typename?: 'ProfileDetails'
      phoneNumber?: string | null
      CandidateSource: Array<{
        __typename?: 'CandidateSource'
        candidateSourceName?: {
          __typename?: 'CandidateSourceName'
          name: string
          sourceCategory?: SourceCategoryEnum | null
        } | null
      }>
    }
    CandidateApplication: Array<{
      __typename?: 'CandidateApplication'
      id: string
      matchScore?: number | null
      templateStageId?: number | null
      applicationType?: string | null
      updatedAt?: any | null
      lastUpdatedStatusAt?: any | null
      applicationStatus?: ApplicationStatusEnum | null
      applicationCreationType?: string | null
      appliedAt?: any | null
      JobListing?: { __typename?: 'JobListing'; publicListingId?: string | null; id: number } | null
    }>
  }> | null
  CountCandidateProfile?: { __typename?: 'countOutput'; count: number } | null
}

export type GetCandidateSourceNamesQueryVariables = Exact<{
  input: CandidateSourceNameInput
}>

export type GetCandidateSourceNamesQuery = {
  __typename?: 'Query'
  GetCandidateSourceNames?: Array<{
    __typename?: 'CandidateSourceName'
    name: string
    id: number
    sourceCategory?: SourceCategoryEnum | null
  }> | null
}

export type ListCompanyUserGroupsQueryVariables = Exact<{
  input: ListCompanyUserGroupsInput
}>

export type ListCompanyUserGroupsQuery = {
  __typename?: 'Query'
  ListCompanyUserGroups: Array<{
    __typename?: 'CompanyUserGroup'
    id: string
    name: string
    externalId?: string | null
    createdAt: any
    updatedAt: any
    deleted: boolean
    createdBy: {
      __typename?: 'CompanyUser'
      id: string
      profileImage?: string | null
      UserInvitation?: {
        __typename?: 'UserInvitation'
        ScalisUser?: {
          __typename?: 'ScalisUser'
          firstName?: string | null
          lastName?: string | null
          EmailVerification?: Array<{ __typename?: 'EmailVerification'; emailAddress?: string | null }> | null
        } | null
      } | null
    }
    Offices: Array<{
      __typename?: 'CompanyUserGroupCompanyOffice'
      CompanyOffice: {
        __typename?: 'CompanyOffice'
        id: string
        officeName?: string | null
        externalId?: string | null
      }
    }>
    Departments: Array<{
      __typename?: 'CompanyUserGroupCompanyDepartment'
      CompanyDepartment: {
        __typename?: 'CompanyDepartment'
        id: string
        departmentName?: string | null
        externalId?: string | null
      }
    }>
    Members: Array<{
      __typename?: 'CompanyUserGroupCompanyUser'
      CompanyUser: {
        __typename?: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          ScalisUser?: {
            __typename?: 'ScalisUser'
            firstName?: string | null
            lastName?: string | null
            EmailVerification?: Array<{ __typename?: 'EmailVerification'; emailAddress?: string | null }> | null
          } | null
        } | null
      }
    }>
  }>
}

export type GetInterviewerCalendarsQueryVariables = Exact<{ [key: string]: never }>

export type GetInterviewerCalendarsQuery = {
  __typename?: 'Query'
  GetCalendarSettings?: {
    __typename?: 'CalendarSettings'
    id: string
    timeZone?: string | null
    provider: string
    email: string
    companyUserId: number
    Calendars?: Array<{
      __typename?: 'Calendar'
      id: string
      nylasCalendarId: string
      name: string
      isPrimary: boolean
      color?: string | null
      calendarSettingsId: number
    }> | null
  } | null
  GetCompanyUsers?: Array<{
    __typename?: 'CompanyUser'
    id: string
    profileImage?: string | null
    companyId: number
    UserInvitation?: {
      __typename?: 'UserInvitation'
      id: number
      email?: string | null
      ScalisUser?: { __typename?: 'ScalisUser'; firstName?: string | null; id: number; lastName?: string | null } | null
    } | null
    CalendarSettings?: {
      __typename?: 'CalendarSettings'
      id: string
      availability?: any | null
      provider: string
      timeZone?: string | null
      hourDisplay: number
      email: string
      Calendars?: Array<{
        __typename?: 'Calendar'
        id: string
        nylasCalendarId: string
        calendarSettingsId: number
        name: string
        isPrimary: boolean
        color?: string | null
        CalendarEvents?: Array<{
          __typename?: 'CalendarEvent'
          id: string
          nylasEventStatus?: NylasEventStatusesEnum | null
          nylasCalendarEventId?: string | null
          title?: string | null
          eventType?: EventTypeEnum | null
          description?: string | null
          startTime?: any | null
          endTime?: any | null
          timeZone?: string | null
          location?: string | null
          repeats?: RepeatsEnum | null
          reminders?: any | null
          employers?: any | null
          conferencing?: any | null
          masterEventId?: string | null
          regardingCandidates?: any | null
          regardingJobs?: any | null
          internalGuests?: any | null
          candidates?: any | null
          isScalisEvent: boolean
          CandidateInterview?: {
            __typename?: 'CandidateInterview'
            stageId?: number | null
            status?: CandidateInterviewStatusEnum | null
            TemplateStage?: {
              __typename?: 'TemplateStage'
              TemplateMilestone?: { __typename?: 'TemplateMilestone'; name?: NameEnum | null } | null
            } | null
          } | null
        }> | null
      }> | null
    } | null
  }> | null
}

export type GetStageCompanyUsersQueryVariables = Exact<{ [key: string]: never }>

export type GetStageCompanyUsersQuery = {
  __typename?: 'Query'
  GetCompanyUsers?: Array<{
    __typename: 'CompanyUser'
    id: string
    companyId: number
    profileImage?: string | null
    UserInvitation?: {
      __typename: 'UserInvitation'
      id: number
      email?: string | null
      ScalisUser?: { __typename: 'ScalisUser'; id: number; firstName?: string | null; lastName?: string | null } | null
    } | null
  }> | null
}

export type GetCompanyDepartmentsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCompanyDepartmentsQuery = {
  __typename?: 'Query'
  GetCompanyInfo?: Array<{
    __typename?: 'Company'
    CompanyDepartment: Array<{
      __typename?: 'CompanyDepartment'
      type?: CompanyDepartmentTypeEnum | null
      subordinateTo?: number | null
      id: string
      externalId?: string | null
      departmentName?: string | null
      ParentCompanyDepartment?: { __typename?: 'CompanyDepartment'; departmentName?: string | null; id: string } | null
    }>
  }> | null
}

export type GetCompanyInfoSettingsFormQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCompanyInfoSettingsFormQuery = {
  __typename?: 'Query'
  GetCompanyInfo?: Array<{
    __typename?: 'Company'
    subdomain?: string | null
    id: string
    CompanyInfo?: {
      __typename?: 'CompanyInfo'
      logo?: string | null
      companyType?: string | null
      companyName?: string | null
      industry?: IndustryEnum | null
      subIndustry?: SubIndustryTypeEnum | null
      phoneNumber?: string | null
      website?: string | null
      linkedin?: string | null
      linkedinId?: string | null
      employeeCount?: EmployeeCountEnum | null
      yearsInOperation?: number | null
    } | null
    Address: Array<{
      __typename?: 'Address'
      id: number
      activeStatus?: boolean | null
      addressLine1?: string | null
      addressLine2?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      postalCode?: string | null
    }>
  }> | null
}

export type GetCompanyOfficesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCompanyOfficesQuery = {
  __typename?: 'Query'
  GetCompanyInfo?: Array<{
    __typename?: 'Company'
    CompanyOffice: Array<{
      __typename?: 'CompanyOffice'
      externalId?: string | null
      officeName?: string | null
      id: string
      Address?: {
        __typename?: 'Address'
        activeStatus?: boolean | null
        addressLine1?: string | null
        addressLine2?: string | null
        addressType?: string | null
        city?: string | null
        country?: string | null
        timeZone?: string | null
        state?: string | null
        postalCode?: string | null
        id: number
      } | null
    }>
  }> | null
}

export type GetCompanyUsersCandidateProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetCompanyUsersCandidateProfileQuery = {
  __typename?: 'Query'
  GetCompanyUsers?: Array<{
    __typename?: 'CompanyUser'
    id: string
    companyUserStatus?: CompanyUserStatusEnum | null
    UserInvitation?: {
      __typename?: 'UserInvitation'
      id: number
      ScalisUser?: {
        __typename?: 'ScalisUser'
        id: number
        firstName?: string | null
        lastName?: string | null
        activeStatus?: boolean | null
      } | null
    } | null
  }> | null
}

export type GetCompanyUsersQueryVariables = Exact<{ [key: string]: never }>

export type GetCompanyUsersQuery = {
  __typename?: 'Query'
  GetCompanyUsers?: Array<{
    __typename?: 'CompanyUser'
    id: string
    companyId: number
    companyUserStatus?: CompanyUserStatusEnum | null
    profileImage?: string | null
    UserRoles?: Array<{
      __typename?: 'UserRoles'
      roleId: number
      userId: number
      role: { __typename?: 'Role'; id: string; name: string }
    }> | null
    UserInvitation?: {
      __typename?: 'UserInvitation'
      id: number
      email?: string | null
      invitedAs?: RoleEnum | null
      invitationStatus?: InvitationStatusEnum | null
      ScalisUser?: {
        __typename?: 'ScalisUser'
        id: number
        firstName?: string | null
        lastName?: string | null
        activeStatus?: boolean | null
        profileImage?: string | null
      } | null
    } | null
  }> | null
}

export type GetCompanyWithSubdomainQueryVariables = Exact<{
  subdomain?: InputMaybe<Scalars['String']['input']>
}>

export type GetCompanyWithSubdomainQuery = {
  __typename?: 'Query'
  GetCompanyInfo?: Array<{
    __typename?: 'Company'
    id: string
    subdomain?: string | null
    CompanyInfo?: { __typename?: 'CompanyInfo'; id: string; logo?: string | null; companyName?: string | null } | null
  }> | null
}

export type GetUserInvitationsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserInvitationsQuery = {
  __typename?: 'Query'
  GetUserInvitations?: Array<{
    __typename?: 'UserInvitation'
    scalisUserId?: number | null
    invitedAs?: RoleEnum | null
    invitationStatus?: InvitationStatusEnum | null
    id: number
    email?: string | null
    deletedAt?: any | null
    companyId?: number | null
    CompanyUser?: {
      __typename?: 'CompanyUser'
      companyId: number
      companyUserStatus?: CompanyUserStatusEnum | null
      id: string
      profileImage?: string | null
      userInvitationId?: number | null
      UserRoles?: Array<{
        __typename?: 'UserRoles'
        userId: number
        roleId: number
        role: { __typename?: 'Role'; id: string; name: string }
      }> | null
    } | null
    ScalisUser?: { __typename?: 'ScalisUser'; firstName?: string | null; lastName?: string | null } | null
  }> | null
}

export type ListCompanyDepartmentsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type ListCompanyDepartmentsQuery = {
  __typename?: 'Query'
  ListCompanyDepartments?: Array<{
    __typename?: 'CompanyDepartment'
    id: string
    companyId?: number | null
    departmentName?: string | null
    subordinateTo?: number | null
    externalId?: string | null
    createdAt?: any | null
    updatedAt?: any | null
  }> | null
}

export type ListCompanyOfficesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type ListCompanyOfficesQuery = {
  __typename?: 'Query'
  ListCompanyOffices?: Array<{
    __typename?: 'CompanyOffice'
    id: string
    companyId?: number | null
    officeName?: string | null
    externalId?: string | null
    addressId?: number | null
    createdAt?: any | null
    updatedAt?: any | null
    Address?: {
      __typename?: 'Address'
      id: number
      addressLine1?: string | null
      addressLine2?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      postalCode?: string | null
      timeZone?: string | null
    } | null
  }> | null
}

export type DeleteOfferCustomFieldMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DeleteOfferCustomFieldMutation = {
  __typename?: 'Mutation'
  DeleteOfferCustomField: { __typename?: 'OfferCustomField'; id: number }
}

export type GetOfferCustomFieldQueryVariables = Exact<{ [key: string]: never }>

export type GetOfferCustomFieldQuery = {
  __typename?: 'Query'
  GetOfferCustomField?: Array<{
    __typename?: 'OfferCustomField'
    id: number
    fieldConfigurationType: CustomFieldInputTypeEnum
    fieldName: string
    fieldDescription?: string | null
    generateSystemToken: boolean
    systemTokenName?: string | null
    fieldRequired: boolean
    triggerNewOfferVersion: boolean
    multipleChoiceOptions?: any | null
    updatedAt?: any | null
    CompanyUser?: {
      __typename?: 'CompanyUser'
      id: string
      UserInvitation?: {
        __typename?: 'UserInvitation'
        id: number
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
          profileImage?: string | null
        } | null
      } | null
    } | null
    CandidateOfferCustomField?: Array<{
      __typename?: 'CandidateOfferCustomField'
      id: number
      fieldValue?: any | null
    }> | null
    OfferTemplateCondition?: Array<{
      __typename?: 'OfferTemplateCondition'
      id: string
      officeId?: number | null
      payType?: PayTypeEnum | null
      employmentType?: JobTypeEnum | null
      departmentId?: number | null
    }> | null
  }> | null
}

export type OfferCustomFieldFragment = {
  __typename?: 'OfferCustomField'
  id: number
  fieldConfigurationType: CustomFieldInputTypeEnum
  fieldName: string
  fieldDescription?: string | null
  generateSystemToken: boolean
  systemTokenName?: string | null
  fieldRequired: boolean
  triggerNewOfferVersion: boolean
  multipleChoiceOptions?: any | null
  updatedAt?: any | null
  CandidateOfferCustomField?: Array<{
    __typename?: 'CandidateOfferCustomField'
    id: number
    fieldValue?: any | null
  }> | null
  OfferTemplateCondition?: Array<{
    __typename?: 'OfferTemplateCondition'
    id: string
    officeId?: number | null
    payType?: PayTypeEnum | null
    employmentType?: JobTypeEnum | null
    departmentId?: number | null
  }> | null
}

export type UpsertOfferCustomFieldMutationVariables = Exact<{
  input: UpsertOfferCustomFieldInput
}>

export type UpsertOfferCustomFieldMutation = {
  __typename?: 'Mutation'
  UpsertOfferCustomField?: {
    __typename?: 'OfferCustomField'
    id: number
    fieldConfigurationType: CustomFieldInputTypeEnum
    fieldName: string
    fieldDescription?: string | null
    generateSystemToken: boolean
    systemTokenName?: string | null
    fieldRequired: boolean
    triggerNewOfferVersion: boolean
    multipleChoiceOptions?: any | null
    updatedAt?: any | null
    CandidateOfferCustomField?: Array<{
      __typename?: 'CandidateOfferCustomField'
      id: number
      fieldValue?: any | null
    }> | null
    OfferTemplateCondition?: Array<{
      __typename?: 'OfferTemplateCondition'
      id: string
      officeId?: number | null
      payType?: PayTypeEnum | null
      employmentType?: JobTypeEnum | null
      departmentId?: number | null
    }> | null
  } | null
}

export type UpsertOfferTemplateConditionMutationVariables = Exact<{
  input: Array<UpsertOfferTemplateConditionInput> | UpsertOfferTemplateConditionInput
}>

export type UpsertOfferTemplateConditionMutation = {
  __typename?: 'Mutation'
  UpsertOfferTemplateCondition?: Array<{
    __typename?: 'OfferTemplateCondition'
    id: string
    conditionType: ConditionTypeEnum
    customFieldId?: number | null
    departmentId?: number | null
    employmentType?: JobTypeEnum | null
    offerDocumentTemplateId?: number | null
    officeId?: number | null
    payType?: PayTypeEnum | null
    sectionTemplateId?: number | null
  } | null> | null
}

export type CreateDocumentTemplateMutationVariables = Exact<{
  input: CreateDocumentTemplateInput
}>

export type CreateDocumentTemplateMutation = {
  __typename?: 'Mutation'
  CreateDocumentTemplate: { __typename?: 'OfferDocumentTemplate'; id: string }
}

export type DeleteOfferDocumentTemplateMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DeleteOfferDocumentTemplateMutation = {
  __typename?: 'Mutation'
  DeleteOfferDocumentTemplate: { __typename?: 'OfferDocumentTemplate'; id: string }
}

export type DownloadDocumentTemplateMutationVariables = Exact<{
  input: DownloadDocumentTemplateInput
}>

export type DownloadDocumentTemplateMutation = {
  __typename?: 'Mutation'
  DownloadDocumentTemplate: { __typename?: 'DownloadDocumentTemplateOutput'; temporaryUrl: string }
}

export type GenerateFilesFromHtmlMutationVariables = Exact<{
  html: Scalars['String']['input']
}>

export type GenerateFilesFromHtmlMutation = {
  __typename?: 'Mutation'
  GenerateFilesFromHtml?: { __typename?: 'GenerateFilesFromHtmlOutput'; pdfKey: string } | null
}

export type GetCompanyOfficesDepartmentsQueryVariables = Exact<{ [key: string]: never }>

export type GetCompanyOfficesDepartmentsQuery = {
  __typename?: 'Query'
  ListCompanyDepartments?: Array<{
    __typename?: 'CompanyDepartment'
    id: string
    departmentName?: string | null
  }> | null
  ListCompanyOffices?: Array<{ __typename?: 'CompanyOffice'; id: string; officeName?: string | null }> | null
}

export type GetDocumentTemplateConditionsQueryVariables = Exact<{
  documentTemplateId: Scalars['Int']['input']
}>

export type GetDocumentTemplateConditionsQuery = {
  __typename?: 'Query'
  GetOfferTemplateCondition?: Array<{
    __typename?: 'OfferTemplateCondition'
    id: string
    departmentId?: number | null
    employmentType?: JobTypeEnum | null
    payType?: PayTypeEnum | null
    officeId?: number | null
  }> | null
}

export type GetDefaultTokenQueryVariables = Exact<{ [key: string]: never }>

export type GetDefaultTokenQuery = {
  __typename?: 'Query'
  GetDefaultToken?: Array<{
    __typename?: 'DefaultToken'
    id: string
    categoryName: TokenCategoryNameEnum
    tokenName: TokenNameEnum
  }> | null
}

export type GetOfferDocumentTemplateQueryVariables = Exact<{
  input: GetOfferDocumentTemplateInput
}>

export type GetOfferDocumentTemplateQuery = {
  __typename?: 'Query'
  GetOfferDocumentTemplate?: Array<{
    __typename?: 'OfferDocumentTemplate'
    id: string
    updatedAt?: any | null
    documentTemplateName: string
    documentType: OfferDocumentTemplateTypeEnum
    documentTemplateStatus: OfferDocumentTemplateStatusEnum
    documentTemplateText: string
    documentFilePath?: string | null
    documentFileName?: string | null
    CompanyUser?: {
      __typename?: 'CompanyUser'
      id: string
      UserInvitation?: {
        __typename?: 'UserInvitation'
        id: number
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
          profileImage?: string | null
        } | null
      } | null
    } | null
  }> | null
}

export type UpdateDocumentTemplateMutationVariables = Exact<{
  input: UpdateDocumentTemplateInput
}>

export type UpdateDocumentTemplateMutation = {
  __typename?: 'Mutation'
  UpdateDocumentTemplate: {
    __typename?: 'OfferDocumentTemplate'
    id: string
    documentTemplateName: string
    documentTemplateText: string
    documentTemplateStatus: OfferDocumentTemplateStatusEnum
    documentType: OfferDocumentTemplateTypeEnum
  }
}

export type ValidateDocumentTemplateMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ValidateDocumentTemplateMutation = {
  __typename?: 'Mutation'
  ValidateOfferDocumentTemplate: {
    __typename?: 'OfferDocumentTemplate'
    id: string
    documentTemplateStatus: OfferDocumentTemplateStatusEnum
    validationResult?: any | null
  }
}

export type GetGlobalListingSetupQueryVariables = Exact<{
  companyId: Scalars['Int']['input']
}>

export type GetGlobalListingSetupQuery = {
  __typename?: 'Query'
  getGlobalListingSetup?: {
    __typename?: 'GlobalListingSetup'
    id: number
    aboutCompanyStatement?: any | null
    footerStatement?: any | null
    companyId: number
    createdAt: any
    isAboutCompanyActive: boolean
    isFooterStatementActive: boolean
    updatedAt: any
  } | null
}

export type GetGlobalScreeningQuestionsQueryVariables = Exact<{
  input: GetGlobalScreeningQuestionInput
}>

export type GetGlobalScreeningQuestionsQuery = {
  __typename?: 'Query'
  GetGlobalScreeningQuestions?: Array<{
    __typename?: 'ScreeningQuestion'
    id: string
    questionTitle?: string | null
    questionDesc?: string | null
    questionType?: QuestionTypeEnum | null
    ansOptions?: string | null
    isRejectionQuestion?: boolean | null
    rejectMessage?: string | null
    preferredAnswer?: string | null
    isActiveGlobally?: boolean | null
    globalQuestionOrder?: number | null
    createdAt?: any | null
    updatedAt?: any | null
  }> | null
}

export type ListGlobalScreeningQuestionsQueryVariables = Exact<{
  input: ListGlobalScreeningQuestionInput
}>

export type ListGlobalScreeningQuestionsQuery = {
  __typename?: 'Query'
  ListGlobalScreeningQuestions?: Array<{
    __typename?: 'ScreeningQuestion'
    id: string
    questionTitle?: string | null
    questionDesc?: string | null
    questionType?: QuestionTypeEnum | null
    ansOptions?: string | null
    minVal?: number | null
    maxVal?: number | null
    valStatus?: string | null
    isRejectionQuestion?: boolean | null
    rejectMessage?: string | null
    preferredAnswer?: string | null
    isActiveGlobally?: boolean | null
    globalQuestionOrder?: number | null
    createdAt?: any | null
    updatedAt?: any | null
  }> | null
}

export type GetHiringTeamMemberQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetHiringTeamMemberQuery = {
  __typename?: 'Query'
  GetHiringTeamMember?: {
    __typename?: 'HiringTeamMember'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    companyUserId?: number | null
    roleId?: number | null
    requisitionId?: number | null
    requisitionJobDescriptionId?: number | null
    role?: { __typename?: 'Role'; id: string; name: string } | null
  } | null
}

export type GetCampaignJobFunctionsQueryVariables = Exact<{ [key: string]: never }>

export type GetCampaignJobFunctionsQuery = {
  __typename?: 'Query'
  CampaignJobFunctions?: Array<{ __typename?: 'JobFunction'; name: string; id: string }> | null
}

export type GetJobCampaignEducationLevelsQueryVariables = Exact<{ [key: string]: never }>

export type GetJobCampaignEducationLevelsQuery = {
  __typename?: 'Query'
  GetJobCampaignEducationLevels?: Array<{
    __typename?: 'JobCampaignEducationLevel'
    id: number
    name: Array<{ __typename?: 'educationLevel'; languageCode: string; value: string }>
  }> | null
}

export type GetJobCampaignIndustriesQueryVariables = Exact<{ [key: string]: never }>

export type GetJobCampaignIndustriesQuery = {
  __typename?: 'Query'
  JobCampaignIndustries?: Array<{ __typename?: 'JobCampaignIndustry'; id: number; name: string }> | null
}

export type SearchJobCampaignLocationsQueryVariables = Exact<{
  input: SearchJobCampaignLocationsInput
}>

export type SearchJobCampaignLocationsQuery = {
  __typename?: 'Query'
  SearchJobCampaignLocations?: Array<{
    __typename?: 'JobCampaignLocation'
    id: number
    fully_qualified_place_name?: string | null
  }> | null
}

export type GetJobCampaignProductsByIdQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type GetJobCampaignProductsByIdQuery = {
  __typename?: 'Query'
  GetJobCampaignProductsById?: {
    __typename?: 'JobCampaignProducts'
    results?: Array<{
      __typename?: 'JobCampaignProduct'
      product_id: string
      audience_group: string
      title: string
      description: string
      homepage: string
      cross_postings: Array<string>
      duration: { __typename?: 'Duration'; period: number; range: string }
      industries: Array<{ __typename?: 'JobCampaignIndustry'; id: number; name: string }>
      job_functions: Array<{ __typename?: 'JobFunction'; id: string; name: string }>
      locations: Array<{ __typename?: 'JobCampaignLocation'; canonical_name: string; id: number }>
      logo_rectangle_url?: Array<{ __typename?: 'Logo'; url: string }> | null
      vonq_price: Array<{ __typename?: 'Price'; amount: number; currency: string }>
      time_to_process: { __typename?: 'ProcessTime'; period: number; range: string }
    }> | null
  } | null
}

export type GetJobCampaignProductsQueryVariables = Exact<{
  input?: InputMaybe<ProductFiltersInput>
}>

export type GetJobCampaignProductsQuery = {
  __typename?: 'Query'
  GetJobCampaignProducts?: {
    __typename?: 'JobCampaignProducts'
    count: number
    results?: Array<{
      __typename?: 'JobCampaignProduct'
      product_id: string
      audience_group: string
      title: string
      description: string
      homepage: string
      cross_postings: Array<string>
      duration: { __typename?: 'Duration'; period: number; range: string }
      industries: Array<{ __typename?: 'JobCampaignIndustry'; id: number; name: string }>
      job_functions: Array<{ __typename?: 'JobFunction'; id: string; name: string }>
      locations: Array<{ __typename?: 'JobCampaignLocation'; canonical_name: string; id: number }>
      logo_rectangle_url?: Array<{ __typename?: 'Logo'; url: string }> | null
      vonq_price: Array<{ __typename?: 'Price'; amount: number; currency: string }>
      time_to_process: { __typename?: 'ProcessTime'; period: number; range: string }
    }> | null
  } | null
}

export type GetJobCampaignSeniorityQueryVariables = Exact<{ [key: string]: never }>

export type GetJobCampaignSeniorityQuery = {
  __typename?: 'Query'
  GetJobCampaignSeniority: Array<{
    __typename?: 'JobCampaignSeniority'
    id: number
    name: Array<{ __typename?: 'SeniorityLevel'; languageCode: string; value: string }>
  }>
}

export type GetOrderQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetOrderQuery = {
  __typename?: 'Query'
  GetOrders: Array<{
    __typename?: 'Order'
    Campaigns?: {
      __typename?: 'JobCampaignResponse'
      data: Array<{
        __typename?: 'JobCampaign'
        campaignName: string
        orderedProducts: Array<string>
        labels?: { __typename?: 'JobCampaignLabels'; jobListingId?: string | null } | null
        targetGroup?: {
          __typename?: 'TargetGroup'
          seniority: Array<{ __typename?: 'TargetGroupItem'; description: string; vonqId: string }>
          jobCategory: Array<{ __typename?: 'TargetGroupItem'; vonqId: string; description: string }>
          industry: Array<{ __typename?: 'TargetGroupItem'; vonqId: string; description: string }>
          educationLevel: Array<{ __typename?: 'TargetGroupItem'; vonqId: string; description: string }>
        } | null
      }>
    } | null
  }>
}

export type GetRequisitionOrdersQueryVariables = Exact<{
  requisitionId: Scalars['Int']['input']
}>

export type GetRequisitionOrdersQuery = {
  __typename?: 'Query'
  GetRequisition?: {
    __typename?: 'Requisition'
    id: string
    Orders?: Array<{
      __typename?: 'Order'
      id: number
      total: number
      applicantsCount?: any | null
      campaignTraffic?: any | null
      Campaigns?: {
        __typename?: 'JobCampaignResponse'
        data: Array<{
          __typename?: 'JobCampaign'
          campaignId: string
          campaignName: string
          createdOn: string
          status: CampaignStatuses
          viewsCount?: any | null
          postings: Array<{ __typename?: 'Posting'; clicks: number }>
          targetGroup?: {
            __typename?: 'TargetGroup'
            seniority: Array<{ __typename?: 'TargetGroupItem'; vonqId: string; description: string }>
            jobCategory: Array<{ __typename?: 'TargetGroupItem'; description: string; vonqId: string }>
            industry: Array<{ __typename?: 'TargetGroupItem'; description: string; vonqId: string }>
            educationLevel: Array<{ __typename?: 'TargetGroupItem'; description: string; vonqId: string }>
          } | null
          labels?: { __typename?: 'JobCampaignLabels'; jobListingId?: string | null } | null
        }>
      } | null
    }> | null
    jobListings?: Array<{
      __typename?: 'JobListing'
      id: number
      workplaceType?: WorkplaceTypeEnum | null
      listingTitle?: string | null
      publicListingId?: string | null
      stateAndOrCountry?: string | null
    }> | null
  } | null
}

export type GetIsCandidateAppliedQueryVariables = Exact<{
  publicListingId: Scalars['String']['input']
}>

export type GetIsCandidateAppliedQuery = {
  __typename?: 'Query'
  GetCandidateApplication?: Array<{ __typename?: 'CandidateApplication'; id: string }> | null
}

export type GetJobListingsPipelineQueryVariables = Exact<{
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetJobListingsPipelineQuery = {
  __typename?: 'Query'
  GetJobListing?: Array<{
    __typename?: 'JobListing'
    id: number
    externalTitle?: string | null
    publicListingId?: string | null
    JobListingJobPipeline?: Array<{
      __typename?: 'JobListingJobPipeline'
      id: string
      JobPipeline?: {
        __typename?: 'JobPipeline'
        id: string
        TemplatePipeline?: {
          __typename?: 'TemplatePipeline'
          id: string
          name?: string | null
          TemplateMilestones?: Array<{
            __typename?: 'TemplateMilestone'
            id: string
            name?: NameEnum | null
            rankOrder?: number | null
            TemplateStage?: Array<{
              __typename?: 'TemplateStage'
              rankOrder?: number | null
              name?: string | null
              id: string
              visibility?: TemplateStageVisibilityEnum | null
            }> | null
          }> | null
        } | null
      } | null
    }> | null
    Requisition?: { __typename?: 'Requisition'; id: string; jobName?: string | null } | null
  }> | null
}

export type GetJobListingQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetJobListingQuery = {
  __typename?: 'Query'
  GetJobListing?: Array<{
    __typename?: 'JobListing'
    id: number
    publicListingId?: string | null
    jobRequisitionId?: number | null
    listingTitle?: string | null
    externalTitle?: string | null
    workplaceType?: WorkplaceTypeEnum | null
    publicDescription?: string | null
    displayPayOnListing?: boolean | null
    industry?: IndustryEnum | null
    subIndustry?: SubIndustryTypeEnum | null
    companyDefault?: boolean | null
    stateAndOrCountry?: string | null
    internalMatchingDescription?: string | null
    searchPublicDescription?: string | null
    searchInternalMatchingDescription?: string | null
    postedAt?: any | null
    acceptingCandidates?: boolean | null
    stage?: StageEnum | null
    createdAt?: any | null
    updatedAt?: any | null
    Address?: Array<{
      __typename?: 'Address'
      id: number
      addressLine1?: string | null
      addressLine2?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      postalCode?: string | null
      timeZone?: string | null
    }> | null
    JobListingScreeningQuestion?: Array<{
      __typename?: 'JobListingScreeningQuestion'
      id: string
      jobListingId?: number | null
      displaySequenceNumber?: number | null
      screeningQuestionId?: number | null
      createdAt?: any | null
      updatedAt?: any | null
      ScreeningQuestion?: {
        __typename?: 'ScreeningQuestion'
        id: string
        isTemplateQuestion?: boolean | null
        questionTitle?: string | null
        questionDesc?: string | null
        questionType?: QuestionTypeEnum | null
        minVal?: number | null
        maxVal?: number | null
        valStatus?: string | null
        ansOptions?: string | null
        isRejectionQuestion?: boolean | null
        isGlobalQuestion?: boolean | null
        rejectMessage?: string | null
        templateMessageId?: number | null
        preferredAnswer?: string | null
        companyId?: number | null
        companyUserId?: number | null
        createdAt?: any | null
        updatedAt?: any | null
      } | null
    }> | null
  }> | null
}

export type GetJobListingsIdTitleQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetJobListingsIdTitleQuery = {
  __typename?: 'Query'
  GetJobListing?: Array<{ __typename: 'JobListing'; id: number; listingTitle?: string | null }> | null
}

export type GetJobPipelineQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  templatePipelineId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetJobPipelineQuery = {
  __typename?: 'Query'
  GetJobPipeline?: Array<{
    __typename?: 'JobPipeline'
    id: string
    title?: string | null
    jobRequisitionId?: number | null
    templatePipelineId?: number | null
    TemplatePipeline?: {
      __typename?: 'TemplatePipeline'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      companyId?: number | null
      companyUserId?: number | null
      name?: string | null
      companyDepartmentId?: number | null
      archived?: boolean | null
      TemplateMilestones?: Array<{
        __typename?: 'TemplateMilestone'
        createdAt?: any | null
        updatedAt?: any | null
        id: string
        templatePipelineId?: number | null
        name?: NameEnum | null
        rankOrder?: number | null
        archived?: boolean | null
      }> | null
      TemplateStage?: Array<{
        __typename?: 'TemplateStage'
        createdAt?: any | null
        updatedAt?: any | null
        id: string
        templatePipelineId?: number | null
        visibility?: TemplateStageVisibilityEnum | null
        name?: string | null
        templateMilestoneId?: number | null
        rankOrder?: number | null
        archived?: boolean | null
      }> | null
    } | null
    StageSettings?: Array<{
      __typename?: 'StageSettings'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      jobPipelineId?: number | null
      templateStageId?: number | null
      scorecardTemplateId?: number | null
    }> | null
  }> | null
}

export type CountCandidateApplicationQueryVariables = Exact<{
  publicListingIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  templateStageIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  matchScore?: InputMaybe<MatchScore>
  candidateProfileId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  skills?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  lastActivity?: InputMaybe<LastActivity>
  applicationStatus?: InputMaybe<Array<ApplicationStatusEnum> | ApplicationStatusEnum>
  applicationStatusNotIn?: InputMaybe<Array<ApplicationStatusEnum> | ApplicationStatusEnum>
  applicationType?: InputMaybe<Array<ApplicationTypeEnum> | ApplicationTypeEnum>
  sourceName?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  medalistDesignation?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  creditedCompanyUserIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  companies?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  educationLevel?: InputMaybe<Array<EducationLevelEnum> | EducationLevelEnum>
  jobTitles?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  majors?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  nonTraditionalStatus?: InputMaybe<Scalars['Boolean']['input']>
  schools?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  timezones?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  veteranStatus?: InputMaybe<Array<VeteranStatusEnum> | VeteranStatusEnum>
  willingToRelocate?: InputMaybe<Array<WillingToRelocateEnum> | WillingToRelocateEnum>
  yearsOfExperience?: InputMaybe<YearsOfExperience>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  profileType?: InputMaybe<ProfileTypeEnum>
  search?: InputMaybe<Scalars['String']['input']>
}>

export type CountCandidateApplicationQuery = {
  __typename?: 'Query'
  CountCandidateApplication?: { __typename?: 'countOutput'; count: number } | null
}

export type GetCandidateApplicationQueryVariables = Exact<{
  publicListingIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  templateStageIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  matchScore?: InputMaybe<MatchScore>
  candidateProfileId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  skills?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  lastActivity?: InputMaybe<LastActivity>
  applicationStatus?: InputMaybe<Array<ApplicationStatusEnum> | ApplicationStatusEnum>
  applicationStatusNotIn?: InputMaybe<Array<ApplicationStatusEnum> | ApplicationStatusEnum>
  applicationType?: InputMaybe<Array<ApplicationTypeEnum> | ApplicationTypeEnum>
  sourceName?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  medalistDesignation?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  creditedCompanyUserIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  companies?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  educationLevel?: InputMaybe<Array<EducationLevelEnum> | EducationLevelEnum>
  jobTitles?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  majors?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  nonTraditionalStatus?: InputMaybe<Scalars['Boolean']['input']>
  schools?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  timezones?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  veteranStatus?: InputMaybe<Array<VeteranStatusEnum> | VeteranStatusEnum>
  willingToRelocate?: InputMaybe<Array<WillingToRelocateEnum> | WillingToRelocateEnum>
  yearsOfExperience?: InputMaybe<YearsOfExperience>
  requisitionId?: InputMaybe<Scalars['Int']['input']>
  profileType?: InputMaybe<ProfileTypeEnum>
  search?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCandidateApplicationQuery = {
  __typename?: 'Query'
  GetCandidateApplication?: Array<{
    __typename: 'CandidateApplication'
    id: string
    matchScore?: number | null
    applicationStatus?: ApplicationStatusEnum | null
    applicationType?: string | null
    appliedAt?: any | null
    lastUpdatedStatusAt?: any | null
    templateStageId?: number | null
    jobListingId?: number | null
    CandidateInterview?: Array<{
      __typename?: 'CandidateInterview'
      id: string
      stageId?: number | null
      status?: CandidateInterviewStatusEnum | null
    }> | null
    CandidateProfile?: {
      __typename?: 'CandidateProfile'
      id: string
      ScalisUser?: {
        __typename?: 'ScalisUser'
        id: number
        firstName?: string | null
        lastName?: string | null
        profileImage?: string | null
        mostRecentJob?: Array<{
          __typename?: 'Experience'
          id: number
          industry?: IndustryEnum | null
          jobTitle?: string | null
          employerName?: string | null
        }> | null
        currentJob?: Array<{
          __typename?: 'Experience'
          id: number
          jobTitle?: string | null
          employerName?: string | null
        }> | null
        highestEducation?: Array<{
          __typename?: 'Education'
          id: number
          educationLevel?: EducationLevelEnum | null
          institutionName?: string | null
        }> | null
        Address?: Array<{
          __typename?: 'Address'
          country?: string | null
          city?: string | null
          state?: string | null
        }> | null
        EmailVerification?: Array<{
          __typename?: 'EmailVerification'
          emailAddress?: string | null
          isVisibleToEmployers?: boolean | null
        }> | null
        JobSeekerInfo?: { __typename?: 'JobSeekerInfo'; jobSeekerId?: number | null } | null
        ExperienceTenure?: {
          __typename?: 'experienceTenure'
          average: number
          longest: number
          shortest: number
        } | null
      } | null
      UploadedCandidate?: {
        __typename?: 'UploadedCandidate'
        emailAddress?: string | null
        firstName?: string | null
        id: number
        lastName?: string | null
      } | null
      ProfileDetails: {
        __typename?: 'ProfileDetails'
        phoneNumber?: string | null
        CandidateSource: Array<{
          __typename?: 'CandidateSource'
          candidateSourceName?: {
            __typename?: 'CandidateSourceName'
            name: string
            sourceCategory?: SourceCategoryEnum | null
          } | null
        }>
      }
    } | null
    JobListing?: { __typename?: 'JobListing'; publicListingId?: string | null } | null
    CandidateScorecard?: Array<{
      __typename?: 'CandidateScorecard'
      id: string
      status?: ScorecardStatusTypeEnum | null
      scorecardTemplateId?: number | null
    }> | null
  }> | null
}

export type CountCandidateApplicationsQueryVariables = Exact<{
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
}>

export type CountCandidateApplicationsQuery = {
  __typename?: 'Query'
  InterviewRequests?: { __typename?: 'countOutput'; count: number } | null
  ActiveApplications?: { __typename?: 'countOutput'; count: number } | null
  InterviewScheduled?: { __typename?: 'countOutput'; count: number } | null
  TotalApplications?: { __typename?: 'countOutput'; count: number } | null
}

export type DetailCandidateApplicationQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DetailCandidateApplicationQuery = {
  __typename?: 'Query'
  GetCandidateApplication?: Array<{
    __typename?: 'CandidateApplication'
    applicationStatus?: ApplicationStatusEnum | null
    candidateProfileId?: number | null
    jobListingId?: number | null
    templateStageId?: number | null
    CandidateProfile?: { __typename?: 'CandidateProfile'; scalisUserId?: number | null } | null
    JobListing?: {
      __typename?: 'JobListing'
      externalTitle?: string | null
      JobListingJobPipeline?: Array<{
        __typename?: 'JobListingJobPipeline'
        JobPipeline?: {
          __typename?: 'JobPipeline'
          title?: string | null
          TemplatePipeline?: {
            __typename?: 'TemplatePipeline'
            TemplateMilestones?: Array<{
              __typename?: 'TemplateMilestone'
              TemplateStage?: Array<{
                __typename?: 'TemplateStage'
                id: string
                name?: string | null
                visibility?: TemplateStageVisibilityEnum | null
                templatePipelineId?: number | null
                templateMilestoneId?: number | null
                rankOrder?: number | null
                archived?: boolean | null
              }> | null
            }> | null
          } | null
        } | null
      }> | null
    } | null
    Company?: {
      __typename?: 'Company'
      CompanyInfo?: { __typename?: 'CompanyInfo'; companyName?: string | null; logo?: string | null } | null
    } | null
  }> | null
}

export type DetailJobListingQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  jobRequisitionId?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type DetailJobListingQuery = {
  __typename?: 'Query'
  GetJobListing?: Array<{
    __typename?: 'JobListing'
    externalTitle?: string | null
    publicDescription?: string | null
    postedAt?: any | null
    workplaceType?: WorkplaceTypeEnum | null
    Requisition?: {
      __typename?: 'Requisition'
      benefits?: any | null
      jobLevel?: JobLevelEnum | null
      jobType?: JobTypeEnum | null
      maxPay?: number | null
      minPay?: number | null
      payType?: PayTypeEnum | null
      Company?: {
        __typename?: 'Company'
        Address: Array<{
          __typename?: 'Address'
          city?: string | null
          country?: string | null
          state?: string | null
          id: number
          addressLine1?: string | null
          addressLine2?: string | null
          postalCode?: string | null
          timeZone?: string | null
        }>
        CompanyInfo?: { __typename?: 'CompanyInfo'; companyName?: string | null; logo?: string | null } | null
      } | null
      RequisitionSkills?: Array<{
        __typename?: 'RequisitionSkill'
        id: string
        requisitionId?: number | null
        skillId?: number | null
        sequenceNumber?: number | null
        skill?: {
          __typename?: 'Skill'
          id: string
          experienceId?: number | null
          name?: string | null
          level?: string | null
          yearsOfExp?: number | null
        } | null
      }> | null
    } | null
  }> | null
}

export type ListApplicantNoteQueryVariables = Exact<{
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
}>

export type ListApplicantNoteQuery = {
  __typename?: 'Query'
  GetApplicantNotes?: Array<{
    __typename?: 'ApplicantNotes'
    id: string
    createdAt?: any | null
    candidateApplicationId?: number | null
    scalisUserId?: number | null
    UserNote?: { __typename?: 'UserNote'; id: string; noteText?: any | null } | null
  }> | null
}

export type ListBookmarkedJobQueryVariables = Exact<{ [key: string]: never }>

export type ListBookmarkedJobQuery = {
  __typename?: 'Query'
  GetBookmarkedJob?: Array<{
    __typename?: 'BookmarkedJob'
    id: string
    jobListingId?: number | null
    scalisUserId?: number | null
    JobListing?: {
      __typename?: 'JobListing'
      acceptingCandidates?: boolean | null
      deletedAt?: any | null
      externalTitle?: string | null
      id: number
      internalMatchingDescription?: string | null
      listingTitle?: string | null
      jobRequisitionId?: number | null
      postedAt?: any | null
      publicDescription?: string | null
      publicListingId?: string | null
      searchInternalMatchingDescription?: string | null
      searchPublicDescription?: string | null
      stage?: StageEnum | null
      workplaceType?: WorkplaceTypeEnum | null
      JobListingJobPipeline?: Array<{
        __typename?: 'JobListingJobPipeline'
        id: string
        jobListingId?: number | null
        jobPipelineId?: number | null
        JobPipeline?: {
          __typename?: 'JobPipeline'
          id: string
          title?: string | null
          jobRequisitionId?: number | null
          templatePipelineId?: number | null
        } | null
      }> | null
    } | null
  }> | null
}

export type ListCandidateApplicationQueryVariables = Exact<{
  applicationStatus?: InputMaybe<Array<ApplicationStatusEnum> | ApplicationStatusEnum>
  applicationType?: InputMaybe<Array<ApplicationTypeEnum> | ApplicationTypeEnum>
  id?: InputMaybe<Scalars['Int']['input']>
  scalisUserId?: InputMaybe<Scalars['Int']['input']>
}>

export type ListCandidateApplicationQuery = {
  __typename?: 'Query'
  GetCandidateApplication?: Array<{
    __typename?: 'CandidateApplication'
    applicationCreationType?: string | null
    applicationStatus?: ApplicationStatusEnum | null
    applicationTimestamp?: any | null
    applicationType?: string | null
    appliedAt?: any | null
    candidateProfileId?: number | null
    createdAt?: any | null
    companyId?: number | null
    jobListingId?: number | null
    deleted?: any | null
    id: string
    matchScore?: number | null
    lastUpdatedStatusAt?: any | null
    templateStageId?: number | null
    updatedAt?: any | null
    viewedProfile?: boolean | null
    viewedAt?: any | null
    Company?: {
      __typename?: 'Company'
      createdAt?: any | null
      externalCompanyId?: string | null
      id: string
      lastActivity?: any | null
      subdomain?: string | null
      updatedAt?: any | null
      CompanyInfo?: {
        __typename?: 'CompanyInfo'
        companyId?: number | null
        companyName?: string | null
        id: string
        logo?: string | null
        atsNeeds?: string | null
        companyType?: string | null
        employeeCount?: EmployeeCountEnum | null
        createdAt?: any | null
        hearAboutUs?: string | null
        industry?: IndustryEnum | null
        hiringNeeds?: string | null
        phoneNumber?: string | null
        positionsToFill?: PositionsToFillEnum | null
        updatedAt?: any | null
        recruitingOrStaffing?: boolean | null
        subIndustry?: SubIndustryTypeEnum | null
        usingATS?: boolean | null
        website?: string | null
        yearsInOperation?: number | null
      } | null
      Address: Array<{
        __typename?: 'Address'
        activeStatus?: boolean | null
        addressLine1?: string | null
        addressLine2?: string | null
        addressType?: string | null
        city?: string | null
        contactName?: string | null
        createdAt?: any | null
        country?: string | null
        id: number
        latitude?: string | null
        longitude?: string | null
        postalCode?: string | null
        scalisUserId?: number | null
        timeZone?: string | null
        state?: string | null
        updatedAt?: any | null
      }>
      CompanyDepartment: Array<{
        __typename?: 'CompanyDepartment'
        companyId?: number | null
        createdAt?: any | null
        departmentName?: string | null
        externalId?: string | null
        id: string
        subordinateTo?: number | null
        updatedAt?: any | null
      }>
      CompanyOffice: Array<{
        __typename?: 'CompanyOffice'
        companyId?: number | null
        createdAt?: any | null
        externalId?: string | null
        id: string
        officeName?: string | null
        updatedAt?: any | null
      }>
    } | null
    JobListing?: {
      __typename?: 'JobListing'
      acceptingCandidates?: boolean | null
      deletedAt?: any | null
      externalTitle?: string | null
      id: number
      internalMatchingDescription?: string | null
      jobRequisitionId?: number | null
      listingTitle?: string | null
      postedAt?: any | null
      publicDescription?: string | null
      publicListingId?: string | null
      searchInternalMatchingDescription?: string | null
      searchPublicDescription?: string | null
      stage?: StageEnum | null
      workplaceType?: WorkplaceTypeEnum | null
      JobListingJobPipeline?: Array<{
        __typename?: 'JobListingJobPipeline'
        id: string
        jobListingId?: number | null
        jobPipelineId?: number | null
        JobPipeline?: {
          __typename?: 'JobPipeline'
          id: string
          title?: string | null
          jobRequisitionId?: number | null
          templatePipelineId?: number | null
        } | null
      }> | null
    } | null
    TemplateStage?: {
      __typename?: 'TemplateStage'
      archived?: boolean | null
      createdAt?: any | null
      id: string
      name?: string | null
      rankOrder?: number | null
      templateMilestoneId?: number | null
      templatePipelineId?: number | null
      updatedAt?: any | null
      visibility?: TemplateStageVisibilityEnum | null
      TemplateMilestone?: {
        __typename?: 'TemplateMilestone'
        archived?: boolean | null
        id: string
        name?: NameEnum | null
        rankOrder?: number | null
        templatePipelineId?: number | null
      } | null
    } | null
  }> | null
}

export type GetBookmarkedJobQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<SortByBoomarkedJob>
  sortDirection?: InputMaybe<SortDirection>
  page?: InputMaybe<Scalars['Int']['input']>
}>

export type GetBookmarkedJobQuery = {
  __typename?: 'Query'
  GetBookmarkedJob?: Array<{
    __typename?: 'BookmarkedJob'
    id: string
    JobListing?: {
      __typename?: 'JobListing'
      id: number
      externalTitle?: string | null
      publicListingId?: string | null
      stateAndOrCountry?: string | null
      workplaceType?: WorkplaceTypeEnum | null
      Address?: Array<{
        __typename?: 'Address'
        activeStatus?: boolean | null
        addressLine1?: string | null
        addressLine2?: string | null
        addressType?: string | null
        city?: string | null
        country?: string | null
        id: number
        state?: string | null
        postalCode?: string | null
        timeZone?: string | null
      }> | null
      Requisition?: {
        __typename?: 'Requisition'
        id: string
        Company?: {
          __typename?: 'Company'
          id: string
          CompanyInfo?: { __typename?: 'CompanyInfo'; companyName?: string | null; logo?: string | null } | null
        } | null
      } | null
    } | null
  }> | null
  CountBookmarkedJob?: { __typename?: 'countBookmarkedJob'; count: number } | null
}

export type GetJobseekerDashboardApplicationsQueryVariables = Exact<{
  limit?: Scalars['Int']['input']
  page?: Scalars['Int']['input']
  sortBy?: InputMaybe<SortByApplication>
  sortDirection?: InputMaybe<SortDirection>
  search?: InputMaybe<Scalars['String']['input']>
}>

export type GetJobseekerDashboardApplicationsQuery = {
  __typename?: 'Query'
  GetCandidateApplication?: Array<{
    __typename?: 'CandidateApplication'
    id: string
    applicationStatus?: ApplicationStatusEnum | null
    appliedAt?: any | null
    applicationType?: string | null
    Company?: {
      __typename?: 'Company'
      Address: Array<{ __typename?: 'Address'; city?: string | null; state?: string | null; country?: string | null }>
      CompanyInfo?: { __typename?: 'CompanyInfo'; companyName?: string | null; logo?: string | null } | null
    } | null
    JobListing?: {
      __typename?: 'JobListing'
      externalTitle?: string | null
      publicListingId?: string | null
      id: number
      stateAndOrCountry?: string | null
      workplaceType?: WorkplaceTypeEnum | null
      Address?: Array<{
        __typename?: 'Address'
        timeZone?: string | null
        state?: string | null
        postalCode?: string | null
        longitude?: string | null
        latitude?: string | null
        id: number
        country?: string | null
        city?: string | null
        addressType?: string | null
        addressLine2?: string | null
        addressLine1?: string | null
        activeStatus?: boolean | null
      }> | null
    } | null
    LastPublicTemplateStage?: {
      __typename?: 'TemplateStage'
      id: string
      name?: string | null
      TemplateMilestone?: { __typename?: 'TemplateMilestone'; name?: NameEnum | null } | null
    } | null
    TemplateStage?: {
      __typename?: 'TemplateStage'
      name?: string | null
      id: string
      TemplateMilestone?: { __typename?: 'TemplateMilestone'; name?: NameEnum | null; id: string } | null
    } | null
  }> | null
  CountCandidateApplication?: { __typename?: 'countOutput'; count: number } | null
}

export type GetJobseekerDashboardMetricsQueryVariables = Exact<{ [key: string]: never }>

export type GetJobseekerDashboardMetricsQuery = {
  __typename?: 'Query'
  active?: { __typename?: 'countOutput'; count: number } | null
  bookmarked?: { __typename?: 'countBookmarkedJob'; count: number } | null
  interviews?: { __typename?: 'countOutput'; count: number } | null
  invites?: { __typename?: 'countOutput'; count: number } | null
  rejected?: { __typename?: 'countOutput'; count: number } | null
  total?: { __typename?: 'countOutput'; count: number } | null
}

export type GetExternalJobApplicationQueryVariables = Exact<{
  status?: InputMaybe<Array<ExternalApplicationStatus> | ExternalApplicationStatus>
  search?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<SortByExternalApplication>
  sortDirection?: InputMaybe<SortDirection>
}>

export type GetExternalJobApplicationQuery = {
  __typename?: 'Query'
  GetExternalJobApplication?: Array<{
    __typename?: 'ExternalJobApplication'
    status?: ExternalApplicationStatus | null
    location: string
    jobUrl: string
    jobTitle: string
    jobId: string
    id: string
    employerName: string
    appliedAt?: any | null
    updatedAt?: any | null
  }> | null
  CountExternalJobApplication?: { __typename?: 'countOutput'; count: number } | null
}

export type GetJobSeekerJobInvitesQueryVariables = Exact<{
  listingTitleSearch?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<SortByJobInvite>
  sortDirection?: InputMaybe<SortDirection>
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetJobSeekerJobInvitesQuery = {
  __typename?: 'Query'
  CountJobInvite?: { __typename?: 'countOutput'; count: number } | null
  GetJobInvite?: Array<{
    __typename?: 'JobInvite'
    token?: number | null
    sentAt?: any | null
    jobListingId?: number | null
    inviteStatus?: InviteStatusEnum | null
    id: string
    dismissed?: boolean | null
    candidateApplicationId?: number | null
    JobListing?: {
      __typename?: 'JobListing'
      id: number
      listingTitle?: string | null
      publicListingId?: string | null
      externalTitle?: string | null
      Requisition?: {
        __typename?: 'Requisition'
        Company?: {
          __typename?: 'Company'
          id: string
          CompanyInfo?: { __typename?: 'CompanyInfo'; companyName?: string | null; id: string } | null
        } | null
      } | null
    } | null
  }> | null
}

export type GetScalisUserQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetScalisUserQuery = {
  __typename?: 'Query'
  GetScalisUser?: {
    __typename?: 'ScalisUser'
    id: number
    completedAt?: any | null
    currentStage?: string | null
    profileImage?: string | null
    Address?: Array<{
      __typename?: 'Address'
      activeStatus?: boolean | null
      addressLine1?: string | null
      addressLine2?: string | null
      city?: string | null
      country?: string | null
      contactName?: string | null
      addressType?: string | null
      createdAt?: any | null
      id: number
      latitude?: string | null
      longitude?: string | null
      state?: string | null
      timeZone?: string | null
      updatedAt?: any | null
      postalCode?: string | null
    }> | null
    JobSeekerInfo?: {
      __typename?: 'JobSeekerInfo'
      canStartNewJob?: CanStartNewJobEnum | null
      consideringOffers?: boolean | null
      currPrefRoles?: any | null
      createdAt?: any | null
      desiredPay?: number | null
      employStatus?: boolean | null
      id: number
      jobSeekerId?: number | null
      jobLevel?: string | null
      jobType?: string | null
      locationsWillRelocate?: any | null
      jobTypePref?: JobTypePrefEnum | null
      messageUserId?: string | null
      nonTraditionalStatus?: boolean | null
      searchStatus?: string | null
      payType?: string | null
      searchingJobs?: boolean | null
      teamSize?: TeamSizeEnum | null
      updatedAt?: any | null
      tryingToRelocate?: string | null
      yearsOfExperience?: number | null
      willingToRelocate?: string | null
      yearsInWorkforce?: number | null
    } | null
    ScalisUserUploadedDocument?: Array<{
      __typename?: 'ScalisUserUploadedDocument'
      id: number
      documentPath?: string | null
      documentName?: string | null
      documentType: DocumentTypeEnum
      createdAt: any
      deletedAt?: any | null
    }> | null
  } | null
}

export type ListJobQueryVariables = Exact<{ [key: string]: never }>

export type ListJobQuery = {
  __typename?: 'Query'
  GetJobListing?: Array<{
    __typename?: 'JobListing'
    acceptingCandidates?: boolean | null
    deletedAt?: any | null
    externalTitle?: string | null
    id: number
    internalMatchingDescription?: string | null
    jobRequisitionId?: number | null
    listingTitle?: string | null
    postedAt?: any | null
    publicDescription?: string | null
    publicListingId?: string | null
    searchPublicDescription?: string | null
    searchInternalMatchingDescription?: string | null
    stage?: StageEnum | null
    workplaceType?: WorkplaceTypeEnum | null
    Requisition?: {
      __typename?: 'Requisition'
      jobLevel?: JobLevelEnum | null
      jobType?: JobTypeEnum | null
      Company?: {
        __typename?: 'Company'
        Address: Array<{
          __typename?: 'Address'
          id: number
          addressLine1?: string | null
          addressLine2?: string | null
          city?: string | null
          state?: string | null
          country?: string | null
          postalCode?: string | null
          timeZone?: string | null
        }>
        CompanyInfo?: {
          __typename?: 'CompanyInfo'
          companyId?: number | null
          companyName?: string | null
          logo?: string | null
        } | null
      } | null
    } | null
  }> | null
}

export type LoggedUserQueryVariables = Exact<{ [key: string]: never }>

export type LoggedUserQuery = {
  __typename?: 'Query'
  Me?: {
    __typename?: 'ScalisUser'
    id: number
    firstName?: string | null
    lastName?: string | null
    completedAt?: any | null
    currentStage?: string | null
    profileImage?: string | null
    JobSeekerInfo?: { __typename?: 'JobSeekerInfo'; aboutMe?: string | null } | null
    Address?: Array<{
      __typename?: 'Address'
      city?: string | null
      state?: string | null
      country?: string | null
    }> | null
    Achievement?: Array<{
      __typename?: 'Achievement'
      id: number
      name?: string | null
      issuingAuthority?: string | null
      issueYear?: number | null
      description?: any | null
    }> | null
    CertificateOrLicense?: Array<{
      __typename?: 'CertificateOrLicense'
      id: number
      name?: string | null
      issuingAuthority?: string | null
      issueYear?: number | null
      description?: any | null
    }> | null
    JobSeekerSkill?: Array<{
      __typename?: 'JobSeekerSkills'
      id: number
      sequenceNumber?: number | null
      level?: string | null
      name?: string | null
      yearsOfExp?: number | null
    }> | null
    DemographicInfo?: Array<{
      __typename?: 'DemographicInfo'
      gender?: GenderEnum | null
      raceEthnicity?: string | null
      veteranStatus?: VeteranStatusEnum | null
    }> | null
    WebsiteOrSocialMedia?: Array<{ __typename?: 'WebsiteOrSocialMedia'; id: number; websiteURL: string }> | null
    VolunteerWork?: Array<{
      __typename?: 'VolunteerWork'
      id: number
      roleType?: RoleTypeEnum | null
      volunteerJobTitle?: string | null
      organizationName?: string | null
      location?: string | null
      startDate?: any | null
      isCurrentlyWorking?: boolean | null
      description?: any | null
    }> | null
    Experience?: Array<{
      __typename?: 'Experience'
      id: number
      jobTitle?: string | null
      employerName?: string | null
      jobType?: JobTypeEnum | null
      locationType?: string | null
      location?: string | null
      startDate?: any | null
      isCurrentlyWorking?: boolean | null
      isCurrentJob?: boolean | null
      description?: string | null
    }> | null
    Education?: Array<{
      __typename?: 'Education'
      id: number
      institutionName?: string | null
      educationLevel?: EducationLevelEnum | null
      areasOfStudy?: any | null
      startDate?: any | null
      endDate?: any | null
      cumGPA?: number | null
      cumGPAScale?: number | null
    }> | null
  } | null
}

export type GetChatUsersJsv2QueryVariables = Exact<{ [key: string]: never }>

export type GetChatUsersJsv2Query = {
  __typename?: 'Query'
  GetChatUsersJSV2: Array<{
    __typename?: 'ChatUserInfoJS'
    firstName: string
    lastName: string
    userId?: number | null
  }>
}

export type GetChatUsersV2QueryVariables = Exact<{ [key: string]: never }>

export type GetChatUsersV2Query = {
  __typename?: 'Query'
  GetChatUsersV2: Array<{ __typename?: 'ChatUserInfo'; firstName: string; lastName: string; userId?: number | null }>
}

export type GetChatsJsv2QueryVariables = Exact<{ [key: string]: never }>

export type GetChatsJsv2Query = {
  __typename?: 'Query'
  GetChatsJSV2: Array<{
    __typename?: 'ChatInfoJS'
    firstName?: string | null
    lastName?: string | null
    companyName?: string | null
    chatId?: string | null
    userId?: number | null
  }>
}

export type GetChatsV2QueryVariables = Exact<{ [key: string]: never }>

export type GetChatsV2Query = {
  __typename?: 'Query'
  GetChatsV2: Array<{
    __typename?: 'ChatInfo'
    firstName: string
    lastName: string
    userId?: number | null
    chatId?: string | null
  }>
}

export type CreateCandidateOfferFileMutationVariables = Exact<{
  input: CreateCandidateOfferFileInput
}>

export type CreateCandidateOfferFileMutation = {
  __typename?: 'Mutation'
  CreateCandidateOfferFile: Array<{ __typename?: 'CandidateOfferFile'; id: number }>
}

export type DeleteCandidateOfferFileMutationVariables = Exact<{
  input: DeleteCandidateOfferFileInput
}>

export type DeleteCandidateOfferFileMutation = {
  __typename?: 'Mutation'
  DeleteCandidateOfferFile: { __typename?: 'CandidateOfferFile'; id: number }
}

export type GetCandidateOfferCustomFieldQueryVariables = Exact<{
  input: GetCandidateOfferCustomFieldInput
}>

export type GetCandidateOfferCustomFieldQuery = {
  __typename?: 'Query'
  GetCandidateOfferCustomField?: Array<{
    __typename?: 'CandidateOfferCustomField'
    id: number
    fieldValue?: any | null
    candidateOfferVersionId: number
    offerCustomFieldId: number
  }> | null
}

export type GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables = Exact<{
  input: GetCandidateOfferVersionEmbeddedClaimUrlInput
}>

export type GetCandidateOfferVersionEmbeddedClaimUrlQuery = {
  __typename?: 'Query'
  GetCandidateOfferVersionEmbeddedClaimUrl: {
    __typename?: 'GetCandidateOfferVersionEmbeddedClaimUrlResult'
    clientId?: string | null
    claimUrl?: string | null
    signatureRequestId?: string | null
  }
}

export type GetCandidateOfferQueryVariables = Exact<{
  candidateApplicationId: Scalars['Int']['input']
}>

export type GetCandidateOfferQuery = {
  __typename?: 'Query'
  GetCandidateOffer?: Array<{
    __typename?: 'CandidateOffer'
    id: number
    CandidateOfferVersion?: Array<{
      __typename?: 'CandidateOfferVersion'
      id: number
      candidateOfferId: number
      version: number
      candidateName: string
      jobTitle: string
      manager?: string | null
      startDate?: any | null
      jobType: JobTypeEnum
      payType: PayTypeEnum
      benefits?: any | null
      expirationDate?: any | null
      companyOfficeId?: number | null
      compensation: number
      additionalCompensation?: any | null
      offerStatus: OfferStatusEnum
      lastUpdatedStatusAt?: any | null
      createdAt?: any | null
      CandidateOfferCustomField?: Array<{
        __typename?: 'CandidateOfferCustomField'
        id: number
        fieldValue?: any | null
        OfferCustomField: {
          __typename?: 'OfferCustomField'
          id: number
          fieldName: string
          fieldConfigurationType: CustomFieldInputTypeEnum
          triggerNewOfferVersion: boolean
        }
      }> | null
      CompanyOffice?: { __typename?: 'CompanyOffice'; id: string; officeName?: string | null } | null
      CandidateOfferFile?: Array<{
        __typename?: 'CandidateOfferFile'
        id: number
        fileName: string
        filePath: string
        fileType: OfferFileTypeEnum
        createdAt?: any | null
      }> | null
    }> | null
  }> | null
}

export type CandidateOfferVersionFragment = {
  __typename?: 'CandidateOfferVersion'
  id: number
  candidateOfferId: number
  version: number
  candidateName: string
  jobTitle: string
  manager?: string | null
  startDate?: any | null
  jobType: JobTypeEnum
  payType: PayTypeEnum
  benefits?: any | null
  expirationDate?: any | null
  companyOfficeId?: number | null
  compensation: number
  additionalCompensation?: any | null
  offerStatus: OfferStatusEnum
  lastUpdatedStatusAt?: any | null
  createdAt?: any | null
  CandidateOfferCustomField?: Array<{
    __typename?: 'CandidateOfferCustomField'
    id: number
    fieldValue?: any | null
    OfferCustomField: {
      __typename?: 'OfferCustomField'
      id: number
      fieldName: string
      fieldConfigurationType: CustomFieldInputTypeEnum
      triggerNewOfferVersion: boolean
    }
  }> | null
  CompanyOffice?: { __typename?: 'CompanyOffice'; id: string; officeName?: string | null } | null
  CandidateOfferFile?: Array<{
    __typename?: 'CandidateOfferFile'
    id: number
    fileName: string
    filePath: string
    fileType: OfferFileTypeEnum
    createdAt?: any | null
  }> | null
}

export type GetCompanyOfficeListQueryVariables = Exact<{ [key: string]: never }>

export type GetCompanyOfficeListQuery = {
  __typename?: 'Query'
  ListCompanyOffices?: Array<{ __typename?: 'CompanyOffice'; id: string; officeName?: string | null }> | null
}

export type GetOfferLetterDocumentTemplateQueryVariables = Exact<{ [key: string]: never }>

export type GetOfferLetterDocumentTemplateQuery = {
  __typename?: 'Query'
  GetOfferDocumentTemplate?: Array<{
    __typename?: 'OfferDocumentTemplate'
    id: string
    documentTemplateName: string
    documentTemplateText: string
  }> | null
}

export type OfferDocumentTemplateFragment = {
  __typename?: 'OfferDocumentTemplate'
  id: string
  documentTemplateName: string
  documentTemplateText: string
}

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetMyProfileQuery = {
  __typename?: 'Query'
  Me?: {
    __typename?: 'ScalisUser'
    id: number
    firstName?: string | null
    lastName?: string | null
    profileImage?: string | null
    EmailVerification?: Array<{ __typename?: 'EmailVerification'; id: number; emailAddress?: string | null }> | null
  } | null
}

export type SendCandidateOfferMutationVariables = Exact<{
  input: SendCandidateOfferInput
}>

export type SendCandidateOfferMutation = { __typename?: 'Mutation'; SendCandidateOffer: boolean }

export type UpdateCandidateOfferVersionStatusMutationVariables = Exact<{
  input: UpdateCandidateOfferVersionStatusInput
}>

export type UpdateCandidateOfferVersionStatusMutation = {
  __typename?: 'Mutation'
  UpdateCandidateOfferVersionStatus: {
    __typename?: 'CandidateOfferVersion'
    id: number
    candidateOfferId: number
    version: number
    candidateName: string
    jobTitle: string
    manager?: string | null
    startDate?: any | null
    jobType: JobTypeEnum
    payType: PayTypeEnum
    benefits?: any | null
    expirationDate?: any | null
    companyOfficeId?: number | null
    compensation: number
    additionalCompensation?: any | null
    offerStatus: OfferStatusEnum
    lastUpdatedStatusAt?: any | null
    createdAt?: any | null
  }
}

export type UpsertCandidateOfferCustomFieldMutationVariables = Exact<{
  input: UpsertCandidateOfferCustomFieldInput
}>

export type UpsertCandidateOfferCustomFieldMutation = {
  __typename?: 'Mutation'
  UpsertCandidateOfferCustomField?: {
    __typename?: 'CandidateOfferCustomField'
    id: number
    fieldValue?: any | null
    candidateOfferVersionId: number
    offerCustomFieldId: number
  } | null
}

export type UpsertCandidateOfferFileMutationVariables = Exact<{
  input: UpsertCandidateOfferFileInput
}>

export type UpsertCandidateOfferFileMutation = {
  __typename?: 'Mutation'
  UpsertCandidateOfferFile?: { __typename?: 'CandidateOfferFile'; id: number } | null
}

export type UpsertCandidateOfferMutationVariables = Exact<{
  input: UpsertCandidateOfferInput
}>

export type UpsertCandidateOfferMutation = {
  __typename?: 'Mutation'
  UpsertCandidateOffer?: {
    __typename?: 'CandidateOffer'
    id: number
    CandidateOfferVersion?: Array<{
      __typename?: 'CandidateOfferVersion'
      id: number
      candidateOfferId: number
      version: number
      candidateName: string
      jobTitle: string
      manager?: string | null
      startDate?: any | null
      jobType: JobTypeEnum
      payType: PayTypeEnum
      benefits?: any | null
      expirationDate?: any | null
      companyOfficeId?: number | null
      compensation: number
      additionalCompensation?: any | null
      offerStatus: OfferStatusEnum
      lastUpdatedStatusAt?: any | null
      createdAt?: any | null
      CandidateOfferCustomField?: Array<{
        __typename?: 'CandidateOfferCustomField'
        id: number
        fieldValue?: any | null
        OfferCustomField: {
          __typename?: 'OfferCustomField'
          id: number
          fieldName: string
          fieldConfigurationType: CustomFieldInputTypeEnum
          triggerNewOfferVersion: boolean
        }
      }> | null
      CompanyOffice?: { __typename?: 'CompanyOffice'; id: string; officeName?: string | null } | null
      CandidateOfferFile?: Array<{
        __typename?: 'CandidateOfferFile'
        id: number
        fileName: string
        filePath: string
        fileType: OfferFileTypeEnum
        createdAt?: any | null
      }> | null
    }> | null
  } | null
}

export type GetPlaceQueryVariables = Exact<{
  placeId: Scalars['String']['input']
}>

export type GetPlaceQuery = {
  __typename?: 'Query'
  GetPlace: {
    __typename?: 'place'
    addressComponents: Array<{
      __typename?: 'addressComponent'
      longText?: string | null
      shortText?: string | null
      types?: Array<string> | null
    }>
  }
}

export type GetPlacesPredictionQueryVariables = Exact<{
  input: Scalars['String']['input']
}>

export type GetPlacesPredictionQuery = {
  __typename?: 'Query'
  GetPlacesPrediction: Array<{ __typename?: 'placePrediction'; placeId: string; description: string }>
}

export type GetListReferralsQueryVariables = Exact<{
  input?: InputMaybe<FilterReferralsInput>
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetListReferralsQuery = {
  __typename?: 'Query'
  ListReferrals: Array<{
    __typename?: 'UploadedCandidate'
    id: number
    firstName?: string | null
    lastName?: string | null
    CandidateProfile: {
      __typename?: 'CandidateProfile'
      id: string
      ProfileDetails: {
        __typename?: 'ProfileDetails'
        id: string
        CandidateSource: Array<{
          __typename?: 'CandidateSource'
          id: number
          candidateSourceName?: {
            __typename?: 'CandidateSourceName'
            id: number
            name: string
            sourceCategory?: SourceCategoryEnum | null
          } | null
          companyUser?: {
            __typename?: 'CompanyUser'
            id: string
            UserInvitation?: {
              __typename?: 'UserInvitation'
              ScalisUser?: {
                __typename?: 'ScalisUser'
                id: number
                firstName?: string | null
                lastName?: string | null
              } | null
            } | null
          } | null
        }>
      }
      CandidateApplication: Array<{
        __typename?: 'CandidateApplication'
        id: string
        createdAt?: any | null
        updatedAt?: any | null
        applicationStatus?: ApplicationStatusEnum | null
        JobListing?: {
          __typename?: 'JobListing'
          id: number
          externalTitle?: string | null
          acceptingCandidates?: boolean | null
          publicListingId?: string | null
        } | null
        TemplateStage?: {
          __typename?: 'TemplateStage'
          id: string
          name?: string | null
          TemplateMilestone?: { __typename?: 'TemplateMilestone'; id: string; name?: NameEnum | null } | null
        } | null
      }>
    }
  }>
}

export type GetReferralCountQueryVariables = Exact<{
  input?: InputMaybe<FilterReferralsInput>
}>

export type GetReferralCountQuery = {
  __typename?: 'Query'
  CountReferrals: { __typename?: 'ReferralsCount'; count: number }
}

export type GetReferralsStatsQueryVariables = Exact<{
  input: FilterReferralsInput
}>

export type GetReferralsStatsQuery = {
  __typename?: 'Query'
  ReferralsStats: {
    __typename?: 'ReferralsStats'
    totalReferrals: number
    myReferrals: number
    totalReferralsByMilestone: any
  }
}

export type ReplaceTokensQueryVariables = Exact<{
  input: ReplaceTokensInput
}>

export type ReplaceTokensQuery = {
  __typename?: 'Query'
  ReplaceTokens?: { __typename?: 'ReplaceTokensOutput'; result: string } | null
}

export type GetCoreReportCsvQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetCoreReportCsvQuery = {
  __typename?: 'Query'
  GetCoreReportCsv?: { __typename?: 'CoreReportCsvOutput'; base64: string } | null
}

export type GetReportDataQueryVariables = Exact<{
  id: Scalars['Int']['input']
  generateAvailableFilters: Scalars['Boolean']['input']
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  filters?: InputMaybe<Array<FilterCriteriaInput> | FilterCriteriaInput>
}>

export type GetReportDataQuery = {
  __typename?: 'Query'
  GetCoreReportData?: {
    __typename?: 'CoreReportDataOutput'
    coreReportId: number
    data: any
    schema: any
    total: number
    availableFilters?: Array<{
      __typename?: 'AvailableFilterOutput'
      column: string
      label: string
      type: CoreReportFilterTypeEnum
      values?: Array<string> | null
    }> | null
  } | null
}

export type GetReportFiltersQueryVariables = Exact<{
  id: Scalars['Int']['input']
  generateAvailableFilters: Scalars['Boolean']['input']
}>

export type GetReportFiltersQuery = {
  __typename?: 'Query'
  GetCoreReportData?: {
    __typename?: 'CoreReportDataOutput'
    coreReportId: number
    availableFilters?: Array<{
      __typename?: 'AvailableFilterOutput'
      column: string
      label: string
      type: CoreReportFilterTypeEnum
      values?: Array<string> | null
    }> | null
  } | null
}

export type GetReportsQueryVariables = Exact<{
  tab: ReportFilterTabEnum
}>

export type GetReportsQuery = {
  __typename?: 'Query'
  CountCoreReport: number
  GetCoreReport?: Array<{
    __typename?: 'CoreReport'
    reportName: CoreReportNameEnum
    isFavorite: boolean
    updatedAt: any
    createdAt: any
    companyId: number
    id: number
  }> | null
}

export type GetScheduledReportQueryVariables = Exact<{
  coreReportId: Scalars['Int']['input']
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetScheduledReportQuery = {
  __typename?: 'Query'
  GetScheduledReport?: Array<{
    __typename?: 'ScheduledReport'
    coreReportId: number
    customDaysOfWeek?: any | null
    customTime?: string | null
    deleted?: any | null
    endDate?: any | null
    id: number
    recipients: any
    repeat?: RepeatTypeEnum | null
    reportSchedulerType: ReportSchedulerTypeEnum
    startDate?: any | null
  }> | null
}

export type GetSharedReportsQueryVariables = Exact<{
  coreReportId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetSharedReportsQuery = {
  __typename?: 'Query'
  GetSharedCoreReport?: Array<{
    __typename?: 'SharedCoreReport'
    email: string
    id: string
    coreReportId: number
    companyUserId: number
  }> | null
}

export type GetRequisitionAndOpeningsIdQueryVariables = Exact<{ [key: string]: never }>

export type GetRequisitionAndOpeningsIdQuery = {
  __typename?: 'Query'
  GetRequisitions?: Array<{
    __typename?: 'Requisition'
    id: string
    extRequisitionId?: string | null
    jobOpening?: Array<{
      __typename?: 'JobOpening'
      id: string
      extOpeningId?: string | null
      jobRequisitionId?: number | null
    }> | null
  }> | null
}

export type GetRequisitionExtIdQueryVariables = Exact<{ [key: string]: never }>

export type GetRequisitionExtIdQuery = {
  __typename?: 'Query'
  GetRequisitionExtId?: {
    __typename?: 'RequisitionExtId'
    id: string
    companyId?: number | null
    extId?: number | null
  } | null
}

export type GetRequisitionJobDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetRequisitionJobDetailsQuery = {
  __typename?: 'Query'
  GetRequisition?: {
    __typename?: 'Requisition'
    id: string
    jobStatus?: JobStatusEnum | null
    openedAt?: any | null
    jobName?: string | null
    extRequisitionId?: string | null
    jobType?: JobTypeEnum | null
    jobLevel?: JobLevelEnum | null
    travelType?: TravelTypeEnum | null
    payType?: PayTypeEnum | null
    minPay?: number | null
    maxPay?: number | null
    additionalComp?: any | null
    benefits?: any | null
    createdAt?: any | null
    updatedAt?: any | null
    companyDepartment?: { __typename?: 'CompanyDepartment'; id: string; departmentName?: string | null } | null
    companyOffice?: { __typename?: 'CompanyOffice'; id: string; officeName?: string | null } | null
    jobOpening?: Array<{
      __typename?: 'JobOpening'
      id: string
      extOpeningId?: string | null
      targetHireDate?: any | null
      openingType?: OpeningTypeEnum | null
    }> | null
  } | null
}

export type GetRequisitionListingsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetRequisitionListingsQuery = {
  __typename?: 'Query'
  GetRequisition?: {
    __typename?: 'Requisition'
    id: string
    companyId?: number | null
    jobName?: string | null
    jobType?: JobTypeEnum | null
    jobLevel?: JobLevelEnum | null
    jobStatus?: JobStatusEnum | null
    openedAt?: any | null
    payType?: PayTypeEnum | null
    minPay?: number | null
    maxPay?: number | null
    benefits?: any | null
    createdAt?: any | null
    updatedAt?: any | null
    Company?: {
      __typename?: 'Company'
      CompanyInfo?: { __typename?: 'CompanyInfo'; companyName?: string | null; logo?: string | null } | null
    } | null
    RequisitionJobDescription?: Array<{ __typename?: 'RequisitionJobDescription'; description?: string | null }> | null
    RequisitionSkills?: Array<{
      __typename?: 'RequisitionSkill'
      skill?: { __typename?: 'Skill'; name?: string | null; level?: string | null; yearsOfExp?: number | null } | null
    }> | null
    jobListings?: Array<{
      __typename?: 'JobListing'
      id: number
      publicListingId?: string | null
      jobRequisitionId?: number | null
      listingTitle?: string | null
      externalTitle?: string | null
      workplaceType?: WorkplaceTypeEnum | null
      publicDescription?: string | null
      displayPayOnListing?: boolean | null
      industry?: IndustryEnum | null
      subIndustry?: SubIndustryTypeEnum | null
      companyDefault?: boolean | null
      stateAndOrCountry?: string | null
      internalMatchingDescription?: string | null
      searchPublicDescription?: string | null
      searchInternalMatchingDescription?: string | null
      postedAt?: any | null
      acceptingCandidates?: boolean | null
      stage?: StageEnum | null
      deletedAt?: any | null
      createdAt?: any | null
      updatedAt?: any | null
      Address?: Array<{
        __typename?: 'Address'
        id: number
        addressLine1?: string | null
        addressLine2?: string | null
        city?: string | null
        state?: string | null
        country?: string | null
        postalCode?: string | null
        timeZone?: string | null
      }> | null
      JobListingScreeningQuestion?: Array<{
        __typename?: 'JobListingScreeningQuestion'
        id: string
        jobListingId?: number | null
        displaySequenceNumber?: number | null
        screeningQuestionId?: number | null
        createdAt?: any | null
        updatedAt?: any | null
        ScreeningQuestion?: {
          __typename?: 'ScreeningQuestion'
          id: string
          isTemplateQuestion?: boolean | null
          questionTitle?: string | null
          questionDesc?: string | null
          questionType?: QuestionTypeEnum | null
          minVal?: number | null
          maxVal?: number | null
          valStatus?: string | null
          ansOptions?: string | null
          isRejectionQuestion?: boolean | null
          isGlobalQuestion?: boolean | null
          rejectMessage?: string | null
          templateMessageId?: number | null
          preferredAnswer?: string | null
          companyId?: number | null
          companyUserId?: number | null
          createdAt?: any | null
          updatedAt?: any | null
        } | null
      }> | null
    }> | null
  } | null
}

export type GetRequisitionPipelineQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetRequisitionPipelineQuery = {
  __typename?: 'Query'
  GetRequisition?: {
    __typename: 'Requisition'
    id: string
    companyId?: number | null
    jobName?: string | null
    extRequisitionId?: string | null
    jobListings?: Array<{
      __typename: 'JobListing'
      id: number
      listingTitle?: string | null
      acceptingCandidates?: boolean | null
    }> | null
    jobPipelines?: Array<{
      __typename: 'JobPipeline'
      id: string
      title?: string | null
      TemplatePipeline?: {
        __typename: 'TemplatePipeline'
        id: string
        name?: string | null
        ghostPipeline?: boolean | null
        TemplateMilestones?: Array<{
          __typename: 'TemplateMilestone'
          id: string
          name?: NameEnum | null
          rankOrder?: number | null
          TemplateStage?: Array<{
            __typename: 'TemplateStage'
            id: string
            name?: string | null
            rankOrder?: number | null
            visibility?: TemplateStageVisibilityEnum | null
            CandidateApplicationByStage?: Array<{ __typename?: 'CandidateApplication'; id: string }> | null
          }> | null
        }> | null
      } | null
    }> | null
  } | null
}

export type GetRequisitionPipelinesQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetRequisitionPipelinesQuery = {
  __typename?: 'Query'
  GetRequisition?: {
    __typename?: 'Requisition'
    id: string
    openedAt?: any | null
    createdAt?: any | null
    updatedAt?: any | null
    jobPipelines?: Array<{
      __typename?: 'JobPipeline'
      id: string
      title?: string | null
      JobListingJobPipeline?: Array<{ __typename?: 'JobListingJobPipeline'; id: string }> | null
      TemplatePipeline?: {
        __typename?: 'TemplatePipeline'
        id: string
        name?: string | null
        ghostPipeline?: boolean | null
        pipelinedUsed?: boolean | null
        archived?: boolean | null
        createdAt?: any | null
        updatedAt?: any | null
        deletedAt?: any | null
        CompanyDepartment?: { __typename?: 'CompanyDepartment'; id: string; departmentName?: string | null } | null
        TemplateMilestones?: Array<{
          __typename?: 'TemplateMilestone'
          id: string
          name?: NameEnum | null
          rankOrder?: number | null
          archived?: boolean | null
          createdAt?: any | null
          updatedAt?: any | null
          TemplateStage?: Array<{
            __typename?: 'TemplateStage'
            id: string
            visibility?: TemplateStageVisibilityEnum | null
            name?: string | null
            templateMilestoneId?: number | null
            rankOrder?: number | null
            archived?: boolean | null
            createdAt?: any | null
            updatedAt?: any | null
            CandidateApplicationByStage?: Array<{ __typename?: 'CandidateApplication'; id: string }> | null
          }> | null
        }> | null
      } | null
    }> | null
  } | null
}

export type GetRequisitionSkillsAndPreferencesQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetRequisitionSkillsAndPreferencesQuery = {
  __typename?: 'Query'
  GetRequisition?: {
    __typename?: 'Requisition'
    id: string
    openedAt?: any | null
    locationDistancePref?: number | null
    educationLevelPref?: EducationLevelPrefEnum | null
    yearsOfExperiencePref?: YearsOfExperienceEnum | null
    nonTraditionalPref?: boolean | null
    veteranPref?: boolean | null
    diverseHirePref?: boolean | null
    collegeGradPref?: boolean | null
    createdAt?: any | null
    updatedAt?: any | null
    RequisitionSkills?: Array<{
      __typename?: 'RequisitionSkill'
      id: string
      requisitionId?: number | null
      skillId?: number | null
      sequenceNumber?: number | null
      createdAt?: any | null
      updatedAt?: any | null
      skill?: {
        __typename?: 'Skill'
        id: string
        experienceId?: number | null
        name?: string | null
        level?: string | null
        yearsOfExp?: number | null
      } | null
    }> | null
  } | null
}

export type GetRequisitionQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetRequisitionQuery = {
  __typename?: 'Query'
  GetRequisition?: {
    __typename?: 'Requisition'
    id: string
    companyUserId?: number | null
    jobStatus?: JobStatusEnum | null
    jobName?: string | null
    extRequisitionId?: string | null
    jobType?: JobTypeEnum | null
    jobLevel?: JobLevelEnum | null
    travelType?: TravelTypeEnum | null
    payType?: PayTypeEnum | null
    minPay?: number | null
    maxPay?: number | null
    additionalComp?: any | null
    benefits?: any | null
    locationDistancePref?: number | null
    educationLevelPref?: EducationLevelPrefEnum | null
    yearsOfExperiencePref?: YearsOfExperienceEnum | null
    nonTraditionalPref?: boolean | null
    veteranPref?: boolean | null
    diverseHirePref?: boolean | null
    collegeGradPref?: boolean | null
    primaryContact?: number | null
    createdAt?: any | null
    updatedAt?: any | null
    Company?: {
      __typename?: 'Company'
      id: string
      CompanyInfo?: { __typename?: 'CompanyInfo'; id: string; companyName?: string | null; logo?: string | null } | null
    } | null
    companyDepartment?: { __typename?: 'CompanyDepartment'; id: string; departmentName?: string | null } | null
    companyOffice?: { __typename?: 'CompanyOffice'; id: string; officeName?: string | null } | null
    jobOpening?: Array<{
      __typename?: 'JobOpening'
      id: string
      openingStatus?: OpeningStatusEnum | null
      extOpeningId?: string | null
      targetHireDate?: any | null
      openingType?: OpeningTypeEnum | null
      closedReason?: ClosedReasonEnum | null
      closedDate?: any | null
      filledDate?: any | null
      startDate?: any | null
      CandidateProfile?: {
        __typename?: 'CandidateProfile'
        id: string
        CandidateApplicationRating: Array<{
          __typename?: 'CandidateApplicationRating'
          id: string
          score?: number | null
        }>
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
        } | null
        CandidateCollaborator: Array<{ __typename?: 'CandidateCollaborator'; id: string }>
        ProfileDetails: {
          __typename?: 'ProfileDetails'
          id: string
          CandidateMedalistDesignation: Array<{ __typename?: 'CandidateMedalistDesignation'; id: number }>
          CandidateProfileLocation: Array<{ __typename?: 'CandidateProfileLocation'; id: number }>
          CandidateSource: Array<{ __typename?: 'CandidateSource'; id: number }>
        }
      } | null
    }> | null
    RequisitionJobDescription?: Array<{
      __typename?: 'RequisitionJobDescription'
      id: string
      description?: string | null
    }> | null
    RequisitionSkills?: Array<{
      __typename?: 'RequisitionSkill'
      id: string
      requisitionId?: number | null
      skillId?: number | null
      sequenceNumber?: number | null
      createdAt?: any | null
      updatedAt?: any | null
      skill?: {
        __typename?: 'Skill'
        id: string
        experienceId?: number | null
        name?: string | null
        level?: string | null
        yearsOfExp?: number | null
      } | null
    }> | null
    jobListings?: Array<{
      __typename?: 'JobListing'
      id: number
      publicListingId?: string | null
      jobRequisitionId?: number | null
      listingTitle?: string | null
      externalTitle?: string | null
      workplaceType?: WorkplaceTypeEnum | null
      publicDescription?: string | null
      displayPayOnListing?: boolean | null
      industry?: IndustryEnum | null
      subIndustry?: SubIndustryTypeEnum | null
      companyDefault?: boolean | null
      stateAndOrCountry?: string | null
      internalMatchingDescription?: string | null
      searchPublicDescription?: string | null
      searchInternalMatchingDescription?: string | null
      postedAt?: any | null
      acceptingCandidates?: boolean | null
      stage?: StageEnum | null
      deletedAt?: any | null
      createdAt?: any | null
      updatedAt?: any | null
      Address?: Array<{
        __typename?: 'Address'
        id: number
        addressLine1?: string | null
        addressLine2?: string | null
        city?: string | null
        state?: string | null
        country?: string | null
        postalCode?: string | null
        timeZone?: string | null
      }> | null
      JobListingScreeningQuestion?: Array<{
        __typename?: 'JobListingScreeningQuestion'
        id: string
        jobListingId?: number | null
        displaySequenceNumber?: number | null
        screeningQuestionId?: number | null
        active?: boolean | null
        hasApplications?: boolean | null
        createdAt?: any | null
        updatedAt?: any | null
        ScreeningQuestion?: {
          __typename?: 'ScreeningQuestion'
          id: string
          isTemplateQuestion?: boolean | null
          questionTitle?: string | null
          questionDesc?: string | null
          questionType?: QuestionTypeEnum | null
          minVal?: number | null
          maxVal?: number | null
          valStatus?: string | null
          ansOptions?: string | null
          isRejectionQuestion?: boolean | null
          rejectMessage?: string | null
          templateMessageId?: number | null
          preferredAnswer?: string | null
          isGlobalQuestion?: boolean | null
          companyId?: number | null
          companyUserId?: number | null
          createdAt?: any | null
          updatedAt?: any | null
        } | null
      }> | null
      CandidateApplication?: Array<{
        __typename?: 'CandidateApplication'
        id: string
        applicationType?: string | null
        applicationStatus?: ApplicationStatusEnum | null
        applicationTimestamp?: any | null
        interviewScheduling?: InterviewSchedulingEnum | null
        TemplateStage?: {
          __typename?: 'TemplateStage'
          id: string
          TemplateMilestone?: { __typename?: 'TemplateMilestone'; id: string; name?: NameEnum | null } | null
        } | null
        CandidateProfile?: {
          __typename?: 'CandidateProfile'
          id: string
          CandidateApplicationRating: Array<{
            __typename?: 'CandidateApplicationRating'
            id: string
            score?: number | null
          }>
          ScalisUser?: {
            __typename?: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
          } | null
          CandidateApplication: Array<{ __typename?: 'CandidateApplication'; id: string }>
          CandidateCollaborator: Array<{ __typename?: 'CandidateCollaborator'; id: string }>
          ProfileDetails: {
            __typename?: 'ProfileDetails'
            id: string
            CandidateMedalistDesignation: Array<{ __typename?: 'CandidateMedalistDesignation'; id: number }>
            CandidateProfileLocation: Array<{ __typename?: 'CandidateProfileLocation'; id: number }>
            CandidateSource: Array<{ __typename?: 'CandidateSource'; id: number }>
          }
        } | null
        CandidateApplicationRating?: Array<{
          __typename?: 'CandidateApplicationRating'
          id: string
          score?: number | null
        }> | null
      }> | null
    }> | null
    hiringTeamMember?: Array<{
      __typename?: 'HiringTeamMember'
      id: string
      role?: { __typename?: 'Role'; id: string; name: string } | null
      CompanyUser?: {
        __typename?: 'CompanyUser'
        id: string
        companyId: number
        companyUserStatus?: CompanyUserStatusEnum | null
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          id: number
          email?: string | null
          invitedAs?: RoleEnum | null
          invitationStatus?: InvitationStatusEnum | null
          ScalisUser?: {
            __typename?: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
            activeStatus?: boolean | null
            profileImage?: string | null
          } | null
        } | null
      } | null
    }> | null
  } | null
}

export type GetRequisitionsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  companyId?: InputMaybe<Scalars['Int']['input']>
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  companyOfficeId?: InputMaybe<Scalars['Int']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  lastActivity?: InputMaybe<RequisitionLastActivity>
  status?: InputMaybe<JobStatusEnum>
  pipeline?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetRequisitionsQuery = {
  __typename?: 'Query'
  GetRequisitions?: Array<{
    __typename?: 'Requisition'
    id: string
    jobStatus?: JobStatusEnum | null
    jobName?: string | null
    extRequisitionId?: string | null
    jobType?: JobTypeEnum | null
    jobLevel?: JobLevelEnum | null
    travelType?: TravelTypeEnum | null
    payType?: PayTypeEnum | null
    minPay?: number | null
    maxPay?: number | null
    additionalComp?: any | null
    benefits?: any | null
    locationDistancePref?: number | null
    educationLevelPref?: EducationLevelPrefEnum | null
    yearsOfExperiencePref?: YearsOfExperienceEnum | null
    nonTraditionalPref?: boolean | null
    veteranPref?: boolean | null
    diverseHirePref?: boolean | null
    collegeGradPref?: boolean | null
    createdAt?: any | null
    updatedAt?: any | null
    companyDepartment?: { __typename?: 'CompanyDepartment'; id: string; departmentName?: string | null } | null
    companyOffice?: { __typename?: 'CompanyOffice'; id: string; officeName?: string | null } | null
    jobOpening?: Array<{
      __typename?: 'JobOpening'
      id: string
      openingStatus?: OpeningStatusEnum | null
      extOpeningId?: string | null
      targetHireDate?: any | null
      openingType?: OpeningTypeEnum | null
      closedReason?: ClosedReasonEnum | null
      closedDate?: any | null
      filledDate?: any | null
      startDate?: any | null
    }> | null
    RequisitionJobDescription?: Array<{
      __typename?: 'RequisitionJobDescription'
      id: string
      description?: string | null
    }> | null
    RequisitionSkills?: Array<{
      __typename?: 'RequisitionSkill'
      id: string
      requisitionId?: number | null
      skillId?: number | null
      sequenceNumber?: number | null
      createdAt?: any | null
      updatedAt?: any | null
      skill?: {
        __typename?: 'Skill'
        id: string
        experienceId?: number | null
        name?: string | null
        level?: string | null
        yearsOfExp?: number | null
      } | null
    }> | null
    jobListings?: Array<{
      __typename?: 'JobListing'
      id: number
      publicListingId?: string | null
      jobRequisitionId?: number | null
      listingTitle?: string | null
      externalTitle?: string | null
      workplaceType?: WorkplaceTypeEnum | null
      publicDescription?: string | null
      displayPayOnListing?: boolean | null
      industry?: IndustryEnum | null
      subIndustry?: SubIndustryTypeEnum | null
      companyDefault?: boolean | null
      stateAndOrCountry?: string | null
      internalMatchingDescription?: string | null
      searchPublicDescription?: string | null
      searchInternalMatchingDescription?: string | null
      postedAt?: any | null
      acceptingCandidates?: boolean | null
      stage?: StageEnum | null
      deletedAt?: any | null
      createdAt?: any | null
      updatedAt?: any | null
      Address?: Array<{
        __typename?: 'Address'
        id: number
        addressLine1?: string | null
        addressLine2?: string | null
        city?: string | null
        state?: string | null
        country?: string | null
        postalCode?: string | null
        timeZone?: string | null
      }> | null
      JobListingScreeningQuestion?: Array<{
        __typename?: 'JobListingScreeningQuestion'
        id: string
        jobListingId?: number | null
        displaySequenceNumber?: number | null
        screeningQuestionId?: number | null
        createdAt?: any | null
        updatedAt?: any | null
        ScreeningQuestion?: {
          __typename?: 'ScreeningQuestion'
          id: string
          isTemplateQuestion?: boolean | null
          questionTitle?: string | null
          questionDesc?: string | null
          questionType?: QuestionTypeEnum | null
          minVal?: number | null
          maxVal?: number | null
          valStatus?: string | null
          ansOptions?: string | null
          isRejectionQuestion?: boolean | null
          rejectMessage?: string | null
          templateMessageId?: number | null
          preferredAnswer?: string | null
          isGlobalQuestion?: boolean | null
          companyId?: number | null
          companyUserId?: number | null
          createdAt?: any | null
          updatedAt?: any | null
        } | null
      }> | null
      CandidateApplication?: Array<{
        __typename?: 'CandidateApplication'
        id: string
        applicationStatus?: ApplicationStatusEnum | null
        TemplateStage?: { __typename?: 'TemplateStage'; id: string; templateMilestoneId?: number | null } | null
      }> | null
    }> | null
    jobPipelines?: Array<{
      __typename?: 'JobPipeline'
      id: string
      title?: string | null
      TemplatePipeline?: {
        __typename?: 'TemplatePipeline'
        id: string
        name?: string | null
        archived?: boolean | null
        pipelinedUsed?: boolean | null
        ghostPipeline?: boolean | null
        createdAt?: any | null
        updatedAt?: any | null
        deletedAt?: any | null
        TemplateMilestones?: Array<{
          __typename?: 'TemplateMilestone'
          id: string
          name?: NameEnum | null
          rankOrder?: number | null
          archived?: boolean | null
          createdAt?: any | null
          updatedAt?: any | null
          TemplateStage?: Array<{
            __typename?: 'TemplateStage'
            id: string
            visibility?: TemplateStageVisibilityEnum | null
            name?: string | null
            rankOrder?: number | null
            archived?: boolean | null
            createdAt?: any | null
            updatedAt?: any | null
          }> | null
        }> | null
      } | null
    }> | null
    hiringTeamMember?: Array<{
      __typename?: 'HiringTeamMember'
      id: string
      role?: { __typename?: 'Role'; id: string; name: string } | null
      CompanyUser?: {
        __typename?: 'CompanyUser'
        id: string
        companyId: number
        companyUserStatus?: CompanyUserStatusEnum | null
        profileImage?: string | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          id: number
          email?: string | null
          invitedAs?: RoleEnum | null
          invitationStatus?: InvitationStatusEnum | null
          ScalisUser?: {
            __typename?: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
            activeStatus?: boolean | null
            profileImage?: string | null
          } | null
        } | null
      } | null
    }> | null
  }> | null
}

export type GetSideBarRequisitionsQueryVariables = Exact<{
  status?: InputMaybe<JobStatusEnum>
}>

export type GetSideBarRequisitionsQuery = {
  __typename?: 'Query'
  GetRequisitions?: Array<{
    __typename?: 'Requisition'
    id: string
    jobStatus?: JobStatusEnum | null
    jobName?: string | null
    extRequisitionId?: string | null
    jobPipelines?: Array<{
      __typename?: 'JobPipeline'
      id: string
      title?: string | null
      TemplatePipeline?: {
        __typename?: 'TemplatePipeline'
        id: string
        deletedAt?: any | null
        TemplateMilestones?: Array<{
          __typename?: 'TemplateMilestone'
          id: string
          name?: NameEnum | null
          rankOrder?: number | null
          TemplateStage?: Array<{
            __typename?: 'TemplateStage'
            id: string
            name?: string | null
            rankOrder?: number | null
          }> | null
        }> | null
      } | null
    }> | null
  }> | null
}

export type GetRequisitionJobDescriptionByRequisitionQueryVariables = Exact<{
  requisitionId: Scalars['Int']['input']
}>

export type GetRequisitionJobDescriptionByRequisitionQuery = {
  __typename?: 'Query'
  GetRequisitionJobDescriptionByRequisition?: {
    __typename?: 'RequisitionJobDescription'
    id: string
    requisitionId?: number | null
    description?: string | null
    createdAt?: any | null
    updatedAt?: any | null
    requisition?: { __typename?: 'Requisition'; id: string; openedAt?: any | null } | null
  } | null
}

export type GetRequisitionJobDescriptionQueryVariables = Exact<{
  requisitionId: Scalars['Int']['input']
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetRequisitionJobDescriptionQuery = {
  __typename?: 'Query'
  GetRequisitionJobDescription?: Array<{
    __typename?: 'RequisitionJobDescription'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    requisitionId?: number | null
    description?: string | null
    hiringTeamMember?: Array<{
      __typename?: 'HiringTeamMember'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      companyUserId?: number | null
      roleId?: number | null
      requisitionId?: number | null
      requisitionJobDescriptionId?: number | null
      role?: { __typename?: 'Role'; id: string; name: string } | null
    }> | null
    candidateCollaborator?: Array<{
      __typename?: 'CandidateCollaborator'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      requisitionId?: number | null
      companyUserId?: number | null
      candidateProfileId?: number | null
      roleId?: number | null
      requisitionJobDescriptionId?: number | null
      role?: { __typename?: 'Role'; id: string; name: string } | null
    }> | null
    stageCollaborator?: Array<{
      __typename?: 'StageCollaborator'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      requisitionId?: number | null
      companyUserId?: number | null
      templateStageId?: number | null
      roleId?: number | null
      requisitionJobDescriptionId?: number | null
      role?: { __typename?: 'Role'; id: string; name: string } | null
    }> | null
  }> | null
}

export type GetTemplateJobDescriptionQueryVariables = Exact<{
  companyUserId?: InputMaybe<Scalars['Int']['input']>
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}>

export type GetTemplateJobDescriptionQuery = {
  __typename?: 'Query'
  GetTemplateJobDescription?: Array<{
    __typename?: 'TemplateJobDescription'
    id: string
    companyId?: number | null
    companyUserId?: number | null
    name?: string | null
    description?: string | null
    companyDepartmentId?: string | null
    createdAt?: any | null
    updatedAt?: any | null
    companyDepartment?: {
      __typename?: 'CompanyDepartment'
      id: string
      companyId?: number | null
      departmentName?: string | null
      subordinateTo?: number | null
      externalId?: string | null
      createdAt?: any | null
      updatedAt?: any | null
    } | null
  }> | null
}

export type GetRequisitionSkillQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetRequisitionSkillQuery = {
  __typename?: 'Query'
  GetRequisitionSkill?: {
    __typename?: 'RequisitionSkill'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    requisitionId?: number | null
    skillId?: number | null
    sequenceNumber?: number | null
    skill?: {
      __typename?: 'Skill'
      id: string
      experienceId?: number | null
      name?: string | null
      level?: string | null
      yearsOfExp?: number | null
    } | null
  } | null
}

export type GetJobListingScreeningQuestionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  jobListingId?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<SortDirection>
  candidateApplicationId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetJobListingScreeningQuestionQuery = {
  __typename?: 'Query'
  GetJobListingScreeningQuestion?: Array<{
    __typename?: 'JobListingScreeningQuestion'
    id: string
    jobListingId?: number | null
    displaySequenceNumber?: number | null
    screeningQuestionId?: number | null
    createdAt?: any | null
    updatedAt?: any | null
    ScreeningQuestion?: {
      __typename?: 'ScreeningQuestion'
      id: string
      isTemplateQuestion?: boolean | null
      questionTitle?: string | null
      questionDesc?: string | null
      questionType?: QuestionTypeEnum | null
      minVal?: number | null
      maxVal?: number | null
      valStatus?: string | null
      ansOptions?: string | null
      isRejectionQuestion?: boolean | null
      rejectMessage?: string | null
      templateMessageId?: number | null
      preferredAnswer?: string | null
      companyId?: number | null
      companyUserId?: number | null
      createdAt?: any | null
      updatedAt?: any | null
    } | null
    CandidateAnswer?: {
      __typename?: 'ScreeningQuestionAnswer'
      id: string
      answer?: string | null
      answerStatus?: boolean | null
      candidateApplicationId?: number | null
      listingScreeningQuestionId?: number | null
      createdAt?: any | null
      updatedAt?: any | null
    } | null
  }> | null
}

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetRolesQuery = {
  __typename?: 'Query'
  GetRoles?: Array<{ __typename?: 'Role'; id: string; name: string }> | null
}

export type GetEmailActivationQueryVariables = Exact<{
  email: Scalars['String']['input']
}>

export type GetEmailActivationQuery = { __typename?: 'Query'; GetEmailActivation: boolean }

export type GetSchedulerLinkSettingsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetSchedulerLinkSettingsQuery = {
  __typename?: 'Query'
  GetSchedulerLinkSettings?: {
    __typename?: 'SchedulerLinkSettings'
    id: string
    minimumHour: number
    rollingDays: number
    maxInterviewsPerDay: number
  } | null
}

export type GetScorecardTemplateQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetScorecardTemplateQuery = {
  __typename?: 'Query'
  GetScorecardTemplate?: Array<{
    __typename?: 'ScorecardTemplate'
    id: string
    archived?: boolean | null
    department?: string | null
    scorecardName?: string | null
    scorecardDescription?: string | null
    visible?: boolean | null
    companyTemplate?: boolean | null
    timestampAt?: any | null
    createdAt?: any | null
    ScorecardTemplateSection: Array<{
      __typename?: 'ScorecardTemplateSection'
      id: string
      rankOrder?: number | null
      scorecardTemplateId?: number | null
      sectionName?: string | null
      sectionType?: SectionTypeEnum | null
      ScorecardTemplateQuestion: Array<{
        __typename?: 'ScorecardTemplateQuestion'
        id: string
        answerOptions?: any | null
        preferredAnswer?: string | null
        questionDescription?: string | null
        questionText?: string | null
        questionType?: ScorecardQuestionTypeEnum | null
        scoreAnswer?: boolean | null
        scorecardTemplateSectionId?: number | null
        skillId?: number | null
        Skill?: { __typename?: 'Skill'; id: string; name?: string | null } | null
        CandidateScorecardAnswers?: Array<{
          __typename?: 'CandidateScorecardAnswers'
          id: string
          answer?: any | null
          answerPreferredStatus?: boolean | null
          candidateScorecardId?: number | null
          dateAnswer?: any | null
          scoreAnswer?: number | null
          scorecardTemplateQuestionId?: number | null
        }> | null
      }>
    }>
  }> | null
}

export type GetCompareScorecardTemplatesQuestionFragment = {
  __typename?: 'ScorecardTemplateQuestion'
  id: string
  answerOptions?: any | null
  preferredAnswer?: string | null
  questionDescription?: string | null
  questionText?: string | null
  questionType?: ScorecardQuestionTypeEnum | null
  scoreAnswer?: boolean | null
  scorecardTemplateSectionId?: number | null
  skillId?: number | null
  Skill?: { __typename?: 'Skill'; id: string; name?: string | null } | null
  CandidateScorecardAnswers?: Array<{
    __typename?: 'CandidateScorecardAnswers'
    answer?: any | null
    answerPreferredStatus?: boolean | null
    candidateScorecardId?: number | null
    dateAnswer?: any | null
    id: string
    overallRecommendation?: OverallRecommendationEnum | null
    scoreAnswer?: number | null
    scorecardTemplateQuestionId?: number | null
  }> | null
}

export type GetCompareScorecardTemplatesCandidateScorecardFragment = {
  __typename?: 'CandidateScorecard'
  id: string
  companyUserId?: number | null
  candidateApplicationId?: number | null
  additionalInformation?: string | null
  scorecardTemplateId?: number | null
  status?: ScorecardStatusTypeEnum | null
  templateStageId?: number | null
  CompanyUser?: {
    __typename?: 'CompanyUser'
    companyId: number
    companyUserStatus?: CompanyUserStatusEnum | null
    id: string
    profileImage?: string | null
    userInvitationId?: number | null
    UserInvitation?: {
      __typename?: 'UserInvitation'
      ScalisUser?: {
        __typename?: 'ScalisUser'
        id: number
        firstName?: string | null
        lastName?: string | null
        profileImage?: string | null
      } | null
    } | null
  } | null
  CandidateApplication?: {
    __typename?: 'CandidateApplication'
    id: string
    CandidateProfile?: {
      __typename?: 'CandidateProfile'
      id: string
      ScalisUser?: {
        __typename?: 'ScalisUser'
        id: number
        firstName?: string | null
        lastName?: string | null
        currentStage?: string | null
        completedAt?: any | null
        activeStatus?: boolean | null
        profileImage?: string | null
      } | null
    } | null
  } | null
  CandidateScorecardAnswers: Array<{
    __typename?: 'CandidateScorecardAnswers'
    answer?: any | null
    answerPreferredStatus?: boolean | null
    candidateScorecardId?: number | null
    dateAnswer?: any | null
    id: string
    overallRecommendation?: OverallRecommendationEnum | null
    scoreAnswer?: number | null
    scorecardTemplateQuestionId?: number | null
    ScorecardTemplateQuestion?: {
      __typename?: 'ScorecardTemplateQuestion'
      answerOptions?: any | null
      id: string
      preferredAnswer?: string | null
      questionDescription?: string | null
      questionText?: string | null
      questionType?: ScorecardQuestionTypeEnum | null
      scoreAnswer?: boolean | null
      scorecardTemplateSectionId?: number | null
      skillId?: number | null
      Skill?: {
        __typename?: 'Skill'
        name?: string | null
        level?: string | null
        id: string
        experienceId?: number | null
        yearsOfExp?: number | null
      } | null
    } | null
  }>
}

export type GetScorecardTemplatesForCompareScorecardQueryVariables = Exact<{
  input: GetScorecardTemplatesInput
}>

export type GetScorecardTemplatesForCompareScorecardQuery = {
  __typename?: 'Query'
  GetScorecardTemplates?: Array<{
    __typename?: 'ScorecardTemplate'
    id: string
    archived?: boolean | null
    department?: string | null
    scorecardName?: string | null
    companyTemplate?: boolean | null
    timestampAt?: any | null
    createdAt?: any | null
    ScorecardTemplateSection: Array<{
      __typename?: 'ScorecardTemplateSection'
      id: string
      rankOrder?: number | null
      scorecardTemplateId?: number | null
      sectionName?: string | null
      sectionType?: SectionTypeEnum | null
      ScorecardTemplateQuestion: Array<{
        __typename?: 'ScorecardTemplateQuestion'
        id: string
        answerOptions?: any | null
        preferredAnswer?: string | null
        questionDescription?: string | null
        questionText?: string | null
        questionType?: ScorecardQuestionTypeEnum | null
        scoreAnswer?: boolean | null
        scorecardTemplateSectionId?: number | null
        skillId?: number | null
        Skill?: { __typename?: 'Skill'; id: string; name?: string | null } | null
        CandidateScorecardAnswers?: Array<{
          __typename?: 'CandidateScorecardAnswers'
          answer?: any | null
          answerPreferredStatus?: boolean | null
          candidateScorecardId?: number | null
          dateAnswer?: any | null
          id: string
          overallRecommendation?: OverallRecommendationEnum | null
          scoreAnswer?: number | null
          scorecardTemplateQuestionId?: number | null
        }> | null
      }>
    }>
    CompanyUser?: {
      __typename?: 'CompanyUser'
      id: string
      UserInvitation?: {
        __typename?: 'UserInvitation'
        id: number
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
        } | null
      } | null
    } | null
    CandidateScorecard: Array<{
      __typename?: 'CandidateScorecard'
      id: string
      companyUserId?: number | null
      candidateApplicationId?: number | null
      additionalInformation?: string | null
      scorecardTemplateId?: number | null
      status?: ScorecardStatusTypeEnum | null
      templateStageId?: number | null
      CompanyUser?: {
        __typename?: 'CompanyUser'
        companyId: number
        companyUserStatus?: CompanyUserStatusEnum | null
        id: string
        profileImage?: string | null
        userInvitationId?: number | null
        UserInvitation?: {
          __typename?: 'UserInvitation'
          ScalisUser?: {
            __typename?: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
            profileImage?: string | null
          } | null
        } | null
      } | null
      CandidateApplication?: {
        __typename?: 'CandidateApplication'
        id: string
        CandidateProfile?: {
          __typename?: 'CandidateProfile'
          id: string
          ScalisUser?: {
            __typename?: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
            currentStage?: string | null
            completedAt?: any | null
            activeStatus?: boolean | null
            profileImage?: string | null
          } | null
        } | null
      } | null
      CandidateScorecardAnswers: Array<{
        __typename?: 'CandidateScorecardAnswers'
        answer?: any | null
        answerPreferredStatus?: boolean | null
        candidateScorecardId?: number | null
        dateAnswer?: any | null
        id: string
        overallRecommendation?: OverallRecommendationEnum | null
        scoreAnswer?: number | null
        scorecardTemplateQuestionId?: number | null
        ScorecardTemplateQuestion?: {
          __typename?: 'ScorecardTemplateQuestion'
          answerOptions?: any | null
          id: string
          preferredAnswer?: string | null
          questionDescription?: string | null
          questionText?: string | null
          questionType?: ScorecardQuestionTypeEnum | null
          scoreAnswer?: boolean | null
          scorecardTemplateSectionId?: number | null
          skillId?: number | null
          Skill?: {
            __typename?: 'Skill'
            name?: string | null
            level?: string | null
            id: string
            experienceId?: number | null
            yearsOfExp?: number | null
          } | null
        } | null
      }>
    }>
  }> | null
}

export type GetScorecardTemplatesQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
  visible?: InputMaybe<Scalars['Boolean']['input']>
  sortBy?: InputMaybe<SortByScorecardTemplate>
  sortDirection?: InputMaybe<SortDirection>
}>

export type GetScorecardTemplatesQuery = {
  __typename?: 'Query'
  GetScorecardTemplate?: Array<{
    __typename?: 'ScorecardTemplate'
    id: string
    archived?: boolean | null
    department?: string | null
    scorecardName?: string | null
    scorecardDescription?: string | null
    visible?: boolean | null
    companyTemplate?: boolean | null
    timestampAt?: any | null
    createdAt?: any | null
    ScorecardTemplateSection: Array<{
      __typename?: 'ScorecardTemplateSection'
      id: string
      rankOrder?: number | null
      scorecardTemplateId?: number | null
      sectionName?: string | null
      sectionType?: SectionTypeEnum | null
      ScorecardTemplateQuestion: Array<{
        __typename?: 'ScorecardTemplateQuestion'
        id: string
        answerOptions?: any | null
        preferredAnswer?: string | null
        questionDescription?: string | null
        questionText?: string | null
        questionType?: ScorecardQuestionTypeEnum | null
        scoreAnswer?: boolean | null
        scorecardTemplateSectionId?: number | null
        skillId?: number | null
        Skill?: { __typename?: 'Skill'; id: string; name?: string | null } | null
      }>
    }>
    CompanyUser?: {
      __typename?: 'CompanyUser'
      id: string
      UserInvitation?: {
        __typename?: 'UserInvitation'
        ScalisUser?: { __typename?: 'ScalisUser'; firstName?: string | null; lastName?: string | null } | null
      } | null
    } | null
  }> | null
}

export type GetSearchResultsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  educationLevel?: InputMaybe<EducationLevelPrefEnum>
  employment?: InputMaybe<JobTypeEnum>
  experienceLevel?: InputMaybe<JobLevelEnum>
  id?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  payment?: InputMaybe<PayTypeEnum>
  search?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<SearchSortEnum>
  tags?: InputMaybe<Scalars['String']['input']>
  workplace?: InputMaybe<LocationTypeEnum>
}>

export type GetSearchResultsQuery = {
  __typename?: 'Query'
  Search?: {
    __typename?: 'SearchResult'
    count: number
    filters: Array<{
      __typename?: 'FiltersType'
      key: string
      values: Array<{ __typename?: 'FilterValueType'; count: number; value: string }>
    }>
    results: Array<{
      __typename?: 'JobSearchResultType'
      benefits?: Array<string> | null
      createdAt?: string | null
      department?: string | null
      description: string
      descriptionHtml?: string | null
      educationLevel?: EducationLevelPrefEnum | null
      employment?: JobTypeEnum | null
      experienceLevel?: JobLevelEnum | null
      id: string
      isApplied?: boolean | null
      isBookmarked?: boolean | null
      payment?: PayTypeEnum | null
      skills?: Array<string> | null
      source?: SourceTypeEnum | null
      title: string
      url: string
      workplace?: LocationTypeEnum | null
      company?: {
        __typename?: 'CompanyType'
        id: string
        logo?: string | null
        name?: string | null
        location?: {
          __typename?: 'LocationType'
          city?: string | null
          continent?: string | null
          country?: string | null
          region?: string | null
        } | null
      } | null
      locations?: Array<{
        __typename?: 'LocationType'
        city?: string | null
        region?: string | null
        country?: string | null
        continent?: string | null
      }> | null
      salary?: { __typename?: 'SalaryType'; min?: number | null; max?: number | null; currency?: string | null } | null
    }>
  } | null
}

export type DeleteOfferSectionTemplateMutationVariables = Exact<{
  input: DeleteOfferSectionTemplateInput
}>

export type DeleteOfferSectionTemplateMutation = {
  __typename?: 'Mutation'
  DeleteOfferSectionTemplate: { __typename?: 'OfferSectionTemplate'; id: number }
}

export type GetOfferSectionTemplateQueryVariables = Exact<{ [key: string]: never }>

export type GetOfferSectionTemplateQuery = {
  __typename?: 'Query'
  GetOfferSectionTemplate?: Array<{
    __typename?: 'OfferSectionTemplate'
    id: number
    sectionTemplateName: string
    sectionToken: string
    sectionTemplateText: string
    sectionTemplateStatus: TokenStatusEnum
    tokenDescription?: string | null
    updatedAt?: any | null
    CompanyUser?: {
      __typename?: 'CompanyUser'
      id: string
      UserInvitation?: {
        __typename?: 'UserInvitation'
        id: number
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
          profileImage?: string | null
        } | null
      } | null
    } | null
    OfferTemplateCondition?: Array<{
      __typename?: 'OfferTemplateCondition'
      id: string
      officeId?: number | null
      payType?: PayTypeEnum | null
      employmentType?: JobTypeEnum | null
      departmentId?: number | null
    }> | null
  }> | null
}

export type OfferSectionTemplateFragment = {
  __typename?: 'OfferSectionTemplate'
  id: number
  sectionTemplateName: string
  sectionToken: string
  sectionTemplateText: string
  sectionTemplateStatus: TokenStatusEnum
  tokenDescription?: string | null
  updatedAt?: any | null
  CompanyUser?: {
    __typename?: 'CompanyUser'
    id: string
    UserInvitation?: {
      __typename?: 'UserInvitation'
      id: number
      ScalisUser?: {
        __typename?: 'ScalisUser'
        id: number
        firstName?: string | null
        lastName?: string | null
        profileImage?: string | null
      } | null
    } | null
  } | null
  OfferTemplateCondition?: Array<{
    __typename?: 'OfferTemplateCondition'
    id: string
    officeId?: number | null
    payType?: PayTypeEnum | null
    employmentType?: JobTypeEnum | null
    departmentId?: number | null
  }> | null
}

export type UpsertOfferSectionTemplateMutationVariables = Exact<{
  input: UpsertOfferSectionTemplateInput
}>

export type UpsertOfferSectionTemplateMutation = {
  __typename?: 'Mutation'
  UpsertOfferSectionTemplate?: {
    __typename?: 'OfferSectionTemplate'
    id: number
    sectionTemplateName: string
    sectionToken: string
    sectionTemplateText: string
    sectionTemplateStatus: TokenStatusEnum
    tokenDescription?: string | null
    updatedAt?: any | null
    CompanyUser?: {
      __typename?: 'CompanyUser'
      id: string
      UserInvitation?: {
        __typename?: 'UserInvitation'
        id: number
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
          profileImage?: string | null
        } | null
      } | null
    } | null
    OfferTemplateCondition?: Array<{
      __typename?: 'OfferTemplateCondition'
      id: string
      officeId?: number | null
      payType?: PayTypeEnum | null
      employmentType?: JobTypeEnum | null
      departmentId?: number | null
    }> | null
  } | null
}

export type ValidateOfferSectionTemplateMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ValidateOfferSectionTemplateMutation = {
  __typename?: 'Mutation'
  ValidateOfferSectionTemplate: {
    __typename?: 'OfferSectionTemplate'
    id: number
    sectionTemplateStatus: TokenStatusEnum
    validationResult?: any | null
  }
}

export type GetSkillsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  experienceId?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetSkillsQuery = {
  __typename?: 'Query'
  GetSkills?: Array<{
    __typename?: 'Skill'
    id: string
    experienceId?: number | null
    name?: string | null
    level?: string | null
    yearsOfExp?: number | null
  }> | null
}

export type GetScreenStageCollaboratorsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetScreenStageCollaboratorsQuery = {
  __typename?: 'Query'
  GetTemplateStage?: Array<{
    __typename?: 'TemplateStage'
    StageCollaborator?: Array<{
      __typename: 'StageCollaborator'
      id: string
      CompanyUser?: {
        __typename: 'CompanyUser'
        id: string
        profileImage?: string | null
        UserInvitation?: {
          __typename: 'UserInvitation'
          id: number
          email?: string | null
          ScalisUser?: {
            __typename: 'ScalisUser'
            id: number
            firstName?: string | null
            lastName?: string | null
          } | null
        } | null
      } | null
    }> | null
  }> | null
}

export type GetStageCollaboratorQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetStageCollaboratorQuery = {
  __typename?: 'Query'
  GetStageCollaborator?: {
    __typename?: 'StageCollaborator'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    requisitionId?: number | null
    companyUserId?: number | null
    templateStageId?: number | null
    roleId?: number | null
    requisitionJobDescriptionId?: number | null
    role?: { __typename?: 'Role'; id: string; name: string } | null
  } | null
}

export type GetStageSettingsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetStageSettingsQuery = {
  __typename?: 'Query'
  GetStageSettings?: {
    __typename?: 'StageSettings'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    jobPipelineId?: number | null
    templateStageId?: number | null
    scorecardTemplateId?: number | null
    JobPipeline?: {
      __typename?: 'JobPipeline'
      id: string
      title?: string | null
      jobRequisitionId?: number | null
      templatePipelineId?: number | null
    } | null
    TemplateStage?: {
      __typename?: 'TemplateStage'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      templatePipelineId?: number | null
      visibility?: TemplateStageVisibilityEnum | null
      name?: string | null
      templateMilestoneId?: number | null
      rankOrder?: number | null
      archived?: boolean | null
    } | null
  } | null
}

export type ListTemplateMessagesQueryVariables = Exact<{
  input: ListTemplateMessageInput
}>

export type ListTemplateMessagesQuery = {
  __typename?: 'Query'
  ListTemplateMessages?: Array<{
    __typename?: 'TemplateMessage'
    id: string
    title?: string | null
    message?: string | null
    messageType?: MessageTypeEnum | null
    messageOwner?: MessageOwnerEnum | null
    createdAt?: any | null
    updatedAt?: any | null
  }> | null
}

export type GetTemplateMilestoneQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetTemplateMilestoneQuery = {
  __typename?: 'Query'
  GetTemplateMilestone?: {
    __typename?: 'TemplateMilestone'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    templatePipelineId?: number | null
    name?: NameEnum | null
    rankOrder?: number | null
    archived?: boolean | null
    TemplatePipeline?: {
      __typename?: 'TemplatePipeline'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      companyId?: number | null
      companyUserId?: number | null
      name?: string | null
      companyDepartmentId?: number | null
      archived?: boolean | null
    } | null
    TemplateStage?: Array<{
      __typename?: 'TemplateStage'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      templatePipelineId?: number | null
      visibility?: TemplateStageVisibilityEnum | null
      name?: string | null
      templateMilestoneId?: number | null
      rankOrder?: number | null
      archived?: boolean | null
    }> | null
  } | null
}

export type GetTemplatePipelineQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetTemplatePipelineQuery = {
  __typename?: 'Query'
  GetTemplatePipeline?: {
    __typename?: 'TemplatePipeline'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    companyId?: number | null
    companyUserId?: number | null
    name?: string | null
    companyDepartmentId?: number | null
    archived?: boolean | null
    TemplateMilestones?: Array<{
      __typename?: 'TemplateMilestone'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      templatePipelineId?: number | null
      name?: NameEnum | null
      rankOrder?: number | null
      archived?: boolean | null
    }> | null
    TemplateStage?: Array<{
      __typename?: 'TemplateStage'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      templatePipelineId?: number | null
      visibility?: TemplateStageVisibilityEnum | null
      name?: string | null
      templateMilestoneId?: number | null
      rankOrder?: number | null
      archived?: boolean | null
    }> | null
    JobPipeline?: Array<{
      __typename?: 'JobPipeline'
      id: string
      title?: string | null
      jobRequisitionId?: number | null
      templatePipelineId?: number | null
    }> | null
    CompanyDepartment?: {
      __typename?: 'CompanyDepartment'
      id: string
      companyId?: number | null
      departmentName?: string | null
      subordinateTo?: number | null
      externalId?: string | null
      createdAt?: any | null
      updatedAt?: any | null
    } | null
  } | null
}

export type ListTemplatePipelinesQueryVariables = Exact<{
  companyDepartmentId?: InputMaybe<Scalars['Int']['input']>
  archived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListTemplatePipelinesQuery = {
  __typename?: 'Query'
  ListTemplatePipelines?: Array<{
    __typename?: 'TemplatePipeline'
    id: string
    name?: string | null
    companyDepartmentId?: number | null
    pipelinedUsed?: boolean | null
    archived?: boolean | null
    createdAt?: any | null
    updatedAt?: any | null
    deletedAt?: any | null
    CompanyDepartment?: {
      __typename?: 'CompanyDepartment'
      id: string
      departmentName?: string | null
      subordinateTo?: number | null
      externalId?: string | null
      createdAt?: any | null
      updatedAt?: any | null
    } | null
    CompanyUser?: {
      __typename?: 'CompanyUser'
      id: string
      profileImage?: string | null
      UserInvitation?: {
        __typename?: 'UserInvitation'
        id: number
        ScalisUser?: {
          __typename?: 'ScalisUser'
          id: number
          firstName?: string | null
          lastName?: string | null
          profileImage?: string | null
        } | null
      } | null
    } | null
    TemplateMilestones?: Array<{
      __typename?: 'TemplateMilestone'
      id: string
      name?: NameEnum | null
      rankOrder?: number | null
      TemplateStage?: Array<{
        __typename?: 'TemplateStage'
        id: string
        rankOrder?: number | null
        name?: string | null
        visibility?: TemplateStageVisibilityEnum | null
      }> | null
    }> | null
    TemplateStage?: Array<{ __typename?: 'TemplateStage'; id: string; name?: string | null }> | null
  }> | null
}

export type GetTemplateStageModalDataQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetTemplateStageModalDataQuery = {
  __typename?: 'Query'
  GetTemplateStage?: Array<{
    __typename: 'TemplateStage'
    id: string
    name?: string | null
    TemplateMilestone?: { __typename: 'TemplateMilestone'; id: string; name?: NameEnum | null } | null
    TemplatePipeline?: {
      __typename: 'TemplatePipeline'
      id: string
      JobPipeline?: Array<{ __typename: 'JobPipeline'; id: string; jobRequisitionId?: number | null }> | null
    } | null
  }> | null
}

export type GetTemplateStageQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetTemplateStageQuery = {
  __typename?: 'Query'
  GetTemplateStage?: Array<{
    __typename?: 'TemplateStage'
    createdAt?: any | null
    updatedAt?: any | null
    id: string
    templatePipelineId?: number | null
    visibility?: TemplateStageVisibilityEnum | null
    name?: string | null
    templateMilestoneId?: number | null
    rankOrder?: number | null
    archived?: boolean | null
    StageSettings?: Array<{
      __typename?: 'StageSettings'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      jobPipelineId?: number | null
      templateStageId?: number | null
      scorecardTemplateId?: number | null
    }> | null
    TemplateMilestone?: {
      __typename?: 'TemplateMilestone'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      templatePipelineId?: number | null
      name?: NameEnum | null
      rankOrder?: number | null
      archived?: boolean | null
    } | null
    TemplatePipeline?: {
      __typename?: 'TemplatePipeline'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      companyId?: number | null
      companyUserId?: number | null
      name?: string | null
      companyDepartmentId?: number | null
      archived?: boolean | null
    } | null
    StageCollaborator?: Array<{
      __typename?: 'StageCollaborator'
      createdAt?: any | null
      updatedAt?: any | null
      id: string
      requisitionId?: number | null
      companyUserId?: number | null
      templateStageId?: number | null
      roleId?: number | null
      requisitionJobDescriptionId?: number | null
      role?: { __typename?: 'Role'; id: string; name: string } | null
    }> | null
  }> | null
}

export type GetTemporaryS3UrlQueryVariables = Exact<{
  input: GetTemporaryS3UrlInput
}>

export type GetTemporaryS3UrlQuery = {
  __typename?: 'Query'
  GetTemporaryS3Url?: { __typename?: 'GetTemporaryS3UrlOutput'; tempUrl: string } | null
}

export type GetUploadedCandidateQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetUploadedCandidateQuery = {
  __typename?: 'Query'
  GetUploadedCandidate?: {
    __typename?: 'UploadedCandidate'
    id: number
    emailAddress?: string | null
    firstName?: string | null
    lastName?: string | null
    linkedinProfile?: string | null
    profileImage?: string | null
    uploadedCandidateType?: UploadedCandidateTypeEnum | null
    CandidateProfile: { __typename?: 'CandidateProfile'; id: string; profileType?: string | null }
    Achievement: Array<{
      __typename?: 'UploadedCandidateAchievement'
      id: number
      name?: string | null
      issuingAuthority?: string | null
      issueYear?: number | null
      description?: any | null
      searchDescription?: string | null
    }>
    CertificateOrLicense: Array<{
      __typename?: 'UploadedCandidateCertificateOrLicense'
      id: number
      name?: string | null
      issuingAuthority?: string | null
      issueYear?: number | null
      description?: any | null
      searchDescription?: string | null
    }>
    Education: Array<{
      __typename?: 'UploadedCandidateEducation'
      id: number
      institutionName?: string | null
      currentlyStudying?: boolean | null
      educationLevel?: EducationLevelEnum | null
      startDate?: any | null
      endDate?: any | null
      areasOfStudy?: any | null
      majors?: any | null
      minors?: any | null
      cumGPA?: number | null
      cumGPAScale?: number | null
      hideCumGPA?: boolean | null
      hideDepartmentGPA?: boolean | null
      departmentCumGPA?: number | null
      departmentCumGPAScale?: number | null
      additionalInformation?: string | null
    }>
    Experience: Array<{
      __typename?: 'UploadedCandidateExperience'
      id: number
      startDate?: any | null
      endDate?: any | null
      locationType?: string | null
      location?: string | null
      shortFormDesc?: any | null
      longFormDesc?: string | null
      searchShortFormDesc?: string | null
      searchLongFormDesc?: string | null
      industry?: IndustryEnum | null
      subIndustry?: string | null
      payType?: string | null
      jobPay?: number | null
      additionalComp?: boolean | null
      jobTitle?: string | null
      employerName?: string | null
      managementPosition?: boolean | null
      teamSize?: TeamSizeEnum | null
      workPlaceCandidate?: WorkPlaceCandidateEnum | null
      isCurrentJob?: boolean | null
      jobType?: JobTypeEnum | null
      isCurrentlyWorking?: boolean | null
      description?: string | null
    }>
    JobSeekerSkill?: Array<{
      __typename?: 'UploadedCandidateJobSeekerSkills'
      id: number
      sequenceNumber?: number | null
      level?: string | null
      name?: string | null
      yearsOfExp?: number | null
    }> | null
    VolunteerWork: Array<{
      __typename?: 'UploadedCandidateVolunteerWork'
      id: number
      volunteerJobTitle?: string | null
      organizationName?: string | null
      isCurrentlyWorking?: boolean | null
      roleType?: RoleTypeEnum | null
      startDate?: any | null
      endDate?: any | null
      description?: any | null
      searchDescription?: string | null
      location?: string | null
    }>
    WebsiteOrSocialMedia: Array<{
      __typename?: 'UploadedCandidateWebsiteOrSocialMedia'
      id: number
      websiteURL: string
      type: WebsiteTypeEnum
    }>
  } | null
}

export type GetAccountInformationQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountInformationQuery = {
  __typename?: 'Query'
  Me?: {
    __typename?: 'ScalisUser'
    id: number
    firstName?: string | null
    lastName?: string | null
    Address?: Array<{
      __typename?: 'Address'
      id: number
      addressLine1?: string | null
      addressLine2?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      postalCode?: string | null
      timeZone?: string | null
    }> | null
    ContactInfo?: Array<{ __typename?: 'ContactInfo'; id: number; phoneNumber?: string | null }> | null
  } | null
}

export type GetEmailsQueryVariables = Exact<{
  scalisUserId: Scalars['Int']['input']
}>

export type GetEmailsQuery = {
  __typename?: 'Query'
  GetScalisUser?: {
    __typename?: 'ScalisUser'
    EmailVerification?: Array<{
      __typename: 'EmailVerification'
      id: number
      emailAddress?: string | null
      isPrimary?: boolean | null
      isVisibleToEmployers?: boolean | null
      activeStatus?: boolean | null
      emailVerified?: boolean | null
    }> | null
  } | null
}

export type GetScalisUserIdQueryVariables = Exact<{ [key: string]: never }>

export type GetScalisUserIdQuery = { __typename?: 'Query'; Me?: { __typename?: 'ScalisUser'; id: number } | null }

export const AddressFragmentFragmentDoc = gql`
  fragment AddressFragment on Address {
    id
    addressLine1
    addressLine2
    city
    state
    country
    postalCode
    timeZone
  }
`
export const ApplicantNotesFragmentFragmentDoc = gql`
  fragment ApplicantNotesFragment on ApplicantNotes {
    id
    createdAt
    candidateApplicationId
    scalisUserId
    UserNote {
      id
      noteText
    }
  }
`
export const CandidateCollaboratorFragmentFragmentDoc = gql`
  fragment CandidateCollaboratorFragment on CandidateCollaborator {
    id
    requisitionId
    companyUserId
    candidateProfileId
    roleId
    requisitionJobDescriptionId
  }
`
export const CompanyDepartmentFragmentFragmentDoc = gql`
  fragment CompanyDepartmentFragment on CompanyDepartment {
    id
    companyId
    departmentName
    subordinateTo
    externalId
    createdAt
    updatedAt
  }
`
export const CompanyInfoFragmentFragmentDoc = gql`
  fragment CompanyInfoFragment on CompanyInfo {
    companyName
    logo
  }
`
export const CompanyRequiredFieldsFragmentDoc = gql`
  fragment CompanyRequiredFields on Company {
    Address {
      activeStatus
      addressLine1
      addressLine2
      addressType
      city
      contactName
      createdAt
      country
      id
      latitude
      longitude
      postalCode
      scalisUserId
      timeZone
      state
      updatedAt
    }
    CompanyInfo {
      atsNeeds
      companyId
      companyType
      employeeCount
      companyName
      createdAt
      hearAboutUs
      industry
      hiringNeeds
      id
      logo
      phoneNumber
      positionsToFill
      updatedAt
      recruitingOrStaffing
      subIndustry
      usingATS
      website
      yearsInOperation
    }
    CompanyDepartment {
      companyId
      createdAt
      departmentName
      externalId
      id
      subordinateTo
      updatedAt
    }
    CompanyOffice {
      companyId
      createdAt
      externalId
      id
      officeName
      updatedAt
    }
    createdAt
    externalCompanyId
    id
    lastActivity
    subdomain
    updatedAt
  }
`
export const CoreReportFragmentDoc = gql`
  fragment CoreReport on CoreReport {
    reportName
    isFavorite
    updatedAt
    createdAt
    companyId
    id
  }
`
export const HiringTeamMemberFragmentFragmentDoc = gql`
  fragment HiringTeamMemberFragment on HiringTeamMember {
    id
    companyUserId
    roleId
    requisitionId
    requisitionJobDescriptionId
  }
`
export const JobCampaignProductFragmentFragmentDoc = gql`
  fragment JobCampaignProductFragment on JobCampaignProduct {
    product_id
    audience_group
    title
    description
    homepage
    cross_postings
    duration {
      period
      range
    }
    industries {
      id
      name
    }
    job_functions {
      id
      name
    }
    locations {
      canonical_name
      id
    }
    logo_rectangle_url {
      url
    }
    vonq_price {
      amount
      currency
    }
    time_to_process {
      period
      range
    }
  }
`
export const RequisitionJobDescriptionFragmentFragmentDoc = gql`
  fragment RequisitionJobDescriptionFragment on RequisitionJobDescription {
    id
    requisitionId
    description
  }
`
export const JobListingJobPipelineFragmentFragmentDoc = gql`
  fragment JobListingJobPipelineFragment on JobListingJobPipeline {
    id
    jobListingId
    jobPipelineId
  }
`
export const JobListingScreeningQuestionFragmentFragmentDoc = gql`
  fragment JobListingScreeningQuestionFragment on JobListingScreeningQuestion {
    id
    jobListingId
    displaySequenceNumber
    screeningQuestionId
  }
`
export const JobListingFragmentFragmentDoc = gql`
  fragment JobListingFragment on JobListing {
    id
    publicListingId
    jobRequisitionId
    listingTitle
    externalTitle
    workplaceType
    publicDescription
    displayPayOnListing
    industry
    subIndustry
    companyDefault
    stateAndOrCountry
    internalMatchingDescription
    searchPublicDescription
    searchInternalMatchingDescription
    postedAt
    acceptingCandidates
    stage
  }
`
export const JobOpeningFragmentFragmentDoc = gql`
  fragment JobOpeningFragment on JobOpening {
    id
    jobRequisitionId
    openingStatus
    extOpeningId
    companyId
    targetHireDate
    openingType
    filledDate
    startDate
    filledByApplication
    filledByCandidate
    closedReason
    closedDate
  }
`
export const JobPipelineFragmentFragmentDoc = gql`
  fragment JobPipelineFragment on JobPipeline {
    id
    title
    jobRequisitionId
    templatePipelineId
  }
`
export const RequisitionSkillsFragmentFragmentDoc = gql`
  fragment RequisitionSkillsFragment on RequisitionSkill {
    id
    requisitionId
    skillId
    sequenceNumber
  }
`
export const RequisitionFragmentFragmentDoc = gql`
  fragment RequisitionFragment on Requisition {
    id
    jobStatus
    openedAt
    jobName
    extRequisitionId
    jobType
    jobLevel
    travelType
    payType
    minPay
    maxPay
    additionalComp
    benefits
    locationDistancePref
    educationLevelPref
    yearsOfExperiencePref
    nonTraditionalPref
    veteranPref
    diverseHirePref
    collegeGradPref
  }
`
export const RoleFragmentFragmentDoc = gql`
  fragment RoleFragment on Role {
    id
    name
  }
`
export const ScalisUserAboutFragmentFragmentDoc = gql`
  fragment ScalisUserAboutFragment on ScalisUser {
    JobSeekerInfo {
      id
      aboutMe
      searchingJobs
    }
  }
`
export const ScalisUserAchievementsFragmentFragmentDoc = gql`
  fragment ScalisUserAchievementsFragment on ScalisUser {
    Achievement {
      id
      name
      issuingAuthority
      issueYear
      description
    }
  }
`
export const ScalisUserCertificatesOrLicensesFragmentFragmentDoc = gql`
  fragment ScalisUserCertificatesOrLicensesFragment on ScalisUser {
    CertificateOrLicense {
      id
      name
      issuingAuthority
      issueYear
      description
    }
  }
`
export const ScalisUserDemographicInfoFragmentFragmentDoc = gql`
  fragment ScalisUserDemographicInfoFragment on ScalisUser {
    DemographicInfo {
      gender
      raceEthnicity
      veteranStatus
    }
  }
`
export const ScalisUserEducationFragmentFragmentDoc = gql`
  fragment ScalisUserEducationFragment on ScalisUser {
    Education {
      id
      institutionName
      educationLevel
      areasOfStudy
      startDate
      endDate
      cumGPA
      cumGPAScale
      majors
      minors
      hideCumGPA
      hideDepartmentGPA
      departmentCumGPA
      additionalInformation
      currentlyStudying
    }
  }
`
export const ScalisUserExperienceFragmentFragmentDoc = gql`
  fragment ScalisUserExperienceFragment on ScalisUser {
    Experience {
      id
      jobTitle
      employerName
      jobType
      locationType
      location
      startDate
      endDate
      isCurrentlyWorking
      isCurrentJob
      description
      industry
      subIndustry
      managementPosition
      teamSize
      isCurrentlyWorking
      Skill {
        id
        name
      }
    }
  }
`
export const ScalisUserSkillsFragmentFragmentDoc = gql`
  fragment ScalisUserSkillsFragment on ScalisUser {
    JobSeekerSkill {
      id
      sequenceNumber
      level
      name
      yearsOfExp
    }
  }
`
export const ScalisUserVolunteerWorkFragmentFragmentDoc = gql`
  fragment ScalisUserVolunteerWorkFragment on ScalisUser {
    VolunteerWork {
      id
      roleType
      volunteerJobTitle
      organizationName
      location
      startDate
      endDate
      isCurrentlyWorking
      description
      searchDescription
    }
  }
`
export const ScalisUserWebsiteOrSocialFragmentFragmentDoc = gql`
  fragment ScalisUserWebsiteOrSocialFragment on ScalisUser {
    WebsiteOrSocialMedia {
      websiteURL
      type
    }
  }
`
export const ScalisUserFragmentDoc = gql`
  fragment ScalisUser on ScalisUser {
    id
    firstName
    lastName
    profileImage
  }
`
export const ScheduledReportFragmentDoc = gql`
  fragment ScheduledReport on ScheduledReport {
    coreReportId
    customDaysOfWeek
    customTime
    deleted
    endDate
    id
    recipients
    repeat
    reportSchedulerType
    startDate
  }
`
export const ScreenStageCollaboratorFragmentFragmentDoc = gql`
  fragment ScreenStageCollaboratorFragment on StageCollaborator {
    __typename
    id
    CompanyUser {
      __typename
      id
      profileImage
      UserInvitation {
        __typename
        id
        email
        ScalisUser {
          __typename
          id
          firstName
          lastName
        }
      }
    }
  }
`
export const ScreeningQuestionFragmentFragmentDoc = gql`
  fragment ScreeningQuestionFragment on ScreeningQuestion {
    id
    isTemplateQuestion
    questionTitle
    questionDesc
    questionType
    minVal
    maxVal
    valStatus
    ansOptions
    isRejectionQuestion
    rejectMessage
    templateMessageId
    preferredAnswer
    companyId
    companyUserId
  }
`
export const SharedCoreReportFragmentDoc = gql`
  fragment SharedCoreReport on SharedCoreReport {
    email
    id
    coreReportId
    companyUserId
  }
`
export const SkillFragmentFragmentDoc = gql`
  fragment SkillFragment on Skill {
    id
    experienceId
    name
    level
    yearsOfExp
  }
`
export const StageCollaboratorFragmentFragmentDoc = gql`
  fragment StageCollaboratorFragment on StageCollaborator {
    id
    requisitionId
    companyUserId
    templateStageId
    roleId
    requisitionJobDescriptionId
  }
`
export const StageSettingsFragmentFragmentDoc = gql`
  fragment StageSettingsFragment on StageSettings {
    id
    jobPipelineId
    templateStageId
    scorecardTemplateId
  }
`
export const TemplateStageKanbanFragmentDoc = gql`
  fragment TemplateStageKanban on TemplateStage {
    id
    name
    rankOrder
    visibility
    CandidateApplicationByStage(requisitionId: $id) {
      id
    }
  }
`
export const TemplateMilestoneKanbanFragmentDoc = gql`
  fragment TemplateMilestoneKanban on TemplateMilestone {
    __typename
    id
    name
    rankOrder
    TemplateStage {
      __typename
      ...TemplateStageKanban
    }
  }
  ${TemplateStageKanbanFragmentDoc}
`
export const TemplateMilestoneFragmentFragmentDoc = gql`
  fragment TemplateMilestoneFragment on TemplateMilestone {
    id
    templatePipelineId
    name
    rankOrder
    archived
  }
`
export const TemplatePipelineFragmentFragmentDoc = gql`
  fragment TemplatePipelineFragment on TemplatePipeline {
    id
    companyId
    companyUserId
    name
    companyDepartmentId
    archived
  }
`
export const TemplateStageFragmentFragmentDoc = gql`
  fragment TemplateStageFragment on TemplateStage {
    id
    templatePipelineId
    visibility
    name
    templateMilestoneId
    rankOrder
    archived
  }
`
export const UserNoteFragmentFragmentDoc = gql`
  fragment UserNoteFragment on UserNote {
    id
    noteText
  }
`
export const GetCandidateInterviewsProfileApplicationFragmentDoc = gql`
  fragment GetCandidateInterviewsProfileApplication on CandidateApplication {
    CandidateInterview {
      CalendarEvent {
        EventAttachments {
          id
          file
          eventId
        }
        Calendar {
          name
          id
          isPrimary
        }
        title
        timeZone
        startTime
        repeats
        reminders
        regardingJobs
        regardingCandidates
        nylasEventStatus
        nylasCalendarEventId
        masterEventId
        location
        isScalisEvent
        internalGuests
        id
        eventType
        employers
        endTime
        description
        conferencing
        companyUserId
        candidates
        calendarId
        CompanyUser {
          UserInvitation {
            ScalisUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      stageId
      interviewData
      id
      status
      companyUserId
      calendarEventId
      applicationId
    }
    viewedProfile
    viewedAt
    templateStageId
    matchScore
    lastUpdatedStatusAt
    jobListingId
    interviewScheduling
    id
    deleted
    createdAt
    companyId
    candidateProfileId
    appliedAt
    applicationType
    applicationTimestamp
    applicationCreationType
    applicationStatus
    TemplateStage {
      id
      name
      visibility
      TemplateMilestone {
        id
        name
      }
      TemplatePipeline {
        TemplateMilestones {
          id
          name
          rankOrder
          TemplateStage {
            name
            id
            rankOrder
            visibility
          }
        }
      }
    }
    JobListing {
      externalListingId
      externalTitle
      publicListingId
      id
      Requisition {
        id
        jobName
        extRequisitionId
      }
      jobRequisitionId
      stateAndOrCountry
      workplaceType
      Address {
        timeZone
        state
        longitude
        latitude
        country
        activeStatus
        addressLine1
        addressLine2
        addressType
        city
        postalCode
      }
    }
    CandidateScorecard {
      id
      status
      templateStageId
      ScorecardTemplate {
        id
        scorecardName
      }
      CompanyUser {
        id
        UserInvitation {
          ScalisUser {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
export const OfferCustomFieldFragmentDoc = gql`
  fragment OfferCustomField on OfferCustomField {
    id
    fieldConfigurationType
    fieldName
    fieldDescription
    generateSystemToken
    systemTokenName
    fieldRequired
    triggerNewOfferVersion
    multipleChoiceOptions
    updatedAt
    CandidateOfferCustomField {
      id
      fieldValue
    }
    OfferTemplateCondition {
      id
      officeId
      payType
      employmentType
      departmentId
    }
  }
`
export const CandidateOfferVersionFragmentDoc = gql`
  fragment CandidateOfferVersion on CandidateOfferVersion {
    id
    candidateOfferId
    version
    candidateName
    jobTitle
    manager
    startDate
    jobType
    payType
    benefits
    expirationDate
    companyOfficeId
    compensation
    additionalCompensation
    offerStatus
    lastUpdatedStatusAt
    createdAt
    CandidateOfferCustomField {
      id
      fieldValue
      OfferCustomField {
        id
        fieldName
        fieldConfigurationType
        triggerNewOfferVersion
      }
    }
    CompanyOffice {
      id
      officeName
    }
    CandidateOfferFile {
      id
      fileName
      filePath
      fileType
      createdAt
    }
  }
`
export const OfferDocumentTemplateFragmentDoc = gql`
  fragment OfferDocumentTemplate on OfferDocumentTemplate {
    id
    documentTemplateName
    documentTemplateText
  }
`
export const GetCompareScorecardTemplatesQuestionFragmentDoc = gql`
  fragment GetCompareScorecardTemplatesQuestion on ScorecardTemplateQuestion {
    id
    answerOptions
    preferredAnswer
    questionDescription
    questionText
    questionType
    scoreAnswer
    scorecardTemplateSectionId
    skillId
    Skill {
      id
      name
    }
    CandidateScorecardAnswers {
      answer
      answerPreferredStatus
      candidateScorecardId
      dateAnswer
      id
      overallRecommendation
      scoreAnswer
      scorecardTemplateQuestionId
    }
  }
`
export const GetCompareScorecardTemplatesCandidateScorecardFragmentDoc = gql`
  fragment GetCompareScorecardTemplatesCandidateScorecard on CandidateScorecard {
    id
    companyUserId
    candidateApplicationId
    additionalInformation
    scorecardTemplateId
    status
    templateStageId
    CompanyUser {
      companyId
      companyUserStatus
      id
      profileImage
      userInvitationId
      UserInvitation {
        ScalisUser {
          id
          firstName
          lastName
          profileImage
        }
      }
    }
    CandidateApplication {
      CandidateProfile {
        id
        ScalisUser {
          id
          firstName
          lastName
          currentStage
          completedAt
          activeStatus
          profileImage
        }
      }
      id
    }
    CandidateScorecardAnswers {
      answer
      answerPreferredStatus
      candidateScorecardId
      dateAnswer
      id
      overallRecommendation
      scoreAnswer
      scorecardTemplateQuestionId
      ScorecardTemplateQuestion {
        Skill {
          name
          level
          id
          experienceId
          yearsOfExp
        }
        answerOptions
        id
        preferredAnswer
        questionDescription
        questionText
        questionType
        scoreAnswer
        scorecardTemplateSectionId
        skillId
      }
    }
  }
`
export const OfferSectionTemplateFragmentDoc = gql`
  fragment OfferSectionTemplate on OfferSectionTemplate {
    id
    sectionTemplateName
    sectionToken
    sectionTemplateText
    sectionTemplateStatus
    tokenDescription
    updatedAt
    CompanyUser {
      id
      UserInvitation {
        id
        ScalisUser {
          id
          firstName
          lastName
          profileImage
        }
      }
    }
    OfferTemplateCondition {
      id
      officeId
      payType
      employmentType
      departmentId
    }
  }
`
export const UpsertAddressFormDocument = gql`
  mutation UpsertAddressForm($input: UpsertAddressInput!) {
    UpsertAddress(input: $input) {
      __typename
      id
    }
  }
`
export type UpsertAddressFormMutationFn = Apollo.MutationFunction<
  UpsertAddressFormMutation,
  UpsertAddressFormMutationVariables
>

/**
 * __useUpsertAddressFormMutation__
 *
 * To run a mutation, you first call `useUpsertAddressFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAddressFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAddressFormMutation, { data, loading, error }] = useUpsertAddressFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAddressFormMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertAddressFormMutation, UpsertAddressFormMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertAddressFormMutation, UpsertAddressFormMutationVariables>(
    UpsertAddressFormDocument,
    options,
  )
}
export type UpsertAddressFormMutationHookResult = ReturnType<typeof useUpsertAddressFormMutation>
export type UpsertAddressFormMutationResult = Apollo.MutationResult<UpsertAddressFormMutation>
export type UpsertAddressFormMutationOptions = Apollo.BaseMutationOptions<
  UpsertAddressFormMutation,
  UpsertAddressFormMutationVariables
>
export const DeleteAchievementDocument = gql`
  mutation DeleteAchievement($input: DeleteAchievementInput!) {
    DeleteAchievement(input: $input)
  }
`
export type DeleteAchievementMutationFn = Apollo.MutationFunction<
  DeleteAchievementMutation,
  DeleteAchievementMutationVariables
>

/**
 * __useDeleteAchievementMutation__
 *
 * To run a mutation, you first call `useDeleteAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAchievementMutation, { data, loading, error }] = useDeleteAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAchievementMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAchievementMutation, DeleteAchievementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAchievementMutation, DeleteAchievementMutationVariables>(
    DeleteAchievementDocument,
    options,
  )
}
export type DeleteAchievementMutationHookResult = ReturnType<typeof useDeleteAchievementMutation>
export type DeleteAchievementMutationResult = Apollo.MutationResult<DeleteAchievementMutation>
export type DeleteAchievementMutationOptions = Apollo.BaseMutationOptions<
  DeleteAchievementMutation,
  DeleteAchievementMutationVariables
>
export const DeleteCertificateOrLicenseDocument = gql`
  mutation DeleteCertificateOrLicense($input: DeleteCertificateOrLicenseInput!) {
    DeleteCertificateOrLicense(input: $input)
  }
`
export type DeleteCertificateOrLicenseMutationFn = Apollo.MutationFunction<
  DeleteCertificateOrLicenseMutation,
  DeleteCertificateOrLicenseMutationVariables
>

/**
 * __useDeleteCertificateOrLicenseMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateOrLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateOrLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateOrLicenseMutation, { data, loading, error }] = useDeleteCertificateOrLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCertificateOrLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificateOrLicenseMutation,
    DeleteCertificateOrLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCertificateOrLicenseMutation, DeleteCertificateOrLicenseMutationVariables>(
    DeleteCertificateOrLicenseDocument,
    options,
  )
}
export type DeleteCertificateOrLicenseMutationHookResult = ReturnType<typeof useDeleteCertificateOrLicenseMutation>
export type DeleteCertificateOrLicenseMutationResult = Apollo.MutationResult<DeleteCertificateOrLicenseMutation>
export type DeleteCertificateOrLicenseMutationOptions = Apollo.BaseMutationOptions<
  DeleteCertificateOrLicenseMutation,
  DeleteCertificateOrLicenseMutationVariables
>
export const DeleteEducationDocument = gql`
  mutation DeleteEducation($input: DeleteEducationInput!) {
    DeleteEducation(input: $input)
  }
`
export type DeleteEducationMutationFn = Apollo.MutationFunction<
  DeleteEducationMutation,
  DeleteEducationMutationVariables
>

/**
 * __useDeleteEducationMutation__
 *
 * To run a mutation, you first call `useDeleteEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationMutation, { data, loading, error }] = useDeleteEducationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEducationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEducationMutation, DeleteEducationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteEducationMutation, DeleteEducationMutationVariables>(DeleteEducationDocument, options)
}
export type DeleteEducationMutationHookResult = ReturnType<typeof useDeleteEducationMutation>
export type DeleteEducationMutationResult = Apollo.MutationResult<DeleteEducationMutation>
export type DeleteEducationMutationOptions = Apollo.BaseMutationOptions<
  DeleteEducationMutation,
  DeleteEducationMutationVariables
>
export const DeleteExperienceDocument = gql`
  mutation DeleteExperience($input: DeleteExperienceInput!) {
    DeleteExperience(input: $input)
  }
`
export type DeleteExperienceMutationFn = Apollo.MutationFunction<
  DeleteExperienceMutation,
  DeleteExperienceMutationVariables
>

/**
 * __useDeleteExperienceMutation__
 *
 * To run a mutation, you first call `useDeleteExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExperienceMutation, { data, loading, error }] = useDeleteExperienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteExperienceMutation, DeleteExperienceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteExperienceMutation, DeleteExperienceMutationVariables>(
    DeleteExperienceDocument,
    options,
  )
}
export type DeleteExperienceMutationHookResult = ReturnType<typeof useDeleteExperienceMutation>
export type DeleteExperienceMutationResult = Apollo.MutationResult<DeleteExperienceMutation>
export type DeleteExperienceMutationOptions = Apollo.BaseMutationOptions<
  DeleteExperienceMutation,
  DeleteExperienceMutationVariables
>
export const DeleteJobSeekerSkillDocument = gql`
  mutation DeleteJobSeekerSkill($input: DeleteJobSeekerSkillInput!) {
    DeleteJobSeekerSkill(input: $input)
  }
`
export type DeleteJobSeekerSkillMutationFn = Apollo.MutationFunction<
  DeleteJobSeekerSkillMutation,
  DeleteJobSeekerSkillMutationVariables
>

/**
 * __useDeleteJobSeekerSkillMutation__
 *
 * To run a mutation, you first call `useDeleteJobSeekerSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobSeekerSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobSeekerSkillMutation, { data, loading, error }] = useDeleteJobSeekerSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobSeekerSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJobSeekerSkillMutation, DeleteJobSeekerSkillMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteJobSeekerSkillMutation, DeleteJobSeekerSkillMutationVariables>(
    DeleteJobSeekerSkillDocument,
    options,
  )
}
export type DeleteJobSeekerSkillMutationHookResult = ReturnType<typeof useDeleteJobSeekerSkillMutation>
export type DeleteJobSeekerSkillMutationResult = Apollo.MutationResult<DeleteJobSeekerSkillMutation>
export type DeleteJobSeekerSkillMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobSeekerSkillMutation,
  DeleteJobSeekerSkillMutationVariables
>
export const DeleteVolunteerWorkDocument = gql`
  mutation DeleteVolunteerWork($input: DeleteVolunteerWorkInput!) {
    DeleteVolunteerWork(input: $input)
  }
`
export type DeleteVolunteerWorkMutationFn = Apollo.MutationFunction<
  DeleteVolunteerWorkMutation,
  DeleteVolunteerWorkMutationVariables
>

/**
 * __useDeleteVolunteerWorkMutation__
 *
 * To run a mutation, you first call `useDeleteVolunteerWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVolunteerWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVolunteerWorkMutation, { data, loading, error }] = useDeleteVolunteerWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVolunteerWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVolunteerWorkMutation, DeleteVolunteerWorkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteVolunteerWorkMutation, DeleteVolunteerWorkMutationVariables>(
    DeleteVolunteerWorkDocument,
    options,
  )
}
export type DeleteVolunteerWorkMutationHookResult = ReturnType<typeof useDeleteVolunteerWorkMutation>
export type DeleteVolunteerWorkMutationResult = Apollo.MutationResult<DeleteVolunteerWorkMutation>
export type DeleteVolunteerWorkMutationOptions = Apollo.BaseMutationOptions<
  DeleteVolunteerWorkMutation,
  DeleteVolunteerWorkMutationVariables
>
export const CreateCandidateApplicationFromJobSeekerDocument = gql`
  mutation CreateCandidateApplicationFromJobSeeker($input: CreateCandidateApplicationFromJobSeekerInput!) {
    CreateCandidateApplicationFromJobSeeker(input: $input) {
      id
    }
  }
`
export type CreateCandidateApplicationFromJobSeekerMutationFn = Apollo.MutationFunction<
  CreateCandidateApplicationFromJobSeekerMutation,
  CreateCandidateApplicationFromJobSeekerMutationVariables
>

/**
 * __useCreateCandidateApplicationFromJobSeekerMutation__
 *
 * To run a mutation, you first call `useCreateCandidateApplicationFromJobSeekerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateApplicationFromJobSeekerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateApplicationFromJobSeekerMutation, { data, loading, error }] = useCreateCandidateApplicationFromJobSeekerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCandidateApplicationFromJobSeekerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCandidateApplicationFromJobSeekerMutation,
    CreateCandidateApplicationFromJobSeekerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCandidateApplicationFromJobSeekerMutation,
    CreateCandidateApplicationFromJobSeekerMutationVariables
  >(CreateCandidateApplicationFromJobSeekerDocument, options)
}
export type CreateCandidateApplicationFromJobSeekerMutationHookResult = ReturnType<
  typeof useCreateCandidateApplicationFromJobSeekerMutation
>
export type CreateCandidateApplicationFromJobSeekerMutationResult =
  Apollo.MutationResult<CreateCandidateApplicationFromJobSeekerMutation>
export type CreateCandidateApplicationFromJobSeekerMutationOptions = Apollo.BaseMutationOptions<
  CreateCandidateApplicationFromJobSeekerMutation,
  CreateCandidateApplicationFromJobSeekerMutationVariables
>
export const UpdateScalisUserBasicInformationDocument = gql`
  mutation UpdateScalisUserBasicInformation($input: UpdateScalisUserDataInput!) {
    UpdateScalisUserData(input: $input) {
      ContactInfo {
        id
        phoneNumber
        scalisUserId
      }
      DemographicInfo {
        gender
        id
        raceEthnicity
        veteranStatus
      }
      firstName
      lastName
    }
  }
`
export type UpdateScalisUserBasicInformationMutationFn = Apollo.MutationFunction<
  UpdateScalisUserBasicInformationMutation,
  UpdateScalisUserBasicInformationMutationVariables
>

/**
 * __useUpdateScalisUserBasicInformationMutation__
 *
 * To run a mutation, you first call `useUpdateScalisUserBasicInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScalisUserBasicInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScalisUserBasicInformationMutation, { data, loading, error }] = useUpdateScalisUserBasicInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScalisUserBasicInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScalisUserBasicInformationMutation,
    UpdateScalisUserBasicInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateScalisUserBasicInformationMutation,
    UpdateScalisUserBasicInformationMutationVariables
  >(UpdateScalisUserBasicInformationDocument, options)
}
export type UpdateScalisUserBasicInformationMutationHookResult = ReturnType<
  typeof useUpdateScalisUserBasicInformationMutation
>
export type UpdateScalisUserBasicInformationMutationResult =
  Apollo.MutationResult<UpdateScalisUserBasicInformationMutation>
export type UpdateScalisUserBasicInformationMutationOptions = Apollo.BaseMutationOptions<
  UpdateScalisUserBasicInformationMutation,
  UpdateScalisUserBasicInformationMutationVariables
>
export const UpsertExperienceSkillsDocument = gql`
  mutation UpsertExperienceSkills($upsertInput: [UpsertSkillInput!]!, $deleteInput: [DeleteSkillInput!]!) {
    UpsertSkill(input: $upsertInput) {
      experienceId
      id
      level
      name
      yearsOfExp
    }
    DeleteSkill(input: $deleteInput)
  }
`
export type UpsertExperienceSkillsMutationFn = Apollo.MutationFunction<
  UpsertExperienceSkillsMutation,
  UpsertExperienceSkillsMutationVariables
>

/**
 * __useUpsertExperienceSkillsMutation__
 *
 * To run a mutation, you first call `useUpsertExperienceSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExperienceSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExperienceSkillsMutation, { data, loading, error }] = useUpsertExperienceSkillsMutation({
 *   variables: {
 *      upsertInput: // value for 'upsertInput'
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useUpsertExperienceSkillsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertExperienceSkillsMutation, UpsertExperienceSkillsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertExperienceSkillsMutation, UpsertExperienceSkillsMutationVariables>(
    UpsertExperienceSkillsDocument,
    options,
  )
}
export type UpsertExperienceSkillsMutationHookResult = ReturnType<typeof useUpsertExperienceSkillsMutation>
export type UpsertExperienceSkillsMutationResult = Apollo.MutationResult<UpsertExperienceSkillsMutation>
export type UpsertExperienceSkillsMutationOptions = Apollo.BaseMutationOptions<
  UpsertExperienceSkillsMutation,
  UpsertExperienceSkillsMutationVariables
>
export const UpdateScalisUserAboutMeDocument = gql`
  mutation UpdateScalisUserAboutMe($id: Int!, $aboutMe: String) {
    UpdateScalisUserData(input: { id: $id, JobSeekerInfo: { aboutMe: $aboutMe } }) {
      id
      JobSeekerInfo {
        id
        aboutMe
      }
    }
  }
`
export type UpdateScalisUserAboutMeMutationFn = Apollo.MutationFunction<
  UpdateScalisUserAboutMeMutation,
  UpdateScalisUserAboutMeMutationVariables
>

/**
 * __useUpdateScalisUserAboutMeMutation__
 *
 * To run a mutation, you first call `useUpdateScalisUserAboutMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScalisUserAboutMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScalisUserAboutMeMutation, { data, loading, error }] = useUpdateScalisUserAboutMeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      aboutMe: // value for 'aboutMe'
 *   },
 * });
 */
export function useUpdateScalisUserAboutMeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateScalisUserAboutMeMutation, UpdateScalisUserAboutMeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateScalisUserAboutMeMutation, UpdateScalisUserAboutMeMutationVariables>(
    UpdateScalisUserAboutMeDocument,
    options,
  )
}
export type UpdateScalisUserAboutMeMutationHookResult = ReturnType<typeof useUpdateScalisUserAboutMeMutation>
export type UpdateScalisUserAboutMeMutationResult = Apollo.MutationResult<UpdateScalisUserAboutMeMutation>
export type UpdateScalisUserAboutMeMutationOptions = Apollo.BaseMutationOptions<
  UpdateScalisUserAboutMeMutation,
  UpdateScalisUserAboutMeMutationVariables
>
export const UpdateScalisUserDataDocument = gql`
  mutation UpdateScalisUserData($input: UpdateScalisUserDataInput!) {
    UpdateScalisUserData(input: $input) {
      id
      firstName
      lastName
      profileImage
      Address {
        ...AddressFragment
      }
      ...ScalisUserAboutFragment
      ...ScalisUserSkillsFragment
      ...ScalisUserExperienceFragment
      ...ScalisUserVolunteerWorkFragment
      ...ScalisUserEducationFragment
      ...ScalisUserCertificatesOrLicensesFragment
      ...ScalisUserAchievementsFragment
      ...ScalisUserDemographicInfoFragment
      ...ScalisUserWebsiteOrSocialFragment
    }
  }
  ${AddressFragmentFragmentDoc}
  ${ScalisUserAboutFragmentFragmentDoc}
  ${ScalisUserSkillsFragmentFragmentDoc}
  ${ScalisUserExperienceFragmentFragmentDoc}
  ${ScalisUserVolunteerWorkFragmentFragmentDoc}
  ${ScalisUserEducationFragmentFragmentDoc}
  ${ScalisUserCertificatesOrLicensesFragmentFragmentDoc}
  ${ScalisUserAchievementsFragmentFragmentDoc}
  ${ScalisUserDemographicInfoFragmentFragmentDoc}
  ${ScalisUserWebsiteOrSocialFragmentFragmentDoc}
`
export type UpdateScalisUserDataMutationFn = Apollo.MutationFunction<
  UpdateScalisUserDataMutation,
  UpdateScalisUserDataMutationVariables
>

/**
 * __useUpdateScalisUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateScalisUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScalisUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScalisUserDataMutation, { data, loading, error }] = useUpdateScalisUserDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScalisUserDataMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateScalisUserDataMutation, UpdateScalisUserDataMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateScalisUserDataMutation, UpdateScalisUserDataMutationVariables>(
    UpdateScalisUserDataDocument,
    options,
  )
}
export type UpdateScalisUserDataMutationHookResult = ReturnType<typeof useUpdateScalisUserDataMutation>
export type UpdateScalisUserDataMutationResult = Apollo.MutationResult<UpdateScalisUserDataMutation>
export type UpdateScalisUserDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateScalisUserDataMutation,
  UpdateScalisUserDataMutationVariables
>
export const UpdateScalisUserIsSearchingJobsDocument = gql`
  mutation UpdateScalisUserIsSearchingJobs($id: Int!, $searchingJobs: Boolean) {
    UpdateScalisUserData(input: { id: $id, JobSeekerInfo: { searchingJobs: $searchingJobs } }) {
      id
      JobSeekerInfo {
        id
        searchingJobs
      }
    }
  }
`
export type UpdateScalisUserIsSearchingJobsMutationFn = Apollo.MutationFunction<
  UpdateScalisUserIsSearchingJobsMutation,
  UpdateScalisUserIsSearchingJobsMutationVariables
>

/**
 * __useUpdateScalisUserIsSearchingJobsMutation__
 *
 * To run a mutation, you first call `useUpdateScalisUserIsSearchingJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScalisUserIsSearchingJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScalisUserIsSearchingJobsMutation, { data, loading, error }] = useUpdateScalisUserIsSearchingJobsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      searchingJobs: // value for 'searchingJobs'
 *   },
 * });
 */
export function useUpdateScalisUserIsSearchingJobsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScalisUserIsSearchingJobsMutation,
    UpdateScalisUserIsSearchingJobsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateScalisUserIsSearchingJobsMutation, UpdateScalisUserIsSearchingJobsMutationVariables>(
    UpdateScalisUserIsSearchingJobsDocument,
    options,
  )
}
export type UpdateScalisUserIsSearchingJobsMutationHookResult = ReturnType<
  typeof useUpdateScalisUserIsSearchingJobsMutation
>
export type UpdateScalisUserIsSearchingJobsMutationResult =
  Apollo.MutationResult<UpdateScalisUserIsSearchingJobsMutation>
export type UpdateScalisUserIsSearchingJobsMutationOptions = Apollo.BaseMutationOptions<
  UpdateScalisUserIsSearchingJobsMutation,
  UpdateScalisUserIsSearchingJobsMutationVariables
>
export const UpdateScalisUserNameDocument = gql`
  mutation UpdateScalisUserName($input: UpdateScalisUserDataInput!) {
    UpdateScalisUserData(input: $input) {
      id
      firstName
      lastName
    }
  }
`
export type UpdateScalisUserNameMutationFn = Apollo.MutationFunction<
  UpdateScalisUserNameMutation,
  UpdateScalisUserNameMutationVariables
>

/**
 * __useUpdateScalisUserNameMutation__
 *
 * To run a mutation, you first call `useUpdateScalisUserNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScalisUserNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScalisUserNameMutation, { data, loading, error }] = useUpdateScalisUserNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScalisUserNameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateScalisUserNameMutation, UpdateScalisUserNameMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateScalisUserNameMutation, UpdateScalisUserNameMutationVariables>(
    UpdateScalisUserNameDocument,
    options,
  )
}
export type UpdateScalisUserNameMutationHookResult = ReturnType<typeof useUpdateScalisUserNameMutation>
export type UpdateScalisUserNameMutationResult = Apollo.MutationResult<UpdateScalisUserNameMutation>
export type UpdateScalisUserNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateScalisUserNameMutation,
  UpdateScalisUserNameMutationVariables
>
export const UpdateScalisUserResumeDocument = gql`
  mutation UpdateScalisUserResume(
    $id: Int!
    $aboutMe: String
    $jobSeekerSkills: [JobSeekerSkillInput!]
    $experiences: [ExperienceInput!]
    $volunteerWorks: [VolunteerWorkInput!]
    $educations: [EducationInput!]
    $certificateOrLicenses: [CertificateOrLicenseInput!]
    $achievements: [AchievementInput!]
  ) {
    UpdateScalisUserData(
      input: {
        id: $id
        JobSeekerInfo: { aboutMe: $aboutMe }
        JobSeekerSkill: $jobSeekerSkills
        Experience: $experiences
        VolunteerWork: $volunteerWorks
        Education: $educations
        CertificateOrLicense: $certificateOrLicenses
        Achievement: $achievements
      }
    ) {
      id
      JobSeekerInfo {
        id
        aboutMe
      }
      JobSeekerSkill {
        id
        level
        name
        sequenceNumber
        yearsOfExp
      }
      Experience {
        id
        description
      }
      VolunteerWork {
        id
        description
      }
      Education {
        id
        additionalInformation
      }
      CertificateOrLicense {
        id
        description
      }
      Achievement {
        id
        description
      }
    }
  }
`
export type UpdateScalisUserResumeMutationFn = Apollo.MutationFunction<
  UpdateScalisUserResumeMutation,
  UpdateScalisUserResumeMutationVariables
>

/**
 * __useUpdateScalisUserResumeMutation__
 *
 * To run a mutation, you first call `useUpdateScalisUserResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScalisUserResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScalisUserResumeMutation, { data, loading, error }] = useUpdateScalisUserResumeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      aboutMe: // value for 'aboutMe'
 *      jobSeekerSkills: // value for 'jobSeekerSkills'
 *      experiences: // value for 'experiences'
 *      volunteerWorks: // value for 'volunteerWorks'
 *      educations: // value for 'educations'
 *      certificateOrLicenses: // value for 'certificateOrLicenses'
 *      achievements: // value for 'achievements'
 *   },
 * });
 */
export function useUpdateScalisUserResumeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateScalisUserResumeMutation, UpdateScalisUserResumeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateScalisUserResumeMutation, UpdateScalisUserResumeMutationVariables>(
    UpdateScalisUserResumeDocument,
    options,
  )
}
export type UpdateScalisUserResumeMutationHookResult = ReturnType<typeof useUpdateScalisUserResumeMutation>
export type UpdateScalisUserResumeMutationResult = Apollo.MutationResult<UpdateScalisUserResumeMutation>
export type UpdateScalisUserResumeMutationOptions = Apollo.BaseMutationOptions<
  UpdateScalisUserResumeMutation,
  UpdateScalisUserResumeMutationVariables
>
export const SignUpDocument = gql`
  mutation SignUp($email: String!, $password: String!) {
    SignUp(input: { email: $email, password: $password }) {
      id
    }
  }
`
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options)
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>
export const DeleteBookmarkedJobDocument = gql`
  mutation DeleteBookmarkedJob($input: DeleteBookmarkedJobInput!) {
    DeleteBookmarkedJob(input: $input) {
      publicListingId
    }
  }
`
export type DeleteBookmarkedJobMutationFn = Apollo.MutationFunction<
  DeleteBookmarkedJobMutation,
  DeleteBookmarkedJobMutationVariables
>

/**
 * __useDeleteBookmarkedJobMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkedJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkedJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkedJobMutation, { data, loading, error }] = useDeleteBookmarkedJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBookmarkedJobMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBookmarkedJobMutation, DeleteBookmarkedJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteBookmarkedJobMutation, DeleteBookmarkedJobMutationVariables>(
    DeleteBookmarkedJobDocument,
    options,
  )
}
export type DeleteBookmarkedJobMutationHookResult = ReturnType<typeof useDeleteBookmarkedJobMutation>
export type DeleteBookmarkedJobMutationResult = Apollo.MutationResult<DeleteBookmarkedJobMutation>
export type DeleteBookmarkedJobMutationOptions = Apollo.BaseMutationOptions<
  DeleteBookmarkedJobMutation,
  DeleteBookmarkedJobMutationVariables
>
export const ConnectCalendarDocument = gql`
  mutation ConnectCalendar($input: ConnectCalendarInput!) {
    ConnectCalendar(input: $input) {
      authUrl
    }
  }
`
export type ConnectCalendarMutationFn = Apollo.MutationFunction<
  ConnectCalendarMutation,
  ConnectCalendarMutationVariables
>

/**
 * __useConnectCalendarMutation__
 *
 * To run a mutation, you first call `useConnectCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectCalendarMutation, { data, loading, error }] = useConnectCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<ConnectCalendarMutation, ConnectCalendarMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ConnectCalendarMutation, ConnectCalendarMutationVariables>(ConnectCalendarDocument, options)
}
export type ConnectCalendarMutationHookResult = ReturnType<typeof useConnectCalendarMutation>
export type ConnectCalendarMutationResult = Apollo.MutationResult<ConnectCalendarMutation>
export type ConnectCalendarMutationOptions = Apollo.BaseMutationOptions<
  ConnectCalendarMutation,
  ConnectCalendarMutationVariables
>
export const DisconnectCalendarDocument = gql`
  mutation DisconnectCalendar($input: DisconnectCalendarInput!) {
    DisconnectCalendar(input: $input) {
      success
    }
  }
`
export type DisconnectCalendarMutationFn = Apollo.MutationFunction<
  DisconnectCalendarMutation,
  DisconnectCalendarMutationVariables
>

/**
 * __useDisconnectCalendarMutation__
 *
 * To run a mutation, you first call `useDisconnectCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectCalendarMutation, { data, loading, error }] = useDisconnectCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<DisconnectCalendarMutation, DisconnectCalendarMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DisconnectCalendarMutation, DisconnectCalendarMutationVariables>(
    DisconnectCalendarDocument,
    options,
  )
}
export type DisconnectCalendarMutationHookResult = ReturnType<typeof useDisconnectCalendarMutation>
export type DisconnectCalendarMutationResult = Apollo.MutationResult<DisconnectCalendarMutation>
export type DisconnectCalendarMutationOptions = Apollo.BaseMutationOptions<
  DisconnectCalendarMutation,
  DisconnectCalendarMutationVariables
>
export const EditCalendarSettingsDocument = gql`
  mutation EditCalendarSettings($input: EditCalendarSettingsInput!) {
    EditCalendarSettings(input: $input) {
      id
      companyUserId
      email
      provider
      nylasGrantId
      availability
      timeZone
    }
  }
`
export type EditCalendarSettingsMutationFn = Apollo.MutationFunction<
  EditCalendarSettingsMutation,
  EditCalendarSettingsMutationVariables
>

/**
 * __useEditCalendarSettingsMutation__
 *
 * To run a mutation, you first call `useEditCalendarSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCalendarSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCalendarSettingsMutation, { data, loading, error }] = useEditCalendarSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCalendarSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<EditCalendarSettingsMutation, EditCalendarSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditCalendarSettingsMutation, EditCalendarSettingsMutationVariables>(
    EditCalendarSettingsDocument,
    options,
  )
}
export type EditCalendarSettingsMutationHookResult = ReturnType<typeof useEditCalendarSettingsMutation>
export type EditCalendarSettingsMutationResult = Apollo.MutationResult<EditCalendarSettingsMutation>
export type EditCalendarSettingsMutationOptions = Apollo.BaseMutationOptions<
  EditCalendarSettingsMutation,
  EditCalendarSettingsMutationVariables
>
export const ExchangeNylasTokenDocument = gql`
  mutation ExchangeNylasToken($input: ExchangeNylasTokenInput!) {
    ExchangeNylasToken(input: $input) {
      id
      availability
      provider
      timeZone
      hourDisplay
      email
      Calendars {
        id
        name
        isPrimary
        color
        CalendarEvents {
          id
          nylasEventStatus
          title
          eventType
          description
          startTime
          endTime
          timeZone
          location
          repeats
          reminders
          employers
          conferencing
          masterEventId
          regardingCandidates
          regardingJobs
          internalGuests
          candidates
          isScalisEvent
        }
      }
    }
  }
`
export type ExchangeNylasTokenMutationFn = Apollo.MutationFunction<
  ExchangeNylasTokenMutation,
  ExchangeNylasTokenMutationVariables
>

/**
 * __useExchangeNylasTokenMutation__
 *
 * To run a mutation, you first call `useExchangeNylasTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeNylasTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeNylasTokenMutation, { data, loading, error }] = useExchangeNylasTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExchangeNylasTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<ExchangeNylasTokenMutation, ExchangeNylasTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ExchangeNylasTokenMutation, ExchangeNylasTokenMutationVariables>(
    ExchangeNylasTokenDocument,
    options,
  )
}
export type ExchangeNylasTokenMutationHookResult = ReturnType<typeof useExchangeNylasTokenMutation>
export type ExchangeNylasTokenMutationResult = Apollo.MutationResult<ExchangeNylasTokenMutation>
export type ExchangeNylasTokenMutationOptions = Apollo.BaseMutationOptions<
  ExchangeNylasTokenMutation,
  ExchangeNylasTokenMutationVariables
>
export const SyncCalendarDocument = gql`
  mutation SyncCalendar {
    SyncCalendar {
      id
    }
  }
`
export type SyncCalendarMutationFn = Apollo.MutationFunction<SyncCalendarMutation, SyncCalendarMutationVariables>

/**
 * __useSyncCalendarMutation__
 *
 * To run a mutation, you first call `useSyncCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncCalendarMutation, { data, loading, error }] = useSyncCalendarMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<SyncCalendarMutation, SyncCalendarMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SyncCalendarMutation, SyncCalendarMutationVariables>(SyncCalendarDocument, options)
}
export type SyncCalendarMutationHookResult = ReturnType<typeof useSyncCalendarMutation>
export type SyncCalendarMutationResult = Apollo.MutationResult<SyncCalendarMutation>
export type SyncCalendarMutationOptions = Apollo.BaseMutationOptions<
  SyncCalendarMutation,
  SyncCalendarMutationVariables
>
export const DeleteCandidateApplicationNoteDocument = gql`
  mutation DeleteCandidateApplicationNote($input: DeleteCandidateApplicationNoteInput!) {
    DeleteCandidateApplicationNote(input: $input) {
      id
    }
  }
`
export type DeleteCandidateApplicationNoteMutationFn = Apollo.MutationFunction<
  DeleteCandidateApplicationNoteMutation,
  DeleteCandidateApplicationNoteMutationVariables
>

/**
 * __useDeleteCandidateApplicationNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateApplicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateApplicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateApplicationNoteMutation, { data, loading, error }] = useDeleteCandidateApplicationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCandidateApplicationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCandidateApplicationNoteMutation,
    DeleteCandidateApplicationNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCandidateApplicationNoteMutation, DeleteCandidateApplicationNoteMutationVariables>(
    DeleteCandidateApplicationNoteDocument,
    options,
  )
}
export type DeleteCandidateApplicationNoteMutationHookResult = ReturnType<
  typeof useDeleteCandidateApplicationNoteMutation
>
export type DeleteCandidateApplicationNoteMutationResult = Apollo.MutationResult<DeleteCandidateApplicationNoteMutation>
export type DeleteCandidateApplicationNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateApplicationNoteMutation,
  DeleteCandidateApplicationNoteMutationVariables
>
export const UpsertCandidateApplicationNoteDocument = gql`
  mutation UpsertCandidateApplicationNote($input: UpsertCandidateApplicationNoteInput!) {
    UpsertCandidateApplicationNote(input: $input) {
      id
    }
  }
`
export type UpsertCandidateApplicationNoteMutationFn = Apollo.MutationFunction<
  UpsertCandidateApplicationNoteMutation,
  UpsertCandidateApplicationNoteMutationVariables
>

/**
 * __useUpsertCandidateApplicationNoteMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateApplicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateApplicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateApplicationNoteMutation, { data, loading, error }] = useUpsertCandidateApplicationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateApplicationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCandidateApplicationNoteMutation,
    UpsertCandidateApplicationNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateApplicationNoteMutation, UpsertCandidateApplicationNoteMutationVariables>(
    UpsertCandidateApplicationNoteDocument,
    options,
  )
}
export type UpsertCandidateApplicationNoteMutationHookResult = ReturnType<
  typeof useUpsertCandidateApplicationNoteMutation
>
export type UpsertCandidateApplicationNoteMutationResult = Apollo.MutationResult<UpsertCandidateApplicationNoteMutation>
export type UpsertCandidateApplicationNoteMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateApplicationNoteMutation,
  UpsertCandidateApplicationNoteMutationVariables
>
export const UpsertCandidateApplicationRatingDocument = gql`
  mutation UpsertCandidateApplicationRating($input: UpsertCandidateApplicationRatingInput!) {
    UpsertCandidateApplicationRating(input: $input) {
      id
      candidateProfileId
      candidateApplicationId
      companyUserId
      score
      createdAt
      updatedAt
    }
  }
`
export type UpsertCandidateApplicationRatingMutationFn = Apollo.MutationFunction<
  UpsertCandidateApplicationRatingMutation,
  UpsertCandidateApplicationRatingMutationVariables
>

/**
 * __useUpsertCandidateApplicationRatingMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateApplicationRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateApplicationRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateApplicationRatingMutation, { data, loading, error }] = useUpsertCandidateApplicationRatingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateApplicationRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCandidateApplicationRatingMutation,
    UpsertCandidateApplicationRatingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertCandidateApplicationRatingMutation,
    UpsertCandidateApplicationRatingMutationVariables
  >(UpsertCandidateApplicationRatingDocument, options)
}
export type UpsertCandidateApplicationRatingMutationHookResult = ReturnType<
  typeof useUpsertCandidateApplicationRatingMutation
>
export type UpsertCandidateApplicationRatingMutationResult =
  Apollo.MutationResult<UpsertCandidateApplicationRatingMutation>
export type UpsertCandidateApplicationRatingMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateApplicationRatingMutation,
  UpsertCandidateApplicationRatingMutationVariables
>
export const AdvanceCandidateApplicationDocument = gql`
  mutation AdvanceCandidateApplication($input: AdvanceCandidateApplicationInput!) {
    AdvanceCandidateApplication(input: $input) {
      id
    }
  }
`
export type AdvanceCandidateApplicationMutationFn = Apollo.MutationFunction<
  AdvanceCandidateApplicationMutation,
  AdvanceCandidateApplicationMutationVariables
>

/**
 * __useAdvanceCandidateApplicationMutation__
 *
 * To run a mutation, you first call `useAdvanceCandidateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvanceCandidateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advanceCandidateApplicationMutation, { data, loading, error }] = useAdvanceCandidateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvanceCandidateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdvanceCandidateApplicationMutation,
    AdvanceCandidateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AdvanceCandidateApplicationMutation, AdvanceCandidateApplicationMutationVariables>(
    AdvanceCandidateApplicationDocument,
    options,
  )
}
export type AdvanceCandidateApplicationMutationHookResult = ReturnType<typeof useAdvanceCandidateApplicationMutation>
export type AdvanceCandidateApplicationMutationResult = Apollo.MutationResult<AdvanceCandidateApplicationMutation>
export type AdvanceCandidateApplicationMutationOptions = Apollo.BaseMutationOptions<
  AdvanceCandidateApplicationMutation,
  AdvanceCandidateApplicationMutationVariables
>
export const CreateUploadedDocumentCandidateApplicationDocument = gql`
  mutation CreateUploadedDocumentCandidateApplication($input: CreateUploadedDocumentCandidateApplicationInput!) {
    CreateUploadedDocumentCandidateApplication(input: $input) {
      id
    }
  }
`
export type CreateUploadedDocumentCandidateApplicationMutationFn = Apollo.MutationFunction<
  CreateUploadedDocumentCandidateApplicationMutation,
  CreateUploadedDocumentCandidateApplicationMutationVariables
>

/**
 * __useCreateUploadedDocumentCandidateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateUploadedDocumentCandidateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadedDocumentCandidateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadedDocumentCandidateApplicationMutation, { data, loading, error }] = useCreateUploadedDocumentCandidateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadedDocumentCandidateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUploadedDocumentCandidateApplicationMutation,
    CreateUploadedDocumentCandidateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUploadedDocumentCandidateApplicationMutation,
    CreateUploadedDocumentCandidateApplicationMutationVariables
  >(CreateUploadedDocumentCandidateApplicationDocument, options)
}
export type CreateUploadedDocumentCandidateApplicationMutationHookResult = ReturnType<
  typeof useCreateUploadedDocumentCandidateApplicationMutation
>
export type CreateUploadedDocumentCandidateApplicationMutationResult =
  Apollo.MutationResult<CreateUploadedDocumentCandidateApplicationMutation>
export type CreateUploadedDocumentCandidateApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateUploadedDocumentCandidateApplicationMutation,
  CreateUploadedDocumentCandidateApplicationMutationVariables
>
export const DeleteUploadedDocumentCandidateApplicationDocument = gql`
  mutation DeleteUploadedDocumentCandidateApplication($input: DeleteUploadedDocumentCandidateApplicationInput!) {
    DeleteUploadedDocumentCandidateApplication(input: $input) {
      id
    }
  }
`
export type DeleteUploadedDocumentCandidateApplicationMutationFn = Apollo.MutationFunction<
  DeleteUploadedDocumentCandidateApplicationMutation,
  DeleteUploadedDocumentCandidateApplicationMutationVariables
>

/**
 * __useDeleteUploadedDocumentCandidateApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteUploadedDocumentCandidateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUploadedDocumentCandidateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUploadedDocumentCandidateApplicationMutation, { data, loading, error }] = useDeleteUploadedDocumentCandidateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUploadedDocumentCandidateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUploadedDocumentCandidateApplicationMutation,
    DeleteUploadedDocumentCandidateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteUploadedDocumentCandidateApplicationMutation,
    DeleteUploadedDocumentCandidateApplicationMutationVariables
  >(DeleteUploadedDocumentCandidateApplicationDocument, options)
}
export type DeleteUploadedDocumentCandidateApplicationMutationHookResult = ReturnType<
  typeof useDeleteUploadedDocumentCandidateApplicationMutation
>
export type DeleteUploadedDocumentCandidateApplicationMutationResult =
  Apollo.MutationResult<DeleteUploadedDocumentCandidateApplicationMutation>
export type DeleteUploadedDocumentCandidateApplicationMutationOptions = Apollo.BaseMutationOptions<
  DeleteUploadedDocumentCandidateApplicationMutation,
  DeleteUploadedDocumentCandidateApplicationMutationVariables
>
export const InviteCandidatesToApplyDocument = gql`
  mutation InviteCandidatesToApply($input: InviteCandidatesToApplyInput!) {
    InviteCandidatesToApply(input: $input) {
      uploadedCandidatesToReviewStage {
        jobListingId
        candidateProfileId
      }
      sent {
        token
        sentAt
        jobListingId
        inviteStatus
        id
        dismissed
        candidateApplicationId
      }
      profileNotFound {
        jobListingId
        candidateProfileId
      }
      isPreviouslyInvited {
        jobListingId
        candidateProfileId
      }
      alreadyHasActiveApplication {
        jobListingId
        candidateProfileId
      }
    }
  }
`
export type InviteCandidatesToApplyMutationFn = Apollo.MutationFunction<
  InviteCandidatesToApplyMutation,
  InviteCandidatesToApplyMutationVariables
>

/**
 * __useInviteCandidatesToApplyMutation__
 *
 * To run a mutation, you first call `useInviteCandidatesToApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCandidatesToApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCandidatesToApplyMutation, { data, loading, error }] = useInviteCandidatesToApplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteCandidatesToApplyMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteCandidatesToApplyMutation, InviteCandidatesToApplyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InviteCandidatesToApplyMutation, InviteCandidatesToApplyMutationVariables>(
    InviteCandidatesToApplyDocument,
    options,
  )
}
export type InviteCandidatesToApplyMutationHookResult = ReturnType<typeof useInviteCandidatesToApplyMutation>
export type InviteCandidatesToApplyMutationResult = Apollo.MutationResult<InviteCandidatesToApplyMutation>
export type InviteCandidatesToApplyMutationOptions = Apollo.BaseMutationOptions<
  InviteCandidatesToApplyMutation,
  InviteCandidatesToApplyMutationVariables
>
export const RejectCandidatesDocument = gql`
  mutation RejectCandidates($input: RejectCandidatesInput!, $delaySeconds: Int) {
    RejectCandidates(input: $input, delaySeconds: $delaySeconds) {
      jobId
    }
  }
`
export type RejectCandidatesMutationFn = Apollo.MutationFunction<
  RejectCandidatesMutation,
  RejectCandidatesMutationVariables
>

/**
 * __useRejectCandidatesMutation__
 *
 * To run a mutation, you first call `useRejectCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCandidatesMutation, { data, loading, error }] = useRejectCandidatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      delaySeconds: // value for 'delaySeconds'
 *   },
 * });
 */
export function useRejectCandidatesMutation(
  baseOptions?: Apollo.MutationHookOptions<RejectCandidatesMutation, RejectCandidatesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RejectCandidatesMutation, RejectCandidatesMutationVariables>(
    RejectCandidatesDocument,
    options,
  )
}
export type RejectCandidatesMutationHookResult = ReturnType<typeof useRejectCandidatesMutation>
export type RejectCandidatesMutationResult = Apollo.MutationResult<RejectCandidatesMutation>
export type RejectCandidatesMutationOptions = Apollo.BaseMutationOptions<
  RejectCandidatesMutation,
  RejectCandidatesMutationVariables
>
export const SendCandidatesToSourcingStageDocument = gql`
  mutation SendCandidatesToSourcingStage($input: SendCandidatesToSourcingStageInput!) {
    SendCandidatesToSourcingStage(input: $input) {
      profileNotFound {
        candidateProfileId
      }
      candidateApplications {
        id
      }
      alreadyHasActiveApplication {
        candidateProfileId
      }
    }
  }
`
export type SendCandidatesToSourcingStageMutationFn = Apollo.MutationFunction<
  SendCandidatesToSourcingStageMutation,
  SendCandidatesToSourcingStageMutationVariables
>

/**
 * __useSendCandidatesToSourcingStageMutation__
 *
 * To run a mutation, you first call `useSendCandidatesToSourcingStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCandidatesToSourcingStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCandidatesToSourcingStageMutation, { data, loading, error }] = useSendCandidatesToSourcingStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCandidatesToSourcingStageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCandidatesToSourcingStageMutation,
    SendCandidatesToSourcingStageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendCandidatesToSourcingStageMutation, SendCandidatesToSourcingStageMutationVariables>(
    SendCandidatesToSourcingStageDocument,
    options,
  )
}
export type SendCandidatesToSourcingStageMutationHookResult = ReturnType<
  typeof useSendCandidatesToSourcingStageMutation
>
export type SendCandidatesToSourcingStageMutationResult = Apollo.MutationResult<SendCandidatesToSourcingStageMutation>
export type SendCandidatesToSourcingStageMutationOptions = Apollo.BaseMutationOptions<
  SendCandidatesToSourcingStageMutation,
  SendCandidatesToSourcingStageMutationVariables
>
export const StartCandidateApplicationDocument = gql`
  mutation StartCandidateApplication($input: StartCandidateApplicationInput!) {
    StartCandidateApplication(input: $input) {
      id
    }
  }
`
export type StartCandidateApplicationMutationFn = Apollo.MutationFunction<
  StartCandidateApplicationMutation,
  StartCandidateApplicationMutationVariables
>

/**
 * __useStartCandidateApplicationMutation__
 *
 * To run a mutation, you first call `useStartCandidateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCandidateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCandidateApplicationMutation, { data, loading, error }] = useStartCandidateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartCandidateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartCandidateApplicationMutation,
    StartCandidateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StartCandidateApplicationMutation, StartCandidateApplicationMutationVariables>(
    StartCandidateApplicationDocument,
    options,
  )
}
export type StartCandidateApplicationMutationHookResult = ReturnType<typeof useStartCandidateApplicationMutation>
export type StartCandidateApplicationMutationResult = Apollo.MutationResult<StartCandidateApplicationMutation>
export type StartCandidateApplicationMutationOptions = Apollo.BaseMutationOptions<
  StartCandidateApplicationMutation,
  StartCandidateApplicationMutationVariables
>
export const UndoRejectCandidatesDocument = gql`
  mutation UndoRejectCandidates($input: UndoRejectCandidatesInput!) {
    UndoRejectCandidates(input: $input)
  }
`
export type UndoRejectCandidatesMutationFn = Apollo.MutationFunction<
  UndoRejectCandidatesMutation,
  UndoRejectCandidatesMutationVariables
>

/**
 * __useUndoRejectCandidatesMutation__
 *
 * To run a mutation, you first call `useUndoRejectCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoRejectCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoRejectCandidatesMutation, { data, loading, error }] = useUndoRejectCandidatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUndoRejectCandidatesMutation(
  baseOptions?: Apollo.MutationHookOptions<UndoRejectCandidatesMutation, UndoRejectCandidatesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UndoRejectCandidatesMutation, UndoRejectCandidatesMutationVariables>(
    UndoRejectCandidatesDocument,
    options,
  )
}
export type UndoRejectCandidatesMutationHookResult = ReturnType<typeof useUndoRejectCandidatesMutation>
export type UndoRejectCandidatesMutationResult = Apollo.MutationResult<UndoRejectCandidatesMutation>
export type UndoRejectCandidatesMutationOptions = Apollo.BaseMutationOptions<
  UndoRejectCandidatesMutation,
  UndoRejectCandidatesMutationVariables
>
export const DeleteCandidateCollaboratorDocument = gql`
  mutation DeleteCandidateCollaborator($input: DeleteCandidateCollaboratorInput!) {
    DeleteCandidateCollaborator(input: $input) {
      id
    }
  }
`
export type DeleteCandidateCollaboratorMutationFn = Apollo.MutationFunction<
  DeleteCandidateCollaboratorMutation,
  DeleteCandidateCollaboratorMutationVariables
>

/**
 * __useDeleteCandidateCollaboratorMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateCollaboratorMutation, { data, loading, error }] = useDeleteCandidateCollaboratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCandidateCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCandidateCollaboratorMutation,
    DeleteCandidateCollaboratorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCandidateCollaboratorMutation, DeleteCandidateCollaboratorMutationVariables>(
    DeleteCandidateCollaboratorDocument,
    options,
  )
}
export type DeleteCandidateCollaboratorMutationHookResult = ReturnType<typeof useDeleteCandidateCollaboratorMutation>
export type DeleteCandidateCollaboratorMutationResult = Apollo.MutationResult<DeleteCandidateCollaboratorMutation>
export type DeleteCandidateCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateCollaboratorMutation,
  DeleteCandidateCollaboratorMutationVariables
>
export const UpsertCandidateCollaboratorDocument = gql`
  mutation UpsertCandidateCollaborator($input: UpsertCandidateCollaboratorInput!) {
    UpsertCandidateCollaborator(input: $input) {
      ...CandidateCollaboratorFragment
    }
  }
  ${CandidateCollaboratorFragmentFragmentDoc}
`
export type UpsertCandidateCollaboratorMutationFn = Apollo.MutationFunction<
  UpsertCandidateCollaboratorMutation,
  UpsertCandidateCollaboratorMutationVariables
>

/**
 * __useUpsertCandidateCollaboratorMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateCollaboratorMutation, { data, loading, error }] = useUpsertCandidateCollaboratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCandidateCollaboratorMutation,
    UpsertCandidateCollaboratorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateCollaboratorMutation, UpsertCandidateCollaboratorMutationVariables>(
    UpsertCandidateCollaboratorDocument,
    options,
  )
}
export type UpsertCandidateCollaboratorMutationHookResult = ReturnType<typeof useUpsertCandidateCollaboratorMutation>
export type UpsertCandidateCollaboratorMutationResult = Apollo.MutationResult<UpsertCandidateCollaboratorMutation>
export type UpsertCandidateCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateCollaboratorMutation,
  UpsertCandidateCollaboratorMutationVariables
>
export const DeleteCandidateMedalistDesignationDocument = gql`
  mutation DeleteCandidateMedalistDesignation($input: DeleteCandidateMedalistDesignationInput!) {
    DeleteCandidateMedalistDesignation(input: $input) {
      id
    }
  }
`
export type DeleteCandidateMedalistDesignationMutationFn = Apollo.MutationFunction<
  DeleteCandidateMedalistDesignationMutation,
  DeleteCandidateMedalistDesignationMutationVariables
>

/**
 * __useDeleteCandidateMedalistDesignationMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateMedalistDesignationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateMedalistDesignationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateMedalistDesignationMutation, { data, loading, error }] = useDeleteCandidateMedalistDesignationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCandidateMedalistDesignationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCandidateMedalistDesignationMutation,
    DeleteCandidateMedalistDesignationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCandidateMedalistDesignationMutation,
    DeleteCandidateMedalistDesignationMutationVariables
  >(DeleteCandidateMedalistDesignationDocument, options)
}
export type DeleteCandidateMedalistDesignationMutationHookResult = ReturnType<
  typeof useDeleteCandidateMedalistDesignationMutation
>
export type DeleteCandidateMedalistDesignationMutationResult =
  Apollo.MutationResult<DeleteCandidateMedalistDesignationMutation>
export type DeleteCandidateMedalistDesignationMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateMedalistDesignationMutation,
  DeleteCandidateMedalistDesignationMutationVariables
>
export const DeleteCandidateProfileLocationDocument = gql`
  mutation DeleteCandidateProfileLocation($input: DeleteCandidateProfileLocationInput!) {
    DeleteCandidateProfileLocation(input: $input) {
      id
    }
  }
`
export type DeleteCandidateProfileLocationMutationFn = Apollo.MutationFunction<
  DeleteCandidateProfileLocationMutation,
  DeleteCandidateProfileLocationMutationVariables
>

/**
 * __useDeleteCandidateProfileLocationMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateProfileLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateProfileLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateProfileLocationMutation, { data, loading, error }] = useDeleteCandidateProfileLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCandidateProfileLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCandidateProfileLocationMutation,
    DeleteCandidateProfileLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCandidateProfileLocationMutation, DeleteCandidateProfileLocationMutationVariables>(
    DeleteCandidateProfileLocationDocument,
    options,
  )
}
export type DeleteCandidateProfileLocationMutationHookResult = ReturnType<
  typeof useDeleteCandidateProfileLocationMutation
>
export type DeleteCandidateProfileLocationMutationResult = Apollo.MutationResult<DeleteCandidateProfileLocationMutation>
export type DeleteCandidateProfileLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateProfileLocationMutation,
  DeleteCandidateProfileLocationMutationVariables
>
export const DeleteCandidateProfileNoteDocument = gql`
  mutation DeleteCandidateProfileNote($input: DeleteCandidateProfileNoteInput!) {
    DeleteCandidateProfileNote(input: $input) {
      id
    }
  }
`
export type DeleteCandidateProfileNoteMutationFn = Apollo.MutationFunction<
  DeleteCandidateProfileNoteMutation,
  DeleteCandidateProfileNoteMutationVariables
>

/**
 * __useDeleteCandidateProfileNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateProfileNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateProfileNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateProfileNoteMutation, { data, loading, error }] = useDeleteCandidateProfileNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCandidateProfileNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCandidateProfileNoteMutation,
    DeleteCandidateProfileNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCandidateProfileNoteMutation, DeleteCandidateProfileNoteMutationVariables>(
    DeleteCandidateProfileNoteDocument,
    options,
  )
}
export type DeleteCandidateProfileNoteMutationHookResult = ReturnType<typeof useDeleteCandidateProfileNoteMutation>
export type DeleteCandidateProfileNoteMutationResult = Apollo.MutationResult<DeleteCandidateProfileNoteMutation>
export type DeleteCandidateProfileNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateProfileNoteMutation,
  DeleteCandidateProfileNoteMutationVariables
>
export const UpsertCandidateProfileNoteDocument = gql`
  mutation UpsertCandidateProfileNote($input: UpsertCandidateProfileNoteInput!) {
    UpsertCandidateProfileNote(input: $input) {
      id
    }
  }
`
export type UpsertCandidateProfileNoteMutationFn = Apollo.MutationFunction<
  UpsertCandidateProfileNoteMutation,
  UpsertCandidateProfileNoteMutationVariables
>

/**
 * __useUpsertCandidateProfileNoteMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateProfileNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateProfileNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateProfileNoteMutation, { data, loading, error }] = useUpsertCandidateProfileNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateProfileNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCandidateProfileNoteMutation,
    UpsertCandidateProfileNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateProfileNoteMutation, UpsertCandidateProfileNoteMutationVariables>(
    UpsertCandidateProfileNoteDocument,
    options,
  )
}
export type UpsertCandidateProfileNoteMutationHookResult = ReturnType<typeof useUpsertCandidateProfileNoteMutation>
export type UpsertCandidateProfileNoteMutationResult = Apollo.MutationResult<UpsertCandidateProfileNoteMutation>
export type UpsertCandidateProfileNoteMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateProfileNoteMutation,
  UpsertCandidateProfileNoteMutationVariables
>
export const CreateCandidateSourceDocument = gql`
  mutation CreateCandidateSource($input: CandidateSourceInput!) {
    CreateCandidateSource(input: $input) {
      id
    }
  }
`
export type CreateCandidateSourceMutationFn = Apollo.MutationFunction<
  CreateCandidateSourceMutation,
  CreateCandidateSourceMutationVariables
>

/**
 * __useCreateCandidateSourceMutation__
 *
 * To run a mutation, you first call `useCreateCandidateSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateSourceMutation, { data, loading, error }] = useCreateCandidateSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCandidateSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCandidateSourceMutation, CreateCandidateSourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCandidateSourceMutation, CreateCandidateSourceMutationVariables>(
    CreateCandidateSourceDocument,
    options,
  )
}
export type CreateCandidateSourceMutationHookResult = ReturnType<typeof useCreateCandidateSourceMutation>
export type CreateCandidateSourceMutationResult = Apollo.MutationResult<CreateCandidateSourceMutation>
export type CreateCandidateSourceMutationOptions = Apollo.BaseMutationOptions<
  CreateCandidateSourceMutation,
  CreateCandidateSourceMutationVariables
>
export const CreateUploadedDocumentCandidateProfileDocument = gql`
  mutation CreateUploadedDocumentCandidateProfile($input: CreateUploadedDocumentCandidateProfileInput!) {
    CreateUploadedDocumentCandidateProfile(input: $input) {
      id
    }
  }
`
export type CreateUploadedDocumentCandidateProfileMutationFn = Apollo.MutationFunction<
  CreateUploadedDocumentCandidateProfileMutation,
  CreateUploadedDocumentCandidateProfileMutationVariables
>

/**
 * __useCreateUploadedDocumentCandidateProfileMutation__
 *
 * To run a mutation, you first call `useCreateUploadedDocumentCandidateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadedDocumentCandidateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadedDocumentCandidateProfileMutation, { data, loading, error }] = useCreateUploadedDocumentCandidateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadedDocumentCandidateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUploadedDocumentCandidateProfileMutation,
    CreateUploadedDocumentCandidateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUploadedDocumentCandidateProfileMutation,
    CreateUploadedDocumentCandidateProfileMutationVariables
  >(CreateUploadedDocumentCandidateProfileDocument, options)
}
export type CreateUploadedDocumentCandidateProfileMutationHookResult = ReturnType<
  typeof useCreateUploadedDocumentCandidateProfileMutation
>
export type CreateUploadedDocumentCandidateProfileMutationResult =
  Apollo.MutationResult<CreateUploadedDocumentCandidateProfileMutation>
export type CreateUploadedDocumentCandidateProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateUploadedDocumentCandidateProfileMutation,
  CreateUploadedDocumentCandidateProfileMutationVariables
>
export const DeleteCandidateSourceDocument = gql`
  mutation DeleteCandidateSource($input: DeleteCandidateSourceInput!) {
    DeleteCandidateSource(input: $input) {
      id
    }
  }
`
export type DeleteCandidateSourceMutationFn = Apollo.MutationFunction<
  DeleteCandidateSourceMutation,
  DeleteCandidateSourceMutationVariables
>

/**
 * __useDeleteCandidateSourceMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateSourceMutation, { data, loading, error }] = useDeleteCandidateSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCandidateSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCandidateSourceMutation, DeleteCandidateSourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCandidateSourceMutation, DeleteCandidateSourceMutationVariables>(
    DeleteCandidateSourceDocument,
    options,
  )
}
export type DeleteCandidateSourceMutationHookResult = ReturnType<typeof useDeleteCandidateSourceMutation>
export type DeleteCandidateSourceMutationResult = Apollo.MutationResult<DeleteCandidateSourceMutation>
export type DeleteCandidateSourceMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateSourceMutation,
  DeleteCandidateSourceMutationVariables
>
export const DeleteUploadedDocumentCandidateProfileDocument = gql`
  mutation DeleteUploadedDocumentCandidateProfile($input: DeleteUploadedDocumentCandidateProfileInput!) {
    DeleteUploadedDocumentCandidateProfile(input: $input) {
      id
    }
  }
`
export type DeleteUploadedDocumentCandidateProfileMutationFn = Apollo.MutationFunction<
  DeleteUploadedDocumentCandidateProfileMutation,
  DeleteUploadedDocumentCandidateProfileMutationVariables
>

/**
 * __useDeleteUploadedDocumentCandidateProfileMutation__
 *
 * To run a mutation, you first call `useDeleteUploadedDocumentCandidateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUploadedDocumentCandidateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUploadedDocumentCandidateProfileMutation, { data, loading, error }] = useDeleteUploadedDocumentCandidateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUploadedDocumentCandidateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUploadedDocumentCandidateProfileMutation,
    DeleteUploadedDocumentCandidateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteUploadedDocumentCandidateProfileMutation,
    DeleteUploadedDocumentCandidateProfileMutationVariables
  >(DeleteUploadedDocumentCandidateProfileDocument, options)
}
export type DeleteUploadedDocumentCandidateProfileMutationHookResult = ReturnType<
  typeof useDeleteUploadedDocumentCandidateProfileMutation
>
export type DeleteUploadedDocumentCandidateProfileMutationResult =
  Apollo.MutationResult<DeleteUploadedDocumentCandidateProfileMutation>
export type DeleteUploadedDocumentCandidateProfileMutationOptions = Apollo.BaseMutationOptions<
  DeleteUploadedDocumentCandidateProfileMutation,
  DeleteUploadedDocumentCandidateProfileMutationVariables
>
export const UpsertCandidateProfileDocument = gql`
  mutation UpsertCandidateProfile($input: UpsertCandidateProfileInput!) {
    UpsertCandidateProfile(input: $input) {
      id
    }
  }
`
export type UpsertCandidateProfileMutationFn = Apollo.MutationFunction<
  UpsertCandidateProfileMutation,
  UpsertCandidateProfileMutationVariables
>

/**
 * __useUpsertCandidateProfileMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateProfileMutation, { data, loading, error }] = useUpsertCandidateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertCandidateProfileMutation, UpsertCandidateProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateProfileMutation, UpsertCandidateProfileMutationVariables>(
    UpsertCandidateProfileDocument,
    options,
  )
}
export type UpsertCandidateProfileMutationHookResult = ReturnType<typeof useUpsertCandidateProfileMutation>
export type UpsertCandidateProfileMutationResult = Apollo.MutationResult<UpsertCandidateProfileMutation>
export type UpsertCandidateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateProfileMutation,
  UpsertCandidateProfileMutationVariables
>
export const DeleteCandidateScorecardDocument = gql`
  mutation DeleteCandidateScorecard($input: DeleteCandidateScorecardInput!) {
    DeleteCandidateScorecard(input: $input) {
      id
    }
  }
`
export type DeleteCandidateScorecardMutationFn = Apollo.MutationFunction<
  DeleteCandidateScorecardMutation,
  DeleteCandidateScorecardMutationVariables
>

/**
 * __useDeleteCandidateScorecardMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateScorecardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateScorecardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateScorecardMutation, { data, loading, error }] = useDeleteCandidateScorecardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCandidateScorecardMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCandidateScorecardMutation, DeleteCandidateScorecardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCandidateScorecardMutation, DeleteCandidateScorecardMutationVariables>(
    DeleteCandidateScorecardDocument,
    options,
  )
}
export type DeleteCandidateScorecardMutationHookResult = ReturnType<typeof useDeleteCandidateScorecardMutation>
export type DeleteCandidateScorecardMutationResult = Apollo.MutationResult<DeleteCandidateScorecardMutation>
export type DeleteCandidateScorecardMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateScorecardMutation,
  DeleteCandidateScorecardMutationVariables
>
export const GetCandidateScorecardDocument = gql`
  query GetCandidateScorecard($input: GetCandidateScorecardInput!) {
    GetCandidateScorecard(input: $input) {
      id
      additionalInformation
      candidateApplicationId
      companyUserId
      scorecardTemplateId
      status
      templateStageId
      updatedAt
      createdAt
      ScorecardTemplate {
        id
        scorecardName
        department
        createdAt
        companyTemplate
        archived
        companyId
        timestampAt
      }
      CandidateApplication {
        id
        candidateProfileId
        applicationStatus
        JobListing {
          id
          externalTitle
          Requisition {
            id
            extRequisitionId
            jobName
          }
        }
      }
      CandidateScorecardAnswers {
        answer
        answerPreferredStatus
        candidateScorecardId
        dateAnswer
        id
        scoreAnswer
        scorecardTemplateQuestionId
        overallRecommendation
      }
      TemplateStage {
        name
        id
        createdAt
        archived
        rankOrder
        templateMilestoneId
        templatePipelineId
        updatedAt
        visibility
        TemplateMilestone {
          id
          name
        }
      }
      CompanyUser {
        UserInvitation {
          ScalisUser {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`

/**
 * __useGetCandidateScorecardQuery__
 *
 * To run a query within a React component, call `useGetCandidateScorecardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateScorecardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateScorecardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCandidateScorecardQuery(
  baseOptions: Apollo.QueryHookOptions<GetCandidateScorecardQuery, GetCandidateScorecardQueryVariables> &
    ({ variables: GetCandidateScorecardQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateScorecardQuery, GetCandidateScorecardQueryVariables>(
    GetCandidateScorecardDocument,
    options,
  )
}
export function useGetCandidateScorecardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateScorecardQuery, GetCandidateScorecardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateScorecardQuery, GetCandidateScorecardQueryVariables>(
    GetCandidateScorecardDocument,
    options,
  )
}
export function useGetCandidateScorecardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateScorecardQuery, GetCandidateScorecardQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateScorecardQuery, GetCandidateScorecardQueryVariables>(
    GetCandidateScorecardDocument,
    options,
  )
}
export type GetCandidateScorecardQueryHookResult = ReturnType<typeof useGetCandidateScorecardQuery>
export type GetCandidateScorecardLazyQueryHookResult = ReturnType<typeof useGetCandidateScorecardLazyQuery>
export type GetCandidateScorecardSuspenseQueryHookResult = ReturnType<typeof useGetCandidateScorecardSuspenseQuery>
export type GetCandidateScorecardQueryResult = Apollo.QueryResult<
  GetCandidateScorecardQuery,
  GetCandidateScorecardQueryVariables
>
export const SendReminderCandidateScorecardDocument = gql`
  mutation SendReminderCandidateScorecard($input: SendReminderCandidateScorecardInput!) {
    SendReminderCandidateScorecard(input: $input) {
      id
    }
  }
`
export type SendReminderCandidateScorecardMutationFn = Apollo.MutationFunction<
  SendReminderCandidateScorecardMutation,
  SendReminderCandidateScorecardMutationVariables
>

/**
 * __useSendReminderCandidateScorecardMutation__
 *
 * To run a mutation, you first call `useSendReminderCandidateScorecardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReminderCandidateScorecardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReminderCandidateScorecardMutation, { data, loading, error }] = useSendReminderCandidateScorecardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendReminderCandidateScorecardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendReminderCandidateScorecardMutation,
    SendReminderCandidateScorecardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendReminderCandidateScorecardMutation, SendReminderCandidateScorecardMutationVariables>(
    SendReminderCandidateScorecardDocument,
    options,
  )
}
export type SendReminderCandidateScorecardMutationHookResult = ReturnType<
  typeof useSendReminderCandidateScorecardMutation
>
export type SendReminderCandidateScorecardMutationResult = Apollo.MutationResult<SendReminderCandidateScorecardMutation>
export type SendReminderCandidateScorecardMutationOptions = Apollo.BaseMutationOptions<
  SendReminderCandidateScorecardMutation,
  SendReminderCandidateScorecardMutationVariables
>
export const UpsertCandidateScorecardAnswersDocument = gql`
  mutation UpsertCandidateScorecardAnswers($input: UpsertCandidateScorecardAnswersInput!) {
    UpsertCandidateScorecardAnswers(input: $input) {
      id
      answer
      answerPreferredStatus
      candidateScorecardId
      dateAnswer
      scoreAnswer
      scorecardTemplateQuestionId
    }
  }
`
export type UpsertCandidateScorecardAnswersMutationFn = Apollo.MutationFunction<
  UpsertCandidateScorecardAnswersMutation,
  UpsertCandidateScorecardAnswersMutationVariables
>

/**
 * __useUpsertCandidateScorecardAnswersMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateScorecardAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateScorecardAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateScorecardAnswersMutation, { data, loading, error }] = useUpsertCandidateScorecardAnswersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateScorecardAnswersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCandidateScorecardAnswersMutation,
    UpsertCandidateScorecardAnswersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateScorecardAnswersMutation, UpsertCandidateScorecardAnswersMutationVariables>(
    UpsertCandidateScorecardAnswersDocument,
    options,
  )
}
export type UpsertCandidateScorecardAnswersMutationHookResult = ReturnType<
  typeof useUpsertCandidateScorecardAnswersMutation
>
export type UpsertCandidateScorecardAnswersMutationResult =
  Apollo.MutationResult<UpsertCandidateScorecardAnswersMutation>
export type UpsertCandidateScorecardAnswersMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateScorecardAnswersMutation,
  UpsertCandidateScorecardAnswersMutationVariables
>
export const UpsertCandidateScorecardDocument = gql`
  mutation UpsertCandidateScorecard($input: UpsertCandidateScorecardInput!) {
    UpsertCandidateScorecard(input: $input) {
      id
      scorecardTemplateId
      status
      templateStageId
      companyUserId
      candidateApplicationId
      additionalInformation
    }
  }
`
export type UpsertCandidateScorecardMutationFn = Apollo.MutationFunction<
  UpsertCandidateScorecardMutation,
  UpsertCandidateScorecardMutationVariables
>

/**
 * __useUpsertCandidateScorecardMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateScorecardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateScorecardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateScorecardMutation, { data, loading, error }] = useUpsertCandidateScorecardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateScorecardMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertCandidateScorecardMutation, UpsertCandidateScorecardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateScorecardMutation, UpsertCandidateScorecardMutationVariables>(
    UpsertCandidateScorecardDocument,
    options,
  )
}
export type UpsertCandidateScorecardMutationHookResult = ReturnType<typeof useUpsertCandidateScorecardMutation>
export type UpsertCandidateScorecardMutationResult = Apollo.MutationResult<UpsertCandidateScorecardMutation>
export type UpsertCandidateScorecardMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateScorecardMutation,
  UpsertCandidateScorecardMutationVariables
>
export const CreateCandidateSourceNameDocument = gql`
  mutation CreateCandidateSourceName($input: CandidateSourceNameCreateInput!) {
    CreateCandidateSourceName(input: $input) {
      id
      name
      sourceCategory
    }
  }
`
export type CreateCandidateSourceNameMutationFn = Apollo.MutationFunction<
  CreateCandidateSourceNameMutation,
  CreateCandidateSourceNameMutationVariables
>

/**
 * __useCreateCandidateSourceNameMutation__
 *
 * To run a mutation, you first call `useCreateCandidateSourceNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateSourceNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateSourceNameMutation, { data, loading, error }] = useCreateCandidateSourceNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCandidateSourceNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCandidateSourceNameMutation,
    CreateCandidateSourceNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCandidateSourceNameMutation, CreateCandidateSourceNameMutationVariables>(
    CreateCandidateSourceNameDocument,
    options,
  )
}
export type CreateCandidateSourceNameMutationHookResult = ReturnType<typeof useCreateCandidateSourceNameMutation>
export type CreateCandidateSourceNameMutationResult = Apollo.MutationResult<CreateCandidateSourceNameMutation>
export type CreateCandidateSourceNameMutationOptions = Apollo.BaseMutationOptions<
  CreateCandidateSourceNameMutation,
  CreateCandidateSourceNameMutationVariables
>
export const CreateCompanyUserGroupDocument = gql`
  mutation CreateCompanyUserGroup($input: CreateCompanyUserGroupInput!) {
    CreateCompanyUserGroup(input: $input) {
      id
      name
      createdAt
      updatedAt
      deleted
      createdBy {
        id
        profileImage
        UserInvitation {
          ScalisUser {
            firstName
            lastName
            EmailVerification {
              emailAddress
            }
          }
        }
      }
      Offices {
        CompanyOffice {
          id
          officeName
          externalId
        }
      }
      Departments {
        CompanyDepartment {
          id
          departmentName
          externalId
        }
      }
      Members {
        CompanyUser {
          id
          profileImage
          UserInvitation {
            ScalisUser {
              firstName
              lastName
              EmailVerification {
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`
export type CreateCompanyUserGroupMutationFn = Apollo.MutationFunction<
  CreateCompanyUserGroupMutation,
  CreateCompanyUserGroupMutationVariables
>

/**
 * __useCreateCompanyUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateCompanyUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyUserGroupMutation, { data, loading, error }] = useCreateCompanyUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyUserGroupMutation, CreateCompanyUserGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyUserGroupMutation, CreateCompanyUserGroupMutationVariables>(
    CreateCompanyUserGroupDocument,
    options,
  )
}
export type CreateCompanyUserGroupMutationHookResult = ReturnType<typeof useCreateCompanyUserGroupMutation>
export type CreateCompanyUserGroupMutationResult = Apollo.MutationResult<CreateCompanyUserGroupMutation>
export type CreateCompanyUserGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyUserGroupMutation,
  CreateCompanyUserGroupMutationVariables
>
export const CreateUsersInCompanyUserGroupDocument = gql`
  mutation CreateUsersInCompanyUserGroup($input: CreateUsersInCompanyUserGroupInput!) {
    CreateUsersInCompanyUserGroup(input: $input) {
      id
      name
      createdAt
      updatedAt
      deleted
      Members {
        CompanyUser {
          id
          profileImage
          UserInvitation {
            ScalisUser {
              firstName
              lastName
              EmailVerification {
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`
export type CreateUsersInCompanyUserGroupMutationFn = Apollo.MutationFunction<
  CreateUsersInCompanyUserGroupMutation,
  CreateUsersInCompanyUserGroupMutationVariables
>

/**
 * __useCreateUsersInCompanyUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUsersInCompanyUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersInCompanyUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersInCompanyUserGroupMutation, { data, loading, error }] = useCreateUsersInCompanyUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsersInCompanyUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUsersInCompanyUserGroupMutation,
    CreateUsersInCompanyUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUsersInCompanyUserGroupMutation, CreateUsersInCompanyUserGroupMutationVariables>(
    CreateUsersInCompanyUserGroupDocument,
    options,
  )
}
export type CreateUsersInCompanyUserGroupMutationHookResult = ReturnType<
  typeof useCreateUsersInCompanyUserGroupMutation
>
export type CreateUsersInCompanyUserGroupMutationResult = Apollo.MutationResult<CreateUsersInCompanyUserGroupMutation>
export type CreateUsersInCompanyUserGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateUsersInCompanyUserGroupMutation,
  CreateUsersInCompanyUserGroupMutationVariables
>
export const DeleteCompanyUserGroupDocument = gql`
  mutation DeleteCompanyUserGroup($input: DeleteCompanyUserGroupInput!) {
    DeleteCompanyUserGroup(input: $input) {
      id
      name
      createdAt
      updatedAt
      deleted
    }
  }
`
export type DeleteCompanyUserGroupMutationFn = Apollo.MutationFunction<
  DeleteCompanyUserGroupMutation,
  DeleteCompanyUserGroupMutationVariables
>

/**
 * __useDeleteCompanyUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyUserGroupMutation, { data, loading, error }] = useDeleteCompanyUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyUserGroupMutation, DeleteCompanyUserGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyUserGroupMutation, DeleteCompanyUserGroupMutationVariables>(
    DeleteCompanyUserGroupDocument,
    options,
  )
}
export type DeleteCompanyUserGroupMutationHookResult = ReturnType<typeof useDeleteCompanyUserGroupMutation>
export type DeleteCompanyUserGroupMutationResult = Apollo.MutationResult<DeleteCompanyUserGroupMutation>
export type DeleteCompanyUserGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyUserGroupMutation,
  DeleteCompanyUserGroupMutationVariables
>
export const DeleteUsersFromCompanyUserGroupDocument = gql`
  mutation DeleteUsersFromCompanyUserGroup($input: DeleteUsersFromCompanyUserGroupInput!) {
    DeleteUsersFromCompanyUserGroup(input: $input) {
      id
      name
      createdAt
      updatedAt
      deleted
      Members {
        CompanyUser {
          id
          profileImage
          UserInvitation {
            ScalisUser {
              firstName
              lastName
              EmailVerification {
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`
export type DeleteUsersFromCompanyUserGroupMutationFn = Apollo.MutationFunction<
  DeleteUsersFromCompanyUserGroupMutation,
  DeleteUsersFromCompanyUserGroupMutationVariables
>

/**
 * __useDeleteUsersFromCompanyUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUsersFromCompanyUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersFromCompanyUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersFromCompanyUserGroupMutation, { data, loading, error }] = useDeleteUsersFromCompanyUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUsersFromCompanyUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUsersFromCompanyUserGroupMutation,
    DeleteUsersFromCompanyUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteUsersFromCompanyUserGroupMutation, DeleteUsersFromCompanyUserGroupMutationVariables>(
    DeleteUsersFromCompanyUserGroupDocument,
    options,
  )
}
export type DeleteUsersFromCompanyUserGroupMutationHookResult = ReturnType<
  typeof useDeleteUsersFromCompanyUserGroupMutation
>
export type DeleteUsersFromCompanyUserGroupMutationResult =
  Apollo.MutationResult<DeleteUsersFromCompanyUserGroupMutation>
export type DeleteUsersFromCompanyUserGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteUsersFromCompanyUserGroupMutation,
  DeleteUsersFromCompanyUserGroupMutationVariables
>
export const UpdateCompanyUserGroupDocument = gql`
  mutation UpdateCompanyUserGroup($input: UpdateCompanyUserGroupInput!) {
    UpdateCompanyUserGroup(input: $input) {
      id
      name
      createdAt
      updatedAt
      deleted
      Members {
        CompanyUser {
          id
          profileImage
          UserInvitation {
            ScalisUser {
              firstName
              lastName
              EmailVerification {
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`
export type UpdateCompanyUserGroupMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserGroupMutation,
  UpdateCompanyUserGroupMutationVariables
>

/**
 * __useUpdateCompanyUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserGroupMutation, { data, loading, error }] = useUpdateCompanyUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyUserGroupMutation, UpdateCompanyUserGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyUserGroupMutation, UpdateCompanyUserGroupMutationVariables>(
    UpdateCompanyUserGroupDocument,
    options,
  )
}
export type UpdateCompanyUserGroupMutationHookResult = ReturnType<typeof useUpdateCompanyUserGroupMutation>
export type UpdateCompanyUserGroupMutationResult = Apollo.MutationResult<UpdateCompanyUserGroupMutation>
export type UpdateCompanyUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserGroupMutation,
  UpdateCompanyUserGroupMutationVariables
>
export const UpdateUsersInCompanyUserGroupDocument = gql`
  mutation UpdateUsersInCompanyUserGroup($input: UpdateUsersInCompanyUserGroupInput!) {
    UpdateUsersInCompanyUserGroup(input: $input) {
      id
      name
      createdAt
      updatedAt
      deleted
      Members {
        CompanyUser {
          id
          profileImage
          UserInvitation {
            ScalisUser {
              firstName
              lastName
              EmailVerification {
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`
export type UpdateUsersInCompanyUserGroupMutationFn = Apollo.MutationFunction<
  UpdateUsersInCompanyUserGroupMutation,
  UpdateUsersInCompanyUserGroupMutationVariables
>

/**
 * __useUpdateUsersInCompanyUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUsersInCompanyUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersInCompanyUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersInCompanyUserGroupMutation, { data, loading, error }] = useUpdateUsersInCompanyUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsersInCompanyUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUsersInCompanyUserGroupMutation,
    UpdateUsersInCompanyUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUsersInCompanyUserGroupMutation, UpdateUsersInCompanyUserGroupMutationVariables>(
    UpdateUsersInCompanyUserGroupDocument,
    options,
  )
}
export type UpdateUsersInCompanyUserGroupMutationHookResult = ReturnType<
  typeof useUpdateUsersInCompanyUserGroupMutation
>
export type UpdateUsersInCompanyUserGroupMutationResult = Apollo.MutationResult<UpdateUsersInCompanyUserGroupMutation>
export type UpdateUsersInCompanyUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsersInCompanyUserGroupMutation,
  UpdateUsersInCompanyUserGroupMutationVariables
>
export const ActivateCompanyUserDocument = gql`
  mutation ActivateCompanyUser($input: ActivateCompanyUserInput!) {
    ActivateCompanyUser(input: $input) {
      id
    }
  }
`
export type ActivateCompanyUserMutationFn = Apollo.MutationFunction<
  ActivateCompanyUserMutation,
  ActivateCompanyUserMutationVariables
>

/**
 * __useActivateCompanyUserMutation__
 *
 * To run a mutation, you first call `useActivateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompanyUserMutation, { data, loading, error }] = useActivateCompanyUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateCompanyUserMutation, ActivateCompanyUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ActivateCompanyUserMutation, ActivateCompanyUserMutationVariables>(
    ActivateCompanyUserDocument,
    options,
  )
}
export type ActivateCompanyUserMutationHookResult = ReturnType<typeof useActivateCompanyUserMutation>
export type ActivateCompanyUserMutationResult = Apollo.MutationResult<ActivateCompanyUserMutation>
export type ActivateCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  ActivateCompanyUserMutation,
  ActivateCompanyUserMutationVariables
>
export const CreateCompanyUserFromInvitationDocument = gql`
  mutation CreateCompanyUserFromInvitation($input: CreateCompanyUserFromInvitationInput!) {
    CreateCompanyUserFromInvitation(input: $input) {
      id
    }
  }
`
export type CreateCompanyUserFromInvitationMutationFn = Apollo.MutationFunction<
  CreateCompanyUserFromInvitationMutation,
  CreateCompanyUserFromInvitationMutationVariables
>

/**
 * __useCreateCompanyUserFromInvitationMutation__
 *
 * To run a mutation, you first call `useCreateCompanyUserFromInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyUserFromInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyUserFromInvitationMutation, { data, loading, error }] = useCreateCompanyUserFromInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyUserFromInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyUserFromInvitationMutation,
    CreateCompanyUserFromInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyUserFromInvitationMutation, CreateCompanyUserFromInvitationMutationVariables>(
    CreateCompanyUserFromInvitationDocument,
    options,
  )
}
export type CreateCompanyUserFromInvitationMutationHookResult = ReturnType<
  typeof useCreateCompanyUserFromInvitationMutation
>
export type CreateCompanyUserFromInvitationMutationResult =
  Apollo.MutationResult<CreateCompanyUserFromInvitationMutation>
export type CreateCompanyUserFromInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyUserFromInvitationMutation,
  CreateCompanyUserFromInvitationMutationVariables
>
export const DeleteCompanyUserFromWorkspaceDocument = gql`
  mutation DeleteCompanyUserFromWorkspace($input: DeleteCompanyUserFromWorkspaceInput!) {
    DeleteCompanyUserFromWorkspace(input: $input) {
      id
    }
  }
`
export type DeleteCompanyUserFromWorkspaceMutationFn = Apollo.MutationFunction<
  DeleteCompanyUserFromWorkspaceMutation,
  DeleteCompanyUserFromWorkspaceMutationVariables
>

/**
 * __useDeleteCompanyUserFromWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyUserFromWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyUserFromWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyUserFromWorkspaceMutation, { data, loading, error }] = useDeleteCompanyUserFromWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyUserFromWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyUserFromWorkspaceMutation,
    DeleteCompanyUserFromWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyUserFromWorkspaceMutation, DeleteCompanyUserFromWorkspaceMutationVariables>(
    DeleteCompanyUserFromWorkspaceDocument,
    options,
  )
}
export type DeleteCompanyUserFromWorkspaceMutationHookResult = ReturnType<
  typeof useDeleteCompanyUserFromWorkspaceMutation
>
export type DeleteCompanyUserFromWorkspaceMutationResult = Apollo.MutationResult<DeleteCompanyUserFromWorkspaceMutation>
export type DeleteCompanyUserFromWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyUserFromWorkspaceMutation,
  DeleteCompanyUserFromWorkspaceMutationVariables
>
export const DeleteInvitationFromWorkspaceDocument = gql`
  mutation DeleteInvitationFromWorkspace($input: DeleteInvitationFromWorkspaceInput!) {
    DeleteInvitationFromWorkspace(input: $input) {
      id
    }
  }
`
export type DeleteInvitationFromWorkspaceMutationFn = Apollo.MutationFunction<
  DeleteInvitationFromWorkspaceMutation,
  DeleteInvitationFromWorkspaceMutationVariables
>

/**
 * __useDeleteInvitationFromWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationFromWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationFromWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationFromWorkspaceMutation, { data, loading, error }] = useDeleteInvitationFromWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvitationFromWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvitationFromWorkspaceMutation,
    DeleteInvitationFromWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteInvitationFromWorkspaceMutation, DeleteInvitationFromWorkspaceMutationVariables>(
    DeleteInvitationFromWorkspaceDocument,
    options,
  )
}
export type DeleteInvitationFromWorkspaceMutationHookResult = ReturnType<
  typeof useDeleteInvitationFromWorkspaceMutation
>
export type DeleteInvitationFromWorkspaceMutationResult = Apollo.MutationResult<DeleteInvitationFromWorkspaceMutation>
export type DeleteInvitationFromWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitationFromWorkspaceMutation,
  DeleteInvitationFromWorkspaceMutationVariables
>
export const InviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    InviteUser(input: $input) {
      id
    }
  }
`
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options)
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>
export const UpdateCompanyUserRoleDocument = gql`
  mutation UpdateCompanyUserRole($input: UpdateCompanyUserRoleInput!) {
    UpdateCompanyUserRole(input: $input) {
      id
    }
  }
`
export type UpdateCompanyUserRoleMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserRoleMutation,
  UpdateCompanyUserRoleMutationVariables
>

/**
 * __useUpdateCompanyUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserRoleMutation, { data, loading, error }] = useUpdateCompanyUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyUserRoleMutation, UpdateCompanyUserRoleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyUserRoleMutation, UpdateCompanyUserRoleMutationVariables>(
    UpdateCompanyUserRoleDocument,
    options,
  )
}
export type UpdateCompanyUserRoleMutationHookResult = ReturnType<typeof useUpdateCompanyUserRoleMutation>
export type UpdateCompanyUserRoleMutationResult = Apollo.MutationResult<UpdateCompanyUserRoleMutation>
export type UpdateCompanyUserRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserRoleMutation,
  UpdateCompanyUserRoleMutationVariables
>
export const CreateCompanyDocument = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    CreateCompany(input: $input) {
      __typename
      id
    }
  }
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options)
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>
export const DeleteCompanyDepartmentDocument = gql`
  mutation DeleteCompanyDepartment($input: DeleteCompanyDepartmentInput!) {
    DeleteCompanyDepartment(input: $input) {
      __typename
      id
    }
  }
`
export type DeleteCompanyDepartmentMutationFn = Apollo.MutationFunction<
  DeleteCompanyDepartmentMutation,
  DeleteCompanyDepartmentMutationVariables
>

/**
 * __useDeleteCompanyDepartmentMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyDepartmentMutation, { data, loading, error }] = useDeleteCompanyDepartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyDepartmentMutation, DeleteCompanyDepartmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyDepartmentMutation, DeleteCompanyDepartmentMutationVariables>(
    DeleteCompanyDepartmentDocument,
    options,
  )
}
export type DeleteCompanyDepartmentMutationHookResult = ReturnType<typeof useDeleteCompanyDepartmentMutation>
export type DeleteCompanyDepartmentMutationResult = Apollo.MutationResult<DeleteCompanyDepartmentMutation>
export type DeleteCompanyDepartmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyDepartmentMutation,
  DeleteCompanyDepartmentMutationVariables
>
export const DeleteCompanyOfficeDocument = gql`
  mutation DeleteCompanyOffice($input: DeleteCompanyOfficeInput!) {
    DeleteCompanyOffice(input: $input) {
      __typename
      id
    }
  }
`
export type DeleteCompanyOfficeMutationFn = Apollo.MutationFunction<
  DeleteCompanyOfficeMutation,
  DeleteCompanyOfficeMutationVariables
>

/**
 * __useDeleteCompanyOfficeMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyOfficeMutation, { data, loading, error }] = useDeleteCompanyOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyOfficeMutation, DeleteCompanyOfficeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyOfficeMutation, DeleteCompanyOfficeMutationVariables>(
    DeleteCompanyOfficeDocument,
    options,
  )
}
export type DeleteCompanyOfficeMutationHookResult = ReturnType<typeof useDeleteCompanyOfficeMutation>
export type DeleteCompanyOfficeMutationResult = Apollo.MutationResult<DeleteCompanyOfficeMutation>
export type DeleteCompanyOfficeMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyOfficeMutation,
  DeleteCompanyOfficeMutationVariables
>
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($input: UpdateCompanyDataInput!) {
    UpdateCompanyData(input: $input) {
      __typename
      id
    }
  }
`
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options)
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>
export const UpsertGlobalListingSetupDocument = gql`
  mutation UpsertGlobalListingSetup($input: GlobalListingSetupInput!) {
    upsertGlobalListingSetup(input: $input) {
      id
      aboutCompanyStatement
      footerStatement
      companyId
      isAboutCompanyActive
      isFooterStatementActive
      updatedAt
    }
  }
`
export type UpsertGlobalListingSetupMutationFn = Apollo.MutationFunction<
  UpsertGlobalListingSetupMutation,
  UpsertGlobalListingSetupMutationVariables
>

/**
 * __useUpsertGlobalListingSetupMutation__
 *
 * To run a mutation, you first call `useUpsertGlobalListingSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertGlobalListingSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertGlobalListingSetupMutation, { data, loading, error }] = useUpsertGlobalListingSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertGlobalListingSetupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertGlobalListingSetupMutation, UpsertGlobalListingSetupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertGlobalListingSetupMutation, UpsertGlobalListingSetupMutationVariables>(
    UpsertGlobalListingSetupDocument,
    options,
  )
}
export type UpsertGlobalListingSetupMutationHookResult = ReturnType<typeof useUpsertGlobalListingSetupMutation>
export type UpsertGlobalListingSetupMutationResult = Apollo.MutationResult<UpsertGlobalListingSetupMutation>
export type UpsertGlobalListingSetupMutationOptions = Apollo.BaseMutationOptions<
  UpsertGlobalListingSetupMutation,
  UpsertGlobalListingSetupMutationVariables
>
export const DeleteGlobalScreeningQuestionDocument = gql`
  mutation DeleteGlobalScreeningQuestion($input: DeleteGlobalScreeningQuestionInput!) {
    DeleteGlobalScreeningQuestion(input: $input) {
      id
      questionTitle
      questionDesc
    }
  }
`
export type DeleteGlobalScreeningQuestionMutationFn = Apollo.MutationFunction<
  DeleteGlobalScreeningQuestionMutation,
  DeleteGlobalScreeningQuestionMutationVariables
>

/**
 * __useDeleteGlobalScreeningQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteGlobalScreeningQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalScreeningQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalScreeningQuestionMutation, { data, loading, error }] = useDeleteGlobalScreeningQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGlobalScreeningQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGlobalScreeningQuestionMutation,
    DeleteGlobalScreeningQuestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteGlobalScreeningQuestionMutation, DeleteGlobalScreeningQuestionMutationVariables>(
    DeleteGlobalScreeningQuestionDocument,
    options,
  )
}
export type DeleteGlobalScreeningQuestionMutationHookResult = ReturnType<
  typeof useDeleteGlobalScreeningQuestionMutation
>
export type DeleteGlobalScreeningQuestionMutationResult = Apollo.MutationResult<DeleteGlobalScreeningQuestionMutation>
export type DeleteGlobalScreeningQuestionMutationOptions = Apollo.BaseMutationOptions<
  DeleteGlobalScreeningQuestionMutation,
  DeleteGlobalScreeningQuestionMutationVariables
>
export const EditGlobalScreeningQuestionDocument = gql`
  mutation EditGlobalScreeningQuestion($input: EditGlobalScreeningQuestionInput!) {
    EditGlobalScreeningQuestion(input: $input) {
      id
      questionTitle
      questionDesc
      questionType
      minVal
      maxVal
      valStatus
      ansOptions
      isRejectionQuestion
      rejectMessage
      preferredAnswer
      isGlobalQuestion
      isActiveGlobally
      globalQuestionOrder
      isEdited
      isReplacedBy
      isDeleted
      companyId
      companyUserId
      createdAt
      updatedAt
    }
  }
`
export type EditGlobalScreeningQuestionMutationFn = Apollo.MutationFunction<
  EditGlobalScreeningQuestionMutation,
  EditGlobalScreeningQuestionMutationVariables
>

/**
 * __useEditGlobalScreeningQuestionMutation__
 *
 * To run a mutation, you first call `useEditGlobalScreeningQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGlobalScreeningQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGlobalScreeningQuestionMutation, { data, loading, error }] = useEditGlobalScreeningQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGlobalScreeningQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditGlobalScreeningQuestionMutation,
    EditGlobalScreeningQuestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditGlobalScreeningQuestionMutation, EditGlobalScreeningQuestionMutationVariables>(
    EditGlobalScreeningQuestionDocument,
    options,
  )
}
export type EditGlobalScreeningQuestionMutationHookResult = ReturnType<typeof useEditGlobalScreeningQuestionMutation>
export type EditGlobalScreeningQuestionMutationResult = Apollo.MutationResult<EditGlobalScreeningQuestionMutation>
export type EditGlobalScreeningQuestionMutationOptions = Apollo.BaseMutationOptions<
  EditGlobalScreeningQuestionMutation,
  EditGlobalScreeningQuestionMutationVariables
>
export const InsertGlobalScreeningQuestionDocument = gql`
  mutation InsertGlobalScreeningQuestion($input: [InsertGlobalScreeningQuestionInput!]!) {
    InsertGlobalScreeningQuestion(input: $input) {
      id
      questionTitle
      questionDesc
      questionType
      ansOptions
      isRejectionQuestion
      rejectMessage
      preferredAnswer
      isActiveGlobally
      globalQuestionOrder
      createdAt
      updatedAt
    }
  }
`
export type InsertGlobalScreeningQuestionMutationFn = Apollo.MutationFunction<
  InsertGlobalScreeningQuestionMutation,
  InsertGlobalScreeningQuestionMutationVariables
>

/**
 * __useInsertGlobalScreeningQuestionMutation__
 *
 * To run a mutation, you first call `useInsertGlobalScreeningQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGlobalScreeningQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGlobalScreeningQuestionMutation, { data, loading, error }] = useInsertGlobalScreeningQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertGlobalScreeningQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertGlobalScreeningQuestionMutation,
    InsertGlobalScreeningQuestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InsertGlobalScreeningQuestionMutation, InsertGlobalScreeningQuestionMutationVariables>(
    InsertGlobalScreeningQuestionDocument,
    options,
  )
}
export type InsertGlobalScreeningQuestionMutationHookResult = ReturnType<
  typeof useInsertGlobalScreeningQuestionMutation
>
export type InsertGlobalScreeningQuestionMutationResult = Apollo.MutationResult<InsertGlobalScreeningQuestionMutation>
export type InsertGlobalScreeningQuestionMutationOptions = Apollo.BaseMutationOptions<
  InsertGlobalScreeningQuestionMutation,
  InsertGlobalScreeningQuestionMutationVariables
>
export const DeleteHiringTeamMemberDocument = gql`
  mutation DeleteHiringTeamMember($input: DeleteHiringTeamMemberInput!) {
    DeleteHiringTeamMember(input: $input) {
      id
    }
  }
`
export type DeleteHiringTeamMemberMutationFn = Apollo.MutationFunction<
  DeleteHiringTeamMemberMutation,
  DeleteHiringTeamMemberMutationVariables
>

/**
 * __useDeleteHiringTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteHiringTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHiringTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHiringTeamMemberMutation, { data, loading, error }] = useDeleteHiringTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteHiringTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteHiringTeamMemberMutation, DeleteHiringTeamMemberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteHiringTeamMemberMutation, DeleteHiringTeamMemberMutationVariables>(
    DeleteHiringTeamMemberDocument,
    options,
  )
}
export type DeleteHiringTeamMemberMutationHookResult = ReturnType<typeof useDeleteHiringTeamMemberMutation>
export type DeleteHiringTeamMemberMutationResult = Apollo.MutationResult<DeleteHiringTeamMemberMutation>
export type DeleteHiringTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteHiringTeamMemberMutation,
  DeleteHiringTeamMemberMutationVariables
>
export const UpsertHiringTeamMemberDocument = gql`
  mutation UpsertHiringTeamMember($input: UpsertHiringTeamMemberInput!) {
    UpsertHiringTeamMember(input: $input) {
      ...HiringTeamMemberFragment
    }
  }
  ${HiringTeamMemberFragmentFragmentDoc}
`
export type UpsertHiringTeamMemberMutationFn = Apollo.MutationFunction<
  UpsertHiringTeamMemberMutation,
  UpsertHiringTeamMemberMutationVariables
>

/**
 * __useUpsertHiringTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpsertHiringTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertHiringTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertHiringTeamMemberMutation, { data, loading, error }] = useUpsertHiringTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertHiringTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertHiringTeamMemberMutation, UpsertHiringTeamMemberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertHiringTeamMemberMutation, UpsertHiringTeamMemberMutationVariables>(
    UpsertHiringTeamMemberDocument,
    options,
  )
}
export type UpsertHiringTeamMemberMutationHookResult = ReturnType<typeof useUpsertHiringTeamMemberMutation>
export type UpsertHiringTeamMemberMutationResult = Apollo.MutationResult<UpsertHiringTeamMemberMutation>
export type UpsertHiringTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  UpsertHiringTeamMemberMutation,
  UpsertHiringTeamMemberMutationVariables
>
export const CreateInterviewDocument = gql`
  mutation CreateInterview($input: CreateInterviewInput!) {
    CreateInterview(input: $input) {
      status
      stageId
      interviewData
      id
      companyUserId
      calendarEventId
      applicationId
    }
  }
`
export type CreateInterviewMutationFn = Apollo.MutationFunction<
  CreateInterviewMutation,
  CreateInterviewMutationVariables
>

/**
 * __useCreateInterviewMutation__
 *
 * To run a mutation, you first call `useCreateInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterviewMutation, { data, loading, error }] = useCreateInterviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInterviewMutation, CreateInterviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateInterviewMutation, CreateInterviewMutationVariables>(CreateInterviewDocument, options)
}
export type CreateInterviewMutationHookResult = ReturnType<typeof useCreateInterviewMutation>
export type CreateInterviewMutationResult = Apollo.MutationResult<CreateInterviewMutation>
export type CreateInterviewMutationOptions = Apollo.BaseMutationOptions<
  CreateInterviewMutation,
  CreateInterviewMutationVariables
>
export const CreateSchedulerLinkDocument = gql`
  mutation CreateSchedulerLink($input: CreateSchedulerLinkInviteInput!) {
    CreateSchedulerLinkInvite(input: $input)
  }
`
export type CreateSchedulerLinkMutationFn = Apollo.MutationFunction<
  CreateSchedulerLinkMutation,
  CreateSchedulerLinkMutationVariables
>

/**
 * __useCreateSchedulerLinkMutation__
 *
 * To run a mutation, you first call `useCreateSchedulerLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchedulerLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchedulerLinkMutation, { data, loading, error }] = useCreateSchedulerLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSchedulerLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSchedulerLinkMutation, CreateSchedulerLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSchedulerLinkMutation, CreateSchedulerLinkMutationVariables>(
    CreateSchedulerLinkDocument,
    options,
  )
}
export type CreateSchedulerLinkMutationHookResult = ReturnType<typeof useCreateSchedulerLinkMutation>
export type CreateSchedulerLinkMutationResult = Apollo.MutationResult<CreateSchedulerLinkMutation>
export type CreateSchedulerLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateSchedulerLinkMutation,
  CreateSchedulerLinkMutationVariables
>
export const DeleteInterviewDocument = gql`
  mutation DeleteInterview($input: DeleteInterviewInput!) {
    DeleteInterview(input: $input)
  }
`
export type DeleteInterviewMutationFn = Apollo.MutationFunction<
  DeleteInterviewMutation,
  DeleteInterviewMutationVariables
>

/**
 * __useDeleteInterviewMutation__
 *
 * To run a mutation, you first call `useDeleteInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInterviewMutation, { data, loading, error }] = useDeleteInterviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInterviewMutation, DeleteInterviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteInterviewMutation, DeleteInterviewMutationVariables>(DeleteInterviewDocument, options)
}
export type DeleteInterviewMutationHookResult = ReturnType<typeof useDeleteInterviewMutation>
export type DeleteInterviewMutationResult = Apollo.MutationResult<DeleteInterviewMutation>
export type DeleteInterviewMutationOptions = Apollo.BaseMutationOptions<
  DeleteInterviewMutation,
  DeleteInterviewMutationVariables
>
export const UpdateInterviewDocument = gql`
  mutation UpdateInterview($input: UpdateInterviewInput!) {
    UpdateInterview(input: $input) {
      status
      stageId
      interviewData
      id
      companyUserId
      calendarEventId
      applicationId
    }
  }
`
export type UpdateInterviewMutationFn = Apollo.MutationFunction<
  UpdateInterviewMutation,
  UpdateInterviewMutationVariables
>

/**
 * __useUpdateInterviewMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewMutation, { data, loading, error }] = useUpdateInterviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInterviewMutation, UpdateInterviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateInterviewMutation, UpdateInterviewMutationVariables>(UpdateInterviewDocument, options)
}
export type UpdateInterviewMutationHookResult = ReturnType<typeof useUpdateInterviewMutation>
export type UpdateInterviewMutationResult = Apollo.MutationResult<UpdateInterviewMutation>
export type UpdateInterviewMutationOptions = Apollo.BaseMutationOptions<
  UpdateInterviewMutation,
  UpdateInterviewMutationVariables
>
export const CountCampaignViewDocument = gql`
  mutation CountCampaignView($input: CountCampaignViewInput!) {
    CountCampaignView(input: $input) {
      message
      viewCount
    }
  }
`
export type CountCampaignViewMutationFn = Apollo.MutationFunction<
  CountCampaignViewMutation,
  CountCampaignViewMutationVariables
>

/**
 * __useCountCampaignViewMutation__
 *
 * To run a mutation, you first call `useCountCampaignViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCountCampaignViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [countCampaignViewMutation, { data, loading, error }] = useCountCampaignViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountCampaignViewMutation(
  baseOptions?: Apollo.MutationHookOptions<CountCampaignViewMutation, CountCampaignViewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CountCampaignViewMutation, CountCampaignViewMutationVariables>(
    CountCampaignViewDocument,
    options,
  )
}
export type CountCampaignViewMutationHookResult = ReturnType<typeof useCountCampaignViewMutation>
export type CountCampaignViewMutationResult = Apollo.MutationResult<CountCampaignViewMutation>
export type CountCampaignViewMutationOptions = Apollo.BaseMutationOptions<
  CountCampaignViewMutation,
  CountCampaignViewMutationVariables
>
export const OrderJobCampaignDocument = gql`
  mutation OrderJobCampaign($input: OrderCampaignInput!) {
    OrderJobCampaign(input: $input) {
      ... on JobCampaignCreation {
        orderReference
        clientSecret
        deliveryTime {
          totalDays
          daysToSetup
          daysToProcess
        }
        price {
          subTotal
          total
          vat
        }
      }
      ... on CampaignValidationErrors {
        errors {
          company
          orderedProducts {
            message
          }
          orderedProductsSpecs {
            message
          }
          postingDetails {
            fields
          }
          targetGroup {
            fields
          }
          recruiterInfo {
            fields
          }
        }
      }
    }
  }
`
export type OrderJobCampaignMutationFn = Apollo.MutationFunction<
  OrderJobCampaignMutation,
  OrderJobCampaignMutationVariables
>

/**
 * __useOrderJobCampaignMutation__
 *
 * To run a mutation, you first call `useOrderJobCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderJobCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderJobCampaignMutation, { data, loading, error }] = useOrderJobCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderJobCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderJobCampaignMutation, OrderJobCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<OrderJobCampaignMutation, OrderJobCampaignMutationVariables>(
    OrderJobCampaignDocument,
    options,
  )
}
export type OrderJobCampaignMutationHookResult = ReturnType<typeof useOrderJobCampaignMutation>
export type OrderJobCampaignMutationResult = Apollo.MutationResult<OrderJobCampaignMutation>
export type OrderJobCampaignMutationOptions = Apollo.BaseMutationOptions<
  OrderJobCampaignMutation,
  OrderJobCampaignMutationVariables
>
export const UpdateJobInviteDocument = gql`
  mutation UpdateJobInvite($input: UpdateJobInviteInput!) {
    UpdateJobInvite(input: $input) {
      id
    }
  }
`
export type UpdateJobInviteMutationFn = Apollo.MutationFunction<
  UpdateJobInviteMutation,
  UpdateJobInviteMutationVariables
>

/**
 * __useUpdateJobInviteMutation__
 *
 * To run a mutation, you first call `useUpdateJobInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobInviteMutation, { data, loading, error }] = useUpdateJobInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJobInviteMutation, UpdateJobInviteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateJobInviteMutation, UpdateJobInviteMutationVariables>(UpdateJobInviteDocument, options)
}
export type UpdateJobInviteMutationHookResult = ReturnType<typeof useUpdateJobInviteMutation>
export type UpdateJobInviteMutationResult = Apollo.MutationResult<UpdateJobInviteMutation>
export type UpdateJobInviteMutationOptions = Apollo.BaseMutationOptions<
  UpdateJobInviteMutation,
  UpdateJobInviteMutationVariables
>
export const DeleteJobListingDocument = gql`
  mutation DeleteJobListing($input: DeleteJobListingInput!) {
    DeleteJobListing(input: $input) {
      id
    }
  }
`
export type DeleteJobListingMutationFn = Apollo.MutationFunction<
  DeleteJobListingMutation,
  DeleteJobListingMutationVariables
>

/**
 * __useDeleteJobListingMutation__
 *
 * To run a mutation, you first call `useDeleteJobListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobListingMutation, { data, loading, error }] = useDeleteJobListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobListingMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJobListingMutation, DeleteJobListingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteJobListingMutation, DeleteJobListingMutationVariables>(
    DeleteJobListingDocument,
    options,
  )
}
export type DeleteJobListingMutationHookResult = ReturnType<typeof useDeleteJobListingMutation>
export type DeleteJobListingMutationResult = Apollo.MutationResult<DeleteJobListingMutation>
export type DeleteJobListingMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobListingMutation,
  DeleteJobListingMutationVariables
>
export const UpsertJobListingJobPipelineDocument = gql`
  mutation UpsertJobListingJobPipeline($input: [UpsertJobListingJobPipelineInput!]!) {
    UpsertJobListingJobPipeline(input: $input) {
      ...JobListingJobPipelineFragment
    }
  }
  ${JobListingJobPipelineFragmentFragmentDoc}
`
export type UpsertJobListingJobPipelineMutationFn = Apollo.MutationFunction<
  UpsertJobListingJobPipelineMutation,
  UpsertJobListingJobPipelineMutationVariables
>

/**
 * __useUpsertJobListingJobPipelineMutation__
 *
 * To run a mutation, you first call `useUpsertJobListingJobPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobListingJobPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobListingJobPipelineMutation, { data, loading, error }] = useUpsertJobListingJobPipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertJobListingJobPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertJobListingJobPipelineMutation,
    UpsertJobListingJobPipelineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertJobListingJobPipelineMutation, UpsertJobListingJobPipelineMutationVariables>(
    UpsertJobListingJobPipelineDocument,
    options,
  )
}
export type UpsertJobListingJobPipelineMutationHookResult = ReturnType<typeof useUpsertJobListingJobPipelineMutation>
export type UpsertJobListingJobPipelineMutationResult = Apollo.MutationResult<UpsertJobListingJobPipelineMutation>
export type UpsertJobListingJobPipelineMutationOptions = Apollo.BaseMutationOptions<
  UpsertJobListingJobPipelineMutation,
  UpsertJobListingJobPipelineMutationVariables
>
export const UpsertJobListingDocument = gql`
  mutation UpsertJobListing($input: UpsertJobListingInput!) {
    UpsertJobListing(input: $input) {
      ...JobListingFragment
      Address {
        ...AddressFragment
      }
      JobListingScreeningQuestion {
        ...JobListingScreeningQuestionFragment
        ScreeningQuestion {
          ...ScreeningQuestionFragment
        }
      }
    }
  }
  ${JobListingFragmentFragmentDoc}
  ${AddressFragmentFragmentDoc}
  ${JobListingScreeningQuestionFragmentFragmentDoc}
  ${ScreeningQuestionFragmentFragmentDoc}
`
export type UpsertJobListingMutationFn = Apollo.MutationFunction<
  UpsertJobListingMutation,
  UpsertJobListingMutationVariables
>

/**
 * __useUpsertJobListingMutation__
 *
 * To run a mutation, you first call `useUpsertJobListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobListingMutation, { data, loading, error }] = useUpsertJobListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertJobListingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertJobListingMutation, UpsertJobListingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertJobListingMutation, UpsertJobListingMutationVariables>(
    UpsertJobListingDocument,
    options,
  )
}
export type UpsertJobListingMutationHookResult = ReturnType<typeof useUpsertJobListingMutation>
export type UpsertJobListingMutationResult = Apollo.MutationResult<UpsertJobListingMutation>
export type UpsertJobListingMutationOptions = Apollo.BaseMutationOptions<
  UpsertJobListingMutation,
  UpsertJobListingMutationVariables
>
export const DeleteJobPipelineDocument = gql`
  mutation DeleteJobPipeline($input: DeleteJobPipelineInput!) {
    DeleteJobPipeline(input: $input) {
      id
    }
  }
`
export type DeleteJobPipelineMutationFn = Apollo.MutationFunction<
  DeleteJobPipelineMutation,
  DeleteJobPipelineMutationVariables
>

/**
 * __useDeleteJobPipelineMutation__
 *
 * To run a mutation, you first call `useDeleteJobPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobPipelineMutation, { data, loading, error }] = useDeleteJobPipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJobPipelineMutation, DeleteJobPipelineMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteJobPipelineMutation, DeleteJobPipelineMutationVariables>(
    DeleteJobPipelineDocument,
    options,
  )
}
export type DeleteJobPipelineMutationHookResult = ReturnType<typeof useDeleteJobPipelineMutation>
export type DeleteJobPipelineMutationResult = Apollo.MutationResult<DeleteJobPipelineMutation>
export type DeleteJobPipelineMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobPipelineMutation,
  DeleteJobPipelineMutationVariables
>
export const UpsertJobPipelineDocument = gql`
  mutation UpsertJobPipeline($input: UpsertJobPipelineInput!) {
    UpsertJobPipeline(input: $input) {
      ...JobPipelineFragment
      TemplatePipeline {
        ...TemplatePipelineFragment
        TemplateMilestones {
          ...TemplateMilestoneFragment
          TemplateStage {
            ...TemplateStageFragment
          }
        }
      }
    }
  }
  ${JobPipelineFragmentFragmentDoc}
  ${TemplatePipelineFragmentFragmentDoc}
  ${TemplateMilestoneFragmentFragmentDoc}
  ${TemplateStageFragmentFragmentDoc}
`
export type UpsertJobPipelineMutationFn = Apollo.MutationFunction<
  UpsertJobPipelineMutation,
  UpsertJobPipelineMutationVariables
>

/**
 * __useUpsertJobPipelineMutation__
 *
 * To run a mutation, you first call `useUpsertJobPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobPipelineMutation, { data, loading, error }] = useUpsertJobPipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertJobPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertJobPipelineMutation, UpsertJobPipelineMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertJobPipelineMutation, UpsertJobPipelineMutationVariables>(
    UpsertJobPipelineDocument,
    options,
  )
}
export type UpsertJobPipelineMutationHookResult = ReturnType<typeof useUpsertJobPipelineMutation>
export type UpsertJobPipelineMutationResult = Apollo.MutationResult<UpsertJobPipelineMutation>
export type UpsertJobPipelineMutationOptions = Apollo.BaseMutationOptions<
  UpsertJobPipelineMutation,
  UpsertJobPipelineMutationVariables
>
export const CreateApplicantNotesDocument = gql`
  mutation CreateApplicantNotes($input: CreateApplicantNotesInput!) {
    CreateApplicantNotes(input: $input) {
      id
    }
  }
`
export type CreateApplicantNotesMutationFn = Apollo.MutationFunction<
  CreateApplicantNotesMutation,
  CreateApplicantNotesMutationVariables
>

/**
 * __useCreateApplicantNotesMutation__
 *
 * To run a mutation, you first call `useCreateApplicantNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicantNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicantNotesMutation, { data, loading, error }] = useCreateApplicantNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicantNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApplicantNotesMutation, CreateApplicantNotesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateApplicantNotesMutation, CreateApplicantNotesMutationVariables>(
    CreateApplicantNotesDocument,
    options,
  )
}
export type CreateApplicantNotesMutationHookResult = ReturnType<typeof useCreateApplicantNotesMutation>
export type CreateApplicantNotesMutationResult = Apollo.MutationResult<CreateApplicantNotesMutation>
export type CreateApplicantNotesMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicantNotesMutation,
  CreateApplicantNotesMutationVariables
>
export const CreateBookmarkedJobDocument = gql`
  mutation CreateBookmarkedJob($input: CreateBookmarkedJobInput!) {
    CreateBookmarkedJob(input: $input) {
      id
      jobListingId
    }
  }
`
export type CreateBookmarkedJobMutationFn = Apollo.MutationFunction<
  CreateBookmarkedJobMutation,
  CreateBookmarkedJobMutationVariables
>

/**
 * __useCreateBookmarkedJobMutation__
 *
 * To run a mutation, you first call `useCreateBookmarkedJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookmarkedJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookmarkedJobMutation, { data, loading, error }] = useCreateBookmarkedJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookmarkedJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBookmarkedJobMutation, CreateBookmarkedJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBookmarkedJobMutation, CreateBookmarkedJobMutationVariables>(
    CreateBookmarkedJobDocument,
    options,
  )
}
export type CreateBookmarkedJobMutationHookResult = ReturnType<typeof useCreateBookmarkedJobMutation>
export type CreateBookmarkedJobMutationResult = Apollo.MutationResult<CreateBookmarkedJobMutation>
export type CreateBookmarkedJobMutationOptions = Apollo.BaseMutationOptions<
  CreateBookmarkedJobMutation,
  CreateBookmarkedJobMutationVariables
>
export const CreateJobSeekerAccountDocument = gql`
  mutation CreateJobSeekerAccount($input: CreateJobSeekerAccountInput!) {
    CreateJobSeekerAccount(input: $input) {
      id
    }
  }
`
export type CreateJobSeekerAccountMutationFn = Apollo.MutationFunction<
  CreateJobSeekerAccountMutation,
  CreateJobSeekerAccountMutationVariables
>

/**
 * __useCreateJobSeekerAccountMutation__
 *
 * To run a mutation, you first call `useCreateJobSeekerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobSeekerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobSeekerAccountMutation, { data, loading, error }] = useCreateJobSeekerAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobSeekerAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateJobSeekerAccountMutation, CreateJobSeekerAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateJobSeekerAccountMutation, CreateJobSeekerAccountMutationVariables>(
    CreateJobSeekerAccountDocument,
    options,
  )
}
export type CreateJobSeekerAccountMutationHookResult = ReturnType<typeof useCreateJobSeekerAccountMutation>
export type CreateJobSeekerAccountMutationResult = Apollo.MutationResult<CreateJobSeekerAccountMutation>
export type CreateJobSeekerAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateJobSeekerAccountMutation,
  CreateJobSeekerAccountMutationVariables
>
export const DeleteApplicantNotesDocument = gql`
  mutation DeleteApplicantNotes($input: DeleteApplicantNotesInput!) {
    DeleteApplicantNotes(input: $input) {
      id
    }
  }
`
export type DeleteApplicantNotesMutationFn = Apollo.MutationFunction<
  DeleteApplicantNotesMutation,
  DeleteApplicantNotesMutationVariables
>

/**
 * __useDeleteApplicantNotesMutation__
 *
 * To run a mutation, you first call `useDeleteApplicantNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicantNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicantNotesMutation, { data, loading, error }] = useDeleteApplicantNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApplicantNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApplicantNotesMutation, DeleteApplicantNotesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteApplicantNotesMutation, DeleteApplicantNotesMutationVariables>(
    DeleteApplicantNotesDocument,
    options,
  )
}
export type DeleteApplicantNotesMutationHookResult = ReturnType<typeof useDeleteApplicantNotesMutation>
export type DeleteApplicantNotesMutationResult = Apollo.MutationResult<DeleteApplicantNotesMutation>
export type DeleteApplicantNotesMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicantNotesMutation,
  DeleteApplicantNotesMutationVariables
>
export const DeleteExternalJobApplicationDocument = gql`
  mutation DeleteExternalJobApplication($input: DeleteExternalApplicationInput!) {
    DeleteExternalJobApplication(input: $input) {
      message
    }
  }
`
export type DeleteExternalJobApplicationMutationFn = Apollo.MutationFunction<
  DeleteExternalJobApplicationMutation,
  DeleteExternalJobApplicationMutationVariables
>

/**
 * __useDeleteExternalJobApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteExternalJobApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExternalJobApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExternalJobApplicationMutation, { data, loading, error }] = useDeleteExternalJobApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExternalJobApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExternalJobApplicationMutation,
    DeleteExternalJobApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteExternalJobApplicationMutation, DeleteExternalJobApplicationMutationVariables>(
    DeleteExternalJobApplicationDocument,
    options,
  )
}
export type DeleteExternalJobApplicationMutationHookResult = ReturnType<typeof useDeleteExternalJobApplicationMutation>
export type DeleteExternalJobApplicationMutationResult = Apollo.MutationResult<DeleteExternalJobApplicationMutation>
export type DeleteExternalJobApplicationMutationOptions = Apollo.BaseMutationOptions<
  DeleteExternalJobApplicationMutation,
  DeleteExternalJobApplicationMutationVariables
>
export const ResendEmailVerificationDocument = gql`
  mutation ResendEmailVerification($input: ResendEmailVerificationInput!) {
    ResendEmailVerification(input: $input) {
      EmailVerification {
        activeStatus
        createdAt
        emailAddress
        emailVerified
        id
        isPrimary
        scalisUserId
        updatedAt
      }
    }
  }
`
export type ResendEmailVerificationMutationFn = Apollo.MutationFunction<
  ResendEmailVerificationMutation,
  ResendEmailVerificationMutationVariables
>

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>(
    ResendEmailVerificationDocument,
    options,
  )
}
export type ResendEmailVerificationMutationHookResult = ReturnType<typeof useResendEmailVerificationMutation>
export type ResendEmailVerificationMutationResult = Apollo.MutationResult<ResendEmailVerificationMutation>
export type ResendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailVerificationMutation,
  ResendEmailVerificationMutationVariables
>
export const SendJobListingDocument = gql`
  mutation SendJobListing($input: SendJobListingInput!) {
    SendJobListing(input: $input) {
      publicListingId
    }
  }
`
export type SendJobListingMutationFn = Apollo.MutationFunction<SendJobListingMutation, SendJobListingMutationVariables>

/**
 * __useSendJobListingMutation__
 *
 * To run a mutation, you first call `useSendJobListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendJobListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendJobListingMutation, { data, loading, error }] = useSendJobListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendJobListingMutation(
  baseOptions?: Apollo.MutationHookOptions<SendJobListingMutation, SendJobListingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendJobListingMutation, SendJobListingMutationVariables>(SendJobListingDocument, options)
}
export type SendJobListingMutationHookResult = ReturnType<typeof useSendJobListingMutation>
export type SendJobListingMutationResult = Apollo.MutationResult<SendJobListingMutation>
export type SendJobListingMutationOptions = Apollo.BaseMutationOptions<
  SendJobListingMutation,
  SendJobListingMutationVariables
>
export const ShareJobListingDocument = gql`
  mutation ShareJobListing($input: SendJobListingInput!) {
    SendJobListing(input: $input) {
      email
      recipients
      publicListingId
      message
    }
  }
`
export type ShareJobListingMutationFn = Apollo.MutationFunction<
  ShareJobListingMutation,
  ShareJobListingMutationVariables
>

/**
 * __useShareJobListingMutation__
 *
 * To run a mutation, you first call `useShareJobListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareJobListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareJobListingMutation, { data, loading, error }] = useShareJobListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareJobListingMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareJobListingMutation, ShareJobListingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ShareJobListingMutation, ShareJobListingMutationVariables>(ShareJobListingDocument, options)
}
export type ShareJobListingMutationHookResult = ReturnType<typeof useShareJobListingMutation>
export type ShareJobListingMutationResult = Apollo.MutationResult<ShareJobListingMutation>
export type ShareJobListingMutationOptions = Apollo.BaseMutationOptions<
  ShareJobListingMutation,
  ShareJobListingMutationVariables
>
export const UpsertExternalJobApplicationDocument = gql`
  mutation UpsertExternalJobApplication($input: ExternalApplicationInput!) {
    UpsertExternalJobApplication(input: $input) {
      id
    }
  }
`
export type UpsertExternalJobApplicationMutationFn = Apollo.MutationFunction<
  UpsertExternalJobApplicationMutation,
  UpsertExternalJobApplicationMutationVariables
>

/**
 * __useUpsertExternalJobApplicationMutation__
 *
 * To run a mutation, you first call `useUpsertExternalJobApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExternalJobApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExternalJobApplicationMutation, { data, loading, error }] = useUpsertExternalJobApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertExternalJobApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertExternalJobApplicationMutation,
    UpsertExternalJobApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertExternalJobApplicationMutation, UpsertExternalJobApplicationMutationVariables>(
    UpsertExternalJobApplicationDocument,
    options,
  )
}
export type UpsertExternalJobApplicationMutationHookResult = ReturnType<typeof useUpsertExternalJobApplicationMutation>
export type UpsertExternalJobApplicationMutationResult = Apollo.MutationResult<UpsertExternalJobApplicationMutation>
export type UpsertExternalJobApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpsertExternalJobApplicationMutation,
  UpsertExternalJobApplicationMutationVariables
>
export const VerifyEmailCodeDocument = gql`
  mutation VerifyEmailCode($input: VerifyEmailCodeInput!) {
    VerifyEmailCode(input: $input) {
      EmailVerification {
        activeStatus
        createdAt
        emailVerified
        emailAddress
        id
        isPrimary
        scalisUserId
        updatedAt
      }
    }
  }
`
export type VerifyEmailCodeMutationFn = Apollo.MutationFunction<
  VerifyEmailCodeMutation,
  VerifyEmailCodeMutationVariables
>

/**
 * __useVerifyEmailCodeMutation__
 *
 * To run a mutation, you first call `useVerifyEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailCodeMutation, { data, loading, error }] = useVerifyEmailCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyEmailCodeMutation, VerifyEmailCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyEmailCodeMutation, VerifyEmailCodeMutationVariables>(VerifyEmailCodeDocument, options)
}
export type VerifyEmailCodeMutationHookResult = ReturnType<typeof useVerifyEmailCodeMutation>
export type VerifyEmailCodeMutationResult = Apollo.MutationResult<VerifyEmailCodeMutation>
export type VerifyEmailCodeMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailCodeMutation,
  VerifyEmailCodeMutationVariables
>
export const WithdrawApplicationDocument = gql`
  mutation WithdrawApplication($input: DeleteCandidateApplicationInput!) {
    DeleteCandidateApplication(input: $input) {
      id
    }
  }
`
export type WithdrawApplicationMutationFn = Apollo.MutationFunction<
  WithdrawApplicationMutation,
  WithdrawApplicationMutationVariables
>

/**
 * __useWithdrawApplicationMutation__
 *
 * To run a mutation, you first call `useWithdrawApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawApplicationMutation, { data, loading, error }] = useWithdrawApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<WithdrawApplicationMutation, WithdrawApplicationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<WithdrawApplicationMutation, WithdrawApplicationMutationVariables>(
    WithdrawApplicationDocument,
    options,
  )
}
export type WithdrawApplicationMutationHookResult = ReturnType<typeof useWithdrawApplicationMutation>
export type WithdrawApplicationMutationResult = Apollo.MutationResult<WithdrawApplicationMutation>
export type WithdrawApplicationMutationOptions = Apollo.BaseMutationOptions<
  WithdrawApplicationMutation,
  WithdrawApplicationMutationVariables
>
export const CreateChatV2Document = gql`
  mutation CreateChatV2($input: CreateChatV2Input!) {
    CreateChatV2(input: $input) {
      coUserId
      scUserId
      chatId
    }
  }
`
export type CreateChatV2MutationFn = Apollo.MutationFunction<CreateChatV2Mutation, CreateChatV2MutationVariables>

/**
 * __useCreateChatV2Mutation__
 *
 * To run a mutation, you first call `useCreateChatV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatV2Mutation, { data, loading, error }] = useCreateChatV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChatV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChatV2Mutation, CreateChatV2MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateChatV2Mutation, CreateChatV2MutationVariables>(CreateChatV2Document, options)
}
export type CreateChatV2MutationHookResult = ReturnType<typeof useCreateChatV2Mutation>
export type CreateChatV2MutationResult = Apollo.MutationResult<CreateChatV2Mutation>
export type CreateChatV2MutationOptions = Apollo.BaseMutationOptions<
  CreateChatV2Mutation,
  CreateChatV2MutationVariables
>
export const CancelScheduleReportDocument = gql`
  mutation CancelScheduleReport($input: CancelScheduledReportInput!) {
    CancelScheduledReport(input: $input) {
      id
    }
  }
`
export type CancelScheduleReportMutationFn = Apollo.MutationFunction<
  CancelScheduleReportMutation,
  CancelScheduleReportMutationVariables
>

/**
 * __useCancelScheduleReportMutation__
 *
 * To run a mutation, you first call `useCancelScheduleReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduleReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduleReportMutation, { data, loading, error }] = useCancelScheduleReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelScheduleReportMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelScheduleReportMutation, CancelScheduleReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelScheduleReportMutation, CancelScheduleReportMutationVariables>(
    CancelScheduleReportDocument,
    options,
  )
}
export type CancelScheduleReportMutationHookResult = ReturnType<typeof useCancelScheduleReportMutation>
export type CancelScheduleReportMutationResult = Apollo.MutationResult<CancelScheduleReportMutation>
export type CancelScheduleReportMutationOptions = Apollo.BaseMutationOptions<
  CancelScheduleReportMutation,
  CancelScheduleReportMutationVariables
>
export const CreateFavoriteReportDocument = gql`
  mutation CreateFavoriteReport($input: FavoriteReportInput!) {
    CreateFavoriteReport(input: $input) {
      id
      coreReportId
      CoreReport {
        ...CoreReport
      }
    }
  }
  ${CoreReportFragmentDoc}
`
export type CreateFavoriteReportMutationFn = Apollo.MutationFunction<
  CreateFavoriteReportMutation,
  CreateFavoriteReportMutationVariables
>

/**
 * __useCreateFavoriteReportMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteReportMutation, { data, loading, error }] = useCreateFavoriteReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFavoriteReportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFavoriteReportMutation, CreateFavoriteReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateFavoriteReportMutation, CreateFavoriteReportMutationVariables>(
    CreateFavoriteReportDocument,
    options,
  )
}
export type CreateFavoriteReportMutationHookResult = ReturnType<typeof useCreateFavoriteReportMutation>
export type CreateFavoriteReportMutationResult = Apollo.MutationResult<CreateFavoriteReportMutation>
export type CreateFavoriteReportMutationOptions = Apollo.BaseMutationOptions<
  CreateFavoriteReportMutation,
  CreateFavoriteReportMutationVariables
>
export const CreateScheduleReportDocument = gql`
  mutation CreateScheduleReport($input: CreateScheduledReportInput!) {
    ScheduleReport(input: $input) {
      CoreReport {
        ...CoreReport
        ScheduledReport {
          ...ScheduledReport
        }
      }
    }
  }
  ${CoreReportFragmentDoc}
  ${ScheduledReportFragmentDoc}
`
export type CreateScheduleReportMutationFn = Apollo.MutationFunction<
  CreateScheduleReportMutation,
  CreateScheduleReportMutationVariables
>

/**
 * __useCreateScheduleReportMutation__
 *
 * To run a mutation, you first call `useCreateScheduleReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleReportMutation, { data, loading, error }] = useCreateScheduleReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduleReportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateScheduleReportMutation, CreateScheduleReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateScheduleReportMutation, CreateScheduleReportMutationVariables>(
    CreateScheduleReportDocument,
    options,
  )
}
export type CreateScheduleReportMutationHookResult = ReturnType<typeof useCreateScheduleReportMutation>
export type CreateScheduleReportMutationResult = Apollo.MutationResult<CreateScheduleReportMutation>
export type CreateScheduleReportMutationOptions = Apollo.BaseMutationOptions<
  CreateScheduleReportMutation,
  CreateScheduleReportMutationVariables
>
export const CreateSharedReportDocument = gql`
  mutation CreateSharedReport($input: SharedReportInput!) {
    CreateSharedReport(input: $input) {
      ...SharedCoreReport
      createdAt
    }
  }
  ${SharedCoreReportFragmentDoc}
`
export type CreateSharedReportMutationFn = Apollo.MutationFunction<
  CreateSharedReportMutation,
  CreateSharedReportMutationVariables
>

/**
 * __useCreateSharedReportMutation__
 *
 * To run a mutation, you first call `useCreateSharedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedReportMutation, { data, loading, error }] = useCreateSharedReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSharedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSharedReportMutation, CreateSharedReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSharedReportMutation, CreateSharedReportMutationVariables>(
    CreateSharedReportDocument,
    options,
  )
}
export type CreateSharedReportMutationHookResult = ReturnType<typeof useCreateSharedReportMutation>
export type CreateSharedReportMutationResult = Apollo.MutationResult<CreateSharedReportMutation>
export type CreateSharedReportMutationOptions = Apollo.BaseMutationOptions<
  CreateSharedReportMutation,
  CreateSharedReportMutationVariables
>
export const DeleteFavoriteReportDocument = gql`
  mutation DeleteFavoriteReport($input: FavoriteReportInput!) {
    DeleteFavoriteReport(input: $input) {
      id
      coreReportId
      CoreReport {
        ...CoreReport
      }
    }
  }
  ${CoreReportFragmentDoc}
`
export type DeleteFavoriteReportMutationFn = Apollo.MutationFunction<
  DeleteFavoriteReportMutation,
  DeleteFavoriteReportMutationVariables
>

/**
 * __useDeleteFavoriteReportMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteReportMutation, { data, loading, error }] = useDeleteFavoriteReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFavoriteReportMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFavoriteReportMutation, DeleteFavoriteReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteFavoriteReportMutation, DeleteFavoriteReportMutationVariables>(
    DeleteFavoriteReportDocument,
    options,
  )
}
export type DeleteFavoriteReportMutationHookResult = ReturnType<typeof useDeleteFavoriteReportMutation>
export type DeleteFavoriteReportMutationResult = Apollo.MutationResult<DeleteFavoriteReportMutation>
export type DeleteFavoriteReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteFavoriteReportMutation,
  DeleteFavoriteReportMutationVariables
>
export const DeleteSharedReportDocument = gql`
  mutation DeleteSharedReport($input: SharedReportInput!) {
    DeleteSharedReport(input: $input)
  }
`
export type DeleteSharedReportMutationFn = Apollo.MutationFunction<
  DeleteSharedReportMutation,
  DeleteSharedReportMutationVariables
>

/**
 * __useDeleteSharedReportMutation__
 *
 * To run a mutation, you first call `useDeleteSharedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharedReportMutation, { data, loading, error }] = useDeleteSharedReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSharedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSharedReportMutation, DeleteSharedReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSharedReportMutation, DeleteSharedReportMutationVariables>(
    DeleteSharedReportDocument,
    options,
  )
}
export type DeleteSharedReportMutationHookResult = ReturnType<typeof useDeleteSharedReportMutation>
export type DeleteSharedReportMutationResult = Apollo.MutationResult<DeleteSharedReportMutation>
export type DeleteSharedReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteSharedReportMutation,
  DeleteSharedReportMutationVariables
>
export const UpdateScheduleReportDocument = gql`
  mutation UpdateScheduleReport($input: UpdateScheduledReportInput!) {
    UpdateScheduledReport(input: $input) {
      CoreReport {
        ...CoreReport
        ScheduledReport {
          ...ScheduledReport
        }
      }
    }
  }
  ${CoreReportFragmentDoc}
  ${ScheduledReportFragmentDoc}
`
export type UpdateScheduleReportMutationFn = Apollo.MutationFunction<
  UpdateScheduleReportMutation,
  UpdateScheduleReportMutationVariables
>

/**
 * __useUpdateScheduleReportMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleReportMutation, { data, loading, error }] = useUpdateScheduleReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScheduleReportMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateScheduleReportMutation, UpdateScheduleReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateScheduleReportMutation, UpdateScheduleReportMutationVariables>(
    UpdateScheduleReportDocument,
    options,
  )
}
export type UpdateScheduleReportMutationHookResult = ReturnType<typeof useUpdateScheduleReportMutation>
export type UpdateScheduleReportMutationResult = Apollo.MutationResult<UpdateScheduleReportMutation>
export type UpdateScheduleReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduleReportMutation,
  UpdateScheduleReportMutationVariables
>
export const DeleteRequisitionDocument = gql`
  mutation DeleteRequisition($input: DeleteRequisitionInput!) {
    DeleteRequisition(input: $input) {
      id
    }
  }
`
export type DeleteRequisitionMutationFn = Apollo.MutationFunction<
  DeleteRequisitionMutation,
  DeleteRequisitionMutationVariables
>

/**
 * __useDeleteRequisitionMutation__
 *
 * To run a mutation, you first call `useDeleteRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequisitionMutation, { data, loading, error }] = useDeleteRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequisitionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRequisitionMutation, DeleteRequisitionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteRequisitionMutation, DeleteRequisitionMutationVariables>(
    DeleteRequisitionDocument,
    options,
  )
}
export type DeleteRequisitionMutationHookResult = ReturnType<typeof useDeleteRequisitionMutation>
export type DeleteRequisitionMutationResult = Apollo.MutationResult<DeleteRequisitionMutation>
export type DeleteRequisitionMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequisitionMutation,
  DeleteRequisitionMutationVariables
>
export const DeleteRequisitionJobDescriptionDocument = gql`
  mutation DeleteRequisitionJobDescription($input: DeleteRequisitionJobDescriptionInput!) {
    DeleteRequisitionJobDescription(input: $input) {
      id
    }
  }
`
export type DeleteRequisitionJobDescriptionMutationFn = Apollo.MutationFunction<
  DeleteRequisitionJobDescriptionMutation,
  DeleteRequisitionJobDescriptionMutationVariables
>

/**
 * __useDeleteRequisitionJobDescriptionMutation__
 *
 * To run a mutation, you first call `useDeleteRequisitionJobDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequisitionJobDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequisitionJobDescriptionMutation, { data, loading, error }] = useDeleteRequisitionJobDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequisitionJobDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRequisitionJobDescriptionMutation,
    DeleteRequisitionJobDescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteRequisitionJobDescriptionMutation, DeleteRequisitionJobDescriptionMutationVariables>(
    DeleteRequisitionJobDescriptionDocument,
    options,
  )
}
export type DeleteRequisitionJobDescriptionMutationHookResult = ReturnType<
  typeof useDeleteRequisitionJobDescriptionMutation
>
export type DeleteRequisitionJobDescriptionMutationResult =
  Apollo.MutationResult<DeleteRequisitionJobDescriptionMutation>
export type DeleteRequisitionJobDescriptionMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequisitionJobDescriptionMutation,
  DeleteRequisitionJobDescriptionMutationVariables
>
export const DeleteTemplateJobDescriptionDocument = gql`
  mutation DeleteTemplateJobDescription($input: DeleteTemplateJobDescriptionInput!) {
    DeleteTemplateJobDescription(input: $input) {
      id
    }
  }
`
export type DeleteTemplateJobDescriptionMutationFn = Apollo.MutationFunction<
  DeleteTemplateJobDescriptionMutation,
  DeleteTemplateJobDescriptionMutationVariables
>

/**
 * __useDeleteTemplateJobDescriptionMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateJobDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateJobDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateJobDescriptionMutation, { data, loading, error }] = useDeleteTemplateJobDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateJobDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTemplateJobDescriptionMutation,
    DeleteTemplateJobDescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTemplateJobDescriptionMutation, DeleteTemplateJobDescriptionMutationVariables>(
    DeleteTemplateJobDescriptionDocument,
    options,
  )
}
export type DeleteTemplateJobDescriptionMutationHookResult = ReturnType<typeof useDeleteTemplateJobDescriptionMutation>
export type DeleteTemplateJobDescriptionMutationResult = Apollo.MutationResult<DeleteTemplateJobDescriptionMutation>
export type DeleteTemplateJobDescriptionMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateJobDescriptionMutation,
  DeleteTemplateJobDescriptionMutationVariables
>
export const UpsertRequisitionJobDescriptionDocument = gql`
  mutation UpsertRequisitionJobDescription($input: UpsertRequisitionJobDescriptionInput!) {
    UpsertRequisitionJobDescription(input: $input) {
      ...RequisitionJobDescriptionFragment
    }
  }
  ${RequisitionJobDescriptionFragmentFragmentDoc}
`
export type UpsertRequisitionJobDescriptionMutationFn = Apollo.MutationFunction<
  UpsertRequisitionJobDescriptionMutation,
  UpsertRequisitionJobDescriptionMutationVariables
>

/**
 * __useUpsertRequisitionJobDescriptionMutation__
 *
 * To run a mutation, you first call `useUpsertRequisitionJobDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRequisitionJobDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRequisitionJobDescriptionMutation, { data, loading, error }] = useUpsertRequisitionJobDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRequisitionJobDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertRequisitionJobDescriptionMutation,
    UpsertRequisitionJobDescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertRequisitionJobDescriptionMutation, UpsertRequisitionJobDescriptionMutationVariables>(
    UpsertRequisitionJobDescriptionDocument,
    options,
  )
}
export type UpsertRequisitionJobDescriptionMutationHookResult = ReturnType<
  typeof useUpsertRequisitionJobDescriptionMutation
>
export type UpsertRequisitionJobDescriptionMutationResult =
  Apollo.MutationResult<UpsertRequisitionJobDescriptionMutation>
export type UpsertRequisitionJobDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpsertRequisitionJobDescriptionMutation,
  UpsertRequisitionJobDescriptionMutationVariables
>
export const UpsertTemplateJobDescriptionDocument = gql`
  mutation UpsertTemplateJobDescription($input: UpsertTemplateJobDescriptionInput!) {
    UpsertTemplateJobDescription(input: $input) {
      id
      name
      description
      companyDepartmentId
    }
  }
`
export type UpsertTemplateJobDescriptionMutationFn = Apollo.MutationFunction<
  UpsertTemplateJobDescriptionMutation,
  UpsertTemplateJobDescriptionMutationVariables
>

/**
 * __useUpsertTemplateJobDescriptionMutation__
 *
 * To run a mutation, you first call `useUpsertTemplateJobDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTemplateJobDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTemplateJobDescriptionMutation, { data, loading, error }] = useUpsertTemplateJobDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTemplateJobDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertTemplateJobDescriptionMutation,
    UpsertTemplateJobDescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertTemplateJobDescriptionMutation, UpsertTemplateJobDescriptionMutationVariables>(
    UpsertTemplateJobDescriptionDocument,
    options,
  )
}
export type UpsertTemplateJobDescriptionMutationHookResult = ReturnType<typeof useUpsertTemplateJobDescriptionMutation>
export type UpsertTemplateJobDescriptionMutationResult = Apollo.MutationResult<UpsertTemplateJobDescriptionMutation>
export type UpsertTemplateJobDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpsertTemplateJobDescriptionMutation,
  UpsertTemplateJobDescriptionMutationVariables
>
export const DeleteJobOpeningDocument = gql`
  mutation DeleteJobOpening($input: DeleteJobOpeningInput!) {
    DeleteJobOpening(input: $input) {
      id
    }
  }
`
export type DeleteJobOpeningMutationFn = Apollo.MutationFunction<
  DeleteJobOpeningMutation,
  DeleteJobOpeningMutationVariables
>

/**
 * __useDeleteJobOpeningMutation__
 *
 * To run a mutation, you first call `useDeleteJobOpeningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobOpeningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobOpeningMutation, { data, loading, error }] = useDeleteJobOpeningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobOpeningMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJobOpeningMutation, DeleteJobOpeningMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteJobOpeningMutation, DeleteJobOpeningMutationVariables>(
    DeleteJobOpeningDocument,
    options,
  )
}
export type DeleteJobOpeningMutationHookResult = ReturnType<typeof useDeleteJobOpeningMutation>
export type DeleteJobOpeningMutationResult = Apollo.MutationResult<DeleteJobOpeningMutation>
export type DeleteJobOpeningMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobOpeningMutation,
  DeleteJobOpeningMutationVariables
>
export const UpsertJobOpeningDocument = gql`
  mutation UpsertJobOpening($input: UpsertJobOpeningInput!) {
    UpsertJobOpening(input: $input) {
      ...JobOpeningFragment
    }
  }
  ${JobOpeningFragmentFragmentDoc}
`
export type UpsertJobOpeningMutationFn = Apollo.MutationFunction<
  UpsertJobOpeningMutation,
  UpsertJobOpeningMutationVariables
>

/**
 * __useUpsertJobOpeningMutation__
 *
 * To run a mutation, you first call `useUpsertJobOpeningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobOpeningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobOpeningMutation, { data, loading, error }] = useUpsertJobOpeningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertJobOpeningMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertJobOpeningMutation, UpsertJobOpeningMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertJobOpeningMutation, UpsertJobOpeningMutationVariables>(
    UpsertJobOpeningDocument,
    options,
  )
}
export type UpsertJobOpeningMutationHookResult = ReturnType<typeof useUpsertJobOpeningMutation>
export type UpsertJobOpeningMutationResult = Apollo.MutationResult<UpsertJobOpeningMutation>
export type UpsertJobOpeningMutationOptions = Apollo.BaseMutationOptions<
  UpsertJobOpeningMutation,
  UpsertJobOpeningMutationVariables
>
export const DeleteRequisitionSkillDocument = gql`
  mutation DeleteRequisitionSkill($input: DeleteRequisitionSkillInput!) {
    DeleteRequisitionSkill(input: $input) {
      id
    }
  }
`
export type DeleteRequisitionSkillMutationFn = Apollo.MutationFunction<
  DeleteRequisitionSkillMutation,
  DeleteRequisitionSkillMutationVariables
>

/**
 * __useDeleteRequisitionSkillMutation__
 *
 * To run a mutation, you first call `useDeleteRequisitionSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequisitionSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequisitionSkillMutation, { data, loading, error }] = useDeleteRequisitionSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequisitionSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRequisitionSkillMutation, DeleteRequisitionSkillMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteRequisitionSkillMutation, DeleteRequisitionSkillMutationVariables>(
    DeleteRequisitionSkillDocument,
    options,
  )
}
export type DeleteRequisitionSkillMutationHookResult = ReturnType<typeof useDeleteRequisitionSkillMutation>
export type DeleteRequisitionSkillMutationResult = Apollo.MutationResult<DeleteRequisitionSkillMutation>
export type DeleteRequisitionSkillMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequisitionSkillMutation,
  DeleteRequisitionSkillMutationVariables
>
export const UpsertRequisitionSkillDocument = gql`
  mutation UpsertRequisitionSkill($input: UpsertRequisitionSkillInput!) {
    UpsertRequisitionSkill(input: $input) {
      ...RequisitionSkillsFragment
    }
  }
  ${RequisitionSkillsFragmentFragmentDoc}
`
export type UpsertRequisitionSkillMutationFn = Apollo.MutationFunction<
  UpsertRequisitionSkillMutation,
  UpsertRequisitionSkillMutationVariables
>

/**
 * __useUpsertRequisitionSkillMutation__
 *
 * To run a mutation, you first call `useUpsertRequisitionSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRequisitionSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRequisitionSkillMutation, { data, loading, error }] = useUpsertRequisitionSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRequisitionSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertRequisitionSkillMutation, UpsertRequisitionSkillMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertRequisitionSkillMutation, UpsertRequisitionSkillMutationVariables>(
    UpsertRequisitionSkillDocument,
    options,
  )
}
export type UpsertRequisitionSkillMutationHookResult = ReturnType<typeof useUpsertRequisitionSkillMutation>
export type UpsertRequisitionSkillMutationResult = Apollo.MutationResult<UpsertRequisitionSkillMutation>
export type UpsertRequisitionSkillMutationOptions = Apollo.BaseMutationOptions<
  UpsertRequisitionSkillMutation,
  UpsertRequisitionSkillMutationVariables
>
export const DeleteJobListingScreeningQuestionDocument = gql`
  mutation DeleteJobListingScreeningQuestion($input: DeleteJobListingScreeningQuestionInput!) {
    DeleteJobListingScreeningQuestion(input: $input) {
      id
    }
  }
`
export type DeleteJobListingScreeningQuestionMutationFn = Apollo.MutationFunction<
  DeleteJobListingScreeningQuestionMutation,
  DeleteJobListingScreeningQuestionMutationVariables
>

/**
 * __useDeleteJobListingScreeningQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteJobListingScreeningQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobListingScreeningQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobListingScreeningQuestionMutation, { data, loading, error }] = useDeleteJobListingScreeningQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobListingScreeningQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteJobListingScreeningQuestionMutation,
    DeleteJobListingScreeningQuestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteJobListingScreeningQuestionMutation,
    DeleteJobListingScreeningQuestionMutationVariables
  >(DeleteJobListingScreeningQuestionDocument, options)
}
export type DeleteJobListingScreeningQuestionMutationHookResult = ReturnType<
  typeof useDeleteJobListingScreeningQuestionMutation
>
export type DeleteJobListingScreeningQuestionMutationResult =
  Apollo.MutationResult<DeleteJobListingScreeningQuestionMutation>
export type DeleteJobListingScreeningQuestionMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobListingScreeningQuestionMutation,
  DeleteJobListingScreeningQuestionMutationVariables
>
export const DeleteScreeningQuestionDocument = gql`
  mutation DeleteScreeningQuestion($input: DeleteScreeningQuestionInput!) {
    DeleteScreeningQuestion(input: $input) {
      id
    }
  }
`
export type DeleteScreeningQuestionMutationFn = Apollo.MutationFunction<
  DeleteScreeningQuestionMutation,
  DeleteScreeningQuestionMutationVariables
>

/**
 * __useDeleteScreeningQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteScreeningQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScreeningQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScreeningQuestionMutation, { data, loading, error }] = useDeleteScreeningQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScreeningQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteScreeningQuestionMutation, DeleteScreeningQuestionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteScreeningQuestionMutation, DeleteScreeningQuestionMutationVariables>(
    DeleteScreeningQuestionDocument,
    options,
  )
}
export type DeleteScreeningQuestionMutationHookResult = ReturnType<typeof useDeleteScreeningQuestionMutation>
export type DeleteScreeningQuestionMutationResult = Apollo.MutationResult<DeleteScreeningQuestionMutation>
export type DeleteScreeningQuestionMutationOptions = Apollo.BaseMutationOptions<
  DeleteScreeningQuestionMutation,
  DeleteScreeningQuestionMutationVariables
>
export const UpsertJobListingScreeningQuestionDocument = gql`
  mutation UpsertJobListingScreeningQuestion($input: UpsertJobListingScreeningQuestionInput!) {
    UpsertJobListingScreeningQuestion(input: $input) {
      ...JobListingScreeningQuestionFragment
      ScreeningQuestion {
        ...ScreeningQuestionFragment
      }
    }
  }
  ${JobListingScreeningQuestionFragmentFragmentDoc}
  ${ScreeningQuestionFragmentFragmentDoc}
`
export type UpsertJobListingScreeningQuestionMutationFn = Apollo.MutationFunction<
  UpsertJobListingScreeningQuestionMutation,
  UpsertJobListingScreeningQuestionMutationVariables
>

/**
 * __useUpsertJobListingScreeningQuestionMutation__
 *
 * To run a mutation, you first call `useUpsertJobListingScreeningQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobListingScreeningQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobListingScreeningQuestionMutation, { data, loading, error }] = useUpsertJobListingScreeningQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertJobListingScreeningQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertJobListingScreeningQuestionMutation,
    UpsertJobListingScreeningQuestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertJobListingScreeningQuestionMutation,
    UpsertJobListingScreeningQuestionMutationVariables
  >(UpsertJobListingScreeningQuestionDocument, options)
}
export type UpsertJobListingScreeningQuestionMutationHookResult = ReturnType<
  typeof useUpsertJobListingScreeningQuestionMutation
>
export type UpsertJobListingScreeningQuestionMutationResult =
  Apollo.MutationResult<UpsertJobListingScreeningQuestionMutation>
export type UpsertJobListingScreeningQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpsertJobListingScreeningQuestionMutation,
  UpsertJobListingScreeningQuestionMutationVariables
>
export const UpsertScreeningQuestionDocument = gql`
  mutation UpsertScreeningQuestion($input: UpsertScreeningQuestionInput!) {
    UpsertScreeningQuestion(input: $input) {
      ...ScreeningQuestionFragment
    }
  }
  ${ScreeningQuestionFragmentFragmentDoc}
`
export type UpsertScreeningQuestionMutationFn = Apollo.MutationFunction<
  UpsertScreeningQuestionMutation,
  UpsertScreeningQuestionMutationVariables
>

/**
 * __useUpsertScreeningQuestionMutation__
 *
 * To run a mutation, you first call `useUpsertScreeningQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertScreeningQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertScreeningQuestionMutation, { data, loading, error }] = useUpsertScreeningQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertScreeningQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertScreeningQuestionMutation, UpsertScreeningQuestionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertScreeningQuestionMutation, UpsertScreeningQuestionMutationVariables>(
    UpsertScreeningQuestionDocument,
    options,
  )
}
export type UpsertScreeningQuestionMutationHookResult = ReturnType<typeof useUpsertScreeningQuestionMutation>
export type UpsertScreeningQuestionMutationResult = Apollo.MutationResult<UpsertScreeningQuestionMutation>
export type UpsertScreeningQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpsertScreeningQuestionMutation,
  UpsertScreeningQuestionMutationVariables
>
export const UpdateRequisitionApplicabilityDocument = gql`
  mutation UpdateRequisitionApplicability($id: Int, $jobListings: [UpsertJobListingInput!]) {
    UpsertRequisition(input: { id: $id, jobListings: $jobListings }) {
      __typename
      id
      jobListings {
        __typename
        id
        acceptingCandidates
      }
    }
  }
`
export type UpdateRequisitionApplicabilityMutationFn = Apollo.MutationFunction<
  UpdateRequisitionApplicabilityMutation,
  UpdateRequisitionApplicabilityMutationVariables
>

/**
 * __useUpdateRequisitionApplicabilityMutation__
 *
 * To run a mutation, you first call `useUpdateRequisitionApplicabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequisitionApplicabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequisitionApplicabilityMutation, { data, loading, error }] = useUpdateRequisitionApplicabilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobListings: // value for 'jobListings'
 *   },
 * });
 */
export function useUpdateRequisitionApplicabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRequisitionApplicabilityMutation,
    UpdateRequisitionApplicabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateRequisitionApplicabilityMutation, UpdateRequisitionApplicabilityMutationVariables>(
    UpdateRequisitionApplicabilityDocument,
    options,
  )
}
export type UpdateRequisitionApplicabilityMutationHookResult = ReturnType<
  typeof useUpdateRequisitionApplicabilityMutation
>
export type UpdateRequisitionApplicabilityMutationResult = Apollo.MutationResult<UpdateRequisitionApplicabilityMutation>
export type UpdateRequisitionApplicabilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateRequisitionApplicabilityMutation,
  UpdateRequisitionApplicabilityMutationVariables
>
export const UpsertRequisitionDocument = gql`
  mutation UpsertRequisition($input: UpsertRequisitionInput!) {
    UpsertRequisition(input: $input) {
      ...RequisitionFragment
      jobOpening {
        ...JobOpeningFragment
      }
      RequisitionJobDescription {
        ...RequisitionJobDescriptionFragment
      }
      RequisitionSkills {
        skill {
          name
          level
          yearsOfExp
        }
        sequenceNumber
      }
      jobListings {
        ...JobListingFragment
        Address {
          ...AddressFragment
        }
        JobListingScreeningQuestion {
          ...JobListingScreeningQuestionFragment
          ScreeningQuestion {
            ...ScreeningQuestionFragment
          }
        }
        deletedAt
      }
      jobPipelines {
        ...JobPipelineFragment
        TemplatePipeline {
          ...TemplatePipelineFragment
          TemplateMilestones {
            ...TemplateMilestoneFragment
            TemplateStage {
              ...TemplateStageFragment
            }
          }
        }
      }
      hiringTeamMember {
        ...HiringTeamMemberFragment
      }
      candidateCollaborator {
        ...CandidateCollaboratorFragment
      }
      stageCollaborator {
        ...StageCollaboratorFragment
      }
    }
  }
  ${RequisitionFragmentFragmentDoc}
  ${JobOpeningFragmentFragmentDoc}
  ${RequisitionJobDescriptionFragmentFragmentDoc}
  ${JobListingFragmentFragmentDoc}
  ${AddressFragmentFragmentDoc}
  ${JobListingScreeningQuestionFragmentFragmentDoc}
  ${ScreeningQuestionFragmentFragmentDoc}
  ${JobPipelineFragmentFragmentDoc}
  ${TemplatePipelineFragmentFragmentDoc}
  ${TemplateMilestoneFragmentFragmentDoc}
  ${TemplateStageFragmentFragmentDoc}
  ${HiringTeamMemberFragmentFragmentDoc}
  ${CandidateCollaboratorFragmentFragmentDoc}
  ${StageCollaboratorFragmentFragmentDoc}
`
export type UpsertRequisitionMutationFn = Apollo.MutationFunction<
  UpsertRequisitionMutation,
  UpsertRequisitionMutationVariables
>

/**
 * __useUpsertRequisitionMutation__
 *
 * To run a mutation, you first call `useUpsertRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRequisitionMutation, { data, loading, error }] = useUpsertRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRequisitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertRequisitionMutation, UpsertRequisitionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertRequisitionMutation, UpsertRequisitionMutationVariables>(
    UpsertRequisitionDocument,
    options,
  )
}
export type UpsertRequisitionMutationHookResult = ReturnType<typeof useUpsertRequisitionMutation>
export type UpsertRequisitionMutationResult = Apollo.MutationResult<UpsertRequisitionMutation>
export type UpsertRequisitionMutationOptions = Apollo.BaseMutationOptions<
  UpsertRequisitionMutation,
  UpsertRequisitionMutationVariables
>
export const CreateScalisUserUploadedDocumentDocument = gql`
  mutation CreateScalisUserUploadedDocument($input: CreateScalisUserUploadedDocumentInput!) {
    CreateScalisUserUploadedDocument(input: $input) {
      id
      documentPath
      documentName
      documentType
    }
  }
`
export type CreateScalisUserUploadedDocumentMutationFn = Apollo.MutationFunction<
  CreateScalisUserUploadedDocumentMutation,
  CreateScalisUserUploadedDocumentMutationVariables
>

/**
 * __useCreateScalisUserUploadedDocumentMutation__
 *
 * To run a mutation, you first call `useCreateScalisUserUploadedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScalisUserUploadedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScalisUserUploadedDocumentMutation, { data, loading, error }] = useCreateScalisUserUploadedDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScalisUserUploadedDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScalisUserUploadedDocumentMutation,
    CreateScalisUserUploadedDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateScalisUserUploadedDocumentMutation,
    CreateScalisUserUploadedDocumentMutationVariables
  >(CreateScalisUserUploadedDocumentDocument, options)
}
export type CreateScalisUserUploadedDocumentMutationHookResult = ReturnType<
  typeof useCreateScalisUserUploadedDocumentMutation
>
export type CreateScalisUserUploadedDocumentMutationResult =
  Apollo.MutationResult<CreateScalisUserUploadedDocumentMutation>
export type CreateScalisUserUploadedDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateScalisUserUploadedDocumentMutation,
  CreateScalisUserUploadedDocumentMutationVariables
>
export const DeleteScalisUserUploadedDocumentDocument = gql`
  mutation DeleteScalisUserUploadedDocument($input: DeleteScalisUserUploadedDocumentInput!) {
    DeleteScalisUserUploadedDocument(input: $input) {
      success
    }
  }
`
export type DeleteScalisUserUploadedDocumentMutationFn = Apollo.MutationFunction<
  DeleteScalisUserUploadedDocumentMutation,
  DeleteScalisUserUploadedDocumentMutationVariables
>

/**
 * __useDeleteScalisUserUploadedDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteScalisUserUploadedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScalisUserUploadedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScalisUserUploadedDocumentMutation, { data, loading, error }] = useDeleteScalisUserUploadedDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScalisUserUploadedDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScalisUserUploadedDocumentMutation,
    DeleteScalisUserUploadedDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteScalisUserUploadedDocumentMutation,
    DeleteScalisUserUploadedDocumentMutationVariables
  >(DeleteScalisUserUploadedDocumentDocument, options)
}
export type DeleteScalisUserUploadedDocumentMutationHookResult = ReturnType<
  typeof useDeleteScalisUserUploadedDocumentMutation
>
export type DeleteScalisUserUploadedDocumentMutationResult =
  Apollo.MutationResult<DeleteScalisUserUploadedDocumentMutation>
export type DeleteScalisUserUploadedDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteScalisUserUploadedDocumentMutation,
  DeleteScalisUserUploadedDocumentMutationVariables
>
export const UpdateSchedulerLinkSettingsDocument = gql`
  mutation UpdateSchedulerLinkSettings($input: UpdateSchedulerLinkSettingsInput!) {
    UpdateSchedulerLinkSettings(input: $input) {
      id
      minimumHour
      rollingDays
      maxInterviewsPerDay
    }
  }
`
export type UpdateSchedulerLinkSettingsMutationFn = Apollo.MutationFunction<
  UpdateSchedulerLinkSettingsMutation,
  UpdateSchedulerLinkSettingsMutationVariables
>

/**
 * __useUpdateSchedulerLinkSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSchedulerLinkSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchedulerLinkSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchedulerLinkSettingsMutation, { data, loading, error }] = useUpdateSchedulerLinkSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSchedulerLinkSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSchedulerLinkSettingsMutation,
    UpdateSchedulerLinkSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSchedulerLinkSettingsMutation, UpdateSchedulerLinkSettingsMutationVariables>(
    UpdateSchedulerLinkSettingsDocument,
    options,
  )
}
export type UpdateSchedulerLinkSettingsMutationHookResult = ReturnType<typeof useUpdateSchedulerLinkSettingsMutation>
export type UpdateSchedulerLinkSettingsMutationResult = Apollo.MutationResult<UpdateSchedulerLinkSettingsMutation>
export type UpdateSchedulerLinkSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSchedulerLinkSettingsMutation,
  UpdateSchedulerLinkSettingsMutationVariables
>
export const DeleteScorecardTemplateDocument = gql`
  mutation DeleteScorecardTemplate($input: DeleteScorecardTemplateInput!) {
    DeleteScorecardTemplate(input: $input) {
      id
    }
  }
`
export type DeleteScorecardTemplateMutationFn = Apollo.MutationFunction<
  DeleteScorecardTemplateMutation,
  DeleteScorecardTemplateMutationVariables
>

/**
 * __useDeleteScorecardTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteScorecardTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScorecardTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScorecardTemplateMutation, { data, loading, error }] = useDeleteScorecardTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScorecardTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteScorecardTemplateMutation, DeleteScorecardTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteScorecardTemplateMutation, DeleteScorecardTemplateMutationVariables>(
    DeleteScorecardTemplateDocument,
    options,
  )
}
export type DeleteScorecardTemplateMutationHookResult = ReturnType<typeof useDeleteScorecardTemplateMutation>
export type DeleteScorecardTemplateMutationResult = Apollo.MutationResult<DeleteScorecardTemplateMutation>
export type DeleteScorecardTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteScorecardTemplateMutation,
  DeleteScorecardTemplateMutationVariables
>
export const ToggleScorecardTemplateArchiveDocument = gql`
  mutation ToggleScorecardTemplateArchive($input: ToggleScorecardTemplateArchiveInput!) {
    ToggleScorecardTemplateArchive(input: $input) {
      id
      scorecardName
      archived
      department
      companyId
      createdAt
    }
  }
`
export type ToggleScorecardTemplateArchiveMutationFn = Apollo.MutationFunction<
  ToggleScorecardTemplateArchiveMutation,
  ToggleScorecardTemplateArchiveMutationVariables
>

/**
 * __useToggleScorecardTemplateArchiveMutation__
 *
 * To run a mutation, you first call `useToggleScorecardTemplateArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleScorecardTemplateArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleScorecardTemplateArchiveMutation, { data, loading, error }] = useToggleScorecardTemplateArchiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleScorecardTemplateArchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleScorecardTemplateArchiveMutation,
    ToggleScorecardTemplateArchiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ToggleScorecardTemplateArchiveMutation, ToggleScorecardTemplateArchiveMutationVariables>(
    ToggleScorecardTemplateArchiveDocument,
    options,
  )
}
export type ToggleScorecardTemplateArchiveMutationHookResult = ReturnType<
  typeof useToggleScorecardTemplateArchiveMutation
>
export type ToggleScorecardTemplateArchiveMutationResult = Apollo.MutationResult<ToggleScorecardTemplateArchiveMutation>
export type ToggleScorecardTemplateArchiveMutationOptions = Apollo.BaseMutationOptions<
  ToggleScorecardTemplateArchiveMutation,
  ToggleScorecardTemplateArchiveMutationVariables
>
export const UpsertScorecardTemplateDocument = gql`
  mutation UpsertScorecardTemplate($input: UpsertScorecardTemplateInput!) {
    UpsertScorecardTemplate(input: $input) {
      id
      scorecardName
      scorecardDescription
      visible
      department
      companyId
      createdAt
    }
  }
`
export type UpsertScorecardTemplateMutationFn = Apollo.MutationFunction<
  UpsertScorecardTemplateMutation,
  UpsertScorecardTemplateMutationVariables
>

/**
 * __useUpsertScorecardTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertScorecardTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertScorecardTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertScorecardTemplateMutation, { data, loading, error }] = useUpsertScorecardTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertScorecardTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertScorecardTemplateMutation, UpsertScorecardTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertScorecardTemplateMutation, UpsertScorecardTemplateMutationVariables>(
    UpsertScorecardTemplateDocument,
    options,
  )
}
export type UpsertScorecardTemplateMutationHookResult = ReturnType<typeof useUpsertScorecardTemplateMutation>
export type UpsertScorecardTemplateMutationResult = Apollo.MutationResult<UpsertScorecardTemplateMutation>
export type UpsertScorecardTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpsertScorecardTemplateMutation,
  UpsertScorecardTemplateMutationVariables
>
export const DeleteSkillDocument = gql`
  mutation DeleteSkill($input: [DeleteSkillInput!]!) {
    DeleteSkill(input: $input)
  }
`
export type DeleteSkillMutationFn = Apollo.MutationFunction<DeleteSkillMutation, DeleteSkillMutationVariables>

/**
 * __useDeleteSkillMutation__
 *
 * To run a mutation, you first call `useDeleteSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillMutation, { data, loading, error }] = useDeleteSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSkillMutation, DeleteSkillMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSkillMutation, DeleteSkillMutationVariables>(DeleteSkillDocument, options)
}
export type DeleteSkillMutationHookResult = ReturnType<typeof useDeleteSkillMutation>
export type DeleteSkillMutationResult = Apollo.MutationResult<DeleteSkillMutation>
export type DeleteSkillMutationOptions = Apollo.BaseMutationOptions<DeleteSkillMutation, DeleteSkillMutationVariables>
export const UpsertSkillDocument = gql`
  mutation UpsertSkill($input: [UpsertSkillInput!]!) {
    UpsertSkill(input: $input) {
      ...SkillFragment
    }
  }
  ${SkillFragmentFragmentDoc}
`
export type UpsertSkillMutationFn = Apollo.MutationFunction<UpsertSkillMutation, UpsertSkillMutationVariables>

/**
 * __useUpsertSkillMutation__
 *
 * To run a mutation, you first call `useUpsertSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSkillMutation, { data, loading, error }] = useUpsertSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertSkillMutation, UpsertSkillMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertSkillMutation, UpsertSkillMutationVariables>(UpsertSkillDocument, options)
}
export type UpsertSkillMutationHookResult = ReturnType<typeof useUpsertSkillMutation>
export type UpsertSkillMutationResult = Apollo.MutationResult<UpsertSkillMutation>
export type UpsertSkillMutationOptions = Apollo.BaseMutationOptions<UpsertSkillMutation, UpsertSkillMutationVariables>
export const DeleteStageCollaboratorDocument = gql`
  mutation DeleteStageCollaborator($input: DeleteStageCollaboratorInput!) {
    DeleteStageCollaborator(input: $input) {
      id
    }
  }
`
export type DeleteStageCollaboratorMutationFn = Apollo.MutationFunction<
  DeleteStageCollaboratorMutation,
  DeleteStageCollaboratorMutationVariables
>

/**
 * __useDeleteStageCollaboratorMutation__
 *
 * To run a mutation, you first call `useDeleteStageCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStageCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStageCollaboratorMutation, { data, loading, error }] = useDeleteStageCollaboratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStageCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteStageCollaboratorMutation, DeleteStageCollaboratorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteStageCollaboratorMutation, DeleteStageCollaboratorMutationVariables>(
    DeleteStageCollaboratorDocument,
    options,
  )
}
export type DeleteStageCollaboratorMutationHookResult = ReturnType<typeof useDeleteStageCollaboratorMutation>
export type DeleteStageCollaboratorMutationResult = Apollo.MutationResult<DeleteStageCollaboratorMutation>
export type DeleteStageCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  DeleteStageCollaboratorMutation,
  DeleteStageCollaboratorMutationVariables
>
export const UpsertScreenStageCollaboratorDocument = gql`
  mutation UpsertScreenStageCollaborator($input: UpsertStageCollaboratorInput!) {
    UpsertStageCollaborator(input: $input) {
      ...ScreenStageCollaboratorFragment
    }
  }
  ${ScreenStageCollaboratorFragmentFragmentDoc}
`
export type UpsertScreenStageCollaboratorMutationFn = Apollo.MutationFunction<
  UpsertScreenStageCollaboratorMutation,
  UpsertScreenStageCollaboratorMutationVariables
>

/**
 * __useUpsertScreenStageCollaboratorMutation__
 *
 * To run a mutation, you first call `useUpsertScreenStageCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertScreenStageCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertScreenStageCollaboratorMutation, { data, loading, error }] = useUpsertScreenStageCollaboratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertScreenStageCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertScreenStageCollaboratorMutation,
    UpsertScreenStageCollaboratorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertScreenStageCollaboratorMutation, UpsertScreenStageCollaboratorMutationVariables>(
    UpsertScreenStageCollaboratorDocument,
    options,
  )
}
export type UpsertScreenStageCollaboratorMutationHookResult = ReturnType<
  typeof useUpsertScreenStageCollaboratorMutation
>
export type UpsertScreenStageCollaboratorMutationResult = Apollo.MutationResult<UpsertScreenStageCollaboratorMutation>
export type UpsertScreenStageCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  UpsertScreenStageCollaboratorMutation,
  UpsertScreenStageCollaboratorMutationVariables
>
export const UpsertStageCollaboratorDocument = gql`
  mutation UpsertStageCollaborator($input: UpsertStageCollaboratorInput!) {
    UpsertStageCollaborator(input: $input) {
      ...StageCollaboratorFragment
    }
  }
  ${StageCollaboratorFragmentFragmentDoc}
`
export type UpsertStageCollaboratorMutationFn = Apollo.MutationFunction<
  UpsertStageCollaboratorMutation,
  UpsertStageCollaboratorMutationVariables
>

/**
 * __useUpsertStageCollaboratorMutation__
 *
 * To run a mutation, you first call `useUpsertStageCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStageCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStageCollaboratorMutation, { data, loading, error }] = useUpsertStageCollaboratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertStageCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertStageCollaboratorMutation, UpsertStageCollaboratorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertStageCollaboratorMutation, UpsertStageCollaboratorMutationVariables>(
    UpsertStageCollaboratorDocument,
    options,
  )
}
export type UpsertStageCollaboratorMutationHookResult = ReturnType<typeof useUpsertStageCollaboratorMutation>
export type UpsertStageCollaboratorMutationResult = Apollo.MutationResult<UpsertStageCollaboratorMutation>
export type UpsertStageCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  UpsertStageCollaboratorMutation,
  UpsertStageCollaboratorMutationVariables
>
export const DeleteStageSettingsDocument = gql`
  mutation DeleteStageSettings($input: DeleteStageSettingsInput!) {
    DeleteStageSettings(input: $input) {
      id
    }
  }
`
export type DeleteStageSettingsMutationFn = Apollo.MutationFunction<
  DeleteStageSettingsMutation,
  DeleteStageSettingsMutationVariables
>

/**
 * __useDeleteStageSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteStageSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStageSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStageSettingsMutation, { data, loading, error }] = useDeleteStageSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStageSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteStageSettingsMutation, DeleteStageSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteStageSettingsMutation, DeleteStageSettingsMutationVariables>(
    DeleteStageSettingsDocument,
    options,
  )
}
export type DeleteStageSettingsMutationHookResult = ReturnType<typeof useDeleteStageSettingsMutation>
export type DeleteStageSettingsMutationResult = Apollo.MutationResult<DeleteStageSettingsMutation>
export type DeleteStageSettingsMutationOptions = Apollo.BaseMutationOptions<
  DeleteStageSettingsMutation,
  DeleteStageSettingsMutationVariables
>
export const UpsertStageSettingsDocument = gql`
  mutation UpsertStageSettings($input: UpsertStageSettingsInput!) {
    UpsertStageSettings(input: $input) {
      ...StageSettingsFragment
    }
  }
  ${StageSettingsFragmentFragmentDoc}
`
export type UpsertStageSettingsMutationFn = Apollo.MutationFunction<
  UpsertStageSettingsMutation,
  UpsertStageSettingsMutationVariables
>

/**
 * __useUpsertStageSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertStageSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStageSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStageSettingsMutation, { data, loading, error }] = useUpsertStageSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertStageSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertStageSettingsMutation, UpsertStageSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertStageSettingsMutation, UpsertStageSettingsMutationVariables>(
    UpsertStageSettingsDocument,
    options,
  )
}
export type UpsertStageSettingsMutationHookResult = ReturnType<typeof useUpsertStageSettingsMutation>
export type UpsertStageSettingsMutationResult = Apollo.MutationResult<UpsertStageSettingsMutation>
export type UpsertStageSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpsertStageSettingsMutation,
  UpsertStageSettingsMutationVariables
>
export const HtmlToBase64Document = gql`
  mutation HtmlToBase64($input: HtmlToBase64Input!) {
    HtmlToBase64(input: $input) {
      pdf
      docx
    }
  }
`
export type HtmlToBase64MutationFn = Apollo.MutationFunction<HtmlToBase64Mutation, HtmlToBase64MutationVariables>

/**
 * __useHtmlToBase64Mutation__
 *
 * To run a mutation, you first call `useHtmlToBase64Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHtmlToBase64Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [htmlToBase64Mutation, { data, loading, error }] = useHtmlToBase64Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHtmlToBase64Mutation(
  baseOptions?: Apollo.MutationHookOptions<HtmlToBase64Mutation, HtmlToBase64MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<HtmlToBase64Mutation, HtmlToBase64MutationVariables>(HtmlToBase64Document, options)
}
export type HtmlToBase64MutationHookResult = ReturnType<typeof useHtmlToBase64Mutation>
export type HtmlToBase64MutationResult = Apollo.MutationResult<HtmlToBase64Mutation>
export type HtmlToBase64MutationOptions = Apollo.BaseMutationOptions<
  HtmlToBase64Mutation,
  HtmlToBase64MutationVariables
>
export const HtmlToDocxDocument = gql`
  mutation HtmlToDocx($input: HtmlToDocxInput!) {
    HtmlToDocx(input: $input) {
      filename
      size
      url
      dir
    }
  }
`
export type HtmlToDocxMutationFn = Apollo.MutationFunction<HtmlToDocxMutation, HtmlToDocxMutationVariables>

/**
 * __useHtmlToDocxMutation__
 *
 * To run a mutation, you first call `useHtmlToDocxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHtmlToDocxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [htmlToDocxMutation, { data, loading, error }] = useHtmlToDocxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHtmlToDocxMutation(
  baseOptions?: Apollo.MutationHookOptions<HtmlToDocxMutation, HtmlToDocxMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<HtmlToDocxMutation, HtmlToDocxMutationVariables>(HtmlToDocxDocument, options)
}
export type HtmlToDocxMutationHookResult = ReturnType<typeof useHtmlToDocxMutation>
export type HtmlToDocxMutationResult = Apollo.MutationResult<HtmlToDocxMutation>
export type HtmlToDocxMutationOptions = Apollo.BaseMutationOptions<HtmlToDocxMutation, HtmlToDocxMutationVariables>
export const DeleteTemplateMessageDocument = gql`
  mutation DeleteTemplateMessage($input: DeleteTemplateMessageInput!) {
    DeleteTemplateMessage(input: $input) {
      id
    }
  }
`
export type DeleteTemplateMessageMutationFn = Apollo.MutationFunction<
  DeleteTemplateMessageMutation,
  DeleteTemplateMessageMutationVariables
>

/**
 * __useDeleteTemplateMessageMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMessageMutation, { data, loading, error }] = useDeleteTemplateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMessageMutation, DeleteTemplateMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTemplateMessageMutation, DeleteTemplateMessageMutationVariables>(
    DeleteTemplateMessageDocument,
    options,
  )
}
export type DeleteTemplateMessageMutationHookResult = ReturnType<typeof useDeleteTemplateMessageMutation>
export type DeleteTemplateMessageMutationResult = Apollo.MutationResult<DeleteTemplateMessageMutation>
export type DeleteTemplateMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateMessageMutation,
  DeleteTemplateMessageMutationVariables
>
export const UpsertTemplateMessageDocument = gql`
  mutation UpsertTemplateMessage($input: UpsertTemplateMessageInput!) {
    UpsertTemplateMessage(input: $input) {
      id
      title
      message
      messageType
      messageOwner
      createdAt
      updatedAt
    }
  }
`
export type UpsertTemplateMessageMutationFn = Apollo.MutationFunction<
  UpsertTemplateMessageMutation,
  UpsertTemplateMessageMutationVariables
>

/**
 * __useUpsertTemplateMessageMutation__
 *
 * To run a mutation, you first call `useUpsertTemplateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTemplateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTemplateMessageMutation, { data, loading, error }] = useUpsertTemplateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTemplateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertTemplateMessageMutation, UpsertTemplateMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertTemplateMessageMutation, UpsertTemplateMessageMutationVariables>(
    UpsertTemplateMessageDocument,
    options,
  )
}
export type UpsertTemplateMessageMutationHookResult = ReturnType<typeof useUpsertTemplateMessageMutation>
export type UpsertTemplateMessageMutationResult = Apollo.MutationResult<UpsertTemplateMessageMutation>
export type UpsertTemplateMessageMutationOptions = Apollo.BaseMutationOptions<
  UpsertTemplateMessageMutation,
  UpsertTemplateMessageMutationVariables
>
export const DeleteTemplateMilestoneDocument = gql`
  mutation DeleteTemplateMilestone($input: DeleteTemplateMilestoneInput!) {
    DeleteTemplateMilestone(input: $input) {
      id
    }
  }
`
export type DeleteTemplateMilestoneMutationFn = Apollo.MutationFunction<
  DeleteTemplateMilestoneMutation,
  DeleteTemplateMilestoneMutationVariables
>

/**
 * __useDeleteTemplateMilestoneMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMilestoneMutation, { data, loading, error }] = useDeleteTemplateMilestoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateMilestoneMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMilestoneMutation, DeleteTemplateMilestoneMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTemplateMilestoneMutation, DeleteTemplateMilestoneMutationVariables>(
    DeleteTemplateMilestoneDocument,
    options,
  )
}
export type DeleteTemplateMilestoneMutationHookResult = ReturnType<typeof useDeleteTemplateMilestoneMutation>
export type DeleteTemplateMilestoneMutationResult = Apollo.MutationResult<DeleteTemplateMilestoneMutation>
export type DeleteTemplateMilestoneMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateMilestoneMutation,
  DeleteTemplateMilestoneMutationVariables
>
export const UpsertTemplateMilestoneDocument = gql`
  mutation UpsertTemplateMilestone($input: UpsertTemplateMilestoneInput!) {
    UpsertTemplateMilestone(input: $input) {
      ...TemplateMilestoneFragment
      TemplateStage {
        ...TemplateStageFragment
      }
    }
  }
  ${TemplateMilestoneFragmentFragmentDoc}
  ${TemplateStageFragmentFragmentDoc}
`
export type UpsertTemplateMilestoneMutationFn = Apollo.MutationFunction<
  UpsertTemplateMilestoneMutation,
  UpsertTemplateMilestoneMutationVariables
>

/**
 * __useUpsertTemplateMilestoneMutation__
 *
 * To run a mutation, you first call `useUpsertTemplateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTemplateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTemplateMilestoneMutation, { data, loading, error }] = useUpsertTemplateMilestoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTemplateMilestoneMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertTemplateMilestoneMutation, UpsertTemplateMilestoneMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertTemplateMilestoneMutation, UpsertTemplateMilestoneMutationVariables>(
    UpsertTemplateMilestoneDocument,
    options,
  )
}
export type UpsertTemplateMilestoneMutationHookResult = ReturnType<typeof useUpsertTemplateMilestoneMutation>
export type UpsertTemplateMilestoneMutationResult = Apollo.MutationResult<UpsertTemplateMilestoneMutation>
export type UpsertTemplateMilestoneMutationOptions = Apollo.BaseMutationOptions<
  UpsertTemplateMilestoneMutation,
  UpsertTemplateMilestoneMutationVariables
>
export const DeleteTemplatePipelineDocument = gql`
  mutation DeleteTemplatePipeline($input: DeleteTemplatePipelineInput!) {
    DeleteTemplatePipeline(input: $input) {
      id
    }
  }
`
export type DeleteTemplatePipelineMutationFn = Apollo.MutationFunction<
  DeleteTemplatePipelineMutation,
  DeleteTemplatePipelineMutationVariables
>

/**
 * __useDeleteTemplatePipelineMutation__
 *
 * To run a mutation, you first call `useDeleteTemplatePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplatePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplatePipelineMutation, { data, loading, error }] = useDeleteTemplatePipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplatePipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTemplatePipelineMutation, DeleteTemplatePipelineMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTemplatePipelineMutation, DeleteTemplatePipelineMutationVariables>(
    DeleteTemplatePipelineDocument,
    options,
  )
}
export type DeleteTemplatePipelineMutationHookResult = ReturnType<typeof useDeleteTemplatePipelineMutation>
export type DeleteTemplatePipelineMutationResult = Apollo.MutationResult<DeleteTemplatePipelineMutation>
export type DeleteTemplatePipelineMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplatePipelineMutation,
  DeleteTemplatePipelineMutationVariables
>
export const UpsertTemplatePipelineDocument = gql`
  mutation UpsertTemplatePipeline($input: UpsertTemplatePipelineInput!) {
    UpsertTemplatePipeline(input: $input) {
      ...TemplatePipelineFragment
      TemplateMilestones {
        ...TemplateMilestoneFragment
        TemplateStage {
          ...TemplateStageFragment
        }
      }
    }
  }
  ${TemplatePipelineFragmentFragmentDoc}
  ${TemplateMilestoneFragmentFragmentDoc}
  ${TemplateStageFragmentFragmentDoc}
`
export type UpsertTemplatePipelineMutationFn = Apollo.MutationFunction<
  UpsertTemplatePipelineMutation,
  UpsertTemplatePipelineMutationVariables
>

/**
 * __useUpsertTemplatePipelineMutation__
 *
 * To run a mutation, you first call `useUpsertTemplatePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTemplatePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTemplatePipelineMutation, { data, loading, error }] = useUpsertTemplatePipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTemplatePipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertTemplatePipelineMutation, UpsertTemplatePipelineMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertTemplatePipelineMutation, UpsertTemplatePipelineMutationVariables>(
    UpsertTemplatePipelineDocument,
    options,
  )
}
export type UpsertTemplatePipelineMutationHookResult = ReturnType<typeof useUpsertTemplatePipelineMutation>
export type UpsertTemplatePipelineMutationResult = Apollo.MutationResult<UpsertTemplatePipelineMutation>
export type UpsertTemplatePipelineMutationOptions = Apollo.BaseMutationOptions<
  UpsertTemplatePipelineMutation,
  UpsertTemplatePipelineMutationVariables
>
export const DeleteTemplateStageDocument = gql`
  mutation DeleteTemplateStage($input: DeleteTemplateStageInput!) {
    DeleteTemplateStage(input: $input) {
      id
    }
  }
`
export type DeleteTemplateStageMutationFn = Apollo.MutationFunction<
  DeleteTemplateStageMutation,
  DeleteTemplateStageMutationVariables
>

/**
 * __useDeleteTemplateStageMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateStageMutation, { data, loading, error }] = useDeleteTemplateStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateStageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTemplateStageMutation, DeleteTemplateStageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTemplateStageMutation, DeleteTemplateStageMutationVariables>(
    DeleteTemplateStageDocument,
    options,
  )
}
export type DeleteTemplateStageMutationHookResult = ReturnType<typeof useDeleteTemplateStageMutation>
export type DeleteTemplateStageMutationResult = Apollo.MutationResult<DeleteTemplateStageMutation>
export type DeleteTemplateStageMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateStageMutation,
  DeleteTemplateStageMutationVariables
>
export const UpsertTemplateStageDocument = gql`
  mutation UpsertTemplateStage($input: UpsertTemplateStageInput!) {
    UpsertTemplateStage(input: $input) {
      ...TemplateStageFragment
    }
  }
  ${TemplateStageFragmentFragmentDoc}
`
export type UpsertTemplateStageMutationFn = Apollo.MutationFunction<
  UpsertTemplateStageMutation,
  UpsertTemplateStageMutationVariables
>

/**
 * __useUpsertTemplateStageMutation__
 *
 * To run a mutation, you first call `useUpsertTemplateStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTemplateStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTemplateStageMutation, { data, loading, error }] = useUpsertTemplateStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTemplateStageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertTemplateStageMutation, UpsertTemplateStageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertTemplateStageMutation, UpsertTemplateStageMutationVariables>(
    UpsertTemplateStageDocument,
    options,
  )
}
export type UpsertTemplateStageMutationHookResult = ReturnType<typeof useUpsertTemplateStageMutation>
export type UpsertTemplateStageMutationResult = Apollo.MutationResult<UpsertTemplateStageMutation>
export type UpsertTemplateStageMutationOptions = Apollo.BaseMutationOptions<
  UpsertTemplateStageMutation,
  UpsertTemplateStageMutationVariables
>
export const UploadS3FileDocument = gql`
  mutation UploadS3File($input: UploadS3FileInput!) {
    UploadS3File(input: $input) {
      fileKey
      filePath
    }
  }
`
export type UploadS3FileMutationFn = Apollo.MutationFunction<UploadS3FileMutation, UploadS3FileMutationVariables>

/**
 * __useUploadS3FileMutation__
 *
 * To run a mutation, you first call `useUploadS3FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadS3FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadS3FileMutation, { data, loading, error }] = useUploadS3FileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadS3FileMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadS3FileMutation, UploadS3FileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadS3FileMutation, UploadS3FileMutationVariables>(UploadS3FileDocument, options)
}
export type UploadS3FileMutationHookResult = ReturnType<typeof useUploadS3FileMutation>
export type UploadS3FileMutationResult = Apollo.MutationResult<UploadS3FileMutation>
export type UploadS3FileMutationOptions = Apollo.BaseMutationOptions<
  UploadS3FileMutation,
  UploadS3FileMutationVariables
>
export const CreateUploadedCandidateDocument = gql`
  mutation CreateUploadedCandidate($input: CreateUploadedCandidateInput!) {
    CreateUploadedCandidate(input: $input) {
      __typename
      id
    }
  }
`
export type CreateUploadedCandidateMutationFn = Apollo.MutationFunction<
  CreateUploadedCandidateMutation,
  CreateUploadedCandidateMutationVariables
>

/**
 * __useCreateUploadedCandidateMutation__
 *
 * To run a mutation, you first call `useCreateUploadedCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadedCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadedCandidateMutation, { data, loading, error }] = useCreateUploadedCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadedCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUploadedCandidateMutation, CreateUploadedCandidateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUploadedCandidateMutation, CreateUploadedCandidateMutationVariables>(
    CreateUploadedCandidateDocument,
    options,
  )
}
export type CreateUploadedCandidateMutationHookResult = ReturnType<typeof useCreateUploadedCandidateMutation>
export type CreateUploadedCandidateMutationResult = Apollo.MutationResult<CreateUploadedCandidateMutation>
export type CreateUploadedCandidateMutationOptions = Apollo.BaseMutationOptions<
  CreateUploadedCandidateMutation,
  CreateUploadedCandidateMutationVariables
>
export const UpdateAccountInformationDocument = gql`
  mutation UpdateAccountInformation($input: UpdateScalisUserDataInput!) {
    UpdateScalisUserData(input: $input) {
      firstName
      lastName
      Address {
        id
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
        timeZone
      }
      ContactInfo {
        id
        phoneNumber
      }
    }
  }
`
export type UpdateAccountInformationMutationFn = Apollo.MutationFunction<
  UpdateAccountInformationMutation,
  UpdateAccountInformationMutationVariables
>

/**
 * __useUpdateAccountInformationMutation__
 *
 * To run a mutation, you first call `useUpdateAccountInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountInformationMutation, { data, loading, error }] = useUpdateAccountInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>(
    UpdateAccountInformationDocument,
    options,
  )
}
export type UpdateAccountInformationMutationHookResult = ReturnType<typeof useUpdateAccountInformationMutation>
export type UpdateAccountInformationMutationResult = Apollo.MutationResult<UpdateAccountInformationMutation>
export type UpdateAccountInformationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountInformationMutation,
  UpdateAccountInformationMutationVariables
>
export const DeleteAccountDocument = gql`
  mutation DeleteAccount {
    DisableUserAccount {
      id
    }
  }
`
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options)
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>
export const UpsertEmailDocument = gql`
  mutation UpsertEmail($input: UpsertEmailInput!) {
    UpsertEmail(input: $input) {
      EmailVerification {
        __typename
        id
        emailAddress
        isPrimary
        isVisibleToEmployers
        activeStatus
      }
    }
  }
`
export type UpsertEmailMutationFn = Apollo.MutationFunction<UpsertEmailMutation, UpsertEmailMutationVariables>

/**
 * __useUpsertEmailMutation__
 *
 * To run a mutation, you first call `useUpsertEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEmailMutation, { data, loading, error }] = useUpsertEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertEmailMutation, UpsertEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertEmailMutation, UpsertEmailMutationVariables>(UpsertEmailDocument, options)
}
export type UpsertEmailMutationHookResult = ReturnType<typeof useUpsertEmailMutation>
export type UpsertEmailMutationResult = Apollo.MutationResult<UpsertEmailMutation>
export type UpsertEmailMutationOptions = Apollo.BaseMutationOptions<UpsertEmailMutation, UpsertEmailMutationVariables>
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    ChangePassword(input: $input) {
      __typename
      id
    }
  }
`
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options)
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const ResendResetPasswordEmailDocument = gql`
  mutation ResendResetPasswordEmail($input: ResendResetPasswordEmailInput!) {
    ResendResetPasswordEmail(input: $input) {
      __typename
      id
    }
  }
`
export type ResendResetPasswordEmailMutationFn = Apollo.MutationFunction<
  ResendResetPasswordEmailMutation,
  ResendResetPasswordEmailMutationVariables
>

/**
 * __useResendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useResendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendResetPasswordEmailMutation, { data, loading, error }] = useResendResetPasswordEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendResetPasswordEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendResetPasswordEmailMutation, ResendResetPasswordEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResendResetPasswordEmailMutation, ResendResetPasswordEmailMutationVariables>(
    ResendResetPasswordEmailDocument,
    options,
  )
}
export type ResendResetPasswordEmailMutationHookResult = ReturnType<typeof useResendResetPasswordEmailMutation>
export type ResendResetPasswordEmailMutationResult = Apollo.MutationResult<ResendResetPasswordEmailMutation>
export type ResendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendResetPasswordEmailMutation,
  ResendResetPasswordEmailMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    ResetPassword(input: $input) {
      __typename
      id
    }
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const SendResetPasswordEmailDocument = gql`
  mutation SendResetPasswordEmail($input: SendResetPasswordEmailInput!) {
    SendResetPasswordEmail(input: $input) {
      __typename
      id
      emailAddress
    }
  }
`
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(
    SendResetPasswordEmailDocument,
    options,
  )
}
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>
export const VerifyEmailCodeResetPasswordDocument = gql`
  mutation VerifyEmailCodeResetPassword($input: VerifyEmailCodeInput!) {
    VerifyEmailCode(input: $input) {
      __typename
      id
    }
  }
`
export type VerifyEmailCodeResetPasswordMutationFn = Apollo.MutationFunction<
  VerifyEmailCodeResetPasswordMutation,
  VerifyEmailCodeResetPasswordMutationVariables
>

/**
 * __useVerifyEmailCodeResetPasswordMutation__
 *
 * To run a mutation, you first call `useVerifyEmailCodeResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailCodeResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailCodeResetPasswordMutation, { data, loading, error }] = useVerifyEmailCodeResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailCodeResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailCodeResetPasswordMutation,
    VerifyEmailCodeResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyEmailCodeResetPasswordMutation, VerifyEmailCodeResetPasswordMutationVariables>(
    VerifyEmailCodeResetPasswordDocument,
    options,
  )
}
export type VerifyEmailCodeResetPasswordMutationHookResult = ReturnType<typeof useVerifyEmailCodeResetPasswordMutation>
export type VerifyEmailCodeResetPasswordMutationResult = Apollo.MutationResult<VerifyEmailCodeResetPasswordMutation>
export type VerifyEmailCodeResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailCodeResetPasswordMutation,
  VerifyEmailCodeResetPasswordMutationVariables
>
export const VerifyPasswordCodeDocument = gql`
  mutation VerifyPasswordCode($input: VerifyPasswordCodeInput!) {
    VerifyPasswordCode(input: $input) {
      __typename
      id
    }
  }
`
export type VerifyPasswordCodeMutationFn = Apollo.MutationFunction<
  VerifyPasswordCodeMutation,
  VerifyPasswordCodeMutationVariables
>

/**
 * __useVerifyPasswordCodeMutation__
 *
 * To run a mutation, you first call `useVerifyPasswordCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPasswordCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPasswordCodeMutation, { data, loading, error }] = useVerifyPasswordCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPasswordCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyPasswordCodeMutation, VerifyPasswordCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyPasswordCodeMutation, VerifyPasswordCodeMutationVariables>(
    VerifyPasswordCodeDocument,
    options,
  )
}
export type VerifyPasswordCodeMutationHookResult = ReturnType<typeof useVerifyPasswordCodeMutation>
export type VerifyPasswordCodeMutationResult = Apollo.MutationResult<VerifyPasswordCodeMutation>
export type VerifyPasswordCodeMutationOptions = Apollo.BaseMutationOptions<
  VerifyPasswordCodeMutation,
  VerifyPasswordCodeMutationVariables
>
export const GetPublicJobListingDocument = gql`
  query GetPublicJobListing($publicListingId: String!) {
    GetPublicJobListing(publicListingId: $publicListingId) {
      JobListingScreeningQuestion {
        id
        active
        ScreeningQuestion {
          id
          companyId
          questionTitle
          questionType
          ansOptions
          isTemplateQuestion
          isGlobalQuestion
          globalQuestionOrder
          maxVal
          minVal
          preferredAnswer
          questionDesc
          valStatus
        }
        displaySequenceNumber
        screeningQuestionId
      }
      externalTitle
      externalListingId
      displayPayOnListing
      id
      industry
      Requisition {
        Company {
          id
        }
      }
    }
  }
`

/**
 * __useGetPublicJobListingQuery__
 *
 * To run a query within a React component, call `useGetPublicJobListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicJobListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicJobListingQuery({
 *   variables: {
 *      publicListingId: // value for 'publicListingId'
 *   },
 * });
 */
export function useGetPublicJobListingQuery(
  baseOptions: Apollo.QueryHookOptions<GetPublicJobListingQuery, GetPublicJobListingQueryVariables> &
    ({ variables: GetPublicJobListingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPublicJobListingQuery, GetPublicJobListingQueryVariables>(
    GetPublicJobListingDocument,
    options,
  )
}
export function useGetPublicJobListingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPublicJobListingQuery, GetPublicJobListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPublicJobListingQuery, GetPublicJobListingQueryVariables>(
    GetPublicJobListingDocument,
    options,
  )
}
export function useGetPublicJobListingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPublicJobListingQuery, GetPublicJobListingQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPublicJobListingQuery, GetPublicJobListingQueryVariables>(
    GetPublicJobListingDocument,
    options,
  )
}
export type GetPublicJobListingQueryHookResult = ReturnType<typeof useGetPublicJobListingQuery>
export type GetPublicJobListingLazyQueryHookResult = ReturnType<typeof useGetPublicJobListingLazyQuery>
export type GetPublicJobListingSuspenseQueryHookResult = ReturnType<typeof useGetPublicJobListingSuspenseQuery>
export type GetPublicJobListingQueryResult = Apollo.QueryResult<
  GetPublicJobListingQuery,
  GetPublicJobListingQueryVariables
>
export const GetUserHasFinishedOnboardingDocument = gql`
  query GetUserHasFinishedOnboarding {
    Me {
      id
      JobSeekerInfo {
        id
        hasFinishedOnboarding
      }
    }
  }
`

/**
 * __useGetUserHasFinishedOnboardingQuery__
 *
 * To run a query within a React component, call `useGetUserHasFinishedOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserHasFinishedOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserHasFinishedOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserHasFinishedOnboardingQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserHasFinishedOnboardingQuery, GetUserHasFinishedOnboardingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserHasFinishedOnboardingQuery, GetUserHasFinishedOnboardingQueryVariables>(
    GetUserHasFinishedOnboardingDocument,
    options,
  )
}
export function useGetUserHasFinishedOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserHasFinishedOnboardingQuery,
    GetUserHasFinishedOnboardingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserHasFinishedOnboardingQuery, GetUserHasFinishedOnboardingQueryVariables>(
    GetUserHasFinishedOnboardingDocument,
    options,
  )
}
export function useGetUserHasFinishedOnboardingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserHasFinishedOnboardingQuery, GetUserHasFinishedOnboardingQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetUserHasFinishedOnboardingQuery, GetUserHasFinishedOnboardingQueryVariables>(
    GetUserHasFinishedOnboardingDocument,
    options,
  )
}
export type GetUserHasFinishedOnboardingQueryHookResult = ReturnType<typeof useGetUserHasFinishedOnboardingQuery>
export type GetUserHasFinishedOnboardingLazyQueryHookResult = ReturnType<
  typeof useGetUserHasFinishedOnboardingLazyQuery
>
export type GetUserHasFinishedOnboardingSuspenseQueryHookResult = ReturnType<
  typeof useGetUserHasFinishedOnboardingSuspenseQuery
>
export type GetUserHasFinishedOnboardingQueryResult = Apollo.QueryResult<
  GetUserHasFinishedOnboardingQuery,
  GetUserHasFinishedOnboardingQueryVariables
>
export const GetUserProfileDataDocument = gql`
  query GetUserProfileData {
    Me {
      id
      firstName
      lastName
      completedAt
      currentStage
      profileImage
      EmailVerification {
        emailAddress
        emailVerified
        isPrimary
      }
      JobSeekerInfo {
        id
        aboutMe
        searchingJobs
      }
      JobSeekerHeader {
        id
        profileHeadline
        jobDisplayedId
        educationDisplayedId
      }
      ContactInfo {
        id
        phoneNumber
      }
      Address {
        id
        city
        state
        country
        addressLine1
        addressLine2
        postalCode
        timeZone
        longitude
        latitude
      }
      Achievement {
        id
        name
        issuingAuthority
        issueYear
        description
      }
      CertificateOrLicense {
        id
        name
        issuingAuthority
        issueYear
        description
      }
      JobSeekerSkill {
        id
        sequenceNumber
        level
        name
        yearsOfExp
      }
      DemographicInfo {
        gender
        raceEthnicity
        veteranStatus
      }
      WebsiteOrSocialMedia {
        websiteURL
        type
      }
      VolunteerWork {
        id
        roleType
        volunteerJobTitle
        organizationName
        location
        startDate
        endDate
        isCurrentlyWorking
        description
        searchDescription
      }
      Experience {
        id
        jobTitle
        employerName
        jobType
        locationType
        location
        startDate
        endDate
        isCurrentlyWorking
        isCurrentJob
        description
        industry
        subIndustry
        managementPosition
        teamSize
        isCurrentlyWorking
        Skill {
          id
          name
        }
      }
      Education {
        id
        institutionName
        educationLevel
        areasOfStudy
        startDate
        endDate
        cumGPA
        cumGPAScale
        majors
        minors
        hideCumGPA
        hideDepartmentGPA
        departmentCumGPA
        additionalInformation
        currentlyStudying
      }
      ScalisUserUploadedDocument {
        id
        documentPath
        documentName
        documentType
        createdAt
      }
    }
  }
`

/**
 * __useGetUserProfileDataQuery__
 *
 * To run a query within a React component, call `useGetUserProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>(GetUserProfileDataDocument, options)
}
export function useGetUserProfileDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>(
    GetUserProfileDataDocument,
    options,
  )
}
export function useGetUserProfileDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>(
    GetUserProfileDataDocument,
    options,
  )
}
export type GetUserProfileDataQueryHookResult = ReturnType<typeof useGetUserProfileDataQuery>
export type GetUserProfileDataLazyQueryHookResult = ReturnType<typeof useGetUserProfileDataLazyQuery>
export type GetUserProfileDataSuspenseQueryHookResult = ReturnType<typeof useGetUserProfileDataSuspenseQuery>
export type GetUserProfileDataQueryResult = Apollo.QueryResult<
  GetUserProfileDataQuery,
  GetUserProfileDataQueryVariables
>
export const GetCalendarSettingsDocument = gql`
  query GetCalendarSettings {
    GetCalendarSettings {
      id
      availability
      provider
      timeZone
      hourDisplay
      email
      companyUserId
      nylasGrantId
      Calendars {
        id
        nylasCalendarId
        calendarSettingsId
        name
        isPrimary
        color
        CalendarEvents {
          id
          nylasEventStatus
          nylasCalendarEventId
          title
          eventType
          description
          startTime
          endTime
          timeZone
          location
          repeats
          reminders
          employers
          conferencing
          masterEventId
          regardingCandidates
          regardingJobs
          internalGuests
          candidates
          isScalisEvent
        }
      }
    }
  }
`

/**
 * __useGetCalendarSettingsQuery__
 *
 * To run a query within a React component, call `useGetCalendarSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCalendarSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCalendarSettingsQuery, GetCalendarSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCalendarSettingsQuery, GetCalendarSettingsQueryVariables>(
    GetCalendarSettingsDocument,
    options,
  )
}
export function useGetCalendarSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarSettingsQuery, GetCalendarSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCalendarSettingsQuery, GetCalendarSettingsQueryVariables>(
    GetCalendarSettingsDocument,
    options,
  )
}
export function useGetCalendarSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCalendarSettingsQuery, GetCalendarSettingsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCalendarSettingsQuery, GetCalendarSettingsQueryVariables>(
    GetCalendarSettingsDocument,
    options,
  )
}
export type GetCalendarSettingsQueryHookResult = ReturnType<typeof useGetCalendarSettingsQuery>
export type GetCalendarSettingsLazyQueryHookResult = ReturnType<typeof useGetCalendarSettingsLazyQuery>
export type GetCalendarSettingsSuspenseQueryHookResult = ReturnType<typeof useGetCalendarSettingsSuspenseQuery>
export type GetCalendarSettingsQueryResult = Apollo.QueryResult<
  GetCalendarSettingsQuery,
  GetCalendarSettingsQueryVariables
>
export const GetCandidateApplicationByListingDocument = gql`
  query GetCandidateApplicationByListing($candidateProfileId: Int!, $publicListingId: String!) {
    GetCandidateApplication(candidateProfileId: [$candidateProfileId], publicListingIds: [$publicListingId]) {
      id
      candidateProfileId
      JobListing {
        id
        Requisition {
          id
          jobName
        }
      }
    }
  }
`

/**
 * __useGetCandidateApplicationByListingQuery__
 *
 * To run a query within a React component, call `useGetCandidateApplicationByListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateApplicationByListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateApplicationByListingQuery({
 *   variables: {
 *      candidateProfileId: // value for 'candidateProfileId'
 *      publicListingId: // value for 'publicListingId'
 *   },
 * });
 */
export function useGetCandidateApplicationByListingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCandidateApplicationByListingQuery,
    GetCandidateApplicationByListingQueryVariables
  > &
    ({ variables: GetCandidateApplicationByListingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateApplicationByListingQuery, GetCandidateApplicationByListingQueryVariables>(
    GetCandidateApplicationByListingDocument,
    options,
  )
}
export function useGetCandidateApplicationByListingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCandidateApplicationByListingQuery,
    GetCandidateApplicationByListingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateApplicationByListingQuery, GetCandidateApplicationByListingQueryVariables>(
    GetCandidateApplicationByListingDocument,
    options,
  )
}
export function useGetCandidateApplicationByListingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCandidateApplicationByListingQuery,
        GetCandidateApplicationByListingQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateApplicationByListingQuery, GetCandidateApplicationByListingQueryVariables>(
    GetCandidateApplicationByListingDocument,
    options,
  )
}
export type GetCandidateApplicationByListingQueryHookResult = ReturnType<
  typeof useGetCandidateApplicationByListingQuery
>
export type GetCandidateApplicationByListingLazyQueryHookResult = ReturnType<
  typeof useGetCandidateApplicationByListingLazyQuery
>
export type GetCandidateApplicationByListingSuspenseQueryHookResult = ReturnType<
  typeof useGetCandidateApplicationByListingSuspenseQuery
>
export type GetCandidateApplicationByListingQueryResult = Apollo.QueryResult<
  GetCandidateApplicationByListingQuery,
  GetCandidateApplicationByListingQueryVariables
>
export const GetApplicationOverviewDocument = gql`
  query GetApplicationOverview($id: Int!) {
    GetCandidateApplication(templateMilestoneNotIn: SOURCING, id: $id) {
      id
      applicationStatus
      appliedAt
      Company {
        Address {
          city
          state
          country
        }
        CompanyInfo {
          companyName
          logo
        }
      }
      JobListing {
        externalListingId
        externalTitle
        publicListingId
        id
        Requisition {
          id
          jobName
          extRequisitionId
        }
        jobRequisitionId
        stateAndOrCountry
        workplaceType
        Address {
          timeZone
          state
          longitude
          latitude
          country
          activeStatus
          addressLine1
          addressLine2
          addressType
          city
          postalCode
        }
      }
      LastPublicTemplateStage {
        id
        name
        TemplateMilestone {
          name
        }
      }
      applicationType
      TemplateStage {
        id
        name
        visibility
        TemplateMilestone {
          id
          name
        }
        TemplatePipeline {
          TemplateMilestones {
            id
            name
            rankOrder
            TemplateStage {
              name
              id
              rankOrder
              visibility
            }
          }
        }
      }
      CandidateInterview {
        CalendarEvent {
          EventAttachments {
            id
            file
            eventId
          }
          Calendar {
            name
            id
            isPrimary
          }
          title
          timeZone
          startTime
          repeats
          reminders
          regardingJobs
          regardingCandidates
          nylasEventStatus
          nylasCalendarEventId
          masterEventId
          location
          isScalisEvent
          internalGuests
          id
          eventType
          employers
          endTime
          description
          conferencing
          companyUserId
          candidates
          calendarId
          CompanyUser {
            UserInvitation {
              ScalisUser {
                id
                firstName
                lastName
              }
            }
          }
        }
        stageId
        interviewData
        id
        status
        companyUserId
        calendarEventId
        applicationId
      }
      ApplicantNotes {
        userNoteId
        id
        candidateApplicationId
        updatedAt
        UserNote {
          id
          noteText
        }
      }
      SchedulerLinkInvite {
        stageId
        nylasConfigurationId
        message
        interviewData
        id
        durationMinutes
        companyUserId
        candidateData
        calendarId
        applicationId
        TemplateStage {
          name
          id
          rankOrder
        }
      }
      CandidateProfile {
        ScalisUser {
          id
          lastName
          firstName
          profileImage
        }
      }
    }
  }
`

/**
 * __useGetApplicationOverviewQuery__
 *
 * To run a query within a React component, call `useGetApplicationOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<GetApplicationOverviewQuery, GetApplicationOverviewQueryVariables> &
    ({ variables: GetApplicationOverviewQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApplicationOverviewQuery, GetApplicationOverviewQueryVariables>(
    GetApplicationOverviewDocument,
    options,
  )
}
export function useGetApplicationOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationOverviewQuery, GetApplicationOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetApplicationOverviewQuery, GetApplicationOverviewQueryVariables>(
    GetApplicationOverviewDocument,
    options,
  )
}
export function useGetApplicationOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetApplicationOverviewQuery, GetApplicationOverviewQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetApplicationOverviewQuery, GetApplicationOverviewQueryVariables>(
    GetApplicationOverviewDocument,
    options,
  )
}
export type GetApplicationOverviewQueryHookResult = ReturnType<typeof useGetApplicationOverviewQuery>
export type GetApplicationOverviewLazyQueryHookResult = ReturnType<typeof useGetApplicationOverviewLazyQuery>
export type GetApplicationOverviewSuspenseQueryHookResult = ReturnType<typeof useGetApplicationOverviewSuspenseQuery>
export type GetApplicationOverviewQueryResult = Apollo.QueryResult<
  GetApplicationOverviewQuery,
  GetApplicationOverviewQueryVariables
>
export const GetCandidatesApplicationsByStageDocument = gql`
  query GetCandidatesApplicationsByStage(
    $milestoneNotIn: [NameEnum!]
    $milestoneIn: [NameEnum!]
    $requisitionId: Int
    $id: Int
  ) {
    GetTemplateStage(
      requisitionId: $requisitionId
      milestoneNotIn: $milestoneNotIn
      milestoneIn: $milestoneIn
      id: $id
    ) {
      id
      CandidateApplicationByStage(requisitionId: $requisitionId) {
        id
        CandidateProfile {
          id
          UploadedCandidate {
            id
            lastName
            firstName
            emailAddress
          }
          ScalisUser {
            id
            lastName
            firstName
            EmailVerification {
              id
              isVisibleToEmployers
              isPrimary
              emailAddress
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetCandidatesApplicationsByStageQuery__
 *
 * To run a query within a React component, call `useGetCandidatesApplicationsByStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidatesApplicationsByStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidatesApplicationsByStageQuery({
 *   variables: {
 *      milestoneNotIn: // value for 'milestoneNotIn'
 *      milestoneIn: // value for 'milestoneIn'
 *      requisitionId: // value for 'requisitionId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCandidatesApplicationsByStageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCandidatesApplicationsByStageQuery,
    GetCandidatesApplicationsByStageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidatesApplicationsByStageQuery, GetCandidatesApplicationsByStageQueryVariables>(
    GetCandidatesApplicationsByStageDocument,
    options,
  )
}
export function useGetCandidatesApplicationsByStageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCandidatesApplicationsByStageQuery,
    GetCandidatesApplicationsByStageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidatesApplicationsByStageQuery, GetCandidatesApplicationsByStageQueryVariables>(
    GetCandidatesApplicationsByStageDocument,
    options,
  )
}
export function useGetCandidatesApplicationsByStageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCandidatesApplicationsByStageQuery,
        GetCandidatesApplicationsByStageQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidatesApplicationsByStageQuery, GetCandidatesApplicationsByStageQueryVariables>(
    GetCandidatesApplicationsByStageDocument,
    options,
  )
}
export type GetCandidatesApplicationsByStageQueryHookResult = ReturnType<
  typeof useGetCandidatesApplicationsByStageQuery
>
export type GetCandidatesApplicationsByStageLazyQueryHookResult = ReturnType<
  typeof useGetCandidatesApplicationsByStageLazyQuery
>
export type GetCandidatesApplicationsByStageSuspenseQueryHookResult = ReturnType<
  typeof useGetCandidatesApplicationsByStageSuspenseQuery
>
export type GetCandidatesApplicationsByStageQueryResult = Apollo.QueryResult<
  GetCandidatesApplicationsByStageQuery,
  GetCandidatesApplicationsByStageQueryVariables
>
export const GetRejectCandidateQuestionsDocument = gql`
  query GetRejectCandidateQuestions {
    GetRejectCandidateQuestions {
      sequence
      required
      question
      id
    }
  }
`

/**
 * __useGetRejectCandidateQuestionsQuery__
 *
 * To run a query within a React component, call `useGetRejectCandidateQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRejectCandidateQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRejectCandidateQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRejectCandidateQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRejectCandidateQuestionsQuery, GetRejectCandidateQuestionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRejectCandidateQuestionsQuery, GetRejectCandidateQuestionsQueryVariables>(
    GetRejectCandidateQuestionsDocument,
    options,
  )
}
export function useGetRejectCandidateQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRejectCandidateQuestionsQuery,
    GetRejectCandidateQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRejectCandidateQuestionsQuery, GetRejectCandidateQuestionsQueryVariables>(
    GetRejectCandidateQuestionsDocument,
    options,
  )
}
export function useGetRejectCandidateQuestionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRejectCandidateQuestionsQuery, GetRejectCandidateQuestionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRejectCandidateQuestionsQuery, GetRejectCandidateQuestionsQueryVariables>(
    GetRejectCandidateQuestionsDocument,
    options,
  )
}
export type GetRejectCandidateQuestionsQueryHookResult = ReturnType<typeof useGetRejectCandidateQuestionsQuery>
export type GetRejectCandidateQuestionsLazyQueryHookResult = ReturnType<typeof useGetRejectCandidateQuestionsLazyQuery>
export type GetRejectCandidateQuestionsSuspenseQueryHookResult = ReturnType<
  typeof useGetRejectCandidateQuestionsSuspenseQuery
>
export type GetRejectCandidateQuestionsQueryResult = Apollo.QueryResult<
  GetRejectCandidateQuestionsQuery,
  GetRejectCandidateQuestionsQueryVariables
>
export const SearchCandidatesFilterContentDocument = gql`
  query SearchCandidatesFilterContent(
    $jobListingId: Int
    $requisitionId: Int
    $publicListingId: String
    $filterType: FilterContentTypeEnum!
    $search: String!
  ) {
    SearchCandidatesFilterContent(
      filterType: $filterType
      search: $search
      requisitionId: $requisitionId
      jobListingId: $jobListingId
      publicListingId: $publicListingId
    ) {
      filterContent {
        value
        id
      }
    }
  }
`

/**
 * __useSearchCandidatesFilterContentQuery__
 *
 * To run a query within a React component, call `useSearchCandidatesFilterContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCandidatesFilterContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCandidatesFilterContentQuery({
 *   variables: {
 *      jobListingId: // value for 'jobListingId'
 *      requisitionId: // value for 'requisitionId'
 *      publicListingId: // value for 'publicListingId'
 *      filterType: // value for 'filterType'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchCandidatesFilterContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchCandidatesFilterContentQuery,
    SearchCandidatesFilterContentQueryVariables
  > &
    ({ variables: SearchCandidatesFilterContentQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchCandidatesFilterContentQuery, SearchCandidatesFilterContentQueryVariables>(
    SearchCandidatesFilterContentDocument,
    options,
  )
}
export function useSearchCandidatesFilterContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCandidatesFilterContentQuery,
    SearchCandidatesFilterContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchCandidatesFilterContentQuery, SearchCandidatesFilterContentQueryVariables>(
    SearchCandidatesFilterContentDocument,
    options,
  )
}
export function useSearchCandidatesFilterContentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SearchCandidatesFilterContentQuery, SearchCandidatesFilterContentQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchCandidatesFilterContentQuery, SearchCandidatesFilterContentQueryVariables>(
    SearchCandidatesFilterContentDocument,
    options,
  )
}
export type SearchCandidatesFilterContentQueryHookResult = ReturnType<typeof useSearchCandidatesFilterContentQuery>
export type SearchCandidatesFilterContentLazyQueryHookResult = ReturnType<
  typeof useSearchCandidatesFilterContentLazyQuery
>
export type SearchCandidatesFilterContentSuspenseQueryHookResult = ReturnType<
  typeof useSearchCandidatesFilterContentSuspenseQuery
>
export type SearchCandidatesFilterContentQueryResult = Apollo.QueryResult<
  SearchCandidatesFilterContentQuery,
  SearchCandidatesFilterContentQueryVariables
>
export const GetCandidateCollaboratorDocument = gql`
  query GetCandidateCollaborator($id: Int!) {
    GetCandidateCollaborator(id: $id) {
      ...CandidateCollaboratorFragment
      createdAt
      updatedAt
      role {
        ...RoleFragment
      }
    }
  }
  ${CandidateCollaboratorFragmentFragmentDoc}
  ${RoleFragmentFragmentDoc}
`

/**
 * __useGetCandidateCollaboratorQuery__
 *
 * To run a query within a React component, call `useGetCandidateCollaboratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateCollaboratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateCollaboratorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCandidateCollaboratorQuery(
  baseOptions: Apollo.QueryHookOptions<GetCandidateCollaboratorQuery, GetCandidateCollaboratorQueryVariables> &
    ({ variables: GetCandidateCollaboratorQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateCollaboratorQuery, GetCandidateCollaboratorQueryVariables>(
    GetCandidateCollaboratorDocument,
    options,
  )
}
export function useGetCandidateCollaboratorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateCollaboratorQuery, GetCandidateCollaboratorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateCollaboratorQuery, GetCandidateCollaboratorQueryVariables>(
    GetCandidateCollaboratorDocument,
    options,
  )
}
export function useGetCandidateCollaboratorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateCollaboratorQuery, GetCandidateCollaboratorQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateCollaboratorQuery, GetCandidateCollaboratorQueryVariables>(
    GetCandidateCollaboratorDocument,
    options,
  )
}
export type GetCandidateCollaboratorQueryHookResult = ReturnType<typeof useGetCandidateCollaboratorQuery>
export type GetCandidateCollaboratorLazyQueryHookResult = ReturnType<typeof useGetCandidateCollaboratorLazyQuery>
export type GetCandidateCollaboratorSuspenseQueryHookResult = ReturnType<
  typeof useGetCandidateCollaboratorSuspenseQuery
>
export type GetCandidateCollaboratorQueryResult = Apollo.QueryResult<
  GetCandidateCollaboratorQuery,
  GetCandidateCollaboratorQueryVariables
>
export const GetCandidateInterviewsDocument = gql`
  query GetCandidateInterviews($candidateProfileId: Int!) {
    GetOneCandidateProfile(id: $candidateProfileId) {
      CandidateApplication {
        ...GetCandidateInterviewsProfileApplication
      }
    }
  }
  ${GetCandidateInterviewsProfileApplicationFragmentDoc}
`

/**
 * __useGetCandidateInterviewsQuery__
 *
 * To run a query within a React component, call `useGetCandidateInterviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateInterviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateInterviewsQuery({
 *   variables: {
 *      candidateProfileId: // value for 'candidateProfileId'
 *   },
 * });
 */
export function useGetCandidateInterviewsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCandidateInterviewsQuery, GetCandidateInterviewsQueryVariables> &
    ({ variables: GetCandidateInterviewsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateInterviewsQuery, GetCandidateInterviewsQueryVariables>(
    GetCandidateInterviewsDocument,
    options,
  )
}
export function useGetCandidateInterviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateInterviewsQuery, GetCandidateInterviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateInterviewsQuery, GetCandidateInterviewsQueryVariables>(
    GetCandidateInterviewsDocument,
    options,
  )
}
export function useGetCandidateInterviewsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateInterviewsQuery, GetCandidateInterviewsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateInterviewsQuery, GetCandidateInterviewsQueryVariables>(
    GetCandidateInterviewsDocument,
    options,
  )
}
export type GetCandidateInterviewsQueryHookResult = ReturnType<typeof useGetCandidateInterviewsQuery>
export type GetCandidateInterviewsLazyQueryHookResult = ReturnType<typeof useGetCandidateInterviewsLazyQuery>
export type GetCandidateInterviewsSuspenseQueryHookResult = ReturnType<typeof useGetCandidateInterviewsSuspenseQuery>
export type GetCandidateInterviewsQueryResult = Apollo.QueryResult<
  GetCandidateInterviewsQuery,
  GetCandidateInterviewsQueryVariables
>
export const GetCandidateProfilesDocument = gql`
  query GetCandidateProfiles($candidateProfileIds: [Int!]!) {
    GetCandidateProfile(candidateProfileIds: $candidateProfileIds) {
      id
      uploadedCandidateId
      scalisUserId
      profileType
      ScalisUser {
        id
        Experience(onlyCurrentJob: true) {
          id
          employerName
          isCurrentlyWorking
          isCurrentJob
          jobTitle
        }
        Education(highestEducation: true) {
          id
          educationLevel
          institutionName
        }
        ContactInfo {
          phoneNumber
          id
        }
        EmailVerification {
          isVisibleToEmployers
          isPrimary
          emailVerified
          id
          emailAddress
          activeStatus
        }
        profileImage
        firstName
        lastName
      }
      UploadedCandidate {
        linkedinProfile
        lastName
        id
        firstName
        emailAddress
      }
    }
  }
`

/**
 * __useGetCandidateProfilesQuery__
 *
 * To run a query within a React component, call `useGetCandidateProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateProfilesQuery({
 *   variables: {
 *      candidateProfileIds: // value for 'candidateProfileIds'
 *   },
 * });
 */
export function useGetCandidateProfilesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCandidateProfilesQuery, GetCandidateProfilesQueryVariables> &
    ({ variables: GetCandidateProfilesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateProfilesQuery, GetCandidateProfilesQueryVariables>(
    GetCandidateProfilesDocument,
    options,
  )
}
export function useGetCandidateProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateProfilesQuery, GetCandidateProfilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateProfilesQuery, GetCandidateProfilesQueryVariables>(
    GetCandidateProfilesDocument,
    options,
  )
}
export function useGetCandidateProfilesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateProfilesQuery, GetCandidateProfilesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateProfilesQuery, GetCandidateProfilesQueryVariables>(
    GetCandidateProfilesDocument,
    options,
  )
}
export type GetCandidateProfilesQueryHookResult = ReturnType<typeof useGetCandidateProfilesQuery>
export type GetCandidateProfilesLazyQueryHookResult = ReturnType<typeof useGetCandidateProfilesLazyQuery>
export type GetCandidateProfilesSuspenseQueryHookResult = ReturnType<typeof useGetCandidateProfilesSuspenseQuery>
export type GetCandidateProfilesQueryResult = Apollo.QueryResult<
  GetCandidateProfilesQuery,
  GetCandidateProfilesQueryVariables
>
export const GetCandidateStatsDocument = gql`
  query GetCandidateStats($jobListingId: String!, $candidateProfileId: Int!) {
    GetCandidateStats(jobListingId: $jobListingId, candidateProfileId: $candidateProfileId) {
      skillsMatched
      screeningQuestions
      opportunity {
        candidateApplicationId
        applicationStatus
        appliedAt
        currentStageId
        jobListingId
        jobListingTitle
        lastUpdatedStatusAt
        location
        milestoneType
        requisitionId
        internalRequisitionId
        requisitionJobName
        stageName
        stages {
          id
          name
        }
      }
      matchPercentage
      experience
      distance
    }
  }
`

/**
 * __useGetCandidateStatsQuery__
 *
 * To run a query within a React component, call `useGetCandidateStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateStatsQuery({
 *   variables: {
 *      jobListingId: // value for 'jobListingId'
 *      candidateProfileId: // value for 'candidateProfileId'
 *   },
 * });
 */
export function useGetCandidateStatsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCandidateStatsQuery, GetCandidateStatsQueryVariables> &
    ({ variables: GetCandidateStatsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateStatsQuery, GetCandidateStatsQueryVariables>(GetCandidateStatsDocument, options)
}
export function useGetCandidateStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateStatsQuery, GetCandidateStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateStatsQuery, GetCandidateStatsQueryVariables>(
    GetCandidateStatsDocument,
    options,
  )
}
export function useGetCandidateStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateStatsQuery, GetCandidateStatsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateStatsQuery, GetCandidateStatsQueryVariables>(
    GetCandidateStatsDocument,
    options,
  )
}
export type GetCandidateStatsQueryHookResult = ReturnType<typeof useGetCandidateStatsQuery>
export type GetCandidateStatsLazyQueryHookResult = ReturnType<typeof useGetCandidateStatsLazyQuery>
export type GetCandidateStatsSuspenseQueryHookResult = ReturnType<typeof useGetCandidateStatsSuspenseQuery>
export type GetCandidateStatsQueryResult = Apollo.QueryResult<GetCandidateStatsQuery, GetCandidateStatsQueryVariables>
export const GetCompanyUsersUploadCandidateDocument = gql`
  query GetCompanyUsersUploadCandidate {
    GetCompanyUsers {
      id
      companyUserStatus
      UserInvitation {
        id
        ScalisUser {
          id
          firstName
          lastName
          activeStatus
        }
      }
    }
  }
`

/**
 * __useGetCompanyUsersUploadCandidateQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersUploadCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersUploadCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersUploadCandidateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyUsersUploadCandidateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyUsersUploadCandidateQuery,
    GetCompanyUsersUploadCandidateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyUsersUploadCandidateQuery, GetCompanyUsersUploadCandidateQueryVariables>(
    GetCompanyUsersUploadCandidateDocument,
    options,
  )
}
export function useGetCompanyUsersUploadCandidateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyUsersUploadCandidateQuery,
    GetCompanyUsersUploadCandidateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyUsersUploadCandidateQuery, GetCompanyUsersUploadCandidateQueryVariables>(
    GetCompanyUsersUploadCandidateDocument,
    options,
  )
}
export function useGetCompanyUsersUploadCandidateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCompanyUsersUploadCandidateQuery,
        GetCompanyUsersUploadCandidateQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyUsersUploadCandidateQuery, GetCompanyUsersUploadCandidateQueryVariables>(
    GetCompanyUsersUploadCandidateDocument,
    options,
  )
}
export type GetCompanyUsersUploadCandidateQueryHookResult = ReturnType<typeof useGetCompanyUsersUploadCandidateQuery>
export type GetCompanyUsersUploadCandidateLazyQueryHookResult = ReturnType<
  typeof useGetCompanyUsersUploadCandidateLazyQuery
>
export type GetCompanyUsersUploadCandidateSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyUsersUploadCandidateSuspenseQuery
>
export type GetCompanyUsersUploadCandidateQueryResult = Apollo.QueryResult<
  GetCompanyUsersUploadCandidateQuery,
  GetCompanyUsersUploadCandidateQueryVariables
>
export const GetOneCandidateProfileDocument = gql`
  query GetOneCandidateProfile($id: Int!) {
    GetOneCandidateProfile(id: $id) {
      id
      profileType
      uploadedCandidateId
      scalisUserId
      ScalisUser {
        id
        firstName
        lastName
        profileImage
        currentJob: Experience(onlyCurrentJob: true) {
          id
          employerName
          isCurrentlyWorking
          isCurrentJob
          jobTitle
        }
        highestEducation: Education(highestEducation: true) {
          id
          educationLevel
          institutionName
        }
        WebsiteOrSocialMedia {
          type
          websiteURL
        }
        ContactInfo {
          id
          phoneNumber
        }
        EmailVerification {
          id
          emailAddress
          isVisibleToEmployers
          isPrimary
          emailAddress
          activeStatus
        }
        JobSeekerHeader {
          id
          profileHeadline
          jobDisplayedId
          educationDisplayedId
        }
        Education {
          id
          institutionName
          educationLevel
          areasOfStudy
          minors
          majors
          endDate
          startDate
          cumGPA
          cumGPAScale
        }
        Experience {
          id
          jobTitle
          employerName
          longFormDesc
          startDate
          endDate
          jobType
          location
          locationType
          description
        }
        JobSeekerInfo {
          aboutMe
          id
        }
        VolunteerWork {
          id
          volunteerJobTitle
          organizationName
          startDate
          endDate
          isCurrentlyWorking
          description
          location
        }
        DemographicInfo {
          workHistory
          veteranStatus
          raceEthnicity
          id
          gender
        }
        JobSeekerSkill {
          id
          level
          name
          sequenceNumber
          yearsOfExp
        }
        CertificateOrLicense {
          id
          issuingAuthority
          name
          description
          issueYear
        }
        Achievement {
          id
          issuingAuthority
          description
          issueYear
          name
        }
        WebsiteOrSocialMedia {
          type
          websiteURL
          id
        }
        Address {
          id
          city
          state
        }
      }
      ProfileDetails {
        id
        secondaryEmail
        phoneNumber
        profileLink
        timezone
        CandidateSource {
          id
          candidateSourceName {
            name
            sourceCategory
          }
          companyUser {
            id
          }
          jobListing {
            id
            externalTitle
            Requisition {
              extRequisitionId
              jobName
            }
          }
        }
        CandidateProfileLocation {
          id
          city
          state
        }
        CandidateMedalistDesignation {
          id
          medalistDesignation
          jobListing {
            id
            externalTitle
          }
        }
      }
      CandidateApplication {
        id
        applicationStatus
        appliedAt
        lastUpdatedStatusAt
        JobListing {
          id
          publicListingId
          externalTitle
          listingTitle
          workplaceType
          stateAndOrCountry
          Address {
            id
            city
            state
            country
          }
          Requisition {
            id
            extRequisitionId
            jobName
          }
        }
        TemplateStage {
          id
          name
          TemplateMilestone {
            id
            name
          }
          TemplatePipeline {
            id
            TemplateMilestones {
              id
              rankOrder
              TemplateStage {
                id
                rankOrder
                name
              }
            }
          }
        }
        CandidateApplicationRating {
          id
          score
        }
        UploadedDocumentCandidateApplication {
          id
          updatedAt
          UploadedDocument {
            id
            documentPath
            documentName
            createdAt
          }
          CompanyUser {
            id
            profileImage
            UserInvitation {
              id
              ScalisUser {
                id
                firstName
                lastName
              }
            }
          }
        }
        CandidateApplicationNote {
          id
          privateStatus
          userNote {
            id
            noteText
            updatedAt
          }
          companyUser {
            id
            profileImage
            UserInvitation {
              id
              ScalisUser {
                id
                firstName
                lastName
              }
            }
          }
          candidateApplication {
            id
            JobListing {
              id
              publicListingId
            }
          }
        }
        ActivityLog {
          id
          activityTimestamp
          activity
          userAction
        }
      }
      CandidateApplicationRating {
        id
        score
      }
      UploadedDocumentCandidateProfile {
        id
        updatedAt
        UploadedDocument {
          id
          documentPath
          documentName
          createdAt
        }
        CompanyUser {
          id
          profileImage
          UserInvitation {
            id
            ScalisUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      CandidateProfileNote {
        id
        privateStatus
        userNote {
          id
          noteText
          updatedAt
        }
        companyUser {
          id
          profileImage
          UserInvitation {
            id
            ScalisUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      UploadedCandidate {
        linkedinProfile
        lastName
        id
        firstName
        emailAddress
      }
    }
  }
`

/**
 * __useGetOneCandidateProfileQuery__
 *
 * To run a query within a React component, call `useGetOneCandidateProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneCandidateProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneCandidateProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneCandidateProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetOneCandidateProfileQuery, GetOneCandidateProfileQueryVariables> &
    ({ variables: GetOneCandidateProfileQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOneCandidateProfileQuery, GetOneCandidateProfileQueryVariables>(
    GetOneCandidateProfileDocument,
    options,
  )
}
export function useGetOneCandidateProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOneCandidateProfileQuery, GetOneCandidateProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOneCandidateProfileQuery, GetOneCandidateProfileQueryVariables>(
    GetOneCandidateProfileDocument,
    options,
  )
}
export function useGetOneCandidateProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetOneCandidateProfileQuery, GetOneCandidateProfileQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetOneCandidateProfileQuery, GetOneCandidateProfileQueryVariables>(
    GetOneCandidateProfileDocument,
    options,
  )
}
export type GetOneCandidateProfileQueryHookResult = ReturnType<typeof useGetOneCandidateProfileQuery>
export type GetOneCandidateProfileLazyQueryHookResult = ReturnType<typeof useGetOneCandidateProfileLazyQuery>
export type GetOneCandidateProfileSuspenseQueryHookResult = ReturnType<typeof useGetOneCandidateProfileSuspenseQuery>
export type GetOneCandidateProfileQueryResult = Apollo.QueryResult<
  GetOneCandidateProfileQuery,
  GetOneCandidateProfileQueryVariables
>
export const GetTalentPoolDocument = gql`
  query GetTalentPool($limit: Int, $page: Int, $applicationFilters: ApplicationFiltersOnCandidateProfile) {
    GetCandidateProfile(page: $page, limit: $limit, applicationFilters: $applicationFilters) {
      id
      ScalisUser {
        id
        firstName
        lastName
        profileImage
        mostRecentJob: Experience(onlyMostRecentJob: true) {
          id
          industry
          jobTitle
          employerName
        }
        currentJob: Experience(onlyCurrentJob: true) {
          id
          jobTitle
          employerName
        }
        highestEducation: Education(highestEducation: true) {
          id
          educationLevel
          institutionName
        }
        Address {
          country
          city
          state
        }
        EmailVerification {
          emailAddress
          isVisibleToEmployers
        }
        JobSeekerInfo {
          jobSeekerId
        }
        ExperienceTenure {
          average
          longest
          shortest
        }
      }
      UploadedCandidate {
        emailAddress
        firstName
        id
        lastName
      }
      ProfileDetails {
        phoneNumber
        CandidateSource {
          candidateSourceName {
            name
            sourceCategory
          }
        }
      }
      CandidateApplication {
        id
        matchScore
        templateStageId
        applicationType
        updatedAt
        lastUpdatedStatusAt
        JobListing {
          publicListingId
          id
        }
        applicationStatus
        applicationCreationType
        appliedAt
      }
    }
    CountCandidateProfile(applicationFilters: $applicationFilters) {
      count
    }
  }
`

/**
 * __useGetTalentPoolQuery__
 *
 * To run a query within a React component, call `useGetTalentPoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentPoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentPoolQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      applicationFilters: // value for 'applicationFilters'
 *   },
 * });
 */
export function useGetTalentPoolQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTalentPoolQuery, GetTalentPoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTalentPoolQuery, GetTalentPoolQueryVariables>(GetTalentPoolDocument, options)
}
export function useGetTalentPoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTalentPoolQuery, GetTalentPoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTalentPoolQuery, GetTalentPoolQueryVariables>(GetTalentPoolDocument, options)
}
export function useGetTalentPoolSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTalentPoolQuery, GetTalentPoolQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTalentPoolQuery, GetTalentPoolQueryVariables>(GetTalentPoolDocument, options)
}
export type GetTalentPoolQueryHookResult = ReturnType<typeof useGetTalentPoolQuery>
export type GetTalentPoolLazyQueryHookResult = ReturnType<typeof useGetTalentPoolLazyQuery>
export type GetTalentPoolSuspenseQueryHookResult = ReturnType<typeof useGetTalentPoolSuspenseQuery>
export type GetTalentPoolQueryResult = Apollo.QueryResult<GetTalentPoolQuery, GetTalentPoolQueryVariables>
export const GetCandidateSourceNamesDocument = gql`
  query GetCandidateSourceNames($input: CandidateSourceNameInput!) {
    GetCandidateSourceNames(input: $input) {
      name
      id
      sourceCategory
    }
  }
`

/**
 * __useGetCandidateSourceNamesQuery__
 *
 * To run a query within a React component, call `useGetCandidateSourceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateSourceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateSourceNamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCandidateSourceNamesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCandidateSourceNamesQuery, GetCandidateSourceNamesQueryVariables> &
    ({ variables: GetCandidateSourceNamesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateSourceNamesQuery, GetCandidateSourceNamesQueryVariables>(
    GetCandidateSourceNamesDocument,
    options,
  )
}
export function useGetCandidateSourceNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateSourceNamesQuery, GetCandidateSourceNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateSourceNamesQuery, GetCandidateSourceNamesQueryVariables>(
    GetCandidateSourceNamesDocument,
    options,
  )
}
export function useGetCandidateSourceNamesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateSourceNamesQuery, GetCandidateSourceNamesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateSourceNamesQuery, GetCandidateSourceNamesQueryVariables>(
    GetCandidateSourceNamesDocument,
    options,
  )
}
export type GetCandidateSourceNamesQueryHookResult = ReturnType<typeof useGetCandidateSourceNamesQuery>
export type GetCandidateSourceNamesLazyQueryHookResult = ReturnType<typeof useGetCandidateSourceNamesLazyQuery>
export type GetCandidateSourceNamesSuspenseQueryHookResult = ReturnType<typeof useGetCandidateSourceNamesSuspenseQuery>
export type GetCandidateSourceNamesQueryResult = Apollo.QueryResult<
  GetCandidateSourceNamesQuery,
  GetCandidateSourceNamesQueryVariables
>
export const ListCompanyUserGroupsDocument = gql`
  query ListCompanyUserGroups($input: ListCompanyUserGroupsInput!) {
    ListCompanyUserGroups(input: $input) {
      id
      name
      externalId
      createdAt
      updatedAt
      deleted
      createdBy {
        id
        profileImage
        UserInvitation {
          ScalisUser {
            firstName
            lastName
            EmailVerification {
              emailAddress
            }
          }
        }
      }
      Offices {
        CompanyOffice {
          id
          officeName
          externalId
        }
      }
      Departments {
        CompanyDepartment {
          id
          departmentName
          externalId
        }
      }
      Members {
        CompanyUser {
          id
          profileImage
          UserInvitation {
            ScalisUser {
              firstName
              lastName
              EmailVerification {
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useListCompanyUserGroupsQuery__
 *
 * To run a query within a React component, call `useListCompanyUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyUserGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCompanyUserGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<ListCompanyUserGroupsQuery, ListCompanyUserGroupsQueryVariables> &
    ({ variables: ListCompanyUserGroupsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListCompanyUserGroupsQuery, ListCompanyUserGroupsQueryVariables>(
    ListCompanyUserGroupsDocument,
    options,
  )
}
export function useListCompanyUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCompanyUserGroupsQuery, ListCompanyUserGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListCompanyUserGroupsQuery, ListCompanyUserGroupsQueryVariables>(
    ListCompanyUserGroupsDocument,
    options,
  )
}
export function useListCompanyUserGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListCompanyUserGroupsQuery, ListCompanyUserGroupsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListCompanyUserGroupsQuery, ListCompanyUserGroupsQueryVariables>(
    ListCompanyUserGroupsDocument,
    options,
  )
}
export type ListCompanyUserGroupsQueryHookResult = ReturnType<typeof useListCompanyUserGroupsQuery>
export type ListCompanyUserGroupsLazyQueryHookResult = ReturnType<typeof useListCompanyUserGroupsLazyQuery>
export type ListCompanyUserGroupsSuspenseQueryHookResult = ReturnType<typeof useListCompanyUserGroupsSuspenseQuery>
export type ListCompanyUserGroupsQueryResult = Apollo.QueryResult<
  ListCompanyUserGroupsQuery,
  ListCompanyUserGroupsQueryVariables
>
export const GetInterviewerCalendarsDocument = gql`
  query GetInterviewerCalendars {
    GetCalendarSettings {
      Calendars {
        id
        nylasCalendarId
        name
        isPrimary
        color
        calendarSettingsId
      }
      id
      timeZone
      provider
      email
      companyUserId
    }
    GetCompanyUsers {
      id
      profileImage
      companyId
      UserInvitation {
        id
        email
        ScalisUser {
          firstName
          id
          lastName
        }
      }
      CalendarSettings {
        id
        availability
        provider
        timeZone
        hourDisplay
        email
        Calendars {
          id
          nylasCalendarId
          calendarSettingsId
          name
          isPrimary
          color
          CalendarEvents {
            id
            nylasEventStatus
            nylasCalendarEventId
            title
            eventType
            description
            startTime
            endTime
            timeZone
            location
            repeats
            reminders
            employers
            conferencing
            masterEventId
            regardingCandidates
            regardingJobs
            internalGuests
            candidates
            isScalisEvent
            CandidateInterview {
              stageId
              status
              TemplateStage {
                TemplateMilestone {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetInterviewerCalendarsQuery__
 *
 * To run a query within a React component, call `useGetInterviewerCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewerCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewerCalendarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInterviewerCalendarsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInterviewerCalendarsQuery, GetInterviewerCalendarsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInterviewerCalendarsQuery, GetInterviewerCalendarsQueryVariables>(
    GetInterviewerCalendarsDocument,
    options,
  )
}
export function useGetInterviewerCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewerCalendarsQuery, GetInterviewerCalendarsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInterviewerCalendarsQuery, GetInterviewerCalendarsQueryVariables>(
    GetInterviewerCalendarsDocument,
    options,
  )
}
export function useGetInterviewerCalendarsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetInterviewerCalendarsQuery, GetInterviewerCalendarsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetInterviewerCalendarsQuery, GetInterviewerCalendarsQueryVariables>(
    GetInterviewerCalendarsDocument,
    options,
  )
}
export type GetInterviewerCalendarsQueryHookResult = ReturnType<typeof useGetInterviewerCalendarsQuery>
export type GetInterviewerCalendarsLazyQueryHookResult = ReturnType<typeof useGetInterviewerCalendarsLazyQuery>
export type GetInterviewerCalendarsSuspenseQueryHookResult = ReturnType<typeof useGetInterviewerCalendarsSuspenseQuery>
export type GetInterviewerCalendarsQueryResult = Apollo.QueryResult<
  GetInterviewerCalendarsQuery,
  GetInterviewerCalendarsQueryVariables
>
export const GetStageCompanyUsersDocument = gql`
  query GetStageCompanyUsers {
    GetCompanyUsers {
      __typename
      id
      companyId
      profileImage
      UserInvitation {
        __typename
        id
        email
        ScalisUser {
          __typename
          id
          firstName
          lastName
        }
      }
    }
  }
`

/**
 * __useGetStageCompanyUsersQuery__
 *
 * To run a query within a React component, call `useGetStageCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStageCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStageCompanyUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStageCompanyUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStageCompanyUsersQuery, GetStageCompanyUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetStageCompanyUsersQuery, GetStageCompanyUsersQueryVariables>(
    GetStageCompanyUsersDocument,
    options,
  )
}
export function useGetStageCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStageCompanyUsersQuery, GetStageCompanyUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetStageCompanyUsersQuery, GetStageCompanyUsersQueryVariables>(
    GetStageCompanyUsersDocument,
    options,
  )
}
export function useGetStageCompanyUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStageCompanyUsersQuery, GetStageCompanyUsersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetStageCompanyUsersQuery, GetStageCompanyUsersQueryVariables>(
    GetStageCompanyUsersDocument,
    options,
  )
}
export type GetStageCompanyUsersQueryHookResult = ReturnType<typeof useGetStageCompanyUsersQuery>
export type GetStageCompanyUsersLazyQueryHookResult = ReturnType<typeof useGetStageCompanyUsersLazyQuery>
export type GetStageCompanyUsersSuspenseQueryHookResult = ReturnType<typeof useGetStageCompanyUsersSuspenseQuery>
export type GetStageCompanyUsersQueryResult = Apollo.QueryResult<
  GetStageCompanyUsersQuery,
  GetStageCompanyUsersQueryVariables
>
export const GetCompanyDepartmentsDocument = gql`
  query GetCompanyDepartments($companyId: Int) {
    GetCompanyInfo(id: $companyId) {
      CompanyDepartment {
        ParentCompanyDepartment {
          departmentName
          id
        }
        type
        subordinateTo
        id
        externalId
        departmentName
      }
    }
  }
`

/**
 * __useGetCompanyDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDepartmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyDepartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyDepartmentsQuery, GetCompanyDepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyDepartmentsQuery, GetCompanyDepartmentsQueryVariables>(
    GetCompanyDepartmentsDocument,
    options,
  )
}
export function useGetCompanyDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyDepartmentsQuery, GetCompanyDepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyDepartmentsQuery, GetCompanyDepartmentsQueryVariables>(
    GetCompanyDepartmentsDocument,
    options,
  )
}
export function useGetCompanyDepartmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyDepartmentsQuery, GetCompanyDepartmentsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyDepartmentsQuery, GetCompanyDepartmentsQueryVariables>(
    GetCompanyDepartmentsDocument,
    options,
  )
}
export type GetCompanyDepartmentsQueryHookResult = ReturnType<typeof useGetCompanyDepartmentsQuery>
export type GetCompanyDepartmentsLazyQueryHookResult = ReturnType<typeof useGetCompanyDepartmentsLazyQuery>
export type GetCompanyDepartmentsSuspenseQueryHookResult = ReturnType<typeof useGetCompanyDepartmentsSuspenseQuery>
export type GetCompanyDepartmentsQueryResult = Apollo.QueryResult<
  GetCompanyDepartmentsQuery,
  GetCompanyDepartmentsQueryVariables
>
export const GetCompanyInfoSettingsFormDocument = gql`
  query GetCompanyInfoSettingsForm($id: Int) {
    GetCompanyInfo(id: $id) {
      subdomain
      id
      CompanyInfo {
        logo
        companyType
        companyName
        industry
        subIndustry
        phoneNumber
        website
        linkedin
        linkedinId
        employeeCount
        yearsInOperation
      }
      Address {
        id
        activeStatus
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
      }
    }
  }
`

/**
 * __useGetCompanyInfoSettingsFormQuery__
 *
 * To run a query within a React component, call `useGetCompanyInfoSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInfoSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyInfoSettingsFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyInfoSettingsFormQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyInfoSettingsFormQuery, GetCompanyInfoSettingsFormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyInfoSettingsFormQuery, GetCompanyInfoSettingsFormQueryVariables>(
    GetCompanyInfoSettingsFormDocument,
    options,
  )
}
export function useGetCompanyInfoSettingsFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyInfoSettingsFormQuery, GetCompanyInfoSettingsFormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyInfoSettingsFormQuery, GetCompanyInfoSettingsFormQueryVariables>(
    GetCompanyInfoSettingsFormDocument,
    options,
  )
}
export function useGetCompanyInfoSettingsFormSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyInfoSettingsFormQuery, GetCompanyInfoSettingsFormQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyInfoSettingsFormQuery, GetCompanyInfoSettingsFormQueryVariables>(
    GetCompanyInfoSettingsFormDocument,
    options,
  )
}
export type GetCompanyInfoSettingsFormQueryHookResult = ReturnType<typeof useGetCompanyInfoSettingsFormQuery>
export type GetCompanyInfoSettingsFormLazyQueryHookResult = ReturnType<typeof useGetCompanyInfoSettingsFormLazyQuery>
export type GetCompanyInfoSettingsFormSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyInfoSettingsFormSuspenseQuery
>
export type GetCompanyInfoSettingsFormQueryResult = Apollo.QueryResult<
  GetCompanyInfoSettingsFormQuery,
  GetCompanyInfoSettingsFormQueryVariables
>
export const GetCompanyOfficesDocument = gql`
  query GetCompanyOffices($companyId: Int) {
    GetCompanyInfo(id: $companyId) {
      CompanyOffice {
        Address {
          activeStatus
          addressLine1
          addressLine2
          addressType
          city
          country
          timeZone
          state
          postalCode
          id
        }
        externalId
        officeName
        id
      }
    }
  }
`

/**
 * __useGetCompanyOfficesQuery__
 *
 * To run a query within a React component, call `useGetCompanyOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOfficesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyOfficesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyOfficesQuery, GetCompanyOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyOfficesQuery, GetCompanyOfficesQueryVariables>(GetCompanyOfficesDocument, options)
}
export function useGetCompanyOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyOfficesQuery, GetCompanyOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyOfficesQuery, GetCompanyOfficesQueryVariables>(
    GetCompanyOfficesDocument,
    options,
  )
}
export function useGetCompanyOfficesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyOfficesQuery, GetCompanyOfficesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyOfficesQuery, GetCompanyOfficesQueryVariables>(
    GetCompanyOfficesDocument,
    options,
  )
}
export type GetCompanyOfficesQueryHookResult = ReturnType<typeof useGetCompanyOfficesQuery>
export type GetCompanyOfficesLazyQueryHookResult = ReturnType<typeof useGetCompanyOfficesLazyQuery>
export type GetCompanyOfficesSuspenseQueryHookResult = ReturnType<typeof useGetCompanyOfficesSuspenseQuery>
export type GetCompanyOfficesQueryResult = Apollo.QueryResult<GetCompanyOfficesQuery, GetCompanyOfficesQueryVariables>
export const GetCompanyUsersCandidateProfileDocument = gql`
  query GetCompanyUsersCandidateProfile {
    GetCompanyUsers {
      id
      companyUserStatus
      UserInvitation {
        id
        ScalisUser {
          id
          firstName
          lastName
          activeStatus
        }
      }
    }
  }
`

/**
 * __useGetCompanyUsersCandidateProfileQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersCandidateProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersCandidateProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersCandidateProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyUsersCandidateProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyUsersCandidateProfileQuery,
    GetCompanyUsersCandidateProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyUsersCandidateProfileQuery, GetCompanyUsersCandidateProfileQueryVariables>(
    GetCompanyUsersCandidateProfileDocument,
    options,
  )
}
export function useGetCompanyUsersCandidateProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyUsersCandidateProfileQuery,
    GetCompanyUsersCandidateProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyUsersCandidateProfileQuery, GetCompanyUsersCandidateProfileQueryVariables>(
    GetCompanyUsersCandidateProfileDocument,
    options,
  )
}
export function useGetCompanyUsersCandidateProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCompanyUsersCandidateProfileQuery,
        GetCompanyUsersCandidateProfileQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyUsersCandidateProfileQuery, GetCompanyUsersCandidateProfileQueryVariables>(
    GetCompanyUsersCandidateProfileDocument,
    options,
  )
}
export type GetCompanyUsersCandidateProfileQueryHookResult = ReturnType<typeof useGetCompanyUsersCandidateProfileQuery>
export type GetCompanyUsersCandidateProfileLazyQueryHookResult = ReturnType<
  typeof useGetCompanyUsersCandidateProfileLazyQuery
>
export type GetCompanyUsersCandidateProfileSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyUsersCandidateProfileSuspenseQuery
>
export type GetCompanyUsersCandidateProfileQueryResult = Apollo.QueryResult<
  GetCompanyUsersCandidateProfileQuery,
  GetCompanyUsersCandidateProfileQueryVariables
>
export const GetCompanyUsersDocument = gql`
  query GetCompanyUsers {
    GetCompanyUsers {
      id
      companyId
      companyUserStatus
      profileImage
      UserRoles {
        roleId
        userId
        role {
          id
          name
        }
      }
      UserInvitation {
        id
        email
        invitedAs
        invitationStatus
        ScalisUser {
          id
          firstName
          lastName
          activeStatus
          profileImage
        }
      }
    }
  }
`

/**
 * __useGetCompanyUsersQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, options)
}
export function useGetCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, options)
}
export function useGetCompanyUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, options)
}
export type GetCompanyUsersQueryHookResult = ReturnType<typeof useGetCompanyUsersQuery>
export type GetCompanyUsersLazyQueryHookResult = ReturnType<typeof useGetCompanyUsersLazyQuery>
export type GetCompanyUsersSuspenseQueryHookResult = ReturnType<typeof useGetCompanyUsersSuspenseQuery>
export type GetCompanyUsersQueryResult = Apollo.QueryResult<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>
export const GetCompanyWithSubdomainDocument = gql`
  query GetCompanyWithSubdomain($subdomain: String) {
    GetCompanyInfo(subdomain: $subdomain) {
      id
      subdomain
      CompanyInfo {
        id
        logo
        companyName
      }
    }
  }
`

/**
 * __useGetCompanyWithSubdomainQuery__
 *
 * To run a query within a React component, call `useGetCompanyWithSubdomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWithSubdomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWithSubdomainQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *   },
 * });
 */
export function useGetCompanyWithSubdomainQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyWithSubdomainQuery, GetCompanyWithSubdomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyWithSubdomainQuery, GetCompanyWithSubdomainQueryVariables>(
    GetCompanyWithSubdomainDocument,
    options,
  )
}
export function useGetCompanyWithSubdomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyWithSubdomainQuery, GetCompanyWithSubdomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyWithSubdomainQuery, GetCompanyWithSubdomainQueryVariables>(
    GetCompanyWithSubdomainDocument,
    options,
  )
}
export function useGetCompanyWithSubdomainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyWithSubdomainQuery, GetCompanyWithSubdomainQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyWithSubdomainQuery, GetCompanyWithSubdomainQueryVariables>(
    GetCompanyWithSubdomainDocument,
    options,
  )
}
export type GetCompanyWithSubdomainQueryHookResult = ReturnType<typeof useGetCompanyWithSubdomainQuery>
export type GetCompanyWithSubdomainLazyQueryHookResult = ReturnType<typeof useGetCompanyWithSubdomainLazyQuery>
export type GetCompanyWithSubdomainSuspenseQueryHookResult = ReturnType<typeof useGetCompanyWithSubdomainSuspenseQuery>
export type GetCompanyWithSubdomainQueryResult = Apollo.QueryResult<
  GetCompanyWithSubdomainQuery,
  GetCompanyWithSubdomainQueryVariables
>
export const GetUserInvitationsDocument = gql`
  query GetUserInvitations {
    GetUserInvitations {
      scalisUserId
      invitedAs
      invitationStatus
      id
      email
      deletedAt
      companyId
      CompanyUser {
        UserRoles {
          userId
          roleId
          role {
            id
            name
          }
        }
        companyId
        companyUserStatus
        id
        profileImage
        userInvitationId
      }
      ScalisUser {
        firstName
        lastName
      }
    }
  }
`

/**
 * __useGetUserInvitationsQuery__
 *
 * To run a query within a React component, call `useGetUserInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>(GetUserInvitationsDocument, options)
}
export function useGetUserInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>(
    GetUserInvitationsDocument,
    options,
  )
}
export function useGetUserInvitationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>(
    GetUserInvitationsDocument,
    options,
  )
}
export type GetUserInvitationsQueryHookResult = ReturnType<typeof useGetUserInvitationsQuery>
export type GetUserInvitationsLazyQueryHookResult = ReturnType<typeof useGetUserInvitationsLazyQuery>
export type GetUserInvitationsSuspenseQueryHookResult = ReturnType<typeof useGetUserInvitationsSuspenseQuery>
export type GetUserInvitationsQueryResult = Apollo.QueryResult<
  GetUserInvitationsQuery,
  GetUserInvitationsQueryVariables
>
export const ListCompanyDepartmentsDocument = gql`
  query ListCompanyDepartments($id: Int) {
    ListCompanyDepartments(id: $id) {
      id
      companyId
      departmentName
      subordinateTo
      externalId
      createdAt
      updatedAt
    }
  }
`

/**
 * __useListCompanyDepartmentsQuery__
 *
 * To run a query within a React component, call `useListCompanyDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyDepartmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListCompanyDepartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCompanyDepartmentsQuery, ListCompanyDepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListCompanyDepartmentsQuery, ListCompanyDepartmentsQueryVariables>(
    ListCompanyDepartmentsDocument,
    options,
  )
}
export function useListCompanyDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCompanyDepartmentsQuery, ListCompanyDepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListCompanyDepartmentsQuery, ListCompanyDepartmentsQueryVariables>(
    ListCompanyDepartmentsDocument,
    options,
  )
}
export function useListCompanyDepartmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListCompanyDepartmentsQuery, ListCompanyDepartmentsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListCompanyDepartmentsQuery, ListCompanyDepartmentsQueryVariables>(
    ListCompanyDepartmentsDocument,
    options,
  )
}
export type ListCompanyDepartmentsQueryHookResult = ReturnType<typeof useListCompanyDepartmentsQuery>
export type ListCompanyDepartmentsLazyQueryHookResult = ReturnType<typeof useListCompanyDepartmentsLazyQuery>
export type ListCompanyDepartmentsSuspenseQueryHookResult = ReturnType<typeof useListCompanyDepartmentsSuspenseQuery>
export type ListCompanyDepartmentsQueryResult = Apollo.QueryResult<
  ListCompanyDepartmentsQuery,
  ListCompanyDepartmentsQueryVariables
>
export const ListCompanyOfficesDocument = gql`
  query ListCompanyOffices($id: Int) {
    ListCompanyOffices(id: $id) {
      id
      companyId
      officeName
      externalId
      addressId
      createdAt
      updatedAt
      Address {
        id
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
        timeZone
      }
    }
  }
`

/**
 * __useListCompanyOfficesQuery__
 *
 * To run a query within a React component, call `useListCompanyOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyOfficesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListCompanyOfficesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCompanyOfficesQuery, ListCompanyOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListCompanyOfficesQuery, ListCompanyOfficesQueryVariables>(ListCompanyOfficesDocument, options)
}
export function useListCompanyOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCompanyOfficesQuery, ListCompanyOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListCompanyOfficesQuery, ListCompanyOfficesQueryVariables>(
    ListCompanyOfficesDocument,
    options,
  )
}
export function useListCompanyOfficesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListCompanyOfficesQuery, ListCompanyOfficesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListCompanyOfficesQuery, ListCompanyOfficesQueryVariables>(
    ListCompanyOfficesDocument,
    options,
  )
}
export type ListCompanyOfficesQueryHookResult = ReturnType<typeof useListCompanyOfficesQuery>
export type ListCompanyOfficesLazyQueryHookResult = ReturnType<typeof useListCompanyOfficesLazyQuery>
export type ListCompanyOfficesSuspenseQueryHookResult = ReturnType<typeof useListCompanyOfficesSuspenseQuery>
export type ListCompanyOfficesQueryResult = Apollo.QueryResult<
  ListCompanyOfficesQuery,
  ListCompanyOfficesQueryVariables
>
export const DeleteOfferCustomFieldDocument = gql`
  mutation DeleteOfferCustomField($id: Int!) {
    DeleteOfferCustomField(input: { id: $id }) {
      id
    }
  }
`
export type DeleteOfferCustomFieldMutationFn = Apollo.MutationFunction<
  DeleteOfferCustomFieldMutation,
  DeleteOfferCustomFieldMutationVariables
>

/**
 * __useDeleteOfferCustomFieldMutation__
 *
 * To run a mutation, you first call `useDeleteOfferCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferCustomFieldMutation, { data, loading, error }] = useDeleteOfferCustomFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfferCustomFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOfferCustomFieldMutation, DeleteOfferCustomFieldMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOfferCustomFieldMutation, DeleteOfferCustomFieldMutationVariables>(
    DeleteOfferCustomFieldDocument,
    options,
  )
}
export type DeleteOfferCustomFieldMutationHookResult = ReturnType<typeof useDeleteOfferCustomFieldMutation>
export type DeleteOfferCustomFieldMutationResult = Apollo.MutationResult<DeleteOfferCustomFieldMutation>
export type DeleteOfferCustomFieldMutationOptions = Apollo.BaseMutationOptions<
  DeleteOfferCustomFieldMutation,
  DeleteOfferCustomFieldMutationVariables
>
export const GetOfferCustomFieldDocument = gql`
  query GetOfferCustomField {
    GetOfferCustomField(input: {}) {
      ...OfferCustomField
      CompanyUser {
        id
        UserInvitation {
          id
          ScalisUser {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
    }
  }
  ${OfferCustomFieldFragmentDoc}
`

/**
 * __useGetOfferCustomFieldQuery__
 *
 * To run a query within a React component, call `useGetOfferCustomFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferCustomFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferCustomFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOfferCustomFieldQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOfferCustomFieldQuery, GetOfferCustomFieldQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOfferCustomFieldQuery, GetOfferCustomFieldQueryVariables>(
    GetOfferCustomFieldDocument,
    options,
  )
}
export function useGetOfferCustomFieldLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfferCustomFieldQuery, GetOfferCustomFieldQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOfferCustomFieldQuery, GetOfferCustomFieldQueryVariables>(
    GetOfferCustomFieldDocument,
    options,
  )
}
export function useGetOfferCustomFieldSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetOfferCustomFieldQuery, GetOfferCustomFieldQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetOfferCustomFieldQuery, GetOfferCustomFieldQueryVariables>(
    GetOfferCustomFieldDocument,
    options,
  )
}
export type GetOfferCustomFieldQueryHookResult = ReturnType<typeof useGetOfferCustomFieldQuery>
export type GetOfferCustomFieldLazyQueryHookResult = ReturnType<typeof useGetOfferCustomFieldLazyQuery>
export type GetOfferCustomFieldSuspenseQueryHookResult = ReturnType<typeof useGetOfferCustomFieldSuspenseQuery>
export type GetOfferCustomFieldQueryResult = Apollo.QueryResult<
  GetOfferCustomFieldQuery,
  GetOfferCustomFieldQueryVariables
>
export const UpsertOfferCustomFieldDocument = gql`
  mutation UpsertOfferCustomField($input: UpsertOfferCustomFieldInput!) {
    UpsertOfferCustomField(input: $input) {
      ...OfferCustomField
    }
  }
  ${OfferCustomFieldFragmentDoc}
`
export type UpsertOfferCustomFieldMutationFn = Apollo.MutationFunction<
  UpsertOfferCustomFieldMutation,
  UpsertOfferCustomFieldMutationVariables
>

/**
 * __useUpsertOfferCustomFieldMutation__
 *
 * To run a mutation, you first call `useUpsertOfferCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOfferCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOfferCustomFieldMutation, { data, loading, error }] = useUpsertOfferCustomFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertOfferCustomFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertOfferCustomFieldMutation, UpsertOfferCustomFieldMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertOfferCustomFieldMutation, UpsertOfferCustomFieldMutationVariables>(
    UpsertOfferCustomFieldDocument,
    options,
  )
}
export type UpsertOfferCustomFieldMutationHookResult = ReturnType<typeof useUpsertOfferCustomFieldMutation>
export type UpsertOfferCustomFieldMutationResult = Apollo.MutationResult<UpsertOfferCustomFieldMutation>
export type UpsertOfferCustomFieldMutationOptions = Apollo.BaseMutationOptions<
  UpsertOfferCustomFieldMutation,
  UpsertOfferCustomFieldMutationVariables
>
export const UpsertOfferTemplateConditionDocument = gql`
  mutation UpsertOfferTemplateCondition($input: [UpsertOfferTemplateConditionInput!]!) {
    UpsertOfferTemplateCondition(input: $input) {
      id
      conditionType
      customFieldId
      departmentId
      employmentType
      offerDocumentTemplateId
      officeId
      payType
      sectionTemplateId
    }
  }
`
export type UpsertOfferTemplateConditionMutationFn = Apollo.MutationFunction<
  UpsertOfferTemplateConditionMutation,
  UpsertOfferTemplateConditionMutationVariables
>

/**
 * __useUpsertOfferTemplateConditionMutation__
 *
 * To run a mutation, you first call `useUpsertOfferTemplateConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOfferTemplateConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOfferTemplateConditionMutation, { data, loading, error }] = useUpsertOfferTemplateConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertOfferTemplateConditionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertOfferTemplateConditionMutation,
    UpsertOfferTemplateConditionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertOfferTemplateConditionMutation, UpsertOfferTemplateConditionMutationVariables>(
    UpsertOfferTemplateConditionDocument,
    options,
  )
}
export type UpsertOfferTemplateConditionMutationHookResult = ReturnType<typeof useUpsertOfferTemplateConditionMutation>
export type UpsertOfferTemplateConditionMutationResult = Apollo.MutationResult<UpsertOfferTemplateConditionMutation>
export type UpsertOfferTemplateConditionMutationOptions = Apollo.BaseMutationOptions<
  UpsertOfferTemplateConditionMutation,
  UpsertOfferTemplateConditionMutationVariables
>
export const CreateDocumentTemplateDocument = gql`
  mutation CreateDocumentTemplate($input: CreateDocumentTemplateInput!) {
    CreateDocumentTemplate(input: $input) {
      id
    }
  }
`
export type CreateDocumentTemplateMutationFn = Apollo.MutationFunction<
  CreateDocumentTemplateMutation,
  CreateDocumentTemplateMutationVariables
>

/**
 * __useCreateDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useCreateDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentTemplateMutation, { data, loading, error }] = useCreateDocumentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDocumentTemplateMutation, CreateDocumentTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateDocumentTemplateMutation, CreateDocumentTemplateMutationVariables>(
    CreateDocumentTemplateDocument,
    options,
  )
}
export type CreateDocumentTemplateMutationHookResult = ReturnType<typeof useCreateDocumentTemplateMutation>
export type CreateDocumentTemplateMutationResult = Apollo.MutationResult<CreateDocumentTemplateMutation>
export type CreateDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentTemplateMutation,
  CreateDocumentTemplateMutationVariables
>
export const DeleteOfferDocumentTemplateDocument = gql`
  mutation DeleteOfferDocumentTemplate($id: Int!) {
    DeleteOfferDocumentTemplate(input: { id: $id }) {
      id
    }
  }
`
export type DeleteOfferDocumentTemplateMutationFn = Apollo.MutationFunction<
  DeleteOfferDocumentTemplateMutation,
  DeleteOfferDocumentTemplateMutationVariables
>

/**
 * __useDeleteOfferDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteOfferDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferDocumentTemplateMutation, { data, loading, error }] = useDeleteOfferDocumentTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfferDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOfferDocumentTemplateMutation,
    DeleteOfferDocumentTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOfferDocumentTemplateMutation, DeleteOfferDocumentTemplateMutationVariables>(
    DeleteOfferDocumentTemplateDocument,
    options,
  )
}
export type DeleteOfferDocumentTemplateMutationHookResult = ReturnType<typeof useDeleteOfferDocumentTemplateMutation>
export type DeleteOfferDocumentTemplateMutationResult = Apollo.MutationResult<DeleteOfferDocumentTemplateMutation>
export type DeleteOfferDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteOfferDocumentTemplateMutation,
  DeleteOfferDocumentTemplateMutationVariables
>
export const DownloadDocumentTemplateDocument = gql`
  mutation DownloadDocumentTemplate($input: DownloadDocumentTemplateInput!) {
    DownloadDocumentTemplate(input: $input) {
      temporaryUrl
    }
  }
`
export type DownloadDocumentTemplateMutationFn = Apollo.MutationFunction<
  DownloadDocumentTemplateMutation,
  DownloadDocumentTemplateMutationVariables
>

/**
 * __useDownloadDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useDownloadDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadDocumentTemplateMutation, { data, loading, error }] = useDownloadDocumentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DownloadDocumentTemplateMutation, DownloadDocumentTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DownloadDocumentTemplateMutation, DownloadDocumentTemplateMutationVariables>(
    DownloadDocumentTemplateDocument,
    options,
  )
}
export type DownloadDocumentTemplateMutationHookResult = ReturnType<typeof useDownloadDocumentTemplateMutation>
export type DownloadDocumentTemplateMutationResult = Apollo.MutationResult<DownloadDocumentTemplateMutation>
export type DownloadDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  DownloadDocumentTemplateMutation,
  DownloadDocumentTemplateMutationVariables
>
export const GenerateFilesFromHtmlDocument = gql`
  mutation GenerateFilesFromHtml($html: String!) {
    GenerateFilesFromHtml(input: { html: $html }) {
      pdfKey
    }
  }
`
export type GenerateFilesFromHtmlMutationFn = Apollo.MutationFunction<
  GenerateFilesFromHtmlMutation,
  GenerateFilesFromHtmlMutationVariables
>

/**
 * __useGenerateFilesFromHtmlMutation__
 *
 * To run a mutation, you first call `useGenerateFilesFromHtmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFilesFromHtmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFilesFromHtmlMutation, { data, loading, error }] = useGenerateFilesFromHtmlMutation({
 *   variables: {
 *      html: // value for 'html'
 *   },
 * });
 */
export function useGenerateFilesFromHtmlMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateFilesFromHtmlMutation, GenerateFilesFromHtmlMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateFilesFromHtmlMutation, GenerateFilesFromHtmlMutationVariables>(
    GenerateFilesFromHtmlDocument,
    options,
  )
}
export type GenerateFilesFromHtmlMutationHookResult = ReturnType<typeof useGenerateFilesFromHtmlMutation>
export type GenerateFilesFromHtmlMutationResult = Apollo.MutationResult<GenerateFilesFromHtmlMutation>
export type GenerateFilesFromHtmlMutationOptions = Apollo.BaseMutationOptions<
  GenerateFilesFromHtmlMutation,
  GenerateFilesFromHtmlMutationVariables
>
export const GetCompanyOfficesDepartmentsDocument = gql`
  query GetCompanyOfficesDepartments {
    ListCompanyDepartments {
      id
      departmentName
    }
    ListCompanyOffices {
      id
      officeName
    }
  }
`

/**
 * __useGetCompanyOfficesDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetCompanyOfficesDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOfficesDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOfficesDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyOfficesDepartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyOfficesDepartmentsQuery, GetCompanyOfficesDepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyOfficesDepartmentsQuery, GetCompanyOfficesDepartmentsQueryVariables>(
    GetCompanyOfficesDepartmentsDocument,
    options,
  )
}
export function useGetCompanyOfficesDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyOfficesDepartmentsQuery,
    GetCompanyOfficesDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyOfficesDepartmentsQuery, GetCompanyOfficesDepartmentsQueryVariables>(
    GetCompanyOfficesDepartmentsDocument,
    options,
  )
}
export function useGetCompanyOfficesDepartmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyOfficesDepartmentsQuery, GetCompanyOfficesDepartmentsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyOfficesDepartmentsQuery, GetCompanyOfficesDepartmentsQueryVariables>(
    GetCompanyOfficesDepartmentsDocument,
    options,
  )
}
export type GetCompanyOfficesDepartmentsQueryHookResult = ReturnType<typeof useGetCompanyOfficesDepartmentsQuery>
export type GetCompanyOfficesDepartmentsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyOfficesDepartmentsLazyQuery
>
export type GetCompanyOfficesDepartmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyOfficesDepartmentsSuspenseQuery
>
export type GetCompanyOfficesDepartmentsQueryResult = Apollo.QueryResult<
  GetCompanyOfficesDepartmentsQuery,
  GetCompanyOfficesDepartmentsQueryVariables
>
export const GetDocumentTemplateConditionsDocument = gql`
  query GetDocumentTemplateConditions($documentTemplateId: Int!) {
    GetOfferTemplateCondition(input: { offerDocumentTemplateId: $documentTemplateId }) {
      id
      departmentId
      employmentType
      payType
      officeId
    }
  }
`

/**
 * __useGetDocumentTemplateConditionsQuery__
 *
 * To run a query within a React component, call `useGetDocumentTemplateConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTemplateConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTemplateConditionsQuery({
 *   variables: {
 *      documentTemplateId: // value for 'documentTemplateId'
 *   },
 * });
 */
export function useGetDocumentTemplateConditionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentTemplateConditionsQuery,
    GetDocumentTemplateConditionsQueryVariables
  > &
    ({ variables: GetDocumentTemplateConditionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDocumentTemplateConditionsQuery, GetDocumentTemplateConditionsQueryVariables>(
    GetDocumentTemplateConditionsDocument,
    options,
  )
}
export function useGetDocumentTemplateConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentTemplateConditionsQuery,
    GetDocumentTemplateConditionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDocumentTemplateConditionsQuery, GetDocumentTemplateConditionsQueryVariables>(
    GetDocumentTemplateConditionsDocument,
    options,
  )
}
export function useGetDocumentTemplateConditionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetDocumentTemplateConditionsQuery, GetDocumentTemplateConditionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetDocumentTemplateConditionsQuery, GetDocumentTemplateConditionsQueryVariables>(
    GetDocumentTemplateConditionsDocument,
    options,
  )
}
export type GetDocumentTemplateConditionsQueryHookResult = ReturnType<typeof useGetDocumentTemplateConditionsQuery>
export type GetDocumentTemplateConditionsLazyQueryHookResult = ReturnType<
  typeof useGetDocumentTemplateConditionsLazyQuery
>
export type GetDocumentTemplateConditionsSuspenseQueryHookResult = ReturnType<
  typeof useGetDocumentTemplateConditionsSuspenseQuery
>
export type GetDocumentTemplateConditionsQueryResult = Apollo.QueryResult<
  GetDocumentTemplateConditionsQuery,
  GetDocumentTemplateConditionsQueryVariables
>
export const GetDefaultTokenDocument = gql`
  query GetDefaultToken {
    GetDefaultToken(input: {}) {
      id
      categoryName
      tokenName
    }
  }
`

/**
 * __useGetDefaultTokenQuery__
 *
 * To run a query within a React component, call `useGetDefaultTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDefaultTokenQuery, GetDefaultTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDefaultTokenQuery, GetDefaultTokenQueryVariables>(GetDefaultTokenDocument, options)
}
export function useGetDefaultTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultTokenQuery, GetDefaultTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDefaultTokenQuery, GetDefaultTokenQueryVariables>(GetDefaultTokenDocument, options)
}
export function useGetDefaultTokenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDefaultTokenQuery, GetDefaultTokenQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetDefaultTokenQuery, GetDefaultTokenQueryVariables>(GetDefaultTokenDocument, options)
}
export type GetDefaultTokenQueryHookResult = ReturnType<typeof useGetDefaultTokenQuery>
export type GetDefaultTokenLazyQueryHookResult = ReturnType<typeof useGetDefaultTokenLazyQuery>
export type GetDefaultTokenSuspenseQueryHookResult = ReturnType<typeof useGetDefaultTokenSuspenseQuery>
export type GetDefaultTokenQueryResult = Apollo.QueryResult<GetDefaultTokenQuery, GetDefaultTokenQueryVariables>
export const GetOfferDocumentTemplateDocument = gql`
  query GetOfferDocumentTemplate($input: GetOfferDocumentTemplateInput!) {
    GetOfferDocumentTemplate(input: $input) {
      id
      updatedAt
      documentTemplateName
      documentType
      documentTemplateStatus
      documentTemplateText
      documentFilePath
      documentFileName
      CompanyUser {
        id
        UserInvitation {
          id
          ScalisUser {
            ...ScalisUser
          }
        }
      }
    }
  }
  ${ScalisUserFragmentDoc}
`

/**
 * __useGetOfferDocumentTemplateQuery__
 *
 * To run a query within a React component, call `useGetOfferDocumentTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferDocumentTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferDocumentTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOfferDocumentTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfferDocumentTemplateQuery, GetOfferDocumentTemplateQueryVariables> &
    ({ variables: GetOfferDocumentTemplateQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOfferDocumentTemplateQuery, GetOfferDocumentTemplateQueryVariables>(
    GetOfferDocumentTemplateDocument,
    options,
  )
}
export function useGetOfferDocumentTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfferDocumentTemplateQuery, GetOfferDocumentTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOfferDocumentTemplateQuery, GetOfferDocumentTemplateQueryVariables>(
    GetOfferDocumentTemplateDocument,
    options,
  )
}
export function useGetOfferDocumentTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetOfferDocumentTemplateQuery, GetOfferDocumentTemplateQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetOfferDocumentTemplateQuery, GetOfferDocumentTemplateQueryVariables>(
    GetOfferDocumentTemplateDocument,
    options,
  )
}
export type GetOfferDocumentTemplateQueryHookResult = ReturnType<typeof useGetOfferDocumentTemplateQuery>
export type GetOfferDocumentTemplateLazyQueryHookResult = ReturnType<typeof useGetOfferDocumentTemplateLazyQuery>
export type GetOfferDocumentTemplateSuspenseQueryHookResult = ReturnType<
  typeof useGetOfferDocumentTemplateSuspenseQuery
>
export type GetOfferDocumentTemplateQueryResult = Apollo.QueryResult<
  GetOfferDocumentTemplateQuery,
  GetOfferDocumentTemplateQueryVariables
>
export const UpdateDocumentTemplateDocument = gql`
  mutation UpdateDocumentTemplate($input: UpdateDocumentTemplateInput!) {
    UpdateDocumentTemplate(input: $input) {
      id
      documentTemplateName
      documentTemplateText
      documentTemplateStatus
      documentType
    }
  }
`
export type UpdateDocumentTemplateMutationFn = Apollo.MutationFunction<
  UpdateDocumentTemplateMutation,
  UpdateDocumentTemplateMutationVariables
>

/**
 * __useUpdateDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentTemplateMutation, { data, loading, error }] = useUpdateDocumentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDocumentTemplateMutation, UpdateDocumentTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateDocumentTemplateMutation, UpdateDocumentTemplateMutationVariables>(
    UpdateDocumentTemplateDocument,
    options,
  )
}
export type UpdateDocumentTemplateMutationHookResult = ReturnType<typeof useUpdateDocumentTemplateMutation>
export type UpdateDocumentTemplateMutationResult = Apollo.MutationResult<UpdateDocumentTemplateMutation>
export type UpdateDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentTemplateMutation,
  UpdateDocumentTemplateMutationVariables
>
export const ValidateDocumentTemplateDocument = gql`
  mutation ValidateDocumentTemplate($id: Int!) {
    ValidateOfferDocumentTemplate(input: { id: $id }) {
      id
      documentTemplateStatus
      validationResult
    }
  }
`
export type ValidateDocumentTemplateMutationFn = Apollo.MutationFunction<
  ValidateDocumentTemplateMutation,
  ValidateDocumentTemplateMutationVariables
>

/**
 * __useValidateDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useValidateDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateDocumentTemplateMutation, { data, loading, error }] = useValidateDocumentTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useValidateDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<ValidateDocumentTemplateMutation, ValidateDocumentTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ValidateDocumentTemplateMutation, ValidateDocumentTemplateMutationVariables>(
    ValidateDocumentTemplateDocument,
    options,
  )
}
export type ValidateDocumentTemplateMutationHookResult = ReturnType<typeof useValidateDocumentTemplateMutation>
export type ValidateDocumentTemplateMutationResult = Apollo.MutationResult<ValidateDocumentTemplateMutation>
export type ValidateDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  ValidateDocumentTemplateMutation,
  ValidateDocumentTemplateMutationVariables
>
export const GetGlobalListingSetupDocument = gql`
  query GetGlobalListingSetup($companyId: Int!) {
    getGlobalListingSetup(companyId: $companyId) {
      id
      aboutCompanyStatement
      footerStatement
      companyId
      createdAt
      isAboutCompanyActive
      isFooterStatementActive
      updatedAt
    }
  }
`

/**
 * __useGetGlobalListingSetupQuery__
 *
 * To run a query within a React component, call `useGetGlobalListingSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalListingSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalListingSetupQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetGlobalListingSetupQuery(
  baseOptions: Apollo.QueryHookOptions<GetGlobalListingSetupQuery, GetGlobalListingSetupQueryVariables> &
    ({ variables: GetGlobalListingSetupQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetGlobalListingSetupQuery, GetGlobalListingSetupQueryVariables>(
    GetGlobalListingSetupDocument,
    options,
  )
}
export function useGetGlobalListingSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalListingSetupQuery, GetGlobalListingSetupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetGlobalListingSetupQuery, GetGlobalListingSetupQueryVariables>(
    GetGlobalListingSetupDocument,
    options,
  )
}
export function useGetGlobalListingSetupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetGlobalListingSetupQuery, GetGlobalListingSetupQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetGlobalListingSetupQuery, GetGlobalListingSetupQueryVariables>(
    GetGlobalListingSetupDocument,
    options,
  )
}
export type GetGlobalListingSetupQueryHookResult = ReturnType<typeof useGetGlobalListingSetupQuery>
export type GetGlobalListingSetupLazyQueryHookResult = ReturnType<typeof useGetGlobalListingSetupLazyQuery>
export type GetGlobalListingSetupSuspenseQueryHookResult = ReturnType<typeof useGetGlobalListingSetupSuspenseQuery>
export type GetGlobalListingSetupQueryResult = Apollo.QueryResult<
  GetGlobalListingSetupQuery,
  GetGlobalListingSetupQueryVariables
>
export const GetGlobalScreeningQuestionsDocument = gql`
  query GetGlobalScreeningQuestions($input: GetGlobalScreeningQuestionInput!) {
    GetGlobalScreeningQuestions(input: $input) {
      id
      questionTitle
      questionDesc
      questionType
      ansOptions
      isRejectionQuestion
      rejectMessage
      preferredAnswer
      isActiveGlobally
      globalQuestionOrder
      createdAt
      updatedAt
    }
  }
`

/**
 * __useGetGlobalScreeningQuestionsQuery__
 *
 * To run a query within a React component, call `useGetGlobalScreeningQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalScreeningQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalScreeningQuestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGlobalScreeningQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetGlobalScreeningQuestionsQuery, GetGlobalScreeningQuestionsQueryVariables> &
    ({ variables: GetGlobalScreeningQuestionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetGlobalScreeningQuestionsQuery, GetGlobalScreeningQuestionsQueryVariables>(
    GetGlobalScreeningQuestionsDocument,
    options,
  )
}
export function useGetGlobalScreeningQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalScreeningQuestionsQuery,
    GetGlobalScreeningQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetGlobalScreeningQuestionsQuery, GetGlobalScreeningQuestionsQueryVariables>(
    GetGlobalScreeningQuestionsDocument,
    options,
  )
}
export function useGetGlobalScreeningQuestionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetGlobalScreeningQuestionsQuery, GetGlobalScreeningQuestionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetGlobalScreeningQuestionsQuery, GetGlobalScreeningQuestionsQueryVariables>(
    GetGlobalScreeningQuestionsDocument,
    options,
  )
}
export type GetGlobalScreeningQuestionsQueryHookResult = ReturnType<typeof useGetGlobalScreeningQuestionsQuery>
export type GetGlobalScreeningQuestionsLazyQueryHookResult = ReturnType<typeof useGetGlobalScreeningQuestionsLazyQuery>
export type GetGlobalScreeningQuestionsSuspenseQueryHookResult = ReturnType<
  typeof useGetGlobalScreeningQuestionsSuspenseQuery
>
export type GetGlobalScreeningQuestionsQueryResult = Apollo.QueryResult<
  GetGlobalScreeningQuestionsQuery,
  GetGlobalScreeningQuestionsQueryVariables
>
export const ListGlobalScreeningQuestionsDocument = gql`
  query ListGlobalScreeningQuestions($input: ListGlobalScreeningQuestionInput!) {
    ListGlobalScreeningQuestions(input: $input) {
      id
      questionTitle
      questionDesc
      questionType
      ansOptions
      minVal
      maxVal
      valStatus
      isRejectionQuestion
      rejectMessage
      preferredAnswer
      isActiveGlobally
      globalQuestionOrder
      createdAt
      updatedAt
    }
  }
`

/**
 * __useListGlobalScreeningQuestionsQuery__
 *
 * To run a query within a React component, call `useListGlobalScreeningQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGlobalScreeningQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGlobalScreeningQuestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListGlobalScreeningQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<ListGlobalScreeningQuestionsQuery, ListGlobalScreeningQuestionsQueryVariables> &
    ({ variables: ListGlobalScreeningQuestionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListGlobalScreeningQuestionsQuery, ListGlobalScreeningQuestionsQueryVariables>(
    ListGlobalScreeningQuestionsDocument,
    options,
  )
}
export function useListGlobalScreeningQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGlobalScreeningQuestionsQuery,
    ListGlobalScreeningQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListGlobalScreeningQuestionsQuery, ListGlobalScreeningQuestionsQueryVariables>(
    ListGlobalScreeningQuestionsDocument,
    options,
  )
}
export function useListGlobalScreeningQuestionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListGlobalScreeningQuestionsQuery, ListGlobalScreeningQuestionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListGlobalScreeningQuestionsQuery, ListGlobalScreeningQuestionsQueryVariables>(
    ListGlobalScreeningQuestionsDocument,
    options,
  )
}
export type ListGlobalScreeningQuestionsQueryHookResult = ReturnType<typeof useListGlobalScreeningQuestionsQuery>
export type ListGlobalScreeningQuestionsLazyQueryHookResult = ReturnType<
  typeof useListGlobalScreeningQuestionsLazyQuery
>
export type ListGlobalScreeningQuestionsSuspenseQueryHookResult = ReturnType<
  typeof useListGlobalScreeningQuestionsSuspenseQuery
>
export type ListGlobalScreeningQuestionsQueryResult = Apollo.QueryResult<
  ListGlobalScreeningQuestionsQuery,
  ListGlobalScreeningQuestionsQueryVariables
>
export const GetHiringTeamMemberDocument = gql`
  query GetHiringTeamMember($id: Int!) {
    GetHiringTeamMember(id: $id) {
      ...HiringTeamMemberFragment
      createdAt
      updatedAt
      role {
        ...RoleFragment
      }
    }
  }
  ${HiringTeamMemberFragmentFragmentDoc}
  ${RoleFragmentFragmentDoc}
`

/**
 * __useGetHiringTeamMemberQuery__
 *
 * To run a query within a React component, call `useGetHiringTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHiringTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHiringTeamMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHiringTeamMemberQuery(
  baseOptions: Apollo.QueryHookOptions<GetHiringTeamMemberQuery, GetHiringTeamMemberQueryVariables> &
    ({ variables: GetHiringTeamMemberQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetHiringTeamMemberQuery, GetHiringTeamMemberQueryVariables>(
    GetHiringTeamMemberDocument,
    options,
  )
}
export function useGetHiringTeamMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHiringTeamMemberQuery, GetHiringTeamMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetHiringTeamMemberQuery, GetHiringTeamMemberQueryVariables>(
    GetHiringTeamMemberDocument,
    options,
  )
}
export function useGetHiringTeamMemberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetHiringTeamMemberQuery, GetHiringTeamMemberQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetHiringTeamMemberQuery, GetHiringTeamMemberQueryVariables>(
    GetHiringTeamMemberDocument,
    options,
  )
}
export type GetHiringTeamMemberQueryHookResult = ReturnType<typeof useGetHiringTeamMemberQuery>
export type GetHiringTeamMemberLazyQueryHookResult = ReturnType<typeof useGetHiringTeamMemberLazyQuery>
export type GetHiringTeamMemberSuspenseQueryHookResult = ReturnType<typeof useGetHiringTeamMemberSuspenseQuery>
export type GetHiringTeamMemberQueryResult = Apollo.QueryResult<
  GetHiringTeamMemberQuery,
  GetHiringTeamMemberQueryVariables
>
export const GetCampaignJobFunctionsDocument = gql`
  query GetCampaignJobFunctions {
    CampaignJobFunctions {
      name
      id
    }
  }
`

/**
 * __useGetCampaignJobFunctionsQuery__
 *
 * To run a query within a React component, call `useGetCampaignJobFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignJobFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignJobFunctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignJobFunctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCampaignJobFunctionsQuery, GetCampaignJobFunctionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCampaignJobFunctionsQuery, GetCampaignJobFunctionsQueryVariables>(
    GetCampaignJobFunctionsDocument,
    options,
  )
}
export function useGetCampaignJobFunctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignJobFunctionsQuery, GetCampaignJobFunctionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCampaignJobFunctionsQuery, GetCampaignJobFunctionsQueryVariables>(
    GetCampaignJobFunctionsDocument,
    options,
  )
}
export function useGetCampaignJobFunctionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCampaignJobFunctionsQuery, GetCampaignJobFunctionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCampaignJobFunctionsQuery, GetCampaignJobFunctionsQueryVariables>(
    GetCampaignJobFunctionsDocument,
    options,
  )
}
export type GetCampaignJobFunctionsQueryHookResult = ReturnType<typeof useGetCampaignJobFunctionsQuery>
export type GetCampaignJobFunctionsLazyQueryHookResult = ReturnType<typeof useGetCampaignJobFunctionsLazyQuery>
export type GetCampaignJobFunctionsSuspenseQueryHookResult = ReturnType<typeof useGetCampaignJobFunctionsSuspenseQuery>
export type GetCampaignJobFunctionsQueryResult = Apollo.QueryResult<
  GetCampaignJobFunctionsQuery,
  GetCampaignJobFunctionsQueryVariables
>
export const GetJobCampaignEducationLevelsDocument = gql`
  query GetJobCampaignEducationLevels {
    GetJobCampaignEducationLevels {
      name {
        languageCode
        value
      }
      id
    }
  }
`

/**
 * __useGetJobCampaignEducationLevelsQuery__
 *
 * To run a query within a React component, call `useGetJobCampaignEducationLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobCampaignEducationLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobCampaignEducationLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobCampaignEducationLevelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetJobCampaignEducationLevelsQuery,
    GetJobCampaignEducationLevelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobCampaignEducationLevelsQuery, GetJobCampaignEducationLevelsQueryVariables>(
    GetJobCampaignEducationLevelsDocument,
    options,
  )
}
export function useGetJobCampaignEducationLevelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobCampaignEducationLevelsQuery,
    GetJobCampaignEducationLevelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobCampaignEducationLevelsQuery, GetJobCampaignEducationLevelsQueryVariables>(
    GetJobCampaignEducationLevelsDocument,
    options,
  )
}
export function useGetJobCampaignEducationLevelsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobCampaignEducationLevelsQuery, GetJobCampaignEducationLevelsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobCampaignEducationLevelsQuery, GetJobCampaignEducationLevelsQueryVariables>(
    GetJobCampaignEducationLevelsDocument,
    options,
  )
}
export type GetJobCampaignEducationLevelsQueryHookResult = ReturnType<typeof useGetJobCampaignEducationLevelsQuery>
export type GetJobCampaignEducationLevelsLazyQueryHookResult = ReturnType<
  typeof useGetJobCampaignEducationLevelsLazyQuery
>
export type GetJobCampaignEducationLevelsSuspenseQueryHookResult = ReturnType<
  typeof useGetJobCampaignEducationLevelsSuspenseQuery
>
export type GetJobCampaignEducationLevelsQueryResult = Apollo.QueryResult<
  GetJobCampaignEducationLevelsQuery,
  GetJobCampaignEducationLevelsQueryVariables
>
export const GetJobCampaignIndustriesDocument = gql`
  query GetJobCampaignIndustries {
    JobCampaignIndustries {
      id
      name
    }
  }
`

/**
 * __useGetJobCampaignIndustriesQuery__
 *
 * To run a query within a React component, call `useGetJobCampaignIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobCampaignIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobCampaignIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobCampaignIndustriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobCampaignIndustriesQuery, GetJobCampaignIndustriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobCampaignIndustriesQuery, GetJobCampaignIndustriesQueryVariables>(
    GetJobCampaignIndustriesDocument,
    options,
  )
}
export function useGetJobCampaignIndustriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobCampaignIndustriesQuery, GetJobCampaignIndustriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobCampaignIndustriesQuery, GetJobCampaignIndustriesQueryVariables>(
    GetJobCampaignIndustriesDocument,
    options,
  )
}
export function useGetJobCampaignIndustriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobCampaignIndustriesQuery, GetJobCampaignIndustriesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobCampaignIndustriesQuery, GetJobCampaignIndustriesQueryVariables>(
    GetJobCampaignIndustriesDocument,
    options,
  )
}
export type GetJobCampaignIndustriesQueryHookResult = ReturnType<typeof useGetJobCampaignIndustriesQuery>
export type GetJobCampaignIndustriesLazyQueryHookResult = ReturnType<typeof useGetJobCampaignIndustriesLazyQuery>
export type GetJobCampaignIndustriesSuspenseQueryHookResult = ReturnType<
  typeof useGetJobCampaignIndustriesSuspenseQuery
>
export type GetJobCampaignIndustriesQueryResult = Apollo.QueryResult<
  GetJobCampaignIndustriesQuery,
  GetJobCampaignIndustriesQueryVariables
>
export const SearchJobCampaignLocationsDocument = gql`
  query SearchJobCampaignLocations($input: SearchJobCampaignLocationsInput!) {
    SearchJobCampaignLocations(input: $input) {
      id
      fully_qualified_place_name
    }
  }
`

/**
 * __useSearchJobCampaignLocationsQuery__
 *
 * To run a query within a React component, call `useSearchJobCampaignLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchJobCampaignLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchJobCampaignLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchJobCampaignLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchJobCampaignLocationsQuery, SearchJobCampaignLocationsQueryVariables> &
    ({ variables: SearchJobCampaignLocationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchJobCampaignLocationsQuery, SearchJobCampaignLocationsQueryVariables>(
    SearchJobCampaignLocationsDocument,
    options,
  )
}
export function useSearchJobCampaignLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchJobCampaignLocationsQuery, SearchJobCampaignLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchJobCampaignLocationsQuery, SearchJobCampaignLocationsQueryVariables>(
    SearchJobCampaignLocationsDocument,
    options,
  )
}
export function useSearchJobCampaignLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SearchJobCampaignLocationsQuery, SearchJobCampaignLocationsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchJobCampaignLocationsQuery, SearchJobCampaignLocationsQueryVariables>(
    SearchJobCampaignLocationsDocument,
    options,
  )
}
export type SearchJobCampaignLocationsQueryHookResult = ReturnType<typeof useSearchJobCampaignLocationsQuery>
export type SearchJobCampaignLocationsLazyQueryHookResult = ReturnType<typeof useSearchJobCampaignLocationsLazyQuery>
export type SearchJobCampaignLocationsSuspenseQueryHookResult = ReturnType<
  typeof useSearchJobCampaignLocationsSuspenseQuery
>
export type SearchJobCampaignLocationsQueryResult = Apollo.QueryResult<
  SearchJobCampaignLocationsQuery,
  SearchJobCampaignLocationsQueryVariables
>
export const GetJobCampaignProductsByIdDocument = gql`
  query GetJobCampaignProductsById($ids: [String!]!) {
    GetJobCampaignProductsById(input: { ids: $ids }) {
      results {
        ...JobCampaignProductFragment
      }
    }
  }
  ${JobCampaignProductFragmentFragmentDoc}
`

/**
 * __useGetJobCampaignProductsByIdQuery__
 *
 * To run a query within a React component, call `useGetJobCampaignProductsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobCampaignProductsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobCampaignProductsByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetJobCampaignProductsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobCampaignProductsByIdQuery, GetJobCampaignProductsByIdQueryVariables> &
    ({ variables: GetJobCampaignProductsByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobCampaignProductsByIdQuery, GetJobCampaignProductsByIdQueryVariables>(
    GetJobCampaignProductsByIdDocument,
    options,
  )
}
export function useGetJobCampaignProductsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobCampaignProductsByIdQuery, GetJobCampaignProductsByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobCampaignProductsByIdQuery, GetJobCampaignProductsByIdQueryVariables>(
    GetJobCampaignProductsByIdDocument,
    options,
  )
}
export function useGetJobCampaignProductsByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobCampaignProductsByIdQuery, GetJobCampaignProductsByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobCampaignProductsByIdQuery, GetJobCampaignProductsByIdQueryVariables>(
    GetJobCampaignProductsByIdDocument,
    options,
  )
}
export type GetJobCampaignProductsByIdQueryHookResult = ReturnType<typeof useGetJobCampaignProductsByIdQuery>
export type GetJobCampaignProductsByIdLazyQueryHookResult = ReturnType<typeof useGetJobCampaignProductsByIdLazyQuery>
export type GetJobCampaignProductsByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetJobCampaignProductsByIdSuspenseQuery
>
export type GetJobCampaignProductsByIdQueryResult = Apollo.QueryResult<
  GetJobCampaignProductsByIdQuery,
  GetJobCampaignProductsByIdQueryVariables
>
export const GetJobCampaignProductsDocument = gql`
  query GetJobCampaignProducts($input: ProductFiltersInput) {
    GetJobCampaignProducts(input: $input) {
      count
      results {
        ...JobCampaignProductFragment
      }
    }
  }
  ${JobCampaignProductFragmentFragmentDoc}
`

/**
 * __useGetJobCampaignProductsQuery__
 *
 * To run a query within a React component, call `useGetJobCampaignProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobCampaignProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobCampaignProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetJobCampaignProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobCampaignProductsQuery, GetJobCampaignProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobCampaignProductsQuery, GetJobCampaignProductsQueryVariables>(
    GetJobCampaignProductsDocument,
    options,
  )
}
export function useGetJobCampaignProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobCampaignProductsQuery, GetJobCampaignProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobCampaignProductsQuery, GetJobCampaignProductsQueryVariables>(
    GetJobCampaignProductsDocument,
    options,
  )
}
export function useGetJobCampaignProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobCampaignProductsQuery, GetJobCampaignProductsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobCampaignProductsQuery, GetJobCampaignProductsQueryVariables>(
    GetJobCampaignProductsDocument,
    options,
  )
}
export type GetJobCampaignProductsQueryHookResult = ReturnType<typeof useGetJobCampaignProductsQuery>
export type GetJobCampaignProductsLazyQueryHookResult = ReturnType<typeof useGetJobCampaignProductsLazyQuery>
export type GetJobCampaignProductsSuspenseQueryHookResult = ReturnType<typeof useGetJobCampaignProductsSuspenseQuery>
export type GetJobCampaignProductsQueryResult = Apollo.QueryResult<
  GetJobCampaignProductsQuery,
  GetJobCampaignProductsQueryVariables
>
export const GetJobCampaignSeniorityDocument = gql`
  query GetJobCampaignSeniority {
    GetJobCampaignSeniority {
      id
      name {
        languageCode
        value
      }
    }
  }
`

/**
 * __useGetJobCampaignSeniorityQuery__
 *
 * To run a query within a React component, call `useGetJobCampaignSeniorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobCampaignSeniorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobCampaignSeniorityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobCampaignSeniorityQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobCampaignSeniorityQuery, GetJobCampaignSeniorityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobCampaignSeniorityQuery, GetJobCampaignSeniorityQueryVariables>(
    GetJobCampaignSeniorityDocument,
    options,
  )
}
export function useGetJobCampaignSeniorityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobCampaignSeniorityQuery, GetJobCampaignSeniorityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobCampaignSeniorityQuery, GetJobCampaignSeniorityQueryVariables>(
    GetJobCampaignSeniorityDocument,
    options,
  )
}
export function useGetJobCampaignSenioritySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobCampaignSeniorityQuery, GetJobCampaignSeniorityQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobCampaignSeniorityQuery, GetJobCampaignSeniorityQueryVariables>(
    GetJobCampaignSeniorityDocument,
    options,
  )
}
export type GetJobCampaignSeniorityQueryHookResult = ReturnType<typeof useGetJobCampaignSeniorityQuery>
export type GetJobCampaignSeniorityLazyQueryHookResult = ReturnType<typeof useGetJobCampaignSeniorityLazyQuery>
export type GetJobCampaignSenioritySuspenseQueryHookResult = ReturnType<typeof useGetJobCampaignSenioritySuspenseQuery>
export type GetJobCampaignSeniorityQueryResult = Apollo.QueryResult<
  GetJobCampaignSeniorityQuery,
  GetJobCampaignSeniorityQueryVariables
>
export const GetOrderDocument = gql`
  query GetOrder($id: Int!) {
    GetOrders(input: { id: $id }) {
      Campaigns {
        data {
          campaignName
          orderedProducts
          labels {
            jobListingId
          }
          targetGroup {
            seniority {
              description
              vonqId
            }
            jobCategory {
              vonqId
              description
            }
            industry {
              vonqId
              description
            }
            educationLevel {
              vonqId
              description
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables> &
    ({ variables: GetOrderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options)
}
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options)
}
export function useGetOrderSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options)
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>
export type GetOrderSuspenseQueryHookResult = ReturnType<typeof useGetOrderSuspenseQuery>
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>
export const GetRequisitionOrdersDocument = gql`
  query GetRequisitionOrders($requisitionId: Int!) {
    GetRequisition(id: $requisitionId) {
      id
      Orders {
        id
        total
        applicantsCount
        campaignTraffic
        Campaigns {
          data {
            campaignId
            campaignName
            createdOn
            status
            viewsCount
            postings {
              clicks
            }
            targetGroup {
              seniority {
                vonqId
                description
              }
              jobCategory {
                description
                vonqId
              }
              industry {
                description
                vonqId
              }
              educationLevel {
                description
                vonqId
              }
            }
            labels {
              jobListingId
            }
          }
        }
      }
      jobListings {
        id
        workplaceType
        listingTitle
        publicListingId
        stateAndOrCountry
      }
    }
  }
`

/**
 * __useGetRequisitionOrdersQuery__
 *
 * To run a query within a React component, call `useGetRequisitionOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionOrdersQuery({
 *   variables: {
 *      requisitionId: // value for 'requisitionId'
 *   },
 * });
 */
export function useGetRequisitionOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequisitionOrdersQuery, GetRequisitionOrdersQueryVariables> &
    ({ variables: GetRequisitionOrdersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionOrdersQuery, GetRequisitionOrdersQueryVariables>(
    GetRequisitionOrdersDocument,
    options,
  )
}
export function useGetRequisitionOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionOrdersQuery, GetRequisitionOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionOrdersQuery, GetRequisitionOrdersQueryVariables>(
    GetRequisitionOrdersDocument,
    options,
  )
}
export function useGetRequisitionOrdersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionOrdersQuery, GetRequisitionOrdersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionOrdersQuery, GetRequisitionOrdersQueryVariables>(
    GetRequisitionOrdersDocument,
    options,
  )
}
export type GetRequisitionOrdersQueryHookResult = ReturnType<typeof useGetRequisitionOrdersQuery>
export type GetRequisitionOrdersLazyQueryHookResult = ReturnType<typeof useGetRequisitionOrdersLazyQuery>
export type GetRequisitionOrdersSuspenseQueryHookResult = ReturnType<typeof useGetRequisitionOrdersSuspenseQuery>
export type GetRequisitionOrdersQueryResult = Apollo.QueryResult<
  GetRequisitionOrdersQuery,
  GetRequisitionOrdersQueryVariables
>
export const GetIsCandidateAppliedDocument = gql`
  query GetIsCandidateApplied($publicListingId: String!) {
    GetCandidateApplication(publicListingIds: [$publicListingId]) {
      id
    }
  }
`

/**
 * __useGetIsCandidateAppliedQuery__
 *
 * To run a query within a React component, call `useGetIsCandidateAppliedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsCandidateAppliedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsCandidateAppliedQuery({
 *   variables: {
 *      publicListingId: // value for 'publicListingId'
 *   },
 * });
 */
export function useGetIsCandidateAppliedQuery(
  baseOptions: Apollo.QueryHookOptions<GetIsCandidateAppliedQuery, GetIsCandidateAppliedQueryVariables> &
    ({ variables: GetIsCandidateAppliedQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetIsCandidateAppliedQuery, GetIsCandidateAppliedQueryVariables>(
    GetIsCandidateAppliedDocument,
    options,
  )
}
export function useGetIsCandidateAppliedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIsCandidateAppliedQuery, GetIsCandidateAppliedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetIsCandidateAppliedQuery, GetIsCandidateAppliedQueryVariables>(
    GetIsCandidateAppliedDocument,
    options,
  )
}
export function useGetIsCandidateAppliedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetIsCandidateAppliedQuery, GetIsCandidateAppliedQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetIsCandidateAppliedQuery, GetIsCandidateAppliedQueryVariables>(
    GetIsCandidateAppliedDocument,
    options,
  )
}
export type GetIsCandidateAppliedQueryHookResult = ReturnType<typeof useGetIsCandidateAppliedQuery>
export type GetIsCandidateAppliedLazyQueryHookResult = ReturnType<typeof useGetIsCandidateAppliedLazyQuery>
export type GetIsCandidateAppliedSuspenseQueryHookResult = ReturnType<typeof useGetIsCandidateAppliedSuspenseQuery>
export type GetIsCandidateAppliedQueryResult = Apollo.QueryResult<
  GetIsCandidateAppliedQuery,
  GetIsCandidateAppliedQueryVariables
>
export const GetJobListingsPipelineDocument = gql`
  query GetJobListingsPipeline($jobRequisitionId: Int) {
    GetJobListing(jobRequisitionId: $jobRequisitionId) {
      JobListingJobPipeline {
        id
        JobPipeline {
          id
          TemplatePipeline {
            id
            name
            TemplateMilestones {
              id
              name
              rankOrder
              TemplateStage {
                rankOrder
                name
                id
                visibility
              }
            }
          }
        }
      }
      id
      externalTitle
      publicListingId
      Requisition {
        id
        jobName
      }
    }
  }
`

/**
 * __useGetJobListingsPipelineQuery__
 *
 * To run a query within a React component, call `useGetJobListingsPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobListingsPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobListingsPipelineQuery({
 *   variables: {
 *      jobRequisitionId: // value for 'jobRequisitionId'
 *   },
 * });
 */
export function useGetJobListingsPipelineQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobListingsPipelineQuery, GetJobListingsPipelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobListingsPipelineQuery, GetJobListingsPipelineQueryVariables>(
    GetJobListingsPipelineDocument,
    options,
  )
}
export function useGetJobListingsPipelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobListingsPipelineQuery, GetJobListingsPipelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobListingsPipelineQuery, GetJobListingsPipelineQueryVariables>(
    GetJobListingsPipelineDocument,
    options,
  )
}
export function useGetJobListingsPipelineSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobListingsPipelineQuery, GetJobListingsPipelineQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobListingsPipelineQuery, GetJobListingsPipelineQueryVariables>(
    GetJobListingsPipelineDocument,
    options,
  )
}
export type GetJobListingsPipelineQueryHookResult = ReturnType<typeof useGetJobListingsPipelineQuery>
export type GetJobListingsPipelineLazyQueryHookResult = ReturnType<typeof useGetJobListingsPipelineLazyQuery>
export type GetJobListingsPipelineSuspenseQueryHookResult = ReturnType<typeof useGetJobListingsPipelineSuspenseQuery>
export type GetJobListingsPipelineQueryResult = Apollo.QueryResult<
  GetJobListingsPipelineQuery,
  GetJobListingsPipelineQueryVariables
>
export const GetJobListingDocument = gql`
  query GetJobListing($id: Int, $jobRequisitionId: Int, $page: Int, $limit: Int) {
    GetJobListing(id: $id, jobRequisitionId: $jobRequisitionId, page: $page, limit: $limit) {
      Address {
        id
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
        timeZone
      }
      JobListingScreeningQuestion {
        id
        jobListingId
        displaySequenceNumber
        screeningQuestionId
        createdAt
        updatedAt
        ScreeningQuestion {
          id
          isTemplateQuestion
          questionTitle
          questionDesc
          questionType
          minVal
          maxVal
          valStatus
          ansOptions
          isRejectionQuestion
          isGlobalQuestion
          rejectMessage
          templateMessageId
          preferredAnswer
          companyId
          companyUserId
          createdAt
          updatedAt
        }
      }
      id
      publicListingId
      jobRequisitionId
      listingTitle
      externalTitle
      workplaceType
      publicDescription
      displayPayOnListing
      industry
      subIndustry
      companyDefault
      stateAndOrCountry
      internalMatchingDescription
      searchPublicDescription
      searchInternalMatchingDescription
      postedAt
      acceptingCandidates
      stage
      createdAt
      updatedAt
    }
  }
`

/**
 * __useGetJobListingQuery__
 *
 * To run a query within a React component, call `useGetJobListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      jobRequisitionId: // value for 'jobRequisitionId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetJobListingQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobListingQuery, GetJobListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobListingQuery, GetJobListingQueryVariables>(GetJobListingDocument, options)
}
export function useGetJobListingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobListingQuery, GetJobListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobListingQuery, GetJobListingQueryVariables>(GetJobListingDocument, options)
}
export function useGetJobListingSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetJobListingQuery, GetJobListingQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobListingQuery, GetJobListingQueryVariables>(GetJobListingDocument, options)
}
export type GetJobListingQueryHookResult = ReturnType<typeof useGetJobListingQuery>
export type GetJobListingLazyQueryHookResult = ReturnType<typeof useGetJobListingLazyQuery>
export type GetJobListingSuspenseQueryHookResult = ReturnType<typeof useGetJobListingSuspenseQuery>
export type GetJobListingQueryResult = Apollo.QueryResult<GetJobListingQuery, GetJobListingQueryVariables>
export const GetJobListingsIdTitleDocument = gql`
  query GetJobListingsIdTitle($page: Int, $limit: Int) {
    GetJobListing(page: $page, limit: $limit) {
      __typename
      id
      listingTitle
    }
  }
`

/**
 * __useGetJobListingsIdTitleQuery__
 *
 * To run a query within a React component, call `useGetJobListingsIdTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobListingsIdTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobListingsIdTitleQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetJobListingsIdTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobListingsIdTitleQuery, GetJobListingsIdTitleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobListingsIdTitleQuery, GetJobListingsIdTitleQueryVariables>(
    GetJobListingsIdTitleDocument,
    options,
  )
}
export function useGetJobListingsIdTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobListingsIdTitleQuery, GetJobListingsIdTitleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobListingsIdTitleQuery, GetJobListingsIdTitleQueryVariables>(
    GetJobListingsIdTitleDocument,
    options,
  )
}
export function useGetJobListingsIdTitleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobListingsIdTitleQuery, GetJobListingsIdTitleQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobListingsIdTitleQuery, GetJobListingsIdTitleQueryVariables>(
    GetJobListingsIdTitleDocument,
    options,
  )
}
export type GetJobListingsIdTitleQueryHookResult = ReturnType<typeof useGetJobListingsIdTitleQuery>
export type GetJobListingsIdTitleLazyQueryHookResult = ReturnType<typeof useGetJobListingsIdTitleLazyQuery>
export type GetJobListingsIdTitleSuspenseQueryHookResult = ReturnType<typeof useGetJobListingsIdTitleSuspenseQuery>
export type GetJobListingsIdTitleQueryResult = Apollo.QueryResult<
  GetJobListingsIdTitleQuery,
  GetJobListingsIdTitleQueryVariables
>
export const GetJobPipelineDocument = gql`
  query GetJobPipeline($id: Int, $jobRequisitionId: Int, $templatePipelineId: Int) {
    GetJobPipeline(id: $id) {
      ...JobPipelineFragment
      TemplatePipeline {
        ...TemplatePipelineFragment
        createdAt
        updatedAt
        TemplateMilestones {
          ...TemplateMilestoneFragment
          createdAt
          updatedAt
        }
        TemplateStage {
          ...TemplateStageFragment
          createdAt
          updatedAt
        }
      }
      StageSettings {
        ...StageSettingsFragment
        createdAt
        updatedAt
      }
    }
  }
  ${JobPipelineFragmentFragmentDoc}
  ${TemplatePipelineFragmentFragmentDoc}
  ${TemplateMilestoneFragmentFragmentDoc}
  ${TemplateStageFragmentFragmentDoc}
  ${StageSettingsFragmentFragmentDoc}
`

/**
 * __useGetJobPipelineQuery__
 *
 * To run a query within a React component, call `useGetJobPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobPipelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *      jobRequisitionId: // value for 'jobRequisitionId'
 *      templatePipelineId: // value for 'templatePipelineId'
 *   },
 * });
 */
export function useGetJobPipelineQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobPipelineQuery, GetJobPipelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobPipelineQuery, GetJobPipelineQueryVariables>(GetJobPipelineDocument, options)
}
export function useGetJobPipelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobPipelineQuery, GetJobPipelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobPipelineQuery, GetJobPipelineQueryVariables>(GetJobPipelineDocument, options)
}
export function useGetJobPipelineSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetJobPipelineQuery, GetJobPipelineQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobPipelineQuery, GetJobPipelineQueryVariables>(GetJobPipelineDocument, options)
}
export type GetJobPipelineQueryHookResult = ReturnType<typeof useGetJobPipelineQuery>
export type GetJobPipelineLazyQueryHookResult = ReturnType<typeof useGetJobPipelineLazyQuery>
export type GetJobPipelineSuspenseQueryHookResult = ReturnType<typeof useGetJobPipelineSuspenseQuery>
export type GetJobPipelineQueryResult = Apollo.QueryResult<GetJobPipelineQuery, GetJobPipelineQueryVariables>
export const CountCandidateApplicationDocument = gql`
  query CountCandidateApplication(
    $publicListingIds: [String!]
    $templateStageIds: [Int!]
    $matchScore: MatchScore
    $candidateProfileId: [Int!]
    $skills: [String!]
    $lastActivity: LastActivity
    $applicationStatus: [ApplicationStatusEnum!]
    $applicationStatusNotIn: [ApplicationStatusEnum!]
    $applicationType: [ApplicationTypeEnum!]
    $sourceName: [String!]
    $medalistDesignation: [String!]
    $creditedCompanyUserIds: [Int!]
    $companies: [String!]
    $educationLevel: [EducationLevelEnum!]
    $jobTitles: [String!]
    $majors: [String!]
    $nonTraditionalStatus: Boolean
    $schools: [String!]
    $timezones: [String!]
    $veteranStatus: [VeteranStatusEnum!]
    $willingToRelocate: [WillingToRelocateEnum!]
    $yearsOfExperience: YearsOfExperience
    $requisitionId: Int
    $profileType: ProfileTypeEnum
    $search: String
  ) {
    CountCandidateApplication(
      publicListingIds: $publicListingIds
      templateStageIds: $templateStageIds
      matchScore: $matchScore
      candidateProfileId: $candidateProfileId
      skills: $skills
      lastActivity: $lastActivity
      applicationStatus: $applicationStatus
      applicationStatusNotIn: $applicationStatusNotIn
      applicationType: $applicationType
      sourceName: $sourceName
      medalistDesignation: $medalistDesignation
      creditedCompanyUserIds: $creditedCompanyUserIds
      companies: $companies
      educationLevel: $educationLevel
      jobTitles: $jobTitles
      majors: $majors
      nonTraditionalStatus: $nonTraditionalStatus
      schools: $schools
      timezones: $timezones
      veteranStatus: $veteranStatus
      willingToRelocate: $willingToRelocate
      yearsOfExperience: $yearsOfExperience
      requisitionId: $requisitionId
      profileType: $profileType
      search: $search
    ) {
      count
    }
  }
`

/**
 * __useCountCandidateApplicationQuery__
 *
 * To run a query within a React component, call `useCountCandidateApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCandidateApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCandidateApplicationQuery({
 *   variables: {
 *      publicListingIds: // value for 'publicListingIds'
 *      templateStageIds: // value for 'templateStageIds'
 *      matchScore: // value for 'matchScore'
 *      candidateProfileId: // value for 'candidateProfileId'
 *      skills: // value for 'skills'
 *      lastActivity: // value for 'lastActivity'
 *      applicationStatus: // value for 'applicationStatus'
 *      applicationStatusNotIn: // value for 'applicationStatusNotIn'
 *      applicationType: // value for 'applicationType'
 *      sourceName: // value for 'sourceName'
 *      medalistDesignation: // value for 'medalistDesignation'
 *      creditedCompanyUserIds: // value for 'creditedCompanyUserIds'
 *      companies: // value for 'companies'
 *      educationLevel: // value for 'educationLevel'
 *      jobTitles: // value for 'jobTitles'
 *      majors: // value for 'majors'
 *      nonTraditionalStatus: // value for 'nonTraditionalStatus'
 *      schools: // value for 'schools'
 *      timezones: // value for 'timezones'
 *      veteranStatus: // value for 'veteranStatus'
 *      willingToRelocate: // value for 'willingToRelocate'
 *      yearsOfExperience: // value for 'yearsOfExperience'
 *      requisitionId: // value for 'requisitionId'
 *      profileType: // value for 'profileType'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCountCandidateApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<CountCandidateApplicationQuery, CountCandidateApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CountCandidateApplicationQuery, CountCandidateApplicationQueryVariables>(
    CountCandidateApplicationDocument,
    options,
  )
}
export function useCountCandidateApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountCandidateApplicationQuery, CountCandidateApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CountCandidateApplicationQuery, CountCandidateApplicationQueryVariables>(
    CountCandidateApplicationDocument,
    options,
  )
}
export function useCountCandidateApplicationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CountCandidateApplicationQuery, CountCandidateApplicationQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CountCandidateApplicationQuery, CountCandidateApplicationQueryVariables>(
    CountCandidateApplicationDocument,
    options,
  )
}
export type CountCandidateApplicationQueryHookResult = ReturnType<typeof useCountCandidateApplicationQuery>
export type CountCandidateApplicationLazyQueryHookResult = ReturnType<typeof useCountCandidateApplicationLazyQuery>
export type CountCandidateApplicationSuspenseQueryHookResult = ReturnType<
  typeof useCountCandidateApplicationSuspenseQuery
>
export type CountCandidateApplicationQueryResult = Apollo.QueryResult<
  CountCandidateApplicationQuery,
  CountCandidateApplicationQueryVariables
>
export const GetCandidateApplicationDocument = gql`
  query GetCandidateApplication(
    $publicListingIds: [String!]
    $templateStageIds: [Int!]
    $matchScore: MatchScore
    $candidateProfileId: [Int!]
    $skills: [String!]
    $lastActivity: LastActivity
    $applicationStatus: [ApplicationStatusEnum!]
    $applicationStatusNotIn: [ApplicationStatusEnum!]
    $applicationType: [ApplicationTypeEnum!]
    $sourceName: [String!]
    $medalistDesignation: [String!]
    $creditedCompanyUserIds: [Int!]
    $companies: [String!]
    $educationLevel: [EducationLevelEnum!]
    $jobTitles: [String!]
    $majors: [String!]
    $nonTraditionalStatus: Boolean
    $schools: [String!]
    $timezones: [String!]
    $veteranStatus: [VeteranStatusEnum!]
    $willingToRelocate: [WillingToRelocateEnum!]
    $yearsOfExperience: YearsOfExperience
    $requisitionId: Int
    $profileType: ProfileTypeEnum
    $search: String
    $limit: Int
    $page: Int
  ) {
    GetCandidateApplication(
      publicListingIds: $publicListingIds
      templateStageIds: $templateStageIds
      matchScore: $matchScore
      candidateProfileId: $candidateProfileId
      skills: $skills
      lastActivity: $lastActivity
      applicationStatus: $applicationStatus
      applicationStatusNotIn: $applicationStatusNotIn
      applicationType: $applicationType
      sourceName: $sourceName
      medalistDesignation: $medalistDesignation
      creditedCompanyUserIds: $creditedCompanyUserIds
      companies: $companies
      educationLevel: $educationLevel
      jobTitles: $jobTitles
      majors: $majors
      nonTraditionalStatus: $nonTraditionalStatus
      schools: $schools
      timezones: $timezones
      veteranStatus: $veteranStatus
      willingToRelocate: $willingToRelocate
      yearsOfExperience: $yearsOfExperience
      requisitionId: $requisitionId
      profileType: $profileType
      search: $search
      limit: $limit
      page: $page
    ) {
      __typename
      id
      matchScore
      applicationStatus
      applicationType
      appliedAt
      lastUpdatedStatusAt
      templateStageId
      jobListingId
      CandidateInterview {
        id
        stageId
        status
      }
      CandidateProfile {
        id
        ScalisUser {
          id
          firstName
          lastName
          profileImage
          mostRecentJob: Experience(onlyMostRecentJob: true) {
            id
            industry
            jobTitle
            employerName
          }
          currentJob: Experience(onlyCurrentJob: true) {
            id
            jobTitle
            employerName
          }
          highestEducation: Education(highestEducation: true) {
            id
            educationLevel
            institutionName
          }
          Address {
            country
            city
            state
          }
          EmailVerification {
            emailAddress
            isVisibleToEmployers
          }
          JobSeekerInfo {
            jobSeekerId
          }
          ExperienceTenure {
            average
            longest
            shortest
          }
        }
        UploadedCandidate {
          emailAddress
          firstName
          id
          lastName
        }
        ProfileDetails {
          phoneNumber
          CandidateSource {
            candidateSourceName {
              name
              sourceCategory
            }
          }
        }
      }
      JobListing {
        publicListingId
      }
      CandidateScorecard {
        id
        status
        scorecardTemplateId
      }
    }
  }
`

/**
 * __useGetCandidateApplicationQuery__
 *
 * To run a query within a React component, call `useGetCandidateApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateApplicationQuery({
 *   variables: {
 *      publicListingIds: // value for 'publicListingIds'
 *      templateStageIds: // value for 'templateStageIds'
 *      matchScore: // value for 'matchScore'
 *      candidateProfileId: // value for 'candidateProfileId'
 *      skills: // value for 'skills'
 *      lastActivity: // value for 'lastActivity'
 *      applicationStatus: // value for 'applicationStatus'
 *      applicationStatusNotIn: // value for 'applicationStatusNotIn'
 *      applicationType: // value for 'applicationType'
 *      sourceName: // value for 'sourceName'
 *      medalistDesignation: // value for 'medalistDesignation'
 *      creditedCompanyUserIds: // value for 'creditedCompanyUserIds'
 *      companies: // value for 'companies'
 *      educationLevel: // value for 'educationLevel'
 *      jobTitles: // value for 'jobTitles'
 *      majors: // value for 'majors'
 *      nonTraditionalStatus: // value for 'nonTraditionalStatus'
 *      schools: // value for 'schools'
 *      timezones: // value for 'timezones'
 *      veteranStatus: // value for 'veteranStatus'
 *      willingToRelocate: // value for 'willingToRelocate'
 *      yearsOfExperience: // value for 'yearsOfExperience'
 *      requisitionId: // value for 'requisitionId'
 *      profileType: // value for 'profileType'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCandidateApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCandidateApplicationQuery, GetCandidateApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateApplicationQuery, GetCandidateApplicationQueryVariables>(
    GetCandidateApplicationDocument,
    options,
  )
}
export function useGetCandidateApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateApplicationQuery, GetCandidateApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateApplicationQuery, GetCandidateApplicationQueryVariables>(
    GetCandidateApplicationDocument,
    options,
  )
}
export function useGetCandidateApplicationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateApplicationQuery, GetCandidateApplicationQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateApplicationQuery, GetCandidateApplicationQueryVariables>(
    GetCandidateApplicationDocument,
    options,
  )
}
export type GetCandidateApplicationQueryHookResult = ReturnType<typeof useGetCandidateApplicationQuery>
export type GetCandidateApplicationLazyQueryHookResult = ReturnType<typeof useGetCandidateApplicationLazyQuery>
export type GetCandidateApplicationSuspenseQueryHookResult = ReturnType<typeof useGetCandidateApplicationSuspenseQuery>
export type GetCandidateApplicationQueryResult = Apollo.QueryResult<
  GetCandidateApplicationQuery,
  GetCandidateApplicationQueryVariables
>
export const CountCandidateApplicationsDocument = gql`
  query CountCandidateApplications($scalisUserId: Int) {
    InterviewRequests: CountCandidateApplication(applicationStatus: INTERVIEW_REQUESTED, scalisUserId: $scalisUserId) {
      count
    }
    ActiveApplications: CountCandidateApplication(
      applicationStatusNotIn: [INVITE_DECLINED, REJECTED, PROSPECT]
      scalisUserId: $scalisUserId
    ) {
      count
    }
    InterviewScheduled: CountCandidateApplication(applicationStatus: INTERVIEW_SCHEDULED, scalisUserId: $scalisUserId) {
      count
    }
    TotalApplications: CountCandidateApplication(scalisUserId: $scalisUserId) {
      count
    }
  }
`

/**
 * __useCountCandidateApplicationsQuery__
 *
 * To run a query within a React component, call `useCountCandidateApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCandidateApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCandidateApplicationsQuery({
 *   variables: {
 *      scalisUserId: // value for 'scalisUserId'
 *   },
 * });
 */
export function useCountCandidateApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<CountCandidateApplicationsQuery, CountCandidateApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CountCandidateApplicationsQuery, CountCandidateApplicationsQueryVariables>(
    CountCandidateApplicationsDocument,
    options,
  )
}
export function useCountCandidateApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountCandidateApplicationsQuery, CountCandidateApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CountCandidateApplicationsQuery, CountCandidateApplicationsQueryVariables>(
    CountCandidateApplicationsDocument,
    options,
  )
}
export function useCountCandidateApplicationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CountCandidateApplicationsQuery, CountCandidateApplicationsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CountCandidateApplicationsQuery, CountCandidateApplicationsQueryVariables>(
    CountCandidateApplicationsDocument,
    options,
  )
}
export type CountCandidateApplicationsQueryHookResult = ReturnType<typeof useCountCandidateApplicationsQuery>
export type CountCandidateApplicationsLazyQueryHookResult = ReturnType<typeof useCountCandidateApplicationsLazyQuery>
export type CountCandidateApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useCountCandidateApplicationsSuspenseQuery
>
export type CountCandidateApplicationsQueryResult = Apollo.QueryResult<
  CountCandidateApplicationsQuery,
  CountCandidateApplicationsQueryVariables
>
export const DetailCandidateApplicationDocument = gql`
  query DetailCandidateApplication($id: Int!) {
    GetCandidateApplication(id: $id) {
      CandidateProfile {
        scalisUserId
      }
      JobListing {
        externalTitle
        JobListingJobPipeline {
          JobPipeline {
            title
            TemplatePipeline {
              TemplateMilestones {
                TemplateStage {
                  ...TemplateStageFragment
                  id
                  name
                  visibility
                }
              }
            }
          }
        }
      }
      Company {
        CompanyInfo {
          ...CompanyInfoFragment
          companyName
          logo
        }
      }
      applicationStatus
      candidateProfileId
      jobListingId
      templateStageId
    }
  }
  ${TemplateStageFragmentFragmentDoc}
  ${CompanyInfoFragmentFragmentDoc}
`

/**
 * __useDetailCandidateApplicationQuery__
 *
 * To run a query within a React component, call `useDetailCandidateApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailCandidateApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailCandidateApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailCandidateApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<DetailCandidateApplicationQuery, DetailCandidateApplicationQueryVariables> &
    ({ variables: DetailCandidateApplicationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DetailCandidateApplicationQuery, DetailCandidateApplicationQueryVariables>(
    DetailCandidateApplicationDocument,
    options,
  )
}
export function useDetailCandidateApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DetailCandidateApplicationQuery, DetailCandidateApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DetailCandidateApplicationQuery, DetailCandidateApplicationQueryVariables>(
    DetailCandidateApplicationDocument,
    options,
  )
}
export function useDetailCandidateApplicationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DetailCandidateApplicationQuery, DetailCandidateApplicationQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DetailCandidateApplicationQuery, DetailCandidateApplicationQueryVariables>(
    DetailCandidateApplicationDocument,
    options,
  )
}
export type DetailCandidateApplicationQueryHookResult = ReturnType<typeof useDetailCandidateApplicationQuery>
export type DetailCandidateApplicationLazyQueryHookResult = ReturnType<typeof useDetailCandidateApplicationLazyQuery>
export type DetailCandidateApplicationSuspenseQueryHookResult = ReturnType<
  typeof useDetailCandidateApplicationSuspenseQuery
>
export type DetailCandidateApplicationQueryResult = Apollo.QueryResult<
  DetailCandidateApplicationQuery,
  DetailCandidateApplicationQueryVariables
>
export const DetailJobListingDocument = gql`
  query DetailJobListing($id: Int, $jobRequisitionId: Int, $page: Int, $limit: Int) {
    GetJobListing(id: $id, jobRequisitionId: $jobRequisitionId, page: $page, limit: $limit) {
      externalTitle
      publicDescription
      postedAt
      workplaceType
      Requisition {
        Company {
          Address {
            ...AddressFragment
            city
            country
            state
          }
          CompanyInfo {
            ...CompanyInfoFragment
            companyName
            logo
          }
        }
        RequisitionSkills {
          ...RequisitionSkillsFragment
          skill {
            ...SkillFragment
          }
        }
        benefits
        jobLevel
        jobType
        maxPay
        minPay
        payType
      }
    }
  }
  ${AddressFragmentFragmentDoc}
  ${CompanyInfoFragmentFragmentDoc}
  ${RequisitionSkillsFragmentFragmentDoc}
  ${SkillFragmentFragmentDoc}
`

/**
 * __useDetailJobListingQuery__
 *
 * To run a query within a React component, call `useDetailJobListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailJobListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailJobListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      jobRequisitionId: // value for 'jobRequisitionId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDetailJobListingQuery(
  baseOptions?: Apollo.QueryHookOptions<DetailJobListingQuery, DetailJobListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DetailJobListingQuery, DetailJobListingQueryVariables>(DetailJobListingDocument, options)
}
export function useDetailJobListingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DetailJobListingQuery, DetailJobListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DetailJobListingQuery, DetailJobListingQueryVariables>(DetailJobListingDocument, options)
}
export function useDetailJobListingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DetailJobListingQuery, DetailJobListingQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DetailJobListingQuery, DetailJobListingQueryVariables>(
    DetailJobListingDocument,
    options,
  )
}
export type DetailJobListingQueryHookResult = ReturnType<typeof useDetailJobListingQuery>
export type DetailJobListingLazyQueryHookResult = ReturnType<typeof useDetailJobListingLazyQuery>
export type DetailJobListingSuspenseQueryHookResult = ReturnType<typeof useDetailJobListingSuspenseQuery>
export type DetailJobListingQueryResult = Apollo.QueryResult<DetailJobListingQuery, DetailJobListingQueryVariables>
export const ListApplicantNoteDocument = gql`
  query ListApplicantNote($candidateApplicationId: Int) {
    GetApplicantNotes(candidateApplicationId: $candidateApplicationId) {
      id
      createdAt
      candidateApplicationId
      scalisUserId
      UserNote {
        ...UserNoteFragment
        id
        noteText
      }
    }
  }
  ${UserNoteFragmentFragmentDoc}
`

/**
 * __useListApplicantNoteQuery__
 *
 * To run a query within a React component, call `useListApplicantNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApplicantNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApplicantNoteQuery({
 *   variables: {
 *      candidateApplicationId: // value for 'candidateApplicationId'
 *   },
 * });
 */
export function useListApplicantNoteQuery(
  baseOptions?: Apollo.QueryHookOptions<ListApplicantNoteQuery, ListApplicantNoteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListApplicantNoteQuery, ListApplicantNoteQueryVariables>(ListApplicantNoteDocument, options)
}
export function useListApplicantNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListApplicantNoteQuery, ListApplicantNoteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListApplicantNoteQuery, ListApplicantNoteQueryVariables>(
    ListApplicantNoteDocument,
    options,
  )
}
export function useListApplicantNoteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListApplicantNoteQuery, ListApplicantNoteQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListApplicantNoteQuery, ListApplicantNoteQueryVariables>(
    ListApplicantNoteDocument,
    options,
  )
}
export type ListApplicantNoteQueryHookResult = ReturnType<typeof useListApplicantNoteQuery>
export type ListApplicantNoteLazyQueryHookResult = ReturnType<typeof useListApplicantNoteLazyQuery>
export type ListApplicantNoteSuspenseQueryHookResult = ReturnType<typeof useListApplicantNoteSuspenseQuery>
export type ListApplicantNoteQueryResult = Apollo.QueryResult<ListApplicantNoteQuery, ListApplicantNoteQueryVariables>
export const ListBookmarkedJobDocument = gql`
  query ListBookmarkedJob {
    GetBookmarkedJob {
      JobListing {
        JobListingJobPipeline {
          JobPipeline {
            id
            title
            jobRequisitionId
            templatePipelineId
          }
          id
          jobListingId
          jobPipelineId
        }
        acceptingCandidates
        deletedAt
        externalTitle
        id
        internalMatchingDescription
        listingTitle
        jobRequisitionId
        postedAt
        publicDescription
        publicListingId
        searchInternalMatchingDescription
        searchPublicDescription
        stage
        workplaceType
      }
      id
      jobListingId
      scalisUserId
    }
  }
`

/**
 * __useListBookmarkedJobQuery__
 *
 * To run a query within a React component, call `useListBookmarkedJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBookmarkedJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBookmarkedJobQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBookmarkedJobQuery(
  baseOptions?: Apollo.QueryHookOptions<ListBookmarkedJobQuery, ListBookmarkedJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListBookmarkedJobQuery, ListBookmarkedJobQueryVariables>(ListBookmarkedJobDocument, options)
}
export function useListBookmarkedJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListBookmarkedJobQuery, ListBookmarkedJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListBookmarkedJobQuery, ListBookmarkedJobQueryVariables>(
    ListBookmarkedJobDocument,
    options,
  )
}
export function useListBookmarkedJobSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListBookmarkedJobQuery, ListBookmarkedJobQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListBookmarkedJobQuery, ListBookmarkedJobQueryVariables>(
    ListBookmarkedJobDocument,
    options,
  )
}
export type ListBookmarkedJobQueryHookResult = ReturnType<typeof useListBookmarkedJobQuery>
export type ListBookmarkedJobLazyQueryHookResult = ReturnType<typeof useListBookmarkedJobLazyQuery>
export type ListBookmarkedJobSuspenseQueryHookResult = ReturnType<typeof useListBookmarkedJobSuspenseQuery>
export type ListBookmarkedJobQueryResult = Apollo.QueryResult<ListBookmarkedJobQuery, ListBookmarkedJobQueryVariables>
export const ListCandidateApplicationDocument = gql`
  query ListCandidateApplication(
    $applicationStatus: [ApplicationStatusEnum!]
    $applicationType: [ApplicationTypeEnum!]
    $id: Int
    $scalisUserId: Int
  ) {
    GetCandidateApplication(
      applicationStatus: $applicationStatus
      applicationType: $applicationType
      id: $id
      scalisUserId: $scalisUserId
    ) {
      Company {
        ...CompanyRequiredFields
        CompanyInfo {
          companyId
          companyName
          id
        }
      }
      JobListing {
        JobListingJobPipeline {
          JobPipeline {
            id
            title
            jobRequisitionId
            templatePipelineId
          }
          id
          jobListingId
          jobPipelineId
        }
        acceptingCandidates
        deletedAt
        externalTitle
        id
        internalMatchingDescription
        jobRequisitionId
        listingTitle
        postedAt
        publicDescription
        publicListingId
        searchInternalMatchingDescription
        searchPublicDescription
        stage
        workplaceType
      }
      TemplateStage {
        TemplateMilestone {
          archived
          id
          name
          rankOrder
          templatePipelineId
        }
        archived
        createdAt
        id
        name
        rankOrder
        templateMilestoneId
        templatePipelineId
        updatedAt
        visibility
      }
      Company {
        CompanyInfo {
          companyName
          logo
        }
      }
      applicationCreationType
      applicationStatus
      applicationTimestamp
      applicationType
      appliedAt
      candidateProfileId
      createdAt
      companyId
      jobListingId
      deleted
      id
      matchScore
      lastUpdatedStatusAt
      templateStageId
      updatedAt
      viewedProfile
      viewedAt
    }
  }
  ${CompanyRequiredFieldsFragmentDoc}
`

/**
 * __useListCandidateApplicationQuery__
 *
 * To run a query within a React component, call `useListCandidateApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCandidateApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCandidateApplicationQuery({
 *   variables: {
 *      applicationStatus: // value for 'applicationStatus'
 *      applicationType: // value for 'applicationType'
 *      id: // value for 'id'
 *      scalisUserId: // value for 'scalisUserId'
 *   },
 * });
 */
export function useListCandidateApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCandidateApplicationQuery, ListCandidateApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListCandidateApplicationQuery, ListCandidateApplicationQueryVariables>(
    ListCandidateApplicationDocument,
    options,
  )
}
export function useListCandidateApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCandidateApplicationQuery, ListCandidateApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListCandidateApplicationQuery, ListCandidateApplicationQueryVariables>(
    ListCandidateApplicationDocument,
    options,
  )
}
export function useListCandidateApplicationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListCandidateApplicationQuery, ListCandidateApplicationQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListCandidateApplicationQuery, ListCandidateApplicationQueryVariables>(
    ListCandidateApplicationDocument,
    options,
  )
}
export type ListCandidateApplicationQueryHookResult = ReturnType<typeof useListCandidateApplicationQuery>
export type ListCandidateApplicationLazyQueryHookResult = ReturnType<typeof useListCandidateApplicationLazyQuery>
export type ListCandidateApplicationSuspenseQueryHookResult = ReturnType<
  typeof useListCandidateApplicationSuspenseQuery
>
export type ListCandidateApplicationQueryResult = Apollo.QueryResult<
  ListCandidateApplicationQuery,
  ListCandidateApplicationQueryVariables
>
export const GetBookmarkedJobDocument = gql`
  query GetBookmarkedJob(
    $limit: Int
    $listingTitleSearch: String
    $sortBy: SortByBoomarkedJob
    $sortDirection: SortDirection
    $page: Int
  ) {
    GetBookmarkedJob(
      sortDirection: $sortDirection
      sortBy: $sortBy
      page: $page
      listingTitleSearch: $listingTitleSearch
      limit: $limit
    ) {
      id
      JobListing {
        id
        Address {
          activeStatus
          addressLine1
          addressLine2
          addressType
          city
          country
          id
          state
          postalCode
          timeZone
        }
        externalTitle
        publicListingId
        Requisition {
          id
          Company {
            id
            CompanyInfo {
              companyName
              logo
            }
          }
        }
        stateAndOrCountry
        workplaceType
      }
    }
    CountBookmarkedJob(listingTitleSearch: $listingTitleSearch) {
      count
    }
  }
`

/**
 * __useGetBookmarkedJobQuery__
 *
 * To run a query within a React component, call `useGetBookmarkedJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarkedJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarkedJobQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      listingTitleSearch: // value for 'listingTitleSearch'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetBookmarkedJobQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBookmarkedJobQuery, GetBookmarkedJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBookmarkedJobQuery, GetBookmarkedJobQueryVariables>(GetBookmarkedJobDocument, options)
}
export function useGetBookmarkedJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookmarkedJobQuery, GetBookmarkedJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBookmarkedJobQuery, GetBookmarkedJobQueryVariables>(GetBookmarkedJobDocument, options)
}
export function useGetBookmarkedJobSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetBookmarkedJobQuery, GetBookmarkedJobQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetBookmarkedJobQuery, GetBookmarkedJobQueryVariables>(
    GetBookmarkedJobDocument,
    options,
  )
}
export type GetBookmarkedJobQueryHookResult = ReturnType<typeof useGetBookmarkedJobQuery>
export type GetBookmarkedJobLazyQueryHookResult = ReturnType<typeof useGetBookmarkedJobLazyQuery>
export type GetBookmarkedJobSuspenseQueryHookResult = ReturnType<typeof useGetBookmarkedJobSuspenseQuery>
export type GetBookmarkedJobQueryResult = Apollo.QueryResult<GetBookmarkedJobQuery, GetBookmarkedJobQueryVariables>
export const GetJobseekerDashboardApplicationsDocument = gql`
  query GetJobseekerDashboardApplications(
    $limit: Int! = 10
    $page: Int! = 1
    $sortBy: SortByApplication
    $sortDirection: SortDirection
    $search: String
  ) {
    GetCandidateApplication(
      limit: $limit
      page: $page
      sortBy: $sortBy
      sortDirection: $sortDirection
      templateMilestoneNotIn: SOURCING
      search: $search
    ) {
      id
      applicationStatus
      appliedAt
      Company {
        Address {
          city
          state
          country
        }
        CompanyInfo {
          companyName
          logo
        }
      }
      JobListing {
        externalTitle
        publicListingId
        id
        stateAndOrCountry
        workplaceType
        Address {
          timeZone
          state
          postalCode
          longitude
          latitude
          id
          country
          city
          addressType
          addressLine2
          addressLine1
          activeStatus
        }
      }
      LastPublicTemplateStage {
        id
        name
        TemplateMilestone {
          name
        }
      }
      applicationType
      TemplateStage {
        name
        id
        TemplateMilestone {
          name
          id
        }
      }
    }
    CountCandidateApplication(templateMilestoneNotIn: SOURCING, search: $search) {
      count
    }
  }
`

/**
 * __useGetJobseekerDashboardApplicationsQuery__
 *
 * To run a query within a React component, call `useGetJobseekerDashboardApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobseekerDashboardApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobseekerDashboardApplicationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetJobseekerDashboardApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetJobseekerDashboardApplicationsQuery,
    GetJobseekerDashboardApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobseekerDashboardApplicationsQuery, GetJobseekerDashboardApplicationsQueryVariables>(
    GetJobseekerDashboardApplicationsDocument,
    options,
  )
}
export function useGetJobseekerDashboardApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobseekerDashboardApplicationsQuery,
    GetJobseekerDashboardApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobseekerDashboardApplicationsQuery, GetJobseekerDashboardApplicationsQueryVariables>(
    GetJobseekerDashboardApplicationsDocument,
    options,
  )
}
export function useGetJobseekerDashboardApplicationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetJobseekerDashboardApplicationsQuery,
        GetJobseekerDashboardApplicationsQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetJobseekerDashboardApplicationsQuery,
    GetJobseekerDashboardApplicationsQueryVariables
  >(GetJobseekerDashboardApplicationsDocument, options)
}
export type GetJobseekerDashboardApplicationsQueryHookResult = ReturnType<
  typeof useGetJobseekerDashboardApplicationsQuery
>
export type GetJobseekerDashboardApplicationsLazyQueryHookResult = ReturnType<
  typeof useGetJobseekerDashboardApplicationsLazyQuery
>
export type GetJobseekerDashboardApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useGetJobseekerDashboardApplicationsSuspenseQuery
>
export type GetJobseekerDashboardApplicationsQueryResult = Apollo.QueryResult<
  GetJobseekerDashboardApplicationsQuery,
  GetJobseekerDashboardApplicationsQueryVariables
>
export const GetJobseekerDashboardMetricsDocument = gql`
  query GetJobseekerDashboardMetrics {
    active: CountCandidateApplication(applicationStatusNotIn: [REJECTED]) {
      count
    }
    bookmarked: CountBookmarkedJob {
      count
    }
    interviews: CountCandidateApplication(applicationStatusNotIn: [INTERVIEW_COMPLETED, PROSPECT, REJECTED]) {
      count
    }
    invites: CountCandidateApplication(applicationStatus: [INVITED_TO_APPLY, INVITE_DECLINED]) {
      count
    }
    rejected: CountCandidateApplication(applicationStatus: [REJECTED]) {
      count
    }
    total: CountCandidateApplication {
      count
    }
  }
`

/**
 * __useGetJobseekerDashboardMetricsQuery__
 *
 * To run a query within a React component, call `useGetJobseekerDashboardMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobseekerDashboardMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobseekerDashboardMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobseekerDashboardMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobseekerDashboardMetricsQuery, GetJobseekerDashboardMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobseekerDashboardMetricsQuery, GetJobseekerDashboardMetricsQueryVariables>(
    GetJobseekerDashboardMetricsDocument,
    options,
  )
}
export function useGetJobseekerDashboardMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobseekerDashboardMetricsQuery,
    GetJobseekerDashboardMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobseekerDashboardMetricsQuery, GetJobseekerDashboardMetricsQueryVariables>(
    GetJobseekerDashboardMetricsDocument,
    options,
  )
}
export function useGetJobseekerDashboardMetricsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobseekerDashboardMetricsQuery, GetJobseekerDashboardMetricsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobseekerDashboardMetricsQuery, GetJobseekerDashboardMetricsQueryVariables>(
    GetJobseekerDashboardMetricsDocument,
    options,
  )
}
export type GetJobseekerDashboardMetricsQueryHookResult = ReturnType<typeof useGetJobseekerDashboardMetricsQuery>
export type GetJobseekerDashboardMetricsLazyQueryHookResult = ReturnType<
  typeof useGetJobseekerDashboardMetricsLazyQuery
>
export type GetJobseekerDashboardMetricsSuspenseQueryHookResult = ReturnType<
  typeof useGetJobseekerDashboardMetricsSuspenseQuery
>
export type GetJobseekerDashboardMetricsQueryResult = Apollo.QueryResult<
  GetJobseekerDashboardMetricsQuery,
  GetJobseekerDashboardMetricsQueryVariables
>
export const GetExternalJobApplicationDocument = gql`
  query GetExternalJobApplication(
    $status: [ExternalApplicationStatus!]
    $search: String
    $limit: Int
    $page: Int
    $sortBy: SortByExternalApplication
    $sortDirection: SortDirection
  ) {
    GetExternalJobApplication(
      status: $status
      search: $search
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      status
      location
      jobUrl
      jobTitle
      jobId
      id
      employerName
      appliedAt
      updatedAt
    }
    CountExternalJobApplication(status: $status, search: $search) {
      count
    }
  }
`

/**
 * __useGetExternalJobApplicationQuery__
 *
 * To run a query within a React component, call `useGetExternalJobApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalJobApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalJobApplicationQuery({
 *   variables: {
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetExternalJobApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExternalJobApplicationQuery, GetExternalJobApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetExternalJobApplicationQuery, GetExternalJobApplicationQueryVariables>(
    GetExternalJobApplicationDocument,
    options,
  )
}
export function useGetExternalJobApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExternalJobApplicationQuery, GetExternalJobApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetExternalJobApplicationQuery, GetExternalJobApplicationQueryVariables>(
    GetExternalJobApplicationDocument,
    options,
  )
}
export function useGetExternalJobApplicationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetExternalJobApplicationQuery, GetExternalJobApplicationQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetExternalJobApplicationQuery, GetExternalJobApplicationQueryVariables>(
    GetExternalJobApplicationDocument,
    options,
  )
}
export type GetExternalJobApplicationQueryHookResult = ReturnType<typeof useGetExternalJobApplicationQuery>
export type GetExternalJobApplicationLazyQueryHookResult = ReturnType<typeof useGetExternalJobApplicationLazyQuery>
export type GetExternalJobApplicationSuspenseQueryHookResult = ReturnType<
  typeof useGetExternalJobApplicationSuspenseQuery
>
export type GetExternalJobApplicationQueryResult = Apollo.QueryResult<
  GetExternalJobApplicationQuery,
  GetExternalJobApplicationQueryVariables
>
export const GetJobSeekerJobInvitesDocument = gql`
  query GetJobSeekerJobInvites(
    $listingTitleSearch: String
    $sortBy: SortByJobInvite
    $sortDirection: SortDirection
    $page: Int
    $limit: Int
  ) {
    CountJobInvite {
      count
    }
    GetJobInvite(
      inviteStatus: [SENT]
      sortDirection: $sortDirection
      sortBy: $sortBy
      page: $page
      limit: $limit
      listingTitleSearch: $listingTitleSearch
    ) {
      token
      sentAt
      jobListingId
      inviteStatus
      id
      dismissed
      candidateApplicationId
      JobListing {
        id
        listingTitle
        publicListingId
        externalTitle
        Requisition {
          Company {
            id
            CompanyInfo {
              companyName
              id
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetJobSeekerJobInvitesQuery__
 *
 * To run a query within a React component, call `useGetJobSeekerJobInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobSeekerJobInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobSeekerJobInvitesQuery({
 *   variables: {
 *      listingTitleSearch: // value for 'listingTitleSearch'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetJobSeekerJobInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobSeekerJobInvitesQuery, GetJobSeekerJobInvitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobSeekerJobInvitesQuery, GetJobSeekerJobInvitesQueryVariables>(
    GetJobSeekerJobInvitesDocument,
    options,
  )
}
export function useGetJobSeekerJobInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobSeekerJobInvitesQuery, GetJobSeekerJobInvitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobSeekerJobInvitesQuery, GetJobSeekerJobInvitesQueryVariables>(
    GetJobSeekerJobInvitesDocument,
    options,
  )
}
export function useGetJobSeekerJobInvitesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetJobSeekerJobInvitesQuery, GetJobSeekerJobInvitesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobSeekerJobInvitesQuery, GetJobSeekerJobInvitesQueryVariables>(
    GetJobSeekerJobInvitesDocument,
    options,
  )
}
export type GetJobSeekerJobInvitesQueryHookResult = ReturnType<typeof useGetJobSeekerJobInvitesQuery>
export type GetJobSeekerJobInvitesLazyQueryHookResult = ReturnType<typeof useGetJobSeekerJobInvitesLazyQuery>
export type GetJobSeekerJobInvitesSuspenseQueryHookResult = ReturnType<typeof useGetJobSeekerJobInvitesSuspenseQuery>
export type GetJobSeekerJobInvitesQueryResult = Apollo.QueryResult<
  GetJobSeekerJobInvitesQuery,
  GetJobSeekerJobInvitesQueryVariables
>
export const GetScalisUserDocument = gql`
  query GetScalisUser($id: Int!) {
    GetScalisUser(id: $id) {
      id
      Address {
        activeStatus
        addressLine1
        addressLine2
        city
        country
        contactName
        addressType
        createdAt
        id
        latitude
        longitude
        state
        timeZone
        updatedAt
        postalCode
      }
      JobSeekerInfo {
        canStartNewJob
        consideringOffers
        currPrefRoles
        createdAt
        desiredPay
        employStatus
        id
        jobSeekerId
        jobLevel
        jobType
        locationsWillRelocate
        jobTypePref
        messageUserId
        nonTraditionalStatus
        searchStatus
        payType
        searchingJobs
        teamSize
        updatedAt
        tryingToRelocate
        yearsOfExperience
        willingToRelocate
        yearsInWorkforce
      }
      completedAt
      currentStage
      profileImage
      ScalisUserUploadedDocument {
        id
        documentPath
        documentName
        documentType
        createdAt
        deletedAt
      }
    }
  }
`

/**
 * __useGetScalisUserQuery__
 *
 * To run a query within a React component, call `useGetScalisUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScalisUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScalisUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScalisUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetScalisUserQuery, GetScalisUserQueryVariables> &
    ({ variables: GetScalisUserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetScalisUserQuery, GetScalisUserQueryVariables>(GetScalisUserDocument, options)
}
export function useGetScalisUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScalisUserQuery, GetScalisUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetScalisUserQuery, GetScalisUserQueryVariables>(GetScalisUserDocument, options)
}
export function useGetScalisUserSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetScalisUserQuery, GetScalisUserQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetScalisUserQuery, GetScalisUserQueryVariables>(GetScalisUserDocument, options)
}
export type GetScalisUserQueryHookResult = ReturnType<typeof useGetScalisUserQuery>
export type GetScalisUserLazyQueryHookResult = ReturnType<typeof useGetScalisUserLazyQuery>
export type GetScalisUserSuspenseQueryHookResult = ReturnType<typeof useGetScalisUserSuspenseQuery>
export type GetScalisUserQueryResult = Apollo.QueryResult<GetScalisUserQuery, GetScalisUserQueryVariables>
export const ListJobDocument = gql`
  query ListJob {
    GetJobListing {
      acceptingCandidates
      deletedAt
      externalTitle
      id
      internalMatchingDescription
      jobRequisitionId
      listingTitle
      postedAt
      publicDescription
      publicListingId
      searchPublicDescription
      searchInternalMatchingDescription
      stage
      workplaceType
      Requisition {
        jobLevel
        Company {
          Address {
            ...AddressFragment
          }
          CompanyInfo {
            companyId
            ...CompanyInfoFragment
          }
        }
        jobType
      }
    }
  }
  ${AddressFragmentFragmentDoc}
  ${CompanyInfoFragmentFragmentDoc}
`

/**
 * __useListJobQuery__
 *
 * To run a query within a React component, call `useListJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJobQuery({
 *   variables: {
 *   },
 * });
 */
export function useListJobQuery(baseOptions?: Apollo.QueryHookOptions<ListJobQuery, ListJobQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListJobQuery, ListJobQueryVariables>(ListJobDocument, options)
}
export function useListJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListJobQuery, ListJobQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListJobQuery, ListJobQueryVariables>(ListJobDocument, options)
}
export function useListJobSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListJobQuery, ListJobQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListJobQuery, ListJobQueryVariables>(ListJobDocument, options)
}
export type ListJobQueryHookResult = ReturnType<typeof useListJobQuery>
export type ListJobLazyQueryHookResult = ReturnType<typeof useListJobLazyQuery>
export type ListJobSuspenseQueryHookResult = ReturnType<typeof useListJobSuspenseQuery>
export type ListJobQueryResult = Apollo.QueryResult<ListJobQuery, ListJobQueryVariables>
export const LoggedUserDocument = gql`
  query LoggedUser {
    Me {
      id
      firstName
      lastName
      completedAt
      currentStage
      profileImage
      JobSeekerInfo {
        aboutMe
      }
      Address {
        city
        state
        country
      }
      Achievement {
        id
        name
        issuingAuthority
        issueYear
        description
      }
      CertificateOrLicense {
        id
        name
        issuingAuthority
        issueYear
        description
      }
      JobSeekerSkill {
        id
        sequenceNumber
        level
        name
        yearsOfExp
      }
      DemographicInfo {
        gender
        raceEthnicity
        veteranStatus
      }
      WebsiteOrSocialMedia {
        id
        websiteURL
      }
      VolunteerWork {
        id
        roleType
        volunteerJobTitle
        organizationName
        location
        startDate
        isCurrentlyWorking
        description
      }
      Experience {
        id
        jobTitle
        employerName
        jobType
        locationType
        location
        startDate
        isCurrentlyWorking
        isCurrentJob
        description
      }
      Education {
        id
        institutionName
        educationLevel
        areasOfStudy
        startDate
        endDate
        cumGPA
        cumGPAScale
      }
    }
  }
`

/**
 * __useLoggedUserQuery__
 *
 * To run a query within a React component, call `useLoggedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedUserQuery(baseOptions?: Apollo.QueryHookOptions<LoggedUserQuery, LoggedUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoggedUserQuery, LoggedUserQueryVariables>(LoggedUserDocument, options)
}
export function useLoggedUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoggedUserQuery, LoggedUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoggedUserQuery, LoggedUserQueryVariables>(LoggedUserDocument, options)
}
export function useLoggedUserSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LoggedUserQuery, LoggedUserQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoggedUserQuery, LoggedUserQueryVariables>(LoggedUserDocument, options)
}
export type LoggedUserQueryHookResult = ReturnType<typeof useLoggedUserQuery>
export type LoggedUserLazyQueryHookResult = ReturnType<typeof useLoggedUserLazyQuery>
export type LoggedUserSuspenseQueryHookResult = ReturnType<typeof useLoggedUserSuspenseQuery>
export type LoggedUserQueryResult = Apollo.QueryResult<LoggedUserQuery, LoggedUserQueryVariables>
export const GetChatUsersJsv2Document = gql`
  query GetChatUsersJSV2 {
    GetChatUsersJSV2 {
      firstName
      lastName
      userId
    }
  }
`

/**
 * __useGetChatUsersJsv2Query__
 *
 * To run a query within a React component, call `useGetChatUsersJsv2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetChatUsersJsv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatUsersJsv2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetChatUsersJsv2Query(
  baseOptions?: Apollo.QueryHookOptions<GetChatUsersJsv2Query, GetChatUsersJsv2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetChatUsersJsv2Query, GetChatUsersJsv2QueryVariables>(GetChatUsersJsv2Document, options)
}
export function useGetChatUsersJsv2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatUsersJsv2Query, GetChatUsersJsv2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetChatUsersJsv2Query, GetChatUsersJsv2QueryVariables>(GetChatUsersJsv2Document, options)
}
export function useGetChatUsersJsv2SuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetChatUsersJsv2Query, GetChatUsersJsv2QueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetChatUsersJsv2Query, GetChatUsersJsv2QueryVariables>(
    GetChatUsersJsv2Document,
    options,
  )
}
export type GetChatUsersJsv2QueryHookResult = ReturnType<typeof useGetChatUsersJsv2Query>
export type GetChatUsersJsv2LazyQueryHookResult = ReturnType<typeof useGetChatUsersJsv2LazyQuery>
export type GetChatUsersJsv2SuspenseQueryHookResult = ReturnType<typeof useGetChatUsersJsv2SuspenseQuery>
export type GetChatUsersJsv2QueryResult = Apollo.QueryResult<GetChatUsersJsv2Query, GetChatUsersJsv2QueryVariables>
export const GetChatUsersV2Document = gql`
  query GetChatUsersV2 {
    GetChatUsersV2 {
      firstName
      lastName
      userId
    }
  }
`

/**
 * __useGetChatUsersV2Query__
 *
 * To run a query within a React component, call `useGetChatUsersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetChatUsersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatUsersV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetChatUsersV2Query(
  baseOptions?: Apollo.QueryHookOptions<GetChatUsersV2Query, GetChatUsersV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetChatUsersV2Query, GetChatUsersV2QueryVariables>(GetChatUsersV2Document, options)
}
export function useGetChatUsersV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatUsersV2Query, GetChatUsersV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetChatUsersV2Query, GetChatUsersV2QueryVariables>(GetChatUsersV2Document, options)
}
export function useGetChatUsersV2SuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChatUsersV2Query, GetChatUsersV2QueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetChatUsersV2Query, GetChatUsersV2QueryVariables>(GetChatUsersV2Document, options)
}
export type GetChatUsersV2QueryHookResult = ReturnType<typeof useGetChatUsersV2Query>
export type GetChatUsersV2LazyQueryHookResult = ReturnType<typeof useGetChatUsersV2LazyQuery>
export type GetChatUsersV2SuspenseQueryHookResult = ReturnType<typeof useGetChatUsersV2SuspenseQuery>
export type GetChatUsersV2QueryResult = Apollo.QueryResult<GetChatUsersV2Query, GetChatUsersV2QueryVariables>
export const GetChatsJsv2Document = gql`
  query GetChatsJSV2 {
    GetChatsJSV2 {
      firstName
      lastName
      companyName
      chatId
      userId
    }
  }
`

/**
 * __useGetChatsJsv2Query__
 *
 * To run a query within a React component, call `useGetChatsJsv2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsJsv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsJsv2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetChatsJsv2Query(
  baseOptions?: Apollo.QueryHookOptions<GetChatsJsv2Query, GetChatsJsv2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetChatsJsv2Query, GetChatsJsv2QueryVariables>(GetChatsJsv2Document, options)
}
export function useGetChatsJsv2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatsJsv2Query, GetChatsJsv2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetChatsJsv2Query, GetChatsJsv2QueryVariables>(GetChatsJsv2Document, options)
}
export function useGetChatsJsv2SuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChatsJsv2Query, GetChatsJsv2QueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetChatsJsv2Query, GetChatsJsv2QueryVariables>(GetChatsJsv2Document, options)
}
export type GetChatsJsv2QueryHookResult = ReturnType<typeof useGetChatsJsv2Query>
export type GetChatsJsv2LazyQueryHookResult = ReturnType<typeof useGetChatsJsv2LazyQuery>
export type GetChatsJsv2SuspenseQueryHookResult = ReturnType<typeof useGetChatsJsv2SuspenseQuery>
export type GetChatsJsv2QueryResult = Apollo.QueryResult<GetChatsJsv2Query, GetChatsJsv2QueryVariables>
export const GetChatsV2Document = gql`
  query GetChatsV2 {
    GetChatsV2 {
      firstName
      lastName
      userId
      chatId
    }
  }
`

/**
 * __useGetChatsV2Query__
 *
 * To run a query within a React component, call `useGetChatsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetChatsV2Query(baseOptions?: Apollo.QueryHookOptions<GetChatsV2Query, GetChatsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetChatsV2Query, GetChatsV2QueryVariables>(GetChatsV2Document, options)
}
export function useGetChatsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatsV2Query, GetChatsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetChatsV2Query, GetChatsV2QueryVariables>(GetChatsV2Document, options)
}
export function useGetChatsV2SuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChatsV2Query, GetChatsV2QueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetChatsV2Query, GetChatsV2QueryVariables>(GetChatsV2Document, options)
}
export type GetChatsV2QueryHookResult = ReturnType<typeof useGetChatsV2Query>
export type GetChatsV2LazyQueryHookResult = ReturnType<typeof useGetChatsV2LazyQuery>
export type GetChatsV2SuspenseQueryHookResult = ReturnType<typeof useGetChatsV2SuspenseQuery>
export type GetChatsV2QueryResult = Apollo.QueryResult<GetChatsV2Query, GetChatsV2QueryVariables>
export const CreateCandidateOfferFileDocument = gql`
  mutation CreateCandidateOfferFile($input: CreateCandidateOfferFileInput!) {
    CreateCandidateOfferFile(input: $input) {
      id
    }
  }
`
export type CreateCandidateOfferFileMutationFn = Apollo.MutationFunction<
  CreateCandidateOfferFileMutation,
  CreateCandidateOfferFileMutationVariables
>

/**
 * __useCreateCandidateOfferFileMutation__
 *
 * To run a mutation, you first call `useCreateCandidateOfferFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateOfferFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateOfferFileMutation, { data, loading, error }] = useCreateCandidateOfferFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCandidateOfferFileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCandidateOfferFileMutation, CreateCandidateOfferFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCandidateOfferFileMutation, CreateCandidateOfferFileMutationVariables>(
    CreateCandidateOfferFileDocument,
    options,
  )
}
export type CreateCandidateOfferFileMutationHookResult = ReturnType<typeof useCreateCandidateOfferFileMutation>
export type CreateCandidateOfferFileMutationResult = Apollo.MutationResult<CreateCandidateOfferFileMutation>
export type CreateCandidateOfferFileMutationOptions = Apollo.BaseMutationOptions<
  CreateCandidateOfferFileMutation,
  CreateCandidateOfferFileMutationVariables
>
export const DeleteCandidateOfferFileDocument = gql`
  mutation DeleteCandidateOfferFile($input: DeleteCandidateOfferFileInput!) {
    DeleteCandidateOfferFile(input: $input) {
      id
    }
  }
`
export type DeleteCandidateOfferFileMutationFn = Apollo.MutationFunction<
  DeleteCandidateOfferFileMutation,
  DeleteCandidateOfferFileMutationVariables
>

/**
 * __useDeleteCandidateOfferFileMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateOfferFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateOfferFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateOfferFileMutation, { data, loading, error }] = useDeleteCandidateOfferFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCandidateOfferFileMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCandidateOfferFileMutation, DeleteCandidateOfferFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCandidateOfferFileMutation, DeleteCandidateOfferFileMutationVariables>(
    DeleteCandidateOfferFileDocument,
    options,
  )
}
export type DeleteCandidateOfferFileMutationHookResult = ReturnType<typeof useDeleteCandidateOfferFileMutation>
export type DeleteCandidateOfferFileMutationResult = Apollo.MutationResult<DeleteCandidateOfferFileMutation>
export type DeleteCandidateOfferFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteCandidateOfferFileMutation,
  DeleteCandidateOfferFileMutationVariables
>
export const GetCandidateOfferCustomFieldDocument = gql`
  query GetCandidateOfferCustomField($input: GetCandidateOfferCustomFieldInput!) {
    GetCandidateOfferCustomField(input: $input) {
      id
      fieldValue
      candidateOfferVersionId
      offerCustomFieldId
    }
  }
`

/**
 * __useGetCandidateOfferCustomFieldQuery__
 *
 * To run a query within a React component, call `useGetCandidateOfferCustomFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateOfferCustomFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateOfferCustomFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCandidateOfferCustomFieldQuery(
  baseOptions: Apollo.QueryHookOptions<GetCandidateOfferCustomFieldQuery, GetCandidateOfferCustomFieldQueryVariables> &
    ({ variables: GetCandidateOfferCustomFieldQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateOfferCustomFieldQuery, GetCandidateOfferCustomFieldQueryVariables>(
    GetCandidateOfferCustomFieldDocument,
    options,
  )
}
export function useGetCandidateOfferCustomFieldLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCandidateOfferCustomFieldQuery,
    GetCandidateOfferCustomFieldQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateOfferCustomFieldQuery, GetCandidateOfferCustomFieldQueryVariables>(
    GetCandidateOfferCustomFieldDocument,
    options,
  )
}
export function useGetCandidateOfferCustomFieldSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateOfferCustomFieldQuery, GetCandidateOfferCustomFieldQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateOfferCustomFieldQuery, GetCandidateOfferCustomFieldQueryVariables>(
    GetCandidateOfferCustomFieldDocument,
    options,
  )
}
export type GetCandidateOfferCustomFieldQueryHookResult = ReturnType<typeof useGetCandidateOfferCustomFieldQuery>
export type GetCandidateOfferCustomFieldLazyQueryHookResult = ReturnType<
  typeof useGetCandidateOfferCustomFieldLazyQuery
>
export type GetCandidateOfferCustomFieldSuspenseQueryHookResult = ReturnType<
  typeof useGetCandidateOfferCustomFieldSuspenseQuery
>
export type GetCandidateOfferCustomFieldQueryResult = Apollo.QueryResult<
  GetCandidateOfferCustomFieldQuery,
  GetCandidateOfferCustomFieldQueryVariables
>
export const GetCandidateOfferVersionEmbeddedClaimUrlDocument = gql`
  query GetCandidateOfferVersionEmbeddedClaimUrl($input: GetCandidateOfferVersionEmbeddedClaimUrlInput!) {
    GetCandidateOfferVersionEmbeddedClaimUrl(input: $input) {
      clientId
      claimUrl
      signatureRequestId
    }
  }
`

/**
 * __useGetCandidateOfferVersionEmbeddedClaimUrlQuery__
 *
 * To run a query within a React component, call `useGetCandidateOfferVersionEmbeddedClaimUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateOfferVersionEmbeddedClaimUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateOfferVersionEmbeddedClaimUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCandidateOfferVersionEmbeddedClaimUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCandidateOfferVersionEmbeddedClaimUrlQuery,
    GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables
  > &
    ({ variables: GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCandidateOfferVersionEmbeddedClaimUrlQuery,
    GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables
  >(GetCandidateOfferVersionEmbeddedClaimUrlDocument, options)
}
export function useGetCandidateOfferVersionEmbeddedClaimUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCandidateOfferVersionEmbeddedClaimUrlQuery,
    GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCandidateOfferVersionEmbeddedClaimUrlQuery,
    GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables
  >(GetCandidateOfferVersionEmbeddedClaimUrlDocument, options)
}
export function useGetCandidateOfferVersionEmbeddedClaimUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCandidateOfferVersionEmbeddedClaimUrlQuery,
        GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCandidateOfferVersionEmbeddedClaimUrlQuery,
    GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables
  >(GetCandidateOfferVersionEmbeddedClaimUrlDocument, options)
}
export type GetCandidateOfferVersionEmbeddedClaimUrlQueryHookResult = ReturnType<
  typeof useGetCandidateOfferVersionEmbeddedClaimUrlQuery
>
export type GetCandidateOfferVersionEmbeddedClaimUrlLazyQueryHookResult = ReturnType<
  typeof useGetCandidateOfferVersionEmbeddedClaimUrlLazyQuery
>
export type GetCandidateOfferVersionEmbeddedClaimUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetCandidateOfferVersionEmbeddedClaimUrlSuspenseQuery
>
export type GetCandidateOfferVersionEmbeddedClaimUrlQueryResult = Apollo.QueryResult<
  GetCandidateOfferVersionEmbeddedClaimUrlQuery,
  GetCandidateOfferVersionEmbeddedClaimUrlQueryVariables
>
export const GetCandidateOfferDocument = gql`
  query GetCandidateOffer($candidateApplicationId: Int!) {
    GetCandidateOffer(input: { candidateApplicationId: $candidateApplicationId }) {
      id
      CandidateOfferVersion {
        ...CandidateOfferVersion
      }
    }
  }
  ${CandidateOfferVersionFragmentDoc}
`

/**
 * __useGetCandidateOfferQuery__
 *
 * To run a query within a React component, call `useGetCandidateOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateOfferQuery({
 *   variables: {
 *      candidateApplicationId: // value for 'candidateApplicationId'
 *   },
 * });
 */
export function useGetCandidateOfferQuery(
  baseOptions: Apollo.QueryHookOptions<GetCandidateOfferQuery, GetCandidateOfferQueryVariables> &
    ({ variables: GetCandidateOfferQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCandidateOfferQuery, GetCandidateOfferQueryVariables>(GetCandidateOfferDocument, options)
}
export function useGetCandidateOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateOfferQuery, GetCandidateOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCandidateOfferQuery, GetCandidateOfferQueryVariables>(
    GetCandidateOfferDocument,
    options,
  )
}
export function useGetCandidateOfferSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCandidateOfferQuery, GetCandidateOfferQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCandidateOfferQuery, GetCandidateOfferQueryVariables>(
    GetCandidateOfferDocument,
    options,
  )
}
export type GetCandidateOfferQueryHookResult = ReturnType<typeof useGetCandidateOfferQuery>
export type GetCandidateOfferLazyQueryHookResult = ReturnType<typeof useGetCandidateOfferLazyQuery>
export type GetCandidateOfferSuspenseQueryHookResult = ReturnType<typeof useGetCandidateOfferSuspenseQuery>
export type GetCandidateOfferQueryResult = Apollo.QueryResult<GetCandidateOfferQuery, GetCandidateOfferQueryVariables>
export const GetCompanyOfficeListDocument = gql`
  query GetCompanyOfficeList {
    ListCompanyOffices {
      id
      officeName
    }
  }
`

/**
 * __useGetCompanyOfficeListQuery__
 *
 * To run a query within a React component, call `useGetCompanyOfficeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOfficeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOfficeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyOfficeListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyOfficeListQuery, GetCompanyOfficeListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyOfficeListQuery, GetCompanyOfficeListQueryVariables>(
    GetCompanyOfficeListDocument,
    options,
  )
}
export function useGetCompanyOfficeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyOfficeListQuery, GetCompanyOfficeListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyOfficeListQuery, GetCompanyOfficeListQueryVariables>(
    GetCompanyOfficeListDocument,
    options,
  )
}
export function useGetCompanyOfficeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyOfficeListQuery, GetCompanyOfficeListQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyOfficeListQuery, GetCompanyOfficeListQueryVariables>(
    GetCompanyOfficeListDocument,
    options,
  )
}
export type GetCompanyOfficeListQueryHookResult = ReturnType<typeof useGetCompanyOfficeListQuery>
export type GetCompanyOfficeListLazyQueryHookResult = ReturnType<typeof useGetCompanyOfficeListLazyQuery>
export type GetCompanyOfficeListSuspenseQueryHookResult = ReturnType<typeof useGetCompanyOfficeListSuspenseQuery>
export type GetCompanyOfficeListQueryResult = Apollo.QueryResult<
  GetCompanyOfficeListQuery,
  GetCompanyOfficeListQueryVariables
>
export const GetOfferLetterDocumentTemplateDocument = gql`
  query GetOfferLetterDocumentTemplate {
    GetOfferDocumentTemplate(input: { documentType: OFFER_LETTER, documentTemplateStatus: VERIFIED }) {
      ...OfferDocumentTemplate
    }
  }
  ${OfferDocumentTemplateFragmentDoc}
`

/**
 * __useGetOfferLetterDocumentTemplateQuery__
 *
 * To run a query within a React component, call `useGetOfferLetterDocumentTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferLetterDocumentTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferLetterDocumentTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOfferLetterDocumentTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOfferLetterDocumentTemplateQuery,
    GetOfferLetterDocumentTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOfferLetterDocumentTemplateQuery, GetOfferLetterDocumentTemplateQueryVariables>(
    GetOfferLetterDocumentTemplateDocument,
    options,
  )
}
export function useGetOfferLetterDocumentTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfferLetterDocumentTemplateQuery,
    GetOfferLetterDocumentTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOfferLetterDocumentTemplateQuery, GetOfferLetterDocumentTemplateQueryVariables>(
    GetOfferLetterDocumentTemplateDocument,
    options,
  )
}
export function useGetOfferLetterDocumentTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOfferLetterDocumentTemplateQuery,
        GetOfferLetterDocumentTemplateQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetOfferLetterDocumentTemplateQuery, GetOfferLetterDocumentTemplateQueryVariables>(
    GetOfferLetterDocumentTemplateDocument,
    options,
  )
}
export type GetOfferLetterDocumentTemplateQueryHookResult = ReturnType<typeof useGetOfferLetterDocumentTemplateQuery>
export type GetOfferLetterDocumentTemplateLazyQueryHookResult = ReturnType<
  typeof useGetOfferLetterDocumentTemplateLazyQuery
>
export type GetOfferLetterDocumentTemplateSuspenseQueryHookResult = ReturnType<
  typeof useGetOfferLetterDocumentTemplateSuspenseQuery
>
export type GetOfferLetterDocumentTemplateQueryResult = Apollo.QueryResult<
  GetOfferLetterDocumentTemplateQuery,
  GetOfferLetterDocumentTemplateQueryVariables
>
export const GetMyProfileDocument = gql`
  query GetMyProfile {
    Me {
      id
      firstName
      lastName
      profileImage
      EmailVerification {
        id
        emailAddress
      }
    }
  }
`

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options)
}
export function useGetMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options)
}
export function useGetMyProfileSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options)
}
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>
export type GetMyProfileSuspenseQueryHookResult = ReturnType<typeof useGetMyProfileSuspenseQuery>
export type GetMyProfileQueryResult = Apollo.QueryResult<GetMyProfileQuery, GetMyProfileQueryVariables>
export const SendCandidateOfferDocument = gql`
  mutation SendCandidateOffer($input: SendCandidateOfferInput!) {
    SendCandidateOffer(input: $input)
  }
`
export type SendCandidateOfferMutationFn = Apollo.MutationFunction<
  SendCandidateOfferMutation,
  SendCandidateOfferMutationVariables
>

/**
 * __useSendCandidateOfferMutation__
 *
 * To run a mutation, you first call `useSendCandidateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCandidateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCandidateOfferMutation, { data, loading, error }] = useSendCandidateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCandidateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<SendCandidateOfferMutation, SendCandidateOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendCandidateOfferMutation, SendCandidateOfferMutationVariables>(
    SendCandidateOfferDocument,
    options,
  )
}
export type SendCandidateOfferMutationHookResult = ReturnType<typeof useSendCandidateOfferMutation>
export type SendCandidateOfferMutationResult = Apollo.MutationResult<SendCandidateOfferMutation>
export type SendCandidateOfferMutationOptions = Apollo.BaseMutationOptions<
  SendCandidateOfferMutation,
  SendCandidateOfferMutationVariables
>
export const UpdateCandidateOfferVersionStatusDocument = gql`
  mutation UpdateCandidateOfferVersionStatus($input: UpdateCandidateOfferVersionStatusInput!) {
    UpdateCandidateOfferVersionStatus(input: $input) {
      id
      candidateOfferId
      version
      candidateName
      jobTitle
      manager
      startDate
      jobType
      payType
      benefits
      expirationDate
      companyOfficeId
      compensation
      additionalCompensation
      offerStatus
      lastUpdatedStatusAt
      createdAt
    }
  }
`
export type UpdateCandidateOfferVersionStatusMutationFn = Apollo.MutationFunction<
  UpdateCandidateOfferVersionStatusMutation,
  UpdateCandidateOfferVersionStatusMutationVariables
>

/**
 * __useUpdateCandidateOfferVersionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateOfferVersionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateOfferVersionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateOfferVersionStatusMutation, { data, loading, error }] = useUpdateCandidateOfferVersionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidateOfferVersionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateOfferVersionStatusMutation,
    UpdateCandidateOfferVersionStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCandidateOfferVersionStatusMutation,
    UpdateCandidateOfferVersionStatusMutationVariables
  >(UpdateCandidateOfferVersionStatusDocument, options)
}
export type UpdateCandidateOfferVersionStatusMutationHookResult = ReturnType<
  typeof useUpdateCandidateOfferVersionStatusMutation
>
export type UpdateCandidateOfferVersionStatusMutationResult =
  Apollo.MutationResult<UpdateCandidateOfferVersionStatusMutation>
export type UpdateCandidateOfferVersionStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCandidateOfferVersionStatusMutation,
  UpdateCandidateOfferVersionStatusMutationVariables
>
export const UpsertCandidateOfferCustomFieldDocument = gql`
  mutation UpsertCandidateOfferCustomField($input: UpsertCandidateOfferCustomFieldInput!) {
    UpsertCandidateOfferCustomField(input: $input) {
      id
      fieldValue
      candidateOfferVersionId
      offerCustomFieldId
    }
  }
`
export type UpsertCandidateOfferCustomFieldMutationFn = Apollo.MutationFunction<
  UpsertCandidateOfferCustomFieldMutation,
  UpsertCandidateOfferCustomFieldMutationVariables
>

/**
 * __useUpsertCandidateOfferCustomFieldMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateOfferCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateOfferCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateOfferCustomFieldMutation, { data, loading, error }] = useUpsertCandidateOfferCustomFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateOfferCustomFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCandidateOfferCustomFieldMutation,
    UpsertCandidateOfferCustomFieldMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateOfferCustomFieldMutation, UpsertCandidateOfferCustomFieldMutationVariables>(
    UpsertCandidateOfferCustomFieldDocument,
    options,
  )
}
export type UpsertCandidateOfferCustomFieldMutationHookResult = ReturnType<
  typeof useUpsertCandidateOfferCustomFieldMutation
>
export type UpsertCandidateOfferCustomFieldMutationResult =
  Apollo.MutationResult<UpsertCandidateOfferCustomFieldMutation>
export type UpsertCandidateOfferCustomFieldMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateOfferCustomFieldMutation,
  UpsertCandidateOfferCustomFieldMutationVariables
>
export const UpsertCandidateOfferFileDocument = gql`
  mutation UpsertCandidateOfferFile($input: UpsertCandidateOfferFileInput!) {
    UpsertCandidateOfferFile(input: $input) {
      id
    }
  }
`
export type UpsertCandidateOfferFileMutationFn = Apollo.MutationFunction<
  UpsertCandidateOfferFileMutation,
  UpsertCandidateOfferFileMutationVariables
>

/**
 * __useUpsertCandidateOfferFileMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateOfferFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateOfferFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateOfferFileMutation, { data, loading, error }] = useUpsertCandidateOfferFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateOfferFileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertCandidateOfferFileMutation, UpsertCandidateOfferFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateOfferFileMutation, UpsertCandidateOfferFileMutationVariables>(
    UpsertCandidateOfferFileDocument,
    options,
  )
}
export type UpsertCandidateOfferFileMutationHookResult = ReturnType<typeof useUpsertCandidateOfferFileMutation>
export type UpsertCandidateOfferFileMutationResult = Apollo.MutationResult<UpsertCandidateOfferFileMutation>
export type UpsertCandidateOfferFileMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateOfferFileMutation,
  UpsertCandidateOfferFileMutationVariables
>
export const UpsertCandidateOfferDocument = gql`
  mutation UpsertCandidateOffer($input: UpsertCandidateOfferInput!) {
    UpsertCandidateOffer(input: $input) {
      id
      CandidateOfferVersion {
        ...CandidateOfferVersion
      }
    }
  }
  ${CandidateOfferVersionFragmentDoc}
`
export type UpsertCandidateOfferMutationFn = Apollo.MutationFunction<
  UpsertCandidateOfferMutation,
  UpsertCandidateOfferMutationVariables
>

/**
 * __useUpsertCandidateOfferMutation__
 *
 * To run a mutation, you first call `useUpsertCandidateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCandidateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCandidateOfferMutation, { data, loading, error }] = useUpsertCandidateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCandidateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertCandidateOfferMutation, UpsertCandidateOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertCandidateOfferMutation, UpsertCandidateOfferMutationVariables>(
    UpsertCandidateOfferDocument,
    options,
  )
}
export type UpsertCandidateOfferMutationHookResult = ReturnType<typeof useUpsertCandidateOfferMutation>
export type UpsertCandidateOfferMutationResult = Apollo.MutationResult<UpsertCandidateOfferMutation>
export type UpsertCandidateOfferMutationOptions = Apollo.BaseMutationOptions<
  UpsertCandidateOfferMutation,
  UpsertCandidateOfferMutationVariables
>
export const GetPlaceDocument = gql`
  query GetPlace($placeId: String!) {
    GetPlace(placeId: $placeId) {
      addressComponents {
        longText
        shortText
        types
      }
    }
  }
`

/**
 * __useGetPlaceQuery__
 *
 * To run a query within a React component, call `useGetPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPlaceQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables> &
    ({ variables: GetPlaceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, options)
}
export function useGetPlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, options)
}
export function useGetPlaceSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, options)
}
export type GetPlaceQueryHookResult = ReturnType<typeof useGetPlaceQuery>
export type GetPlaceLazyQueryHookResult = ReturnType<typeof useGetPlaceLazyQuery>
export type GetPlaceSuspenseQueryHookResult = ReturnType<typeof useGetPlaceSuspenseQuery>
export type GetPlaceQueryResult = Apollo.QueryResult<GetPlaceQuery, GetPlaceQueryVariables>
export const GetPlacesPredictionDocument = gql`
  query GetPlacesPrediction($input: String!) {
    GetPlacesPrediction(input: $input) {
      placeId
      description
    }
  }
`

/**
 * __useGetPlacesPredictionQuery__
 *
 * To run a query within a React component, call `useGetPlacesPredictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacesPredictionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacesPredictionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlacesPredictionQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlacesPredictionQuery, GetPlacesPredictionQueryVariables> &
    ({ variables: GetPlacesPredictionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPlacesPredictionQuery, GetPlacesPredictionQueryVariables>(
    GetPlacesPredictionDocument,
    options,
  )
}
export function useGetPlacesPredictionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlacesPredictionQuery, GetPlacesPredictionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPlacesPredictionQuery, GetPlacesPredictionQueryVariables>(
    GetPlacesPredictionDocument,
    options,
  )
}
export function useGetPlacesPredictionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPlacesPredictionQuery, GetPlacesPredictionQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPlacesPredictionQuery, GetPlacesPredictionQueryVariables>(
    GetPlacesPredictionDocument,
    options,
  )
}
export type GetPlacesPredictionQueryHookResult = ReturnType<typeof useGetPlacesPredictionQuery>
export type GetPlacesPredictionLazyQueryHookResult = ReturnType<typeof useGetPlacesPredictionLazyQuery>
export type GetPlacesPredictionSuspenseQueryHookResult = ReturnType<typeof useGetPlacesPredictionSuspenseQuery>
export type GetPlacesPredictionQueryResult = Apollo.QueryResult<
  GetPlacesPredictionQuery,
  GetPlacesPredictionQueryVariables
>
export const GetListReferralsDocument = gql`
  query GetListReferrals($input: FilterReferralsInput, $page: Int, $limit: Int) {
    ListReferrals(input: $input, page: $page, limit: $limit) {
      id
      firstName
      lastName
      CandidateProfile {
        id
        ProfileDetails {
          id
          CandidateSource {
            id
            candidateSourceName {
              id
              name
              sourceCategory
            }
            companyUser {
              id
              UserInvitation {
                ScalisUser {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
        CandidateApplication {
          id
          createdAt
          updatedAt
          applicationStatus
          JobListing {
            id
            externalTitle
            acceptingCandidates
            publicListingId
          }
          TemplateStage {
            id
            name
            TemplateMilestone {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetListReferralsQuery__
 *
 * To run a query within a React component, call `useGetListReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListReferralsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetListReferralsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetListReferralsQuery, GetListReferralsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetListReferralsQuery, GetListReferralsQueryVariables>(GetListReferralsDocument, options)
}
export function useGetListReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetListReferralsQuery, GetListReferralsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetListReferralsQuery, GetListReferralsQueryVariables>(GetListReferralsDocument, options)
}
export function useGetListReferralsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetListReferralsQuery, GetListReferralsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetListReferralsQuery, GetListReferralsQueryVariables>(
    GetListReferralsDocument,
    options,
  )
}
export type GetListReferralsQueryHookResult = ReturnType<typeof useGetListReferralsQuery>
export type GetListReferralsLazyQueryHookResult = ReturnType<typeof useGetListReferralsLazyQuery>
export type GetListReferralsSuspenseQueryHookResult = ReturnType<typeof useGetListReferralsSuspenseQuery>
export type GetListReferralsQueryResult = Apollo.QueryResult<GetListReferralsQuery, GetListReferralsQueryVariables>
export const GetReferralCountDocument = gql`
  query GetReferralCount($input: FilterReferralsInput) {
    CountReferrals(input: $input) {
      count
    }
  }
`

/**
 * __useGetReferralCountQuery__
 *
 * To run a query within a React component, call `useGetReferralCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReferralCountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReferralCountQuery, GetReferralCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReferralCountQuery, GetReferralCountQueryVariables>(GetReferralCountDocument, options)
}
export function useGetReferralCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReferralCountQuery, GetReferralCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReferralCountQuery, GetReferralCountQueryVariables>(GetReferralCountDocument, options)
}
export function useGetReferralCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetReferralCountQuery, GetReferralCountQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetReferralCountQuery, GetReferralCountQueryVariables>(
    GetReferralCountDocument,
    options,
  )
}
export type GetReferralCountQueryHookResult = ReturnType<typeof useGetReferralCountQuery>
export type GetReferralCountLazyQueryHookResult = ReturnType<typeof useGetReferralCountLazyQuery>
export type GetReferralCountSuspenseQueryHookResult = ReturnType<typeof useGetReferralCountSuspenseQuery>
export type GetReferralCountQueryResult = Apollo.QueryResult<GetReferralCountQuery, GetReferralCountQueryVariables>
export const GetReferralsStatsDocument = gql`
  query GetReferralsStats($input: FilterReferralsInput!) {
    ReferralsStats(input: $input) {
      totalReferrals
      myReferrals
      totalReferralsByMilestone
    }
  }
`

/**
 * __useGetReferralsStatsQuery__
 *
 * To run a query within a React component, call `useGetReferralsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralsStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReferralsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReferralsStatsQuery, GetReferralsStatsQueryVariables> &
    ({ variables: GetReferralsStatsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReferralsStatsQuery, GetReferralsStatsQueryVariables>(GetReferralsStatsDocument, options)
}
export function useGetReferralsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReferralsStatsQuery, GetReferralsStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReferralsStatsQuery, GetReferralsStatsQueryVariables>(
    GetReferralsStatsDocument,
    options,
  )
}
export function useGetReferralsStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetReferralsStatsQuery, GetReferralsStatsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetReferralsStatsQuery, GetReferralsStatsQueryVariables>(
    GetReferralsStatsDocument,
    options,
  )
}
export type GetReferralsStatsQueryHookResult = ReturnType<typeof useGetReferralsStatsQuery>
export type GetReferralsStatsLazyQueryHookResult = ReturnType<typeof useGetReferralsStatsLazyQuery>
export type GetReferralsStatsSuspenseQueryHookResult = ReturnType<typeof useGetReferralsStatsSuspenseQuery>
export type GetReferralsStatsQueryResult = Apollo.QueryResult<GetReferralsStatsQuery, GetReferralsStatsQueryVariables>
export const ReplaceTokensDocument = gql`
  query ReplaceTokens($input: ReplaceTokensInput!) {
    ReplaceTokens(input: $input) {
      result
    }
  }
`

/**
 * __useReplaceTokensQuery__
 *
 * To run a query within a React component, call `useReplaceTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useReplaceTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReplaceTokensQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceTokensQuery(
  baseOptions: Apollo.QueryHookOptions<ReplaceTokensQuery, ReplaceTokensQueryVariables> &
    ({ variables: ReplaceTokensQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReplaceTokensQuery, ReplaceTokensQueryVariables>(ReplaceTokensDocument, options)
}
export function useReplaceTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReplaceTokensQuery, ReplaceTokensQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReplaceTokensQuery, ReplaceTokensQueryVariables>(ReplaceTokensDocument, options)
}
export function useReplaceTokensSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReplaceTokensQuery, ReplaceTokensQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReplaceTokensQuery, ReplaceTokensQueryVariables>(ReplaceTokensDocument, options)
}
export type ReplaceTokensQueryHookResult = ReturnType<typeof useReplaceTokensQuery>
export type ReplaceTokensLazyQueryHookResult = ReturnType<typeof useReplaceTokensLazyQuery>
export type ReplaceTokensSuspenseQueryHookResult = ReturnType<typeof useReplaceTokensSuspenseQuery>
export type ReplaceTokensQueryResult = Apollo.QueryResult<ReplaceTokensQuery, ReplaceTokensQueryVariables>
export const GetCoreReportCsvDocument = gql`
  query GetCoreReportCsv($id: Int!) {
    GetCoreReportCsv(id: $id) {
      base64
    }
  }
`

/**
 * __useGetCoreReportCsvQuery__
 *
 * To run a query within a React component, call `useGetCoreReportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoreReportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoreReportCsvQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCoreReportCsvQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoreReportCsvQuery, GetCoreReportCsvQueryVariables> &
    ({ variables: GetCoreReportCsvQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCoreReportCsvQuery, GetCoreReportCsvQueryVariables>(GetCoreReportCsvDocument, options)
}
export function useGetCoreReportCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoreReportCsvQuery, GetCoreReportCsvQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCoreReportCsvQuery, GetCoreReportCsvQueryVariables>(GetCoreReportCsvDocument, options)
}
export function useGetCoreReportCsvSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCoreReportCsvQuery, GetCoreReportCsvQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCoreReportCsvQuery, GetCoreReportCsvQueryVariables>(
    GetCoreReportCsvDocument,
    options,
  )
}
export type GetCoreReportCsvQueryHookResult = ReturnType<typeof useGetCoreReportCsvQuery>
export type GetCoreReportCsvLazyQueryHookResult = ReturnType<typeof useGetCoreReportCsvLazyQuery>
export type GetCoreReportCsvSuspenseQueryHookResult = ReturnType<typeof useGetCoreReportCsvSuspenseQuery>
export type GetCoreReportCsvQueryResult = Apollo.QueryResult<GetCoreReportCsvQuery, GetCoreReportCsvQueryVariables>
export const GetReportDataDocument = gql`
  query GetReportData(
    $id: Int!
    $generateAvailableFilters: Boolean!
    $page: Int
    $limit: Int
    $filters: [FilterCriteriaInput!]
  ) {
    GetCoreReportData(
      id: $id
      page: $page
      limit: $limit
      generateAvailableFilters: $generateAvailableFilters
      filters: $filters
    ) {
      coreReportId
      data
      schema
      total
      availableFilters {
        column
        label
        type
        values
      }
    }
  }
`

/**
 * __useGetReportDataQuery__
 *
 * To run a query within a React component, call `useGetReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      generateAvailableFilters: // value for 'generateAvailableFilters'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetReportDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportDataQuery, GetReportDataQueryVariables> &
    ({ variables: GetReportDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReportDataQuery, GetReportDataQueryVariables>(GetReportDataDocument, options)
}
export function useGetReportDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportDataQuery, GetReportDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReportDataQuery, GetReportDataQueryVariables>(GetReportDataDocument, options)
}
export function useGetReportDataSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetReportDataQuery, GetReportDataQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetReportDataQuery, GetReportDataQueryVariables>(GetReportDataDocument, options)
}
export type GetReportDataQueryHookResult = ReturnType<typeof useGetReportDataQuery>
export type GetReportDataLazyQueryHookResult = ReturnType<typeof useGetReportDataLazyQuery>
export type GetReportDataSuspenseQueryHookResult = ReturnType<typeof useGetReportDataSuspenseQuery>
export type GetReportDataQueryResult = Apollo.QueryResult<GetReportDataQuery, GetReportDataQueryVariables>
export const GetReportFiltersDocument = gql`
  query GetReportFilters($id: Int!, $generateAvailableFilters: Boolean!) {
    GetCoreReportData(id: $id, generateAvailableFilters: $generateAvailableFilters) {
      coreReportId
      availableFilters {
        column
        label
        type
        values
      }
    }
  }
`

/**
 * __useGetReportFiltersQuery__
 *
 * To run a query within a React component, call `useGetReportFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportFiltersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      generateAvailableFilters: // value for 'generateAvailableFilters'
 *   },
 * });
 */
export function useGetReportFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportFiltersQuery, GetReportFiltersQueryVariables> &
    ({ variables: GetReportFiltersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReportFiltersQuery, GetReportFiltersQueryVariables>(GetReportFiltersDocument, options)
}
export function useGetReportFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportFiltersQuery, GetReportFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReportFiltersQuery, GetReportFiltersQueryVariables>(GetReportFiltersDocument, options)
}
export function useGetReportFiltersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetReportFiltersQuery, GetReportFiltersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetReportFiltersQuery, GetReportFiltersQueryVariables>(
    GetReportFiltersDocument,
    options,
  )
}
export type GetReportFiltersQueryHookResult = ReturnType<typeof useGetReportFiltersQuery>
export type GetReportFiltersLazyQueryHookResult = ReturnType<typeof useGetReportFiltersLazyQuery>
export type GetReportFiltersSuspenseQueryHookResult = ReturnType<typeof useGetReportFiltersSuspenseQuery>
export type GetReportFiltersQueryResult = Apollo.QueryResult<GetReportFiltersQuery, GetReportFiltersQueryVariables>
export const GetReportsDocument = gql`
  query GetReports($tab: ReportFilterTabEnum!) {
    GetCoreReport(tab: $tab) {
      ...CoreReport
    }
    CountCoreReport(tab: $tab)
  }
  ${CoreReportFragmentDoc}
`

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      tab: // value for 'tab'
 *   },
 * });
 */
export function useGetReportsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables> &
    ({ variables: GetReportsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options)
}
export function useGetReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options)
}
export function useGetReportsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options)
}
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>
export type GetReportsSuspenseQueryHookResult = ReturnType<typeof useGetReportsSuspenseQuery>
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>
export const GetScheduledReportDocument = gql`
  query GetScheduledReport($coreReportId: Int!, $id: Int) {
    GetScheduledReport(coreReportId: $coreReportId, id: $id) {
      ...ScheduledReport
    }
  }
  ${ScheduledReportFragmentDoc}
`

/**
 * __useGetScheduledReportQuery__
 *
 * To run a query within a React component, call `useGetScheduledReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledReportQuery({
 *   variables: {
 *      coreReportId: // value for 'coreReportId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduledReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetScheduledReportQuery, GetScheduledReportQueryVariables> &
    ({ variables: GetScheduledReportQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetScheduledReportQuery, GetScheduledReportQueryVariables>(GetScheduledReportDocument, options)
}
export function useGetScheduledReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledReportQuery, GetScheduledReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetScheduledReportQuery, GetScheduledReportQueryVariables>(
    GetScheduledReportDocument,
    options,
  )
}
export function useGetScheduledReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetScheduledReportQuery, GetScheduledReportQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetScheduledReportQuery, GetScheduledReportQueryVariables>(
    GetScheduledReportDocument,
    options,
  )
}
export type GetScheduledReportQueryHookResult = ReturnType<typeof useGetScheduledReportQuery>
export type GetScheduledReportLazyQueryHookResult = ReturnType<typeof useGetScheduledReportLazyQuery>
export type GetScheduledReportSuspenseQueryHookResult = ReturnType<typeof useGetScheduledReportSuspenseQuery>
export type GetScheduledReportQueryResult = Apollo.QueryResult<
  GetScheduledReportQuery,
  GetScheduledReportQueryVariables
>
export const GetSharedReportsDocument = gql`
  query GetSharedReports($coreReportId: Int) {
    GetSharedCoreReport(coreReportId: $coreReportId) {
      ...SharedCoreReport
    }
  }
  ${SharedCoreReportFragmentDoc}
`

/**
 * __useGetSharedReportsQuery__
 *
 * To run a query within a React component, call `useGetSharedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedReportsQuery({
 *   variables: {
 *      coreReportId: // value for 'coreReportId'
 *   },
 * });
 */
export function useGetSharedReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSharedReportsQuery, GetSharedReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSharedReportsQuery, GetSharedReportsQueryVariables>(GetSharedReportsDocument, options)
}
export function useGetSharedReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSharedReportsQuery, GetSharedReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSharedReportsQuery, GetSharedReportsQueryVariables>(GetSharedReportsDocument, options)
}
export function useGetSharedReportsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetSharedReportsQuery, GetSharedReportsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSharedReportsQuery, GetSharedReportsQueryVariables>(
    GetSharedReportsDocument,
    options,
  )
}
export type GetSharedReportsQueryHookResult = ReturnType<typeof useGetSharedReportsQuery>
export type GetSharedReportsLazyQueryHookResult = ReturnType<typeof useGetSharedReportsLazyQuery>
export type GetSharedReportsSuspenseQueryHookResult = ReturnType<typeof useGetSharedReportsSuspenseQuery>
export type GetSharedReportsQueryResult = Apollo.QueryResult<GetSharedReportsQuery, GetSharedReportsQueryVariables>
export const GetRequisitionAndOpeningsIdDocument = gql`
  query GetRequisitionAndOpeningsId {
    GetRequisitions {
      id
      extRequisitionId
      jobOpening {
        id
        extOpeningId
        jobRequisitionId
      }
    }
  }
`

/**
 * __useGetRequisitionAndOpeningsIdQuery__
 *
 * To run a query within a React component, call `useGetRequisitionAndOpeningsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionAndOpeningsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionAndOpeningsIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRequisitionAndOpeningsIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRequisitionAndOpeningsIdQuery, GetRequisitionAndOpeningsIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionAndOpeningsIdQuery, GetRequisitionAndOpeningsIdQueryVariables>(
    GetRequisitionAndOpeningsIdDocument,
    options,
  )
}
export function useGetRequisitionAndOpeningsIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequisitionAndOpeningsIdQuery,
    GetRequisitionAndOpeningsIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionAndOpeningsIdQuery, GetRequisitionAndOpeningsIdQueryVariables>(
    GetRequisitionAndOpeningsIdDocument,
    options,
  )
}
export function useGetRequisitionAndOpeningsIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionAndOpeningsIdQuery, GetRequisitionAndOpeningsIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionAndOpeningsIdQuery, GetRequisitionAndOpeningsIdQueryVariables>(
    GetRequisitionAndOpeningsIdDocument,
    options,
  )
}
export type GetRequisitionAndOpeningsIdQueryHookResult = ReturnType<typeof useGetRequisitionAndOpeningsIdQuery>
export type GetRequisitionAndOpeningsIdLazyQueryHookResult = ReturnType<typeof useGetRequisitionAndOpeningsIdLazyQuery>
export type GetRequisitionAndOpeningsIdSuspenseQueryHookResult = ReturnType<
  typeof useGetRequisitionAndOpeningsIdSuspenseQuery
>
export type GetRequisitionAndOpeningsIdQueryResult = Apollo.QueryResult<
  GetRequisitionAndOpeningsIdQuery,
  GetRequisitionAndOpeningsIdQueryVariables
>
export const GetRequisitionExtIdDocument = gql`
  query GetRequisitionExtId {
    GetRequisitionExtId {
      id
      companyId
      extId
    }
  }
`

/**
 * __useGetRequisitionExtIdQuery__
 *
 * To run a query within a React component, call `useGetRequisitionExtIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionExtIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionExtIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRequisitionExtIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRequisitionExtIdQuery, GetRequisitionExtIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionExtIdQuery, GetRequisitionExtIdQueryVariables>(
    GetRequisitionExtIdDocument,
    options,
  )
}
export function useGetRequisitionExtIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionExtIdQuery, GetRequisitionExtIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionExtIdQuery, GetRequisitionExtIdQueryVariables>(
    GetRequisitionExtIdDocument,
    options,
  )
}
export function useGetRequisitionExtIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionExtIdQuery, GetRequisitionExtIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionExtIdQuery, GetRequisitionExtIdQueryVariables>(
    GetRequisitionExtIdDocument,
    options,
  )
}
export type GetRequisitionExtIdQueryHookResult = ReturnType<typeof useGetRequisitionExtIdQuery>
export type GetRequisitionExtIdLazyQueryHookResult = ReturnType<typeof useGetRequisitionExtIdLazyQuery>
export type GetRequisitionExtIdSuspenseQueryHookResult = ReturnType<typeof useGetRequisitionExtIdSuspenseQuery>
export type GetRequisitionExtIdQueryResult = Apollo.QueryResult<
  GetRequisitionExtIdQuery,
  GetRequisitionExtIdQueryVariables
>
export const GetRequisitionJobDetailsDocument = gql`
  query GetRequisitionJobDetails($id: Int!) {
    GetRequisition(id: $id) {
      id
      jobStatus
      openedAt
      jobName
      extRequisitionId
      jobType
      jobLevel
      travelType
      payType
      minPay
      maxPay
      additionalComp
      benefits
      companyDepartment {
        id
        departmentName
      }
      companyOffice {
        id
        officeName
      }
      jobOpening {
        id
        extOpeningId
        targetHireDate
        openingType
      }
      createdAt
      updatedAt
    }
  }
`

/**
 * __useGetRequisitionJobDetailsQuery__
 *
 * To run a query within a React component, call `useGetRequisitionJobDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionJobDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionJobDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequisitionJobDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequisitionJobDetailsQuery, GetRequisitionJobDetailsQueryVariables> &
    ({ variables: GetRequisitionJobDetailsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionJobDetailsQuery, GetRequisitionJobDetailsQueryVariables>(
    GetRequisitionJobDetailsDocument,
    options,
  )
}
export function useGetRequisitionJobDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionJobDetailsQuery, GetRequisitionJobDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionJobDetailsQuery, GetRequisitionJobDetailsQueryVariables>(
    GetRequisitionJobDetailsDocument,
    options,
  )
}
export function useGetRequisitionJobDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionJobDetailsQuery, GetRequisitionJobDetailsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionJobDetailsQuery, GetRequisitionJobDetailsQueryVariables>(
    GetRequisitionJobDetailsDocument,
    options,
  )
}
export type GetRequisitionJobDetailsQueryHookResult = ReturnType<typeof useGetRequisitionJobDetailsQuery>
export type GetRequisitionJobDetailsLazyQueryHookResult = ReturnType<typeof useGetRequisitionJobDetailsLazyQuery>
export type GetRequisitionJobDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetRequisitionJobDetailsSuspenseQuery
>
export type GetRequisitionJobDetailsQueryResult = Apollo.QueryResult<
  GetRequisitionJobDetailsQuery,
  GetRequisitionJobDetailsQueryVariables
>
export const GetRequisitionListingsDocument = gql`
  query GetRequisitionListings($id: Int!) {
    GetRequisition(id: $id) {
      id
      companyId
      Company {
        CompanyInfo {
          companyName
          logo
        }
      }
      jobName
      jobType
      jobLevel
      jobStatus
      openedAt
      payType
      minPay
      maxPay
      RequisitionJobDescription {
        description
      }
      benefits
      RequisitionSkills {
        skill {
          name
          level
          yearsOfExp
        }
      }
      jobListings {
        id
        publicListingId
        jobRequisitionId
        listingTitle
        externalTitle
        workplaceType
        publicDescription
        displayPayOnListing
        industry
        subIndustry
        companyDefault
        stateAndOrCountry
        internalMatchingDescription
        searchPublicDescription
        searchInternalMatchingDescription
        postedAt
        acceptingCandidates
        stage
        Address {
          id
          addressLine1
          addressLine2
          city
          state
          country
          postalCode
          timeZone
        }
        JobListingScreeningQuestion {
          id
          jobListingId
          displaySequenceNumber
          screeningQuestionId
          createdAt
          updatedAt
          ScreeningQuestion {
            id
            isTemplateQuestion
            questionTitle
            questionDesc
            questionType
            minVal
            maxVal
            valStatus
            ansOptions
            isRejectionQuestion
            isGlobalQuestion
            rejectMessage
            templateMessageId
            preferredAnswer
            companyId
            companyUserId
            createdAt
            updatedAt
          }
        }
        deletedAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`

/**
 * __useGetRequisitionListingsQuery__
 *
 * To run a query within a React component, call `useGetRequisitionListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionListingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequisitionListingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequisitionListingsQuery, GetRequisitionListingsQueryVariables> &
    ({ variables: GetRequisitionListingsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionListingsQuery, GetRequisitionListingsQueryVariables>(
    GetRequisitionListingsDocument,
    options,
  )
}
export function useGetRequisitionListingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionListingsQuery, GetRequisitionListingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionListingsQuery, GetRequisitionListingsQueryVariables>(
    GetRequisitionListingsDocument,
    options,
  )
}
export function useGetRequisitionListingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionListingsQuery, GetRequisitionListingsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionListingsQuery, GetRequisitionListingsQueryVariables>(
    GetRequisitionListingsDocument,
    options,
  )
}
export type GetRequisitionListingsQueryHookResult = ReturnType<typeof useGetRequisitionListingsQuery>
export type GetRequisitionListingsLazyQueryHookResult = ReturnType<typeof useGetRequisitionListingsLazyQuery>
export type GetRequisitionListingsSuspenseQueryHookResult = ReturnType<typeof useGetRequisitionListingsSuspenseQuery>
export type GetRequisitionListingsQueryResult = Apollo.QueryResult<
  GetRequisitionListingsQuery,
  GetRequisitionListingsQueryVariables
>
export const GetRequisitionPipelineDocument = gql`
  query GetRequisitionPipeline($id: Int!) {
    GetRequisition(id: $id) {
      __typename
      id
      companyId
      jobName
      extRequisitionId
      jobListings {
        __typename
        id
        listingTitle
        acceptingCandidates
      }
      jobPipelines {
        __typename
        id
        title
        TemplatePipeline {
          __typename
          id
          name
          ghostPipeline
          TemplateMilestones {
            ...TemplateMilestoneKanban
          }
        }
      }
    }
  }
  ${TemplateMilestoneKanbanFragmentDoc}
`

/**
 * __useGetRequisitionPipelineQuery__
 *
 * To run a query within a React component, call `useGetRequisitionPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionPipelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequisitionPipelineQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequisitionPipelineQuery, GetRequisitionPipelineQueryVariables> &
    ({ variables: GetRequisitionPipelineQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionPipelineQuery, GetRequisitionPipelineQueryVariables>(
    GetRequisitionPipelineDocument,
    options,
  )
}
export function useGetRequisitionPipelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionPipelineQuery, GetRequisitionPipelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionPipelineQuery, GetRequisitionPipelineQueryVariables>(
    GetRequisitionPipelineDocument,
    options,
  )
}
export function useGetRequisitionPipelineSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionPipelineQuery, GetRequisitionPipelineQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionPipelineQuery, GetRequisitionPipelineQueryVariables>(
    GetRequisitionPipelineDocument,
    options,
  )
}
export type GetRequisitionPipelineQueryHookResult = ReturnType<typeof useGetRequisitionPipelineQuery>
export type GetRequisitionPipelineLazyQueryHookResult = ReturnType<typeof useGetRequisitionPipelineLazyQuery>
export type GetRequisitionPipelineSuspenseQueryHookResult = ReturnType<typeof useGetRequisitionPipelineSuspenseQuery>
export type GetRequisitionPipelineQueryResult = Apollo.QueryResult<
  GetRequisitionPipelineQuery,
  GetRequisitionPipelineQueryVariables
>
export const GetRequisitionPipelinesDocument = gql`
  query GetRequisitionPipelines($id: Int!) {
    GetRequisition(id: $id) {
      id
      openedAt
      jobPipelines {
        id
        title
        JobListingJobPipeline {
          id
        }
        TemplatePipeline {
          id
          name
          CompanyDepartment {
            id
            departmentName
          }
          ghostPipeline
          pipelinedUsed
          archived
          createdAt
          updatedAt
          deletedAt
          TemplateMilestones {
            id
            name
            rankOrder
            archived
            createdAt
            updatedAt
            TemplateStage {
              id
              visibility
              name
              templateMilestoneId
              rankOrder
              archived
              createdAt
              updatedAt
              CandidateApplicationByStage(requisitionId: $id) {
                id
              }
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`

/**
 * __useGetRequisitionPipelinesQuery__
 *
 * To run a query within a React component, call `useGetRequisitionPipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionPipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionPipelinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequisitionPipelinesQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequisitionPipelinesQuery, GetRequisitionPipelinesQueryVariables> &
    ({ variables: GetRequisitionPipelinesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionPipelinesQuery, GetRequisitionPipelinesQueryVariables>(
    GetRequisitionPipelinesDocument,
    options,
  )
}
export function useGetRequisitionPipelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionPipelinesQuery, GetRequisitionPipelinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionPipelinesQuery, GetRequisitionPipelinesQueryVariables>(
    GetRequisitionPipelinesDocument,
    options,
  )
}
export function useGetRequisitionPipelinesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionPipelinesQuery, GetRequisitionPipelinesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionPipelinesQuery, GetRequisitionPipelinesQueryVariables>(
    GetRequisitionPipelinesDocument,
    options,
  )
}
export type GetRequisitionPipelinesQueryHookResult = ReturnType<typeof useGetRequisitionPipelinesQuery>
export type GetRequisitionPipelinesLazyQueryHookResult = ReturnType<typeof useGetRequisitionPipelinesLazyQuery>
export type GetRequisitionPipelinesSuspenseQueryHookResult = ReturnType<typeof useGetRequisitionPipelinesSuspenseQuery>
export type GetRequisitionPipelinesQueryResult = Apollo.QueryResult<
  GetRequisitionPipelinesQuery,
  GetRequisitionPipelinesQueryVariables
>
export const GetRequisitionSkillsAndPreferencesDocument = gql`
  query GetRequisitionSkillsAndPreferences($id: Int!) {
    GetRequisition(id: $id) {
      id
      openedAt
      locationDistancePref
      educationLevelPref
      yearsOfExperiencePref
      nonTraditionalPref
      veteranPref
      diverseHirePref
      collegeGradPref
      RequisitionSkills {
        id
        requisitionId
        skillId
        sequenceNumber
        createdAt
        updatedAt
        skill {
          id
          experienceId
          name
          level
          yearsOfExp
        }
      }
      createdAt
      updatedAt
    }
  }
`

/**
 * __useGetRequisitionSkillsAndPreferencesQuery__
 *
 * To run a query within a React component, call `useGetRequisitionSkillsAndPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionSkillsAndPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionSkillsAndPreferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequisitionSkillsAndPreferencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRequisitionSkillsAndPreferencesQuery,
    GetRequisitionSkillsAndPreferencesQueryVariables
  > &
    ({ variables: GetRequisitionSkillsAndPreferencesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionSkillsAndPreferencesQuery, GetRequisitionSkillsAndPreferencesQueryVariables>(
    GetRequisitionSkillsAndPreferencesDocument,
    options,
  )
}
export function useGetRequisitionSkillsAndPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequisitionSkillsAndPreferencesQuery,
    GetRequisitionSkillsAndPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionSkillsAndPreferencesQuery, GetRequisitionSkillsAndPreferencesQueryVariables>(
    GetRequisitionSkillsAndPreferencesDocument,
    options,
  )
}
export function useGetRequisitionSkillsAndPreferencesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetRequisitionSkillsAndPreferencesQuery,
        GetRequisitionSkillsAndPreferencesQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetRequisitionSkillsAndPreferencesQuery,
    GetRequisitionSkillsAndPreferencesQueryVariables
  >(GetRequisitionSkillsAndPreferencesDocument, options)
}
export type GetRequisitionSkillsAndPreferencesQueryHookResult = ReturnType<
  typeof useGetRequisitionSkillsAndPreferencesQuery
>
export type GetRequisitionSkillsAndPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetRequisitionSkillsAndPreferencesLazyQuery
>
export type GetRequisitionSkillsAndPreferencesSuspenseQueryHookResult = ReturnType<
  typeof useGetRequisitionSkillsAndPreferencesSuspenseQuery
>
export type GetRequisitionSkillsAndPreferencesQueryResult = Apollo.QueryResult<
  GetRequisitionSkillsAndPreferencesQuery,
  GetRequisitionSkillsAndPreferencesQueryVariables
>
export const GetRequisitionDocument = gql`
  query GetRequisition($id: Int!) {
    GetRequisition(id: $id) {
      id
      companyUserId
      Company {
        id
        CompanyInfo {
          id
          companyName
          logo
        }
      }
      jobStatus
      jobName
      extRequisitionId
      jobType
      jobLevel
      travelType
      payType
      minPay
      maxPay
      additionalComp
      benefits
      locationDistancePref
      educationLevelPref
      yearsOfExperiencePref
      nonTraditionalPref
      veteranPref
      diverseHirePref
      collegeGradPref
      companyDepartment {
        id
        departmentName
      }
      companyOffice {
        id
        officeName
      }
      jobOpening {
        id
        openingStatus
        extOpeningId
        targetHireDate
        openingType
        closedReason
        closedDate
        filledDate
        startDate
        CandidateProfile {
          id
          CandidateApplicationRating {
            id
          }
          ScalisUser {
            id
            firstName
            lastName
          }
          CandidateCollaborator {
            id
          }
          ProfileDetails {
            id
            CandidateMedalistDesignation {
              id
            }
            CandidateProfileLocation {
              id
            }
            CandidateSource {
              id
            }
          }
          CandidateApplicationRating {
            id
            score
          }
        }
      }
      RequisitionJobDescription {
        id
        description
      }
      RequisitionSkills {
        id
        requisitionId
        skillId
        sequenceNumber
        createdAt
        updatedAt
        skill {
          id
          experienceId
          name
          level
          yearsOfExp
        }
      }
      jobListings {
        id
        publicListingId
        jobRequisitionId
        listingTitle
        externalTitle
        workplaceType
        publicDescription
        displayPayOnListing
        industry
        subIndustry
        companyDefault
        stateAndOrCountry
        internalMatchingDescription
        searchPublicDescription
        searchInternalMatchingDescription
        postedAt
        acceptingCandidates
        stage
        Address {
          id
          addressLine1
          addressLine2
          city
          state
          country
          postalCode
          timeZone
        }
        JobListingScreeningQuestion {
          id
          jobListingId
          displaySequenceNumber
          screeningQuestionId
          active
          hasApplications
          createdAt
          updatedAt
          ScreeningQuestion {
            id
            isTemplateQuestion
            questionTitle
            questionDesc
            questionType
            minVal
            maxVal
            valStatus
            ansOptions
            isRejectionQuestion
            rejectMessage
            templateMessageId
            preferredAnswer
            isGlobalQuestion
            companyId
            companyUserId
            createdAt
            updatedAt
          }
        }
        CandidateApplication {
          id
          applicationType
          applicationStatus
          applicationTimestamp
          interviewScheduling
          TemplateStage {
            id
            TemplateMilestone {
              id
              name
            }
          }
          CandidateProfile {
            id
            CandidateApplicationRating {
              id
            }
            ScalisUser {
              id
              firstName
              lastName
            }
            CandidateApplication {
              id
            }
            CandidateCollaborator {
              id
            }
            ProfileDetails {
              id
              CandidateMedalistDesignation {
                id
              }
              CandidateProfileLocation {
                id
              }
              CandidateSource {
                id
              }
            }
            CandidateApplicationRating {
              id
              score
            }
          }
          CandidateApplicationRating {
            id
            score
          }
        }
        deletedAt
        createdAt
        updatedAt
      }
      hiringTeamMember {
        id
        role {
          id
          name
        }
        CompanyUser {
          id
          companyId
          companyUserStatus
          profileImage
          UserInvitation {
            id
            email
            invitedAs
            invitationStatus
            ScalisUser {
              id
              firstName
              lastName
              activeStatus
              profileImage
            }
          }
        }
      }
      primaryContact
      createdAt
      updatedAt
    }
  }
`

/**
 * __useGetRequisitionQuery__
 *
 * To run a query within a React component, call `useGetRequisitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequisitionQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequisitionQuery, GetRequisitionQueryVariables> &
    ({ variables: GetRequisitionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionQuery, GetRequisitionQueryVariables>(GetRequisitionDocument, options)
}
export function useGetRequisitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionQuery, GetRequisitionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionQuery, GetRequisitionQueryVariables>(GetRequisitionDocument, options)
}
export function useGetRequisitionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRequisitionQuery, GetRequisitionQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionQuery, GetRequisitionQueryVariables>(GetRequisitionDocument, options)
}
export type GetRequisitionQueryHookResult = ReturnType<typeof useGetRequisitionQuery>
export type GetRequisitionLazyQueryHookResult = ReturnType<typeof useGetRequisitionLazyQuery>
export type GetRequisitionSuspenseQueryHookResult = ReturnType<typeof useGetRequisitionSuspenseQuery>
export type GetRequisitionQueryResult = Apollo.QueryResult<GetRequisitionQuery, GetRequisitionQueryVariables>
export const GetRequisitionsDocument = gql`
  query GetRequisitions(
    $id: Int
    $companyId: Int
    $companyUserId: Int
    $companyDepartmentId: Int
    $companyOfficeId: Int
    $createdAt: DateTime
    $lastActivity: RequisitionLastActivity
    $status: JobStatusEnum
    $pipeline: String
    $page: Int
    $limit: Int
  ) {
    GetRequisitions(
      id: $id
      companyId: $companyId
      companyUserId: $companyUserId
      companyDepartmentId: $companyDepartmentId
      companyOfficeId: $companyOfficeId
      createdAt: $createdAt
      lastActivity: $lastActivity
      status: $status
      pipeline: $pipeline
      page: $page
      limit: $limit
    ) {
      id
      jobStatus
      jobName
      extRequisitionId
      jobType
      jobLevel
      travelType
      payType
      minPay
      maxPay
      additionalComp
      benefits
      locationDistancePref
      educationLevelPref
      yearsOfExperiencePref
      nonTraditionalPref
      veteranPref
      diverseHirePref
      collegeGradPref
      companyDepartment {
        id
        departmentName
      }
      companyOffice {
        id
        officeName
      }
      jobOpening {
        id
        openingStatus
        extOpeningId
        targetHireDate
        openingType
        closedReason
        closedDate
        filledDate
        startDate
      }
      RequisitionJobDescription {
        id
        description
      }
      RequisitionSkills {
        id
        requisitionId
        skillId
        sequenceNumber
        createdAt
        updatedAt
        skill {
          id
          experienceId
          name
          level
          yearsOfExp
        }
      }
      jobListings {
        id
        publicListingId
        jobRequisitionId
        listingTitle
        externalTitle
        workplaceType
        publicDescription
        displayPayOnListing
        industry
        subIndustry
        companyDefault
        stateAndOrCountry
        internalMatchingDescription
        searchPublicDescription
        searchInternalMatchingDescription
        postedAt
        acceptingCandidates
        stage
        Address {
          id
          addressLine1
          addressLine2
          city
          state
          country
          postalCode
          timeZone
        }
        JobListingScreeningQuestion {
          id
          jobListingId
          displaySequenceNumber
          screeningQuestionId
          createdAt
          updatedAt
          ScreeningQuestion {
            id
            isTemplateQuestion
            questionTitle
            questionDesc
            questionType
            minVal
            maxVal
            valStatus
            ansOptions
            isRejectionQuestion
            rejectMessage
            templateMessageId
            preferredAnswer
            isGlobalQuestion
            companyId
            companyUserId
            createdAt
            updatedAt
          }
        }
        CandidateApplication {
          id
          applicationStatus
          TemplateStage {
            id
            templateMilestoneId
          }
        }
        deletedAt
        createdAt
        updatedAt
      }
      jobPipelines {
        id
        title
        TemplatePipeline {
          id
          name
          archived
          pipelinedUsed
          ghostPipeline
          createdAt
          updatedAt
          deletedAt
          TemplateMilestones {
            id
            name
            rankOrder
            archived
            createdAt
            updatedAt
            TemplateStage {
              id
              visibility
              name
              rankOrder
              archived
              createdAt
              updatedAt
            }
          }
        }
      }
      hiringTeamMember {
        id
        role {
          id
          name
        }
        CompanyUser {
          id
          companyId
          companyUserStatus
          profileImage
          UserInvitation {
            id
            email
            invitedAs
            invitationStatus
            ScalisUser {
              id
              firstName
              lastName
              activeStatus
              profileImage
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`

/**
 * __useGetRequisitionsQuery__
 *
 * To run a query within a React component, call `useGetRequisitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      companyUserId: // value for 'companyUserId'
 *      companyDepartmentId: // value for 'companyDepartmentId'
 *      companyOfficeId: // value for 'companyOfficeId'
 *      createdAt: // value for 'createdAt'
 *      lastActivity: // value for 'lastActivity'
 *      status: // value for 'status'
 *      pipeline: // value for 'pipeline'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetRequisitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRequisitionsQuery, GetRequisitionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionsQuery, GetRequisitionsQueryVariables>(GetRequisitionsDocument, options)
}
export function useGetRequisitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionsQuery, GetRequisitionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionsQuery, GetRequisitionsQueryVariables>(GetRequisitionsDocument, options)
}
export function useGetRequisitionsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRequisitionsQuery, GetRequisitionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionsQuery, GetRequisitionsQueryVariables>(GetRequisitionsDocument, options)
}
export type GetRequisitionsQueryHookResult = ReturnType<typeof useGetRequisitionsQuery>
export type GetRequisitionsLazyQueryHookResult = ReturnType<typeof useGetRequisitionsLazyQuery>
export type GetRequisitionsSuspenseQueryHookResult = ReturnType<typeof useGetRequisitionsSuspenseQuery>
export type GetRequisitionsQueryResult = Apollo.QueryResult<GetRequisitionsQuery, GetRequisitionsQueryVariables>
export const GetSideBarRequisitionsDocument = gql`
  query GetSideBarRequisitions($status: JobStatusEnum) {
    GetRequisitions(status: $status) {
      id
      jobStatus
      jobName
      extRequisitionId
      jobPipelines {
        id
        title
        TemplatePipeline {
          id
          deletedAt
          TemplateMilestones {
            id
            name
            rankOrder
            TemplateStage {
              id
              name
              rankOrder
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetSideBarRequisitionsQuery__
 *
 * To run a query within a React component, call `useGetSideBarRequisitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSideBarRequisitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSideBarRequisitionsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetSideBarRequisitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSideBarRequisitionsQuery, GetSideBarRequisitionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSideBarRequisitionsQuery, GetSideBarRequisitionsQueryVariables>(
    GetSideBarRequisitionsDocument,
    options,
  )
}
export function useGetSideBarRequisitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSideBarRequisitionsQuery, GetSideBarRequisitionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSideBarRequisitionsQuery, GetSideBarRequisitionsQueryVariables>(
    GetSideBarRequisitionsDocument,
    options,
  )
}
export function useGetSideBarRequisitionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetSideBarRequisitionsQuery, GetSideBarRequisitionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSideBarRequisitionsQuery, GetSideBarRequisitionsQueryVariables>(
    GetSideBarRequisitionsDocument,
    options,
  )
}
export type GetSideBarRequisitionsQueryHookResult = ReturnType<typeof useGetSideBarRequisitionsQuery>
export type GetSideBarRequisitionsLazyQueryHookResult = ReturnType<typeof useGetSideBarRequisitionsLazyQuery>
export type GetSideBarRequisitionsSuspenseQueryHookResult = ReturnType<typeof useGetSideBarRequisitionsSuspenseQuery>
export type GetSideBarRequisitionsQueryResult = Apollo.QueryResult<
  GetSideBarRequisitionsQuery,
  GetSideBarRequisitionsQueryVariables
>
export const GetRequisitionJobDescriptionByRequisitionDocument = gql`
  query GetRequisitionJobDescriptionByRequisition($requisitionId: Int!) {
    GetRequisitionJobDescriptionByRequisition(requisitionId: $requisitionId) {
      id
      requisitionId
      description
      createdAt
      updatedAt
      requisition {
        id
        openedAt
      }
    }
  }
`

/**
 * __useGetRequisitionJobDescriptionByRequisitionQuery__
 *
 * To run a query within a React component, call `useGetRequisitionJobDescriptionByRequisitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionJobDescriptionByRequisitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionJobDescriptionByRequisitionQuery({
 *   variables: {
 *      requisitionId: // value for 'requisitionId'
 *   },
 * });
 */
export function useGetRequisitionJobDescriptionByRequisitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRequisitionJobDescriptionByRequisitionQuery,
    GetRequisitionJobDescriptionByRequisitionQueryVariables
  > &
    ({ variables: GetRequisitionJobDescriptionByRequisitionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRequisitionJobDescriptionByRequisitionQuery,
    GetRequisitionJobDescriptionByRequisitionQueryVariables
  >(GetRequisitionJobDescriptionByRequisitionDocument, options)
}
export function useGetRequisitionJobDescriptionByRequisitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequisitionJobDescriptionByRequisitionQuery,
    GetRequisitionJobDescriptionByRequisitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRequisitionJobDescriptionByRequisitionQuery,
    GetRequisitionJobDescriptionByRequisitionQueryVariables
  >(GetRequisitionJobDescriptionByRequisitionDocument, options)
}
export function useGetRequisitionJobDescriptionByRequisitionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetRequisitionJobDescriptionByRequisitionQuery,
        GetRequisitionJobDescriptionByRequisitionQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetRequisitionJobDescriptionByRequisitionQuery,
    GetRequisitionJobDescriptionByRequisitionQueryVariables
  >(GetRequisitionJobDescriptionByRequisitionDocument, options)
}
export type GetRequisitionJobDescriptionByRequisitionQueryHookResult = ReturnType<
  typeof useGetRequisitionJobDescriptionByRequisitionQuery
>
export type GetRequisitionJobDescriptionByRequisitionLazyQueryHookResult = ReturnType<
  typeof useGetRequisitionJobDescriptionByRequisitionLazyQuery
>
export type GetRequisitionJobDescriptionByRequisitionSuspenseQueryHookResult = ReturnType<
  typeof useGetRequisitionJobDescriptionByRequisitionSuspenseQuery
>
export type GetRequisitionJobDescriptionByRequisitionQueryResult = Apollo.QueryResult<
  GetRequisitionJobDescriptionByRequisitionQuery,
  GetRequisitionJobDescriptionByRequisitionQueryVariables
>
export const GetRequisitionJobDescriptionDocument = gql`
  query GetRequisitionJobDescription($requisitionId: Int!, $id: Int) {
    GetRequisitionJobDescription(requisitionId: $requisitionId, id: $id) {
      ...RequisitionJobDescriptionFragment
      createdAt
      updatedAt
      hiringTeamMember {
        ...HiringTeamMemberFragment
        createdAt
        updatedAt
        role {
          ...RoleFragment
        }
      }
      candidateCollaborator {
        ...CandidateCollaboratorFragment
        createdAt
        updatedAt
        role {
          ...RoleFragment
        }
      }
      stageCollaborator {
        ...StageCollaboratorFragment
        createdAt
        updatedAt
        role {
          ...RoleFragment
        }
      }
    }
  }
  ${RequisitionJobDescriptionFragmentFragmentDoc}
  ${HiringTeamMemberFragmentFragmentDoc}
  ${RoleFragmentFragmentDoc}
  ${CandidateCollaboratorFragmentFragmentDoc}
  ${StageCollaboratorFragmentFragmentDoc}
`

/**
 * __useGetRequisitionJobDescriptionQuery__
 *
 * To run a query within a React component, call `useGetRequisitionJobDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionJobDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionJobDescriptionQuery({
 *   variables: {
 *      requisitionId: // value for 'requisitionId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequisitionJobDescriptionQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequisitionJobDescriptionQuery, GetRequisitionJobDescriptionQueryVariables> &
    ({ variables: GetRequisitionJobDescriptionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionJobDescriptionQuery, GetRequisitionJobDescriptionQueryVariables>(
    GetRequisitionJobDescriptionDocument,
    options,
  )
}
export function useGetRequisitionJobDescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequisitionJobDescriptionQuery,
    GetRequisitionJobDescriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionJobDescriptionQuery, GetRequisitionJobDescriptionQueryVariables>(
    GetRequisitionJobDescriptionDocument,
    options,
  )
}
export function useGetRequisitionJobDescriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionJobDescriptionQuery, GetRequisitionJobDescriptionQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionJobDescriptionQuery, GetRequisitionJobDescriptionQueryVariables>(
    GetRequisitionJobDescriptionDocument,
    options,
  )
}
export type GetRequisitionJobDescriptionQueryHookResult = ReturnType<typeof useGetRequisitionJobDescriptionQuery>
export type GetRequisitionJobDescriptionLazyQueryHookResult = ReturnType<
  typeof useGetRequisitionJobDescriptionLazyQuery
>
export type GetRequisitionJobDescriptionSuspenseQueryHookResult = ReturnType<
  typeof useGetRequisitionJobDescriptionSuspenseQuery
>
export type GetRequisitionJobDescriptionQueryResult = Apollo.QueryResult<
  GetRequisitionJobDescriptionQuery,
  GetRequisitionJobDescriptionQueryVariables
>
export const GetTemplateJobDescriptionDocument = gql`
  query GetTemplateJobDescription($companyUserId: Int, $companyDepartmentId: Int, $id: Int, $name: String) {
    GetTemplateJobDescription(
      companyUserId: $companyUserId
      companyDepartmentId: $companyDepartmentId
      id: $id
      name: $name
    ) {
      id
      companyId
      companyUserId
      name
      description
      companyDepartmentId
      createdAt
      updatedAt
      companyDepartment {
        id
        companyId
        departmentName
        subordinateTo
        externalId
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useGetTemplateJobDescriptionQuery__
 *
 * To run a query within a React component, call `useGetTemplateJobDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateJobDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateJobDescriptionQuery({
 *   variables: {
 *      companyUserId: // value for 'companyUserId'
 *      companyDepartmentId: // value for 'companyDepartmentId'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetTemplateJobDescriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTemplateJobDescriptionQuery, GetTemplateJobDescriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTemplateJobDescriptionQuery, GetTemplateJobDescriptionQueryVariables>(
    GetTemplateJobDescriptionDocument,
    options,
  )
}
export function useGetTemplateJobDescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateJobDescriptionQuery, GetTemplateJobDescriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTemplateJobDescriptionQuery, GetTemplateJobDescriptionQueryVariables>(
    GetTemplateJobDescriptionDocument,
    options,
  )
}
export function useGetTemplateJobDescriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTemplateJobDescriptionQuery, GetTemplateJobDescriptionQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTemplateJobDescriptionQuery, GetTemplateJobDescriptionQueryVariables>(
    GetTemplateJobDescriptionDocument,
    options,
  )
}
export type GetTemplateJobDescriptionQueryHookResult = ReturnType<typeof useGetTemplateJobDescriptionQuery>
export type GetTemplateJobDescriptionLazyQueryHookResult = ReturnType<typeof useGetTemplateJobDescriptionLazyQuery>
export type GetTemplateJobDescriptionSuspenseQueryHookResult = ReturnType<
  typeof useGetTemplateJobDescriptionSuspenseQuery
>
export type GetTemplateJobDescriptionQueryResult = Apollo.QueryResult<
  GetTemplateJobDescriptionQuery,
  GetTemplateJobDescriptionQueryVariables
>
export const GetRequisitionSkillDocument = gql`
  query GetRequisitionSkill($id: Int!) {
    GetRequisitionSkill(id: $id) {
      ...RequisitionSkillsFragment
      createdAt
      updatedAt
      skill {
        ...SkillFragment
      }
    }
  }
  ${RequisitionSkillsFragmentFragmentDoc}
  ${SkillFragmentFragmentDoc}
`

/**
 * __useGetRequisitionSkillQuery__
 *
 * To run a query within a React component, call `useGetRequisitionSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionSkillQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRequisitionSkillQuery(
  baseOptions: Apollo.QueryHookOptions<GetRequisitionSkillQuery, GetRequisitionSkillQueryVariables> &
    ({ variables: GetRequisitionSkillQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRequisitionSkillQuery, GetRequisitionSkillQueryVariables>(
    GetRequisitionSkillDocument,
    options,
  )
}
export function useGetRequisitionSkillLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequisitionSkillQuery, GetRequisitionSkillQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRequisitionSkillQuery, GetRequisitionSkillQueryVariables>(
    GetRequisitionSkillDocument,
    options,
  )
}
export function useGetRequisitionSkillSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRequisitionSkillQuery, GetRequisitionSkillQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRequisitionSkillQuery, GetRequisitionSkillQueryVariables>(
    GetRequisitionSkillDocument,
    options,
  )
}
export type GetRequisitionSkillQueryHookResult = ReturnType<typeof useGetRequisitionSkillQuery>
export type GetRequisitionSkillLazyQueryHookResult = ReturnType<typeof useGetRequisitionSkillLazyQuery>
export type GetRequisitionSkillSuspenseQueryHookResult = ReturnType<typeof useGetRequisitionSkillSuspenseQuery>
export type GetRequisitionSkillQueryResult = Apollo.QueryResult<
  GetRequisitionSkillQuery,
  GetRequisitionSkillQueryVariables
>
export const GetJobListingScreeningQuestionDocument = gql`
  query GetJobListingScreeningQuestion(
    $id: Int
    $jobListingId: Int
    $orderBy: SortDirection
    $candidateApplicationId: Int
  ) {
    GetJobListingScreeningQuestion(id: $id, jobListingId: $jobListingId, orderBy: $orderBy) {
      id
      jobListingId
      displaySequenceNumber
      screeningQuestionId
      createdAt
      updatedAt
      ScreeningQuestion {
        id
        isTemplateQuestion
        questionTitle
        questionDesc
        questionType
        minVal
        maxVal
        valStatus
        ansOptions
        isRejectionQuestion
        rejectMessage
        templateMessageId
        preferredAnswer
        companyId
        companyUserId
        createdAt
        updatedAt
      }
      CandidateAnswer(candidateApplicationId: $candidateApplicationId) {
        id
        answer
        answerStatus
        candidateApplicationId
        listingScreeningQuestionId
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useGetJobListingScreeningQuestionQuery__
 *
 * To run a query within a React component, call `useGetJobListingScreeningQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobListingScreeningQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobListingScreeningQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      jobListingId: // value for 'jobListingId'
 *      orderBy: // value for 'orderBy'
 *      candidateApplicationId: // value for 'candidateApplicationId'
 *   },
 * });
 */
export function useGetJobListingScreeningQuestionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetJobListingScreeningQuestionQuery,
    GetJobListingScreeningQuestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobListingScreeningQuestionQuery, GetJobListingScreeningQuestionQueryVariables>(
    GetJobListingScreeningQuestionDocument,
    options,
  )
}
export function useGetJobListingScreeningQuestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobListingScreeningQuestionQuery,
    GetJobListingScreeningQuestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobListingScreeningQuestionQuery, GetJobListingScreeningQuestionQueryVariables>(
    GetJobListingScreeningQuestionDocument,
    options,
  )
}
export function useGetJobListingScreeningQuestionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetJobListingScreeningQuestionQuery,
        GetJobListingScreeningQuestionQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetJobListingScreeningQuestionQuery, GetJobListingScreeningQuestionQueryVariables>(
    GetJobListingScreeningQuestionDocument,
    options,
  )
}
export type GetJobListingScreeningQuestionQueryHookResult = ReturnType<typeof useGetJobListingScreeningQuestionQuery>
export type GetJobListingScreeningQuestionLazyQueryHookResult = ReturnType<
  typeof useGetJobListingScreeningQuestionLazyQuery
>
export type GetJobListingScreeningQuestionSuspenseQueryHookResult = ReturnType<
  typeof useGetJobListingScreeningQuestionSuspenseQuery
>
export type GetJobListingScreeningQuestionQueryResult = Apollo.QueryResult<
  GetJobListingScreeningQuestionQuery,
  GetJobListingScreeningQuestionQueryVariables
>
export const GetRolesDocument = gql`
  query GetRoles {
    GetRoles {
      id
      name
    }
  }
`

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options)
}
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options)
}
export function useGetRolesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options)
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>
export const GetEmailActivationDocument = gql`
  query GetEmailActivation($email: String!) {
    GetEmailActivation(email: $email)
  }
`

/**
 * __useGetEmailActivationQuery__
 *
 * To run a query within a React component, call `useGetEmailActivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailActivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailActivationQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetEmailActivationQuery(
  baseOptions: Apollo.QueryHookOptions<GetEmailActivationQuery, GetEmailActivationQueryVariables> &
    ({ variables: GetEmailActivationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEmailActivationQuery, GetEmailActivationQueryVariables>(GetEmailActivationDocument, options)
}
export function useGetEmailActivationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEmailActivationQuery, GetEmailActivationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEmailActivationQuery, GetEmailActivationQueryVariables>(
    GetEmailActivationDocument,
    options,
  )
}
export function useGetEmailActivationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetEmailActivationQuery, GetEmailActivationQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetEmailActivationQuery, GetEmailActivationQueryVariables>(
    GetEmailActivationDocument,
    options,
  )
}
export type GetEmailActivationQueryHookResult = ReturnType<typeof useGetEmailActivationQuery>
export type GetEmailActivationLazyQueryHookResult = ReturnType<typeof useGetEmailActivationLazyQuery>
export type GetEmailActivationSuspenseQueryHookResult = ReturnType<typeof useGetEmailActivationSuspenseQuery>
export type GetEmailActivationQueryResult = Apollo.QueryResult<
  GetEmailActivationQuery,
  GetEmailActivationQueryVariables
>
export const GetSchedulerLinkSettingsDocument = gql`
  query GetSchedulerLinkSettings($id: Int) {
    GetSchedulerLinkSettings(id: $id) {
      id
      minimumHour
      rollingDays
      maxInterviewsPerDay
    }
  }
`

/**
 * __useGetSchedulerLinkSettingsQuery__
 *
 * To run a query within a React component, call `useGetSchedulerLinkSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchedulerLinkSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchedulerLinkSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchedulerLinkSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSchedulerLinkSettingsQuery, GetSchedulerLinkSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSchedulerLinkSettingsQuery, GetSchedulerLinkSettingsQueryVariables>(
    GetSchedulerLinkSettingsDocument,
    options,
  )
}
export function useGetSchedulerLinkSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSchedulerLinkSettingsQuery, GetSchedulerLinkSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSchedulerLinkSettingsQuery, GetSchedulerLinkSettingsQueryVariables>(
    GetSchedulerLinkSettingsDocument,
    options,
  )
}
export function useGetSchedulerLinkSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetSchedulerLinkSettingsQuery, GetSchedulerLinkSettingsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSchedulerLinkSettingsQuery, GetSchedulerLinkSettingsQueryVariables>(
    GetSchedulerLinkSettingsDocument,
    options,
  )
}
export type GetSchedulerLinkSettingsQueryHookResult = ReturnType<typeof useGetSchedulerLinkSettingsQuery>
export type GetSchedulerLinkSettingsLazyQueryHookResult = ReturnType<typeof useGetSchedulerLinkSettingsLazyQuery>
export type GetSchedulerLinkSettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetSchedulerLinkSettingsSuspenseQuery
>
export type GetSchedulerLinkSettingsQueryResult = Apollo.QueryResult<
  GetSchedulerLinkSettingsQuery,
  GetSchedulerLinkSettingsQueryVariables
>
export const GetScorecardTemplateDocument = gql`
  query GetScorecardTemplate($id: Int) {
    GetScorecardTemplate(id: $id) {
      id
      archived
      department
      scorecardName
      scorecardDescription
      visible
      companyTemplate
      timestampAt
      createdAt
      ScorecardTemplateSection {
        id
        rankOrder
        scorecardTemplateId
        sectionName
        sectionType
        ScorecardTemplateQuestion {
          id
          answerOptions
          preferredAnswer
          questionDescription
          questionText
          questionType
          scoreAnswer
          scorecardTemplateSectionId
          skillId
          Skill {
            id
            name
          }
          CandidateScorecardAnswers {
            id
            answer
            answerPreferredStatus
            candidateScorecardId
            dateAnswer
            scoreAnswer
            scorecardTemplateQuestionId
          }
        }
      }
    }
  }
`

/**
 * __useGetScorecardTemplateQuery__
 *
 * To run a query within a React component, call `useGetScorecardTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScorecardTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScorecardTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScorecardTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetScorecardTemplateQuery, GetScorecardTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetScorecardTemplateQuery, GetScorecardTemplateQueryVariables>(
    GetScorecardTemplateDocument,
    options,
  )
}
export function useGetScorecardTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScorecardTemplateQuery, GetScorecardTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetScorecardTemplateQuery, GetScorecardTemplateQueryVariables>(
    GetScorecardTemplateDocument,
    options,
  )
}
export function useGetScorecardTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetScorecardTemplateQuery, GetScorecardTemplateQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetScorecardTemplateQuery, GetScorecardTemplateQueryVariables>(
    GetScorecardTemplateDocument,
    options,
  )
}
export type GetScorecardTemplateQueryHookResult = ReturnType<typeof useGetScorecardTemplateQuery>
export type GetScorecardTemplateLazyQueryHookResult = ReturnType<typeof useGetScorecardTemplateLazyQuery>
export type GetScorecardTemplateSuspenseQueryHookResult = ReturnType<typeof useGetScorecardTemplateSuspenseQuery>
export type GetScorecardTemplateQueryResult = Apollo.QueryResult<
  GetScorecardTemplateQuery,
  GetScorecardTemplateQueryVariables
>
export const GetScorecardTemplatesForCompareScorecardDocument = gql`
  query GetScorecardTemplatesForCompareScorecard($input: GetScorecardTemplatesInput!) {
    GetScorecardTemplates(input: $input) {
      id
      archived
      department
      scorecardName
      companyTemplate
      timestampAt
      createdAt
      ScorecardTemplateSection {
        id
        rankOrder
        scorecardTemplateId
        sectionName
        sectionType
        ScorecardTemplateQuestion {
          ...GetCompareScorecardTemplatesQuestion
        }
      }
      CompanyUser {
        id
        UserInvitation {
          id
          ScalisUser {
            id
            firstName
            lastName
          }
        }
      }
      CandidateScorecard {
        ...GetCompareScorecardTemplatesCandidateScorecard
      }
    }
  }
  ${GetCompareScorecardTemplatesQuestionFragmentDoc}
  ${GetCompareScorecardTemplatesCandidateScorecardFragmentDoc}
`

/**
 * __useGetScorecardTemplatesForCompareScorecardQuery__
 *
 * To run a query within a React component, call `useGetScorecardTemplatesForCompareScorecardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScorecardTemplatesForCompareScorecardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScorecardTemplatesForCompareScorecardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetScorecardTemplatesForCompareScorecardQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScorecardTemplatesForCompareScorecardQuery,
    GetScorecardTemplatesForCompareScorecardQueryVariables
  > &
    ({ variables: GetScorecardTemplatesForCompareScorecardQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetScorecardTemplatesForCompareScorecardQuery,
    GetScorecardTemplatesForCompareScorecardQueryVariables
  >(GetScorecardTemplatesForCompareScorecardDocument, options)
}
export function useGetScorecardTemplatesForCompareScorecardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScorecardTemplatesForCompareScorecardQuery,
    GetScorecardTemplatesForCompareScorecardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetScorecardTemplatesForCompareScorecardQuery,
    GetScorecardTemplatesForCompareScorecardQueryVariables
  >(GetScorecardTemplatesForCompareScorecardDocument, options)
}
export function useGetScorecardTemplatesForCompareScorecardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetScorecardTemplatesForCompareScorecardQuery,
        GetScorecardTemplatesForCompareScorecardQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetScorecardTemplatesForCompareScorecardQuery,
    GetScorecardTemplatesForCompareScorecardQueryVariables
  >(GetScorecardTemplatesForCompareScorecardDocument, options)
}
export type GetScorecardTemplatesForCompareScorecardQueryHookResult = ReturnType<
  typeof useGetScorecardTemplatesForCompareScorecardQuery
>
export type GetScorecardTemplatesForCompareScorecardLazyQueryHookResult = ReturnType<
  typeof useGetScorecardTemplatesForCompareScorecardLazyQuery
>
export type GetScorecardTemplatesForCompareScorecardSuspenseQueryHookResult = ReturnType<
  typeof useGetScorecardTemplatesForCompareScorecardSuspenseQuery
>
export type GetScorecardTemplatesForCompareScorecardQueryResult = Apollo.QueryResult<
  GetScorecardTemplatesForCompareScorecardQuery,
  GetScorecardTemplatesForCompareScorecardQueryVariables
>
export const GetScorecardTemplatesDocument = gql`
  query GetScorecardTemplates(
    $archived: Boolean
    $visible: Boolean
    $sortBy: SortByScorecardTemplate
    $sortDirection: SortDirection
  ) {
    GetScorecardTemplate(archived: $archived, visible: $visible, sortBy: $sortBy, sortDirection: $sortDirection) {
      id
      archived
      department
      scorecardName
      scorecardDescription
      visible
      companyTemplate
      timestampAt
      createdAt
      ScorecardTemplateSection {
        id
        rankOrder
        scorecardTemplateId
        sectionName
        sectionType
        ScorecardTemplateQuestion {
          id
          answerOptions
          preferredAnswer
          questionDescription
          questionText
          questionType
          scoreAnswer
          scorecardTemplateSectionId
          skillId
          Skill {
            id
            name
          }
        }
      }
      CompanyUser {
        id
        UserInvitation {
          ScalisUser {
            firstName
            lastName
          }
        }
      }
    }
  }
`

/**
 * __useGetScorecardTemplatesQuery__
 *
 * To run a query within a React component, call `useGetScorecardTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScorecardTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScorecardTemplatesQuery({
 *   variables: {
 *      archived: // value for 'archived'
 *      visible: // value for 'visible'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetScorecardTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetScorecardTemplatesQuery, GetScorecardTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetScorecardTemplatesQuery, GetScorecardTemplatesQueryVariables>(
    GetScorecardTemplatesDocument,
    options,
  )
}
export function useGetScorecardTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScorecardTemplatesQuery, GetScorecardTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetScorecardTemplatesQuery, GetScorecardTemplatesQueryVariables>(
    GetScorecardTemplatesDocument,
    options,
  )
}
export function useGetScorecardTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetScorecardTemplatesQuery, GetScorecardTemplatesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetScorecardTemplatesQuery, GetScorecardTemplatesQueryVariables>(
    GetScorecardTemplatesDocument,
    options,
  )
}
export type GetScorecardTemplatesQueryHookResult = ReturnType<typeof useGetScorecardTemplatesQuery>
export type GetScorecardTemplatesLazyQueryHookResult = ReturnType<typeof useGetScorecardTemplatesLazyQuery>
export type GetScorecardTemplatesSuspenseQueryHookResult = ReturnType<typeof useGetScorecardTemplatesSuspenseQuery>
export type GetScorecardTemplatesQueryResult = Apollo.QueryResult<
  GetScorecardTemplatesQuery,
  GetScorecardTemplatesQueryVariables
>
export const GetSearchResultsDocument = gql`
  query GetSearchResults(
    $companyId: String
    $department: String
    $educationLevel: EducationLevelPrefEnum
    $employment: JobTypeEnum
    $experienceLevel: JobLevelEnum
    $id: String
    $limit: Int
    $page: Int
    $payment: PayTypeEnum
    $search: String
    $sort: SearchSortEnum
    $tags: String
    $workplace: LocationTypeEnum
  ) {
    Search(
      companyId: $companyId
      department: $department
      educationLevel: $educationLevel
      employment: $employment
      experienceLevel: $experienceLevel
      id: $id
      limit: $limit
      page: $page
      payment: $payment
      search: $search
      sort: $sort
      tags: $tags
      workplace: $workplace
    ) {
      count
      filters {
        key
        values {
          count
          value
        }
      }
      results {
        benefits
        company {
          location {
            city
            continent
            country
            region
          }
          id
          logo
          name
        }
        createdAt
        department
        description
        descriptionHtml
        educationLevel
        employment
        experienceLevel
        id
        isApplied
        isBookmarked
        locations {
          city
          region
          country
          continent
        }
        payment
        salary {
          min
          max
          currency
        }
        skills
        source
        title
        url
        workplace
      }
    }
  }
`

/**
 * __useGetSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      department: // value for 'department'
 *      educationLevel: // value for 'educationLevel'
 *      employment: // value for 'employment'
 *      experienceLevel: // value for 'experienceLevel'
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      payment: // value for 'payment'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      tags: // value for 'tags'
 *      workplace: // value for 'workplace'
 *   },
 * });
 */
export function useGetSearchResultsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(GetSearchResultsDocument, options)
}
export function useGetSearchResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(GetSearchResultsDocument, options)
}
export function useGetSearchResultsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
    GetSearchResultsDocument,
    options,
  )
}
export type GetSearchResultsQueryHookResult = ReturnType<typeof useGetSearchResultsQuery>
export type GetSearchResultsLazyQueryHookResult = ReturnType<typeof useGetSearchResultsLazyQuery>
export type GetSearchResultsSuspenseQueryHookResult = ReturnType<typeof useGetSearchResultsSuspenseQuery>
export type GetSearchResultsQueryResult = Apollo.QueryResult<GetSearchResultsQuery, GetSearchResultsQueryVariables>
export const DeleteOfferSectionTemplateDocument = gql`
  mutation DeleteOfferSectionTemplate($input: DeleteOfferSectionTemplateInput!) {
    DeleteOfferSectionTemplate(input: $input) {
      id
    }
  }
`
export type DeleteOfferSectionTemplateMutationFn = Apollo.MutationFunction<
  DeleteOfferSectionTemplateMutation,
  DeleteOfferSectionTemplateMutationVariables
>

/**
 * __useDeleteOfferSectionTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteOfferSectionTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferSectionTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferSectionTemplateMutation, { data, loading, error }] = useDeleteOfferSectionTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOfferSectionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOfferSectionTemplateMutation,
    DeleteOfferSectionTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOfferSectionTemplateMutation, DeleteOfferSectionTemplateMutationVariables>(
    DeleteOfferSectionTemplateDocument,
    options,
  )
}
export type DeleteOfferSectionTemplateMutationHookResult = ReturnType<typeof useDeleteOfferSectionTemplateMutation>
export type DeleteOfferSectionTemplateMutationResult = Apollo.MutationResult<DeleteOfferSectionTemplateMutation>
export type DeleteOfferSectionTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteOfferSectionTemplateMutation,
  DeleteOfferSectionTemplateMutationVariables
>
export const GetOfferSectionTemplateDocument = gql`
  query GetOfferSectionTemplate {
    GetOfferSectionTemplate(input: {}) {
      ...OfferSectionTemplate
    }
  }
  ${OfferSectionTemplateFragmentDoc}
`

/**
 * __useGetOfferSectionTemplateQuery__
 *
 * To run a query within a React component, call `useGetOfferSectionTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferSectionTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferSectionTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOfferSectionTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOfferSectionTemplateQuery, GetOfferSectionTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOfferSectionTemplateQuery, GetOfferSectionTemplateQueryVariables>(
    GetOfferSectionTemplateDocument,
    options,
  )
}
export function useGetOfferSectionTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfferSectionTemplateQuery, GetOfferSectionTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOfferSectionTemplateQuery, GetOfferSectionTemplateQueryVariables>(
    GetOfferSectionTemplateDocument,
    options,
  )
}
export function useGetOfferSectionTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetOfferSectionTemplateQuery, GetOfferSectionTemplateQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetOfferSectionTemplateQuery, GetOfferSectionTemplateQueryVariables>(
    GetOfferSectionTemplateDocument,
    options,
  )
}
export type GetOfferSectionTemplateQueryHookResult = ReturnType<typeof useGetOfferSectionTemplateQuery>
export type GetOfferSectionTemplateLazyQueryHookResult = ReturnType<typeof useGetOfferSectionTemplateLazyQuery>
export type GetOfferSectionTemplateSuspenseQueryHookResult = ReturnType<typeof useGetOfferSectionTemplateSuspenseQuery>
export type GetOfferSectionTemplateQueryResult = Apollo.QueryResult<
  GetOfferSectionTemplateQuery,
  GetOfferSectionTemplateQueryVariables
>
export const UpsertOfferSectionTemplateDocument = gql`
  mutation UpsertOfferSectionTemplate($input: UpsertOfferSectionTemplateInput!) {
    UpsertOfferSectionTemplate(input: $input) {
      ...OfferSectionTemplate
    }
  }
  ${OfferSectionTemplateFragmentDoc}
`
export type UpsertOfferSectionTemplateMutationFn = Apollo.MutationFunction<
  UpsertOfferSectionTemplateMutation,
  UpsertOfferSectionTemplateMutationVariables
>

/**
 * __useUpsertOfferSectionTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertOfferSectionTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOfferSectionTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOfferSectionTemplateMutation, { data, loading, error }] = useUpsertOfferSectionTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertOfferSectionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertOfferSectionTemplateMutation,
    UpsertOfferSectionTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertOfferSectionTemplateMutation, UpsertOfferSectionTemplateMutationVariables>(
    UpsertOfferSectionTemplateDocument,
    options,
  )
}
export type UpsertOfferSectionTemplateMutationHookResult = ReturnType<typeof useUpsertOfferSectionTemplateMutation>
export type UpsertOfferSectionTemplateMutationResult = Apollo.MutationResult<UpsertOfferSectionTemplateMutation>
export type UpsertOfferSectionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpsertOfferSectionTemplateMutation,
  UpsertOfferSectionTemplateMutationVariables
>
export const ValidateOfferSectionTemplateDocument = gql`
  mutation ValidateOfferSectionTemplate($id: Int!) {
    ValidateOfferSectionTemplate(input: { id: $id }) {
      id
      sectionTemplateStatus
      validationResult
    }
  }
`
export type ValidateOfferSectionTemplateMutationFn = Apollo.MutationFunction<
  ValidateOfferSectionTemplateMutation,
  ValidateOfferSectionTemplateMutationVariables
>

/**
 * __useValidateOfferSectionTemplateMutation__
 *
 * To run a mutation, you first call `useValidateOfferSectionTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOfferSectionTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOfferSectionTemplateMutation, { data, loading, error }] = useValidateOfferSectionTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useValidateOfferSectionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateOfferSectionTemplateMutation,
    ValidateOfferSectionTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ValidateOfferSectionTemplateMutation, ValidateOfferSectionTemplateMutationVariables>(
    ValidateOfferSectionTemplateDocument,
    options,
  )
}
export type ValidateOfferSectionTemplateMutationHookResult = ReturnType<typeof useValidateOfferSectionTemplateMutation>
export type ValidateOfferSectionTemplateMutationResult = Apollo.MutationResult<ValidateOfferSectionTemplateMutation>
export type ValidateOfferSectionTemplateMutationOptions = Apollo.BaseMutationOptions<
  ValidateOfferSectionTemplateMutation,
  ValidateOfferSectionTemplateMutationVariables
>
export const GetSkillsDocument = gql`
  query GetSkills($id: Int, $experienceId: Int, $page: Int, $limit: Int) {
    GetSkills(id: $id, experienceId: $experienceId, page: $page, limit: $limit) {
      ...SkillFragment
    }
  }
  ${SkillFragmentFragmentDoc}
`

/**
 * __useGetSkillsQuery__
 *
 * To run a query within a React component, call `useGetSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      experienceId: // value for 'experienceId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSkillsQuery(baseOptions?: Apollo.QueryHookOptions<GetSkillsQuery, GetSkillsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSkillsQuery, GetSkillsQueryVariables>(GetSkillsDocument, options)
}
export function useGetSkillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSkillsQuery, GetSkillsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSkillsQuery, GetSkillsQueryVariables>(GetSkillsDocument, options)
}
export function useGetSkillsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSkillsQuery, GetSkillsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSkillsQuery, GetSkillsQueryVariables>(GetSkillsDocument, options)
}
export type GetSkillsQueryHookResult = ReturnType<typeof useGetSkillsQuery>
export type GetSkillsLazyQueryHookResult = ReturnType<typeof useGetSkillsLazyQuery>
export type GetSkillsSuspenseQueryHookResult = ReturnType<typeof useGetSkillsSuspenseQuery>
export type GetSkillsQueryResult = Apollo.QueryResult<GetSkillsQuery, GetSkillsQueryVariables>
export const GetScreenStageCollaboratorsDocument = gql`
  query GetScreenStageCollaborators($id: Int!) {
    GetTemplateStage(id: $id) {
      StageCollaborator {
        ...ScreenStageCollaboratorFragment
      }
    }
  }
  ${ScreenStageCollaboratorFragmentFragmentDoc}
`

/**
 * __useGetScreenStageCollaboratorsQuery__
 *
 * To run a query within a React component, call `useGetScreenStageCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScreenStageCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScreenStageCollaboratorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScreenStageCollaboratorsQuery(
  baseOptions: Apollo.QueryHookOptions<GetScreenStageCollaboratorsQuery, GetScreenStageCollaboratorsQueryVariables> &
    ({ variables: GetScreenStageCollaboratorsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetScreenStageCollaboratorsQuery, GetScreenStageCollaboratorsQueryVariables>(
    GetScreenStageCollaboratorsDocument,
    options,
  )
}
export function useGetScreenStageCollaboratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScreenStageCollaboratorsQuery,
    GetScreenStageCollaboratorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetScreenStageCollaboratorsQuery, GetScreenStageCollaboratorsQueryVariables>(
    GetScreenStageCollaboratorsDocument,
    options,
  )
}
export function useGetScreenStageCollaboratorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetScreenStageCollaboratorsQuery, GetScreenStageCollaboratorsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetScreenStageCollaboratorsQuery, GetScreenStageCollaboratorsQueryVariables>(
    GetScreenStageCollaboratorsDocument,
    options,
  )
}
export type GetScreenStageCollaboratorsQueryHookResult = ReturnType<typeof useGetScreenStageCollaboratorsQuery>
export type GetScreenStageCollaboratorsLazyQueryHookResult = ReturnType<typeof useGetScreenStageCollaboratorsLazyQuery>
export type GetScreenStageCollaboratorsSuspenseQueryHookResult = ReturnType<
  typeof useGetScreenStageCollaboratorsSuspenseQuery
>
export type GetScreenStageCollaboratorsQueryResult = Apollo.QueryResult<
  GetScreenStageCollaboratorsQuery,
  GetScreenStageCollaboratorsQueryVariables
>
export const GetStageCollaboratorDocument = gql`
  query GetStageCollaborator($id: Int!) {
    GetStageCollaborator(id: $id) {
      ...StageCollaboratorFragment
      createdAt
      updatedAt
      role {
        ...RoleFragment
      }
    }
  }
  ${StageCollaboratorFragmentFragmentDoc}
  ${RoleFragmentFragmentDoc}
`

/**
 * __useGetStageCollaboratorQuery__
 *
 * To run a query within a React component, call `useGetStageCollaboratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStageCollaboratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStageCollaboratorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStageCollaboratorQuery(
  baseOptions: Apollo.QueryHookOptions<GetStageCollaboratorQuery, GetStageCollaboratorQueryVariables> &
    ({ variables: GetStageCollaboratorQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetStageCollaboratorQuery, GetStageCollaboratorQueryVariables>(
    GetStageCollaboratorDocument,
    options,
  )
}
export function useGetStageCollaboratorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStageCollaboratorQuery, GetStageCollaboratorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetStageCollaboratorQuery, GetStageCollaboratorQueryVariables>(
    GetStageCollaboratorDocument,
    options,
  )
}
export function useGetStageCollaboratorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStageCollaboratorQuery, GetStageCollaboratorQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetStageCollaboratorQuery, GetStageCollaboratorQueryVariables>(
    GetStageCollaboratorDocument,
    options,
  )
}
export type GetStageCollaboratorQueryHookResult = ReturnType<typeof useGetStageCollaboratorQuery>
export type GetStageCollaboratorLazyQueryHookResult = ReturnType<typeof useGetStageCollaboratorLazyQuery>
export type GetStageCollaboratorSuspenseQueryHookResult = ReturnType<typeof useGetStageCollaboratorSuspenseQuery>
export type GetStageCollaboratorQueryResult = Apollo.QueryResult<
  GetStageCollaboratorQuery,
  GetStageCollaboratorQueryVariables
>
export const GetStageSettingsDocument = gql`
  query GetStageSettings($id: Int!) {
    GetStageSettings(id: $id) {
      ...StageSettingsFragment
      createdAt
      updatedAt
      JobPipeline {
        ...JobPipelineFragment
      }
      TemplateStage {
        ...TemplateStageFragment
        createdAt
        updatedAt
      }
    }
  }
  ${StageSettingsFragmentFragmentDoc}
  ${JobPipelineFragmentFragmentDoc}
  ${TemplateStageFragmentFragmentDoc}
`

/**
 * __useGetStageSettingsQuery__
 *
 * To run a query within a React component, call `useGetStageSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStageSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStageSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStageSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetStageSettingsQuery, GetStageSettingsQueryVariables> &
    ({ variables: GetStageSettingsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetStageSettingsQuery, GetStageSettingsQueryVariables>(GetStageSettingsDocument, options)
}
export function useGetStageSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStageSettingsQuery, GetStageSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetStageSettingsQuery, GetStageSettingsQueryVariables>(GetStageSettingsDocument, options)
}
export function useGetStageSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStageSettingsQuery, GetStageSettingsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetStageSettingsQuery, GetStageSettingsQueryVariables>(
    GetStageSettingsDocument,
    options,
  )
}
export type GetStageSettingsQueryHookResult = ReturnType<typeof useGetStageSettingsQuery>
export type GetStageSettingsLazyQueryHookResult = ReturnType<typeof useGetStageSettingsLazyQuery>
export type GetStageSettingsSuspenseQueryHookResult = ReturnType<typeof useGetStageSettingsSuspenseQuery>
export type GetStageSettingsQueryResult = Apollo.QueryResult<GetStageSettingsQuery, GetStageSettingsQueryVariables>
export const ListTemplateMessagesDocument = gql`
  query ListTemplateMessages($input: ListTemplateMessageInput!) {
    ListTemplateMessages(input: $input) {
      id
      title
      message
      messageType
      messageOwner
      createdAt
      updatedAt
    }
  }
`

/**
 * __useListTemplateMessagesQuery__
 *
 * To run a query within a React component, call `useListTemplateMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTemplateMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTemplateMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTemplateMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<ListTemplateMessagesQuery, ListTemplateMessagesQueryVariables> &
    ({ variables: ListTemplateMessagesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTemplateMessagesQuery, ListTemplateMessagesQueryVariables>(
    ListTemplateMessagesDocument,
    options,
  )
}
export function useListTemplateMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTemplateMessagesQuery, ListTemplateMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTemplateMessagesQuery, ListTemplateMessagesQueryVariables>(
    ListTemplateMessagesDocument,
    options,
  )
}
export function useListTemplateMessagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListTemplateMessagesQuery, ListTemplateMessagesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListTemplateMessagesQuery, ListTemplateMessagesQueryVariables>(
    ListTemplateMessagesDocument,
    options,
  )
}
export type ListTemplateMessagesQueryHookResult = ReturnType<typeof useListTemplateMessagesQuery>
export type ListTemplateMessagesLazyQueryHookResult = ReturnType<typeof useListTemplateMessagesLazyQuery>
export type ListTemplateMessagesSuspenseQueryHookResult = ReturnType<typeof useListTemplateMessagesSuspenseQuery>
export type ListTemplateMessagesQueryResult = Apollo.QueryResult<
  ListTemplateMessagesQuery,
  ListTemplateMessagesQueryVariables
>
export const GetTemplateMilestoneDocument = gql`
  query GetTemplateMilestone($id: Int!) {
    GetTemplateMilestone(id: $id) {
      ...TemplateMilestoneFragment
      createdAt
      updatedAt
      TemplatePipeline {
        ...TemplatePipelineFragment
        createdAt
        updatedAt
      }
      TemplateStage {
        ...TemplateStageFragment
        createdAt
        updatedAt
      }
    }
  }
  ${TemplateMilestoneFragmentFragmentDoc}
  ${TemplatePipelineFragmentFragmentDoc}
  ${TemplateStageFragmentFragmentDoc}
`

/**
 * __useGetTemplateMilestoneQuery__
 *
 * To run a query within a React component, call `useGetTemplateMilestoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateMilestoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateMilestoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateMilestoneQuery(
  baseOptions: Apollo.QueryHookOptions<GetTemplateMilestoneQuery, GetTemplateMilestoneQueryVariables> &
    ({ variables: GetTemplateMilestoneQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTemplateMilestoneQuery, GetTemplateMilestoneQueryVariables>(
    GetTemplateMilestoneDocument,
    options,
  )
}
export function useGetTemplateMilestoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateMilestoneQuery, GetTemplateMilestoneQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTemplateMilestoneQuery, GetTemplateMilestoneQueryVariables>(
    GetTemplateMilestoneDocument,
    options,
  )
}
export function useGetTemplateMilestoneSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTemplateMilestoneQuery, GetTemplateMilestoneQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTemplateMilestoneQuery, GetTemplateMilestoneQueryVariables>(
    GetTemplateMilestoneDocument,
    options,
  )
}
export type GetTemplateMilestoneQueryHookResult = ReturnType<typeof useGetTemplateMilestoneQuery>
export type GetTemplateMilestoneLazyQueryHookResult = ReturnType<typeof useGetTemplateMilestoneLazyQuery>
export type GetTemplateMilestoneSuspenseQueryHookResult = ReturnType<typeof useGetTemplateMilestoneSuspenseQuery>
export type GetTemplateMilestoneQueryResult = Apollo.QueryResult<
  GetTemplateMilestoneQuery,
  GetTemplateMilestoneQueryVariables
>
export const GetTemplatePipelineDocument = gql`
  query GetTemplatePipeline($id: Int!) {
    GetTemplatePipeline(id: $id) {
      ...TemplatePipelineFragment
      createdAt
      updatedAt
      TemplateMilestones {
        ...TemplateMilestoneFragment
        createdAt
        updatedAt
      }
      TemplateStage {
        ...TemplateStageFragment
        createdAt
        updatedAt
      }
      JobPipeline {
        ...JobPipelineFragment
      }
      CompanyDepartment {
        ...CompanyDepartmentFragment
      }
    }
  }
  ${TemplatePipelineFragmentFragmentDoc}
  ${TemplateMilestoneFragmentFragmentDoc}
  ${TemplateStageFragmentFragmentDoc}
  ${JobPipelineFragmentFragmentDoc}
  ${CompanyDepartmentFragmentFragmentDoc}
`

/**
 * __useGetTemplatePipelineQuery__
 *
 * To run a query within a React component, call `useGetTemplatePipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatePipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatePipelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplatePipelineQuery(
  baseOptions: Apollo.QueryHookOptions<GetTemplatePipelineQuery, GetTemplatePipelineQueryVariables> &
    ({ variables: GetTemplatePipelineQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTemplatePipelineQuery, GetTemplatePipelineQueryVariables>(
    GetTemplatePipelineDocument,
    options,
  )
}
export function useGetTemplatePipelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatePipelineQuery, GetTemplatePipelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTemplatePipelineQuery, GetTemplatePipelineQueryVariables>(
    GetTemplatePipelineDocument,
    options,
  )
}
export function useGetTemplatePipelineSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTemplatePipelineQuery, GetTemplatePipelineQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTemplatePipelineQuery, GetTemplatePipelineQueryVariables>(
    GetTemplatePipelineDocument,
    options,
  )
}
export type GetTemplatePipelineQueryHookResult = ReturnType<typeof useGetTemplatePipelineQuery>
export type GetTemplatePipelineLazyQueryHookResult = ReturnType<typeof useGetTemplatePipelineLazyQuery>
export type GetTemplatePipelineSuspenseQueryHookResult = ReturnType<typeof useGetTemplatePipelineSuspenseQuery>
export type GetTemplatePipelineQueryResult = Apollo.QueryResult<
  GetTemplatePipelineQuery,
  GetTemplatePipelineQueryVariables
>
export const ListTemplatePipelinesDocument = gql`
  query ListTemplatePipelines($companyDepartmentId: Int, $archived: Boolean) {
    ListTemplatePipelines(companyDepartmentId: $companyDepartmentId, archived: $archived) {
      id
      name
      companyDepartmentId
      pipelinedUsed
      archived
      createdAt
      updatedAt
      deletedAt
      CompanyDepartment {
        id
        departmentName
        subordinateTo
        externalId
        createdAt
        updatedAt
      }
      CompanyUser {
        id
        profileImage
        UserInvitation {
          id
          ScalisUser {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
      TemplateMilestones {
        id
        name
        rankOrder
        TemplateStage {
          id
          rankOrder
          name
          visibility
        }
      }
      TemplateStage {
        id
        name
      }
    }
  }
`

/**
 * __useListTemplatePipelinesQuery__
 *
 * To run a query within a React component, call `useListTemplatePipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTemplatePipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTemplatePipelinesQuery({
 *   variables: {
 *      companyDepartmentId: // value for 'companyDepartmentId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useListTemplatePipelinesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTemplatePipelinesQuery, ListTemplatePipelinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTemplatePipelinesQuery, ListTemplatePipelinesQueryVariables>(
    ListTemplatePipelinesDocument,
    options,
  )
}
export function useListTemplatePipelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTemplatePipelinesQuery, ListTemplatePipelinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTemplatePipelinesQuery, ListTemplatePipelinesQueryVariables>(
    ListTemplatePipelinesDocument,
    options,
  )
}
export function useListTemplatePipelinesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListTemplatePipelinesQuery, ListTemplatePipelinesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListTemplatePipelinesQuery, ListTemplatePipelinesQueryVariables>(
    ListTemplatePipelinesDocument,
    options,
  )
}
export type ListTemplatePipelinesQueryHookResult = ReturnType<typeof useListTemplatePipelinesQuery>
export type ListTemplatePipelinesLazyQueryHookResult = ReturnType<typeof useListTemplatePipelinesLazyQuery>
export type ListTemplatePipelinesSuspenseQueryHookResult = ReturnType<typeof useListTemplatePipelinesSuspenseQuery>
export type ListTemplatePipelinesQueryResult = Apollo.QueryResult<
  ListTemplatePipelinesQuery,
  ListTemplatePipelinesQueryVariables
>
export const GetTemplateStageModalDataDocument = gql`
  query GetTemplateStageModalData($id: Int!) {
    GetTemplateStage(id: $id) {
      __typename
      id
      name
      TemplateMilestone {
        __typename
        id
        name
      }
      TemplatePipeline {
        __typename
        id
        JobPipeline {
          __typename
          id
          jobRequisitionId
        }
      }
    }
  }
`

/**
 * __useGetTemplateStageModalDataQuery__
 *
 * To run a query within a React component, call `useGetTemplateStageModalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateStageModalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateStageModalDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateStageModalDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetTemplateStageModalDataQuery, GetTemplateStageModalDataQueryVariables> &
    ({ variables: GetTemplateStageModalDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTemplateStageModalDataQuery, GetTemplateStageModalDataQueryVariables>(
    GetTemplateStageModalDataDocument,
    options,
  )
}
export function useGetTemplateStageModalDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateStageModalDataQuery, GetTemplateStageModalDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTemplateStageModalDataQuery, GetTemplateStageModalDataQueryVariables>(
    GetTemplateStageModalDataDocument,
    options,
  )
}
export function useGetTemplateStageModalDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTemplateStageModalDataQuery, GetTemplateStageModalDataQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTemplateStageModalDataQuery, GetTemplateStageModalDataQueryVariables>(
    GetTemplateStageModalDataDocument,
    options,
  )
}
export type GetTemplateStageModalDataQueryHookResult = ReturnType<typeof useGetTemplateStageModalDataQuery>
export type GetTemplateStageModalDataLazyQueryHookResult = ReturnType<typeof useGetTemplateStageModalDataLazyQuery>
export type GetTemplateStageModalDataSuspenseQueryHookResult = ReturnType<
  typeof useGetTemplateStageModalDataSuspenseQuery
>
export type GetTemplateStageModalDataQueryResult = Apollo.QueryResult<
  GetTemplateStageModalDataQuery,
  GetTemplateStageModalDataQueryVariables
>
export const GetTemplateStageDocument = gql`
  query GetTemplateStage($id: Int!) {
    GetTemplateStage(id: $id) {
      ...TemplateStageFragment
      createdAt
      updatedAt
      StageSettings {
        ...StageSettingsFragment
        createdAt
        updatedAt
      }
      TemplateMilestone {
        ...TemplateMilestoneFragment
        createdAt
        updatedAt
      }
      TemplatePipeline {
        ...TemplatePipelineFragment
        createdAt
        updatedAt
      }
      StageCollaborator {
        ...StageCollaboratorFragment
        createdAt
        updatedAt
        role {
          ...RoleFragment
        }
      }
    }
  }
  ${TemplateStageFragmentFragmentDoc}
  ${StageSettingsFragmentFragmentDoc}
  ${TemplateMilestoneFragmentFragmentDoc}
  ${TemplatePipelineFragmentFragmentDoc}
  ${StageCollaboratorFragmentFragmentDoc}
  ${RoleFragmentFragmentDoc}
`

/**
 * __useGetTemplateStageQuery__
 *
 * To run a query within a React component, call `useGetTemplateStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateStageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateStageQuery(
  baseOptions: Apollo.QueryHookOptions<GetTemplateStageQuery, GetTemplateStageQueryVariables> &
    ({ variables: GetTemplateStageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTemplateStageQuery, GetTemplateStageQueryVariables>(GetTemplateStageDocument, options)
}
export function useGetTemplateStageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateStageQuery, GetTemplateStageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTemplateStageQuery, GetTemplateStageQueryVariables>(GetTemplateStageDocument, options)
}
export function useGetTemplateStageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTemplateStageQuery, GetTemplateStageQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTemplateStageQuery, GetTemplateStageQueryVariables>(
    GetTemplateStageDocument,
    options,
  )
}
export type GetTemplateStageQueryHookResult = ReturnType<typeof useGetTemplateStageQuery>
export type GetTemplateStageLazyQueryHookResult = ReturnType<typeof useGetTemplateStageLazyQuery>
export type GetTemplateStageSuspenseQueryHookResult = ReturnType<typeof useGetTemplateStageSuspenseQuery>
export type GetTemplateStageQueryResult = Apollo.QueryResult<GetTemplateStageQuery, GetTemplateStageQueryVariables>
export const GetTemporaryS3UrlDocument = gql`
  query GetTemporaryS3Url($input: GetTemporaryS3UrlInput!) {
    GetTemporaryS3Url(input: $input) {
      tempUrl
    }
  }
`

/**
 * __useGetTemporaryS3UrlQuery__
 *
 * To run a query within a React component, call `useGetTemporaryS3UrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemporaryS3UrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemporaryS3UrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTemporaryS3UrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetTemporaryS3UrlQuery, GetTemporaryS3UrlQueryVariables> &
    ({ variables: GetTemporaryS3UrlQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTemporaryS3UrlQuery, GetTemporaryS3UrlQueryVariables>(GetTemporaryS3UrlDocument, options)
}
export function useGetTemporaryS3UrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTemporaryS3UrlQuery, GetTemporaryS3UrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTemporaryS3UrlQuery, GetTemporaryS3UrlQueryVariables>(
    GetTemporaryS3UrlDocument,
    options,
  )
}
export function useGetTemporaryS3UrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTemporaryS3UrlQuery, GetTemporaryS3UrlQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTemporaryS3UrlQuery, GetTemporaryS3UrlQueryVariables>(
    GetTemporaryS3UrlDocument,
    options,
  )
}
export type GetTemporaryS3UrlQueryHookResult = ReturnType<typeof useGetTemporaryS3UrlQuery>
export type GetTemporaryS3UrlLazyQueryHookResult = ReturnType<typeof useGetTemporaryS3UrlLazyQuery>
export type GetTemporaryS3UrlSuspenseQueryHookResult = ReturnType<typeof useGetTemporaryS3UrlSuspenseQuery>
export type GetTemporaryS3UrlQueryResult = Apollo.QueryResult<GetTemporaryS3UrlQuery, GetTemporaryS3UrlQueryVariables>
export const GetUploadedCandidateDocument = gql`
  query GetUploadedCandidate($id: Int!) {
    GetUploadedCandidate(id: $id) {
      id
      emailAddress
      firstName
      lastName
      linkedinProfile
      profileImage
      uploadedCandidateType
      CandidateProfile {
        id
        profileType
      }
      Achievement {
        id
        name
        issuingAuthority
        issueYear
        description
        searchDescription
      }
      CertificateOrLicense {
        id
        name
        issuingAuthority
        issueYear
        description
        searchDescription
      }
      Education {
        id
        institutionName
        currentlyStudying
        educationLevel
        startDate
        endDate
        areasOfStudy
        majors
        minors
        cumGPA
        cumGPAScale
        hideCumGPA
        hideDepartmentGPA
        departmentCumGPA
        departmentCumGPAScale
        additionalInformation
      }
      Experience {
        id
        startDate
        endDate
        locationType
        location
        shortFormDesc
        longFormDesc
        searchShortFormDesc
        searchLongFormDesc
        industry
        subIndustry
        payType
        jobPay
        additionalComp
        jobTitle
        employerName
        managementPosition
        teamSize
        workPlaceCandidate
        isCurrentJob
        jobType
        isCurrentlyWorking
        description
      }
      JobSeekerSkill {
        id
        sequenceNumber
        level
        name
        yearsOfExp
      }
      VolunteerWork {
        id
        volunteerJobTitle
        organizationName
        isCurrentlyWorking
        roleType
        startDate
        endDate
        description
        searchDescription
        location
      }
      WebsiteOrSocialMedia {
        id
        websiteURL
        type
      }
    }
  }
`

/**
 * __useGetUploadedCandidateQuery__
 *
 * To run a query within a React component, call `useGetUploadedCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadedCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadedCandidateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUploadedCandidateQuery(
  baseOptions: Apollo.QueryHookOptions<GetUploadedCandidateQuery, GetUploadedCandidateQueryVariables> &
    ({ variables: GetUploadedCandidateQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUploadedCandidateQuery, GetUploadedCandidateQueryVariables>(
    GetUploadedCandidateDocument,
    options,
  )
}
export function useGetUploadedCandidateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUploadedCandidateQuery, GetUploadedCandidateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUploadedCandidateQuery, GetUploadedCandidateQueryVariables>(
    GetUploadedCandidateDocument,
    options,
  )
}
export function useGetUploadedCandidateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUploadedCandidateQuery, GetUploadedCandidateQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetUploadedCandidateQuery, GetUploadedCandidateQueryVariables>(
    GetUploadedCandidateDocument,
    options,
  )
}
export type GetUploadedCandidateQueryHookResult = ReturnType<typeof useGetUploadedCandidateQuery>
export type GetUploadedCandidateLazyQueryHookResult = ReturnType<typeof useGetUploadedCandidateLazyQuery>
export type GetUploadedCandidateSuspenseQueryHookResult = ReturnType<typeof useGetUploadedCandidateSuspenseQuery>
export type GetUploadedCandidateQueryResult = Apollo.QueryResult<
  GetUploadedCandidateQuery,
  GetUploadedCandidateQueryVariables
>
export const GetAccountInformationDocument = gql`
  query GetAccountInformation {
    Me {
      id
      firstName
      lastName
      Address {
        id
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
        timeZone
      }
      ContactInfo {
        id
        phoneNumber
      }
    }
  }
`

/**
 * __useGetAccountInformationQuery__
 *
 * To run a query within a React component, call `useGetAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAccountInformationQuery, GetAccountInformationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(
    GetAccountInformationDocument,
    options,
  )
}
export function useGetAccountInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountInformationQuery, GetAccountInformationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(
    GetAccountInformationDocument,
    options,
  )
}
export function useGetAccountInformationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetAccountInformationQuery, GetAccountInformationQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(
    GetAccountInformationDocument,
    options,
  )
}
export type GetAccountInformationQueryHookResult = ReturnType<typeof useGetAccountInformationQuery>
export type GetAccountInformationLazyQueryHookResult = ReturnType<typeof useGetAccountInformationLazyQuery>
export type GetAccountInformationSuspenseQueryHookResult = ReturnType<typeof useGetAccountInformationSuspenseQuery>
export type GetAccountInformationQueryResult = Apollo.QueryResult<
  GetAccountInformationQuery,
  GetAccountInformationQueryVariables
>
export const GetEmailsDocument = gql`
  query GetEmails($scalisUserId: Int!) {
    GetScalisUser(id: $scalisUserId) {
      EmailVerification {
        __typename
        id
        emailAddress
        isPrimary
        isVisibleToEmployers
        activeStatus
        emailVerified
      }
    }
  }
`

/**
 * __useGetEmailsQuery__
 *
 * To run a query within a React component, call `useGetEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailsQuery({
 *   variables: {
 *      scalisUserId: // value for 'scalisUserId'
 *   },
 * });
 */
export function useGetEmailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables> &
    ({ variables: GetEmailsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, options)
}
export function useGetEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, options)
}
export function useGetEmailsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, options)
}
export type GetEmailsQueryHookResult = ReturnType<typeof useGetEmailsQuery>
export type GetEmailsLazyQueryHookResult = ReturnType<typeof useGetEmailsLazyQuery>
export type GetEmailsSuspenseQueryHookResult = ReturnType<typeof useGetEmailsSuspenseQuery>
export type GetEmailsQueryResult = Apollo.QueryResult<GetEmailsQuery, GetEmailsQueryVariables>
export const GetScalisUserIdDocument = gql`
  query GetScalisUserId {
    Me {
      id
    }
  }
`

/**
 * __useGetScalisUserIdQuery__
 *
 * To run a query within a React component, call `useGetScalisUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScalisUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScalisUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScalisUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetScalisUserIdQuery, GetScalisUserIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetScalisUserIdQuery, GetScalisUserIdQueryVariables>(GetScalisUserIdDocument, options)
}
export function useGetScalisUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScalisUserIdQuery, GetScalisUserIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetScalisUserIdQuery, GetScalisUserIdQueryVariables>(GetScalisUserIdDocument, options)
}
export function useGetScalisUserIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetScalisUserIdQuery, GetScalisUserIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetScalisUserIdQuery, GetScalisUserIdQueryVariables>(GetScalisUserIdDocument, options)
}
export type GetScalisUserIdQueryHookResult = ReturnType<typeof useGetScalisUserIdQuery>
export type GetScalisUserIdLazyQueryHookResult = ReturnType<typeof useGetScalisUserIdLazyQuery>
export type GetScalisUserIdSuspenseQueryHookResult = ReturnType<typeof useGetScalisUserIdSuspenseQuery>
export type GetScalisUserIdQueryResult = Apollo.QueryResult<GetScalisUserIdQuery, GetScalisUserIdQueryVariables>
